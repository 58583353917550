import {Box, ListItem, ListItemAvatar, ListItemText, Theme} from "@mui/material";
import React, {MouseEventHandler, ReactNode} from "react";
import {makeStyles} from "tss-react/mui";
import {ChevronRight} from "@mui/icons-material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import AppAvatar from "../AppAvatar";

const useStyles = makeStyles()((theme: Theme) => ({
    listItem: {
        paddingLeft: 16,
        paddingRight: 16,
        transition: 'background 0.3s linear',
        ':hover': {
            background: kAppColors.hoverColor.grey(theme.palette.mode === "dark"),
            cursor: "pointer",
        },
        marginBottom: 4,
    },
    noMarginBottom: {
        marginBottom: 0,
    },
    noHover: {
        ':hover': {
            background: "transparent",
            cursor: "default",
        }
    },
    description: {
        '.MuiListItemText-secondary': {
            whiteSpace: "pre-wrap",
        },
    },
    smallerTitle: {
        '.MuiTypography-body1': {
            fontSize: 16,
            lineHeight: 21 / 16,
        }
    },
    thin: {
        '.MuiTypography-body1': {
            fontSize: 16 + 'px !important',
            lineHeight: 21 / 16,
            whiteSpace: "pre-wrap",
        },
        minHeight: 52,
        marginBottom: 0,
        paddingBottom: 0,
        paddingTop: 0,
    },
    listItemAvatar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    textContainer: {
        paddingRight: 8,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        flexGrow: 1,
    },
    mainContainer: {
        flexGrow: 1,
    },
    mainInnerContainer: {
        display: "flex",
        flexGrow: 1,
    },
    noValueVariant: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontStyle: 'italic',
    },
}));

export interface IAppListItemProps {
    listKey?: string;
    title: string,
    className?: string,
    description?: ReactNode | NullOrUndefined,
    secondLine?: ReactNode,
    profileImage?: string | NullOrUndefined,
    customAvatar?: ReactNode,
    customAvatarInCircle?: ReactNode,
    customBottomWidget?: ReactNode,
    belowListItemWidget?: ReactNode,
    noAvatar?: boolean
    actionWidget?: ReactNode,
    noMarginBottom?: boolean,
    onClick?: MouseEventHandler<HTMLLIElement>,
    variant?: 'smaller-title' | 'thin' | 'noValue' | undefined,
}

export default function AppListItem(props: IAppListItemProps) {
    const {
        title,
        description,
        secondLine,
        onClick,
        actionWidget,
        profileImage,
        variant,
        customAvatar,
        noMarginBottom,
        customAvatarInCircle,
        className,
        noAvatar,
        customBottomWidget,
        belowListItemWidget,
    } = props;

    const {classes, cx} = useStyles();

    const listItemClass = cx(
        classes.listItem,
        noMarginBottom ? classes.noMarginBottom : undefined,
        onClick == null ? classes.noHover : undefined,
        variant === "thin" || variant === "noValue" ? classes.thin : undefined,
        variant === "noValue" ? classes.noValueVariant : undefined,
        className,
    );

    const listItemTextClass = cx(
        variant === 'smaller-title' ?
            classes.smallerTitle : undefined,
        classes.description
    );

    const secondaryActionJSX = actionWidget == null ? <ChevronRight/> : actionWidget;

    const avatarJSX = customAvatar != null ? customAvatar :
        <AppAvatar
            icon={customAvatarInCircle}
            title={title}
            img={profileImage}
        />;

    return (
        <Box>
            <ListItem onClick={onClick} className={listItemClass} secondaryAction={secondaryActionJSX}>
                {noAvatar ? null : <ListItemAvatar className={classes.listItemAvatar}>
                    {avatarJSX}
                </ListItemAvatar>}

                <Box className={cx(classes.textContainer, 'textsContainer')}>
                    <ListItemText
                        className={listItemTextClass}
                        primary={title}
                        secondary={secondLine || description}/>

                    {customBottomWidget}
                </Box>
            </ListItem>

            {belowListItemWidget ? <Box pb={1} pl={2} pr={2}>
                {belowListItemWidget}
            </Box> : null}
        </Box>

    );
}
