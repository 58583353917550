import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8AddShoppingCart1Props {
    className?: string;
}

export default function Icons8AddShopping1Cart(props: IIcons8AddShoppingCart1Props) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 3.5 6 A 1.50015 1.50015 0 1 0 3.5 9 L 6.2558594 9 C 6.9837923 9 7.5905865 9.5029243 7.7285156 10.21875 L 8.0234375 11.765625 C 8.0242582 11.770197 8.0245268 11.774728 8.0253906 11.779297 L 11.251953 28.716797 C 11.834953 31.778797 14.521672 34 17.638672 34 L 36.363281 34 C 39.480281 34 42.164047 31.778797 42.748047 28.716797 L 45.974609 11.779297 C 46.056609 11.341297 45.94025 10.887969 45.65625 10.542969 C 45.37125 10.198969 44.947 10 44.5 10 L 10.740234 10 L 10.675781 9.6582031 C 10.272657 7.5455321 8.4069705 6 6.2558594 6 L 3.5 6 z M 26.5 16 C 27.329 16 28 16.671 28 17.5 L 28 20 L 30.5 20 C 31.329 20 32 20.671 32 21.5 C 32 22.329 31.329 23 30.5 23 L 28 23 L 28 25.5 C 28 26.329 27.329 27 26.5 27 C 25.671 27 25 26.329 25 25.5 L 25 23 L 22.5 23 C 21.671 23 21 22.329 21 21.5 C 21 20.671 21.671 20 22.5 20 L 25 20 L 25 17.5 C 25 16.671 25.671 16 26.5 16 z M 20 36 A 3 3 0 0 0 20 42 A 3 3 0 0 0 20 36 z M 34 36 A 3 3 0 0 0 34 42 A 3 3 0 0 0 34 36 z"/>
            </svg>
        </span>
    );
}



