import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8CancelProps {
    className?: string;
}

export default function Icons8Upload(props: IIcons8CancelProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 23.978516 6 A 1.50015 1.50015 0 0 0 22.939453 6.4394531 L 16.939453 12.439453 A 1.50015 1.50015 0 1 0 19.060547 14.560547 L 22.5 11.121094 L 22.5 32.5 A 1.50015 1.50015 0 1 0 25.5 32.5 L 25.5 11.121094 L 28.939453 14.560547 A 1.50015 1.50015 0 1 0 31.060547 12.439453 L 25.060547 6.4394531 A 1.50015 1.50015 0 0 0 23.978516 6 z M 11.5 17 C 8.4802259 17 6 19.480226 6 22.5 L 6 36.5 C 6 39.519774 8.4802259 42 11.5 42 L 36.5 42 C 39.519774 42 42 39.519774 42 36.5 L 42 22.5 C 42 19.480226 39.519774 17 36.5 17 L 34.5 17 A 1.50015 1.50015 0 1 0 34.5 20 L 36.5 20 C 37.898226 20 39 21.101774 39 22.5 L 39 36.5 C 39 37.898226 37.898226 39 36.5 39 L 11.5 39 C 10.101774 39 9 37.898226 9 36.5 L 9 22.5 C 9 21.101774 10.101774 20 11.5 20 L 13.5 20 A 1.50015 1.50015 0 1 0 13.5 17 L 11.5 17 z"/>
            </svg>
        </span>
    );
}
