import {RadioButtonGroupProps} from "./InputProps";
import React from "react";
import GreyLabel from "../decorations/GreyLabel";
import {Box, Theme, Typography} from "@mui/material";
import {useStyles as formUseStyles} from "./FormBuilder";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";


export const useStyles = makeStyles()((theme: Theme) => ({
    topLabelPadding: {
        paddingTop: 16,
        paddingBottom: 16,
    },
    label: {
        width: '100%',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 16,
        paddingRight: 16,
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        position: "relative",
        'input:checked ~ .radioButtonThingClass': {
            border: `2px solid ${kAppColors.primary.main}`,
        },
        'input:checked ~ .radioButtonThingClass:after': {
            backgroundColor: kAppColors.primary.main,
        },
        ':hover': {
            background: kAppColors.hoverColor.grey(theme.palette.mode === "dark"),
        },
    },
    radioButtonThing: {
        display: 'block',
        position: "relative",
        width: 20,
        height: 20,
        borderRadius: 20,
        transition: 'background 0.3s linear',
        border: `2px solid ${kAppColors.grey.disabledInput(theme.palette.mode === "dark")}`,
        flexShrink: 0,
        marginRight: 8,
        ':after': {
            position: "absolute",
            content: `''`,
            backgroundColor: "transparent",
            transition: 'background 0.3s linear',
            borderRadius: 10,
            width: 10,
            height: 10,
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
        },
    },
    input: {
        opacity: 0,
        position: "absolute",
    },
    inputContainer: {
        display: "flex",
    },
    disabledClassLabel: {
        cursor: "default",
        'input:checked ~ .radioButtonThingClass:after': {
            backgroundColor: kAppColors.grey.disabledInput(theme.palette.mode === "dark"),
        },
    },
    disabledClassRadioButtonThing: {
        border: `2px solid ${kAppColors.grey.disabledInput(theme.palette.mode === "dark")}`,
    },
    text: {
        wontWeight: 500,
    }
}));

export default function RadioButtonGroup(props: RadioButtonGroupProps) {
    const {
        index,
        className,
        label,
        value,
        updateValue,
        options,
        onChange,
        hidden,
        error,
        errorMessage,
        helperText,
        readOnly,
        testId,
        topLabel,
        disabled,
        avatar,
    } = props;

    /**
     * OnChange update data of this field.
     */
    const OnChangeField = (newValue: string) => {
        updateValue(index, newValue);

        if (onChange) {
            onChange(index, newValue);
        }
    };

    const {classes: formClasses, cx} = formUseStyles();
    const {classes} = useStyles();

    return (
        <>
            <div className={cx(className, (hidden ? formClasses.hidden : undefined))}>
                {topLabel ? <GreyLabel className={classes.topLabelPadding} text={topLabel}/> : null}

                {options.map((item) =>
                    <Box
                        key={item.value}
                        className={classes.inputContainer}
                    >
                        <label className={cx(classes.label, disabled ? classes.disabledClassLabel : null)}>
                            <input
                                className={classes.input}
                                type="radio"
                                name={index}
                                value={item.value}
                                checked={value === item.value}
                                disabled={disabled}
                                onChange={(e) => OnChangeField(item.value)}
                            />

                            <span
                                className={cx(classes.radioButtonThing, disabled ? classes.disabledClassRadioButtonThing : null, 'radioButtonThingClass')}></span>
                            <Typography className={classes.text}>{item.label}</Typography>
                            {avatar && value === item.value ? <Box pl={1}>
                                {avatar}
                            </Box> : null}
                        </label>
                    </Box>)}
            </div>
        </>
    );
}
