import {Box, Theme, Typography} from "@mui/material";
import {tt} from "../../../../../core/Localization";
import HeadlineWithButton from "../../../../screenSections/detailListPreviewSection/HeadlineWithButton";
import React, {useState} from "react";
import {makeStyles} from "tss-react/mui";
import Icons8Invoice from "../../../../../icons/Icons8Invoice";
import Icons8Receipt from "../../../../../icons/Icons8Receipt";
import EditVisitInvoiceDeliveryNumbersModalBottomSheet
    from "../../../modals/job/visits/EditVisitInvoiceDeliveryNumbersModalBottomSheet";
import {IOnUpdateVisitIdParams} from "../../../modals/job/visits/VisitDetailModal";
import PencilIcon from "../../../../../icons/PencilIcon";
import Icons8EditOutlined from "../../../../../icons/Icons8EditOutlined";
import {kActionUpdate, kPermissionsJobs} from "../../../../../core/constants";

export const useStyles = makeStyles()((theme: Theme) => ({
    headlineWithButton: {
        paddingTop: 0,
    },
    numbersContainer: {
        display: 'flex',
    },
    numberContainer: {
        '> *': {
            display: 'inline-block',
            verticalAlign: 'middle',
        },
    },
}));

export interface IJobDetailInvoiceDeliverySectionProps {
    jobId: number;
    repeatingDay?: number;
    deliveryNumber?: string | NullOrUndefined;
    invoiceNumber?: string | NullOrUndefined;
    onUpdateJobId: (params: IOnUpdateVisitIdParams) => void;
}

/**
 * Component for the Invoice Delivery section of the Job Detail page
 */
export default function VisitDetailInvoiceDeliverySection(props: IJobDetailInvoiceDeliverySectionProps) {
    const {jobId, repeatingDay, deliveryNumber, invoiceNumber, onUpdateJobId} = props;

    const [updateModal, setUpdateModal] = useState(false);

    const {classes, cx} = useStyles();

    const invoiceJSX = invoiceNumber ? (
        <Box pr={2} className={classes.numberContainer}>
            <Box pr={1}>
                <Icons8Invoice/>
            </Box>

            <Typography>{invoiceNumber}</Typography>
        </Box>
    ) : undefined;

    const deliveryJSX = deliveryNumber ? (
        <Box className={classes.numberContainer}>
            <Box pr={1}>
                <Icons8Receipt/>
            </Box>

            <Typography>{deliveryNumber}</Typography>
        </Box>
    ) : undefined;

    return (
        <>
            <Box>
                <HeadlineWithButton
                    className={cx(classes.headlineWithButton)}
                    title={tt('visitDetail.invoiceDelivery')}
                    iconJSX={<Icons8EditOutlined/>}
                    onClick={() => setUpdateModal(true)}
                    buttonText={tt('common.edit')}
                    permission={kPermissionsJobs}
                    requiredPermissions={[kActionUpdate]}
                />

                {invoiceJSX || deliveryJSX ? (
                    <Box pl={2} pr={2} pb={2} className={classes.numbersContainer}>
                        {invoiceJSX}

                        {deliveryJSX}
                    </Box>
                ) : undefined}
            </Box>

            <EditVisitInvoiceDeliveryNumbersModalBottomSheet
                modalAboveModals={true}
                visitId={jobId}
                repeatingDay={repeatingDay}
                open={updateModal}
                setOpen={setUpdateModal}
                onUpdateVisitId={onUpdateJobId}
                deliveryNumber={deliveryNumber}
                invoiceNumber={invoiceNumber}
            />
        </>
    );
}
