import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import ContentPadding from "../../components/paper/ContentPadding";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../components/form/FormBuilder";
import ScreenContent from "../../components/screens/ScreenContent";
import AppButton from "../../components/buttons/AppButton";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {
    UserChangeEmailDocument,
    UserChangeEmailMutation,
    UserChangeEmailMutationVariables,
    UserProfileResponse
} from "../../../generated/graphql/graphql";
import {ErrorToast} from "../../../service/ToastService";
import {EmailAuthProvider, getAuth, reauthenticateWithCredential, updateEmail} from "firebase/auth";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {useNavigate} from "react-router-dom";
import {kProfileRoute} from "../profile/ProfileScreen";
import {FirebaseAuthErrorMessage} from "../../../service/AuthUserService";
import {processMutationError, processQueryError} from "../../../service/ErrorService";
import {AppDataContext} from "../../../AppData";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";

export const kChangeLoginEmailRoute = '/change-login-email';

export default function ChangeLoginEmailScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle, setAuthUser} = appContext;

    const appDataContext = useContext(AppDataContext);

    const navigate = useNavigate();

    const [processLoading, setProcessLoading] = useState<boolean>(false);
    const [password, setPassword] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<UserProfileResponse | NullOrUndefined>();
    useEffect(() => {
        restApiGet({
            uri: '/user/profile',
            params: {},
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading,
            onData: setData,
            onError: (error) => processQueryError(appDataContext, error),
        });
    }, []);

    const [mutateChange, {
        loading: changeLoading,
        error: changeError,
        data: changeData,
    }] = useResettableMutation<UserChangeEmailMutation, UserChangeEmailMutationVariables>(UserChangeEmailDocument);

    useEffect(() => {
        setTitle(tt('changeLoginEmail.screen.title'));
    }, []);

    useEffect(() => {
        if (changeError) {
            processMutationError(changeError);
        }
    }, [changeError]);

    const [inputs, setInputs] = useState<IInputsData>({
        loginEmail: {
            type: InputType.Text,
            label: tt('changeLoginEmail.screen.input.label') + '*',
            value: '',
            required: true,
            isClearable: true,
            requireEmail: true,
        },
    });

    useEffect(() => {
        if (data) {
            setInputs((prev: IInputsData) => {
                return {
                    ...prev,
                    loginEmail: {
                        ...prev.loginEmail,
                        value: data?.user.email,
                    },
                };
            });
        }
    }, [data]);

    useEffect(() => {
        if (password && !inputs.currentPassword) {
            setInputs(prev => {
                return {
                    ...prev,
                    currentPassword: {
                        type: InputType.Password,
                        label: tt('changeLoginEmail.screen.currentPassword.label') + '*',
                        value: '',
                        required: true,
                    },
                };
            });
        }
    }, [password]);

    /**
     * Change User email on Firebase and our BE.
     */
    const ChangeEmail = async () => {
        if (ValidateForm(inputs, setInputs)) {
            setProcessLoading(true);

            try {
                const auth = getAuth();

                const user = auth.currentUser!;

                if (password) {
                    await reauthenticateWithCredential(user, EmailAuthProvider.credential(user.email!, inputs.currentPassword.value));
                }

                await updateEmail(user, inputs.loginEmail.value);

                const variables: UserChangeEmailMutationVariables = {
                    input: {
                        email: inputs.loginEmail.value,
                    },
                };

                const result = await mutateChange({variables});

                if (!result.errors) {
                    const idToken = await auth.currentUser!.getIdToken(true);

                    setAuthUser(prev => {
                        setTimeout(() => {
                            navigate(kProfileRoute);
                        }, 1);

                        return {
                            ...prev!,
                            email: inputs.loginEmail.value,
                            idToken,
                        };
                    });
                }
            } catch (e: any) {
                console.error(e);

                if (e.code === 'auth/requires-recent-login') {
                    setPassword(true);

                    ErrorToast(tt('changeLoginEmail.screen.error.recentLogin'), {duration: 6000});
                } else {
                    FirebaseAuthErrorMessage(e);
                }
            }

            setProcessLoading(false);
        }
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                loading={loading}
                changeLoading={changeLoading || processLoading}
                isMobile={isMobile}
                inputs={inputs}
                setInputs={setInputs}
                changeEmail={ChangeEmail}
            />
        );
    }

    return (
        <ResponsiveContainer
            smallPhoneScreen={bodyJSX(true)}
            largePhoneScreen={bodyJSX(true)}
            tabletScreen={bodyJSX()}
            smallDesktopScreen={bodyJSX()}
            largeDesktopScreen={bodyJSX()}
            extraLargeDesktopScreen={bodyJSX()}
        />
    );
}

interface IBodyProps {
    isMobile?: boolean;
    loading: boolean;
    changeLoading?: boolean;
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    changeEmail: VoidFunction;
}

function Body(props: IBodyProps) {
    const {
        loading,
        isMobile,
        inputs,
        setInputs,
        changeEmail,
        changeLoading,
    } = props;

    return (
        <ScreenContent appBar={!isMobile}
                       noContentPadding={isMobile}
                       navigationDrawer={!isMobile}
                       bottomBar={isMobile}
                       centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar isMobile={isMobile}
                             title={tt('changeLoginEmail.screen.title')}
                             backRoute={kProfileRoute}
                             cancelIconBackButton={true}/>
                <ContentPadding>
                    <FormBuilder inputs={inputs} setInputs={setInputs}/>
                    <AppButton
                        isLoading={changeLoading}
                        onClick={changeEmail}
                        variant={"contained"}
                        fullWidth={true}
                        disabled={loading}
                    >
                        {tt('changeLoginEmail.screen.submit')}
                    </AppButton>
                </ContentPadding>
            </AppPaper>
        </ScreenContent>
    );
}
