import React, {Dispatch, SetStateAction} from "react";
import AppModal from "../AppModal";
import {tt} from "../../../../core/Localization";
import JobFormDetailContent from "../../jobForms/JobFormDetailContent";
import {EmployeeJoinedUserResponse, FileResponse, JobFormResponse} from "../../../../generated/graphql/graphql";
import {UpdateJobFormElementDoneCallback} from "../../jobs/visits/visitDetail/VisitDetailContent";

export interface IJobFormPreviewModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    jobFormResponse: JobFormResponse | NullOrUndefined;
    employeesData?: Array<EmployeeJoinedUserResponse> | NullOrUndefined;
    files?: FileResponse[] | NullOrUndefined;
    canEdit?: boolean | NullOrUndefined;
    updateJobFormElementDone?: UpdateJobFormElementDoneCallback;
    modalAboveModals?: boolean;
    overrideName?: string | NullOrUndefined;
}

export default function JobFormPreviewModal(props: IJobFormPreviewModalProps) {
    const {
        open,
        setOpen,
        jobFormResponse,
        updateJobFormElementDone,
        canEdit,
        employeesData,
        files,
        modalAboveModals,
        overrideName,
    } = props;

    return (
        <AppModal
            noContentPadding={true}
            blurBackdrop={true}
            open={open}
            setOpen={setOpen}
            title={tt('jobFormPreviewModal.title')}
            fullScreenOnMobile={true}
            modalAboveModals={modalAboveModals}
        >
            <JobFormDetailContent
                jobFormResponse={jobFormResponse}
                updateJobFormElementDone={updateJobFormElementDone}
                canEdit={canEdit}
                employeesData={employeesData}
                files={files}
                overrideName={overrideName}
            />
        </AppModal>
    );
}
