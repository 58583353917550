import {Box, Checkbox, Theme, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import {tt} from "../../../core/Localization";
import ModalBottomSheet from "../ModalBottomSheet";
import BottomSheetModalAppbar from "../modals/BottomSheetModalAppbar";
import AppButton from "../buttons/AppButton";
import {NoteItemResponse} from "../../../generated/graphql/graphql";


export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        paddingLeft: 16,
        paddingRight: 16,
    },
}));

export interface INoteItemEditModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    existing?: NoteItemResponse | NullOrUndefined;
    loading?: boolean;
    onSave: (note: string) => void;
}

export default function NoteItemEditModalBottomSheet(props: INoteItemEditModalBottomSheetProps) {
    const {
        open,
        setOpen,
        existing,
        loading,
        onSave,
    } = props;

    const {classes} = useStyles();

    const [inputs, setInputs] = useState<IInputsData>({
        note: {
            testId: 'workerNoteInputTestId',
            value: '',
            label: tt('common.description'),
            type: InputType.Text,
            minRows: 5,
        },
    });

    useEffect(() => {
        if (open) {
            setInputs((prev) => {
                return {
                    ...prev,
                    note: {
                        ...prev.note,
                        value: existing?.note || '',
                    },
                };
            });
        }
    }, [open, existing]);

    return (
        <ModalBottomSheet
            blurBackdrop={true}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
            modalAboveModals={true}
            children={
                <>
                    <BottomSheetModalAppbar
                        title={tt('common.note')}
                        onClose={() => setOpen(false)}
                        noBorderBottom={true}
                    />
                    <Box className={classes.container}>
                        <Box pb={1}/>
                        <FormBuilder inputs={inputs} setInputs={setInputs}/>
                        <Box pb={1}/>
                        <AppButton
                            variant={"contained"}
                            fullWidth={true}
                            isLoading={loading}
                            onClick={() => {
                                if (ValidateForm(inputs, setInputs)) {
                                    onSave(inputs.note.value);
                                }
                            }}>
                            {tt('common.save')}
                        </AppButton>
                        <Box pb={3}/>
                    </Box>
                </>
            }
        />
    );
}
