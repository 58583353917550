import {AutoCompleteOption} from "../ui/components/form/InputProps";
import {tt} from "../core/Localization";
import { LocationPureResponse } from "../generated/graphql/graphql";
import {addressToSingleLine} from "../utils/AddressUtils";

/**
 * Display Location name or type if no name.
 */
export function LocationNameDisplay(name: string | NullOrUndefined, type: string, address?: {
    street: string,
    city: string,
    country: string,
    state: string,
    zipcode: string
} | NullOrUndefined) {
    if (address) {
        return name || addressToSingleLine(address, true) || LocationTypeDisplay(type);
    }

    return name || LocationTypeDisplay(type);
}

/**
 * Convert Location type to text for display.
 */
export function LocationTypeDisplay(type: string) {
    const option = kLocationTypesOptions().find(option => option.value === type);
    if (option) {
        return option.label;
    }

    return type;
}

/**
 * Find location AutoCompleteOption for value.
 */
export function LocationTypeOptionByValue(value: string): AutoCompleteOption {
    for (const optionOf of kLocationTypesOptions()) {
        if (optionOf.value === value) {
            return optionOf;
        }
    }

    return {
        label: value,
        value,
    };
}

/**
 * Filter for Locations by Client, sorted from newest to oldest.
 */
export function locationsPureForClient(clientId: number, locations: LocationPureResponse[]): LocationPureResponse[] {
    return locations.filter(location => location.clientId === clientId)
        .sort((a, b) => b.createdAt - a.createdAt);
}

export function kLocationTypesOptions(skipOther?: boolean): AutoCompleteOption[] {
    const list = [
        {
            'label': tt('locationType.airbnbApt'),
            'value': 'airbnb-apt',
        },
        {
            'label': tt('locationType.apt'),
            'value': 'apt',
        },
        {
            'label': tt('locationType.aptBuilding'),
            'value': 'apt-building',
        },
        {
            'label': tt('locationType.businessCentre'),
            'value': 'business-centre',
        },
        {
            'label': tt('locationType.corridor'),
            'value': 'corridor',
        },
        {
            'label': tt('locationType.factory'),
            'value': 'factory',
        },
        {
            'label': tt('locationType.familyHouse'),
            'value': 'family-house',
        },
        {
            'label': tt('locationType.field'),
            'value': 'field',
        },
        {
            'label': tt('locationType.flat'),
            'value': 'flat',
        },
        {
            'label': tt('locationType.flatBuilding'),
            'value': 'flat-building',
        },
        {
            'label': tt('locationType.garage'),
            'value': 'garage',
        },
        {
            'label': tt('locationType.garden'),
            'value': 'garden',
        },
        {
            'label': tt('locationType.hospital'),
            'value': 'hospital',
        },
        {
            'label': tt('locationType.hotel'),
            'value': 'hotel',
        },
        {
            'label': tt('locationType.household'),
            'value': 'household',
        },
        {
            'label': tt('locationType.kindergarten'),
            'value': 'kindergarten',
        },
        {
            'label': tt('locationType.office'),
            'value': 'office',
        },
        {
            'label': tt('locationType.officeBuilding'),
            'value': 'office-building',
        },
        {
            'label': tt('locationType.operationalSrea'),
            'value': 'operational-area',
        },
        {
            'label': tt('locationType.park'),
            'value': 'park',
        },
        {
            'label': tt('locationType.parking'),
            'value': 'parking',
        },
        {
            'label': tt('locationType.playground'),
            'value': 'playground',
        },
        {
            'label': tt('locationType.postOffice'),
            'value': 'post-office',
        },
        {
            'label': tt('locationType.restaurant'),
            'value': 'restaurant',
        },
        {
            'label': tt('locationType.room'),
            'value': 'room',
        },
        {
            'label': tt('locationType.shop'),
            'value': 'shop',
        },
        {
            'label': tt('locationType.shoppingCentre'),
            'value': 'shopping-centre',
        },
        {
            'label': tt('locationType.school'),
            'value': 'school',
        },
        {
            'label': tt('locationType.store'),
            'value': 'store',
        },
        {
            'label': tt('locationType.street'),
            'value': 'street',
        },
        {
            'label': tt('locationType.subway'),
            'value': 'subway',
        },
        {
            'label': tt('locationType.supermarket'),
            'value': 'supermarket',
        },
        {
            'label': tt('locationType.vetOffice'),
            'value': 'vet-office',
        },
        {
            'label': tt('locationType.warehouse'),
            'value': 'warehouse',
        },
        {
            'label': tt('locationType.other'),
            'value': 'other',
        },
        {
            'label': tt('locationType.hairdressing'),
            'value': 'hairdressing',
        },
        {
            'label': tt('locationType.operatingRoom'),
            'value': 'operatingRoom',
        },
        {
            'label': tt('locationType.doctorsOffice'),
            'value': 'doctorsOffice',
        },
        {
            'label': tt('locationType.polyclinic'),
            'value': 'polyclinic',
        },
    ];

    return skipOther ? list.filter(option => option.value !== 'other') : list;
}
