import {Tab, Theme, Tooltip} from "@mui/material";
import {Key, ReactNode, SyntheticEvent} from "react";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";

export const useStyles = makeStyles()((theme: Theme) => ({
    active: {
        color: kAppColors.primary.main,
        opacity: 1,
    },
}));

export interface ITooltipTabProps {
    key?: Key | null | undefined;
    value: any;
    title: ReactNode;
    label: ReactNode;
    onChange: (event: React.SyntheticEvent, newValue: string) => void;
    tabValue: string;
}

/**
 * Component for rendering a tab with a tooltip.
 */
export function TooltipTab(props: ITooltipTabProps) {
    const {key, value, title, label, onChange, tabValue} = props;

    const {classes, cx} = useStyles();

    const isActive = tabValue === value;

    return (
        <Tooltip title={title}>
            <Tab
                className={cx(isActive ? classes.active : undefined)}
                key={key}
                value={value}
                label={label}
                onClick={(event: SyntheticEvent) => onChange(event, value)}
            />
        </Tooltip>
    );
}
