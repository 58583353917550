import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import {AppDataContext, SetAppBreadcrumbs} from "../../../AppData";
import {tt} from "../../../core/Localization";
import {useNavigate, useParams} from "react-router-dom";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import {usePopupState} from "material-ui-popup-state/hooks";
import {HideConfirmModal, SetConfirmModal} from "../../components/modals/AppModals";
import DetailScreenShimmer from "../../components/shimmers/DetailScreenShimmer";
import AppListItem from "../../components/listItems/AppListItem";
import UserIcon from "../../../icons/UserIcon";
import AppTabsComponent from "../../components/AppTabsComponent";
import ContactSection from "../../screenSections/contactSection/ContactSection";
import {Box} from "@mui/material";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import PaperAppbar from "../../components/paper/PaperAppbar";
import AppIconButton from "../../components/buttons/AppIconButton";
import PencilIcon from "../../../icons/PencilIcon";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MoreFilledIcon from "../../../icons/MoreFilledIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "../../../icons/DeleteIcon";
import {contactPersonEditRoute, ContactPersonEditRouteKind} from "./ContactPersonEditScreen";
import {clientDetailRoute} from "../clients/ClientDetailScreen";
import {
    ClientContactProfileResponse,
    DeleteClientContactDocument,
    DeleteClientContactMutation,
    DeleteClientContactMutationVariables,
    GetClientContactInput} from "../../../generated/graphql/graphql";
import {useMutation} from "@apollo/client";
import {SuccessToast} from "../../../service/ToastService";
import {UserFullName} from "../../../service/UserService";
import IBreadcrumb from "../../../model/Breadcrumb";
import {kClientsBreadcrumb, kClientsRoute} from "../clients/ClientsScreen";
import {contactPersonListRoute} from "./ContactPersonListScreen";
import AppBreadCrumb from "../../components/breadCrumb/AppBreadCrumb";
import {PlaceDetailRoute} from "../places/PlaceDetailScreen";
import {locationDetailRoute} from "../locations/LocationDetailScreen";
import {LocationNameDisplay} from "../../../service/LocationService";
import IEventSystemListener from "../../../model/EventSystemListener";
import {
    listenToEventSystem,
    unListenToEventSystem
} from "../../../service/EventSystemService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import {processMutationError, processQueryError} from "../../../service/ErrorService";
import {
    kActionDelete,
    kActionUpdate, kPermissionsClients,
    kTopicClientContacts,
    kTopicClients, kTopicLocationPlaces,
    kTopicLocations
} from "../../../core/constants";
import PermissionValid from "../../components/permissions/PermissionValid";
import { RestApiClientContext } from "../../../core/RestApiProvider";

export const kContactPersonDetailRoute = '/clients/:clientId/contact-person/:contactId';
export const kContactPersonDetailFromListRoute = '/clients/:clientId/contact-persons/:contactId';
export const kContactPersonDetailFromLocationRoute = '/clients/:clientId/locations/:locationId/contact-person/:contactId';
export const kContactPersonDetailFromPlaceRoute = '/clients/:clientId/locations/:locationId/places/:placeId/contact-person/:contactId';

export enum ContactPersonDetailRouteKind {
    default,
    fromList,
    fromLocation,
    fromPlace,
}

export function contactPersonDetailRoute(
    clientId: string | number,
    contactId: string | number,
    kind?: ContactPersonDetailRouteKind,
    locationId?: string | number,
    placeId?: string | number,
) {
    if (kind === ContactPersonDetailRouteKind.fromList) {
        return kContactPersonDetailFromListRoute
            .replace(':contactId', `${contactId}`)
            .replace(':clientId', `${clientId}`);
    }

    if (kind === ContactPersonDetailRouteKind.fromLocation) {
        if (!locationId) {
            throw Error('LocationId is required for route');
        }

        return kContactPersonDetailFromLocationRoute
            .replace(':contactId', `${contactId}`)
            .replace(':clientId', `${clientId}`)
            .replace(':locationId', `${locationId}`);
    }

    if (kind === ContactPersonDetailRouteKind.fromPlace) {
        if (!locationId) {
            throw Error('LocationId is required for route');
        }
        if (!placeId) {
            throw Error('PlaceId is required for route');
        }

        return kContactPersonDetailFromPlaceRoute
            .replace(':contactId', `${contactId}`)
            .replace(':clientId', `${clientId}`)
            .replace(':locationId', `${locationId}`)
            .replace(':placeId', `${placeId}`);
    }

    return kContactPersonDetailRoute
        .replace(':contactId', `${contactId}`)
        .replace(':clientId', `${clientId}`);
}

export default function ContactPersonDetailsScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;
    
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);

    const {contactId, clientId, locationId, placeId} = useParams();
    const navigate = useNavigate();

    let kindOfRoute = ContactPersonDetailRouteKind.default;

    if (locationId && placeId && contactPersonDetailRoute(clientId!, contactId!, ContactPersonDetailRouteKind.fromPlace, locationId, placeId) === window.location.pathname) {
        kindOfRoute = ContactPersonDetailRouteKind.fromPlace;
    } else if (locationId && contactPersonDetailRoute(clientId!, contactId!, ContactPersonDetailRouteKind.fromLocation, locationId) === window.location.pathname) {
        kindOfRoute = ContactPersonDetailRouteKind.fromLocation;
    } else if (contactPersonDetailRoute(clientId!, contactId!, ContactPersonDetailRouteKind.fromList) === window.location.pathname) {
        kindOfRoute = ContactPersonDetailRouteKind.fromList;
    }

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<ClientContactProfileResponse | NullOrUndefined>();
    useEffect(() => {
        if (contactId && clientId) {
            const subscription = subscribe(
                kTopicClientContacts,
                {
                    uri: '/client/contact-profile',
                    params: {
                        clientContactId: parseInt(contactId!),
                        includeLocationId: locationId ? parseInt(locationId) : undefined,
                        includePlaceId: placeId ? parseInt(placeId) : undefined,
                    } as GetClientContactInput,
                    setLoading,
                    onData: setData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                (notifications: IEventSystemNotification[]) => {
                    return notifications.some(notification => {
                        if (notification.action !== kActionUpdate) {
                            return false;
                        }
    
                        return notification.data.id === parseInt(contactId!);
                    });
                },
            );
    
            return () => subscription.cancel();
        } else {
            setLoading(false);
            setData(null);
        }
    }, [contactId, clientId, locationId, placeId]);

    const locationData = data?.location;
    const placeData = data?.locationPlace;

    const [mutateDelete, {
        loading: deleteLoading,
    }] = useMutation<DeleteClientContactMutation, DeleteClientContactMutationVariables>(DeleteClientContactDocument);

    /**
     * Set breadcrumbs.
     */
    const SetBreadcrumbs = () => {
        const breadcrumbs: IBreadcrumb[] = [kClientsBreadcrumb];

        if (data) {
            breadcrumbs.push({
                key: 'client-detail',
                text: data!.client.name,
                route: clientDetailRoute(clientId!),
            });

            if ((kindOfRoute === ContactPersonDetailRouteKind.fromLocation || kindOfRoute === ContactPersonDetailRouteKind.fromPlace) && locationData) {
                breadcrumbs.push({
                    key: 'location-detail',
                    text: LocationNameDisplay(locationData.name, locationData.type, locationData.address),
                    route: locationDetailRoute(clientId!, locationId!),
                });
            }

            if (kindOfRoute === ContactPersonDetailRouteKind.fromPlace && placeData) {
                breadcrumbs.push({
                    key: 'place-detail',
                    text: placeData.name,
                    route: PlaceDetailRoute(clientId!, locationId!, placeId!),
                });
            }

            breadcrumbs.push({
                key: 'client-contact',
                text: UserFullName(data!.clientContact.name, data!.clientContact.surname),
                route: '',
            });
        }

        SetAppBreadcrumbs(appDataContext, breadcrumbs);
    };

    useEffect(() => {
        setTitle(tt('contactPersonDetail.screen.title'));

        SetBreadcrumbs();
    }, []);

    useEffect(() => {
        SetBreadcrumbs();
    }, [data]);

    useEffect(() => {
        SetConfirmModal(appDataContext, {
            loading: deleteLoading,
        });
    }, [deleteLoading]);

    /**
     * Mutation delete Client Contact to BE.
     */
    const DeleteContact = async () => {
        try {
            const variables: DeleteClientContactMutationVariables = {
                input: {
                    clientContactId: parseInt(contactId!),
                },
            };

            const result = await mutateDelete({variables});

            if (!result.errors) {
                HideConfirmModal(appDataContext);

                navigate(clientDetailRoute(clientId!));

                SuccessToast(tt('contactPersonDetail.screen.delete.success'));
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                kindOfRoute={kindOfRoute}
                isMobile={isMobile}
                loading={loading}
                data={data}
                deleteContact={DeleteContact}
            />
        );
    }

    return (
        <>
            <EventSystemListeners />

            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}/>
        </>
    );
}

interface IEventSystemListenersProps {
}

/**
 * Component for EventSystem listeners.
 */
function EventSystemListeners(props: IEventSystemListenersProps) {
    const {contactId, clientId, locationId, placeId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicClientContacts,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(contactId!);
                });

                if (doDelete) {
                    if (placeId) {
                        navigate(PlaceDetailRoute(clientId!, locationId!, placeId!));
                    } else if (locationId) {
                        navigate(locationDetailRoute(clientId!, locationId!));
                    } else if (clientId) {
                        navigate(clientDetailRoute(clientId!));
                    } else {
                        navigate(kClientsRoute);
                    }
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [contactId, clientId, locationId, placeId]);

    useEffect(() => {
        if (clientId) {
            const eventSystemListener: IEventSystemListener = {
                topic: kTopicClients,
                callback: (notifications: IEventSystemNotification[]) => {
                    const doDelete = notifications.some(notification => {
                        if (notification.action !== kActionDelete) {
                            return false;
                        }

                        return notification.data.id === parseInt(clientId!);
                    });

                    if (doDelete) {
                        navigate(kClientsRoute);
                    }
                },
            };

            listenToEventSystem(eventSystemListener);

            return () => {
                unListenToEventSystem(eventSystemListener);
            };
        }
    }, [clientId]);

    useEffect(() => {
        if (locationId) {
            const eventSystemListener: IEventSystemListener = {
                topic: kTopicLocations,
                callback: (notifications: IEventSystemNotification[]) => {
                    const doDelete = notifications.some(notification => {
                        if (notification.action !== kActionDelete) {
                            return false;
                        }

                        return notification.data.id === parseInt(locationId!);
                    });

                    if (doDelete) {
                        if (clientId) {
                            navigate(clientDetailRoute(clientId!));
                        } else {
                            navigate(kClientsRoute);
                        }
                    }
                },
            };

            listenToEventSystem(eventSystemListener);

            return () => {
                unListenToEventSystem(eventSystemListener);
            };
        }
    }, [clientId, locationId]);

    useEffect(() => {
        if (placeId) {
            const eventSystemListener: IEventSystemListener = {
                topic: kTopicLocationPlaces,
                callback: (notifications: IEventSystemNotification[]) => {
                    const doDelete = notifications.some(notification => {
                        if (notification.action !== kActionDelete) {
                            return false;
                        }

                        return notification.data.id === parseInt(placeId!);
                    });

                    if (doDelete) {
                        if (locationId) {
                            navigate(locationDetailRoute(clientId!, locationId!));
                        } else if (clientId) {
                            navigate(clientDetailRoute(clientId!));
                        } else {
                            navigate(kClientsRoute);
                        }
                    }
                },
            };

            listenToEventSystem(eventSystemListener);

            return () => {
                unListenToEventSystem(eventSystemListener);
            };
        }
    }, [clientId, locationId, placeId]);

    return null;
}

interface IBodyProps {
    kindOfRoute: ContactPersonDetailRouteKind;
    isMobile?: boolean;
    loading: boolean;
    data?: ClientContactProfileResponse | NullOrUndefined;
    deleteContact: VoidFunction;
}

/**
 * Main body component.
 */
function Body(props: IBodyProps) {
    const {
        kindOfRoute,
        isMobile,
        loading,
        data,
        deleteContact,
    } = props;

    const appDataContext = useContext(AppDataContext);

    const {contactId, clientId, locationId, placeId} = useParams();
    const navigate = useNavigate();

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: 'popup-client-detail-settings',
    });

    const contactsJSX =
        <AppTabsComponent
            data={[
                {
                    label: tt('common.contact'), content: <>
                        <ContactSection
                            hideAddress={true}
                            hideEmail={true}
                            phone={data?.clientContact.phoneNumber}
                        />
                    </>
                },
            ]}
        />;

    const contentJSX = loading && !data ? <DetailScreenShimmer/> :
        (
            <>
                <AppListItem
                    customAvatarInCircle={<UserIcon/>}
                    title={UserFullName(data?.clientContact.name, data?.clientContact.surname)}
                    description={data?.clientContact.role || ''}
                    actionWidget={
                        <></>
                    }/>

                {contactsJSX}
            </>
        );

    let backRoute = clientDetailRoute(parseInt(clientId!));
    let editRoute = contactPersonEditRoute(clientId!, contactId!);

    if (kindOfRoute === ContactPersonDetailRouteKind.fromPlace) {
        backRoute = PlaceDetailRoute(clientId!, locationId!, placeId!);
        editRoute = contactPersonEditRoute(clientId!, contactId!, ContactPersonEditRouteKind.fromPlace, locationId, placeId);
    } else if (kindOfRoute === ContactPersonDetailRouteKind.fromLocation) {
        backRoute = locationDetailRoute(clientId!, locationId!);
        editRoute = contactPersonEditRoute(clientId!, contactId!, ContactPersonEditRouteKind.fromLocation, locationId);
    } else if (kindOfRoute === ContactPersonDetailRouteKind.fromList) {
        backRoute = contactPersonListRoute(clientId!);
        editRoute = contactPersonEditRoute(clientId!, contactId!, ContactPersonEditRouteKind.fromList);
    }

    return (
        <ScreenContent
            showBreadCrumb={true}
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    isMobile={isMobile}
                    title={tt('contactPerson.detail.content.title')}
                    backRoute={backRoute}>
                    <PermissionValid
                        permission={kPermissionsClients}
                        requiredPermissions={[kActionUpdate]}
                    >
                        <AppIconButton key={'contactPersonDetailBtn1'}
                                       onClick={() => navigate(editRoute)}>
                            <PencilIcon/>
                        </AppIconButton>
                    </PermissionValid>

                    <PermissionValid
                        permission={kPermissionsClients}
                        requiredPermissions={[kActionDelete]}
                    >
                        <AppIconButton key={'contactPersonDetailBtn2'}
                                       {...bindTrigger(settingsPopupState)}
                        >
                            <MoreFilledIcon/>
                        </AppIconButton>
                    </PermissionValid>
                </PaperAppbar>

                {isMobile ? <AppBreadCrumb/> : null}

                {contentJSX}

                <Menu {...bindMenu(settingsPopupState)}>
                    <PermissionValid
                        permission={kPermissionsClients}
                        requiredPermissions={[kActionDelete]}
                    >
                        <MenuItem key={'contactPersonDetailDeleteKey'} onClick={() => {
                            settingsPopupState.close();

                            SetConfirmModal(appDataContext, {
                                open: true,
                                title: tt('contactPerson.detail.delete.confirm.title'),
                                subtitle: tt('contactPerson.detail.delete.confirm.subtitle'),
                                confirmationButtonText: tt('common.delete'),
                                cancelButtonText: tt('common.close'),
                                children: <></>,
                                onConfirm: deleteContact,
                            });
                        }}>
                            <Box sx={{color: kAppColors.red.confirmButton}}><DeleteIcon/></Box>
                            {tt('common.delete')}
                        </MenuItem>
                    </PermissionValid>
                </Menu>
            </AppPaper>
        </ScreenContent>
    );
}
