import {Box, Theme, Typography} from "@mui/material";
import AppIconButton from "../buttons/AppIconButton";
import ArrowLeftIcon from "../../../icons/ArrowLeftIcon";
import React, {useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import CloseIcon from "../../../icons/CloseIcon";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {useNavigate} from "react-router-dom";

export const useStyles = makeStyles()((theme: Theme) => ({
    appbar: {
        flexShrink: 0,
        width: '100%',
        maxWidth: '100%',
        position: "sticky",
        top: -16,
        zIndex: 100,
        backgroundColor: kAppColors.background.mobileAppbar(theme.palette.mode === "dark"),
        backdropFilter: `saturate(180%) blur(10px)`,
        transition: 'all 0.1s linear',
        borderBottom: `1px solid transparent`,
        overflow: "hidden",
        "@media (max-width: 767px)": {
            top: 0,
        },
        marginBottom: 16,
    },
    appbarInnerContainer: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 56,
    },
    appbarBorderOnScroll: {
        borderBottom: `1px solid ${kAppColors.border(theme.palette.mode === "dark")}`
    },
    noMarginBottom: {
        marginBottom: 0,
    },
    title: {
        flexGrow: 1,
        marginBottom: 0,
        fontSize: 20,
        fontWeight: 600,
    },
    buttons: {
        display: 'flex',
        alignItems: "center",
        marginLeft: "auto",
        '> button': {
            marginLeft: 8,
        }
    },
    defaultHorizontalMargin: {
        paddingRight: 16,
        paddingLeft: 8,
    },
    defaultHorizontalMarginWithoutBackButton: {
        paddingRight: 16,
        paddingLeft: 16,
    },
    mobileAppbar: {
        marginTop: 0,
        marginBottom: 12,
    },
    backButtonContainer: {
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
    },
    mobileTitle: {
        fontSize: 16,
    }
}));

export interface IPaperAppbarProps {
    children?: React.ReactNode;
    title: string;
    hideBackButton?: boolean;
    noMarginBottom?: boolean;
    backRoute?: string;
    cancelIconBackButton?: boolean,
    isMobile?: boolean,
    customOnBackFunction?: Function,
    bottomContent?: React.ReactNode,
    className?: string;
}

export default function PaperAppbar(props: IPaperAppbarProps) {
    const {
        className,
        children,
        title,
        hideBackButton,
        backRoute,
        noMarginBottom,
        cancelIconBackButton,
        isMobile,
        customOnBackFunction,
        bottomContent,
    } = props;
    const navigate = useNavigate();

    const [showBorderOnScroll, setBorderOnScroll] = useState(false);

    useEffect(() => {
        function handleScroll() {
            if (isMobile) {
                if (window.scrollY > 56) {
                    if (!showBorderOnScroll) {
                        setBorderOnScroll(true);
                    }
                } else {
                    if (showBorderOnScroll) {
                        setBorderOnScroll(false);
                    }
                }
            }

        }

        window.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', handleScroll);

        };

    }, [window.scrollY]);

    const {classes, cx} = useStyles();

    const theAppbarClass = cx(
        classes.appbar,
        className,
        hideBackButton ? classes.defaultHorizontalMarginWithoutBackButton : classes.defaultHorizontalMargin,
        noMarginBottom ? classes.noMarginBottom : undefined,
        isMobile ? classes.mobileAppbar : undefined,
        showBorderOnScroll ? classes.appbarBorderOnScroll : undefined,
    );

    const theTitleClass = cx(
        classes.title,
        isMobile ? classes.mobileTitle : undefined,
    );

    const OnBack = () => {
        if (customOnBackFunction != null) {
            customOnBackFunction();
        } else {
            if (backRoute == null) {
                throw Error("Provide back route");
            } else {
                navigate(backRoute);
            }
        }
    };

    const contentJSX = children == null ? <></> : children;


    const backJSX = hideBackButton ? null : (
        <Box sx={{mr: 1}}><AppIconButton
            onClick={OnBack}
        >
            {cancelIconBackButton ? <CloseIcon/> : <ArrowLeftIcon/>}
        </AppIconButton>
        </Box>
    );

    return (
        <Box className={theAppbarClass}>
            <Box className={classes.appbarInnerContainer}>
                <Box className={classes.backButtonContainer}>
                    {backJSX}
                </Box>
                <Typography className={theTitleClass}>{title}</Typography>
                <Box className={classes.buttons}>
                    {contentJSX}
                </Box>
            </Box>
            {bottomContent}
        </Box>
    );
}
