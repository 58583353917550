import {makeStyles} from "tss-react/mui";
import {Box, Divider, Theme} from "@mui/material";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {tt} from "../../../core/Localization";
import AppListItem from "../listItems/AppListItem";
import {kAppColors, kContentWidthNarrow} from "../../../styles/AppThemeProcessor";
import AppModal from "./AppModal";
import AppChip from "../chips/AppChip";
import {CheckCircle} from "@mui/icons-material";
import {JobUpdateRepeats} from "../../../generated/graphql/graphql";

export const useStyles = makeStyles()((theme: Theme) => ({
    modalPaper: {
        maxWidth: kContentWidthNarrow,
    },
    removeHorizontalMargins: {
        marginRight: -16,
        marginLeft: -16,
        marginBottom: 8,
    },
    checkMark: {
        background: kAppColors.primary.main,
    },
    chip: {
        flexGrow: 1,
    },
    confirmChip: {
        flexGrow: 1,
    },
    closeButtonContainer: {
        flexGrow: 1,
        display: "flex",
        '.MuiChip-root': {
            margin: "auto",
            minWidth: 190,
        }
    }
}));

export interface IEditRecurringVisitModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onConfirm: (repeats: JobUpdateRepeats) => void;
    modalAboveModals?: boolean;
}

export default function EditRecurringVisitModal(props: IEditRecurringVisitModalProps) {
    const {open, onConfirm, setOpen, modalAboveModals} = props;

    const {classes} = useStyles();

    const [jobUpdateRepeats, setJobUpdateRepeats] = useState(JobUpdateRepeats.Single);

    useEffect(() => {
        if (open) {
            setJobUpdateRepeats(JobUpdateRepeats.Single);
        }
    }, [open]);

    const actionsJSX =
        <>
            <AppChip
                className={classes.confirmChip}
                key={'confirmButtonKey'}
                onClick={(e) => {
                    onConfirm(jobUpdateRepeats);

                    setOpen(false)
                }}
                chipstyle={"primary"}
                label={tt('common.ok')}
            />

            <Box key={'confirmPopupSpaceKey'} pr={2}/>

            <AppChip
                className={classes.chip}
                key={'cancelButtonKey'}
                onClick={(event) => {
                    setOpen(false)
                }}
                chipstyle={"secondary"}
                label={tt('common.cancel')}
            />
        </>;

    return (
        <AppModal
            modalAboveModals={modalAboveModals}
            blurBackdrop={true}
            paperClassName={classes.modalPaper}
            noContentPadding={true}
            title={tt('editRecurringVisitModal.title')}
            open={open}
            centeredHeader={true}
            actions={actionsJSX}>
            {<Box>
                <AppListItem
                    customAvatar={<></>}
                    variant={"smaller-title"}
                    onClick={() => setJobUpdateRepeats(JobUpdateRepeats.Single)}
                    title={tt('editRecurringVisitModal.notRecurringOption')}
                    actionWidget={jobUpdateRepeats !== JobUpdateRepeats.Single ?
                        <></> :
                        <CheckCircle color={"primary"}/>}
                    noMarginBottom={true}
                />

                <Divider/>

                <AppListItem
                    customAvatar={<></>}
                    variant={"smaller-title"}
                    onClick={() => setJobUpdateRepeats(JobUpdateRepeats.ThisAndAllFuture)}
                    title={tt('editRecurringVisitModal.recurringOption')}
                    actionWidget={jobUpdateRepeats !== JobUpdateRepeats.ThisAndAllFuture ?
                        <></> :
                        <CheckCircle color={"primary"}/>}
                />
            </Box>}
            <Box pb={1.75}/>
        </AppModal>
    );
}
