import React, {useContext, useEffect, useId, useRef, useState} from "react";
import {AppContext} from "../../../App";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import {tt} from "../../../core/Localization";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import AppIconButton from "../../components/buttons/AppIconButton";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MoreFilledIcon from "../../../icons/MoreFilledIcon";
import MenuItem from "@mui/material/MenuItem";
import {Box, Divider} from "@mui/material";
import Menu from "@mui/material/Menu";
import AppListItem from "../../components/listItems/AppListItem";
import {usePopupState} from "material-ui-popup-state/hooks";
import UserStatusRow from "../../screenSections/userStatussRow/UserStatusRow";
import SecuredEmailIcon from "../../../icons/SecuredEmailIcon";
import PasswordIcon from "../../../icons/PasswordIcon";
import {
    CreateFileInput,
    FileResponse,
    LoginProvider,
    UpdateUserPhotoInput,
    UserProfileResponse,
    UserResponse
} from "../../../generated/graphql/graphql";
import {UserFullName, UserPhotoUrl} from "../../../service/UserService";
import {useNavigate} from "react-router-dom";
import {kChangeLoginEmailRoute} from "../appbarMenu/ChangeLoginEmailScreen";
import {kChangePasswordRoute} from "../appbarMenu/ChangePasswordScreen";
import DeleteIcon from "../../../icons/DeleteIcon";
import DetailScreenShimmer from "../../components/shimmers/DetailScreenShimmer";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import AppButton from "../../components/buttons/AppButton";
import {kSignOutRoute} from "../authorization/SignOutScreen";
import {kCloseAccountRoute} from "./CloseAccountScreen";
import {appModalsStyles, DisplayCloseAccountConfirm} from "../../components/modals/AppModals";
import {AppDataContext} from "../../../AppData";
import {kMobileMenuRoute} from "../mobile/MobileMenuScreen";
import {processMutationError, processQueryError} from "../../../service/ErrorService";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import PencilIcon from "../../../icons/PencilIcon";
import Icons8Image from "../../../icons/Icons8Image";
import IFileState, {IFileStateType} from "../../../model/FileState";
import {v4 as uuidv4} from "uuid";
import {
    getPublicUrls,
    kStorageCategoryCompanyLogo,
    kStorageCategoryUserPhoto,
    storeFileToFirebase
} from "../../../service/StorageService";
import {genericStyles} from "../../../styles/UtilStyles";
import CropImageModal from "../../components/modals/CropImageModal";
import {SuccessToast} from "../../../service/ToastService";
import {kActionUpdate, kTopicUsers} from "../../../core/constants";

export const kProfileRoute = '/profile';

export default function ProfileScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe, restApiPost} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle, authUser} = appContext;

    const appDataContext = useContext(AppDataContext);

    const navigate = useNavigate();
    const {classes} = appModalsStyles();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<UserProfileResponse | null>();
    useEffect(() => {
        const subscription = subscribe(
            kTopicUsers,
            {
                uri: '/user/profile',
                setLoading,
                onData: (data: UserProfileResponse | NullOrUndefined) => {
                    setData(data);
                },
                onError: (error) => processQueryError(appDataContext, error),
            },
            (notifications) => {
                return notifications.some((notification) => {
                    const uid = notification.data['uid'];

                    return notification.action === kActionUpdate && uid === authUser?.uid;
                });
            },
        );

        return () => {
            subscription.cancel();
        };
    }, [authUser?.uid]);

    useEffect(() => {
        setTitle(tt('common.profile'));
    }, []);

    /**
     * If no request go to account closure else show result modal directly.
     */
    const CloseAccount = () => {
        if (data?.userDeleteRequest?.id) {
            DisplayCloseAccountConfirm(appDataContext, classes);
        } else {
            navigate(kCloseAccountRoute);
        }
    };

    const [updatePhotoLoading, setUpdatePhotoLoading] = useState(false);
    /**
     * After new photo image is uploaded to Firebase, we create file on BE and then add to the User on BE.
     */
    const updatePhoto = (input: CreateFileInput) => {
        restApiPost({
            uri: '/storage',
            params: input,
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading: setUpdatePhotoLoading,
            onData: (data: FileResponse | NullOrUndefined) => {
                if (data) {
                    restApiPost({
                        uri: '/user/photo',
                        params: {
                            customPhotoId: data.id,
                        } as UpdateUserPhotoInput,
                        fetchPolicy: FetchPolicy.NetworkOnly,
                        setLoading: setUpdatePhotoLoading,
                        onData: (data: UserResponse | NullOrUndefined) => {
                            if (data) {
                                SuccessToast(tt('profile.screen.photo.success'));
                            }
                        },
                        onError: (error) => processMutationError(error),
                    });
                }
            },
            onError: (error) => processMutationError(error),
        });
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                loading={loading}
                data={data}
                isMobile={isMobile}
                closeAccount={CloseAccount}
                updatePhoto={updatePhoto}
            />
        );
    }

    return (
        <ResponsiveContainer
            smallPhoneScreen={bodyJSX(true)}
            largePhoneScreen={bodyJSX(true)}
            tabletScreen={bodyJSX()}
            smallDesktopScreen={bodyJSX()}
            largeDesktopScreen={bodyJSX()}
            extraLargeDesktopScreen={bodyJSX()}
        />
    );
}

interface IBodyProps {
    loading: boolean;
    data?: UserProfileResponse | NullOrUndefined;
    isMobile?: boolean;
    closeAccount: VoidFunction;
    updatePhoto: (input: CreateFileInput) => void;
    updatePhotoLoading?: boolean;
}

function Body(props: IBodyProps) {
    const {loading, data, isMobile, closeAccount, updatePhoto, updatePhotoLoading} = props;

    const appDataContext = useContext(AppDataContext);
    const {companyId, storage, setStorage} = appDataContext;

    const navigate = useNavigate();
    const userData = data?.user;

    const {classes: genericClasses} = genericStyles();

    // const theAddress = UserAddressSingle(userData?.address);
    // const addressLink = AddressMapsLink(theAddress);

    const photoUpdateInputRef = useRef<HTMLInputElement>(null);
    const [photoUpdateFileState, setPhotoUpdateFileState] = useState<IFileState>();
    const [image, setImage] = useState<string>();
    const [cropModal, setCropModal] = useState<boolean>(false);

    useEffect(() => {
        if (data?.customPhotoFile) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...((data?.customPhotoFile ? [data?.customPhotoFile] : undefined) || []),
                    ],
                };
            });
        }
    }, [data]);

    const profilePopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const editPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const logoutButton = isMobile ? (
        <Box p={2}>
            <AppButton
                variant={"contained"}
                fullWidth={true}
                color={"error"}
                onClick={() => navigate(kSignOutRoute)}>
                {tt('menu.signOut')}
            </AppButton>
        </Box>
    ) : undefined;

    const contentJSX = loading ? <DetailScreenShimmer/> : (
        <>
            <AppListItem
                title={userData?.name == undefined && userData?.surname == undefined ? userData?.email : UserFullName(userData?.name, userData?.surname) || tt('common.notFilledIn')}
                description={userData?.name == undefined && userData?.surname == undefined ? null : userData?.email}
                profileImage={
                    UserPhotoUrl(userData, data?.customPhotoFile ? [data?.customPhotoFile] : undefined, storage.publicUrlsForFiles)
                }
                actionWidget={<></>}
            />

            <Box sx={{mb: 2}}/>
            <Divider sx={{mb: 0.5}}/>

            <UserStatusRow loginProviders={userData?.loginProviders} noBorder={true}/>

            {/*<AppTabsComponent data={*/}
            {/*    [{*/}
            {/*        label: tt('common.contact'), content:*/}
            {/*            <ContactSection*/}
            {/*                address={theAddress}*/}
            {/*                addressLink={addressLink}*/}
            {/*                email={userData?.contactEmail}*/}
            {/*                phone={userData?.phoneNumber}*/}
            {/*                loginEmail={userData?.email}*/}
            {/*            />*/}
            {/*    }]*/}
            {/*}/>*/}
            {logoutButton}
        </>
    );

    const changePassEmailMenuItemsJSX = userData?.loginProviders.includes(LoginProvider.Email) ? [
            <MenuItem
                key={'profileChangePassKey'} onClick={() => {
                navigate(kChangePasswordRoute);
            }}>
                <PasswordIcon/>
                {tt('common.changePassword')}
            </MenuItem>,
            <MenuItem key={'profileChangeEmailKey'} onClick={() => {
                navigate(kChangeLoginEmailRoute);
            }}>
                <SecuredEmailIcon/>
                {tt('common.changeLoginEmail')}
            </MenuItem>
        ]
        : [];

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}
        >
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    isMobile={isMobile}
                    title={tt('common.profile')}
                    hideBackButton={!isMobile}
                    backRoute={isMobile ? kMobileMenuRoute : undefined}
                >
                    <AppIconButton
                        onClick={(e) => {
                            editPopupState.open(e);
                        }}>
                        <PencilIcon/>
                    </AppIconButton>

                    <AppIconButton key={'profileDetailBtn2'}
                                   {...bindTrigger(profilePopupState)}
                    ><MoreFilledIcon/></AppIconButton>
                </PaperAppbar>

                <Menu {...bindMenu(editPopupState)}>
                    <MenuItem key={'editCompanyImageItem'} onClick={() => {
                        editPopupState.close();
                        if (photoUpdateInputRef.current) {
                            photoUpdateInputRef.current.click();
                        }
                    }}>
                        <Icons8Image/>
                        {tt('common.user.profilePhoto')}
                    </MenuItem>
                </Menu>

                <Menu {...bindMenu(profilePopupState)}>
                    {changePassEmailMenuItemsJSX}

                    <MenuItem key={'profileCloseAccountKey'} onClick={() => {
                        profilePopupState.close();

                        closeAccount();
                    }}>
                        <Box color={kAppColors.red.confirmButton}><DeleteIcon/></Box>
                        {tt('common.closeAccount')}
                    </MenuItem>
                </Menu>

                {contentJSX}

                <input
                    ref={photoUpdateInputRef}
                    className={genericClasses.hidden}
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                        if (e.target.files && e.target.files.length > 0) {
                            const theFile = e.target.files[0];

                            const reader = new FileReader();
                            reader.readAsDataURL(theFile);
                            reader.onload = function (e) {
                                setPhotoUpdateFileState({
                                    type: IFileStateType.Select,
                                    uuid: uuidv4(),
                                    category: kStorageCategoryUserPhoto,
                                    fileAccept: 'image/*',
                                    inputFile: theFile,
                                });

                                setImage(reader.result as string);
                            };
                        }

                        setCropModal(true);
                    }}/>
            </AppPaper>

            <CropImageModal
                open={cropModal}
                setOpen={setCropModal}
                isLoading={photoUpdateFileState?.type == IFileStateType.Upload || updatePhotoLoading}
                onSave={(blob: Blob | null) => {
                    if (blob) {
                        const newFileState: IFileState = {
                            ...photoUpdateFileState!,
                            type: IFileStateType.Upload,
                            overrideFileUpdateData: blob,
                        };

                        setPhotoUpdateFileState(newFileState);

                        storeFileToFirebase({
                            fileState: newFileState,
                            onUpdateFileState: (updateFileState: (fileState: IFileState) => IFileState, fileUploadFinished?: boolean) => {
                                setPhotoUpdateFileState(updateFileState(photoUpdateFileState!));
                            },
                            onDelete: (uuid: string) => {
                            },
                            onComplete: (input: CreateFileInput) => {
                                setPhotoUpdateFileState({
                                    ...photoUpdateFileState!,
                                    type: IFileStateType.File,
                                });

                                updatePhoto(input);

                                setCropModal(false);
                            },
                        });
                    }
                }}
                img={image || ''}
                title={tt('common.user.profilePhoto')}
            />
        </ScreenContent>
    );
}
