import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8HouseFenceProps {
    className?: string;
}

export default function Icons8HouseFence(props: IIcons8HouseFenceProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px"
                 preserveAspectRatio="xMidYMid meet" focusable="false">
                <path
                    d="M 9.9277344 5.0019531 A 1.50015 1.50015 0 0 0 8.8710938 5.5117188 L 5.3710938 9.5117188 A 1.50015 1.50015 0 0 0 5 10.5 L 5 38.5 C 5 40.414955 6.5850452 42 8.5 42 L 11.5 42 C 13.414955 42 15 40.414955 15 38.5 L 15 36 L 19 36 L 19 38.5 C 19 40.414955 20.585045 42 22.5 42 L 25.5 42 C 27.414955 42 29 40.414955 29 38.5 L 29 36 L 33 36 L 33 38.5 C 33 40.414955 34.585045 42 36.5 42 L 39.5 42 C 41.414955 42 43 40.414955 43 38.5 L 43 10.5 A 1.50015 1.50015 0 0 0 42.628906 9.5117188 L 39.128906 5.5117188 A 1.50015 1.50015 0 0 0 37.927734 5.0019531 A 1.50015 1.50015 0 0 0 36.871094 5.5117188 L 33.371094 9.5117188 A 1.50015 1.50015 0 0 0 33 10.5 L 33 16 L 29 16 L 29 10.5 A 1.50015 1.50015 0 0 0 28.628906 9.5117188 L 25.128906 5.5117188 A 1.50015 1.50015 0 0 0 23.927734 5.0019531 A 1.50015 1.50015 0 0 0 22.871094 5.5117188 L 19.371094 9.5117188 A 1.50015 1.50015 0 0 0 19 10.5 L 19 16 L 15 16 L 15 10.5 A 1.50015 1.50015 0 0 0 14.628906 9.5117188 L 11.128906 5.5117188 A 1.50015 1.50015 0 0 0 9.9277344 5.0019531 z M 10 8.7792969 L 12 11.064453 L 12 38.5 C 12 38.795045 11.795045 39 11.5 39 L 8.5 39 C 8.2049548 39 8 38.795045 8 38.5 L 8 11.064453 L 10 8.7792969 z M 24 8.7792969 L 26 11.064453 L 26 38.5 C 26 38.795045 25.795045 39 25.5 39 L 22.5 39 C 22.204955 39 22 38.795045 22 38.5 L 22 11.064453 L 24 8.7792969 z M 38 8.7792969 L 40 11.064453 L 40 38.5 C 40 38.795045 39.795045 39 39.5 39 L 36.5 39 C 36.204955 39 36 38.795045 36 38.5 L 36 11.064453 L 38 8.7792969 z M 15 21 L 19 21 L 19 31 L 15 31 L 15 21 z M 29 21 L 33 21 L 33 31 L 29 31 L 29 21 z"/>
            </svg>
        </span>
    );
}