import {AutoCompleteOption, ITextAutocompleteFieldProps} from "./InputProps";
import React, {useEffect, useRef} from "react";
import {Autocomplete, Box, createFilterOptions, Divider, Grid, TextField, Theme, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {tt} from "../../../core/Localization";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {useStyles as formUseStyles} from "./FormBuilder";
import AppListItemV2 from "../listItems/ApplistItemV2";
import Debouncer from "../../../utils/Debouncer";
import {kThemeAnimationDuration, kUserInputDebounce} from "../../../core/constants";

export const useStyles = makeStyles()((theme: Theme) => ({
    paper: {
        backgroundColor: kAppColors.background.menuPaper(theme.palette.mode === "dark") + ' !important',
        borderRadius: 10,
        backgroundImage: "unset",
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        ul: {
            li: {
                paddingLeft: '16px !important',
                paddingRight: '16px !important',
            },
            'li.liWithListItem': {
                padding: '0 !important',
                marginBottom: '0 !important',
            }
        }
    },
    suffix: {
        marginLeft: theme.spacing(2),
    },
    overlayEndSuffix: {
        position: "absolute",
        right: 0,
        top: 0,
        height: 54,
    },
    container: {
        position: "relative",
        button: {
            width: 54,
            height: 54,
        }
    },
    newValue: {
        color: kAppColors.primary.main,
        fontWeight: 700,
    },
    liNoPadding: {
        padding: "0 !important",
    },
    cursor: {
        cursor: "pointer",
    },
    popper: {
        zIndex: 1320,
    },
    listItem: {
        cursor: "pointer",
        pointerEvents: "none",
    },
    textField: {
        '.MuiInputAdornment-root': {
            margin: '2px 4px 0 4px!important',
        }
    },
    liWithListItem: {
        '.MuiListItem-root .MuiListItemText-root .MuiListItemText-secondary': {
            fontSize: "14px !important",
        },
        borderRadius: "0 !important",
        transition: "background 0.3s linear",
        '&:hover': {
            background: kAppColors.hoverColor.grey(theme.palette.mode === "dark")
        }
    }
}));

export default function TextAutocompleteField(props: ITextAutocompleteFieldProps) {
    const {
        index,
        className,
        label,
        value,
        updateValue,
        autocompleteOptions,
        hidden,
        error,
        errorMessage,
        helperText,
        placeholder,
        paperClass,
        suffixJSX,
        overlayEndSuffixJSX,
        onAutocompleteValueChanged,
        onNewValueAdded,
        freeSolo,
        disabled,
        hideAddNewOption,
        clearOnBlur = true,
        updateOptionsForQuery,
        innerSuffixJSX,
        innerPrefixJSX,
        filterOptions,
    } = props;

    const theFreeSolo = typeof freeSolo === "undefined" ? true : freeSolo;

    const {classes: formClasses} = formUseStyles();
    const {classes, cx} = useStyles();

    const onInputChangedTimeout = useRef(new Debouncer(kThemeAnimationDuration));

    useEffect(() => {
        return () => {
            onInputChangedTimeout.current?.dispose();
        }
    }, []);

    function OnChange(newValue: AutoCompleteOption) {
        updateValue(index, newValue);
    }

    /**
     * If provided updateOptionsForQuery, call with updated text to get new options.
     */
    const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (updateOptionsForQuery) {
            onInputChangedTimeout.current.run(() => {
                updateOptionsForQuery(e.target.value.trim());
            });
        }
    };

    const filter = createFilterOptions<AutoCompleteOption>();

    const suffixJSXContent = suffixJSX ? (
        <Grid item={true} alignItems={"start"} className={classes.suffix}>
            {suffixJSX()}
        </Grid>
    ) : null;

    const theOverlayEndSuffixJSX = overlayEndSuffixJSX ? (
        <Box className={classes.overlayEndSuffix}>
            {overlayEndSuffixJSX(props)}
        </Box>
    ) : null;

    return (
        <div className={cx(className, hidden ? formClasses.hidden : undefined)}>
            <Grid container={true} spacing={0} alignItems="start">
                <Grid item={true} xs={true} className={classes.container}>
                    <Autocomplete
                        disabled={disabled}
                        renderOption={(props, option) => {
                            if (option.icon || option.description) {
                                let index = autocompleteOptions.findIndex(value1 => value1.value === option.value);
                                return <li {...props} key={`${option.value}`} className={cx('liWithListItem', classes.liWithListItem)}>
                                    <AppListItemV2
                                        className={classes.listItem}
                                        title={option.label}
                                        variant={"thin"}
                                        customAvatar={option.icon}
                                        description={option.description}
                                        actionWidget={<></>}
                                    />
                                    {index === autocompleteOptions.length - 1 ? <Box pb={0.5}></Box> :
                                        <Divider/>
                                    }
                                </li>;
                            }

                            return <li {...props} key={`${option.value}`}
                                       className={cx(option.newValue ? classes.newValue : null, classes.cursor)}>{option.label}</li>
                        }}
                        selectOnFocus={true}
                        clearOnBlur={clearOnBlur}
                        filterSelectedOptions={true}
                        handleHomeEndKeys={true}
                        onChange={(event, newValue, reason, details) => {
                            if (typeof newValue === 'string') {
                                OnChange({
                                    label: newValue,
                                    value: newValue,
                                });
                            } else if (newValue.newValue) {
                                OnChange({
                                    label: newValue.newValue,
                                    value: newValue.newValue,
                                });
                                if (onNewValueAdded) {
                                    onNewValueAdded(newValue.newValue);
                                }
                            } else {
                                OnChange(newValue);
                            }

                            if (onAutocompleteValueChanged) {
                                onAutocompleteValueChanged(
                                    event,
                                    newValue,
                                    reason,
                                    details,
                                );
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filterOptions ? filterOptions(options, params) : filter(options, params);

                            const {inputValue} = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.label);
                            if (inputValue !== '' && !isExisting && !hideAddNewOption) {
                                filtered.push({
                                    newValue: inputValue,
                                    label: `${tt('autocomplete.field.add')} "${inputValue}"`,
                                });
                            }

                            return filtered;
                        }}

                        freeSolo={theFreeSolo}
                        componentsProps={{
                            popper: {
                                className: classes.popper,
                            },
                            paper: {
                                className: cx(paperClass, classes.paper),
                            },
                        }}

                        value={value}
                        disableClearable={true}
                        options={autocompleteOptions}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Typography>{option.label}</Typography>
                            ))
                        }

                        renderInput={(params) => (
                            <TextField
                                className={cx(classes.textField, className)}
                                variant={"filled"}
                                error={error}
                                helperText={error ? errorMessage : undefined}
                                {...params}
                                label={label}
                                placeholder={placeholder}
                                onChange={onInputChanged}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: innerSuffixJSX,
                                    startAdornment: innerPrefixJSX,
                                }}
                            />
                        )}
                    />

                    {theOverlayEndSuffixJSX}
                </Grid>

                {suffixJSXContent}
            </Grid>

            {helperText}
        </div>
    );
}
