import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8LockOpenedProps {
    className?: string;
}

export default function Icons8LockOpened(props: IIcons8LockOpenedProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
           <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 48 48" width="24px" height="24px">
            <path
                d="M 24 4 C 19.599415 4 16 7.599415 16 12 L 16 16 L 12.5 16 C 10.032499 16 8 18.032499 8 20.5 L 8 39.5 C 8 41.967501 10.032499 44 12.5 44 L 35.5 44 C 37.967501 44 40 41.967501 40 39.5 L 40 20.5 C 40 18.032499 37.967501 16 35.5 16 L 19 16 L 19 12 C 19 9.220585 21.220585 7 24 7 C 26.647834 7 28.781049 9.0253952 28.978516 11.613281 A 1.5003761 1.5003761 0 1 0 31.970703 11.386719 C 31.656169 7.2646048 28.194166 4 24 4 z M 12.5 19 L 17.253906 19 A 1.50015 1.50015 0 0 0 17.740234 19 L 35.5 19 C 36.346499 19 37 19.653501 37 20.5 L 37 39.5 C 37 40.346499 36.346499 41 35.5 41 L 12.5 41 C 11.653501 41 11 40.346499 11 39.5 L 11 20.5 C 11 19.653501 11.653501 19 12.5 19 z M 24 27 A 3 3 0 0 0 24 33 A 3 3 0 0 0 24 27 z"/>
            </svg>
        </span>
    );
}