import {Box, Menu, MenuItem} from "@mui/material";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import {
    DeleteJobDocument,
    DeleteJobMutationVariables,
    JobDetailResponse,
    UpdateJobResponsiblePersonDocument,
    UpdateJobResponsiblePersonMutationVariables
} from "../../../../generated/graphql/graphql";
import React, {useContext, useEffect, useId, useState} from "react";
import IEventSystemListener from "../../../../model/EventSystemListener";
import {listenToEventSystem, unListenToEventSystem} from "../../../../service/EventSystemService";
import IEventSystemNotification from "../../../../model/firestore/EventSystemNotification";
import {tt} from "../../../../core/Localization";
import {usePopupState} from "material-ui-popup-state/hooks";
import AppIconButton from "../../buttons/AppIconButton";
import PencilIcon from "../../../../icons/PencilIcon";
import MoreFilledIcon from "../../../../icons/MoreFilledIcon";
import EditJobDetailsModalBottomSheet from "../../modals/job/jobDetail/EditJobDetailsModalBottomSheet";
import {UserFullName, UserPhotoUrl} from "../../../../service/UserService";
import AppAvatar from "../../AppAvatar";
import UserIcon from "../../../../icons/UserIcon";
import Icons8TextOneLine from "../../../../icons/Icons8TextOneLine";
import ChooseResponsiblePersonModalBottomSheet from "../../modals/job/editJob/ChooseResponsiblePersonModalBottomSheet";
import {useMutation} from "@apollo/client";
import {processMutationError} from "../../../../service/ErrorService";
import {SuccessToast} from "../../../../service/ToastService";
import {HideConfirmModal, HideVisitDetailModal, SetConfirmModal} from "../../modals/AppModals";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import DeleteIcon from "../../../../icons/DeleteIcon";
import {AppDataContext} from "../../../../AppData";
import {kActionDelete, kActionUpdate, kPermissionsJobs, kTopicJobs} from "../../../../core/constants";
import PermissionValid from "../../permissions/PermissionValid";

export interface IJobDetailMenuProps {
    jobId: number;
    data?: JobDetailResponse | NullOrUndefined;
    onDelete: VoidFunction;
}

export default function JobDetailMenu(props: IJobDetailMenuProps) {
    const {data, onDelete, jobId} = props;

    const appDataContext = useContext(AppDataContext);
    const {storage} = appDataContext;

    const [editDetails, setEditDetails] = useState(false);
    const [bottomSheetResponsiblePerson, setBottomSheetResponsiblePerson] = useState(false);

    const [mutateUpdateJobResponsiblePerson, {
        loading: assignResponsiblePersonLoading,
    }] = useMutation(UpdateJobResponsiblePersonDocument);

    const [mutateDeleteJob, {
        loading: deleteJobLoading,
    }] = useMutation(DeleteJobDocument);

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicJobs,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === jobId;
                });

                if (doDelete) {
                    HideVisitDetailModal(appDataContext);

                    onDelete();
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [onDelete, jobId]);

    /**
     * Update responsible person to the job on BE.
     */
    const updateResponsiblePerson = async (responsiblePersonId: number) => {
        try {
            const variables: UpdateJobResponsiblePersonMutationVariables = {
                input: {
                    jobId,
                    responsiblePersonId,
                },
            };

            const result = await mutateUpdateJobResponsiblePerson({variables});

            if (!result.errors) {
                SuccessToast(tt('jobDetail.menu.toast.responsiblePersonUpdated'));

                setBottomSheetResponsiblePerson(false);
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * Delete job on BE.
     */
    const deleteJob = async () => {
        try {
            const variables: DeleteJobMutationVariables = {
                input: {
                    jobId,
                },
            };

            const result = await mutateDeleteJob({variables});

            if (!result.errors) {
                HideConfirmModal(appDataContext);

                SuccessToast(tt('jobDetail.menu.toast.jobDeleted'));
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    const responsiblePersonJSX = data?.responsiblePerson ? (
        <AppAvatar
            variant="small"
            img={
                UserPhotoUrl(data.responsiblePerson.user, data?.files, storage.publicUrlsForFiles)
            }
            title={UserFullName(data.responsiblePerson.name || data.responsiblePerson.user?.name, data.responsiblePerson.surname || data.responsiblePerson.user?.surname)}
            tooltip={UserFullName(data.responsiblePerson.name || data.responsiblePerson.user?.name, data.responsiblePerson.surname || data.responsiblePerson.user?.surname)}
            // tooltipDescription={UserRole(data.getJobDetail.responsiblePerson.role)}
            tooltipDescription={tt('jobDetail.menu.tooltip.responsiblePerson')}
        />
    ) : undefined;

    const editOptionsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const editMenuJSX = (
        <PermissionValid
            permission={kPermissionsJobs}
            requiredPermissions={[kActionUpdate]}
        >
            <AppIconButton
                tooltip={tt('jobDetail.menu.tooltip.edit')}
                key={'jobDetailEditBtn'}
                onClick={(e) => {
                    editOptionsPopupState.open(e);
                }}
            >
                <PencilIcon/>
            </AppIconButton>
        </PermissionValid>
    );

    return (
        <>
            {responsiblePersonJSX}

            {editMenuJSX}

            <PermissionValid
                permission={kPermissionsJobs}
                requiredPermissions={[kActionDelete]}
            >
                <AppIconButton
                    tooltip={tt('jobDetail.menu.tooltip.more')}
                    key={'jobDetailMoreBtn'}
                    {...bindTrigger(settingsPopupState)}
                >
                    <MoreFilledIcon/>
                </AppIconButton>
            </PermissionValid>

            {/*edit*/}
            <Menu {...bindMenu(editOptionsPopupState)}>
                <PermissionValid
                    permission={kPermissionsJobs}
                    requiredPermissions={[kActionUpdate]}
                >
                    <MenuItem
                        onClick={() => {
                            editOptionsPopupState.close();

                            setEditDetails(true);
                        }}
                    >
                        <Icons8TextOneLine/>
                        {tt('jobDetailMenu.button.editJobDetails')}
                    </MenuItem>
                </PermissionValid>

                <PermissionValid
                    permission={kPermissionsJobs}
                    requiredPermissions={[kActionUpdate]}
                >
                    <MenuItem
                        onClick={() => {
                            editOptionsPopupState.close();

                            setBottomSheetResponsiblePerson(true);
                        }}
                    >
                        <UserIcon/>
                        {tt('jobDetailMenu.button.responsiblePerson')}
                    </MenuItem>
                </PermissionValid>
            </Menu>

            {/*settings*/}
            <Menu {...bindMenu(settingsPopupState)}>
                <PermissionValid
                    permission={kPermissionsJobs}
                    requiredPermissions={[kActionDelete]}
                >
                    <MenuItem key={'jobDetailDeleteKey'} onClick={() => {
                        settingsPopupState.close();

                        SetConfirmModal(appDataContext, {
                            open: true,
                            title: tt('jobDetail.delete.confirmModal.title'),
                            subtitle: tt('jobDetail.delete.confirmModal.subtitle'),
                            confirmationButtonText: tt('common.delete'),
                            cancelButtonText: tt('common.close'),
                            children: <></>,
                            onConfirm: () => deleteJob(),
                        });
                    }}>
                        <Box sx={{color: kAppColors.red.confirmButton}}><DeleteIcon/></Box>
                        {tt('common.delete')}
                    </MenuItem>
                </PermissionValid>
            </Menu>

            <EditJobDetailsModalBottomSheet
                jobId={jobId}
                open={editDetails}
                setOpen={setEditDetails}
                modalAboveModals={true}
                name={data?.job.name}
            />

            <ChooseResponsiblePersonModalBottomSheet
                open={bottomSheetResponsiblePerson}
                setOpen={setBottomSheetResponsiblePerson}
                onSave={updateResponsiblePerson}
                existingChosenPerson={data?.job.responsiblePersonId}
                loading={assignResponsiblePersonLoading}
                modalAboveModals={true}
            />
        </>
    );
}
