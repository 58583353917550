import {CheckBoxProps} from "./InputProps";
import React from "react";
import {useStyles as formUseStyles} from "./FormBuilder";
import {Checkbox, FormControlLabel, Theme} from "@mui/material";
import GreyLabel from "../decorations/GreyLabel";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
    topLabelPadding: {
        paddingTop: 16,
        paddingBottom: 8,
    },
}));

/**
 * Render the component into html.
 */
export default function FormCheckBox(props: CheckBoxProps) {
    const {
        index,
        className,
        label,
        value,
        updateValue,
        hidden,
        error,
        errorMessage,
        onToggleCheckbox,
        disabled,
        topLabel,
    } = props;

    const {classes: formClasses} = formUseStyles();
    const {classes, cx} = useStyles();

    /**
     * OnChange update data of this field.
     */
    const OnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateValue(index, e.target.checked);

        if (onToggleCheckbox) {
            onToggleCheckbox(e.target.checked);
        }
    };

    return (
        <div className={cx(className, (hidden ? formClasses.hidden : undefined))}>
            {topLabel ? <GreyLabel className={classes.topLabelPadding} text={topLabel}/> : null}
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        onChange={OnChange}
                        disabled={disabled}
                    />
                }
                label={label}
                labelPlacement="end"
            />
        </div>
    );
}
