import React, {Dispatch, SetStateAction, SyntheticEvent, useContext, useEffect, useState} from "react";

import {GetProductTypeTitle} from "../../../service/ProductService";
import ModalBottomSheet from "../ModalBottomSheet";
import {Box, Theme} from "@mui/material";
import BottomSheetModalAppbar from "../modals/BottomSheetModalAppbar";
import {tt} from "../../../core/Localization";
import AppButton from "../buttons/AppButton";
import {makeStyles} from "tss-react/mui";
import {AppDataContext} from "../../../AppData";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import {GetProductsInput, ProductResponse, ProductResponsePage, ProductType} from "../../../generated/graphql/graphql";
import {AutocompleteChangeDetails, AutocompleteChangeReason} from "@mui/base/useAutocomplete/useAutocomplete";
import {AddVatToPrice, GetVatAmount, PriceDisplay} from "../../../service/CompanyService";
import {IProductFormUpdate} from "./ProductForm";
import SplitButton from "../buttons/SplitButton";
import {processQueryError} from "../../../service/ErrorService";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import {kTopicProductTemplates} from "../../../core/constants";

export const useStyles = makeStyles()((theme: Theme) => ({
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
    },
    oneFifth: {
        flexBasis: '20% !important',
        "@media (max-width: 767px)": {
            flexBasis: '100% !important',
        }
    }
}));

export interface IAddProductModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onSubmit: (update: IProductFormUpdate, saveAsTemplate: boolean, templateId?: number) => void;
    mutateLoading?: boolean;
}

export default function AddProductModalBottomSheet(props: IAddProductModalBottomSheetProps) {
    const {
        open,
        setOpen,
        onSubmit,
        mutateLoading,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const [selectedProduct, setSelectedProduct] = useState<ProductResponse>();

    const appDataContext = useContext(AppDataContext);
    const {currency, language, companyId, company} = appDataContext;

    const {classes} = useStyles();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ProductResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (open) {
            const subscription = subscribe(
                kTopicProductTemplates,
                {
                    uri: '/product/search-by-company',
                    params: {
                        companyId: companyId!,
                        isTemplate: true,
                    } as GetProductsInput,
                    setLoading,
                    onData: setData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                () => true,
            );

            return () => {
                subscription.cancel();
            };
        } else {
            setData(null);
        }
    }, [open, companyId]);

    const companyHasVat = company?.hasVat || false;

    const [typeInput, setTypeInput] = useState<IInputsData>({
        type: {
            testId: 'serviceTypeInput',
            type: InputType.ChipSwitch,
            label: '',
            value: ProductType.Product,
            options: [
                {label: GetProductTypeTitle(ProductType.Product), value: ProductType.Product},
                {label: GetProductTypeTitle(ProductType.Service), value: ProductType.Service}
            ],
        },
    });

    /**
     * Select existing Product and fill the form.
     */
    const selectProduct = (id: number) => {
        const theProduct = data?.content.find(item => item.id === id);

        if (theProduct) {
            setInputs(prev => {
                return {
                    ...prev,
                    name: {
                        ...prev.name,
                        value: theProduct.name,
                    },
                    quantity: {
                        ...prev.quantity,
                        value: 1,
                    },
                    unitOfMeasure: {
                        ...prev.unitOfMeasure,
                        value: theProduct.unitName || '',
                    },
                    vatRate: {
                        ...prev.vatRate,
                        value: theProduct.vatRate || 0,
                    },
                    price: {
                        ...prev.price,
                        value: theProduct.price || 0,
                    },
                    description: {
                        ...prev.description,
                        value: theProduct.description || '',
                    },
                };
            });
        }
    };

    const [inputs, setInputs] = useState<IInputsData>({
        name: {
            testId: 'addProductNameInput',
            type: InputType.TextAutocomplete,
            label: tt('product.form.label.name') + '*',
            value: '',
            required: true,
            autocompleteOptions: [],
            onAutocompleteValueChanged: (event: SyntheticEvent, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => {
            },
            onNewValueAdded: (value) => {
            },
        },
        quantity: {
            testId: 'addProductQuantityInput',
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('product.form.label.quantity')}`,
            value: '',
            required: false,
            grid: {
                sm: companyHasVat ? 3 : 4,
                xs: 6,
            },
        },
        unitOfMeasure: {
            testId: 'addProductUnitOfMeasureInput',
            type: InputType.Text,
            label: tt('product.form.label.unitOfMeasure'),
            placeholder: tt('product.form.placeholder.unitOfMeasure'),
            value: '',
            required: false,
            grid: {
                sm: companyHasVat ? 3 : 4,
                xs: 6,
            },
        },
        price: {
            testId: 'addProductPriceInput',
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('product.form.label.price')} (${currency})`,
            value: '',
            required: false,
            grid: {
                sm: companyHasVat ? 3 : 4,
                xs: 6,
            },
        },
        vatRate: {
            testId: 'addProductVatRateInput',
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('product.form.label.vatRate')}`,
            value: '',
            hidden: !companyHasVat,
            required: false,
            grid: {
                sm: companyHasVat ? 3 : 4,
                xs: 6,
            },
        },
        description: {
            testId: 'productDescriptionInput',
            type: InputType.ButtonTextField,
            label: tt('common.description'),
            toggleButtonText: tt('common.description'),
            value: '',
            minRows: 4,
            changeThisToCloseInput: 0,
        },
    });

    useEffect(() => {
        if (open) {
            // Reset the form.
            setInputs(prev => {
                return {
                    ...prev,
                    name: {
                        ...prev.name,
                        value: '',
                    },
                    quantity: {
                        ...prev.quantity,
                        value: '',
                    },
                    unitOfMeasure: {
                        ...prev.unitOfMeasure,
                        value: '',
                    },
                    vatRate: {
                        ...prev.vatRate,
                        value: '',
                    },
                    price: {
                        ...prev.price,
                        value: '',
                    },
                    description: {
                        ...prev.description,
                        value: '',
                        changeThisToCloseInput: ++prev.description.changeThisToCloseInput!,
                    },
                };
            });
        }
    }, [open]);

    useEffect(() => {
        const newHidden = !companyHasVat;

        if (inputs.vatRate.hidden !== newHidden) {
            setInputs(prev => {
                return {
                    ...prev,
                    price: {
                        ...prev.price,
                        grid: {
                            sm: companyHasVat ? 3 : 4,
                            xs: 6,
                        },
                    },
                    quantity: {
                        ...prev.quantity,
                        grid: {
                            sm: companyHasVat ? 3 : 4,
                            xs: 6,
                        },
                    },
                    vatRate: {
                        ...prev.vatRate,
                        hidden: !companyHasVat,
                        required: companyHasVat,
                        grid: {
                            sm: companyHasVat ? 3 : 4,
                            xs: 6,
                        },
                    },
                    unitOfMeasure: {
                        ...prev.unitOfMeasure,
                        grid: {
                            sm: companyHasVat ? 3 : 4,
                            xs: 6,
                        },
                    },
                };
            });
        }
    }, [companyHasVat]);

    useEffect(() => {
        if (companyHasVat) {
            const thePrice = parseFloat(inputs.price.value || '0');
            const theVatRate = parseFloat(inputs.vatRate.value || '0');
            const withVAT = AddVatToPrice(thePrice, theVatRate);

            setInputs(prev => {
                return {
                    ...prev,
                    price: {
                        ...prev.price,
                        helperText: `${PriceDisplay(withVAT, currency, language, true)} ${tt('productsForm.includingVat')}`,
                    },
                    vatRate: {
                        ...prev.vatRate,
                        helperText: `${PriceDisplay(GetVatAmount(thePrice, theVatRate), currency, language, true)}`,
                    },
                };
            });
        }
    }, [inputs.vatRate.value, inputs.price.value, companyHasVat]);

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                name: {
                    ...prev.name,
                    onAutocompleteValueChanged: (event: SyntheticEvent, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => {
                        if (reason === "selectOption") {
                            selectProduct(parseInt(value.value));

                            setSelectedProduct(data?.content.find(item => item.id === parseInt(value.value)));
                        }
                    },
                },
            };
        });
    }, [data]);

    useEffect(() => {
        if (data) {
            setInputs(prev => {
                return {
                    ...prev,
                    name: {
                        ...prev.name,
                        autocompleteOptions: data.content
                            .filter(item => item.type === typeInput.type.value)
                            .map(item => {
                                return {
                                    value: item.id,
                                    label: item.name,
                                };
                            }),
                    },
                };
            });
        }
    }, [data, typeInput.type.value]);

    const SubmitForm = (saveAsTemplate: boolean) => {
        if (ValidateForm(inputs, setInputs)) {
            const theName = inputs.name.value;

            if (typeof theName === 'object') {
                inputs.name.value = inputs.name.value.value;
            }

            onSubmit({
                inputs: {
                    ...inputs,
                    type: typeInput.type,
                },
            }, saveAsTemplate, selectedProduct?.id);
        }
    };

    const isNewProduct = selectedProduct === undefined || selectedProduct.name !== inputs.name.value;

    return (
        <>
            <ModalBottomSheet
                blurBackdrop={true}
                open={open}
                setOpen={setOpen}
                hideHeader={true}
                tallOnMobile={true}
            >
                <Box>
                    <BottomSheetModalAppbar
                        noBorderBottom={true}
                        title={tt('addProductModal.title')}
                        onClose={() => setOpen(false)}
                    />

                    <Box pr={2} pl={2}>
                        <FormBuilder
                            inputs={typeInput}
                            setInputs={setTypeInput}
                        />

                        <FormBuilder
                            inputs={inputs}
                            setInputs={setInputs}
                        />
                    </Box>

                    <Box pb={9}/>

                    <Box className={classes.buttonContainer}>
                        {isNewProduct ? (
                            <SplitButton
                                isLoading={mutateLoading}
                                isInModal={true}
                                onChange={
                                    (index) => {
                                        SubmitForm(true);
                                    }
                                }
                                onClick={(index, value) => {
                                    SubmitForm(false);
                                }}
                                text={tt('common.save')}
                                options={[
                                    tt('addProductModal.saveAsBoth'),
                                ]}
                            />
                        ) : (
                            <AppButton
                                variant={"contained"}
                                fullWidth={true}
                                isLoading={mutateLoading}
                                onClick={() => SubmitForm(false)}>
                                {tt('common.save')}
                            </AppButton>
                        )}
                    </Box>
                </Box>
            </ModalBottomSheet>
        </>
    );
}
