import {SelectProps} from "./InputProps";
import {useStyles as formUseStyles} from "./FormBuilder";
import React from "react";
import {MenuItem, TextField, Theme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import GreyLabel from "../decorations/GreyLabel";


export const useStyles = makeStyles()((theme: Theme) => ({
    select: {
        '.MuiSelect-select': {
            height: '20px !important',
        }
    },
    topLabelPadding: {
        paddingTop: 16,
        paddingBottom: 16,
    }
}));


/**
 * Form component for dropdown select.
 */
export default function Select(props: SelectProps) {
    const {
        index,
        className,
        label,
        value,
        updateValue,
        options,
        onChange,
        hidden,
        error,
        errorMessage,
        helperText,
        readOnly,
        testId,
        topLabel,
        disabled,
    } = props;

    const {classes: formClasses, cx} = formUseStyles();
    const {classes} = useStyles();

    /**
     * OnChange update data of this field.
     */
    const OnChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateValue(index, e.target.value);

        if (onChange) {
            onChange(index, e.target.value);
        }
    };

    const menuItems = options.map(option => (
        <MenuItem key={`${index}-${option.value}`} value={option.value}>{option.icon}{option.label}</MenuItem>
    ));

    const helperTextJSX = error ? errorMessage : helperText;

    return (
        <div className={cx(className, (hidden ? formClasses.hidden : undefined), classes.select)}>
            {topLabel ? <GreyLabel className={classes.topLabelPadding} text={topLabel}/> : null}
            <TextField
                data-testid={testId}
                label={label}
                disabled={readOnly || disabled}
                value={value}
                variant="filled"
                error={error}
                helperText={helperTextJSX}
                fullWidth={true}
                margin="none"
                select={true}
                onChange={OnChangeField}
            >
                {menuItems}
            </TextField>
        </div>
    );
}
