import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8LeftProps {
    className?: string;
}

export default function Icons8Left(props: IIcons8LeftProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg id="Icons-sm" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <rect id="Rectangle_28" data-name="Rectangle 28" width="28" height="28" fill="none"/>
                <g id="icons8-left" transform="translate(4 5)">
                    <path fill="currentColor" id="icons8-left_1_" data-name="icons8-left (1)"
                          d="M14.1,25.452a1.111,1.111,0,0,0,1.571-1.571L9.793,18h15.1a1.111,1.111,0,1,0,0-2.222H9.793L15.675,9.9A1.111,1.111,0,1,0,14.1,8.326L6.326,16.1a1.111,1.111,0,0,0,0,1.571Z"
                          transform="translate(-6 -8)"/>
                </g>
            </svg>
        </span>
    );
}