import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8HereProps {
    className?: string;
}

export default function Icons8Here(props: IIcons8HereProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 21.5 4 C 18.480226 4 16 6.4802259 16 9.5 L 16 20 L 10.5 20 A 1.50015 1.50015 0 0 0 9.4199219 22.541016 L 22.919922 36.541016 A 1.50015 1.50015 0 0 0 25.080078 36.541016 L 38.580078 22.541016 A 1.50015 1.50015 0 0 0 37.5 20 L 32 20 L 32 9.5 C 32 6.4802259 29.519774 4 26.5 4 L 21.5 4 z M 21.5 7 L 26.5 7 C 27.898226 7 29 8.1017741 29 9.5 L 29 21.5 A 1.50015 1.50015 0 0 0 30.5 23 L 33.96875 23 L 24 33.339844 L 14.03125 23 L 17.5 23 A 1.50015 1.50015 0 0 0 19 21.5 L 19 9.5 C 19 8.1017741 20.101774 7 21.5 7 z M 12.955078 29.398438 C 8.3140781 30.705437 5 32.920813 5 36.007812 C 5 41.259813 14.558 44.007812 24 44.007812 C 33.442 44.007812 43 41.259813 43 36.007812 C 43 32.920813 39.685922 30.705437 35.044922 29.398438 L 32.568359 31.875 C 37.253359 32.906 40 34.657813 40 36.007812 C 40 38.046813 33.767 41.007812 24 41.007812 C 14.233 41.007812 8 38.046813 8 36.007812 C 8 34.657812 10.746641 32.906 15.431641 31.875 L 12.955078 29.398438 z"/>
            </svg>
        </span>
    );
}

