import {makeStyles} from "tss-react/mui";
import {Drawer, List, Theme, Toolbar, useTheme} from "@mui/material";
import React, {useContext, useId, useMemo, useState} from "react";
import {kDashboardRoute} from "../../screens/dashboard/DashboardScreen";
import HomeIcon from "../../../icons/HomeIcon";
import {useNavigate} from "react-router-dom";
import {tt} from "../../../core/Localization";
import DrawerNavigationButton from "../buttons/DrawerNavigationButton";
import WorkersIcon from "../../../icons/WorkersIcon";
import ClockIcon from "../../../icons/ClockIcon";
import AddIcon from "../../../icons/AddIcon";
import {usePopupState} from "material-ui-popup-state/hooks";
import Menu from "@mui/material/Menu";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MenuItem from "@mui/material/MenuItem";
import UserIcon from "../../../icons/UserIcon";
import HammerIcon from "../../../icons/HammerIcon";
import WorkerIcon from "../../../icons/WorkerIcon";
import {kJobsRoute} from "../../screens/jobs/JobsScreen";
import {kClientsRoute} from "../../screens/clients/ClientsScreen";
import {kWorkersRoute} from "../../screens/workers/WorkersScreen";
import {kTimeSheetsRoute} from "../../screens/timesheets/TimeSheetsScreen";
import CreateWorkerModal from "../workers/CreateWorkerModal";
import {AppDataContext} from "../../../AppData";
import {EmployeeRole} from "../../../generated/graphql/graphql";
import CreateClientModal from "../clients/CreateClientModal";
import Icons8ClipBoardList from "../../../icons/Icons8ClipBoardList";
import Icons8More from "../../../icons/Icons8More";
import {kJobFormsRoute} from "../../screens/jobForms/JobFormsScreen";
import Icons8AddShoppingCart2 from "../../../icons/Icons8AddShoppingCart2";
import {DisplaySubscriptionUpgradeModal} from "../../../service/CompanyService";
import {kProductsRoute} from "../../screens/products/ProductsListScreen";
import Icons8Product from "../../../icons/Icons8Product";
import {kVisitsRoute} from "../../screens/jobs/visits/VisitsScreen";
import ScheduleEvent from "../../../icons/ScheduleEvent";
import {newJobAndVisitRoute} from "../../screens/jobs/NewJobAndVisitScreen";
import {kMaterialsRoute} from "../../screens/materials/MaterialsListScreen";
import Icons8Layers from "../../../icons/Icons8Layers";
import PermissionValid, {hasSomePermissions} from "../permissions/PermissionValid";
import {
    kActionCreate,
    kActionUpdate,
    kActionView,
    kPermissionsClients,
    kPermissionsCompanySubscription,
    kPermissionsForms,
    kPermissionsJobs,
    kPermissionsMaterials,
    kPermissionsProducts,
    kPermissionsTimesheets,
    kPermissionsWorkers
} from "../../../core/constants";
import Icons8Contacts from "../../../icons/Icons8Contacts";

export const drawerWidth = 68;

const useStyles = makeStyles()((theme: Theme) => ({
    drawer: {
        paddingTop: 8,
        width: drawerWidth,
    },
    list: {
        paddingTop: theme.spacing(2),
        width: drawerWidth,
    },
    li: {
        display: 'flex',
        marginBottom: theme.spacing(1),
        justifyContent: "center",
    },
    addPopupMenu: {
        '.MuiPaper-root': {
            top: '71px !important',
            left: '75px !important',
        }
    },
    morePopupMenu: {
        '.MuiPaper-root': {
            left: '75px !important',
            top: '495px !important',
        }
    }
}));

/**
 * Main Navigation Drawer component.
 */
export default function NavigationDrawer() {
    const navigate = useNavigate();
    const theme = useTheme();

    const appDataContext = useContext(AppDataContext);
    const {
        companyId,
        companyData,
        companyEmployeeRole,
        companySubscriptionStatus,
        employeePermissionsMap
    } = appDataContext;

    const limitedDrawer = !companyId || !companyData.subscriptionIsValid || companyEmployeeRole === EmployeeRole.Worker;

    const {classes, cx} = useStyles();
    const [createWorkerModal, setCreateWorkerModal] = useState<boolean>(false);
    const [createClientModal, setCreateClientModal] = useState<boolean>(false);

    const drawerListItems = useMemo(() => {
        return NavigationDrawerItems(limitedDrawer).map(item => {
            let isSelected = (item.exact && item.to === window.location.pathname) || (!item.exact && window.location.pathname.indexOf(item.to) === 0);

            return item.permission ? (
                <PermissionValid
                    key={item.to}
                    permission={item.permission}
                >
                    <li className={classes.li}>
                        <DrawerNavigationButton
                            testId={item.testId}
                            isSelected={isSelected}
                            tooltip={item.text}
                            placement={"right"}
                            onClick={() => navigate(item.to)}>
                            {item.icon}
                        </DrawerNavigationButton>
                    </li>
                </PermissionValid>
            ) : (
                <li key={item.to} className={classes.li}>
                    <DrawerNavigationButton
                        testId={item.testId}
                        isSelected={isSelected}
                        tooltip={item.text}
                        placement={"right"}
                        onClick={() => navigate(item.to)}>
                        {item.icon}
                    </DrawerNavigationButton>
                </li>
            );
        });
    }, [limitedDrawer, window.location.pathname]);

    const addMenuPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const moreMenuPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const addJSX = !limitedDrawer ? (
        <li className={classes.li}>
            <DrawerNavigationButton
                testId={'drawerButtonAddMenu'}
                tooltip={tt('navigation.addNew')}
                placement={"right"}
                variant={"highlighted"}
                forceIconColor={theme.palette.primary.main}
                {...bindTrigger(addMenuPopupState)}
            >
                <AddIcon/>
            </DrawerNavigationButton>
        </li>
    ) : null;

    const moreMenu = !limitedDrawer && hasSomePermissions([{
        permission: kPermissionsForms,
        requiredPermissions: [kActionView],
    }, {
        permission: kPermissionsProducts,
        requiredPermissions: [kActionView],
    }, {
        permission: kPermissionsMaterials,
        requiredPermissions: [kActionView],
    }], employeePermissionsMap) ? (
        <li className={classes.li}>
            <DrawerNavigationButton
                testId={'drawerButtonMoreMenu'}
                tooltip={tt('navigation.more')}
                placement={"right"}
                {...bindTrigger(moreMenuPopupState)}
            >
                <Icons8More/>
            </DrawerNavigationButton>
        </li>
    ) : null;

    const currentUsers = companySubscriptionStatus?.currentUsers || 0;
    const maxUsers = companySubscriptionStatus?.maxUsers || 0;
    const showUpgradeButton = currentUsers >= maxUsers;

    return (
        <Drawer className={classes.drawer} variant="permanent">
            <Toolbar/>
            <List className={classes.list}>
                {addJSX}

                {drawerListItems}

                {moreMenu}
            </List>

            <Menu className={classes.addPopupMenu} {...bindMenu(addMenuPopupState)}>
                <PermissionValid
                    permission={kPermissionsJobs}
                    requiredPermissions={[kActionCreate]}
                >
                    <MenuItem onClick={() => {
                        addMenuPopupState.close();
                        navigate(newJobAndVisitRoute({}));
                    }}>
                        <HammerIcon/>
                        {tt('menu.create_job')}
                    </MenuItem>
                </PermissionValid>

                <PermissionValid
                    permission={kPermissionsClients}
                    requiredPermissions={[kActionCreate]}
                >
                    <MenuItem onClick={() => {
                        addMenuPopupState.close();
                        setCreateClientModal(true);
                    }
                    }>
                        <UserIcon/>
                        {tt('menu.create_client')}
                    </MenuItem>
                </PermissionValid>

                {showUpgradeButton ? (
                    <PermissionValid
                        permission={kPermissionsCompanySubscription}
                        requiredPermissions={[kActionUpdate]}
                    >
                        <MenuItem onClick={() => {
                            addMenuPopupState.close();

                            DisplaySubscriptionUpgradeModal(
                                appDataContext,
                                currentUsers,
                                maxUsers,
                            );
                        }}>
                            <Icons8AddShoppingCart2/>
                            {tt('menu.subscription_upgrade')}
                        </MenuItem>
                    </PermissionValid>
                ) : (
                    <PermissionValid
                        permission={kPermissionsWorkers}
                        requiredPermissions={[kActionCreate]}
                    >
                        <MenuItem onClick={() => {
                            addMenuPopupState.close();
                            setCreateWorkerModal(true);
                        }}>
                            <WorkerIcon/>
                            {tt('menu.create_worker')}
                        </MenuItem>
                    </PermissionValid>
                )}
            </Menu>

            <Menu className={classes.morePopupMenu} {...bindMenu(moreMenuPopupState)}>
                <PermissionValid
                    permission={kPermissionsForms}
                >
                    <MenuItem onClick={() => {
                        moreMenuPopupState.close();
                        navigate(kJobFormsRoute);
                    }}>
                        <Icons8ClipBoardList/>
                        {tt('drawerMoreMenu.job_forms')}
                    </MenuItem>
                </PermissionValid>

                <PermissionValid
                    permission={kPermissionsProducts}
                >
                    <MenuItem onClick={() => {
                        moreMenuPopupState.close();
                        navigate(kProductsRoute);
                    }}>
                        <Icons8Product/>
                        {tt('drawerMoreMenu.products')}
                    </MenuItem>
                </PermissionValid>

                <PermissionValid
                    permission={kPermissionsMaterials}
                >
                    <MenuItem onClick={() => {
                        moreMenuPopupState.close();
                        navigate(kMaterialsRoute);
                    }}>
                        <Icons8Layers/>
                        {tt('drawerMoreMenu.materials')}
                    </MenuItem>
                </PermissionValid>
            </Menu>

            <CreateWorkerModal setOpen={setCreateWorkerModal} open={createWorkerModal}/>

            <CreateClientModal setOpen={setCreateClientModal} open={createClientModal}/>
        </Drawer>
    );
}

interface IDrawerItem {
    text: string;
    testId: string;
    icon: React.ReactNode;
    to: string;
    exact?: boolean;
    permission?: string;
}

/**
 * Create list of items for navigation.
 */
function NavigationDrawerItems(limited: boolean): IDrawerItem[] {
    if (limited) {
        return [
            {
                testId: 'drawerButtonDashboard',
                text: tt('navigation.dashboard'),
                icon: <HomeIcon/>,
                to: kDashboardRoute,
                exact: true,
            },
        ];
    }

    return [
        {
            testId: 'drawerButtonDashboard',
            text: tt('navigation.dashboard'),
            icon: <HomeIcon/>,
            to: kDashboardRoute,
            exact: true,
        },
        {
            testId: 'drawerButtonCalendar',
            text: tt('navigation.visits'),
            icon: <ScheduleEvent/>,
            to: kVisitsRoute,
            permission: kPermissionsJobs,
        },
        {
            testId: 'drawerButtonJobs',
            text: tt('navigation.jobs'),
            icon: <HammerIcon/>,
            to: kJobsRoute,
            permission: kPermissionsJobs,
        },
        {
            testId: 'drawerButtonClients',
            text: tt('navigation.clients'),
            icon: <Icons8Contacts/>,
            to: kClientsRoute,
            permission: kPermissionsClients,
        },
        {
            testId: 'drawerButtonWorkers',
            text: tt('navigation.workers'),
            icon: <WorkersIcon/>,
            to: kWorkersRoute,
            permission: kPermissionsWorkers,
        },
        {
            testId: 'drawerButtonTimesheet',
            text: tt('navigation.timesheets'),
            icon: <ClockIcon/>,
            to: kTimeSheetsRoute,
            permission: kPermissionsTimesheets,
        },
    ];
}
