import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material";
import {kAppColors} from "./AppThemeProcessor";

export const genericStyles = makeStyles()((theme: Theme) => ({
    hidden: {
        display: "none",
    },
}));

export const linkLikeButtonStyle = makeStyles()((theme: Theme) => ({
    linkButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
        borderRadius: 50,
        overflow: "hidden",
        height: 44,
        width: 44,
        background: kAppColors.background.contactIconButton(theme.palette.mode === "dark"),
        color: kAppColors.text.black,
        'svg': {
            color: kAppColors.text.primary(theme.palette.mode === 'dark'),
        },
        ':hover': {
            backgroundColor: kAppColors.hoverColor.contactIconButton(theme.palette.mode === "dark"),
        },
    },
    noHrefLink: {
        'svg': {
            color: kAppColors.text.primary(theme.palette.mode === 'dark'),
        },
        background: "transparent",
        pointerEvents: "none",
        ':hover': {
            background: "transparent",
        }
    },
}));

export const bottomSheetContainerStyles = makeStyles()((theme: Theme) => ({
    container: {
        maxHeight: '80vh',
        height: '100vh',
        overflow: "auto",
        boxShadow: "none",
    },
}));

export const boxContentStyles = makeStyles()((theme: Theme) => ({
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export const inputShimmerStyles = makeStyles()((theme: Theme) => ({
    inputShimmer: {
        marginBottom: 20,
        borderRadius: 8,
    },
}));
