import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import FullSizedContainer from "../../components/FullSizedContainer";
import AppPaper from "../../components/paper/AppPaper";
import {AppContext} from "../../../App";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {tt} from "../../../core/Localization";
import {Box, Grid, Link, Theme, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../components/form/FormBuilder";
import AppButton from "../../components/buttons/AppButton";
import {kWelcomeRoute} from "../WelcomeScreen";
import {useNavigate, useSearchParams} from "react-router-dom";
import ContentPadding from "../../components/paper/ContentPadding";
import LogoWithText from "../../components/LogoWithText";
import LanguagesMenu from "../../components/menus/LanguagesMenu";
import TextDivider from "../../components/decorations/TextDivider";
import SocialButton from "../../components/buttons/SocialButton";
import Icons8Google from "../../../icons/Icons8Google";
import Icon8Facebook from "../../../icons/Icons8Facebook";
import Icons8AppleLogo from "../../../icons/Icons8AppleLogo";
import {makeStyles} from "tss-react/mui";
import {kDashboardRoute} from "../dashboard/DashboardScreen";
import {createUserWithEmailAndPassword, getAuth} from "firebase/auth";
import {AppDataContext} from "../../../AppData";
import {
    AuthUserSignInSocial,
    AuthUserSignInSocialRedirectResult,
    FirebaseAuthErrorMessage,
    LinkAuthProvidersCancel,
    LinkAuthProvidersStart
} from "../../../service/AuthUserService";
import {LoginProvider} from "../../../generated/graphql/graphql";
import {kAppColors, kContentWidthNarrow} from "../../../styles/AppThemeProcessor";
import DarkModeMenu from "../../components/menus/DarkModeMenu";
import {FlavorByEnvironment} from "../../../flavor-config";
import {trackDataLayerEvent} from "../../../service/AnalyticsService";
import {checkAppVersion, clearLastCheckedVersionAt} from "../../../service/AppVersionService";
import InfoSection from "../../components/infos/InfoSection";

export const kSignUpRoute = '/sign-up';

export const useStyles = makeStyles()((theme: Theme) => ({
        socialButton: {
            marginRight: 4,
            marginLeft: 4,
            marginBottom: 8,
        },
        termsTextStyle: {
            fontWeight: 500,
            color: kAppColors.grey.link,
            lineHeight: 18 / 14,
        }
    })
);

export default function SignUpScreen() {
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);

    useEffect(() => {
        setTitle(tt('signUp.screen.title'));
    }, []);

    useEffect(() => {
        AuthUserSignInSocialRedirectResult(appDataContext);
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            clearLastCheckedVersionAt();

            checkAppVersion(appDataContext);
        }, 1);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <ResponsiveContainer
            smallPhoneScreen={<Body isMobile={true}/>}
            largePhoneScreen={<Body isMobile={true}/>}
            tabletScreen={<Body/>}
            smallDesktopScreen={<Body/>}
            largeDesktopScreen={<Body/>}
            extraLargeDesktopScreen={<Body/>}/>
    );
}

interface IBodyProps {
    isMobile?: boolean;
}

function Body(props: IBodyProps) {
    const {isMobile} = props;

    const appContext = useContext(AppContext);
    const {authUser, setCurrentLoginProvider} = appContext;

    const appDataContext = useContext(AppDataContext);

    const navigate = useNavigate();
    const {classes} = useStyles();

    const [searchParams, setSearchParams] = useSearchParams();

    const prefillEmail = searchParams.get('email');

    const [loading, setLoading] = useState<boolean>(false);

    const [inputs, setInputs] = useState<IInputsData>({
        email: {
            type: InputType.Text,
            label: `${tt('common.email')}*`,
            value: prefillEmail || '',
            required: true,
            requireEmail: true,
        },
        password: {
            type: InputType.Password,
            label: `${tt('common.password')}*`,
            value: '',
            required: true,
            requireMinLength: 8,
            validateOnChange: true,
        },
    });

    /**
     * SignUp user using any supported provider with Firebase Auth.
     */
    const SignUp = async (provider: LoginProvider) => {
        trackDataLayerEvent('new_sign_up');

        LinkAuthProvidersCancel();

        setCurrentLoginProvider(provider);

        setLoading(true);

        if (provider === LoginProvider.Email) {
            if (ValidateForm(inputs, setInputs)) {
                try {
                    await createUserWithEmailAndPassword(
                        getAuth(),
                        inputs.email.value,
                        inputs.password.value,
                    );
                } catch (e) {
                    console.error(e);

                    if (FirebaseAuthErrorMessage(e, true)) {
                        LinkAuthProvidersStart(appDataContext, e);
                    }
                }
            }
        }

        AuthUserSignInSocial(
            appDataContext,
            provider
        );

        setLoading(false);
    };

    useEffect(() => {
        if (authUser && authUser.signInResponse && !authUser.signInResponse.error && authUser.signInResponse.data) {
            navigate(kDashboardRoute);
        }
    }, [authUser]);

    useEffect(() => {
        const shouldShowPassWordError = inputs.password.value.length > 0 && inputs.password.value.length < 8;
        const shouldHidePassError = inputs.password.value.length == 0 || inputs.password.value.length > 7;

        if (shouldShowPassWordError) {
            setInputs(
                prev => {
                    return {
                        ...prev,
                        password: {
                            ...prev.password,
                            error: true,
                            errorMessage: `${tt('common.isNotValidMinLength').replace('$length', `${inputs.password.requireMinLength}`)}.`
                        }
                    };
                }
            );
        }

        if (shouldHidePassError) {
            setInputs(
                prev => {
                    return {
                        ...prev,
                        password: {
                            ...prev.password,
                            error: false,
                            errorMessage: undefined,
                        }
                    };
                }
            )
        }
    }, [inputs]);

    return (
        <FullSizedContainer topAlignedContent={isMobile}>
            <Box sx={{maxWidth: isMobile ? undefined : kContentWidthNarrow}}>
                <InfoSection />
            </Box>

            <AppPaper
                showPaddingTop={true}
                sx={{maxWidth: isMobile ? undefined : kContentWidthNarrow}}>
                <PaperAppbar
                    isMobile={isMobile}
                    title={tt('signUp.screen.title')}
                    backRoute={kWelcomeRoute}
                    children={<><LanguagesMenu/><DarkModeMenu/></>}/>
                <ContentPadding isNarrow={isMobile}>
                    <LogoWithText
                        description={tt('signUp.screen.freTrialText')}
                        primaryColorDescription={true}/>
                    <FormBuilder inputs={inputs} setInputs={setInputs}/>

                    <AppButton
                        sx={{mb: 2}}
                        onClick={() => SignUp(LoginProvider.Email)}
                        fullWidth={true}
                        variant="contained"
                        isLoading={loading}
                    >{tt('signUp.screen.signUp')}</AppButton>
                    <Box pb={3.5}/>
                    <TextDivider text={tt('common.dividerText.or')}/>
                    <Box pb={3.5}/>
                    <Grid container justifyContent={"center"}>
                        <SocialButton
                            fullWidth={isMobile}
                            onClick={() => SignUp(LoginProvider.Google)}
                            className={classes.socialButton} text={tt('common.google')}
                            icon={<Icons8Google/>}/>
                        <SocialButton
                            fullWidth={isMobile}
                            onClick={() => SignUp(LoginProvider.Facebook)}
                            className={classes.socialButton} text={tt('common.facebook')}
                            icon={<Icon8Facebook/>}/>
                        <SocialButton
                            fullWidth={isMobile}
                            onClick={() => SignUp(LoginProvider.Apple)}
                            className={classes.socialButton} text={tt('common.apple')}
                            icon={<Icons8AppleLogo/>}/>
                    </Grid>

                    {/*<Box pb={2}/>

                    <Box sx={{textAlign: 'center'}}>
                        <NavLink to={alternativeSignInRoute(true)}>
                            {tt('navigate.alternativeSignIn')}
                        </NavLink>
                    </Box>*/}

                    <Box pb={3}/>

                    <Typography className={classes.termsTextStyle}
                                align={"center"}>{tt('signUp.screen.termsAgreementText')}</Typography>
                    <Box sx={{textAlign: 'center'}}>
                        <Link
                            href={FlavorByEnvironment()!.termsAndConditions}
                            target={'_blank'}>{tt('common.termsAndConditions')}</Link>
                    </Box>
                </ContentPadding>
            </AppPaper>
        </FullSizedContainer>
    );
}
