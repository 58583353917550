import ResponsiveContainer from "../components/screens/ResponsiveContainer";
import FullSizedContainer from "../components/FullSizedContainer";
import AppPaper from "../components/paper/AppPaper";
import {Box, Theme} from "@mui/material";
import {tt} from "../../core/Localization";
import AppButton from "../components/buttons/AppButton";
import {AppContext} from "../../App";
import {useNavigate} from "react-router-dom";
import {kSignInRoute} from "./authorization/SignInScreen";
import {kSignUpRoute} from "./authorization/SignUpScreen";
import React, {useContext, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import {kDashboardRoute} from "./dashboard/DashboardScreen";
import PaperAppbar from "../components/paper/PaperAppbar";
import LanguagesMenu from "../components/menus/LanguagesMenu";
import LogoWithText from "../components/LogoWithText";
import ContentPadding from "../components/paper/ContentPadding";
import {kContentWidthNarrow} from "../../styles/AppThemeProcessor";
import DarkModeMenu from "../components/menus/DarkModeMenu";
import {checkAppVersion, clearLastCheckedVersionAt} from "../../service/AppVersionService";
import {AppDataContext} from "../../AppData";
import InfoSection from "../components/infos/InfoSection";


export const kWelcomeRoute = '/welcome';

export const useStyles = makeStyles()((theme: Theme) => ({
    outerContainer: {
        maxWidth: kContentWidthNarrow + 'px !important',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    paper: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    mobile: {
        flexGrow: 1,
    }
}));

export default function WelcomeScreen() {
    const appContext = useContext(AppContext);
    const {setTitle, authUser} = appContext;

    const appDataContext = useContext(AppDataContext);

    const navigate = useNavigate();

    useEffect(() => {
        setTitle(tt('welcome.screen.title'));
    }, []);

    useEffect(() => {
        if (authUser) {
            navigate(kDashboardRoute);
        }
    }, [authUser]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            clearLastCheckedVersionAt();

            checkAppVersion(appDataContext);
        }, 2000);

        return () => clearTimeout(timeout);
    }, []);

    return (<ResponsiveContainer
            smallPhoneScreen={<Mobile/>}
            largePhoneScreen={<Mobile/>}
            tabletScreen={<Body/>}
            smallDesktopScreen={<Body/>}
            largeDesktopScreen={<Body/>}
            extraLargeDesktopScreen={<Body/>}
        />
    );
}

function Body() {
    const navigate = useNavigate();

    const {classes, cx} = useStyles();

    return (
        <FullSizedContainer>
            <Box className={cx(classes.outerContainer)}>
                <InfoSection/>

                <AppPaper
                    showPaddingTop={true}
                    centerContent={false}
                >
                    <PaperAppbar
                        title={''}
                        hideBackButton={true}
                        children={<><LanguagesMenu/><DarkModeMenu/></>}
                    />
                    <ContentPadding>
                        <LogoWithText/>
                        <AppButton
                            onClick={() => navigate(kSignUpRoute)}
                            fullWidth={true}
                            variant={"contained"}>{tt('welcome.screen.button.signUp')}</AppButton>
                        <Box sx={{mb: 1}}/>
                        <AppButton
                            onClick={() => navigate(kSignInRoute)}
                            fullWidth={true}>{tt('welcome.screen.button.signIn')}</AppButton>
                    </ContentPadding>
                </AppPaper>
            </Box>

        </FullSizedContainer>
    );
}

function Mobile() {
    const navigate = useNavigate();

    return (
        <FullSizedContainer topAlignedContent={true}>
            <InfoSection/>

            <AppPaper>
                <PaperAppbar
                    isMobile={true}
                    title={''}
                    hideBackButton={true}
                    children={<><LanguagesMenu/><DarkModeMenu/></>}/>
                <ContentPadding isNarrow={true} isMobile={true} centered={true}>
                    <LogoWithText/>
                    <AppButton
                        onClick={() => navigate(kSignUpRoute)}
                        fullWidth={true}
                        variant={"contained"}>{tt('welcome.screen.button.signUp')}</AppButton>
                    <Box sx={{mb: 1}}/>
                    <AppButton
                        onClick={() => navigate(kSignInRoute)}
                        fullWidth={true}>{tt('welcome.screen.button.signIn')}</AppButton>
                </ContentPadding>
            </AppPaper>
        </FullSizedContainer>
    );
}
