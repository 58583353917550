import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8BugProps {
    className?: string;
}

export default function Icons8Bug(props: IIcons8BugProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 19.476562 4.9785156 A 1.50015 1.50015 0 0 0 18 6.5 L 18 8 C 18 9.2644391 18.399446 10.441134 19.076172 11.412109 C 16.284331 12.374377 14.230218 14.941893 14.025391 18 L 13.5 18 C 10.996501 18 9 16.003499 9 13.5 L 9 10.5 A 1.50015 1.50015 0 0 0 7.4765625 8.9785156 A 1.50015 1.50015 0 0 0 6 10.5 L 6 13.5 C 6 17.624501 9.3754991 21 13.5 21 L 14 21 L 14 24.5 L 5.5 24.5 A 1.50015 1.50015 0 1 0 5.5 27.5 L 14 27.5 L 14 30.708984 C 14 30.806764 14.010947 30.902682 14.013672 31 L 13.5 31 C 9.3754991 31 6 34.375499 6 38.5 L 6 41.5 A 1.50015 1.50015 0 1 0 9 41.5 L 9 38.5 C 9 35.996501 10.996501 34 13.5 34 L 14.542969 34 C 15.800074 37.79048 19.152215 40.707067 23.337891 40.978516 C 28.071838 41.284486 32.187029 38.245933 33.533203 34 L 34.5 34 C 37.003499 34 39 35.996501 39 38.5 L 39 41.5 A 1.50015 1.50015 0 1 0 42 41.5 L 42 38.5 C 42 34.375499 38.624501 31 34.5 31 L 34 31 L 34 27.5 L 42.5 27.5 A 1.50015 1.50015 0 1 0 42.5 24.5 L 34 24.5 L 34 21 L 34.5 21 C 38.624501 21 42 17.624501 42 13.5 L 42 10.5 A 1.50015 1.50015 0 0 0 40.476562 8.9785156 A 1.50015 1.50015 0 0 0 39 10.5 L 39 13.5 C 39 16.003499 37.003499 18 34.5 18 L 33.974609 18 C 33.769782 14.941893 31.715669 12.374377 28.923828 11.412109 C 29.600554 10.441134 30 9.2644391 30 8 L 30 6.5 A 1.50015 1.50015 0 1 0 27 6.5 L 27 8 C 27 9.6741348 25.674135 11 24 11 C 22.325865 11 21 9.6741348 21 8 L 21 6.5 A 1.50015 1.50015 0 0 0 19.476562 4.9785156 z M 21.5 14 L 24 14 L 26.5 14 C 29.003499 14 31 15.996501 31 18.5 L 31 19.253906 A 1.50015 1.50015 0 0 0 31 19.740234 L 31 25.753906 A 1.50015 1.50015 0 0 0 31 26.240234 L 31 31 C 31 35.039108 27.642041 38.250069 23.53125 37.984375 C 19.833978 37.744601 17 34.481401 17 30.708984 L 17 26.246094 A 1.50015 1.50015 0 0 0 17 25.759766 L 17 19.746094 A 1.50015 1.50015 0 0 0 17 19.259766 L 17 18.5 C 17 15.996501 18.996501 14 21.5 14 z"/>
            </svg>
        </span>
    );
}
