import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import AppAvatar from "../AppAvatar";
import {tt} from "../../../core/Localization";
import React, {useContext} from "react";
import {EmployeeJoinedUserResponse, FileResponse} from "../../../generated/graphql/graphql";
import {AppDataContext} from "../../../AppData";
import {UserFullName, UserPhotoUrl} from "../../../service/UserService";
import {RestApiClientContext} from "../../../core/RestApiProvider";

const useStyles = makeStyles()((theme: Theme) => ({
    avatarsContainer: {
        paddingRight: 16,
        display: 'flex',
        paddingLeft: 86,
        paddingBottom: 8,
        alignItems: "center",
        overflowX: "auto",
    },
    verticalDivider: {
        width: 2,
        height: 22,
        background: kAppColors.text.primary(theme.palette.mode === "dark"),
        marginRight: 8,
    },
    opacity: {
        opacity: 0.5,
    },
}));

export interface IListItemAvatarsWidgetProps {
    offerTotalPrice: number;
    substituteCount?: number;
    substituteEmployeeIds?: number[];
    employeeIds?: number[];
    rejectedEmployeeIds?: number[];
    ignoreRejectedEmployees?: boolean;
    isNewJob?: boolean;
    jobEmployeeData?: EmployeeJoinedUserResponse[] | NullOrUndefined;
    files?: FileResponse[] | NullOrUndefined;
}

export default function ListItemAvatarsWidget(props: IListItemAvatarsWidgetProps) {
    const {
        offerTotalPrice,
        substituteCount,
        substituteEmployeeIds,
        employeeIds,
        rejectedEmployeeIds,
        ignoreRejectedEmployees,
        isNewJob,
        jobEmployeeData,
        files,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {storage} = appDataContext;

    const {classes, cx} = useStyles();

    let substituteAvatarsJSX: React.ReactNode[] = [];

    if (jobEmployeeData) {
        substituteAvatarsJSX = jobEmployeeData
            .filter((employee) => substituteEmployeeIds?.includes(employee.id))
            .map((employee) => {
                return (
                    <Box mr={1} key={employee.id}
                    >
                        <AppAvatar
                            img={
                                UserPhotoUrl(employee.user, files, storage.publicUrlsForFiles)
                            }
                            tooltip={`${tt('avatar.tooltip.jobOfferSeat.substitute')}: ${UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}`}
                            title={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                            tooltipDescription={isNewJob ? null : tt('avatar.tooltip.waitingToBeFilled')}
                            variant={"small"}
                        />
                    </Box>
                );
            });
    }

    if (substituteAvatarsJSX.length < (substituteCount || 0)) {
        for (let i = substituteAvatarsJSX.length; i < (substituteCount || 0); i++) {
            substituteAvatarsJSX.push(
                <Box mr={1} key={`backup-${i}`}>
                    <AppAvatar
                        img={null}
                        tooltip={`${tt('common.backupWorker')} ${i + 1}`}
                        tooltipDescription={isNewJob ? null : tt('avatar.tooltip.waitingToBeFilled')}
                        title={'? ?'}
                        variant={"small"}
                    />
                </Box>
            );
        }
    }

    let employeeAvatarsJSX: React.ReactNode[] = [];

    if (jobEmployeeData) {
        employeeAvatarsJSX = jobEmployeeData
            .filter((employee) => {
                if (ignoreRejectedEmployees) {
                    return employeeIds?.includes(employee.id) && !substituteEmployeeIds?.includes(employee.id);
                }

                return (employeeIds?.includes(employee.id) || rejectedEmployeeIds?.includes(employee.id)) && !substituteEmployeeIds?.includes(employee.id);
            })
            .map((employee) => {
                const rejected = rejectedEmployeeIds?.includes(employee.id);

                return (
                    <Box mr={1} key={employee.id}
                         className={rejected ? classes.opacity : undefined}
                    >
                        <AppAvatar
                            img={
                                UserPhotoUrl(employee.user, files, storage.publicUrlsForFiles)
                            }
                            tooltip={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                            tooltipDescription={isNewJob || rejected ? undefined : tt('avatar.tooltip.waitingForAnswer')}
                            tooltipRedDescription={rejected ? tt('avatar.tooltip.rejectedOffer') : undefined}
                            title={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                            variant={"small"}
                        />
                    </Box>
                );
            });
    }

    return (
        <>
            {
                substituteAvatarsJSX.length > 0 || employeeAvatarsJSX.length > 0
                    ? <Box className={cx('styledScrollbar', classes.avatarsContainer)}>
                        {substituteAvatarsJSX}

                        {substituteAvatarsJSX.length > 0 && employeeAvatarsJSX.length > 0 ?
                            <Box className={classes.verticalDivider}/> : null}

                        {employeeAvatarsJSX}
                    </Box> : null}
        </>
    );
}
