import {iconUseStyles} from "../styles/IconStyles";

export interface ISignOutIconProps {
    className?: string;
}

export default function SignOutIcon(props: ISignOutIconProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px"
                 preserveAspectRatio="xMidYMid meet" focusable="false">
                <path
                    d="M 24 4 C 12.972292 4 4 12.972292 4 24 C 4 35.027708 12.972292 44 24 44 C 30.348948 44 36.015633 41.02311 39.675781 36.400391 A 1.50015 1.50015 0 1 0 37.324219 34.537109 C 34.208367 38.47239 29.413052 41 24 41 C 14.593708 41 7 33.406292 7 24 C 7 14.593708 14.593708 7 24 7 C 29.413052 7 34.208367 9.5276097 37.324219 13.462891 A 1.50015 1.50015 0 1 0 39.675781 11.599609 C 36.015633 6.9768903 30.348948 4 24 4 z M 36.484375 16.484375 A 1.50015 1.50015 0 0 0 35.439453 19.060547 L 38.876953 22.498047 L 18.501953 22.480469 A 1.50015 1.50015 0 1 0 18.498047 25.480469 L 38.880859 25.498047 L 35.439453 28.939453 A 1.50015 1.50015 0 1 0 37.560547 31.060547 L 43.560547 25.060547 A 1.50015 1.50015 0 0 0 43.560547 22.939453 L 37.560547 16.939453 A 1.50015 1.50015 0 0 0 36.484375 16.484375 z"></path>
            </svg>
        </span>
    );
}






















