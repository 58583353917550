import { useContext, useEffect, useState } from "react";
import { tt } from "../../../../../core/Localization";
import { FetchPolicy, RestApiClientContext } from "../../../../../core/RestApiProvider";
import { FileResponsePage, GetFilesByIdsInput, JobUpdateRepeats, UpdateVisitAttachmentsInput, VisitDetailResponse } from "../../../../../generated/graphql/graphql";
import Icons8Plus from "../../../../../icons/Icons8Plus";
import IFileState, { IFileStateType } from "../../../../../model/FileState";
import HeadlineWithButton from "../../../../screenSections/detailListPreviewSection/HeadlineWithButton";
import {v4 as uuidv4} from "uuid";
import { kActionCreate, kPermissionsVisitAttachments, kTopicStorage } from "../../../../../core/constants";
import { processMutationError, processQueryError } from "../../../../../service/ErrorService";
import { AppDataContext } from "../../../../../AppData";
import { kStorageCategoryJobDocuments } from "../../../../../service/StorageService";
import AttachmentListItem from "../../attachments/AttachmentListItem";
import PreviewFileModal from "../../../modals/documents/PreviewFileModal";
import { SuccessToast } from "../../../../../service/ToastService";

export interface IVisitDetailAttachmentsTabProps {
    canEdit: boolean;
    visitId: number;
    repeatingDay?: number;
    data: NullOrUndefined | VisitDetailResponse;
}

/**
 * Component for tab of Visit attachments for the visit detail view.
 */
export default function VisitDetailAttachmentsTab(props: IVisitDetailAttachmentsTabProps) {
    const {canEdit, visitId, repeatingDay, data} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe, restApiPost} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const [documents, setDocuments] = useState<IFileState[]>([]);
    const [documentsModal, setDocumentsModal] = useState<boolean>(false);
    const [documentsModalIndexOnOpen, setDocumentsModalIndexOnOpen] = useState<number>(0);

    const [getFilesDocumentsLoading, setGetFilesDocumentsLoading] = useState<boolean>(false);
    const [getFilesDocumentsData, setGetFilesDocumentsData] = useState<FileResponsePage | NullOrUndefined>();
    useEffect(() => {
        const fileIds = (data?.visit.repeatingDayData ? data?.visit.repeatingDayData.attachmentFileIds : data?.visit?.attachmentFileIds) || [];
        
        if (fileIds.length > 0) {
            const subscription = subscribe(
                kTopicStorage,
                {
                    uri: '/storage/search',
                    params: {
                        fileIds,
                    } as GetFilesByIdsInput,
                    setLoading: setGetFilesDocumentsLoading,
                    onData: (data) => {
                        setGetFilesDocumentsData(data);

                        if (data) {
                            setDocuments((data as FileResponsePage).content.map(document => ({
                                type: IFileStateType.File,
                                uuid: document.uuid,
                                companyId: document.companyId,
                                category: document.category,
                                fileAccept: 'image/*,application/pdf',
                                data: document,
                            })));
                        } else {
                            setDocuments([]);
                        }
                    },
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                () => true,
            );

            return () => subscription.cancel();
        } else {
            setGetFilesDocumentsData(null);
            setDocuments([]);
        }
    }, [data]);

    const [updateLoading, setUpdateLoading] = useState<boolean>(false);
    /**
     * Mutate update Attachments to BE.
     */
    const updateAttachments = async (repeats: JobUpdateRepeats, attachmentFileIds: number[], isRemovalTypeUpdate?: boolean) => {
        restApiPost({
            uri: '/job/visit/update-attachments',
            params: {
                visitId,
                repeatingDay,
                repeats,
                attachmentFileIds,
            } as UpdateVisitAttachmentsInput,
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading: setUpdateLoading,
            onData: (data) => {
                if (data) {
                    SuccessToast(isRemovalTypeUpdate ? tt('visitDetailContent.updateAttachments.remove.success') : tt('visitDetailContent.updateAttachments.success'));
                }
            },
            onError: (error: any) => processMutationError(error),
        });
    };

    return (
        <>
            <HeadlineWithButton
                title={tt('common.attachment')}
                iconFileUploadJSX={<Icons8Plus/>}
                fileUploadText={tt('common.upload')}
                fileAccept={'image/*,application/pdf'}
                fileChosen={canEdit ? (e: React.ChangeEvent<HTMLInputElement>) => {
                    const htmlInput = e.target;

                    if (htmlInput.files && htmlInput.files.length > 0) {
                        const newDocuments = [...documents];

                        for (let i = 0; i < htmlInput.files.length; i++) {
                            newDocuments.push({
                                type: IFileStateType.Select,
                                uuid: uuidv4(),
                                companyId: companyId,
                                category: kStorageCategoryJobDocuments,
                                fileAccept: 'image/*,application/pdf',
                                inputFile: htmlInput.files[i],
                            });
                        }

                        setDocuments(newDocuments);
                    }
                } : undefined}
                fileMultiple={true}
                filePermission={kPermissionsVisitAttachments}
                fileRequiredPermissions={[kActionCreate]}
            />

            {documents.map((document, index) => {
                return (
                    <AttachmentListItem
                        key={index}
                        fileState={document}
                        onUpdateFileState={(updateFileState: (fileState: IFileState) => IFileState, fileUploadFinished?: boolean) => {
                            setDocuments(prev => {
                                const newDocuments = prev.map((item) => {
                                    if (item.uuid === document.uuid) {
                                        return updateFileState(item);
                                    }

                                    return item;
                                });

                                setTimeout(() => {
                                    const hasAnyNotDone = newDocuments.find(document => document.type !== IFileStateType.File);

                                    if (!hasAnyNotDone && fileUploadFinished) {
                                        updateAttachments(
                                            JobUpdateRepeats.Single,
                                            newDocuments
                                                .filter(document => document.data?.id)
                                                .map(document => document.data!.id!),
                                        );
                                    }
                                }, 1);

                                return newDocuments;
                            });
                        }}
                        onDelete={(uuid: string) => {
                            setDocuments(prev => {
                                const newDocuments = prev.filter((item) => item.uuid !== uuid);

                                setTimeout(() => {
                                    updateAttachments(
                                        JobUpdateRepeats.Single,
                                        newDocuments
                                            .filter(document => document.data?.id)
                                            .map(document => document.data!.id!),
                                        true,
                                    );
                                }, 1);

                                return newDocuments;
                            });
                        }}
                        onPreview={(uuid: string) => {
                            const index = documents.findIndex((item) => item.uuid === uuid);

                            setDocumentsModalIndexOnOpen(index);

                            setDocumentsModal(true);
                        }}
                        canEdit={canEdit}
                    />
                );
            })}

            <PreviewFileModal
                onClose={() => setDocumentsModal(false)}
                open={documentsModal}
                fileStates={documents}
                indexOnOpen={documentsModalIndexOnOpen}
            />
        </>
    );
}
