import {
    Address,
    GpsLocationResponse,
    GpsLocationStatus,
    JobEmployeeStatus
} from "../../../../../generated/graphql/graphql";
import {Box, Theme, Tooltip, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {kJobEmployeeStatusGpsDistanceValid} from "../../../../../core/constants";
import {tt} from "../../../../../core/Localization";
import {useContext} from "react";
import {AppDataContext} from "../../../../../AppData";
import {GoogleMapsLink, kLocationDiagnosisDocumentLink} from "../../../../../service/MapsService";
import {displayDistance} from "../../../../../utils/Location";
import {kAppColors} from "../../../../../styles/AppThemeProcessor";
import Icons8UserLocation from "../../../../../icons/Icons8UserLocation";
import {DateTime} from "luxon";
import {GpsLocationStatusToIndex} from "../../../../../service/JobService";

const useStyles = makeStyles()((theme: Theme) => ({
    tooltip: {
        textAlign: "center",
    },
    text: {
        fontWeight: 700,
        fontSize: 12,
    },
    dateStyle: {
        marginLeft: 6,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontSize: 10,
        fontWeight: 600,
    },
    link: {
        textDecoration: 'none',
        ':hover .gpsChip': {
            background: theme.palette.mode === "dark" ? kAppColors.hoverColor.contactIconButton(theme.palette.mode === "dark") : kAppColors.grey.hexaDFE1E5,
        }
    },
    chip: {
        transition: 'background 0.3s linear',
        background: theme.palette.mode === "dark" ? kAppColors.background.contactIconButton(theme.palette.mode === "dark") : kAppColors.grey.secondaryChipBg,
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
        minHeight: 30,
        display: "flex",
        alignItems: 'center',
        paddingTop: 4,
        paddingBottom: 4,
        borderRadius: 8,
        paddingRight: 8,
        paddingLeft: 8,
        marginBottom: 8,
        'div, svg': {
            height: '20px !important',
            width: '20px !important',
        },
        'div': {
            marginRight: 4,
        }
    }
}));

export interface IJobEmployeeStatusGpsLocationProps {
    status: JobEmployeeStatus;
    gpsLocation?: GpsLocationResponse | NullOrUndefined;
    distance: number;
    targetAddress?: Address;
}

/**
 * Component to display check with employee status and GPS location.
 */
export default function VisitEmployeeStatusGpsLocation(props: IJobEmployeeStatusGpsLocationProps) {
    const {status, gpsLocation, distance, targetAddress} = props;

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const {classes, cx} = useStyles();

    const tooltipText = status === JobEmployeeStatus.InProgress ? tt('visitDetail.gpsLocation.tooltip.recordedOnStart') : tt('visitDetail.gpsLocation.tooltip.recordedWhenFinished');

    const isOk = gpsLocation && gpsLocation.latitude && gpsLocation.longitude;

    let iconColor = kAppColors.grey.hexa70;
    if (gpsLocation && isOk) {
        if (distance < kJobEmployeeStatusGpsDistanceValid) {
            iconColor = kAppColors.green.main;
        } else {
            iconColor = kAppColors.red.main;
        }
    }

    const iconJSX = <Box sx={{color: iconColor}}><Icons8UserLocation/></Box>;

    const unknownGPSText = gpsLocation ?
        tt('visitDetail.gpsLocation.unknown.withCode')
            .replace('{code}', GpsLocationStatusToIndex(gpsLocation.status)) :
        tt('visitDetail.gpsLocation.unknown');

    const distanceText = isOk ? displayDistance(distance, language) : unknownGPSText;

    const chip = (
        <Box className={cx(classes.chip, 'gpsChip')}>
            {iconJSX}

            <Typography
                className={classes.text}>
                {`${status === JobEmployeeStatus.InProgress ? tt('visitDetail.gpsLocation.label.gpsStart') : tt('visitDetail.gpsLocation.label.gpsEnd')}:
                ${distanceText}`}

                {gpsLocation?.createdAt ? (
                    <span className={classes.dateStyle}>
                        {DateTime.fromMillis(gpsLocation?.createdAt).toFormat('H:mm · d.MM.yyyy')}
                    </span>
                ) : ''}
            </Typography>
        </Box>
    );

    const content = isOk ? (
        <a
            className={classes.link}
            href={
                targetAddress?.latitude && targetAddress?.longitude ?
                    GoogleMapsLink(
                        {latitude: gpsLocation.latitude!, longitude: gpsLocation.longitude!},
                        {latitude: targetAddress.latitude, longitude: targetAddress.longitude},
                        'walking',
                    ) :
                    GoogleMapsLink({latitude: gpsLocation.latitude!, longitude: gpsLocation.longitude!})
            }
            target="_blank"
        >{chip}</a>
    ) : (
        <a
            className={classes.link}
            href={kLocationDiagnosisDocumentLink}
            target="_blank"
        >{chip}</a>
    );

    return (
        <Tooltip
            title={
                <Box className={classes.tooltip}>{tooltipText}</Box>
            }
        >
            {content}
        </Tooltip>
    );
}
