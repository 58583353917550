import AppVersion, {AppType} from "../model/firestore/AppVersion";
import {getFirestore, collection, query, where, orderBy, limit, getDocs} from "firebase/firestore";
import {DateTime} from "luxon";
import packageJson from "../../package.json";
import {SetAppVersionModal} from "../ui/components/modals/AppModals";
import {IAppDataContext} from "../AppData";

const kAppVersionsCollection = 'app_versions';

/**
 * Query the latest version of the app from Firestore.
 */
export async function queryLatestAppVersion(): Promise<AppVersion | null> {
    const firestore = getFirestore();
    const theCollection = collection(firestore, kAppVersionsCollection);

    const now = DateTime.now().toMillis();

    const theQuery = query(
        theCollection,
        where('app', '==', AppType.Web),
        where('createdAt', '<=', now),
        orderBy('createdAt', 'desc'),
        limit(1),
    )

    const snapshot = await getDocs(theQuery);

    if (snapshot.empty) {
        return null;
    }

    const doc = snapshot.docs[0];

    return {
        ...doc.data(),
        id: doc.id,
    } as AppVersion;
}

let lastCheckedAt: number = 0;
const kTenMinutes = 1000 * 60 * 10;

/**
 * Compare latest app version with latest version on Firestore.
 * If there is newer version, display update dialog.
 */
export async function checkAppVersion(appDataContext: IAppDataContext): Promise<void> {
    const now = DateTime.now().toMillis();

    if (now - lastCheckedAt < kTenMinutes) {
        return;
    }
    lastCheckedAt = now;

    try {
        const latestAppVersion = await queryLatestAppVersion();

        if (latestAppVersion) {
            const latestVersionParts = latestAppVersion.version.split('.');
            const currentVersionParts = packageJson.version.split('.');

            const latestMajor = parseInt(latestVersionParts[0]);
            const latestMinor = parseInt(latestVersionParts[1]);
            const latestPatch = parseInt(latestVersionParts[2]);
            const currentMajor = parseInt(currentVersionParts[0]);
            const currentMinor = parseInt(currentVersionParts[1]);
            const currentPatch = parseInt(currentVersionParts[2]);

            if (latestMajor > currentMajor) {
                SetAppVersionModal(appDataContext, {
                    open: true,
                    version: latestAppVersion.version,
                });
            } else if (latestMajor === currentMajor && latestMinor > currentMinor) {
                SetAppVersionModal(appDataContext, {
                    open: true,
                    version: latestAppVersion.version,
                });
            } else if (latestMajor === currentMajor && latestMinor === currentMinor && latestPatch > currentPatch) {
                SetAppVersionModal(appDataContext, {
                    open: true,
                    version: latestAppVersion.version,
                });
            }
        }
    } catch (e) {
        console.error(e);
    }
}

/**
 * Clear lastCheckedVersionAt (lastCheckedAt) to force another check.
 */
export function clearLastCheckedVersionAt(): void {
    lastCheckedAt = 0;
}
