import {iconUseStyles} from "../styles/IconStyles";


export interface IIcons8GooglePlayProps {
    className?: string;
}

export default function Icons8GooglePlay(props: IIcons8GooglePlayProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg id="icons8-google-play" xmlns="http://www.w3.org/2000/svg" width="20" height="22.835"
                 viewBox="0 0 20 22.835">
                <path id="Path_107" data-name="Path 107"
                      d="M7.4,4.043a.568.568,0,0,0-.4.616V26.342a.477.477,0,0,0,.286.489L18.81,15.373Z"
                      transform="translate(-7 -4.023)" fill="#4db6ac"/>
                <path id="Path_108" data-name="Path 108"
                      d="M22.286,12.2,17.542,9.465,8.324,4.143A.83.83,0,0,0,7.7,4.028l11.408,11.33Z"
                      transform="translate(-7.302 -4.008)" fill="#dce775"/>
                <path id="Path_109" data-name="Path 109"
                      d="M8.024,35.258l14.2-8.2-3.2-3.176L7.5,35.341A.622.622,0,0,0,8.024,35.258Z"
                      transform="translate(-7.214 -12.533)" fill="#d32f2f"/>
                <path id="Path_110" data-name="Path 110"
                      d="M35.514,21.05c-.455-.245-4.626-2.67-4.626-2.67l-.035-.02-3.173,3.154,3.2,3.176,4.6-2.657C36.021,21.722,35.969,21.3,35.514,21.05Z"
                      transform="translate(-15.871 -10.164)" fill="#fbc02d"/>
            </svg>
        </span>
    );
}