import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import React, {useContext} from "react";
import AppModal from "./AppModal";
import {tt} from "../../../core/Localization";
import AppChip from "../chips/AppChip";
import {kAppColors, kContentWidthNarrow} from "../../../styles/AppThemeProcessor";
import {AppDataContext} from "../../../AppData";
import {FlavorByEnvironment} from "../../../flavor-config";
import {HideUpgradeSubscriptionModal} from "./AppModals";

export const useStyles = makeStyles()((theme: Theme) => ({
    modalPaper: {
        maxWidth: kContentWidthNarrow,
    },
    chip: {
        flexGrow: 1,
    },
    closeButtonContainer: {
        flexGrow: 1,
        display: "flex",
    },
    confirmChip: {
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 40,
        paddingLeft: 4,
        paddingRight: 4,
        fontSize: 14,
        height: 36,
        fontWeight: 600,
        transition: 'background-color 0.3s linear',
        background: kAppColors.primary.main,
        color: kAppColors.text.white,
        textDecoration: "none",
        'svg': {
            color: kAppColors.text.tertiary(theme.palette.mode == "dark"),
        },
        ':hover': {
            backgroundColor: kAppColors.hoverColor.main,
        },
    },
    buttonsContainer: {
        width: '100%',
        display: "flex",
    }
}));

export interface IUpgradeSubscriptionModalProps {
    open: boolean;
    currentUsers?: number;
    maxUsers?: number;
}

export default function ConfirmationModal(props: IUpgradeSubscriptionModalProps) {
    const {
        open,
        currentUsers,
        maxUsers,
    } = props;

    const appDataContext = useContext(AppDataContext);

    const {classes} = useStyles();

    const actionsJSX =
        <Box className={classes.buttonsContainer}>
            <a
                href={`mailto:${FlavorByEnvironment()!.contactEmail}`}
                className={classes.confirmChip}
                key={'confirmButtonKey'}
            ><Box>{tt('upgradeModal.button.contactUs')}</Box></a>

            <Box key={'confirmPopupSpaceKey'} pr={2}/>

            <Box key={'cancelButtonKey'} className={classes.closeButtonContainer}>
                <AppChip
                    className={classes.chip}
                    onClick={(event) => {
                        HideUpgradeSubscriptionModal(appDataContext);
                    }}
                    chipstyle={"secondary"}
                    label={tt('common.close')}
                />
            </Box>
        </Box>;

    return (
        <AppModal
            blurBackdrop={true}
            paperClassName={classes.modalPaper}
            title={tt('upgradeModal.title').replace('$current', currentUsers).replace('$total', maxUsers)}
            subtitle={<span dangerouslySetInnerHTML={
                {
                    __html: `${tt('upgradeModal.description').replace('$contactEmail',
                        `<strong style="color: ${kAppColors.primary.main}">${FlavorByEnvironment()!.contactEmail}</strong>`)}`
                }
            }/>}
            open={open}
            centeredHeader={true}
            actions={actionsJSX}>
            <Box pb={1.75}/>
        </AppModal>
    );
}

