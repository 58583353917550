import {iconUseStyles} from "../styles/IconStyles";
import {Theme, useTheme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../styles/AppThemeProcessor";

export const useStyles = makeStyles()((theme: Theme) => ({
    iconColor: {
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
    }
}));


export interface IIcons8AppleLogoProps {
    className?: string;
}

export default function Icons8AppleLogo(props: IIcons8AppleLogoProps) {
    const {className} = props;

    const {classes: iconClasses, cx} = iconUseStyles();
    const {classes} = useStyles();

    const theClassName = cx(
        iconClasses.icon,
        className,
        classes.iconColor,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="20" height="23.275" viewBox="0 0 20 23.275">
                <g id="icons8-apple-logo" transform="translate(0)">
                    <path id="icons8-apple-logo-2" data-name="icons8-apple-logo"
                          d="M25,18.077A16.712,16.712,0,0,1,23.49,20.9c-.982,1.494-2.368,3.36-4.079,3.372-1.524.014-1.917-.994-3.987-.976s-2.5,1-4.024.98c-1.714-.016-3.024-1.7-4.006-3.188C4.646,16.9,4.356,12,6.054,9.388a6.079,6.079,0,0,1,4.886-2.937c1.818,0,2.961,1,4.463,1,1.459,0,2.346-1,4.449-1a5.938,5.938,0,0,1,4.471,2.36A5.118,5.118,0,0,0,25,18.077Zm-6.746-13.3A5.1,5.1,0,0,0,19.389,1a5.517,5.517,0,0,0-3.558,1.913A4.743,4.743,0,0,0,14.667,6.6,4.639,4.639,0,0,0,18.254,4.779Z"
                          transform="translate(-5 -1)"/>
                </g>
            </svg>
        </span>
    );
}












