import {makeStyles} from "tss-react/mui";
import {Box, Theme, Typography} from "@mui/material";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import React, {useContext, useEffect} from "react";
import AppPaper from "../../components/paper/AppPaper";
import {tt} from "../../../core/Localization";
import {AppContext} from "../../../App";
import PaperAppbar from "../../components/paper/PaperAppbar";
import LanguagesMenu from "../../components/menus/LanguagesMenu";
import DarkModeMenu from "../../components/menus/DarkModeMenu";
import {useParams} from "react-router-dom";
import {EmployeeRole} from "../../../generated/graphql/graphql";
import InvitationCodeSection from "../../components/invitation/InvitationCodeSection";
import TimotyLogo from "../../../icons/TimotyLogo";
import {SuccessToast} from "../../../service/ToastService";
import AppButton from "../../components/buttons/AppButton";
import Icons8Copy from "../../../icons/Icons8Copy";
import AppIconButton from "../../components/buttons/AppIconButton";
import Icons8QRCode from "../../../icons/Icons8QRCode";
import {FlavorByEnvironment} from "../../../flavor-config";
import {invitationInstructionsRoute} from "./InvitationInstructionsScreen";

export const kInvitationRoute = '/invitation/:role/:code/:fullName';

/**
 * Create navigation route for this screen.
 */
export function invitationRoute(role: number, code: string, fullName: string): string {
    return kInvitationRoute
        .replace(':role', `${role}`)
        .replace(':code', `${code}`)
        .replace(':fullName', `${fullName}`);
}

export const useStyles = makeStyles()((theme: Theme) => ({
    outerContainer: {
        width: '100%',
        maxWidth: kContentWidthMedium,
        margin: "auto",
        "@media (max-width: 767px)": {
            marginTop: 0,
        },
    },
    paper: {
        width: '100%',
        maxWidth: kContentWidthMedium,
        paddingTop: 8,
        "@media (max-width: 767px)": {
            paddingTop: 0,
            marginTop: 0,
            marginBottom: 0,
        },
    },
    contentContainer: {
        width: '100%',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    name: {
        paddingTop: 20,
        fontSize: 24,
        fontWeight: 700,
        marginBottom: 30,
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
        textAlign: "center",
        strong: {
            fontWeight: 700,
        }
    },
    text: {
        fontWeight: 500,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        textAlign: "center",
    },
    buttons: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        flexFlow: "wrap",
        '.MuiButton-contained': {
            borderRadius: 100,
            height: 44,
        },
    },
    codeSpan: {
        cursor: "pointer",
        color: kAppColors.primary.main,
        fontWeight: "bold",
    },
}));

export default function InvitationScreen() {
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    useEffect(() => {
        setTitle(tt('invitation.screen.title'));
    }, []);

    return (
        <ResponsiveContainer
            smallPhoneScreen={<Body isMobile={true}/>}
            largePhoneScreen={<Body isMobile={true}/>}
            tabletScreen={<Body/>}
            smallDesktopScreen={<Body/>}
            largeDesktopScreen={<Body/>}
            extraLargeDesktopScreen={<Body/>}
        />);
}


interface IBodyProps {
    isMobile?: boolean;
}

function Body(props: IBodyProps) {
    const {isMobile} = props;

    const {classes, cx} = useStyles();

    const {role, code, fullName} = useParams();

    const urlWithParams = `${FlavorByEnvironment()!.webUrl}${invitationInstructionsRoute(parseInt(role!), code!)}`;

    const instructionsWithCode = `${role === EmployeeRole.Worker ? tt('inviteWorkerModal.multilineTextfield.content') : tt('inviteWorkerModal.multilineTextfield.content.notEmployee')} ${urlWithParams}`;


    const step1JSX = <>
        <Typography className={classes.title}>{tt('inviteWorkerModal.step1.downloadApp')}</Typography>
        <Typography className={classes.text}>{tt('inviteWorkerModal.step1.description')}
            <span
                onClick={() => {
                    navigator.clipboard.writeText(code!);
                    SuccessToast(tt('common.copiedToClipboard'));
                }}
                className={classes.codeSpan}>&nbsp;{code}</span></Typography>
        <Box pb={2}/>
        <Box className={classes.buttons}>
            <AppButton
                variant={"contained"}
                onClick={() => {
                    navigator.clipboard.writeText(instructionsWithCode);
                    SuccessToast(tt('common.copiedToClipboard'));
                }}
            >
                <Icons8Copy/>
                <span>{tt('inviteWorkerModal.step1.button.copyInvitation')}</span>
            </AppButton>
        </Box>
    </>;

    return (
        <Box className={classes.outerContainer}>
            <AppPaper
                className={classes.paper}
                centerContent={true}
            >
                <PaperAppbar
                    title={tt('invitation.screen.title')}
                    hideBackButton={true}
                    children={<><LanguagesMenu/><DarkModeMenu/></>}
                />
                <Box pb={3}/>
                <TimotyLogo size={65}></TimotyLogo>
                <Typography className={classes.name}>{fullName}</Typography>

                <Box className={classes.contentContainer}>
                    {step1JSX}
                    <Box pb={3}/>
                </Box>
            </AppPaper>
        </Box>
    );
}
