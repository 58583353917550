import {ReactNode, useContext, useEffect} from "react";
import {AppDataContext} from "../AppData";
import {getPublicUrls, getPublicUrlsCombined, getPublicUrlsOfNotifications} from "../service/StorageService";

export interface IStorageProviderProps {
    children: ReactNode;
}

/**
 * Component to process state data related to files and storage.
 */
export default function StorageProvider(props: IStorageProviderProps) {
    const {children} = props;

    const appDataContext = useContext(AppDataContext);
    const {storage, setStorage} = appDataContext;

    useEffect(() => {
        if (storage.filesToProcess.length > 0) {
            setStorage((prev) => {
                const filesToProcess = prev.filesToProcess;
                setTimeout(() => {
                    getPublicUrlsCombined(filesToProcess)
                        .then((files) => {
                            const urls: Record<string, string> = {};
                            const urlsForIds: Record<number, string> = {};

                            for (const file of files) {
                                urls[file.file.uuid] = file.publicUrl;
                                urlsForIds[file.file.id] = file.publicUrl;
                            }

                            setStorage((prev) => {
                                return {
                                    publicUrlsForFiles: {
                                        ...prev.publicUrlsForFiles,
                                        ...urls,
                                    },
                                    publicUrlsForIds: {
                                        ...prev.publicUrlsForIds,
                                        ...urlsForIds,
                                    }, 
                                };
                            });
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }, 1);

                return {
                    filesToProcess: [],
                };
            });
        }
    }, [storage.filesToProcess]);

    useEffect(() => {
        if (storage.notificationsToProcess.length > 0) {
            setStorage((prev) => {
                const notificationsToProcess = prev.notificationsToProcess;
                setTimeout(() => {
                    getPublicUrlsOfNotifications(notificationsToProcess)
                        .then((urls) => {
                            setStorage((prev) => {
                                return {
                                    publicUrlsForFiles: {
                                        ...prev.publicUrlsForFiles,
                                        ...urls,
                                    },
                                };
                            });
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }, 1);

                return {
                    notificationsToProcess: [],
                };
            });
        }
    }, [storage.notificationsToProcess]);

    return (
        <>
            {children}
        </>
    );
}
