import React from "react";
import {DateTime} from "luxon";
import {useSearchParams} from "react-router-dom";
import {MenuItem} from "@mui/material";
import {DateRangeType, getDateRangeIntervalData} from "../../../../../service/JobService";

interface IJobDateFilterMenuItemProps {
    type: DateRangeType;
    setDateRange: React.Dispatch<React.SetStateAction<DateRangeType>>;
    setFromDate: React.Dispatch<React.SetStateAction<DateTime>>;
    setToDate: React.Dispatch<React.SetStateAction<DateTime>>;
    setDateRangeModal?: React.Dispatch<React.SetStateAction<Boolean>>;
    closeMenu?: Function;
}

/**
 * Component to choose dateRange type and dates if static.
 */
export default function VisitDateFilterMenuItem(props: IJobDateFilterMenuItemProps) {
    const {
        type,
        setDateRange,
        setFromDate,
        setToDate,
        setDateRangeModal,
        closeMenu,
    } = props;

    const [searchParams, setSearchParams] = useSearchParams();

    /**
     * Execute correct action by dateRange type.
     * Custom allows date range selection.
     */

    const dateRangeData = getDateRangeIntervalData(type);

    const pickFromDate = dateRangeData.fromDate;
    const pickToDate = dateRangeData.toDate;

    const Execute = () => {
        if (closeMenu) {
            closeMenu();
        }

        if (type === DateRangeType.custom) {
            if (setDateRangeModal) {
                setDateRangeModal(true);
            }
        } else {
            setDateRange(type);
            setFromDate(pickFromDate!);
            setToDate(pickToDate!);

            setSearchParams(prev => {
                prev.set('dateRange', type);
                prev.set('fromDate', `${pickFromDate!.toMillis()}`);
                prev.set('toDate', `${pickToDate!.toMillis()}`);
                return prev;
            });
        }
    };

    return (
        <MenuItem
            onClick={Execute}
        >{dateRangeData.title}</MenuItem>
    );
}
