import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import {tt} from "../../../core/Localization";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {useNavigate, useParams} from "react-router-dom";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {AppDataContext} from "../../../AppData";
import CreateAndEditJobFormForm from "../../components/jobForms/CreateAndEditJobForm";
import ContentPadding from "../../components/paper/ContentPadding";
import {jobFormDetailRoute} from "./JobFormDetailScreen";
import {
    GetJobFormInput,
    JobFormResponse,
    UpdateJobFormTemplateDocument,
    UpdateJobFormTemplateMutation,
    UpdateJobFormTemplateMutationVariables
} from "../../../generated/graphql/graphql";
import {useMutation} from "@apollo/client";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import AppIconButton from "../../components/buttons/AppIconButton";
import Icons8Eye from "../../../icons/Icons8Eye";
import AppChip from "../../components/chips/AppChip";
import JobFormPreviewModal from "../../components/modals/jobForms/JobFormPreviewModal";
import IEventSystemListener from "../../../model/EventSystemListener";
import {listenToEventSystem, unListenToEventSystem} from "../../../service/EventSystemService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import {kJobFormsRoute} from "./JobFormsScreen";
import {processQueryError} from "../../../service/ErrorService";
import {kActionDelete, kTopicJobFormTemplates} from "../../../core/constants";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";

export const kJobFormEditRoute = '/job-forms/:jobFormId/edit';

/**
 * Route for JobFormEditScreen with parameters.
 */
export function jobFormEditRoute(jobFormId: string | number) {
    return kJobFormEditRoute.replace(':jobFormId', `${jobFormId}`);
}

export default function JobFormEditScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);

    const navigate = useNavigate();
    const {jobFormId} = useParams();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<JobFormResponse | NullOrUndefined>();
    useEffect(() => {
        if (jobFormId) {
            restApiGet({
                uri: '/job-form',
                params: {
                    jobFormId: parseInt(jobFormId!),
                } as GetJobFormInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading,
                onData: setData,
                onError: (error) => processQueryError(appDataContext, error),
            });
        } else {
            setLoading(false);
            setData(null);
        }
    }, [jobFormId]);

    const [mutateUpdate, {
        loading: loadingUpdate,
        data: dataUpdate,
        error: errorUpdate,
    }] = useMutation<UpdateJobFormTemplateMutation, UpdateJobFormTemplateMutationVariables>(UpdateJobFormTemplateDocument);

    useEffect(() => {
        setTitle(tt('jobFormEdit.screen.title'));
    }, []);

    /**
     * Mutate update JobForm to BE.
     */
    const updateJobForm = async (jobForm: JobFormResponse) => {
        try {
            const variables: UpdateJobFormTemplateMutationVariables = {
                input: {
                    jobFormId: parseInt(jobFormId!),
                    name: jobForm.name,
                    sections: jobForm.sections,
                    elements: jobForm.elements,
                },
            };

            const result = await mutateUpdate({variables});

            if (!result.errors) {
                navigate(jobFormDetailRoute(result.data!.updateJobFormTemplate.id));

                SuccessToast(tt('jobFormEdit.modal.success'));
            }
        } catch (e) {
            console.error(e);
            ErrorToast(tt('common.mutation.error'));
        }
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                jobFormId={parseInt(jobFormId!)}
                loading={loading}
                data={data}
                isMobile={isMobile}
                onSubmit={updateJobForm}
                loadingUpdate={loadingUpdate}
            />
        );
    }

    return (
        <>
            <EventSystemListeners/>

            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}
            />
        </>
    );
}

/**
 * Component for EventSystem listeners.
 */
function EventSystemListeners() {
    const navigate = useNavigate();
    const {jobFormId} = useParams();

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicJobFormTemplates,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(jobFormId!);
                });

                if (doDelete) {
                    navigate(kJobFormsRoute);
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [jobFormId]);

    return null;
}

interface IBodyProps {
    jobFormId: number;
    loading: boolean;
    loadingUpdate?: boolean;
    data: JobFormResponse | NullOrUndefined;
    isMobile?: boolean;
    onSubmit: (jobForm: JobFormResponse) => void;
}

function Body(props: IBodyProps) {
    const {
        jobFormId,
        loading,
        data,
        isMobile,
        onSubmit,
        loadingUpdate,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const [previewModal, setPreviewModal] = useState<boolean>(false);
    const [jobFormResponse, setJobFormResponse] = useState<JobFormResponse | null>(null);


    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    isMobile={isMobile}
                    title={tt('jobFormEdit.screen.content.title')}
                    cancelIconBackButton={true}
                    backRoute={jobFormDetailRoute(jobFormId)}
                    children={
                        isMobile ?
                            <AppIconButton
                                variant={"greyBg"}
                                onClick={() => setPreviewModal(true)}
                            >
                                <Icons8Eye/>
                            </AppIconButton>
                            :
                            <AppChip
                                chipstyle={"secondary"}
                                label={tt('common.preview')}
                                icon={<Icons8Eye/>}
                                onClick={() => setPreviewModal(true)}
                            />
                    }
                ></PaperAppbar>

                <ContentPadding>
                    <CreateAndEditJobFormForm
                        isEdit={true}
                        companyId={companyId!}
                        isTemplate={true}
                        submitRight={true}
                        loading={loading}
                        loadingUpdate={loadingUpdate}
                        onChange={(jobForm: JobFormResponse) => {
                            setJobFormResponse(jobForm);
                        }}
                        onSubmit={onSubmit}
                        existingData={data}
                    />
                </ContentPadding>
            </AppPaper>

            {
                jobFormResponse ?
                    <JobFormPreviewModal
                        open={previewModal}
                        setOpen={setPreviewModal}
                        jobFormResponse={jobFormResponse}
                        canEdit={true}
                    />
                    : null
            }

        </ScreenContent>
    );
}
