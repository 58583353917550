import React, {useContext} from "react";
import {AppDataContext} from "../../../AppData";
import AppIconButton from "../buttons/AppIconButton";
import DarkModeIcon from "../../../icons/DarkModeIcon";
import LightModeIcon from "../../../icons/LightModeIcon";
import {ChangeTheme} from "../../../service/ThemeService";
import {tt} from "../../../core/Localization";

export default function DarkModeMenu() {
    const appDataContext = useContext(AppDataContext);
    const {theme, setTheme} = appDataContext;

    const darkMode = theme === 'dark';

    const currentIconJSX = darkMode ? <DarkModeIcon/> : <LightModeIcon/>;

    const tooltip = darkMode ? tt('settings.screen.darkMode') : tt('settings.screen.lightMode');

    return (

        <AppIconButton tooltip={tooltip} onClick={() => {
            if (darkMode) {
                ChangeTheme('light', setTheme)
            } else {
                ChangeTheme('dark', setTheme);
            }
        }
        }>
            {currentIconJSX}
        </AppIconButton>
    );
}