import {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../../../App";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ListShimmer from "../../components/shimmers/ListShimmer";
import EmptyListText from "../../components/textComponents/EmptyListText";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import PaperAppbar from "../../components/paper/PaperAppbar";
import AppChip from "../../components/chips/AppChip";
import {AddOutlined} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import AppListItem from "../../components/listItems/AppListItem";
import UserIcon from "../../../icons/UserIcon";
import {contactPersonDetailRoute, ContactPersonDetailRouteKind} from "./ContactPersonDetailScreen";
import CreateContactPersonModal from "../../components/contactPerson/CreateContactPersonModal";
import {clientDetailRoute} from "../clients/ClientDetailScreen";
import {
    ClientContactResponse,
    ClientResponse,
    GetClientInput
} from "../../../generated/graphql/graphql";
import {UserFullName} from "../../../service/UserService";
import IBreadcrumb from "../../../model/Breadcrumb";
import {kClientsBreadcrumb, kClientsRoute} from "../clients/ClientsScreen";
import {AppDataContext, SetAppBreadcrumbs} from "../../../AppData";
import AppBreadCrumb from "../../components/breadCrumb/AppBreadCrumb";
import IEventSystemListener from "../../../model/EventSystemListener";
import {
    listenToEventSystem,
    unListenToEventSystem
} from "../../../service/EventSystemService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import {processQueryError} from "../../../service/ErrorService";
import {
    kActionDelete, kActionUpdate, kPermissionsClients,
    kTopicClientContacts,
    kTopicClients
} from "../../../core/constants";
import PermissionValid from "../../components/permissions/PermissionValid";
import { RestApiClientContext } from "../../../core/RestApiProvider";

export const kContactPersonListRoute = '/clients/:clientId/contact-persons';

/**
 * Shorthand to create Route url.
 */
export function contactPersonListRoute(clientId: string | number): string {
    return kContactPersonListRoute
        .replace(':clientId', `${clientId}`);
}

export default function ContactPersonListScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;
    
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);

    const {clientId} = useParams();

    const [createModal, setCreateModal] = useState<boolean>(false);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ClientResponse | NullOrUndefined>();
    const [refetch, setRefetch] = useState<number>(0);
    useEffect(() => {
        if (clientId) {
            const subscription = subscribe(
                kTopicClients,
                {
                    uri: '/client',
                    params: {
                        clientId: parseInt(clientId!),
                    } as GetClientInput,
                    setLoading,
                    onData: setData,
                    onError: (error) => processQueryError(appDataContext, error),
                },
                (notifications: IEventSystemNotification[]) => {
                    return notifications.some(notification => {
                        if (notification.action !== kActionUpdate) {
                            return false;
                        }

                        return notification.data.id === parseInt(clientId!);
                    });
                },
            );

            return () => subscription.cancel();
        } else {
            setLoading(false);
            setData(null);
        }
    }, [clientId, refetch]);

    /**
     * Set breadcrumbs.
     */
    const SetBreadcrumbs = () => {
        const breadcrumbs: IBreadcrumb[] = [kClientsBreadcrumb];

        if (data) {
            breadcrumbs.push({
                key: 'client-detail',
                text: data!.name,
                route: clientDetailRoute(clientId!),
            });

            breadcrumbs.push({
                key: 'client-contacts',
                text: tt('contactPersonList.screen.title'),
                route: contactPersonListRoute(clientId!),
            });
        }

        SetAppBreadcrumbs(appDataContext, breadcrumbs);
    };

    useEffect(() => {
        setTitle(tt('contactPersonList.screen.title'));

        SetBreadcrumbs();
    }, []);

    useEffect(() => {
        SetBreadcrumbs();
    }, [data]);

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                loading={loading}
                data={data}
                setCreateModal={setCreateModal}
                isMobile={isMobile}
            />
        );
    }

    return (
        <>
            <EventSystemListeners refetch={() => setRefetch(refetch + 1)}/>

            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}/>

            <CreateContactPersonModal open={createModal} setOpen={setCreateModal} clientId={parseInt(clientId!)}/>
        </>
    );
}

interface IEventSystemListenersProps {
    refetch: VoidFunction;
}

/**
 * Component for EventSystem listeners.
 */
function EventSystemListeners(props: IEventSystemListenersProps) {
    const {refetch} = props;

    const {clientId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicClients,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(clientId!);
                });

                if (doDelete) {
                    navigate(kClientsRoute);
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [clientId]);

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicClientContacts,
            callback: (notifications: IEventSystemNotification[]) => {
                const update = notifications.some(notification => {
                    return notification.data.clientId === parseInt(clientId!);
                });

                if (update) {
                    refetch();
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [refetch, clientId]);

    return null;
}

interface IBodyProps {
    isMobile?: boolean;
    setCreateModal: Dispatch<SetStateAction<boolean>>;
    loading: boolean;
    data?: ClientResponse | NullOrUndefined;
}

function Body(props: IBodyProps) {
    const {isMobile, loading, setCreateModal, data} = props;

    const {clientId} = useParams();

    const content = useMemo(() => {
        const clientContacts = (data?.contacts || [])
            .filter(contactPerson => contactPerson.assignedToClient);

        return loading && !data ?
            <ListShimmer items={2}/> : data && clientContacts.length > 0 ?
                clientContacts.map(contactPerson => (
                    <ContactPersonListItem key={contactPerson.id} clientId={parseInt(clientId!)} data={contactPerson}/>
                ))
                : <EmptyListText text={tt('contactPersonList.screen.emptyListMessage')}/>;
    }, [data, loading]);

    return (
        <ScreenContent
            showBreadCrumb={true}
            appBar={!isMobile} noContentPadding={isMobile} navigationDrawer={!isMobile} bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    isMobile={isMobile}
                    title={tt('common.contactPersons')}
                    backRoute={clientDetailRoute(parseInt(clientId!))}>

                    {isMobile ? <></> : (
                        <PermissionValid
                            permission={kPermissionsClients}
                            requiredPermissions={[kActionUpdate]}
                        >
                            <AppChip
                                onClick={() => setCreateModal(true)}
                                key={'addClientChip'} label={tt('common.newPerson')}
                                icon={<AddOutlined/>}></AppChip>
                        </PermissionValid>
                    )}

                </PaperAppbar>

                {isMobile ? <AppBreadCrumb/> : null}

                {content}
            </AppPaper>
        </ScreenContent>
    );
}

interface IContactPersonItemProps {
    clientId: number;
    data: ClientContactResponse;
}

/**
 * Component for ContactPerson list item.
 */
function ContactPersonListItem(props: IContactPersonItemProps) {
    const {clientId, data} = props;

    const navigate = useNavigate();

    return (
        <AppListItem
            customAvatarInCircle={<UserIcon/>}
            title={UserFullName(data.name, data.surname)}
            description={data.role}
            variant={"smaller-title"}
            onClick={() => navigate(contactPersonDetailRoute(clientId, data.id, ContactPersonDetailRouteKind.fromList))}
        />
    );
}
