import {iconUseStyles} from "../styles/IconStyles";

export interface IScheduleEventProps {
    className?: string;
    size?: number;
}

export default function ScheduleEvent(props: IScheduleEventProps) {
    const {className, size} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        size ? null : classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width={size || "24px"}
                 height={size || "24px"}>
             <path
                 d="M 11.5 0 C 5.1664891 0 0 5.1664939 0 11.5 C 0 17.833506 5.1664891 23 11.5 23 C 17.833511 23 23 17.833506 23 11.5 C 23 5.1664939 17.833511 0 11.5 0 z M 11.5 3 C 16.212192 3 20 6.7878114 20 11.5 C 20 16.212189 16.212192 20 11.5 20 C 6.7878084 20 3 16.212189 3 11.5 C 3 6.7878114 6.7878084 3 11.5 3 z M 11.476562 4.9785156 A 1.50015 1.50015 0 0 0 10 6.5 L 10 11.5 A 1.50015 1.50015 0 0 0 10.439453 12.560547 L 12.439453 14.560547 A 1.50015 1.50015 0 1 0 14.560547 12.439453 L 13 10.878906 L 13 6.5 A 1.50015 1.50015 0 0 0 11.476562 4.9785156 z M 23.820312 6 C 24.244312 6.948 24.563766 7.952 24.759766 9 L 35.5 9 C 37.43 9 39 10.57 39 12.5 L 39 14 L 24.759766 14 C 24.562766 15.048 24.243313 16.052 23.820312 17 L 39 17 L 39 35.5 C 39 37.43 37.43 39 35.5 39 L 12.5 39 C 10.57 39 9 37.43 9 35.5 L 9 24.759766 C 7.952 24.562766 6.948 24.243313 6 23.820312 L 6 35.5 C 6 39.084 8.916 42 12.5 42 L 35.5 42 C 39.084 42 42 39.084 42 35.5 L 42 12.5 C 42 8.916 39.084 6 35.5 6 L 23.820312 6 z M 24 21 A 2.5 2.5 0 0 0 24 26 A 2.5 2.5 0 0 0 24 21 z M 32.5 21 A 2.5 2.5 0 0 0 32.5 26 A 2.5 2.5 0 0 0 32.5 21 z M 15.5 30 A 2.5 2.5 0 0 0 15.5 35 A 2.5 2.5 0 0 0 15.5 30 z M 24 30 A 2.5 2.5 0 0 0 24 35 A 2.5 2.5 0 0 0 24 30 z"/>
            </svg>
        </span>
    );
}

