import React, {Dispatch, SetStateAction, SyntheticEvent, useContext, useEffect, useState} from "react";
import ModalBottomSheet from "../ModalBottomSheet";
import {Box, Theme} from "@mui/material";
import BottomSheetModalAppbar from "../modals/BottomSheetModalAppbar";
import {tt} from "../../../core/Localization";
import AppButton from "../buttons/AppButton";
import {makeStyles} from "tss-react/mui";
import {AppDataContext} from "../../../AppData";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import {GetMaterialsInput, MaterialResponsePage} from "../../../generated/graphql/graphql";
import {AutocompleteChangeDetails, AutocompleteChangeReason} from "@mui/base/useAutocomplete/useAutocomplete";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import {processQueryError} from "../../../service/ErrorService";
import {IMaterialFormUpdate} from "./MaterialForm";
import SplitButton from "../buttons/SplitButton";
import {kTopicMaterialTemplates} from "../../../core/constants";

export const useStyles = makeStyles()((theme: Theme) => ({
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
    },
    oneFifth: {
        flexBasis: '20% !important',
        "@media (max-width: 767px)": {
            flexBasis: '100% !important',
        }
    }
}));

export interface IAddMaterialModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onSubmit: (update: IMaterialFormUpdate, saveAsTemplate: boolean, templateId?: number) => void;
    mutateLoading?: boolean;
}

export default function AddMaterialModalBottomSheet(props: IAddMaterialModalBottomSheetProps) {
    const {
        open,
        setOpen,
        onSubmit,
        mutateLoading,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const [selectedMaterial, setSelectedMaterial] = useState<any>();

    const appDataContext = useContext(AppDataContext);
    const {companyId, employeeId} = appDataContext;

    const {classes} = useStyles();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<MaterialResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (open) {
            const subscription = subscribe(
                kTopicMaterialTemplates,
                {
                    uri: '/material/search-by-company',
                    params: {
                        companyId: companyId!,
                        isTemplate: true,
                    } as GetMaterialsInput,
                    setLoading,
                    onData: setData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                () => true,
            );

            return () => {
                subscription.cancel();
            };
        } else {
            setData(null);
        }
    }, [open, companyId]);

    /**
     * Select existing Material and fill the form.
     */
    const selectMaterial = (id: number) => {
        const theMaterial = data?.content.find(item => item.id === id);

        if (theMaterial) {
            setInputs(prev => {
                return {
                    ...prev,
                    name: {
                        ...prev.name,
                        value: theMaterial.name,
                    },
                    quantity: {
                        ...prev.quantity,
                        value: 1,
                    },
                    unitOfMeasure: {
                        ...prev.unitOfMeasure,
                        value: theMaterial.unitName || '',
                    },
                };
            });
        }
    };

    const [inputs, setInputs] = useState<IInputsData>({
        name: {
            testId: 'addMaterialNameInput',
            type: InputType.TextAutocomplete,
            label: tt('material.form.label.name') + '*',
            value: '',
            required: true,
            autocompleteOptions: [],
            onAutocompleteValueChanged: (event: SyntheticEvent, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => {
            },
            onNewValueAdded: (value) => {
            },
        },
        quantity: {
            testId: 'addMaterialQuantityInput',
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('material.form.label.quantity')}*`,
            value: '',
            required: true,
            grid: {
                sm: 6,
                xs: 6,
            },
        },
        unitOfMeasure: {
            testId: 'addMaterialUnitOfMeasureInput',
            type: InputType.Text,
            label: tt('material.form.label.unitOfMeasure'),
            placeholder: tt('material.form.placeholder.unitOfMeasure'),
            value: '',
            required: false,
            grid: {
                sm: 6,
                xs: 6,
            },
        },
    });

    useEffect(() => {
        if (open) {
            // Reset the form.
            setInputs(prev => {
                return {
                    ...prev,
                    name: {
                        ...prev.name,
                        value: '',
                    },
                    quantity: {
                        ...prev.quantity,
                        value: '',
                    },
                    unitOfMeasure: {
                        ...prev.unitOfMeasure,
                        value: '',
                    },
                };
            });
        }
    }, [open]);

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                name: {
                    ...prev.name,
                    onAutocompleteValueChanged: (event: SyntheticEvent, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => {
                        if (reason === "selectOption") {
                            selectMaterial(parseInt(value.value));

                            setSelectedMaterial(data?.content.find(item => item.id === parseInt(value.value)));
                        }
                    },
                },
            };
        });
    }, [data]);

    useEffect(() => {
        if (data) {
            setInputs(prev => {
                return {
                    ...prev,
                    name: {
                        ...prev.name,
                        autocompleteOptions: data.content
                            .map(item => {
                                return {
                                    value: item.id,
                                    label: item.name,
                                };
                            }),
                    },
                };
            });
        }
    }, [data]);

    const SubmitForm = (saveAsTemplate: boolean) => {
        if (ValidateForm(inputs, setInputs)) {
            const theName = inputs.name.value;

            if (typeof theName === 'object') {
                inputs.name.value = inputs.name.value.value;
            }

            onSubmit({
                inputs: {
                    ...inputs,
                },
            }, saveAsTemplate, selectedMaterial?.id);
        }
    };

    const isNewMaterial = selectedMaterial === undefined || selectedMaterial.name !== inputs.name.value;

    return (
        <>
            <ModalBottomSheet
                blurBackdrop={true}
                open={open}
                setOpen={setOpen}
                hideHeader={true}
                tallOnMobile={true}
            >
                <Box>
                    <BottomSheetModalAppbar
                        noBorderBottom={true}
                        title={tt('addMaterialModal.title')}
                        onClose={() => setOpen(false)}
                    />

                    <Box pr={2} pl={2}>
                        <FormBuilder
                            inputs={inputs}
                            setInputs={setInputs}
                        />
                    </Box>

                    <Box pb={9}/>

                    <Box className={classes.buttonContainer}>
                        {isNewMaterial ? (
                            <SplitButton
                                isLoading={mutateLoading}
                                isInModal={true}
                                onChange={
                                    (index) => {
                                        SubmitForm(true);
                                    }
                                }
                                onClick={(index, value) => {
                                    SubmitForm(false);
                                }}
                                text={tt('common.save')}
                                options={[
                                    tt('addMaterialModal.saveAsBoth'),
                                ]}
                            />
                        ) : (
                            <AppButton
                                variant={"contained"}
                                fullWidth={true}
                                isLoading={mutateLoading}
                                onClick={() => SubmitForm(false)}>
                                {tt('common.save')}
                            </AppButton>
                        )}
                    </Box>
                </Box>
            </ModalBottomSheet>
        </>
    );
}
