import {usePopupState} from "material-ui-popup-state/hooks";
import AppListItem from "../../listItems/AppListItem";
import {UserFullName} from "../../../../service/UserService";
import AppIconButton from "../../buttons/AppIconButton";
import {tt} from "../../../../core/Localization";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MoreFilledIcon from "../../../../icons/MoreFilledIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {Box} from "@mui/material";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import React from "react";
import UserIcon from "../../../../icons/UserIcon";
import PhoneMenu from "../../menus/PhoneMenu";
import CloseIcon from "../../../../icons/CloseIcon";
import {ClientContactResponse} from "../../../../generated/graphql/graphql";

export interface INewJobContactPersonProps {
    data: ClientContactResponse;
    onDelete: (id: number) => void;
}

/**
 * Client Contact component for Job creation.
 */
export default function NewJobContactPerson(props: INewJobContactPersonProps) {
    const {data, onDelete} = props;

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: 'popup-contact-more-options' + data.id,
    });

    return (
        <>
            <AppListItem
                key={data.id}
                variant={"smaller-title"}
                title={UserFullName(data?.name, data?.surname)}
                description={data.role}
                customAvatar={<UserIcon/>}
                actionWidget={<>
                    {data.phoneNumber ? <PhoneMenu
                        phone={data.phoneNumber}
                        showMarginRight={true}/> : null}
                    <AppIconButton
                        variant={"greyBg"}
                        placement={"right"}
                        {...bindTrigger(settingsPopupState)}
                    >
                        <MoreFilledIcon/>
                    </AppIconButton>
                </>
                }
            />

            <Menu {...bindMenu(settingsPopupState)}>
                <MenuItem key={'contactDetailDeleteKey' + data.id} onClick={() => {
                    settingsPopupState.close();
                    onDelete(data.id);
                }}>
                    <Box sx={{color: kAppColors.red.confirmButton}}><CloseIcon/></Box>
                    {tt('common.removeFromList')}
                </MenuItem>
            </Menu>
        </>
    );
}
