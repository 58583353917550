import React, {useState} from "react";
import Cropper, {Area} from "react-easy-crop";
import {Box, Theme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import AppIconButton from "../buttons/AppIconButton";
import IconPlus from "../../../icons/IconPlus";
import IconMinus from "../../../icons/IconMinus";

export const useStyles = makeStyles()((theme: Theme) => ({
    controls: {
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: 420,
        'button': {
            display: "flex",
            alignItems: "center",
            width: 40,
            height: 40,
            padding: 0,
            'p': {
                height: 40,
                fontSize: 30,
                lineHeight: '30px',
            },
            flexShrink: 0,
        }
    },
    zoomRange: {
        margin: "auto",
        flexGrow: 1,
        WebkitAppearance: 'none',
        cursor: "pointer",
        background: 'transparent',
        '::-moz-range-track': {
            boxShadow: 'none',
            border: 'none',
            borderRadius: 20,
            height: 8,
            background: "transparent",
        },
        "::-ms-track": {
            boxShadow: 'none',
            border: 'none',
            borderRadius: 20,
            height: 8,
            background: "transparent",
        },
        '::-webkit-slider-runnable-track': {
            boxShadow: 'none',
            border: 'none',
            borderRadius: 20,
            height: 8,
            background: "transparent",
        },
        "::-moz-range-thumb": {
            WebkitAppearance: 'none',
            height: 24,
            width: 24,
            background: kAppColors.primary.main,
            marginTop: -8,
            borderRadius: 24,
            border: "none",
        },
        '::-ms-thumb': {
            WebkitAppearance: 'none',
            height: 24,
            width: 24,
            background: kAppColors.primary.main,
            marginTop: -8,
            borderRadius: 24,
            border: "none",
        },
        '::-webkit-slider-thumb': {
            WebkitAppearance: 'none',
            height: 24,
            width: 24,
            background: kAppColors.primary.main,
            marginTop: -8,
            borderRadius: 24,
            border: "none",
        },
    },
    cropperContainer: {
        margin: "auto",
        height: 380,
        maxWidth: 380,
        position: "relative"
    }
}));

interface IImageCropperProps {
    img: string;
    onCropComplete: (croppedArea: Area, croppedAreaPixels: Area) => void
}

export default function ImageCropper(props: IImageCropperProps) {
    const {
        img,
        onCropComplete,
    } = props;

    const {classes, cx} = useStyles();

    const [crop, setCrop] = useState({x: 0, y: 0})
    const [zoom, setZoom] = useState(1)

    const zoomAsPercentage = (zoom - 1) / 0.02;
    const step = 0.1;

    return (
        <>
            <Box
                className={classes.cropperContainer}
            >
                <Cropper
                    style={{
                        cropAreaStyle: {
                            borderRadius: 1000,
                        },
                        containerStyle: {
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#fff",
                        },
                    }}
                    image={img}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    showGrid={false}
                />
            </Box>

            <Box pb={3}/>

            <Box className={classes.controls}>
                <AppIconButton
                    onClick={() => {
                        if (zoom - step >= 1) {
                            setZoom(zoom - step);
                        } else {
                            setZoom(1)
                        }
                    }}
                >
                    <IconMinus/>
                </AppIconButton>
                <Box pl={1}/>
                <Box
                    style={{
                        borderRadius: 20,
                        flexGrow: 1,
                        display: "flex",
                        background: `linear-gradient(to right, ${kAppColors.primary.main} 0%, ${kAppColors.primary.main} ${zoomAsPercentage}%, rgb(246 176 154) ${zoomAsPercentage}%)`
                    }}>
                    <input
                        type="range"
                        value={zoom}
                        min={1}
                        max={3}
                        step={step}
                        aria-labelledby="Zoom"
                        onChange={(e) => {
                            setZoom(parseFloat(e.target.value))
                        }}
                        className={cx(classes.zoomRange, 'rangeInputClass')}
                    />
                </Box>
                <Box pl={1}/>
                <AppIconButton
                    onClick={() => {
                        if (zoom + step <= 3) {
                            setZoom(zoom + step);
                        } else {
                            setZoom(3);
                        }
                    }
                    }
                >
                    <IconPlus/>
                </AppIconButton>
            </Box>
        </>
    )
}