import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8ElevatorProps {
    className?: string;
}

export default function Icons8Elevator(props: IIcons8ElevatorProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
              <path
                  d="M 27.134766 0 C 26.286766 0 25.824187 0.989625 26.367188 1.640625 L 29.232422 5.578125 C 29.632422 6.058125 30.367578 6.058125 30.767578 5.578125 L 33.632812 1.640625 C 34.175812 0.989625 33.713234 -2.9605947e-16 32.865234 0 L 27.134766 0 z M 18 0.0625 C 17.716 0.0625 17.432422 0.181875 17.232422 0.421875 L 14.367188 4.359375 C 13.824187 5.010375 14.286766 6 15.134766 6 L 20.865234 6 C 21.713234 6 22.175813 5.010375 21.632812 4.359375 L 18.767578 0.421875 C 18.567578 0.181875 18.284 0.0625 18 0.0625 z M 11.5 8 C 9.0324991 8 7 10.032499 7 12.5 L 7 39.5 C 7 41.967501 9.0324991 44 11.5 44 L 36.5 44 C 38.967501 44 41 41.967501 41 39.5 L 41 12.5 C 41 10.032499 38.967501 8 36.5 8 L 11.5 8 z M 11.5 11 L 36.5 11 C 37.346499 11 38 11.653501 38 12.5 L 38 39.5 C 38 40.346499 37.346499 41 36.5 41 L 11.5 41 C 10.653501 41 10 40.346499 10 39.5 L 10 12.5 C 10 11.653501 10.653501 11 11.5 11 z M 18 13 A 3 3 0 0 0 18 19 A 3 3 0 0 0 18 13 z M 30 13 A 3 3 0 0 0 30 19 A 3 3 0 0 0 30 13 z M 17.5 20 C 15.032499 20 13 22.032499 13 24.5 L 13 28.5 A 1.50015 1.50015 0 0 0 14 29.933594 L 14 37.5 A 1.50015 1.50015 0 1 0 17 37.5 L 18 30 L 19 37.5 A 1.50015 1.50015 0 1 0 22 37.5 L 22 29.933594 A 1.50015 1.50015 0 0 0 23 28.5 L 23 24.5 C 23 22.032499 20.967501 20 18.5 20 L 17.5 20 z M 29.5 20 C 27.032499 20 25 22.032499 25 24.5 L 25 28.5 A 1.50015 1.50015 0 0 0 26 29.933594 L 26 37.5 A 1.50015 1.50015 0 1 0 29 37.5 L 30 30 L 31 37.5 A 1.50015 1.50015 0 1 0 34 37.5 L 34 29.933594 A 1.50015 1.50015 0 0 0 35 28.5 L 35 24.5 C 35 22.032499 32.967501 20 30.5 20 L 29.5 20 z"/>
              </svg>
        </span>
    );
}
























