import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import ModalBottomSheet from "../../../ModalBottomSheet";
import {ClientResponse, GetClientInput} from "../../../../../generated/graphql/graphql";
import {tt} from "../../../../../core/Localization";
import AppListItem from "../../../listItems/AppListItem";
import UserIcon from "../../../../../icons/UserIcon";
import {AddOutlined} from "@mui/icons-material";
import {UserFullName} from "../../../../../service/UserService";
import {Box, Checkbox, InputAdornment, Theme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import AppButton from "../../../buttons/AppButton";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import CreateContactPersonModal from "../../../contactPerson/CreateContactPersonModal";
import {bottomSheetContainerStyles} from "../../../../../styles/UtilStyles";
import EmptyListText from "../../../textComponents/EmptyListText";
import {processQueryError} from "../../../../../service/ErrorService";
import {AppDataContext} from "../../../../../AppData";
import FormBuilder, {IInputsData, InputType} from "../../../form/FormBuilder";
import SearchIcon from "../../../../../icons/SearchIcon";
import {kActionCreate, kActionUpdate, kPermissionsClients} from "../../../../../core/constants";
import PermissionValid from "../../../permissions/PermissionValid";
import {RestApiClientContext} from "../../../../../core/RestApiProvider";

export const useStyles = makeStyles()((theme: Theme) => ({
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
    },
}));

export interface IChooseClientContactModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    clientId: number;
    existingContactIds: number[];
    onSave: (ids: number[]) => void | Promise<void>;
    canSaveToClear?: boolean;
    updateLoading?: boolean;
    modalAboveModals?: boolean;
}

/**
 * Bottom sheet modal component to select new Client Contacts.
 */
export default function ChooseClientContactModalBottomSheet(props: IChooseClientContactModalBottomSheetProps) {
    const {
        open,
        setOpen,
        clientId,
        existingContactIds,
        onSave,
        canSaveToClear,
        updateLoading,
        modalAboveModals,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);

    const {classes} = useStyles();
    const containerStyle = bottomSheetContainerStyles();

    const [selected, setSelected] = useState<number[]>([]);
    const [createContact, setCreateContact] = useState<boolean>(false);

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            type: InputType.Text,
            label: '',
            placeholder: tt('contactPersons.modal.search.placeholder'),
            hideLabel: true,
            inputVariant: 'standard',
            extraStyle: 'thin',
            value: '',
            required: true,
            isClearable: true,
            innerPrefixJSX: (
                <InputAdornment position={"start"}>
                    <SearchIcon/>
                </InputAdornment>
            ),
        },
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<ClientResponse | NullOrUndefined>();
    useEffect(() => {
        if (open && !createContact) {
            restApiGet({
                uri: '/client',
                params: {
                    clientId,
                } as GetClientInput,
                setLoading: setLoading,
                onData: setData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        }
    }, [open, createContact, clientId]);

    useEffect(() => {
        if (open) {
            setSelected([...existingContactIds]);
        }
    }, [open]);

    /**
     * Toggle contact is selected list.
     */
    const Toggle = (id: number) => {
        if (selected.includes(id)) {
            setSelected(prev => {
                const newSelected = [];

                for (const idOf of prev) {
                    if (idOf !== id) {
                        newSelected.push(idOf);
                    }
                }

                return newSelected;
            });
        } else {
            setSelected(prev => {
                return [
                    ...prev,
                    id,
                ];
            });
        }
    };

    const contactsJSX = useMemo(() => {
        if (data) {
            const theData = data?.contacts.filter(
                (contact) => {
                    return UserFullName(contact.name, contact.surname).toLowerCase().includes(inputs.search.value.toLowerCase());
                }
            ).sort((a, b) => UserFullName(a.name, a.surname).localeCompare(UserFullName(b.name, b.surname)));

            return theData.map(contact =>
                <AppListItem
                    key={contact.id}
                    customAvatarInCircle={<UserIcon/>}
                    description={contact.role}
                    title={UserFullName(contact.name, contact.surname)}
                    variant={"smaller-title"}
                    onClick={() => Toggle(contact.id)}
                    actionWidget={
                        selected.includes(contact.id) ? <Checkbox checked={true}/> : <Checkbox checked={false}/>
                    }
                />
            );
        } else {
            return null;
        }
    }, [data, inputs, selected]);

    return (
        <>
            <ModalBottomSheet
                tallOnMobile={true}
                blurBackdrop={true}
                open={open}
                setOpen={setOpen}
                hideHeader={true}
                modalAboveModals={modalAboveModals}>
                <Box className={containerStyle.classes.container}>
                    <BottomSheetModalAppbar
                        onClose={() => setOpen(false)}
                        bottomContent={
                            <FormBuilder inputs={inputs} setInputs={setInputs}/>
                        }
                    >
                        <PermissionValid
                            permission={kPermissionsClients}
                            requiredPermissions={[kActionUpdate]}
                        >
                            <AppButton
                                variant={"textThin"}
                                onClick={() => setCreateContact(true)}
                            >
                                <AddOutlined sx={{mr: 0.25}}/>
                                {tt('common.newPerson')}
                            </AppButton>
                        </PermissionValid>
                    </BottomSheetModalAppbar>

                    {!contactsJSX || contactsJSX.length === 0 ?
                        <EmptyListText text={tt('newJobContactList.emptyListMessage')}/> : contactsJSX}

                    <Box sx={{pb: 10}}/>

                    <Box className={classes.buttonContainer}>
                        <AppButton
                            variant={"contained"}
                            disabled={loading || (selected.length < 1 && !canSaveToClear)}
                            isLoading={updateLoading}
                            fullWidth={true}
                            onClick={() => onSave(selected)}>
                            {tt('common.save')}
                        </AppButton>
                    </Box>
                </Box>
            </ModalBottomSheet>

            <CreateContactPersonModal
                open={createContact}
                setOpen={setCreateContact}
                clientId={clientId}
                navigation={false}
                modalAboveModals={modalAboveModals}/>
        </>
    );
}
