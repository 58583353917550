import {iconUseStyles} from "../styles/IconStyles";

export interface IRadioButtonIconProps {
    className?: string;
}

export default function RadioButtonIcon(props: IRadioButtonIconProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" width="20px" height="20px">
                <g id="Group_38" data-name="Group 38" transform="translate(-994 -154)">
                    <g id="Ellipse_19" data-name="Ellipse 19" transform="translate(994 154)"
                       stroke="currentColor" strokeWidth="2">
                        <circle cx="12.5" cy="12.5" r="11.5" fill="none"/>
                    </g>
                    <circle id="Ellipse_21" data-name="Ellipse 21" cx="6.5" cy="6.5" r="6.5"
                            transform="translate(1000 160)" fill="currentColor"/>
                </g>
            </svg>
        </span>
    );
}

