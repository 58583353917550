import HeadlineWithButton from "../../screenSections/detailListPreviewSection/HeadlineWithButton";
import {tt} from "../../../core/Localization";
import React, {Dispatch, SetStateAction, useContext, useMemo, useState} from "react";
import {AppDataContext} from "../../../AppData";
import {
    AddVisitMaterialInput,
    CreateMaterialInput,
    CreateMaterialTemplateInput,
    DeleteVisitMaterialInput,
    EmployeeJoinedUserResponse, FileResponse,
    JobUpdateRepeats,
    MaterialResponse,
    UpdateVisitMaterialInput,
    VisitResponse,
} from "../../../generated/graphql/graphql";
import Icons8Plus from "../../../icons/Icons8Plus";
import MaterialListItem from "./MaterialListItem";
import AddMaterialModalBottomSheet from "./AddMaterialModalBottomSheet";
import EditVisitMaterialModalBottomSheet, {IMaterialsByTemplate} from "./EditVisitMaterialModalBottomSheet";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import {IMaterialFormUpdate} from "./MaterialForm";
import {processMutationError} from "../../../service/ErrorService";
import {v4 as uuidv4} from "uuid";
import {HideConfirmModal, SetConfirmModal} from "../modals/AppModals";
import EditMaterialModalBottomSheet from "./EditMaterialModalBottomSheet";
import {SuccessToast} from "../../../service/ToastService";
import {uniqueArrayByPredicate} from "../../../utils/Utils";
import {IInputsData} from "../form/FormBuilder";
import {kActionCreate, kPermissionsMaterials} from "../../../core/constants";

interface IMaterialsSectionProps {
    isNewJobCreation?: boolean;
    createMaterialsInput?: CreateMaterialInput[];
    setCreateMaterialsInput?: Dispatch<SetStateAction<CreateMaterialInput[]>>;
    visitId?: number;
    repeatingDay?: number;
    isVisitDetail?: boolean;
    materials?: MaterialResponse[];
    files?: FileResponse[] | NullOrUndefined;
    mutateLoading?: boolean;
    isRepeating?: boolean;
    repeats?: JobUpdateRepeats;
    setRecurringConfirmActionCallback?: (recurringConfirmActionCallback: ((repeats: JobUpdateRepeats) => void) | ((repeats: JobUpdateRepeats) => Promise<void>) | undefined) => void;
    setRecurringEditModal?: Dispatch<SetStateAction<boolean>>;
    setRepeats?: Dispatch<SetStateAction<JobUpdateRepeats>>;
    employees?: EmployeeJoinedUserResponse[];
    canEdit?: boolean;
}

export default function MaterialsSection(props: IMaterialsSectionProps) {
    const {
        isNewJobCreation,
        createMaterialsInput,
        setCreateMaterialsInput,
        visitId,
        repeatingDay,
        isVisitDetail,
        materials,
        files,
        mutateLoading,
        isRepeating,
        repeats,
        setRecurringConfirmActionCallback,
        setRecurringEditModal,
        setRepeats,
        employees,
        canEdit,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiPost, restApiDelete} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, employeeId, storage} = appDataContext;

    const [addMaterial, setAddMaterial] = useState<boolean>(false);
    const [addMaterialForTemplate, setAddMaterialForTemplate] = useState<IMaterialsByTemplate>();
    const [editCreateInputData, setEditCreateInputData] = useState<CreateMaterialInput>();
    const [editCreateInput, setEditCreateInput] = useState<boolean>(false);
    const [editData, setEditData] = useState<IMaterialsByTemplate>();
    const [editMaterial, setEditMaterial] = useState<boolean>(false);

    const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
    const [loadingAdd, setLoadingAdd] = useState<boolean>(false);
    /**
     * If new Job adds to inputs list.
     * On detail mutate new Material to BE.
     */
    const onSubmit = async (input: IMaterialFormUpdate, saveAsTemplate: boolean, templateId?: number) => {
        const addMaterial = (templateId?: number) => {
            if (setCreateMaterialsInput) {
                const existingForTemplateId = createMaterialsInput?.find(item => item.templateId === templateId);

                if (!isVisitDetail && existingForTemplateId) {
                    setCreateMaterialsInput(prev => {
                        return prev.map(item => {
                            if (item.templateId === templateId) {
                                return {
                                    ...item,
                                    unitCount: item.unitCount + parseFloat(input.inputs.quantity.value || '0'),
                                };
                            }

                            return item;
                        });
                    });
                } else {
                    setCreateMaterialsInput(prev => {
                        return [...prev, {
                            uuid: uuidv4(),
                            employeeId: employeeId!,
                            name: input.inputs.name.value,
                            unitCount: parseFloat(input.inputs.quantity.value || '0'),
                            unitName: input.inputs.unitOfMeasure.value,
                            sortOrder: 0,
                            templateId: templateId,
                        }];
                    });
                }

                setAddMaterial(false);
            }

            if (isVisitDetail) {
                restApiPost({
                    uri: '/job/visit/add-material',
                    params: {
                        visitId: visitId!,
                        repeatingDay,
                        repeats,
                        uuid: uuidv4(),
                        templateId,
                        employeeId: employeeId!,
                        name: input.inputs.name.value,
                        unitName: input.inputs.unitOfMeasure.value,
                        unitCount: parseFloat(input.inputs.quantity.value || '0'),
                        saveAsTemplate: false,
                    } as AddVisitMaterialInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading: setLoadingAdd,
                    onData: (data: VisitResponse) => {
                        if (data) {
                            SuccessToast(tt('visitDetailContent.addMaterial.success'));

                            setAddMaterial(false);
                        }
                    },
                    onError: (error) => processMutationError(error),
                });
            }
        };

        if (saveAsTemplate) {
            restApiPost({
                uri: '/material/template',
                params: {
                    companyId: companyId!,
                    name: input.inputs.name.value,
                    unitName: input.inputs.unitOfMeasure.value,
                } as CreateMaterialTemplateInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading: setLoadingCreate,
                onData: (data: MaterialResponse) => {
                    if (data) {
                        addMaterial(data.id);
                    }
                },
                onError: (error) => processMutationError(error),
            });
        } else {
            addMaterial(templateId);
        }
    };

    const [loadingAddDetail, setLoadingAddDetail] = useState<boolean>(false);
    /**
     * On detail add new of Material.
     */
    const addMaterialDetail = (repeats: JobUpdateRepeats, material: Partial<AddVisitMaterialInput>, input: IInputsData) => {
        if (material.templateId) {
            restApiPost({
                uri: '/job/visit/add-material',
                params: {
                    visitId: visitId!,
                    repeatingDay,
                    repeats,
                    uuid: material.uuid,
                    templateId: material.templateId,
                    name: material.name,
                    unitCount: input.quantity.value,
                    unitName: input.unitOfMeasure.value,
                    employeeId: employeeId!,
                } as AddVisitMaterialInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading: setLoadingAddDetail,
                onData: (data: VisitResponse) => {
                    if (data) {
                        SuccessToast(tt('visitDetailContent.addMaterial.success'));
                    }
                },
                onError: (error) => processMutationError(error),
            });
        }
    };

    /**
     * Edit CreateMaterialInput during create new Job.
     */
    const onEditCreateMaterialInput = (update: IMaterialFormUpdate, uuid: string) => {
        if (setCreateMaterialsInput) {
            setCreateMaterialsInput(prev => {
                return prev.map(item => {
                    if (item.uuid === uuid) {
                        return {
                            ...item,
                            unitCount: parseFloat(update.inputs.quantity.value || '0'),
                            unitName: update.inputs.unitOfMeasure.value,
                        };
                    }

                    return item;
                });
            });
        }

        setEditCreateInput(false);
    };

    /**
     * Start edit Material.
     */
    const startUpdate = (item: IMaterialsByTemplate) => {
        setEditData(item);

        setEditMaterial(true);
    };

    const [loadingUpdateDetail, setLoadingUpdateDetail] = useState<boolean>(false);
    /**
     * Update Material item data to BE.
     */
    const onUpdateDetail = (repeats: JobUpdateRepeats, uuid: string, data: IInputsData) => {
        const theMaterial = materials!.find(item => item.uuid === uuid);

        restApiPost({
            uri: '/job/visit/update-material',
            params: {
                visitId: visitId!,
                repeatingDay,
                repeats,
                uuid,
                employeeId: employeeId!,
                unitName: data.unitOfMeasure.value,
                unitCount: parseFloat(data.quantity.value || '0'),
                materialId: theMaterial!.id,
            } as UpdateVisitMaterialInput,
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading: setLoadingUpdateDetail,
            onData: (data: VisitResponse) => {
                if (data) {
                    SuccessToast(tt('visitDetailContent.updateMaterial.success'));
                }
            },
            onError: (error) => processMutationError(error),
        });
    };

    /**
     * Start delete Material.
     * Used to delete Materials.
     */
    const startDelete = (repeats: JobUpdateRepeats, index: number | NullOrUndefined, uuid?: string) => {
        SetConfirmModal(appDataContext, {
            open: true,
            title: tt('deleteVisitMaterial.popup.title'),
            subtitle: tt('deleteVisitMaterial.popup.description'),
            cancelButtonText: tt('common.close'),
            confirmationButtonText: tt('deleteVisitMaterial.popup.confirm'),
            onConfirm: async () => {
                if (index) {
                    onDelete(index)
                        .catch(e => console.error(e));
                } else if (uuid) {
                    onDeleteDetail(repeats, uuid);
                }

                HideConfirmModal(appDataContext);
            },
            children: <></>,
        });
    };

    /**
     * If new Visit remove from inputs list.
     */
    const onDelete = async (index: number) => {
        if (setCreateMaterialsInput) {
            setCreateMaterialsInput(prev => {
                return prev.filter((item, itemIndex) => itemIndex !== index);
            });
        }
    };

    const [loadingDeleteMaterial, setLoadingDeleteMaterial] = useState<boolean>(false);
    /**
     * Send delete request to BE for Material item.
     */
    const onDeleteDetail = (repeats: JobUpdateRepeats, uuid: string) => {
        const material = materials?.find(item => item.uuid === uuid);
        const materialId = material!.id;

        restApiDelete({
            uri: '/job/visit/delete-material',
            params: {
                visitId: visitId!,
                repeatingDay,
                repeats,
                uuid,
                materialId,
            } as DeleteVisitMaterialInput,
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading: setLoadingDeleteMaterial,
            onData: (data: VisitResponse) => {
                if (data) {
                    SuccessToast(tt('visitDetailContent.deleteMaterial.success'));
                }
            },
            onError: (error) => processMutationError(error),
        });
    };

    const materialsJSX = useMemo(() => {
        if (createMaterialsInput) {
            return [...createMaterialsInput]
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((item, index) => (
                    <MaterialListItem
                        key={index}
                        isNewJobCreation={isNewJobCreation}
                        data={{
                            ...item,
                            index,
                        }}
                        files={files}
                        onUpdate={() => {
                            setEditCreateInputData(item);

                            setEditCreateInput(true);
                        }}
                        onDelete={(index: number) => onDelete(index)}
                        isVisitDetail={isVisitDetail}
                        canEdit={true}
                    />
                ));
        }

        if (materials) {
            const byTemplate: IMaterialsByTemplate[] = [];

            materials.forEach(item => {
                const employee = employees?.find(e => e.id === item.employeeId);

                if (item.templateId) {
                    const existing = byTemplate.find(i => i.materialId === item.templateId);

                    if (existing) {
                        existing.unitCount += item.unitCount || 0;

                        if (employee) {
                            existing.employees.push(employee);

                            existing.employees = uniqueArrayByPredicate(existing.employees, (a, b) => a.id === b.id);
                        }
                    } else {
                        byTemplate.push({
                            materialId: item.templateId,
                            name: item.name,
                            unitCount: item.unitCount || 0,
                            unitName: item.unitName!,
                            employees: employee ? [employee] : [],
                        });
                    }
                } else {
                    byTemplate.push({
                        materialUuid: item.uuid!,
                        name: item.name,
                        unitCount: item.unitCount || 0,
                        unitName: item.unitName!,
                        employees: employee ? [employee] : [],
                    });
                }
            });

            if (editData) {
                const itemForEdit = editData.materialId ? byTemplate.find(i => i.materialId === editData.materialId) : byTemplate.find(i => i.materialUuid === editData.materialUuid);

                setTimeout(() => {
                    setEditData(itemForEdit);
                }, 1);
            }

            return byTemplate
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item, index) => (
                    <MaterialListItem
                        key={index}
                        isNewJobCreation={isNewJobCreation}
                        data={{
                            ...item,
                            index,
                        }}
                        files={files}
                        onUpdate={() => {
                            if (setRecurringConfirmActionCallback && setRepeats && setRecurringEditModal) {
                                setRecurringConfirmActionCallback((repeats: JobUpdateRepeats) => startUpdate(item));

                                if (isRepeating) {
                                    setRepeats(JobUpdateRepeats.Single);
                                    setRecurringEditModal(true);
                                } else {
                                    startUpdate(item);
                                }
                            } else {
                                startUpdate(item);
                            }
                        }}
                        onDelete={(index: number) => {
                            if (setRecurringConfirmActionCallback && setRepeats && setRecurringEditModal) {
                                setRecurringConfirmActionCallback((repeats: JobUpdateRepeats) => startDelete(repeats, index));

                                if (isRepeating) {
                                    setRepeats(JobUpdateRepeats.Single);
                                    setRecurringEditModal(true);
                                } else {
                                    startDelete(JobUpdateRepeats.Single, index);
                                }
                            } else {
                                startDelete(JobUpdateRepeats.Single, index);
                            }
                        }}
                        isVisitDetail={isVisitDetail}
                        canEdit={canEdit}
                    />
                ));
        }

        return undefined;
    }, [createMaterialsInput, materials, files, canEdit, storage.publicUrlsForFiles]);

    return (
        <>
            <HeadlineWithButton
                title={tt('materials.section.title')}
                iconJSX={<Icons8Plus/>}
                onClick={!isVisitDetail || canEdit ? () => {
                    if (setRecurringConfirmActionCallback && setRepeats && setRecurringEditModal) {
                        setRecurringConfirmActionCallback((repeats: JobUpdateRepeats) => setAddMaterial(true));

                        if (isRepeating) {
                            setRepeats(JobUpdateRepeats.Single);
                            setRecurringEditModal(true);
                        } else {
                            setAddMaterial(true);
                        }
                    } else {
                        setAddMaterial(true);
                    }
                } : undefined}
                buttonText={tt('products.section.addItem')}
                permission={kPermissionsMaterials}
                requiredPermissions={[kActionCreate]}
            />

            {materialsJSX}

            <AddMaterialModalBottomSheet
                open={addMaterial}
                setOpen={setAddMaterial}
                mutateLoading={mutateLoading || loadingCreate || loadingAdd}
                onSubmit={onSubmit}
            />

            <EditMaterialModalBottomSheet
                open={editCreateInput}
                setOpen={setEditCreateInput}
                onSubmit={onEditCreateMaterialInput}
                createMaterialInput={editCreateInputData}
            />

            <EditVisitMaterialModalBottomSheet
                open={editMaterial}
                setOpen={setEditMaterial}
                data={editData}
                materials={materials}
                onAdd={(material: Partial<AddVisitMaterialInput>, input: IInputsData) => {
                    addMaterialDetail(repeats!, material, input);
                }}
                onDelete={(uuid: string) => {
                    startDelete(repeats!, undefined, uuid);
                }}
                onUpdate={(uuid: string, data: IInputsData) => {
                    onUpdateDetail(repeats!, uuid, data);
                }}
            />
        </>
    );
}
