import {TextAreaProps} from "./InputProps";
import {useStyles as formUseStyles} from "./FormBuilder";
import {TextField} from "@mui/material";
import React from "react";

/**
 * Form component for multiline Text input.
 */
export default function TextArea(props: TextAreaProps) {
    const {
        index,
        className,
        label,
        value,
        updateValue,
        minRows,
        hidden,
        error,
        errorMessage,
        disabled,
    } = props;

    const {classes: formClasses, cx} = formUseStyles();

    /**
     * OnChange update data of this field.
     */
    const OnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateValue(index, e.target.value);
    };

    return (
        <div className={cx(className, (hidden ? formClasses.hidden : undefined))}>
            <TextField
                label={label}
                value={value}
                variant="outlined"
                error={error}
                disabled={disabled}
                helperText={error ? errorMessage : undefined}
                fullWidth={true}
                margin="none"
                multiline={true}
                minRows={minRows}
                onChange={OnChange}/>
        </div>
    );
}
