import {makeStyles} from "tss-react/mui";
import {Box, InputAdornment, Theme} from "@mui/material";
import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {ClientResponse, GetJobsInput, JobJoinedOthersResponseResponsePage} from "../../../../generated/graphql/graphql";
import {RestApiClientContext} from "../../../../core/RestApiProvider";
import {AppDataContext} from "../../../../AppData";
import {processQueryError} from "../../../../service/ErrorService";
import FormBuilder, {IInputsData, InputType} from "../../form/FormBuilder";
import {tt} from "../../../../core/Localization";
import SearchIcon from "../../../../icons/SearchIcon";
import ListShimmer from "../../shimmers/ListShimmer";
import EmptyListText from "../../textComponents/EmptyListText";
import ModalBottomSheet from "../../ModalBottomSheet";
import BottomSheetModalAppbar from "../BottomSheetModalAppbar";
import AppButton from "../../buttons/AppButton";
import {CheckCircle} from "@mui/icons-material";
import SingleJobListItem from "../../jobs/SingleJobListItem";
import {JobNameOrSequenceId, kJobsListDisplayLimitPage} from "../../../../service/JobService";
import {boxContentStyles} from "../../../../styles/UtilStyles";
import {getPublicUrls} from "../../../../service/StorageService";

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        maxHeight: '80vh',
        height: '100vh',
        overflow: "auto",
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
    },
}));

export interface IChooseJobModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    client: ClientResponse | undefined;
    jobId: string | undefined;
    setJobId: Dispatch<SetStateAction<string | undefined>>;
}

export default function ChooseJobModalBottomSheet(props: IChooseJobModalBottomSheetProps) {
    const {
        open,
        setOpen,
        jobId,
        setJobId,
        client,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const {classes} = useStyles();
    const {classes: boxContentClasses} = boxContentStyles();

    const appDataContext = useContext(AppDataContext);
    const {companyId, storage, setStorage} = appDataContext;

    const [selected, setSelected] = useState<string | undefined>(jobId);

    const [jobsCount, setJobsCount] = useState<number>(0);
    const [jobsDisplayLimit, setJobsDisplayLimit] = useState<number>(kJobsListDisplayLimitPage);

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            type: InputType.Text,
            label: '',
            inputVariant: 'standard',
            extraStyle: 'thin',
            hideLabel: true,
            placeholder: tt('jobs.screen.search.placeholder'),
            value: '',
            required: true,
            isClearable: true,
            innerPrefixJSX: (
                <InputAdornment position={"start"}>
                    <SearchIcon/>
                </InputAdornment>
            ),
        },
    });
    const theQuery = inputs.search.value;

    useEffect(() => {
        if (open) {
            setInputs({
                search: {
                    ...inputs.search,
                    value: '',
                },
            });
        }
    }, [open]);

    const clientId = client?.id;

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<JobJoinedOthersResponseResponsePage | undefined>(undefined);
    useEffect(() => {
        if (open) {
            if (clientId) {
                restApiGet({
                    uri: '/job/search-joined-others',
                    params: {
                        companyId: companyId!,
                        clientId,
                        query: theQuery || undefined,
                        page: theQuery && theQuery.length > 0 ? undefined : 0,
                        pageSize: theQuery && theQuery.length > 0 ? undefined : (kJobsListDisplayLimitPage * 4),
                    } as GetJobsInput,
                    setLoading,
                    onData: setData,
                    onError: (error) => {
                        processQueryError(appDataContext, error);
                    },
                });
            } else {
                setData(undefined);
            }
        }
    }, [open, clientId, theQuery]);

    useEffect(() => {
        if (data && open) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(data.files || []),
                    ],
                };
            });
        }
    }, [data, open]);

    const content = useMemo(
        () => {
            let jobsDisplaying = 0;

            let jobs = data?.jobs;
            if (jobs) {
                const theQueryValue = theQuery?.toLowerCase();

                jobs = jobs.filter(job => {
                    const theName = JobNameOrSequenceId(job).toLowerCase();
                    const theQueryFilter = !theQueryValue || theName.includes(theQueryValue);

                    return job.clientId === clientId && theQueryFilter;
                })
                    .sort((a, b) => b.createdAt - a.createdAt);
            }

            setJobsCount(jobs?.length || 0);

            return loading && !data ? (
                <ListShimmer items={3} showIcon={false}/>
            ) : data && jobs && jobs?.length > 0 ?
                jobs
                    .map(job => {
                        if (jobsDisplayLimit <= jobsDisplaying && jobsDisplaying !== 0) {
                            return null;
                        }

                        jobsDisplaying++;

                        return (
                            <SingleJobListItem
                                key={job.id}
                                data={job}
                                stats={data!.stats.find(stats => stats.jobId === job.id)}
                                client={client}
                                responsiblePerson={data!.employees.find(employee => employee.id === job.responsiblePersonId)}
                                onClick={() => setSelected(job.id)}
                                customActionWidget={
                                    selected === job.id ? <CheckCircle color={"primary"}/> : <></>
                                }
                                files={data?.files}
                            />
                        );
                    })
                : (
                    <EmptyListText text={tt('chooseJob.Modal.emptyListMessage')}/>
                );
        },
        [data, inputs.search.value, selected, jobsDisplayLimit, storage.publicUrlsForFiles]
    );

    useEffect(() => {
        setJobsDisplayLimit(kJobsListDisplayLimitPage);
    }, [inputs.search.value]);

    return (
        <ModalBottomSheet
            tallOnMobile={true}
            blurBackdrop={true}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
            children={
                <Box className={classes.container}>
                    <BottomSheetModalAppbar
                        title={tt('chooseJob.modalBottomSheet.title')}
                        onClose={() => setOpen(false)}
                        bottomContent={
                            <>
                                <FormBuilder inputs={inputs} setInputs={setInputs}/>
                            </>
                        }
                    />

                    {content}

                    {jobsDisplayLimit < jobsCount ? (
                        <>
                            <Box sx={{pb: 2}}/>

                            <Box className={boxContentClasses.centered}>
                                <AppButton variant="outlined" color="primary" disabled={loading}
                                           onClick={() => setJobsDisplayLimit(jobsDisplayLimit + kJobsListDisplayLimitPage)}>
                                    {tt('common.loadMore')}
                                </AppButton>
                            </Box>

                            <Box sx={{pb: 2}}/>
                        </>
                    ) : undefined}

                    <Box sx={{pb: 10}}/>

                    <Box className={classes.buttonContainer}>
                        <AppButton
                            variant={"contained"}
                            fullWidth={true}
                            onClick={() => {
                                setJobId(selected)

                                setOpen(false);
                            }}>
                            {tt('common.save')}
                        </AppButton>
                    </Box>
                </Box>
            }
        />
    );
}
