import React, {Dispatch, SetStateAction, useContext, useEffect, useId, useState} from "react";
import {AppContext} from "../../../App";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import {usePopupState} from "material-ui-popup-state/hooks";
import DetailScreenShimmer from "../../components/shimmers/DetailScreenShimmer";
import AppListItem from "../../components/listItems/AppListItem";
import {Box, Divider, Menu, MenuItem} from "@mui/material";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import PaperAppbar from "../../components/paper/PaperAppbar";
import AppIconButton from "../../components/buttons/AppIconButton";
import PencilIcon from "../../../icons/PencilIcon";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MoreFilledIcon from "../../../icons/MoreFilledIcon";
import DeleteIcon from "../../../icons/DeleteIcon";
import {locationDetailRoute} from "../locations/LocationDetailScreen";
import {useMutation} from "@apollo/client";
import {
    CreateJobFormTemplateInput,
    DeleteLocationPlaceDocument,
    DeleteLocationPlaceMutation,
    DeleteLocationPlaceMutationVariables,
    GetLocationPlaceProfileInput,
    LocationPlaceProfileResponse,
    ManageAssignedClientContactsOfLocationPlaceDocument,
    ManageAssignedClientContactsOfLocationPlaceMutation,
    ManageAssignedClientContactsOfLocationPlaceMutationVariables,
    ManageAssignedJobFormsOfLocationPlaceDocument,
    ManageAssignedJobFormsOfLocationPlaceMutation,
    ManageAssignedJobFormsOfLocationPlaceMutationVariables
} from "../../../generated/graphql/graphql";
import {HideConfirmModal, SetConfirmModal} from "../../components/modals/AppModals";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import {AppDataContext, SetAppBreadcrumbs} from "../../../AppData";
import {PlaceEditRoute} from "./PlaceEditScreen";
import IBreadcrumb from "../../../model/Breadcrumb";
import {kClientsBreadcrumb, kClientsRoute} from "../clients/ClientsScreen";
import {clientDetailRoute} from "../clients/ClientDetailScreen";
import {PlacesRoute} from "./PlacesScreen";
import AppBreadCrumb from "../../components/breadCrumb/AppBreadCrumb";
import {LocationNameDisplay, LocationTypeDisplay} from "../../../service/LocationService";
import ContactSection from "../../screenSections/contactSection/ContactSection";
import AppTabsComponent from "../../components/AppTabsComponent";
import DetailListPreviewSection from "../../screenSections/detailListPreviewSection/DetailListPreviewSection";
import {UserFullName} from "../../../service/UserService";
import UserIcon from "../../../icons/UserIcon";
import {contactPersonDetailRoute, ContactPersonDetailRouteKind} from "../contactPerson/ContactPersonDetailScreen";
import ChooseClientContactModalBottomSheet
    from "../../components/modals/job/editJob/ChooseClientContactModalBottomSheet";
import Icons8Here from "../../../icons/Icons8-here";
import ChooseJobFormModalBottomSheet, {
    IChooseJobFormModalBottomSheetOnSaveParams
} from "../../components/modals/job/editJob/ChooseJobFormModalBottomSheet";
import HeadlineWithButton from "../../screenSections/detailListPreviewSection/HeadlineWithButton";
import JobFormItem from "../../components/jobs/newJob/JobFormItem";
import IEventSystemListener from "../../../model/EventSystemListener";
import {listenToEventSystem, unListenToEventSystem} from "../../../service/EventSystemService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import {processQueryError} from "../../../service/ErrorService";
import Icons8Contacts from "../../../icons/Icons8Contacts";
import Icons8ClipBoardList from "../../../icons/Icons8ClipBoardList";
import CreateContactPersonModal from "../../components/contactPerson/CreateContactPersonModal";
import {
    kActionDelete,
    kActionUpdate,
    kPermissionsClients,
    kPermissionsForms,
    kTopicClients,
    kTopicLocationPlaces,
    kTopicLocations
} from "../../../core/constants";
import PermissionValid from "../../components/permissions/PermissionValid";
import {RestApiClientContext} from "../../../core/RestApiProvider";

export const kPlaceDetailRoute = '/clients/:clientId/locations/:locationId/places/:placeId';

/**
 * Shorthand to create Route url.
 */
export function PlaceDetailRoute(clientId: string | number, locationId: string | number, placeId: string | number): string {
    return kPlaceDetailRoute
        .replace(':locationId', `${locationId}`)
        .replace(':clientId', `${clientId}`)
        .replace(':placeId', `${placeId}`);
}

/**
 * Screen component for detail of Plac.
 */
export default function PlaceDetailScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);

    const {clientId, locationId, placeId} = useParams();
    const navigate = useNavigate();

    const [chooseContact, setChooseContact] = useState<boolean>(false);
    const [createContactModal, setCreateContactModal] = useState<boolean>(false);
    const [chooseJobForm, setChooseJobForm] = useState<boolean>(false);
    // const [createJobFormModal, setCreateJobFormModal] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<LocationPlaceProfileResponse | NullOrUndefined>();
    const [refetch, setRefetch] = useState<number>(0);
    useEffect(() => {
        if (placeId) {
            const subscription = subscribe(
                kTopicLocationPlaces,
                {
                    uri: '/location/place/profile',
                    params: {
                        locationPlaceId: parseInt(placeId!),
                        includeClientContacts: true,
                        includeJobForm: true
                    } as GetLocationPlaceProfileInput,
                    setLoading,
                    onData: setData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                (notifications: IEventSystemNotification[]) => {
                    return notifications.some(notification => {
                        if (notification.action !== kActionUpdate) {
                            return false;
                        }

                        return notification.data.id === parseInt(placeId!);
                    });
                },
            );

            return () => subscription.cancel();
        } else {
            setLoading(false);
            setData(null);
        }
    }, [placeId, refetch]);

    const [mutateDelete, {
        loading: deleteLoading,
        error: deleteError,
        data: deleteData,
    }] = useMutation<DeleteLocationPlaceMutation, DeleteLocationPlaceMutationVariables>(DeleteLocationPlaceDocument);

    const [mutateAssign, {
        loading: assignLoading,
        error: assignError,
        data: assignData,
    }] = useMutation<ManageAssignedClientContactsOfLocationPlaceMutation, ManageAssignedClientContactsOfLocationPlaceMutationVariables>(ManageAssignedClientContactsOfLocationPlaceDocument);

    const [mutateAssignJobForm, {
        loading: assignJobFormLoading,
        error: assignJobFormError,
        data: assignJobFormData,
    }] = useMutation<ManageAssignedJobFormsOfLocationPlaceMutation, ManageAssignedJobFormsOfLocationPlaceMutationVariables>(ManageAssignedJobFormsOfLocationPlaceDocument);

    /**
     * Set breadcrumbs.
     */
    const SetBreadcrumbs = () => {
        const breadcrumbs: IBreadcrumb[] = [kClientsBreadcrumb];

        if (data) {
            breadcrumbs.push({
                key: 'client-detail',
                text: data!.client.name,
                route: clientDetailRoute(clientId!),
            });

            breadcrumbs.push({
                key: 'location-detail',
                text: LocationNameDisplay(data!.location.name, data!.location.type, data!.location.address),
                route: locationDetailRoute(clientId!, locationId!),
            });

            breadcrumbs.push({
                key: 'place-detail',
                text: data!.place.name,
                route: PlaceDetailRoute(clientId!, locationId!, placeId!),
            });
        }

        SetAppBreadcrumbs(appDataContext, breadcrumbs);
    };

    useEffect(() => {
        setTitle(tt('placeDetail.screen.title'));

        SetBreadcrumbs();
    }, []);

    useEffect(() => {
        SetBreadcrumbs();
    }, [data]);

    useEffect(() => {
        SetConfirmModal(appDataContext, {
            loading: deleteLoading,
        });
    }, [deleteLoading]);

    /**
     * Mutation to delete Place to BE.
     */
    const DeletePlace = async () => {
        try {
            const variables: DeleteLocationPlaceMutationVariables = {
                input: {
                    locationPlaceId: parseInt(placeId!),
                },
            };

            const result = await mutateDelete({variables});

            if (!result.errors) {
                HideConfirmModal(appDataContext);

                navigate(locationDetailRoute(clientId!, locationId!));

                SuccessToast(tt('placeDetail.screen.delete.success'));
            }
        } catch (e) {
            console.error(e);
            ErrorToast(tt('common.mutation.error'));
        }
    };

    /**
     * Mutation to assign Contacts to Location Place to BE.
     */
    const AssignContacts = async (ids: number[]) => {
        try {
            const variables: ManageAssignedClientContactsOfLocationPlaceMutationVariables = {
                input: {
                    locationPlaceId: parseInt(placeId!),
                    clientContacts: ids,
                },
            };

            const result = await mutateAssign({variables});

            if (!result.errors) {
                setChooseContact(false);

                SuccessToast(tt('assignContacts.modal.success'));
            }
        } catch (e) {
            console.error(e);

            ErrorToast(tt('common.mutation.error'));
        }
    };

    /**
     * Mutation to assign Job Form to Location Place to BE.
     */
    const AssignJobForm = async (params: IChooseJobFormModalBottomSheetOnSaveParams) => {
        try {
            const variables: ManageAssignedJobFormsOfLocationPlaceMutationVariables = {
                input: {
                    locationPlaceId: parseInt(placeId!),
                    jobForms: params.ids!,
                },
            };

            const result = await mutateAssignJobForm({variables});

            if (!result.errors) {
                setChooseJobForm(false);

                SuccessToast(
                    params.ids!.length > 0 ? tt('assignJobForm.modal.success') : tt('assignJobForm.modal.remove.success'),
                );
            }
        } catch (e) {
            console.error(e);

            ErrorToast(tt('common.mutation.error'));
        }
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                isMobile={isMobile}
                loading={loading}
                data={data}
                deletePlace={DeletePlace}
                setChooseContact={setChooseContact}
                setChooseJobForm={setChooseJobForm}
                assignJobForm={AssignJobForm}
                // setCreateJobFormModal={setCreateJobFormModal}
                setCreateContactModal={setCreateContactModal}
            />
        );
    }

    return (
        <>
            <EventSystemListeners refetch={() => setRefetch(prev => prev + 1)}/>

            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}/>

            <ChooseClientContactModalBottomSheet
                open={chooseContact}
                setOpen={setChooseContact}
                clientId={parseInt(clientId!)}
                existingContactIds={data?.place.clientContactIds || []}
                updateLoading={assignLoading}
                onSave={AssignContacts}
                canSaveToClear={true}
            />

            <CreateContactPersonModal
                open={createContactModal}
                setOpen={setCreateContactModal}
                clientId={parseInt(clientId!)}
                navigation={false}
                onCreate={(id: number) => {
                    const existing = data?.place.clientContactIds || [];

                    AssignContacts([...existing, id]);
                }}
            />

            <ChooseJobFormModalBottomSheet
                open={chooseJobForm}
                setOpen={setChooseJobForm}
                existingFormIds={data?.place.jobFormIds ? data?.place.jobFormIds : undefined}
                onSave={AssignJobForm}
                singleSelection={true}
            />

            {/*<CreateJobFormModal
                open={createJobFormModal}
                setOpen={setCreateJobFormModal}
                onCreateNoBESave={(input: CreateJobFormTemplateInput) => {
                    //TODO TCH create job form
                    // setJobFormInputs(prev => {
                    //     return [...prev, input];
                    // });
                }}
            />*/}
        </>
    );
}

interface IEventSystemListenersProps {
    refetch: VoidFunction;
}

/**
 * Component for EventSystem listeners.
 */
function EventSystemListeners(props: IEventSystemListenersProps) {
    const {refetch} = props;

    const {clientId, locationId, placeId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicClients,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(clientId!);
                });

                if (doDelete) {
                    navigate(kClientsRoute);
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [clientId]);

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicLocations,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(locationId!);
                });

                if (doDelete) {
                    if (clientId) {
                        navigate(clientDetailRoute(clientId!));
                    } else {
                        navigate(kClientsRoute);
                    }
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [locationId, clientId]);

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicLocationPlaces,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(placeId!);
                });

                if (doDelete) {
                    if (locationId) {
                        navigate(locationDetailRoute(clientId!, locationId!));
                    } else if (clientId) {
                        navigate(clientDetailRoute(clientId!));
                    } else {
                        navigate(kClientsRoute);
                    }
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [locationId, clientId, placeId]);

    return null;
}

interface IBodyProps {
    isMobile?: boolean;
    loading: boolean;
    data?: LocationPlaceProfileResponse | NullOrUndefined;
    deletePlace: VoidFunction;
    setChooseContact: Dispatch<SetStateAction<boolean>>;
    setChooseJobForm: Dispatch<SetStateAction<boolean>>;
    assignJobForm: (params: IChooseJobFormModalBottomSheetOnSaveParams) => Promise<void>;
    setCreateContactModal: Dispatch<SetStateAction<boolean>>;
    // setCreateJobFormModal: Dispatch<SetStateAction<boolean>>;
}

/**
 * Main body component.
 */
function Body(props: IBodyProps) {
    const {
        isMobile,
        loading,
        data,
        deletePlace,
        setChooseContact,
        setChooseJobForm,
        assignJobForm,
        // setCreateJobFormModal,
        setCreateContactModal,
    } = props;

    const appDataContext = useContext(AppDataContext);

    const {clientId, locationId, placeId} = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const jobFormJSX = (
        <PermissionValid
            permission={kPermissionsForms}
        >
            <Divider/>

            <HeadlineWithButton
                title={tt('common.jobForm')}

                iconJSX={<Icons8ClipBoardList/>}
                onClick={() => {
                    setChooseJobForm(true);
                }}
                buttonText={tt('common.select')}
                permission={kPermissionsClients}
                requiredPermissions={[kActionUpdate]}

                /*icon2JSX={<Icons8Plus/>}
                button2OnClick={() => {
                    //TODO TCH create job form
                    setCreateJobFormModal(true);
                }}
                button2Text={tt('newJob.screen.button.newJobForm.label')}*/
            />

            {data?.jobForm ? (
                <JobFormItem
                    data={data?.jobForm}
                    onDelete={(id) => assignJobForm({ids: []})}
                />
            ) : undefined}
        </PermissionValid>
    );

    const contentJSX = loading && !data ? <DetailScreenShimmer/> :
        (
            <>
                <AppListItem
                    customAvatarInCircle={<Icons8Here/>}
                    title={data?.place.name || tt('common.notFilledIn')}
                    description={LocationTypeDisplay(data?.place.type || '')}
                    actionWidget={
                        <></>
                    }/>

                <AppTabsComponent
                    data={[
                        {
                            label: tt('common.location'),
                            content: <>
                                <ContactSection
                                    hideEmail={true}
                                    hideAddress={true}
                                    phone={data?.place?.phoneNumber}
                                />

                                <Divider/>

                                <DetailListPreviewSection
                                    headlineWidgetProps={
                                        {
                                            title: tt('locationDetail.screen.contactPersons'),

                                            iconJSX: <Icons8Contacts/>,
                                            onClick: (e) => setChooseContact(true),
                                            buttonText: tt('common.AddContactPerson'),
                                            permission: kPermissionsClients,
                                            requiredPermissions: [kActionUpdate],

                                            // button2Text: tt('locationDetail.screen.newPerson'),
                                            // icon2JSX: <Icons8Plus/>,
                                            // button2OnClick: (e) => {
                                            //     //TODO TCH create contact
                                            //     setCreateContactModal(true);
                                            // },
                                        }
                                    }
                                    itemPropsMultiple={
                                        data?.clientContacts?.map(item => ({
                                            listKey: `${item.id}`,
                                            title: UserFullName(item.name, item.surname),
                                            description: item.role || '',
                                            customAvatar: <UserIcon/>,
                                            onClick: () => navigate(contactPersonDetailRoute(item.clientId, item.id, ContactPersonDetailRouteKind.fromPlace, locationId, placeId)),
                                        }))
                                    }
                                />

                                {jobFormJSX}
                            </>
                        },
                    ]}
                />
            </>
        );

    return (
        <ScreenContent
            showBreadCrumb={true}
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    isMobile={isMobile}
                    title={tt('placeDetail.content.title')}
                    backRoute={location.search.includes('list=1') ? PlacesRoute(clientId!, locationId!) : locationDetailRoute(clientId!, locationId!)}>
                    <PermissionValid
                        permission={kPermissionsClients}
                        requiredPermissions={[kActionUpdate]}
                    >
                        <AppIconButton key={'placeDetailBtn1'}
                                       onClick={() => navigate(PlaceEditRoute(clientId!, locationId!, placeId!))}><PencilIcon/></AppIconButton>
                    </PermissionValid>

                    <PermissionValid
                        permission={kPermissionsClients}
                        requiredPermissions={[kActionDelete]}
                    >
                        <AppIconButton key={'placeDetailBtn2'}
                                       {...bindTrigger(settingsPopupState)}
                        >
                            <MoreFilledIcon/>
                        </AppIconButton>
                    </PermissionValid>
                </PaperAppbar>

                {isMobile ? <AppBreadCrumb/> : null}

                {contentJSX}

                <Menu {...bindMenu(settingsPopupState)}>
                    <PermissionValid
                        permission={kPermissionsClients}
                        requiredPermissions={[kActionDelete]}
                    >
                        <MenuItem key={'placeDetailDeleteKey'} onClick={() => {
                            settingsPopupState.close();

                            SetConfirmModal(appDataContext, {
                                open: true,
                                title: tt('placeDetail.delete.confirm.title'),
                                subtitle: tt('placeDetail.delete.confirm.subtitle'),
                                confirmationButtonText: tt('common.delete'),
                                cancelButtonText: tt('common.close'),
                                children: <></>,
                                onConfirm: deletePlace,
                            });
                        }}>
                            <Box sx={{color: kAppColors.red.confirmButton}}><DeleteIcon/></Box>
                            {tt('common.delete')}
                        </MenuItem>
                    </PermissionValid>
                </Menu>
            </AppPaper>
        </ScreenContent>
    );
}
