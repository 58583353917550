import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8UserLocationProps {
    className?: string;
}

export default function Icons8UserLocation(props: IIcons8UserLocationProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <div className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 24 2 C 15.181311 2 8 9.1813107 8 18 C 8 21.8151 10.357007 26.360033 13.083984 30.84375 C 15.810645 35.326947 19.002634 39.610412 20.974609 42.152344 C 22.484322 44.098951 25.513724 44.098951 27.023438 42.152344 C 28.996001 39.610303 32.189219 35.326962 34.916016 30.84375 C 37.643129 26.360017 40 21.8151 40 18 C 40 9.1813107 32.818689 2 24 2 z M 24 5 C 31.197311 5 37 10.802689 37 18 C 37 20.4079 34.978949 24.965389 32.351562 29.285156 C 29.724176 33.604924 26.585786 37.825385 24.654297 40.314453 C 24.29801 40.773846 23.70199 40.773846 23.345703 40.314453 C 21.414624 37.825276 18.275709 33.604939 15.648438 29.285156 C 13.021164 24.965374 11 20.4079 11 18 C 11 10.802689 16.802689 5 24 5 z M 24 11 A 4 4 0 0 0 24 19 A 4 4 0 0 0 24 11 z M 18.447266 21 C 17.647266 21 17 21.647266 17 22.447266 L 17 23.751953 C 17 25.974953 19.837 28 24 28 C 28.163 28 31 25.974953 31 23.751953 L 31 22.447266 C 31 21.647266 30.352734 21 29.552734 21 L 18.447266 21 z"/>
            </svg>
        </div>
    );
}
