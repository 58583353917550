import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8InvoiceProps {
    className?: string;
}

export default function Icons8Receipt(props: IIcons8InvoiceProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 9.5273438 5 A 1.50015 1.50015 0 0 0 8 6.5 L 8 37.5 C 8 40.519774 10.480226 43 13.5 43 L 34.5 43 C 37.519774 43 40 40.519774 40 37.5 L 40 6.5 A 1.50015 1.50015 0 0 0 37.890625 5.1289062 L 33.96875 6.8730469 L 29.556641 5.1074219 A 1.50015 1.50015 0 0 0 28.443359 5.1074219 L 24 6.8847656 L 19.556641 5.1074219 A 1.50015 1.50015 0 0 0 18.443359 5.1074219 L 14.03125 6.8730469 L 10.109375 5.1289062 A 1.50015 1.50015 0 0 0 9.5273438 5 z M 19 8.1152344 L 23.443359 9.8925781 A 1.50015 1.50015 0 0 0 24.556641 9.8925781 L 29 8.1152344 L 33.443359 9.8925781 A 1.50015 1.50015 0 0 0 34.609375 9.8710938 L 37 8.8085938 L 37 37.5 C 37 38.898226 35.898226 40 34.5 40 L 13.5 40 C 12.101774 40 11 38.898226 11 37.5 L 11 8.8085938 L 13.390625 9.8710938 A 1.50015 1.50015 0 0 0 14.556641 9.8925781 L 19 8.1152344 z M 15.5 17 A 1.50015 1.50015 0 1 0 15.5 20 L 32.5 20 A 1.50015 1.50015 0 1 0 32.5 17 L 15.5 17 z M 15.5 26 A 1.50015 1.50015 0 1 0 15.5 29 L 24.5 29 A 1.50015 1.50015 0 1 0 24.5 26 L 15.5 26 z M 30.5 26 A 1.50015 1.50015 0 1 0 30.5 29 L 32.5 29 A 1.50015 1.50015 0 1 0 32.5 26 L 30.5 26 z M 15.5 32 A 1.50015 1.50015 0 1 0 15.5 35 L 24.5 35 A 1.50015 1.50015 0 1 0 24.5 32 L 15.5 32 z M 30.5 32 A 1.50015 1.50015 0 1 0 30.5 35 L 32.5 35 A 1.50015 1.50015 0 1 0 32.5 32 L 30.5 32 z"/>
            </svg>
        </span>
    );
}
