import React, {useContext, useEffect} from "react";
import {AppContext} from "../../App";
import ResponsiveContainer from "../components/screens/ResponsiveContainer";
import {Box, Theme, Typography, useTheme} from "@mui/material";
import AppPaper from "../components/paper/AppPaper";
import TimotyLogo from "../../icons/TimotyLogo";
import AppButton from "../components/buttons/AppButton";
import {tt} from "../../core/Localization";
import ScreenContent from "../components/screens/ScreenContent";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "tss-react/mui";

export const kNoMatchRoute = '*';

export const useStyles = makeStyles()((theme: Theme) => ({
    paper: {
        padding: theme.spacing(6),
        maxWidth: 620,
        width: '100%'
    }
}));

/**
 * Screen component for when URL does not match any Route.
 */
export default function NoMatchScreen() {
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    useEffect(() => {
        setTitle(tt('noMatch.screen.title'));
    }, []);

    return (<ResponsiveContainer
            smallPhoneScreen={<Body isMobile={true}/>}
            largePhoneScreen={<Body isMobile={true}/>}
            tabletScreen={<Body/>}
            smallDesktopScreen={<Body/>} largeDesktopScreen={<Body/>}
            extraLargeDesktopScreen={<Body/>}/>
    );
}


interface IBodyProps {
    isMobile?: boolean;
}

/**
 * NoMatch screen body contents.
 */
function Body(props: IBodyProps) {
    const {isMobile} = props;
    const appContext = useContext(AppContext);
    const {authUser} = appContext;

    const theme = useTheme();

    const navigate = useNavigate();

    const {classes, cx} = useStyles();

    return (
        <ScreenContent appBar={!isMobile && !!authUser} navigationDrawer={!isMobile && !!authUser}
                       bottomBar={isMobile && !!authUser} centerContent={true}>
            <AppPaper centerContent={true} className={cx(classes.paper)}>
                <Box sx={{mb: 2}}>
                    <TimotyLogo size={80}></TimotyLogo>
                </Box>

                <Typography variant="h1" paragraph={true}
                            sx={{color: theme.palette.primary.main, textAlign: 'center', fontWeight: 400}}>
                    {tt('404.screen.title')}
                </Typography>
                <Box sx={{mb: 1}}/>
                <AppButton variant={"contained"} onClick={() => navigate(-1)} fullWidth={true}>
                    {tt('404.screen.button.title')}
                </AppButton>
                <Box sx={{mb: 1}}/>
            </AppPaper>
        </ScreenContent>
    );
}
