import {iconUseStyles} from "../styles/IconStyles";

export interface IArrowLeftIconProps {
    className?: string;
    size?: number;
}

export default function ArrowLeftIcon(props: IArrowLeftIconProps) {
    const {className, size} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName} style={{width: size, height: size}}>
            <svg fill="currentColor" id="icons8-left" xmlns="http://www.w3.org/2000/svg" width="20" height="17.778" viewBox="0 0 20 17.778">
                <path id="icons8-left_1_" d="M14.1,25.452a1.111,1.111,0,0,0,1.571-1.571L9.793,18h15.1a1.111,1.111,0,1,0,0-2.222H9.793L15.675,9.9A1.111,1.111,0,1,0,14.1,8.326L6.326,16.1a1.111,1.111,0,0,0,0,1.571Z" transform="translate(-6 -8)"/>
            </svg>
        </span>
    );
}

