import {Box, Theme, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../../styles/AppThemeProcessor";


const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        width: 52,
        flexShrink: 0,
        position: "relative",
    },
    barOuter: {
        minHeight: 4,
        backgroundColor: theme.palette.mode === "dark" ? '#292929' : '#eaeaea',
        cursor: "pointer",
        overflow: "clip",
        borderRadius: 4,
        width: 20,
        display: "flex",
        flexDirection: "column-reverse",
        transition: 'height 0.5s ease-out',
    },
    barMoreOuter: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: "end",
        alignItems: "center",
    },
    barInner: {
        borderRadius: 4,
        transition: 'height 0.5s ease-out',
    },
    selectedOuter: {
        backgroundColor: '#c7eba9',
    },
    selectedInner: {
        borderRadius: 0,
        backgroundColor: kAppColors.green.main,
    },
    label1: {
        paddingTop: 4,
        textAlign: "center",
        fontSize: 12,
        fontWeight: 700,
    },
}));

export interface ISingleBarProps {
    height: number;
    maxHeight: number;
    totalMaxHeight: number;
    labelLine1: string;
    labelLine2: string;
    isSelected: boolean;
    onClick: Function;
}

export default function SingleBar(props: ISingleBarProps) {
    const {onClick, labelLine1, labelLine2, maxHeight, totalMaxHeight, height, isSelected} = props;

    const {classes, cx} = useStyles();

    return (
        <Box className={classes.container} onClick={() => onClick()}>
            <Box className={classes.barMoreOuter}
                 style={{height: totalMaxHeight}}
            >
                <Box className={cx(classes.barOuter, isSelected ? classes.selectedOuter : null)}
                     style={{height: maxHeight}}>
                    <Box className={cx(classes.barInner, isSelected ? classes.selectedInner : null)}
                         style={{height: height}}></Box>
                </Box>
            </Box>
            <Typography className={cx(classes.label1)}>{labelLine1}</Typography>
        </Box>
    );
}
