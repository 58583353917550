import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../../form/FormBuilder";
import {tt} from "../../../../../core/Localization";
import ModalBottomSheet from "../../../ModalBottomSheet";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import ContentPadding from "../../../paper/ContentPadding";
import {Box} from "@mui/material";
import AppButton from "../../../buttons/AppButton";
import {useMutation} from "@apollo/client";
import {
    JobUpdateRepeats, RenameVisitJobFormDocument, RenameVisitJobFormMutation, RenameVisitJobFormMutationVariables,
} from "../../../../../generated/graphql/graphql";
import {processMutationError} from "../../../../../service/ErrorService";
import {SuccessToast} from "../../../../../service/ToastService";

export interface IEditVisitJobFormNameModalBottomSheetProps {
    visitId?: number;
    repeatingDay?: number;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    modalAboveModals?: boolean;
    jobFormUUID?: string | NullOrUndefined;
    existingName?: string | NullOrUndefined;
    onRename?: (name: string) => void;
}

/**
 * Component for editing the name of a JobForm assigned to a Visit.
 */
export default function EditVisitJobFormNameModalBottomSheet(props: IEditVisitJobFormNameModalBottomSheetProps) {
    const {
        visitId,
        repeatingDay,
        open,
        setOpen,
        modalAboveModals,
        jobFormUUID,
        existingName,
        onRename,
    } = props;

    const [mutateRenameJobJobForm, {
        loading: updateLoading,
    }] = useMutation<RenameVisitJobFormMutation, RenameVisitJobFormMutationVariables>(RenameVisitJobFormDocument);

    const [inputs, setInputs] = useState<IInputsData>({
        name: {
            type: InputType.Text,
            label: `${tt('common.name')}*`,
            value: '',
            required: true,
        },
    });

    useEffect(() => {
        if (existingName && open) {
            setInputs({
                name: {
                    ...inputs.name,
                    value: existingName || '',
                },
            });
        }
    }, [existingName, open]);

    /**
     * Mutate the JobForm name to BE.
     */
    const RenameForm = async () => {
        if (ValidateForm(inputs, setInputs)) {
            if (visitId && jobFormUUID) {
                try {
                    const variables: RenameVisitJobFormMutationVariables = {
                        input: {
                            visitId: visitId,
                            repeatingDay,
                            repeats: JobUpdateRepeats.Single,
                            jobFormUUID: jobFormUUID,
                            name: inputs.name.value,
                        },
                    };

                    const result = await mutateRenameJobJobForm({variables});

                    if (!result.errors) {
                        SuccessToast(tt('editVisitVisitFormName.modal.success'));

                        setOpen(false);
                    }
                } catch (e) {
                    processMutationError(e);
                }
            } else if (onRename) {
                onRename(inputs.name.value);

                SuccessToast(tt('editVisitVisitFormName.modal.success'));

                setOpen(false);
            }
        }
    };

    return (
        <ModalBottomSheet
            modalAboveModals={modalAboveModals}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
            tallOnMobile={true}
        >
            <BottomSheetModalAppbar
                noBorderBottom={true}
                onClose={() => setOpen(false)}
                title={tt('common.name')}>
            </BottomSheetModalAppbar>

            <ContentPadding>
                <FormBuilder inputs={inputs} setInputs={setInputs}/>

                <Box sx={{pb: 2}}/>

                <AppButton
                    variant={"contained"}
                    fullWidth={true}
                    onClick={RenameForm}
                    isLoading={updateLoading}
                >
                    {tt('common.save')}
                </AppButton>
            </ContentPadding>
        </ModalBottomSheet>
    );
}
