import {makeStyles} from "tss-react/mui";
import {Menu, Theme} from "@mui/material";
import {usePopupState} from "material-ui-popup-state/hooks";
import AppIconButton from "../buttons/AppIconButton";
import {bindMenu} from "material-ui-popup-state";
import React, {useContext, useEffect, useId, useState} from "react";
import {tt} from "../../../core/Localization";
import Icons8Bell from "../../../icons/Icons8Bell";
import NotificationsContent from "../notifications/NotificationsContent";
import {useNavigate} from "react-router-dom";
import {kNotificationsRoute} from "../../screens/notifications/NotificationsScreen";
import {countUnReadNotifications, subscribeNotifications} from "../../../service/NotificationService";
import {AppDataContext} from "../../../AppData";

export const useStyles = makeStyles()((theme: Theme) => ({
    iconButton: {
        marginRight: 4,
    },
    popup: {
        '.MuiPaper-root': {
            paddingLeft: 0,
            paddingRight: 0,
            top: '72px !important',
        },
    }
}));

let lastCount = 0;
let lastPlaySoundCount = 0;

export interface INotificationsMenuProps {
    isMobile?: boolean;
}

export default function NotificationsMenu(props: INotificationsMenuProps) {
    const {isMobile} = props;

    const appDataContext = useContext(AppDataContext);
    const {employeeId} = appDataContext;

    const [unReadCount, setUnReadCount] = useState<number>(lastCount);

    useEffect(() => {
        if (employeeId) {
            const unsubscribe = subscribeNotifications({
                employeeId,
                onNext: (snapshot) => {
                    setTimeout(() => {
                        countUnReadNotifications(employeeId)
                            .then((count) => {
                                lastCount = count;
                                lastPlaySoundCount = count;

                                setUnReadCount(count);
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    }, 1);

                    setTimeout(() => {
                        countUnReadNotifications(employeeId)
                            .then((count) => {
                                lastCount = count;
                                lastPlaySoundCount = count;

                                setUnReadCount(count);
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    }, 1000);
                },
                unreadOnly: true,
                loadPages: 1,
            });

            return () => {
                unsubscribe();
            };
        }
    }, [employeeId]);

    const navigate = useNavigate();

    const {classes} = useStyles();

    const notificationsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    return (
        <>
            <AppIconButton
                count={unReadCount}
                tooltip={tt('common.notifications')}
                className={classes.iconButton}
                onClick={(e) => {
                    isMobile ? navigate(kNotificationsRoute) :
                        notificationsPopupState.setOpen(true, e);
                }}
            >
                <Icons8Bell/>
            </AppIconButton>

            <Menu className={classes.popup} {...bindMenu(notificationsPopupState)}>
                <NotificationsContent
                    closeMenu={() => {
                        notificationsPopupState.setOpen(false);
                    }}
                    isTooltip={true}
                />
            </Menu>
        </>
    );
}
