import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import {useNavigate, useParams} from "react-router-dom";
import {
    GetLocationPlaceProfileInput,
    LocationPlaceProfileResponse,
    UpdateLocationPlaceDocument,
    UpdateLocationPlaceMutation,
    UpdateLocationPlaceMutationVariables
} from "../../../generated/graphql/graphql";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {tt} from "../../../core/Localization";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import {IInputsData, ValidateForm} from "../../components/form/FormBuilder";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import PaperAppbar from "../../components/paper/PaperAppbar";
import ContentPadding from "../../components/paper/ContentPadding";
import LocationPlaceForm from "../../components/places/LocationPlaceForm";
import {PlaceDetailRoute} from "./PlaceDetailScreen";
import IBreadcrumb from "../../../model/Breadcrumb";
import {kClientsBreadcrumb, kClientsRoute} from "../clients/ClientsScreen";
import {clientDetailRoute} from "../clients/ClientDetailScreen";
import {locationDetailRoute} from "../locations/LocationDetailScreen";
import {AppDataContext, SetAppBreadcrumbs} from "../../../AppData";
import AppBreadCrumb from "../../components/breadCrumb/AppBreadCrumb";
import {LocationNameDisplay} from "../../../service/LocationService";
import IEventSystemListener from "../../../model/EventSystemListener";
import {listenToEventSystem, unListenToEventSystem} from "../../../service/EventSystemService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import {processQueryError} from "../../../service/ErrorService";
import {kActionDelete, kTopicClients, kTopicLocationPlaces, kTopicLocations} from "../../../core/constants";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";

export const kPlaceEditRoute = '/clients/:clientId/locations/:locationId/places/:placeId/edit';

/**
 * Shorthand to create Route url.
 */
export function PlaceEditRoute(clientId: string | number, locationId: string | number, placeId: string | number): string {
    return kPlaceEditRoute
        .replace(':locationId', `${locationId}`)
        .replace(':clientId', `${clientId}`)
        .replace(':placeId', `${placeId}`);
}

/**
 * Screen component for Location Place edit.
 */
export default function PlaceEditScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);

    const {clientId, locationId, placeId} = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<LocationPlaceProfileResponse | NullOrUndefined>();
    useEffect(() => {
        if (placeId) {
            restApiGet({
                uri: '/location/place/profile',
                params: {
                    locationPlaceId: parseInt(placeId!),
                } as GetLocationPlaceProfileInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading,
                onData: setData,
                onError: (error: any) => processQueryError(appDataContext, error),
            });
        } else {
            setLoading(false);
            setData(null);
        }
    }, [placeId]);

    const [mutateUpdate, {
        loading: updateLoading,
        error: updateError,
        data: updateData,
    }] = useResettableMutation<UpdateLocationPlaceMutation, UpdateLocationPlaceMutationVariables>(UpdateLocationPlaceDocument);

    /**
     * Set breadcrumbs.
     */
    const SetBreadcrumbs = () => {
        const breadcrumbs: IBreadcrumb[] = [kClientsBreadcrumb];

        if (data) {
            breadcrumbs.push({
                key: 'client-detail',
                text: data!.client.name,
                route: clientDetailRoute(clientId!),
            });

            breadcrumbs.push({
                key: 'location-detail',
                text: LocationNameDisplay(data!.location.name, data!.location.type, data!.location.address),
                route: locationDetailRoute(clientId!, locationId!),
            });

            breadcrumbs.push({
                key: 'place-detail',
                text: data!.place.name,
                route: PlaceDetailRoute(clientId!, locationId!, placeId!),
            });

            breadcrumbs.push({
                key: 'place-edit',
                text: tt('placeEdit.screen.title'),
                route: PlaceEditRoute(clientId!, locationId!, placeId!),
            });
        }

        SetAppBreadcrumbs(appDataContext, breadcrumbs);
    };

    useEffect(() => {
        setTitle(tt('placeEdit.screen.title'));

        SetBreadcrumbs();
    }, []);

    useEffect(() => {
        SetBreadcrumbs();
    }, [data]);

    /**
     * Mutate Place update to BE.
     */
    const UpdatePlace = async (inputs: IInputsData, setInputs: Dispatch<SetStateAction<IInputsData>>, placesCount: number) => {
        if (ValidateForm(inputs, setInputs)) {
            try {
                const variables: UpdateLocationPlaceMutationVariables = {
                    input: {
                        locationPlaceId: parseInt(placeId!),
                        name: inputs.place_1_name.value,
                        type: inputs.place_1_type.value.newValue || inputs.place_1_type.value.value,
                        icon: inputs.place_1_type.userData || '',
                        phoneNumber: inputs.place_1_phone.value || undefined,
                    },
                };

                const result = await mutateUpdate({variables});

                if (!result.errors) {
                    navigate(PlaceDetailRoute(clientId!, locationId!, placeId!));

                    SuccessToast(tt('placeEdit.screen.success'));
                }
            } catch (e) {
                console.error(e);

                ErrorToast(tt('common.mutation.error'));
            }
        }
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                isMobile={isMobile}
                updateLoading={updateLoading}
                loading={loading}
                updatePlace={UpdatePlace}
                data={data}
            />
        );
    }

    return (
        <>
            <EventSystemListeners/>

            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}
            />
        </>
    );
}

/**
 * Component for EventSystem listeners.
 */
function EventSystemListeners() {
    const {clientId, locationId, placeId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicClients,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(clientId!);
                });

                if (doDelete) {
                    navigate(kClientsRoute);
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [clientId]);

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicLocations,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(locationId!);
                });

                if (doDelete) {
                    if (clientId) {
                        navigate(clientDetailRoute(clientId!));
                    } else {
                        navigate(kClientsRoute);
                    }
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [locationId, clientId]);

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicLocationPlaces,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(placeId!);
                });

                if (doDelete) {
                    if (locationId) {
                        navigate(locationDetailRoute(clientId!, locationId!));
                    } else if (clientId) {
                        navigate(clientDetailRoute(clientId!));
                    } else {
                        navigate(kClientsRoute);
                    }
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [locationId, clientId, placeId]);

    return null;
}

interface IBodyProps {
    isMobile?: boolean;
    updateLoading?: boolean;
    loading: boolean;
    updatePlace: (inputs: IInputsData, setInputs: Dispatch<SetStateAction<IInputsData>>, placesCount: number) => void;
    data?: LocationPlaceProfileResponse | NullOrUndefined;
}

/**
 * Main body component.
 */
function Body(props: IBodyProps) {
    const {
        isMobile,
        loading,
        updatePlace,
        data,
        updateLoading,
    } = props;

    const {clientId, locationId, placeId} = useParams();

    return (
        <ScreenContent
            showBreadCrumb={true}
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}
            >
                <PaperAppbar isMobile={isMobile}
                             title={tt('placeEdit.screen.title')}
                             backRoute={PlaceDetailRoute(clientId!, locationId!, placeId!)}
                             cancelIconBackButton={true}/>

                {isMobile ? <AppBreadCrumb/> : null}

                <ContentPadding>
                    <LocationPlaceForm onSubmit={updatePlace}
                                       isEdit={true}
                                       loading={loading}
                                       updateLoading={updateLoading}
                                       dataLoading={!data && loading}
                                       name={data?.place.name}
                                       type={data?.place.type}
                                       icon={data?.place.icon}
                    />
                </ContentPadding>
            </AppPaper>
        </ScreenContent>
    );
}
