import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8ClipBoardListPlusProps {
    className?: string;
}

export default function Icons8ClipBoardListPlus(props: IIcons8ClipBoardListPlusProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256"
                 width="24px" height="24px" fill="currentColor">
                <defs>
                    <clipPath id="clip-1">
                        <path
                            d="M0,256v-256h256v256zM189.33333,155.55556c-27.49091,0 -49.77778,22.28687 -49.77778,49.77778c0,27.49091 22.28687,49.77778 49.77778,49.77778c27.49091,0 49.77778,-22.28687 49.77778,-49.77778c0,-27.49091 -22.28687,-49.77778 -49.77778,-49.77778z"
                            id="overlayBgMask" fill="none" opacity="0.5"></path>
                    </clipPath>
                </defs>
                <g clipPath="url(#clip-1)" fill="none" fillRule="nonzero" stroke="none" strokeWidth="1"
                   strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray=""
                   strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none"
                >
                    <g transform="scale(5.33333,5.33333)" fill="currentColor">
                        <path
                            d="M35.5,8h-3.551c-0.252,-2.244 -2.139,-4 -4.449,-4h-7c-2.31,0 -4.197,1.756 -4.449,4h-3.551c-2.481,0 -4.5,2.019 -4.5,4.5v27c0,2.481 2.019,4.5 4.5,4.5h23c2.481,0 4.5,-2.019 4.5,-4.5v-27c0,-2.481 -2.019,-4.5 -4.5,-4.5zM16.5,35c-0.828,0 -1.5,-0.672 -1.5,-1.5c0,-0.828 0.672,-1.5 1.5,-1.5c0.828,0 1.5,0.672 1.5,1.5c0,0.828 -0.672,1.5 -1.5,1.5zM16.5,29c-0.828,0 -1.5,-0.672 -1.5,-1.5c0,-0.828 0.672,-1.5 1.5,-1.5c0.828,0 1.5,0.672 1.5,1.5c0,0.828 -0.672,1.5 -1.5,1.5zM16.5,23c-0.828,0 -1.5,-0.672 -1.5,-1.5c0,-0.828 0.672,-1.5 1.5,-1.5c0.828,0 1.5,0.672 1.5,1.5c0,0.828 -0.672,1.5 -1.5,1.5zM19,8.5c0,-0.827 0.673,-1.5 1.5,-1.5h7c0.827,0 1.5,0.673 1.5,1.5c0,0.827 -0.673,1.5 -1.5,1.5h-7c-0.827,0 -1.5,-0.673 -1.5,-1.5zM31.5,35h-9c-0.829,0 -1.5,-0.672 -1.5,-1.5c0,-0.828 0.671,-1.5 1.5,-1.5h9c0.829,0 1.5,0.672 1.5,1.5c0,0.828 -0.671,1.5 -1.5,1.5zM31.5,29h-9c-0.829,0 -1.5,-0.672 -1.5,-1.5c0,-0.828 0.671,-1.5 1.5,-1.5h9c0.829,0 1.5,0.672 1.5,1.5c0,0.828 -0.671,1.5 -1.5,1.5zM31.5,23h-9c-0.829,0 -1.5,-0.672 -1.5,-1.5c0,-0.828 0.671,-1.5 1.5,-1.5h9c0.829,0 1.5,0.672 1.5,1.5c0,0.828 -0.671,1.5 -1.5,1.5z"></path>
                    </g>
                </g>
                <g fill="currentColor" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt"
                   strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0"
                   fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none"
                >
                    <g transform="translate(146.66667,162.66667) scale(3.87879,3.87879)" id="overlay">
                        <path
                            d="M11,0c-6.075,0 -11,4.925 -11,11c0,6.075 4.925,11 11,11c6.075,0 11,-4.925 11,-11c0,-6.075 -4.925,-11 -11,-11zM18,12h-6v6c0,0.552 -0.448,1 -1,1c-0.552,0 -1,-0.448 -1,-1v-6h-6c-0.552,0 -1,-0.448 -1,-1c0,-0.552 0.448,-1 1,-1h6v-6c0,-0.552 0.448,-1 1,-1c0.552,0 1,0.448 1,1v6h6c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1z"></path>
                    </g>
                </g>
            </svg>
        </span>
    );
}

