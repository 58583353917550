import React, {useEffect, useState} from "react";
import {Box, Divider, Tab, Tabs, Theme, Tooltip} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../styles/AppThemeProcessor";

export const useStyles = makeStyles()((theme: Theme) => ({
    expandedTab: {
        flexGrow: 1,
    },
    labelContainer: {
        display: "flex",
        alignItems: 'center',
        'span': {
            marginRight: 8
        }
    },
    showUnread: {
        position: "relative",
        '&:after': {
            borderRadius: 8,
            content: '""',
            display: "block",
            width: 8,
            height: 8,
            background: kAppColors.red.confirmButton,
            position: "absolute",
            right: 22,
            top: 10,
        }
    },
    iconsOnly: {
        padding: '0 !important',
    },
    iconsOnlyTooltipClass: {
        paddingRight: 35,
        paddingLeft: 35,
        paddingTop: 16,
        paddingBottom: 16,
        'span': {
            marginRight: '0 !important',
        }
    }
}));

export interface IAppTabsComponentProps {
    className?: string;
    fullWidthTabs?: boolean;
    variant?: 'standard' | 'scrollable' | 'fullWidth',
    scrollButtons?: 'auto' | true | false,
    data: Array<{
        index?: string,
        'icon'?: React.ReactNode,
        'label': string,
        'content': React.ReactNode,
        'showUnread'?: boolean,
    }>;
    onTabChange?: (value: string) => void;
    controlledValue?: string;
    iconsOnly?: boolean;
}

export default function AppTabsComponent(props: IAppTabsComponentProps) {
    const {
        data,
        fullWidthTabs,
        onTabChange,
        variant,
        scrollButtons,
        controlledValue,
        iconsOnly,
        className,
    } = props;

    const [tabValue, setTabValue] = useState<string>(controlledValue ? controlledValue : '0');

    useEffect(() => {
        if (controlledValue && controlledValue !== tabValue) {
            setTabValue(controlledValue);
        }
    }, [controlledValue]);

    const {classes, cx} = useStyles();

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);

        if (onTabChange) {
            onTabChange(newValue);
        }
    };

    const tabsJSX = data.map((item, index) => {
        let labelJSX;

        if (iconsOnly) {
            labelJSX = <Tooltip
                className={classes.iconsOnlyTooltipClass}
                title={item.label}
            ><Box className={classes.labelContainer}>
                {item.icon ? item.icon : undefined}
            </Box>
            </Tooltip>;
        } else {
            labelJSX = <Box className={classes.labelContainer}>
                {item.icon ? item.icon : undefined}
                {item.label}
            </Box>;
        }

        return (
            <Tab
                key={'tab' + index}
                className={cx(fullWidthTabs ? classes.expandedTab : undefined, item.showUnread ? classes.showUnread : null, iconsOnly ? classes.iconsOnly : null)}
                value={item.index || `${index}`}
                label={labelJSX}
            />
        );
    });

    const panelsJSX = data.map((item, index) => {
        return (
            <TabPanel
                key={'panel' + index}
                sx={{padding: 0}}
                value={item.index || `${index}`}>
                {item.content}
            </TabPanel>
        );
    });

    return (
        <TabContext
            value={tabValue}
        >
            <Tabs
                className={className}
                variant={variant || "scrollable"}
                scrollButtons={scrollButtons || "auto"}
                allowScrollButtonsMobile
                value={tabValue}
                onChange={handleTabChange}
            >
                {tabsJSX}
            </Tabs>

            <Divider/>

            {panelsJSX}
        </TabContext>
    );
}
