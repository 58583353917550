import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import ModalBottomSheet from "../ModalBottomSheet";
import {Box, Theme} from "@mui/material";
import BottomSheetModalAppbar from "../modals/BottomSheetModalAppbar";
import {tt} from "../../../core/Localization";
import AppButton from "../buttons/AppButton";
import {makeStyles} from "tss-react/mui";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import {CreateMaterialInput} from "../../../generated/graphql/graphql";
import {IMaterialFormUpdate} from "./MaterialForm";

export const useStyles = makeStyles()((theme: Theme) => ({
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
    },
    oneFifth: {
        flexBasis: '20% !important',
        "@media (max-width: 767px)": {
            flexBasis: '100% !important',
        }
    }
}));

export interface IEditMaterialModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onSubmit: (update: IMaterialFormUpdate, uuid: string) => void;
    mutateLoading?: boolean;
    createMaterialInput?: CreateMaterialInput;
}

export default function EditMaterialModalBottomSheet(props: IEditMaterialModalBottomSheetProps) {
    const {
        open,
        setOpen,
        onSubmit,
        mutateLoading,
        createMaterialInput,
    } = props;

    const {classes} = useStyles();

    const [inputs, setInputs] = useState<IInputsData>({
        quantity: {
            testId: 'addMaterialQuantityInput',
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('material.form.label.quantity')}`,
            value: '',
            required: true,
            grid: {
                sm: 6,
                xs: 6,
            },
        },
        unitOfMeasure: {
            testId: 'addMaterialUnitOfMeasureInput',
            type: InputType.Text,
            label: tt('material.form.label.unitOfMeasure'),
            placeholder: tt('material.form.placeholder.unitOfMeasure'),
            value: '',
            required: false,
            grid: {
                sm: 6,
                xs: 6,
            },
        },
    });

    useEffect(() => {
        if (open) {
            setInputs(prev => {
                return {
                    ...prev,
                    quantity: {
                        ...prev.quantity,
                        value: createMaterialInput?.unitCount?.toString() || '',
                    },
                    unitOfMeasure: {
                        ...prev.unitOfMeasure,
                        value: createMaterialInput?.unitName || '',
                    },
                };
            });
        }
    }, [open, createMaterialInput]);

    const SubmitForm = () => {
        if (ValidateForm(inputs, setInputs)) {
            onSubmit({
                inputs: {
                    ...inputs,
                },
            }, createMaterialInput!.uuid);
        }
    };

    return (
        <>
            <ModalBottomSheet
                blurBackdrop={true}
                open={open}
                setOpen={setOpen}
                hideHeader={true}
                tallOnMobile={true}
            >
                <Box>
                    <BottomSheetModalAppbar
                        noBorderBottom={true}
                        title={tt('editMaterialModal.title')}
                        onClose={() => setOpen(false)}
                    />

                    <Box pr={2} pl={2}>
                        <FormBuilder
                            inputs={inputs}
                            setInputs={setInputs}
                        />
                    </Box>

                    <Box pb={9}/>

                    <Box className={classes.buttonContainer}>
                        <AppButton
                            variant={"contained"}
                            fullWidth={true}
                            isLoading={mutateLoading}
                            onClick={() => SubmitForm()}>
                            {tt('common.save')}
                        </AppButton>
                    </Box>
                </Box>
            </ModalBottomSheet>
        </>
    );
}
