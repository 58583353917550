import {iconUseStyles} from "../styles/IconStyles";
import {useId} from "react";

export interface IIcons8CalendarWithQuestionMarkProps {
    className?: string;
}

export default function Icons8CalendarWithQuestionMark(props: IIcons8CalendarWithQuestionMarkProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    const idOverlayMask = useId();
    const clipThingID = useId();
    const clipPath = `url("#${clipThingID}")`;

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0,0,256,256" width="24px" height="24px">
                <defs>
                <clipPath id={clipThingID}><path
                    d="M0,256v-256h256v256h-30.03854c16.75896,-12.50094 27.60997,-32.48196 27.60997,-55c0,-37.87429 -30.69714,-68.57143 -68.57143,-68.57143c-37.87429,0 -68.57143,30.69714 -68.57143,68.57143c0,22.51804 10.85101,42.49906 27.60997,55z"
                    id={idOverlayMask} fill="none"/>
                 </clipPath>
            </defs>
                <g clipPath={clipPath} fill="none"
                   fillRule="nonzero" stroke="none" strokeWidth="1"
                   strokeLinecap="butt" strokeLinejoin="miter"
                   strokeMiterlimit="10">
                    <g
                        transform="scale(5.33333,5.33333)" fill="currentColor">
                        <path
                            d="M12.5,6c-3.57194,0 -6.5,2.92806 -6.5,6.5v23c0,3.57194 2.92806,6.5 6.5,6.5h23c3.57194,0 6.5,-2.92806 6.5,-6.5v-23c0,-3.57194 -2.92806,-6.5 -6.5,-6.5zM12.5,9h23c1.95006,0 3.5,1.54994 3.5,3.5v1.5h-30v-1.5c0,-1.95006 1.54994,-3.5 3.5,-3.5zM9,17h30v18.5c0,1.95006 -1.54994,3.5 -3.5,3.5h-23c-1.95006,0 -3.5,-1.54994 -3.5,-3.5zM15.5,21c-1.38071,0 -2.5,1.11929 -2.5,2.5c0,1.38071 1.11929,2.5 2.5,2.5c1.38071,0 2.5,-1.11929 2.5,-2.5c0,-1.38071 -1.11929,-2.5 -2.5,-2.5zM24,21c-1.38071,0 -2.5,1.11929 -2.5,2.5c0,1.38071 1.11929,2.5 2.5,2.5c1.38071,0 2.5,-1.11929 2.5,-2.5c0,-1.38071 -1.11929,-2.5 -2.5,-2.5zM32.5,21c-1.38071,0 -2.5,1.11929 -2.5,2.5c0,1.38071 1.11929,2.5 2.5,2.5c1.38071,0 2.5,-1.11929 2.5,-2.5c0,-1.38071 -1.11929,-2.5 -2.5,-2.5zM15.5,30c-1.38071,0 -2.5,1.11929 -2.5,2.5c0,1.38071 1.11929,2.5 2.5,2.5c1.38071,0 2.5,-1.11929 2.5,-2.5c0,-1.38071 -1.11929,-2.5 -2.5,-2.5zM24,30c-1.38071,0 -2.5,1.11929 -2.5,2.5c0,1.38071 1.11929,2.5 2.5,2.5c1.38071,0 2.5,-1.11929 2.5,-2.5c0,-1.38071 -1.11929,-2.5 -2.5,-2.5z"/></g></g><g
                fill="currentColor" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt"
                strokeLinejoin="miter" strokeMiterlimit="10">
                <g transform="translate(131.66667,147.66667) scale(7.61905,7.61905)"
                   id="overlay">
                    <path
                        d="M7,0c-3.866,0 -7,3.134 -7,7c0,3.866 3.134,7 7,7c3.866,0 7,-3.134 7,-7c0,-3.866 -3.134,-7 -7,-7zM7,12c-0.552,0 -1,-0.448 -1,-1c0,-0.552 0.448,-1 1,-1c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1zM8.426,7.172c-0.457,0.422 -0.736,0.698 -0.736,1.035h-1.38c0,-0.958 0.654,-1.562 1.18,-2.048c0.551,-0.509 0.889,-0.849 0.889,-1.4c0,-0.76 -0.619,-1.379 -1.379,-1.379c-0.76,0 -1.379,0.618 -1.379,1.379h-1.38c0,-1.522 1.238,-2.759 2.759,-2.759c1.521,0 2.759,1.237 2.759,2.759c0,1.182 -0.739,1.864 -1.333,2.413z"/></g></g></svg>
        </span>
    );
}
