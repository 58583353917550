import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {AppContext} from "../../../App";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import {tt} from "../../../core/Localization";
import {Box, Divider, InputAdornment, Theme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {AppDataContext} from "../../../AppData";
import PaperAppbar from "../../components/paper/PaperAppbar";
import FormBuilder, {IInputsData, InputType} from "../../components/form/FormBuilder";
import SearchIcon from "../../../icons/SearchIcon";
import AppPaper from "../../components/paper/AppPaper";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import AppChip from "../../components/chips/AppChip";
import {AddOutlined} from "@mui/icons-material";
import {useNavigate, useSearchParams} from "react-router-dom";
import {
    GetJobsInput,
    JobJoinedOthersResponseResponsePage,
    JobResponse,
    VisitStatus,
    VisitStatusModifier,
} from "../../../generated/graphql/graphql";
import {
    FilterCheckJobIsValidForVisitStatusAndVisitStatusModifier,
    JobNameOrSequenceId,
    kJobsListDisplayLimitPage
} from "../../../service/JobService";
import {processQueryError} from "../../../service/ErrorService";
import {routeWithCurrentAsParam} from "../../../utils/Utils";
import {jobDetailRoute} from "./JobDetailScreen";
import AppButton from "../../components/buttons/AppButton";
import {boxContentStyles} from "../../../styles/UtilStyles";
import AppListItemShimmer from "../../components/shimmers/AppListItemShimmer";
import EmptyListText from "../../components/textComponents/EmptyListText";
import SingleJobListItem from "../../components/jobs/SingleJobListItem";
import {newJobAndVisitRoute} from "./NewJobAndVisitScreen";
import {kMobileMenuRoute} from "../mobile/MobileMenuScreen";
import JobsByStatusFilter from "../../components/jobs/JobsByStatusFilter";
import {kActionCreate, kPermissionsJobs, kTopicJobs} from "../../../core/constants";
import PermissionValid from "../../components/permissions/PermissionValid";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import {getPublicUrls} from "../../../service/StorageService";

export const kJobsRoute = '/jobs';

export const useStyles = makeStyles()((theme: Theme) => ({
    paginationContainer: {
        display: "flex",
        justifyContent: "center",
        paddingTop: 8,
        paddingBottom: 8,
    },
    chipsContainerOuter: {
        flexShrink: 0,
        paddingBottom: 8,
    },
    chevronDown: {
        marginRight: -8,
        paddingLeft: 4,
    },
    checkIcon: {
        marginLeft: -8,
        paddingLeft: 8,
    },
    menuOffset: {
        '.MuiPaper-root': {
            minWidth: 200,
        },
        transform: 'translateY(8px)',
    }
}));

export default function JobsScreen() {
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    useEffect(() => {
        setTitle(tt('jobs.screen.title'));

        document.body.classList.add('jobsScreen');

        return () => {
            document.body.classList.remove('jobsScreen');
        }
    }, []);

    return (
        <ResponsiveContainer
            smallPhoneScreen={<Body isMobile={true}/>}
            largePhoneScreen={<Body isMobile={true}/>}
            tabletScreen={<Body/>}
            smallDesktopScreen={<Body/>}
            largeDesktopScreen={<Body/>}
            extraLargeDesktopScreen={<Body/>}
        />
    );
}

interface IBodyProps {
    isMobile?: boolean;
}

function Body(props: IBodyProps) {
    const {isMobile} = props;

    const {classes, cx} = useStyles();
    const {classes: boxContentClasses} = boxContentStyles();

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const navigate = useNavigate();

    const appDataContext = useContext(AppDataContext);
    const {companyId, storage, setStorage} = appDataContext;

    const [searchParams, setSearchParams] = useSearchParams();
    const statusFilterFromParams = searchParams.get('jobsStatusFilter') as VisitStatus | undefined;

    const [unfilteredJobs, setUnfilteredJobs] = useState<JobResponse[]>([]);
    const [jobsCount, setJobsCount] = useState<number>(0);
    const [jobsDisplayLimit, setJobsDisplayLimit] = useState<number>(searchParams.get('jobsDisplayLimit') ? parseInt(searchParams.get('jobsDisplayLimit')!) : kJobsListDisplayLimitPage);
    const [statusFilter, setStatusFilter] = useState<VisitStatus | undefined>(statusFilterFromParams);
    const [statusModifierFilter, setStatusModifierFilter] = useState<VisitStatusModifier | undefined>(searchParams.get('jobsStatusModifierFilter') as any);

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            type: InputType.Text,
            label: '',
            inputVariant: 'standard',
            extraStyle: 'thin',
            hideLabel: true,
            placeholder: tt('jobs.screen.search.placeholder'),
            value: searchParams.get('jobsQuery') || '',
            required: true,
            isClearable: true,
            innerPrefixJSX: (
                <InputAdornment position={"start"}>
                    <SearchIcon/>
                </InputAdornment>
            ),
        },
    });
    const theQuery = inputs.search.value;

    const navigateToItemRef = useRef<HTMLDivElement | null>(null);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<JobJoinedOthersResponseResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            const subscription = subscribe(
                kTopicJobs,
                {
                    uri: '/job/search-joined-others',
                    params: {
                        companyId: companyId!,
                        query: theQuery || undefined,
                        page: theQuery && theQuery.length > 0 ? undefined : 0,
                        pageSize: theQuery && theQuery.length > 0 ? undefined : (kJobsListDisplayLimitPage * 4),
                    } as GetJobsInput,
                    setLoading,
                    onData: (value: JobJoinedOthersResponseResponsePage) => {
                        if (value) {
                            setData({
                                ...value,
                                jobs: value.jobs.map(job => ({
                                    ...job,
                                    stats: value.stats.find(stats => stats.jobId === job.id),
                                })),
                            });
                        } else {
                            setData(null);
                        }
                    },
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                () => true,
            );

            return () => {
                subscription.cancel();
            };
        }
    }, [companyId, theQuery]);

    useEffect(() => {
        if (data) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(data.files || []),
                    ],
                };
            });
        }
    }, [data]);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('jobsDisplayLimit', jobsDisplayLimit.toString());
            return prev;
        });
    }, [jobsDisplayLimit]);

    /**
     * Set JobStatus filter with URL params.
     */
    const SetJobStatusFilter = (status?: VisitStatus, statusModifier?: VisitStatusModifier) => {
        setStatusFilter(status);
        setStatusModifierFilter(statusModifier);

        setSearchParams(prev => {
            prev.set('jobsStatusFilter', status || '');
            prev.set('jobsStatusModifierFilter', statusModifier || '');

            return prev;
        });
    };

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('jobsQuery', theQuery || '');

            return prev;
        });
    }, [theQuery]);

    /**
     * Navigate to detail with parameters for return back.
     */
    const ToDetail = (id: number) => {
        setSearchParams(prev => {
            prev.set('id', id.toString());
            return prev;
        });

        let urlParams: string[] = [];

        searchParams.forEach((value: string, key: string) => {
            if (key !== 'id') {
                urlParams.push(`${key}=${value}`);
            }
        });

        urlParams.push(`id=${id}`);

        navigate(routeWithCurrentAsParam(
            jobDetailRoute({
                jobId: id,
                urlParams,
            })
        ));
    };

    /**
     * Scroll to job and clear.
     */
    const ScrollToJob = () => {
        setTimeout(() => {
            navigateToItemRef.current?.scrollIntoView({behavior: 'smooth'});

            setSearchParams(prev => {
                prev.set('id', '');
                return prev;
            });
        }, 1);
    };

    useEffect(() => {
        if (data) {
            setUnfilteredJobs([...data.jobs]);
        }
    }, [data]);

    const cards = useMemo(() => {
        let jobsDisplaying = 0;

        let jobs = data?.jobs;
        if (jobs) {
            const theQueryValue = theQuery?.toLowerCase();

            jobs = jobs.filter(job => {
                const theName = JobNameOrSequenceId(job).toLowerCase();
                const theQueryFilter = !theQueryValue || theName.includes(theQueryValue);

                const theStatusFilter = FilterCheckJobIsValidForVisitStatusAndVisitStatusModifier(job, statusFilter, statusModifierFilter);

                return theQueryFilter && theStatusFilter;
            })
                .sort((a, b) => b.createdAt - a.createdAt);
        }

        setJobsCount(jobs?.length || 0);

        return (
            <>
                {jobs && jobs.length > 0 ? (
                    jobs.map((job, index) => {
                        if (jobsDisplayLimit <= jobsDisplaying && jobsDisplaying !== 0) {
                            return null;
                        }

                        const navigateToId = searchParams.get('id');

                        if (navigateToId && navigateToId == job.id) {
                            ScrollToJob();
                        }

                        jobsDisplaying++;

                        return (
                            <div key={job.id}
                                 ref={(navigateToId && navigateToId == job.id) ? navigateToItemRef : undefined}>
                                <SingleJobListItem
                                    data={job}
                                    stats={data!.stats.find(stats => stats.jobId === job.id)}
                                    client={data!.clients.find(client => client.id === job.clientId)}
                                    responsiblePerson={data!.employees.find(employee => employee.id === job.responsiblePersonId)}
                                    onClick={ToDetail}
                                    files={data?.files}
                                />
                            </div>
                        );
                    })
                ) : (
                    <EmptyListText
                        text={tt('jobs.screen.empty')}
                    />
                )}
            </>
        );
    }, [data, jobsDisplayLimit, theQuery, statusFilter, statusModifierFilter, storage.publicUrlsForFiles]);

    const filtersJSX = (
        <Box className={classes.chipsContainerOuter}>
            <JobsByStatusFilter
                statusModifierFilter={statusModifierFilter}
                statusFilter={statusFilter}
                SetFilter={SetJobStatusFilter}
                unfilteredJobs={unfilteredJobs}
                notScheduledCount={data?.notScheduledCount}
            />
            {isMobile ? <Box pr={1}/> : null}
        </Box>
    );

    return (
        <>
            <ScreenContent
                appBar={!isMobile}
                noContentPadding={isMobile}
                navigationDrawer={!isMobile}
                bottomBar={isMobile}
                centerHorizontally={true}
            >
                <AppPaper sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                    <PaperAppbar
                        noMarginBottom={true}
                        isMobile={isMobile}
                        title={tt('jobs.screen.title')}
                        hideBackButton={!isMobile}
                        backRoute={kMobileMenuRoute}
                        children={
                            isMobile ? null : (
                                <PermissionValid
                                    permission={kPermissionsJobs}
                                    requiredPermissions={[kActionCreate]}
                                >
                                    <AppChip
                                        onClick={() => {
                                            navigate(newJobAndVisitRoute({}))
                                        }}
                                        label={tt('common.newJob')}
                                        icon={<AddOutlined/>}></AppChip>
                                </PermissionValid>
                            )
                        }
                        bottomContent={
                            <>
                                <Box pt={1}><FormBuilder inputs={inputs} setInputs={setInputs}/></Box>

                                {filtersJSX}
                                <Box pt={1} mr={-2} ml={-2}>
                                    <Divider/>
                                </Box>
                            </>
                        }
                    />
                    <Box pt={2}/>

                    {loading && !data ? (
                        <>
                            <AppListItemShimmer/>
                            <AppListItemShimmer/>
                        </>
                    ) : cards}

                    {jobsDisplayLimit < jobsCount ? (
                        <>
                            <Box sx={{pb: 2}}/>

                            <Box className={boxContentClasses.centered}>
                                <AppButton variant="outlined" color="primary" disabled={loading}
                                           onClick={() => setJobsDisplayLimit(jobsDisplayLimit + kJobsListDisplayLimitPage)}>
                                    {tt('common.loadMore')}
                                </AppButton>
                            </Box>

                            <Box sx={{pb: 2}}/>
                        </>
                    ) : undefined}

                    <Box sx={{pb: 0.5}}/>
                </AppPaper>
            </ScreenContent>
        </>
    );
}
