import AppModal from "./AppModal";
import {makeStyles} from "tss-react/mui";
import {Box, CircularProgress, Theme, Typography} from "@mui/material";
import CompanyIcon from "../../../icons/CompanyIcon";
import React from "react";
import ResponsiveContainer from "../screens/ResponsiveContainer";

export const useStyles = makeStyles()((theme: Theme) => ({
    outerContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
        width: '100%',
        height: '100vh',
    },
    logoContainer: {
        margin: "auto",
        width: 120,
        height: 120,
        position: "relative",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        "@media (max-width: 767px)": {
            width: 60,
            height: 60,
        }
    },
    innerContainer: {
        width: '100%',
        textAlign: 'center',
    },
    circularProgress: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    text: {
        textAlign: "center",
        fontSize: 28,
        fontWeight: 500,
        "@media (max-width: 767px)": {
            fontSize: 16,
        }
    },
    icon: {
        height: 38,
        width: 38,
    },

}));

export interface IFullScreenLoaderModalProps {
    text: string;
    open: boolean;
    isMobile?: boolean;
}

export default function FullScreenLoaderModal(props: IFullScreenLoaderModalProps) {
    const {open, text} = props;

    const {classes} = useStyles();

    const contentJSX = <ResponsiveContainer
        smallPhoneScreen={<Body {...props} isMobile={true}/>}
        largePhoneScreen={<Body  {...props} isMobile={true}/>}
        tabletScreen={<Body {...props} />}
        smallDesktopScreen={<Body {...props} />}
        largeDesktopScreen={<Body {...props} />}
        extraLargeDesktopScreen={<Body {...props} />}/>

    return (
        <>
            {contentJSX}
        </>
    );
}

function Body(props: IFullScreenLoaderModalProps) {
    const {open, text, isMobile} = props;

    const {classes} = useStyles();

    return (<AppModal
        open={open}
        fullScreen={true}>
        <Box className={classes.outerContainer}>
            <Box className={classes.innerContainer}>
                <Box className={classes.logoContainer}>
                    <CompanyIcon size={isMobile ? 22 : 38} className={classes.icon}/>
                    <CircularProgress
                        size={isMobile ? 60 : 120}
                        color={"primary"}
                        className={classes.circularProgress}
                    />
                </Box>
                <Box sx={{pb: isMobile ? 1 : 2}}></Box>
                <Typography className={classes.text}>{text}</Typography>
            </Box>
        </Box>
    </AppModal>);
}