import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {UpdateJobDetailsDocument, UpdateJobDetailsMutationVariables} from "../../../../../generated/graphql/graphql";
import {useMutation} from "@apollo/client";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../../form/FormBuilder";
import {tt} from "../../../../../core/Localization";
import {SuccessToast} from "../../../../../service/ToastService";
import {processMutationError} from "../../../../../service/ErrorService";
import {kOneLineTextLimit} from "../../../../../core/constants";
import ModalBottomSheet from "../../../ModalBottomSheet";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import ContentPadding from "../../../paper/ContentPadding";
import {Box} from "@mui/material";
import AppButton from "../../../buttons/AppButton";

export interface IEditJobDetailsModalBottomSheetProps {
    jobId: number;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    modalAboveModals?: boolean;
    name?: string | NullOrUndefined;
}

/**
 * Modal bottom sheet for editing the details of a Job.
 */
export default function EditJobDetailsModalBottomSheet(props: IEditJobDetailsModalBottomSheetProps) {
    const {
        jobId,
        open,
        setOpen,
        modalAboveModals,
        name,
    } = props;

    const [mutateUpdateJobDetails, {
        loading: updateJobDetailsLoading,
    }] = useMutation(UpdateJobDetailsDocument);

    const [inputs, setInputs] = useState<IInputsData>({
        name: {
            type: InputType.Text,
            label: tt('jobDetailScreen.jobDetails.editJobDetailsModalBottomSheet.nameLabel'),
            value: name || '',
            maxChars: kOneLineTextLimit,
            showCharCounter: true,
        },
    });

    useEffect(() => {
        if (open) {
            setInputs({
                name: {
                    ...inputs.name,
                    value: name || '',
                },
            });
        }
    }, [open]);

    /**
     * Mutate update of Job details to BE.
     */
    const updateUpdateJobDetails = async () => {
        if (ValidateForm(inputs, setInputs)) {
            try {
                const variables: UpdateJobDetailsMutationVariables = {
                    input: {
                        jobId: jobId,
                        name: inputs.name.value || null,
                    },
                };

                const result = await mutateUpdateJobDetails({variables});

                if (!result.errors) {
                    SuccessToast(tt('jobDetailScreen.jobDetails.editJobDetailsModalBottomSheet.successToast'));

                    setOpen(false);
                }
            } catch (error) {
                processMutationError(error);
            }
        }
    };

    return (
        <ModalBottomSheet
            modalAboveModals={modalAboveModals}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
        >
            <BottomSheetModalAppbar
                noBorderBottom={true}
                onClose={() => setOpen(false)}
                title={tt('jobDetailScreen.jobDetails.editJobDetailsModalBottomSheet.title')}/>

            <ContentPadding>
                <FormBuilder inputs={inputs} setInputs={setInputs}/>

                <Box sx={{pb: 2}}/>

                <AppButton
                    variant={"contained"}
                    fullWidth={true}
                    onClick={updateUpdateJobDetails}
                    isLoading={updateJobDetailsLoading}
                >
                    {tt('common.save')}
                </AppButton>
            </ContentPadding>
        </ModalBottomSheet>
    );
}
