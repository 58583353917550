import {makeStyles} from "tss-react/mui";
import {ListItem, ListItemAvatar, ListItemText, Theme} from "@mui/material";
import React from "react";
import {ChevronRight} from "@mui/icons-material";
import {kAppColors} from "../../styles/AppThemeProcessor";

const useStyles = makeStyles()((theme: Theme) => ({
    listItemClass: {
        height: 54,
        paddingLeft: 16,
        paddingRight: 16,
    },
    borderBottom: {
        borderBottom: `1px solid ${kAppColors.border(theme.palette.mode === "dark")}`,
    },
    title: {
        'span.MuiListItemText-primary': {
            fontSize: 16,
            fontWeight: 500,
        }
    }
}));

export interface IMobileMenuItemProps {
    title: string,
    icon?: React.ReactNode,
    actionWidget?: React.ReactNode,
    onClick?: React.MouseEventHandler<HTMLLIElement>,
    borderBottom?: boolean;
    className?: string;
}

export default function MobileMenuItem(props: IMobileMenuItemProps) {
    const {title, onClick, actionWidget, icon, borderBottom, className} = props;

    const {classes, cx} = useStyles();

    const listItemClass = cx(
        classes.listItemClass,
        borderBottom == true ?
            classes.borderBottom : undefined,
        className,
    );

    const secondaryActionJSX = actionWidget == null ? <ChevronRight/> : actionWidget;

    return (
        <ListItem onClick={onClick} className={listItemClass} secondaryAction={secondaryActionJSX}>
            <ListItemAvatar>
                {icon}
            </ListItemAvatar>
            <ListItemText className={classes.title}
                          primary={title}
            />
        </ListItem>
    );

}