import {Box, Theme, Typography} from "@mui/material";
import React from "react";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../../styles/AppThemeProcessor";

export const useStyles = makeStyles()((theme: Theme) => ({
    labelWithValueContainer: {
        display: "flex",
        justifyContent: "center",
        flex: '1 1 0px',
        paddingLeft: 8,
        paddingRight: 8,
        "@media (max-width: 576px)": {
            display: "none",
        },
    },
    labelWithValueContainerInner: {
        flexShrink: 1,
        cursor: "pointer",
        borderBottom: `1px solid transparent`,
        ':hover': {
            borderBottom: `1px solid ${kAppColors.text.primary(theme.palette.mode === "dark")}`,
        }
    },
    value: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: 1.2,
        textAlign: "center",
    },
    label: {
        fontSize: 14,
        textAlign: "center",
        whiteSpace: 'nowrap',
        textOverflow: 'clip',
    },
}));

export interface IRadialBarChartLabelProps {
    value: number,
    label: string,
    onClick: Function,
}

export default function RadialBarChartLabel(props: IRadialBarChartLabelProps) {
    const {value, label, onClick} = props;

    const {classes, cx} = useStyles();

    return (
        <Box className={classes.labelWithValueContainer}>
            <Box className={classes.labelWithValueContainerInner} onClick={() => onClick()}>
                <Typography className={classes.value}>{value}</Typography>
                <Typography className={classes.label}>{label}</Typography>
            </Box>
        </Box>
    );
}
