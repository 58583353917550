import {makeStyles} from "tss-react/mui";
import {Box, Divider, Theme} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import NotificationListItem from "./NotificationListItem";
import PaperAppbar from "../paper/PaperAppbar";
import {tt} from "../../../core/Localization";
import TwoLineChip from "../chips/TwoLineChip";
import AppButton from "../buttons/AppButton";
import {kDashboardRoute} from "../../screens/dashboard/DashboardScreen";
import EmptyListText from "../textComponents/EmptyListText";
import AppListItemShimmer from "../shimmers/AppListItemShimmer";
import {AppDataContext} from "../../../AppData";
import INotification from "../../../model/firestore/Notification";
import {
    kMaxNotificationsPages,
    kNotificationsPageSize,
    markAllNotificationsAsRead,
    subscribeNotifications
} from "../../../service/NotificationService";
import {processQueryError} from "../../../service/ErrorService";
import {boxContentStyles} from "../../../styles/UtilStyles";
import {useNavigate} from "react-router-dom";
import {kNotificationsRoute} from "../../screens/notifications/NotificationsScreen";
import AppIconButton from "../buttons/AppIconButton";
import Icons8Done from "../../../icons/Icons8Done";
import Icons8DoubleTick from "../../../icons/Icons8DoubleTick";

const useStyles = makeStyles()((theme: Theme) => ({
    filterContainer: {
        display: "flex",
        alignItems: "center",
        overflowX: "auto",
        width: '100%',
        minWidth: 340,
        maxWidth: '100%',
        paddingBottom: 8,
        '*': {
            flexShrink: 0,
        }
    },
    spacer: {
        flexGrow: 1,
    },
}));

export interface INotificationsContentProps {
    isScreen?: boolean;
    isMobile?: boolean;
    closeMenu?: VoidFunction;
    isTooltip?: boolean;
}

export default function NotificationsContent(props: INotificationsContentProps) {
    const {isScreen, isMobile, closeMenu, isTooltip} = props;

    const appDataContext = useContext(AppDataContext);
    const {employeeId, setStorage} = appDataContext;

    const {classes, cx} = useStyles();
    const {classes: boxContentClasses} = boxContentStyles();
    const navigate = useNavigate();

    const [unreadOnly, setUnreadOnly] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [notifications, setNotifications] = useState<Array<INotification>>([]);
    const [loadPages, setLoadPages] = useState<number>(1);

    useEffect(() => {
        if (employeeId) {
            if (loadPages === 1) {
                setIsLoading(true);
            }

            const unsubscribe = subscribeNotifications({
                employeeId,
                onNext: (snapshot) => {
                    const newNotifications: INotification[] = [];

                    snapshot.forEach((doc) => {
                        const data = doc.data();
                        const notification = data as any;

                        notification.id = doc.id;

                        newNotifications.push(notification);
                    });

                    setNotifications(newNotifications);

                    setIsLoading(false);
                },
                unreadOnly: unreadOnly,
                loadPages: loadPages,
            });

            return () => {
                unsubscribe();
            };
        }
    }, [employeeId, unreadOnly, loadPages]);

    useEffect(() => {
        if (notifications) {
            setStorage((prev) => {
                return {
                    notificationsToProcess: [
                        ...prev.notificationsToProcess,
                        ...(notifications || []),
                    ],
                };
            });
        }
    }, [notifications]);

    /**
     * Load more notifications if there are more notifications.
     * Limit to max 5 pages.
     */
    const loadMoreNotifications = () => {
        if (loadPages < kMaxNotificationsPages) {
            setLoadPages(loadPages + 1);
        }
    };

    const notificationsFilterJSX = (
        <Box><Box
            className={classes.filterContainer}>
            <TwoLineChip
                variant={unreadOnly ? "secondary" : "primary"}
                text1={tt('notifications.filter.button.all')}
                onClick={() => {
                    setUnreadOnly(false);
                }}
            />

            <Box pr={1}/>

            <TwoLineChip
                variant={unreadOnly ? "primary" : "secondary"}
                text1={tt('notifications.filter.button.unread')}
                onClick={() => {
                    setUnreadOnly(true);
                }}
            />

            <Box pr={1}/>

            <Box className={classes.spacer}/>

            {employeeId ? (
                <AppIconButton
                    color={"primary"}
                    tooltip={tt('notifications.button.markAllAsRead')}
                    onClick={() => {
                        markAllNotificationsAsRead(employeeId!)
                            .catch((error) => {
                                processQueryError(appDataContext, error);
                            });
                    }}
                >
                    <Icons8DoubleTick/>
                </AppIconButton>
            ) : undefined}
        </Box>
            <Box mr={-2} ml={-2}>
                <Divider/>
            </Box>
        </Box>
    );

    const showAllJSX = !unreadOnly && isTooltip && notifications.length > 0 ? (
        <>
            <Box sx={{pb: 2}}/>

            <Box className={boxContentClasses.centered}>
                <AppButton variant="textThin" color="primary"
                           onClick={() => navigate(kNotificationsRoute)}>
                    {tt('notifications.button.showAll')}
                </AppButton>
            </Box>

            <Box sx={{pb: 1}}/>
        </>
    ) : undefined;

    const loadMoreJSX = notifications.length >= (kNotificationsPageSize * loadPages) && loadPages < kMaxNotificationsPages && !isTooltip ? (
        <>
            <Box sx={{pb: 2}}/>
            <Box className={boxContentClasses.centered}>
                <AppButton variant="outlined" color="primary"
                           onClick={loadMoreNotifications}>
                    {tt('notifications.button.loadMore')}
                </AppButton>
            </Box>

            <Box sx={{pb: 1}}/>
        </>
    ) : undefined;

    return (
        <>
            <PaperAppbar
                hideBackButton={!isScreen || !isMobile}
                backRoute={isMobile ? kDashboardRoute : undefined}
                title={tt('common.notifications')}
                bottomContent={notificationsFilterJSX}
                noMarginBottom={true}
            />

            {!isLoading && notifications.length === 0 ? <Box pt={2} pb={4}>
                <EmptyListText text={tt('notifications.emptyStateText')}/>
            </Box> : null}

            {isLoading ?
                <>
                    <AppListItemShimmer/>
                    <AppListItemShimmer/>
                    <AppListItemShimmer/>
                    <AppListItemShimmer/>
                </>
                : (
                    <>
                        {notifications.map((notification) => <NotificationListItem
                            key={notification.id}
                            notification={notification}
                            closeMenu={closeMenu}
                            isTooltip={!!isTooltip}
                        />)}

                        {showAllJSX}

                        {loadMoreJSX}
                    </>
                )}
        </>
    );
}
