import React, {useContext, useId} from "react";
import {GetProductIcon} from "../../../service/ProductService";
import AppChip from "../chips/AppChip";
import AppIconButton from "../buttons/AppIconButton";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MoreFilledIcon from "../../../icons/MoreFilledIcon";
import {usePopupState} from "material-ui-popup-state/hooks";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {tt} from "../../../core/Localization";
import {Box, Theme} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import CloseIcon from "../../../icons/CloseIcon";
import {AppDataContext} from "../../../AppData";
import PencilIcon from "../../../icons/PencilIcon";
import {useNavigate} from "react-router-dom";
import {productEditRoute} from "../../screens/products/ProductEditScreen";
import AppListItem from "../listItems/AppListItem";
import {EmployeeJoinedUserResponse, FileResponse, ProductType} from "../../../generated/graphql/graphql";
import {PriceDisplay} from "../../../service/CompanyService";
import {makeStyles} from "tss-react/mui";
import PermissionValid from "../permissions/PermissionValid";
import {kActionDelete, kActionUpdate, kPermissionsProducts} from "../../../core/constants";
import AppAvatar from "../AppAvatar";
import {UserFullName, UserPhotoUrl} from "../../../service/UserService";

const useStyles = makeStyles()((theme: Theme) => ({
    chipsContainer: {
        display: 'flex',
        flexWrap: "wrap",
        '.MuiChip-root': {
            marginRight: 4,
            marginBottom: 4,
        }
    }
}));

interface IProductListItemsProps {
    isNewJobCreation?: boolean;
    data: {
        id: number;
        name: string;
        description?: string | NullOrUndefined;
        price?: number | NullOrUndefined;
        type: ProductType;
        vatRate?: number | NullOrUndefined;
        unitCount?: number | NullOrUndefined;
        unitName?: string | NullOrUndefined;
    };
    files?: FileResponse[] | NullOrUndefined;
    employee?: EmployeeJoinedUserResponse | NullOrUndefined;
    onUpdate?: (id: number) => void;
    onDelete: (id: number) => void;
    canEdit?: boolean;
}

export default function ProductListItem(props: IProductListItemsProps) {
    const {isNewJobCreation, data, files, employee, onUpdate, onDelete, canEdit} = props;

    const {classes} = useStyles();

    const appDataContext = useContext(AppDataContext);
    const {currency, language, company, storage} = appDataContext;
    const navigate = useNavigate();

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    return (
        <>
            <AppListItem
                customAvatarInCircle={GetProductIcon(data.type)}
                title={data.name}
                description={data.description}
                variant={"smaller-title"}
                customBottomWidget={
                    <Box className={classes.chipsContainer}>
                        {data.unitCount ?
                            <AppChip
                                chipstyle={"outlined"}
                                label={`${data.unitCount} ${data.unitName}`}
                            />
                            :
                            null
                        }

                        <AppChip
                            chipstyle={"outlined"}
                            label={PriceDisplay(data.price, currency, language, true)}
                        />

                        {company?.hasVat ? (
                            <AppChip
                                chipstyle={"outlined"}
                                label={tt('common.vatRate.percentage').replace('{vatRate}', (data.vatRate || 0).toString())}
                            />
                        ) : undefined}

                        {employee ? (
                            <AppChip
                                chipstyle={"outlined"}
                                icon={(
                                    <AppAvatar
                                        title={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                                        variant={"tiny"}
                                        tooltip={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                                        img={
                                            UserPhotoUrl(employee.user, files, storage.publicUrlsForFiles)
                                        }
                                    />
                                )}
                            />
                        ) : null}
                    </Box>
                }
                actionWidget={canEdit ? (
                    <>
                        {/*<AppIconButton //TODO: Implement drag and drop
                            variant={"greyBg"}
                            onClick={() => {
                            }}>
                            <Icons8MoveGrabber/>
                        </AppIconButton>

                        <Box pr={1}/>*/}

                        <PermissionValid
                            permission={kPermissionsProducts}
                            requiredPermissions={[kActionUpdate, kActionDelete]}
                            anyRequiredPermission={true}
                            forceValid={isNewJobCreation}
                        >
                            <AppIconButton
                                variant={"greyBg"}
                                placement={"right"}
                                {...bindTrigger(settingsPopupState)}
                            >
                                <MoreFilledIcon/>
                            </AppIconButton>
                        </PermissionValid>
                    </>
                ) : <></>}
            />

            <Menu {...bindMenu(settingsPopupState)}>
                <PermissionValid
                    permission={kPermissionsProducts}
                    requiredPermissions={[kActionUpdate]}
                    forceValid={isNewJobCreation}
                >
                    <MenuItem key={'productEditKey' + data.id} onClick={() => {
                        settingsPopupState.close();

                        if (onUpdate) {
                            onUpdate(data.id);
                        } else {
                            navigate(productEditRoute(data.id));
                        }
                    }}>
                        <PencilIcon/>
                        {tt('common.edit')}
                    </MenuItem>
                </PermissionValid>

                <PermissionValid
                    permission={kPermissionsProducts}
                    requiredPermissions={[kActionDelete]}
                    forceValid={isNewJobCreation}
                >
                    <MenuItem key={'productDeleteKey' + data.id} onClick={() => {
                        settingsPopupState.close();
                        onDelete(data.id);
                    }}>
                        <Box sx={{color: kAppColors.red.confirmButton}}><CloseIcon/></Box>
                        {tt('common.removeFromList')}
                    </MenuItem>
                </PermissionValid>
            </Menu>
        </>
    );
}
