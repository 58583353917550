import {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../../../App";
import {useNavigate, useParams} from "react-router-dom";
import {
    GetLocationsForClientInput,
    LocationJoinedOthersResponsePage
} from "../../../generated/graphql/graphql";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ListShimmer from "../../components/shimmers/ListShimmer";
import EmptyListText from "../../components/textComponents/EmptyListText";
import AppListItem from "../../components/listItems/AppListItem";
import {LocationNameDisplay, LocationTypeDisplay} from "../../../service/LocationService";
import {locationDetailRoute} from "./LocationDetailScreen";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import PaperAppbar from "../../components/paper/PaperAppbar";
import AppChip from "../../components/chips/AppChip";
import {AddOutlined} from "@mui/icons-material";
import {clientDetailRoute} from "../clients/ClientDetailScreen";
import CreateLocationModal from "../../components/locations/CreateLocationModal";
import IBreadcrumb from "../../../model/Breadcrumb";
import {kClientsBreadcrumb, kClientsRoute} from "../clients/ClientsScreen";
import {AppDataContext, SetAppBreadcrumbs} from "../../../AppData";
import AppBreadCrumb from "../../components/breadCrumb/AppBreadCrumb";
import Icons8Location from "../../../icons/Icons8Location";
import IEventSystemListener from "../../../model/EventSystemListener";
import {listenToEventSystem, unListenToEventSystem} from "../../../service/EventSystemService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import {processQueryError} from "../../../service/ErrorService";
import {
    kActionDelete,
    kActionUpdate,
    kPermissionsClients,
    kTopicClients,
    kTopicLocations
} from "../../../core/constants";
import PermissionValid from "../../components/permissions/PermissionValid";
import { RestApiClientContext } from "../../../core/RestApiProvider";
import {addressToSingleLine} from "../../../utils/AddressUtils";

export const kLocationsRoute = '/clients/:clientId/locations';

/**
 * Shorthand to create Route url.
 */
export function LocationsRoute(clientId: string | number): string {
    return kLocationsRoute
        .replace(':clientId', `${clientId}`);
}

/**
 * Screen component for list of Locations for Client.
 */
export default function LocationsScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);

    const {clientId} = useParams();

    const [createModal, setCreateModal] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<LocationJoinedOthersResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (clientId) {
            const subscription = subscribe(
                kTopicLocations,
                {
                    uri: '/location/search-joined-others',
                    params: {
                        clientId: parseInt(clientId!),
                        includeClientPureId: parseInt(clientId!),
                    } as GetLocationsForClientInput,
                    setLoading,
                    onData: setData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                (notifications: IEventSystemNotification[]) => {
                    return notifications.some(notification => {
                        return notification.data.clientId === parseInt(clientId!);
                    });
                },
            );

            return () => subscription.cancel();
        } else {
            setLoading(false);
            setData(null);
        }
    }, [clientId]);

    /**
     * Set breadcrumbs.
     */
    const SetBreadcrumbs = () => {
        const breadcrumbs: IBreadcrumb[] = [kClientsBreadcrumb];

        if (data) {
            breadcrumbs.push({
                key: 'client-detail',
                text: data.clientPure!.name,
                route: clientDetailRoute(clientId!),
            });

            breadcrumbs.push({
                key: 'client-locations',
                text: tt('locations.screen.title'),
                route: LocationsRoute(clientId!),
            });
        }

        SetAppBreadcrumbs(appDataContext, breadcrumbs);
    };

    useEffect(() => {
        setTitle(tt('locations.screen.title'));

        SetBreadcrumbs();
    }, []);

    useEffect(() => {
        SetBreadcrumbs();
    }, [data]);

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                isMobile={isMobile}
                loading={loading}
                data={data}
                setCreateModal={setCreateModal}
            />
        );
    }

    return (
        <>
            <EventSystemListeners />

            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}/>

            <CreateLocationModal open={createModal} setOpen={setCreateModal} clientId={parseInt(clientId!)}/>
        </>
    );
}

interface IEventSystemListenersProps {
}

/**
 * Component for EventSystem listeners.
 */
function EventSystemListeners(props: IEventSystemListenersProps) {
    const {clientId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicClients,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(clientId!);
                });

                if (doDelete) {
                    navigate(kClientsRoute);
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [clientId]);

    return null;
}

interface IBodyProps {
    isMobile?: boolean;
    loading: boolean;
    data?: LocationJoinedOthersResponsePage | NullOrUndefined;
    setCreateModal: Dispatch<SetStateAction<boolean>>;
}

/**
 * Screen body contents.
 */
function Body(props: IBodyProps) {
    const {isMobile, loading, data, setCreateModal} = props;

    const {clientId} = useParams();
    const navigate = useNavigate();

    const content = useMemo(() => {
        return loading && !data ?
            <ListShimmer items={2}/> : data && data!.locations.length > 0 ?
                data!.locations.map(location => (
                    <AppListItem
                        key={location.id}
                        customAvatarInCircle={<Icons8Location/>}
                        title={LocationNameDisplay(location.name, location.type || '')}
                        description={`${location.name ? LocationTypeDisplay(location.type || '') : ''}\n${addressToSingleLine(location.address)}`.trim()}
                        variant={"smaller-title"}
                        onClick={() => navigate(`${locationDetailRoute(clientId!, location.id)}?list=1`)}
                    />
                ))
                : <EmptyListText text={tt('locations.screen.emptyListMessage')}/>;
    }, [data]);

    return (
        <ScreenContent
            showBreadCrumb={true}
            appBar={!isMobile} noContentPadding={isMobile} navigationDrawer={!isMobile} bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    isMobile={isMobile}
                    title={tt('common.locations')}
                    backRoute={clientDetailRoute(clientId!)}>

                    {isMobile ? <></> : (
                        <PermissionValid
                            permission={kPermissionsClients}
                            requiredPermissions={[kActionUpdate]}
                        >
                            <AppChip
                                onClick={() => setCreateModal(true)}
                                key={'addLocationChip'} label={tt('common.newLocation')}
                                icon={<AddOutlined/>}/>
                        </PermissionValid>
                    )}

                </PaperAppbar>

                {isMobile ? <AppBreadCrumb/> : null}

                {content}
            </AppPaper>
        </ScreenContent>
    );
}
