import {CreateMaterialInput, EmployeeJoinedUserResponse, MaterialResponse} from "../generated/graphql/graphql";
import {v4 as uuidv4} from "uuid";
import IMaterialsByType from "../model/MaterialsByType";

/**
 * Convert MaterialResponse to CreateMaterialInput.
 */
export function convertMaterialResponseToCreateInput(material: MaterialResponse): CreateMaterialInput {
    return {
        employeeId: material.employeeId!,
        name: material.name,
        sortOrder: material.sortOrder,
        templateId: material.templateId,
        unitCount: material.unitCount || 0,
        unitName: material.unitName,
        uuid: uuidv4(),
    };
}

export interface IFilterMaterialsForJobParams {
    materials?: MaterialResponse[];
    visitId: number;
    repeatingDay?: number;
    filterDistinctByRepeatingDay?: boolean;
}

/*
 * Filter materials by Visit and repeatingDay.
 * Filtered by repeatingDay also removes null repeatingDay for those that have repeatingDay.
 */
export function filterMaterialsForVisit(params: IFilterMaterialsForJobParams): MaterialResponse[] {
    const {materials, visitId, repeatingDay, filterDistinctByRepeatingDay} = params;

    const theFilterDistinctByRepeatingDay = filterDistinctByRepeatingDay === undefined ? true : filterDistinctByRepeatingDay;

    if (!materials) {
        return [];
    }

    if (!repeatingDay) {
        return materials.filter((material) => {
            return material.visitId === visitId && !material.deleted && !material.isTemplate;
        });
    } else {
        let data = materials.filter((material) => {
            return material.visitId === visitId && !material.isTemplate && (material.repeatingDay === repeatingDay || !material.repeatingDay);
        });

        if (theFilterDistinctByRepeatingDay) {
            data = data.filter((item) => {
                if (item.repeatingDay === null) {
                    const repeatDayData = data.find((it) => {
                        return it.repeatingDay === repeatingDay && item.uuid === it.uuid;
                    });

                    if (repeatDayData) {
                        return false;
                    }
                }

                return true;
            });
        }

        return data.filter((material) => {
            return !material.deleted;
        });
    }
}

export interface ICombineMaterialsByTemplateParams {
    materials: MaterialResponse[];
    employees: EmployeeJoinedUserResponse[] | NullOrUndefined;
}

/**
 * Combine Materials by template or none.
 */
export function combineMaterialsByTemplate(params: ICombineMaterialsByTemplateParams): IMaterialsByType[] {
    const {materials, employees} = params;

    const materialsByType: IMaterialsByType[] = [];

    materials.forEach((material) => {
        const employee = employees?.find((employee) => {
            return employee.id === material.employeeId;
        });

        if (material.templateId) {
            const existing = materialsByType.find((item) => {
                return item.materialId === material.templateId;
            });

            if (existing) {
                existing.unitCount += material.unitCount || 0;

                if (employee) {
                    const existingEmployee = existing.employees.find((item) => {
                        return item.id === employee.id;
                    });

                    if (!existingEmployee) {
                        existing.employees.push(employee);
                    }
                }
            } else {
                materialsByType.push({
                    materialId: material.templateId,
                    materialUuid: material.uuid,
                    name: material.name,
                    unitCount: material.unitCount || 0,
                    unitName: material.unitName!,
                    employees: employee ? [employee] : [],
                });
            }
        } else {
            materialsByType.push({
                materialId: undefined,
                materialUuid: material.uuid,
                name: material.name,
                unitCount: material.unitCount || 0,
                unitName: material.unitName!,
                employees: employee ? [employee] : [],
            });
        }
    });

    return materialsByType;
}
