import HeadlineWithButton from "../../screenSections/detailListPreviewSection/HeadlineWithButton";
import {tt} from "../../../core/Localization";
import {kActionCreate, kActionUpdate, kTopicClients, kTopicCompanyEmployees} from "../../../core/constants";
import Icons8Plus from "../../../icons/Icons8Plus";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {
    AddNoteItemInput,
    DeleteNoteItemInput,
    GetNoteItemsInput,
    NoteItemResponse,
    NoteItemResponsePage,
    UpdateNoteItemInput
} from "../../../generated/graphql/graphql";
import {AppDataContext} from "../../../AppData";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import {processQueryError} from "../../../service/ErrorService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import NoteItemListItem from "./NoteItemListItem";
import {HideConfirmModal, SetConfirmModal, SetConfirmModalLoading} from "../modals/AppModals";
import NoteItemEditModalBottomSheet from "./NoteItemEditModalBottomSheet";
import {SuccessToast} from "../../../service/ToastService";
import AppListItemShimmer from "../shimmers/AppListItemShimmer";
import {Box, Skeleton} from "@mui/material";

export interface NoteItemsSectionProps {
    permission: string;
    clientId?: number;
    employeeId?: number;
}

export default function NoteItemsSection(props: NoteItemsSectionProps) {
    const {permission, clientId, employeeId} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe, restApiPost, restApiDelete} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, storage, setStorage} = appDataContext;

    const [noteModal, setNoteModal] = useState<boolean>(false);
    const [editingNoteItem, setEditingNoteItem] = useState<NoteItemResponse>();

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<NoteItemResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (clientId || employeeId) {
            const topic = clientId ? kTopicClients : kTopicCompanyEmployees;

            const subscription = subscribe(
                topic,
                {
                    uri: '/note-item/search',
                    params: {
                        companyId: companyId!,
                        clientId,
                        employeeId,
                        includeEmployeeJoinedUsers: true,
                        includeFiles: true,
                    } as GetNoteItemsInput,
                    setLoading,
                    onData: setData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                (notifications: IEventSystemNotification[]) => {
                    const update = notifications.some(notification => {
                        if (notification.action !== kActionUpdate) {
                            return false;
                        }

                        if (clientId) {
                            return notification.data.id === clientId;
                        } else if (employeeId) {
                            return notification.data.id === employeeId;
                        }

                        return false;
                    });

                    return update;
                },
            );

            return () => {
                subscription.cancel();
            };
        }
    }, [companyId, clientId, employeeId]);

    useEffect(() => {
        if (data) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(data.files || []),
                    ],
                };
            });
        }
    }, [data]);

    /**
     * Open edit modal to add new note.
     */
    const addNote = () => {
        setNoteModal(true);
        setEditingNoteItem(undefined);
    };

    /**
     * Open edit modal to edit note.
     */
    const editNote = (noteItem: NoteItemResponse) => {
        setNoteModal(true);
        setEditingNoteItem(noteItem);
    };

    const [editLoading, setEditLoading] = useState<boolean>(false);
    /**
     * Create new or update existing NoteItem to BE.
     */
    const onSave = (note: string) => {
        setEditLoading(true);

        if (editingNoteItem) {
            restApiPost({
                uri: '/note-item/update',
                params: {
                    noteId: editingNoteItem.id,
                    note,
                } as UpdateNoteItemInput,
                setLoading: setEditLoading,
                onData: (data: NoteItemResponse) => {
                    if (data) {
                        setNoteModal(false);

                        SuccessToast(tt('noteItem.screen.update.success'));
                    }
                },
                onError: (error: any) => processQueryError(appDataContext, error),
            });
        } else {
            restApiPost({
                uri: '/note-item',
                params: {
                    companyId: companyId!,
                    clientId,
                    employeeId,
                    note,
                } as AddNoteItemInput,
                setLoading: setEditLoading,
                onData: (data: NoteItemResponse) => {
                    if (data) {
                        setNoteModal(false);

                        SuccessToast(tt('noteItem.screen.add.success'));
                    }
                },
                onError: (error: any) => processQueryError(appDataContext, error),
            });
        }
    };

    /**
     * Delete existing NoteItem to BE.
     */
    const onDeleteNote = (noteItem: NoteItemResponse) => {
        SetConfirmModal(appDataContext, {
            open: true,
            title: tt('noteItem.deleteConfirmationModal.title'),
            subtitle: tt('noteItem.deleteConfirmationModal.description'),
            confirmationButtonText: tt('noteItem.deleteConfirmationModal.confirmButton'),
            cancelButtonText: tt('common.close'),
            children: <></>,
            onConfirm: async () => {
                SetConfirmModalLoading(appDataContext, true);

                restApiDelete({
                    uri: '/note-item',
                    params: {
                        noteId: noteItem.id,
                    } as DeleteNoteItemInput,
                    setLoading: (value) => {
                        SetConfirmModalLoading(appDataContext, value);
                    },
                    onData: (data: NoteItemResponse) => {
                        if (data) {
                            HideConfirmModal(appDataContext);

                            SuccessToast(tt('noteItem.screen.delete.success'));
                        }
                    },
                    onError: (error: any) => processQueryError(appDataContext, error),
                });
            },
        });
    };

    const notesJSX = useMemo(() => {
        return loading && !data ? (
            <>
                <AppListItemShimmer/>
                <Box pt={1} pr={2} pl={2} pb={2}>
                    <Skeleton width={400} variant={"rectangular"}/>
                </Box>
                <AppListItemShimmer/>
                <Box pt={1} pr={2} pl={2} pb={2}>
                    <Skeleton width={500} variant={"rectangular"}/>
                </Box>
            </>
        ) : !data ? null : [...data.content]
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((noteItem, index) => {
                return (
                    <NoteItemListItem
                        key={noteItem.id}
                        permission={permission}
                        employee={data.employees?.find(employee => employee.id === noteItem.createdEmployeeId)}
                        data={noteItem}
                        files={data.files}
                        isFirst={index === 0}
                        onEditNote={editNote}
                        onDeleteNote={onDeleteNote}
                    />
                );
            });
    }, [loading, data, storage.publicUrlsForFiles]);

    return (
        <>
            <HeadlineWithButton
                title={tt('common.notes')}
                onClick={addNote}
                iconJSX={<Icons8Plus/>}
                permission={permission}
                requiredPermissions={[kActionCreate]}
            />

            {notesJSX}

            <NoteItemEditModalBottomSheet
                open={noteModal}
                setOpen={setNoteModal}
                existing={editingNoteItem}
                loading={editLoading}
                onSave={onSave}
            />
        </>
    );
}
