import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import {tt} from "../../../core/Localization";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {Box} from "@mui/material";
import {AppDataContext} from "../../../AppData";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {kSettingsRoute} from "./SettingsScreen";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../components/form/FormBuilder";
import AppButton from "../../components/buttons/AppButton";
import {useNavigate} from "react-router-dom";
import {kUserPreferencesTimesheetsViewMode} from "../../../core/constants";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import {SaveUserPreferencesItemsForKeysInput} from "../../../generated/graphql/graphql";
import {processMutationError} from "../../../service/ErrorService";
import {SuccessToast} from "../../../service/ToastService";
import {ViewMode} from "../timesheets/TimeSheetsScreen";

export const kTimesheetsSettingsRoute = '/settings/timesheets';

export default function TimesheetsSettingsScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiPost} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const navigate = useNavigate();

    useEffect(() => {
        setTitle(tt('timesheetsSettings.screen.title'));
    }, []);

    const [loading, setLoading] = useState<boolean>(false);
    /**
     * Save timesheets related settings to UserPreferences on BE.
     */
    const saveSettings = (inputs: IInputsData) => {
        setLoading(true);

        restApiPost({
            uri: '/user-preferences/user-preferences-items-for-keys',
            params: {
                companyId,
                items: [
                    {key: kUserPreferencesTimesheetsViewMode, valueInt: inputs.viewMode.value},
                ],
            } as SaveUserPreferencesItemsForKeysInput,
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading,
            onData: (data) => {
                if (data) {
                    SuccessToast(tt('timesheetsSettings.screen.success'));

                    navigate(kSettingsRoute);
                }
            },
            onError: (error) => processMutationError(error),
        });
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                isMobile={isMobile}
                saveSettings={saveSettings}
                saveLoading={loading}
            />
        );
    }

    return (
        <ResponsiveContainer
            smallPhoneScreen={bodyJSX(true)}
            largePhoneScreen={bodyJSX(true)}
            tabletScreen={bodyJSX()}
            smallDesktopScreen={bodyJSX()}
            largeDesktopScreen={bodyJSX()}
            extraLargeDesktopScreen={bodyJSX()}/>
    );
}

interface IBodyProps {
    isMobile?: boolean;
    saveSettings: (inputs: IInputsData) => void;
    saveLoading: boolean;
}

function Body(props: IBodyProps) {
    const {
        isMobile,
        saveSettings,
        saveLoading,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {userPreferences, currency} = appDataContext;

    const [canSetValues, setCanSetValues] = useState<boolean>(true);

    const [inputs, setInputs] = useState<IInputsData>({
        viewMode: {
            type: InputType.ChipSwitch,
            label: '',
            topLabel: tt('common.defaultView'),
            value: ViewMode.Time,
            options: [
                {label: tt('timesheets.screen.time'), value: ViewMode.Time},
                {label: currency, value: ViewMode.Currency},
                {label: tt('timesheets.screen.distance'), value: ViewMode.Distance},
            ]
        }
    });

    useEffect(() => {
        if (userPreferences.length > 0) {
            const viewMode = userPreferences.find(item => item.key === kUserPreferencesTimesheetsViewMode);

            if (viewMode && canSetValues) {
                setCanSetValues(false);

                setInputs(prev => {
                    return {
                        ...prev,
                        viewMode: {
                            ...prev.viewMode,
                            value: viewMode.valueInt || ViewMode.Time,
                        },
                    };
                });
            }
        }
    }, [userPreferences]);

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}
        >
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    isMobile={isMobile}
                    title={tt('timesheetsSettings.screen.title')}
                    cancelIconBackButton={true}
                    backRoute={kSettingsRoute}
                />
                <Box pl={2} pr={2} pb={2}>
                    <FormBuilder
                        inputs={inputs}
                        setInputs={setInputs}
                    />

                    <Box pb={4}/>

                    <AppButton
                        fullWidth={true}
                        variant={"contained"}
                        isLoading={saveLoading}
                        onClick={() => {
                            if (ValidateForm(inputs, setInputs)) {
                                saveSettings(inputs);
                            }
                        }}
                    >{tt('common.save')}</AppButton>
                </Box>
            </AppPaper>
        </ScreenContent>
    );
}
