import React, {useContext, useEffect} from "react";
import {AppContext} from "../../../App";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import FullSizedContainer from "../../components/FullSizedContainer";
import AppPaper from "../../components/paper/AppPaper";
import {kAppColors, kContentWidthNarrow} from "../../../styles/AppThemeProcessor";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {kWelcomeRoute} from "../WelcomeScreen";
import LanguagesMenu from "../../components/menus/LanguagesMenu";
import DarkModeMenu from "../../components/menus/DarkModeMenu";
import ContentPadding from "../../components/paper/ContentPadding";
import LogoWithText from "../../components/LogoWithText";
import {Box, Grid, Link, Theme, Typography} from "@mui/material";
import SocialButton from "../../components/buttons/SocialButton";
import Icons8Google from "../../../icons/Icons8Google";
import Icon8Facebook from "../../../icons/Icons8Facebook";
import Icons8AppleLogo from "../../../icons/Icons8AppleLogo";
import {FlavorByEnvironment} from "../../../flavor-config";
import {makeStyles} from "tss-react/mui";
import {kSignUpRoute} from "./SignUpScreen";
import {kSignInRoute} from "./SignInScreen";
import {LoginProvider} from "../../../generated/graphql/graphql";
import {trackDataLayerEvent} from "../../../service/AnalyticsService";
import {AuthUserSignInSocialPopup} from "../../../service/AuthUserService";
import {AppDataContext} from "../../../AppData";
import {kDashboardRoute} from "../dashboard/DashboardScreen";
import {useNavigate} from "react-router-dom";

export const kAlternativeSignInRoute = '/alternative-sign-in';

/**
 * Create route with optional params.
 */
export function alternativeSignInRoute(isSignUp?: boolean) {
    return `${kAlternativeSignInRoute}${isSignUp ? '?isSignUp=true' : ''}`;
}

export const useStyles = makeStyles()((theme: Theme) => ({
        socialButton: {
            marginRight: 4,
            marginLeft: 4,
            marginBottom: 8,
        },
        termsTextStyle: {
            fontWeight: 500,
            color: kAppColors.grey.link,
            lineHeight: 18 / 14,
        }
    })
);

/**
 * Screen component for signIn to the Firebase auth using alternative method.
 */
export default function AlternativeSignInScreen() {
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const isSignUp = window.location.search.includes('isSignUp=true');

    useEffect(() => {
        if (isSignUp) {
            setTitle(tt('alternativeSignIn.screen.title.signUp'));
        } else {
            setTitle(tt('alternativeSignIn.screen.title'));
        }
    }, []);

    return (
        <ResponsiveContainer
            smallPhoneScreen={<Body isMobile={true}/>}
            largePhoneScreen={<Body isMobile={true}/>}
            tabletScreen={<Body/>}
            smallDesktopScreen={<Body/>}
            largeDesktopScreen={<Body/>}
            extraLargeDesktopScreen={<Body/>}/>
    );
}

interface IBodyProps {
    isMobile?: boolean;
}

/**
 * Main screen body contents.
 */
function Body(props: IBodyProps) {
    const {isMobile} = props;

    const appContext = useContext(AppContext);
    const {authUser, setAuthUser} = appContext;

    const appDataContext = useContext(AppDataContext);

    const isSignUp = window.location.search.includes('isSignUp=true');

    const navigate = useNavigate();
    const {classes} = useStyles();

    /**
     * SignIn/Up with Firebase Auth.
     */
    const signInUp = async (provider: LoginProvider) => {
        if (isSignUp) {
            trackDataLayerEvent('new_sign_up_alternative');
        } else {
            trackDataLayerEvent('sign_in_alternative');
        }

        AuthUserSignInSocialPopup(
            appDataContext,
            provider,
        );
    };

    useEffect(() => {
        if (authUser && authUser.signInResponse && !authUser.signInResponse.error && authUser.signInResponse.data) {
            navigate(kDashboardRoute);
        }
    }, [authUser]);

    return (
        <FullSizedContainer topAlignedContent={isMobile}>
            <AppPaper
                showPaddingTop={true}
                sx={{maxWidth: isMobile ? undefined : kContentWidthNarrow}}>
                <PaperAppbar
                    isMobile={isMobile}
                    title={isSignUp ? tt('signUp.screen.title') : tt('signIn.screen.title')}
                    backRoute={isSignUp ? kSignUpRoute : kSignInRoute}
                    children={<><LanguagesMenu/><DarkModeMenu/></>}/>
                <ContentPadding isNarrow={isMobile}>
                    <Grid container justifyContent={"center"}>
                        <SocialButton
                            fullWidth={true}
                            onClick={() => signInUp(LoginProvider.Google)}
                            className={classes.socialButton} text={tt('common.google')}
                            icon={<Icons8Google/>}/>

                        <SocialButton
                            fullWidth={true}
                            onClick={() => signInUp(LoginProvider.Facebook)}
                            className={classes.socialButton} text={tt('common.facebook')}
                            icon={<Icon8Facebook/>}/>

                        <SocialButton
                            fullWidth={true}
                            onClick={() => signInUp(LoginProvider.Apple)}
                            className={classes.socialButton} text={tt('common.apple')}
                            icon={<Icons8AppleLogo/>}/>
                    </Grid>
                </ContentPadding>
            </AppPaper>
        </FullSizedContainer>
    );
}
