import {FlavorByEnvironment} from "../flavor-config";
import Gleap from "gleap";
import {IAppDataCompanyData, IAppDataContext} from "../AppData";
import IAuthUser from "../model/AuthUser";
import {UserFullName} from "./UserService";
import {CompanyResponse} from "../generated/graphql/graphql";

/**
 * Initialize Gleap SDK with your API key and correct language.
 */
export function initGleap(appDataContext: IAppDataContext) {
    const config = FlavorByEnvironment();

    if (config?.gleap) {
        changeGleapLanguage(appDataContext.language);

        Gleap.initialize(config.gleap);
    }
}

/**
 * Change Gleap language.
 */
export function changeGleapLanguage(language: string) {
    const config = FlavorByEnvironment();

    if (config?.gleap) {
        Gleap.setLanguage(language);
    }
}

export interface ISetGleapIdentityParams {
    authUser: IAuthUser;
    company: CompanyResponse | undefined;
    companyData: IAppDataCompanyData;
}

/**
 * Set user identity in Gleap for IAuthUser.
 */
export function setGleapIdentity(params: ISetGleapIdentityParams) {
    const {authUser, company, companyData} = params;

    const config = FlavorByEnvironment();

    if (config?.gleap) {
        const data: any = {
            email: authUser.email,
        };

        if (company) {
            data.companyId = company.id;
            data.companyName = company.name;
        }

        if (companyData.employee) {
            data.name = UserFullName(companyData.employee.user?.name ?? companyData.employee.name, companyData.employee.user?.surname ?? companyData.employee.surname);
            data.email = companyData.employee.user?.contactEmail ?? companyData.employee.user?.email ?? authUser.email;
            data.phone = companyData.employee.user?.phoneNumber ?? '';
        }

        try {
            Gleap.identify(authUser.uid, data);
        } catch (e) {
            console.error(e);
        }
    }
}

/**
 * Clear user identity in Gleap.
 */
export function clearGleapIdentity() {
    const config = FlavorByEnvironment();

    if (config?.gleap) {
        Gleap.clearIdentity();
    }
}
