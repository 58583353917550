import {Box, CircularProgress, CircularProgressProps, Theme, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";


const useStyles = makeStyles()((theme: Theme) => ({
    text: {
        fontSize: 10,
        color: kAppColors.text.primary(theme.palette.mode === "dark")
    }
}));

export interface ICircularProgressWithLabelProps extends CircularProgressProps {
    className?: string;
    value: number;
}

/**
 * Circular progress with label component.
 */
export default function CircularProgressWithLabel(props: ICircularProgressWithLabelProps) {
    const {className, value} = props;

    const {classes, cx} = useStyles();

    return (
        <Box
            className={cx(className)}
            sx={{position: 'relative', display: 'inline-flex'}}
        >
            <CircularProgress variant="determinate" {...props} />

            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    className={classes.text}
                >{`${Math.round(value)}%`}</Typography>
            </Box>
        </Box>
    );
}
