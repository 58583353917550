import {makeStyles} from "tss-react/mui";
import {Box, Link, Theme} from "@mui/material";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import React, {useContext} from "react";
import {drawerWidth} from "../screens/NavigationDrawer";
import {ChevronRight} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import AppIconButton from "../buttons/AppIconButton";
import {tt} from "../../../core/Localization";
import {AppDataContext} from "../../../AppData";
import IBreadcrumb from "../../../model/Breadcrumb";

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        overflowX: "auto",
        display: 'flex',
        alignItems: "center",
        position: "absolute",
        left: `calc( 50% + ${drawerWidth - 30}px)`,
        transform: `translateX(-50%)`,
        maxWidth: kContentWidthMedium,
        width: '100%',
        "@media (max-width: 1000px)": {
            width: "auto",
            transform: "none",
            left: "auto",
            position: "relative",
        },
        "@media (max-width: 767px)": {
            zIndex: 1,
            marginLeft: 4,
            paddingRight: 8,
            marginBottom: 16,
        },
        'div:last-child': {
            a: {
                textDecoration: "none",
                color: kAppColors.text.secondary(theme.palette.mode === "dark"),
            }
        }
    },
    chevron: {
        marginLeft: 8,
        marginRight: 8,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
    },
    firstChevron: {
        marginLeft: 0,
    },
    linkContainer: {
        'a': {
            lineHeight: '24px',
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
            textDecorationColor: kAppColors.text.primary(theme.palette.mode === "dark"),
            fontWeight: "bold",
        },
        display: "flex",
        whiteSpace: "nowrap",
    }
}));

export default function AppBreadCrumb() {
    const appDataContext = useContext(AppDataContext);
    const {breadcrumbs} = appDataContext;

    const {classes, cx} = useStyles();

    const breadcrumbItems = breadcrumbs.map((item, index) => (
        <BreadcrumbItem key={item.key} isFirst={index === 0} breadcrumb={item}/>
    ));

    return (
        <Box className={cx('styledScrollbar', classes.container)}>
            {breadcrumbItems}
        </Box>
    );
}

interface IBreadcrumbItemProps {
    isFirst?: boolean;
    breadcrumb: IBreadcrumb;
}

function BreadcrumbItem(props: IBreadcrumbItemProps) {
    const {isFirst, breadcrumb} = props;

    const navigate = useNavigate();
    const {classes, cx} = useStyles();

    const theChevronClass = cx(
        classes.chevron,
        // isFirst ? classes.firstChevron : undefined, //TODO Jan, may need different way to handle margin
    );

    let childJSX = undefined;
    if (breadcrumb.text) {
        childJSX =
            <Link color={kAppColors.primary.main} onClick={() => navigate(breadcrumb.route)}>{breadcrumb.text}</Link>;
    } else if (breadcrumb.icon) {
        childJSX = <AppIconButton
            tooltip={breadcrumb.tooltip}
            onClick={() => navigate(breadcrumb.route)}
        >{breadcrumb.icon}</AppIconButton>;
    } else {
        console.error('BreadcrumbItem missing text or icon');
    }

    return (
        <Box className={classes.linkContainer}>
            {!isFirst ? <ChevronRight className={theChevronClass}/> : null}

            {childJSX}
        </Box>
    );
}
