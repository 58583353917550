import { iconUseStyles } from "../styles/IconStyles";

export interface ICalendarProps {
    className?: string;
}

export default function CardIcon(props: ICalendarProps) {
    const { className } = props;

    const { classes, cx } = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
            <path d="M 9.5 9 C 5.9280619 9 3 11.928062 3 15.5 L 3 32.5 C 3 36.071938 5.9280619 39 9.5 39 L 38.5 39 C 42.071938 39 45 36.071938 45 32.5 L 45 15.5 C 45 11.928062 42.071938 9 38.5 9 L 9.5 9 z M 9.5 12 L 38.5 12 C 40.450062 12 42 13.549938 42 15.5 L 42 32.5 C 42 34.450062 40.450062 36 38.5 36 L 9.5 36 C 7.5499381 36 6 34.450062 6 32.5 L 6 15.5 C 6 13.549938 7.5499381 12 9.5 12 z M 11 17 C 9.895 17 9 17.895 9 19 L 9 20.714844 C 9 21.819844 9.895 22.714844 11 22.714844 L 15 22.714844 C 16.105 22.714844 17 21.819844 17 20.714844 L 17 19 C 17 17.895 16.105 17 15 17 L 11 17 z M 24.5 17 A 1.50015 1.50015 0 1 0 24.5 20 L 37.5 20 A 1.50015 1.50015 0 1 0 37.5 17 L 24.5 17 z M 10.5 28 A 1.50015 1.50015 0 1 0 10.5 31 L 13.5 31 A 1.50015 1.50015 0 1 0 13.5 28 L 10.5 28 z M 18.5 28 A 1.50015 1.50015 0 1 0 18.5 31 L 21.5 31 A 1.50015 1.50015 0 1 0 21.5 28 L 18.5 28 z M 26.5 28 A 1.50015 1.50015 0 1 0 26.5 31 L 29.5 31 A 1.50015 1.50015 0 1 0 29.5 28 L 26.5 28 z M 34.5 28 A 1.50015 1.50015 0 1 0 34.5 31 L 37.5 31 A 1.50015 1.50015 0 1 0 37.5 28 L 34.5 28 z"/>
            </svg>
        </span>
    );
}
