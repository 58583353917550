import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import WorkersAndProfileForm, {IWorkersFormUpdate} from "../../components/workers/WorkersAndProfileForm";
import ContentPadding from "../../components/paper/ContentPadding";
import {
    EmployeeJoinedUserResponse,
    GetEmployeeJoinedUserInput,
    UpdateCompanyEmployeeDocument,
    UpdateCompanyEmployeeMutation,
    UpdateCompanyEmployeeMutationVariables
} from "../../../generated/graphql/graphql";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {useNavigate, useParams} from "react-router-dom";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {SuccessToast} from "../../../service/ToastService";
import {workerDetailRoute} from "./WorkerDetailScreen";
import {processMutationError, processQueryError} from "../../../service/ErrorService";
import IEventSystemListener from "../../../model/EventSystemListener";
import {listenToEventSystem, unListenToEventSystem} from "../../../service/EventSystemService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import {kWorkersRoute} from "./WorkersScreen";
import {AppDataContext} from "../../../AppData";
import {kActionDelete, kTopicCompanyEmployees} from "../../../core/constants";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";


export const kWorkerEditRoute = '/workers/:id/edit';


export default function WorkerEditScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle, authUser} = appContext;

    const appDataContext = useContext(AppDataContext);

    const {id} = useParams();

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<EmployeeJoinedUserResponse | NullOrUndefined>();
    useEffect(() => {
        restApiGet({
            uri: '/company/employee/joined-user',
            params: {
                employeeId: parseInt(id!),
            } as GetEmployeeJoinedUserInput,
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading: setLoading,
            onData: setData,
            onError: (error) => {
                processQueryError(appDataContext, error);
            },
        });
    }, [id]);

    const [mutateUpdate, {
        loading: updateLoading,
    }] = useResettableMutation<UpdateCompanyEmployeeMutation, UpdateCompanyEmployeeMutationVariables>(UpdateCompanyEmployeeDocument);

    useEffect(() => {
        setTitle(tt('workerEdit.screen.title'));
    }, []);

    /**
     * Mutate Employee update to BE and back on success.
     */
    const UpdateEmployee = async (update: IWorkersFormUpdate) => {
        try {
            const variables: UpdateCompanyEmployeeMutationVariables = {
                input: {
                    id: parseInt(id!),
                    name: update.inputs.firstName.value,
                    surname: update.inputs.lastName.value,
                    phoneNumber: update.inputs.phone.value,
                    contactEmail: update.inputs.email.value,
                    address: update.addressFragment,
                    role: update.inputs.position.value,
                    contractType: update.inputs.contractType.value || undefined,
                    hourRate: parseFloat(update.inputs.hourlyWage.value),
                    distanceRate: parseFloat(update.inputs.distanceRate.value),
                    permissionsUpdateForRole: update.inputs.canPermissionsUpdateForRole.value && !update.inputs.canPermissionsUpdateForRole.hidden,
                },
            };

            const result = await mutateUpdate({variables});

            if (!result.errors) {
                navigate(workerDetailRoute(id!));

                SuccessToast(tt('workerEdit.screen.success'));
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                isMe={authUser?.email === data?.user?.email}
                loading={loading || updateLoading}
                data={data}
                isMobile={isMobile}
                onSubmit={UpdateEmployee}
            />
        );
    }

    return (
        <>
            <EventSystemListeners/>

            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}
            />
        </>
    );
}

/**
 * Component for EventSystem listeners.
 */
function EventSystemListeners() {
    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicCompanyEmployees,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(id!);
                });

                if (doDelete) {
                    navigate(kWorkersRoute);
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [id]);

    return null;
}

interface IBodyProps {
    isMe: boolean;
    loading: boolean;
    data?: EmployeeJoinedUserResponse | NullOrUndefined;
    isMobile?: boolean;
    onSubmit: (update: IWorkersFormUpdate) => void;
}

function Body(props: IBodyProps) {
    const {isMe, loading, data, isMobile, onSubmit} = props;

    const {id} = useParams();

    const theData = data;

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}
            >
                <PaperAppbar isMobile={isMobile}
                             title={tt('workerEdit.screen.title')}
                             backRoute={workerDetailRoute(id!)}
                             cancelIconBackButton={true}/>

                <ContentPadding>
                    <WorkersAndProfileForm
                        isEdit={true}
                        isMe={isMe}
                        firstName={theData?.name || theData?.user?.name}
                        lastName={theData?.surname || theData?.user?.surname}
                        address={theData?.address || theData?.user?.address}
                        position={theData?.role}
                        phone={theData?.phoneNumber || theData?.user?.phoneNumber}
                        email={theData?.contactEmail || theData?.user?.contactEmail}
                        contractType={theData?.contractType}
                        hourlyWage={theData?.hourRate}
                        distanceRate={theData?.distanceRate}
                        onSubmit={onSubmit}
                        loading={loading}
                        loadingData={!data && loading}
                        canPermissionsUpdateForRole={true}
                    />
                </ContentPadding>
            </AppPaper>
        </ScreenContent>);
}
