import React from "react";
import {Box} from "@mui/material";
import {EmployeeRole} from "../../../generated/graphql/graphql";
import {kAdminPermissions, kAllPermissions} from "../../../service/PermissionsService";
import SinglePermissionListItem from "./SinglePermissionListItem";
import {IUpdatePermissionsParams} from "./EditPermissionsModal";

export interface IPermissionsSectionProps {
    role: EmployeeRole;
    employeePermissionsMap: Record<string, string[]>;
    applyForAllOfRole?: EmployeeRole;
    updatePermissions?: (params: IUpdatePermissionsParams) => void;
}

export default function PermissionsSection(props: IPermissionsSectionProps) {
    const {role, employeePermissionsMap, applyForAllOfRole, updatePermissions} = props;

    return (
        <>
            <Box pb={1}/>

            {role === EmployeeRole.Admin ? (
                <>
                    {kAdminPermissions.map((value, index) => {
                        return (
                            <SinglePermissionListItem
                                key={index}
                                role={role}
                                permission={value}
                                employeePermissionsMap={employeePermissionsMap}
                                applyForAllOfRole={applyForAllOfRole}
                                updatePermissions={updatePermissions}
                            />
                        );
                    })}
                </>
            ) : kAllPermissions.map((value, index) => {
                return (
                    <SinglePermissionListItem
                        key={index}
                        role={role}
                        permission={value}
                        employeePermissionsMap={employeePermissionsMap}
                        applyForAllOfRole={applyForAllOfRole}
                        updatePermissions={updatePermissions}
                    />
                );
            })}
        </>
    );
}
