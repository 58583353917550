import {makeStyles} from "tss-react/mui";
import {Box, Menu, Theme} from "@mui/material";
import {usePopupState} from "material-ui-popup-state/hooks";
import AppIconButton from "../buttons/AppIconButton";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import PhoneIcon from "../../../icons/PhoneIcon";
import Icons8PhoneFilled from "../../../icons/Icons8PhoneFilled";

export const useStyles = makeStyles()((theme: Theme) => ({
    marginRight: {
        marginRight: 8,
    },
    link: {
        display: 'inline-flex',
        color: 'inherit',
        textDecoration: 'none',
        alignItems: "center",
    },
}));

export interface IPhoneMenuProps {
    phone: string;
    showMarginRight?: boolean;
}

export default function PhoneMenu(props: IPhoneMenuProps) {
    const {phone, showMarginRight} = props;

    const phoneOptionsPopupState = usePopupState({
        variant: 'popover',
        popupId: 'popup-address',
    });

    const {classes, cx} = useStyles();

    const theButtonClass = cx(
        showMarginRight ? classes.marginRight : null,
    );

    return (
        <>
            <AppIconButton
                className={theButtonClass}
                placement={"right"}
                variant={"greyBg"}
                {...bindTrigger(phoneOptionsPopupState)}>
                <Icons8PhoneFilled/>
            </AppIconButton>

            <Menu {...bindMenu(phoneOptionsPopupState)}>
                <MenuItem onClick={() => phoneOptionsPopupState.close()}>
                    <a className={classes.link} href={`tel:${phone.replace(' ', '')}`}>
                        <PhoneIcon/>
                        <Box pr={1}/>
                        <span>{phone}</span>
                    </a>
                </MenuItem>
            </Menu>
        </>
    );
}
