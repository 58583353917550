import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../../App";
import {AppDataContext} from "../../../AppData";
import {GetJobInput, JobDetailResponse} from "../../../generated/graphql/graphql";
import {tt} from "../../../core/Localization";
import {processQueryError} from "../../../service/ErrorService";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import {kJobsRoute} from "./JobsScreen";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {getBackRoute} from "../../../utils/Utils";
import JobDetailMenu from "../../components/jobs/jobDetail/JobDetailMenu";
import DetailScreenShimmer from "../../components/shimmers/DetailScreenShimmer";
import JobDetailContent from "../../components/jobs/jobDetail/JobDetailContent";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import {Simulate} from "react-dom/test-utils";
import {kActionUpdate, kTopicJobs} from "../../../core/constants";

export const kJobDetailRoute = '/jobs/:jobId';

export interface IJobDetailRouteParams {
    jobId: string | number;
    urlParams?: string[];
}

export function jobDetailRoute(
    params: IJobDetailRouteParams,
): string {
    const {jobId, urlParams} = params;

    let theRoute = kJobDetailRoute
        .replace(':jobId', `${jobId}`);

    const theUrlsParams: string[] = urlParams || [];

    if (theUrlsParams.length > 0) {
        theRoute += `?${theUrlsParams.join('&')}`;
    }

    return theRoute;
}

/**
 * Verify if current location is JobDetailScreen for Job.
 */
export function isJobDetailScreen(jobId: string | number): boolean {
    const routeForId = jobDetailRoute({jobId});

    return window.location.pathname.startsWith(routeForId);
}

export interface IOnUpdateVisitIdParams {
    jobId: number;
    repeatingDay: number;
}

export default function JobDetailScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {setStorage} = appDataContext;

    const {jobId, status} = useParams();

    const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);
    const [canLoadingAlways, setCanLoadingAlways] = useState<boolean>(true);

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<JobDetailResponse | NullOrUndefined>();
    useEffect(() => {
        const subscription = subscribe(
            kTopicJobs,
            {
                uri: '/job/detail',
                params: {
                    jobId: parseInt(jobId!),
                } as GetJobInput,
                setLoading,
                onData: setData,
                onError: (error) => processQueryError(appDataContext, error),
            },
            (notifications) => {
                return notifications.some(notification => {
                    if (notification.action !== kActionUpdate) {
                        return false;
                    }

                    return notification.data.id === parseInt(jobId!);
                });
            },
        );

        return () => {
            subscription.cancel();
        };
    }, [jobId]);

    useEffect(() => {
        if (data) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(data.files || []),
                    ],
                };
            });
        }
    }, [data]);

    useEffect(() => {
        setTitle(tt('jobDetail.screen.title'));
    }, []);

    useEffect(() => {
        if (isFirstLoading && !loading) {
            setIsFirstLoading(false);
        }
    }, [loading]);

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                status={status}
                isMobile={isMobile}
                isLoading={loading && (isFirstLoading || canLoadingAlways)}
                data={data}
                jobId={parseInt(jobId!)}
            />
        );
    }

    return (
        <>
            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}/>
        </>
    );
}

interface IBodyProps {
    status?: string;
    isMobile?: boolean;
    isLoading?: boolean;
    data?: JobDetailResponse | NullOrUndefined;
    jobId: number;
}

function Body(props: IBodyProps) {
    const {
        status,
        isMobile,
        data,
        isLoading,
        jobId,
    } = props;

    const location = useLocation();
    const navigate = useNavigate();

    let backRoute = `${kJobsRoute}${location.search}`;

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium, flexGrow: isMobile ? 1 : undefined}}>
                <PaperAppbar
                    isMobile={isMobile}
                    title={tt('jobDetail.detail.content.title')}
                    backRoute={getBackRoute(backRoute)}
                    noMarginBottom={true}
                >
                    <JobDetailMenu
                        data={data}
                        onDelete={() => navigate(getBackRoute(backRoute))}
                        jobId={jobId}
                    />
                </PaperAppbar>

                {isLoading && !data ? <DetailScreenShimmer/> : (
                    <JobDetailContent
                        data={data}
                        jobId={jobId}
                    />
                )}
            </AppPaper>
        </ScreenContent>
    );
}
