import React, {useContext, useEffect, useState} from "react";
import {tt} from "../../../../../core/Localization";
import AppModal from "../../AppModal";
import VisitDetailMenu from "../../../jobs/visits/visitDetail/VisitDetailMenu";
import VisitDetailContent from "../../../jobs/visits/visitDetail/VisitDetailContent";
import {processQueryError} from "../../../../../service/ErrorService";
import {AppDataContext} from "../../../../../AppData";
import {
    GetVisitInput,
    JobUpdateRepeats,
    VisitDetailResponse,
    VisitRepeating
} from "../../../../../generated/graphql/graphql";
import {HideVisitDetailModal, SetVisitDetailModal} from "../../AppModals";
import {useNavigate} from "react-router-dom";
import {routeWithCurrentAsParam} from "../../../../../utils/Utils";
import {jobDetailRoute} from "../../../../screens/jobs/JobDetailScreen";
import {RestApiClientContext} from "../../../../../core/RestApiProvider";
import IEventSystemNotification from "../../../../../model/firestore/EventSystemNotification";
import VisitDetailShimmer from "../../../shimmers/screenSpecificShimmers/VisitDetailShimmer";
import {kActionUpdate, kTopicVisits} from "../../../../../core/constants";
import {DateTimeToRepeatingDayId} from "../../../../../service/VisitService";
import {DateTime} from "luxon";

export interface IOnUpdateVisitIdParams {
    visitId: number;
    repeatingDay: number;
}

export interface IVisitDetailModalProps {
    visitId: number;
    repeatingDay?: number;
    open: boolean;
    canNavigateToJob?: boolean;
    preselectSelectedTab?: string | NullOrUndefined;
}

export default function VisitDetailModal(props: IVisitDetailModalProps) {
    const {open, visitId, repeatingDay, canNavigateToJob, preselectSelectedTab} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {setStorage} = appDataContext;

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<VisitDetailResponse | NullOrUndefined>();
    useEffect(() => {
        if (open) {
            setLoading(true);

            const subscription = subscribe(
                kTopicVisits,
                {
                    uri: '/job/visit/detail',
                    params: {
                        visitId,
                        repeatingDay,
                        includeHistory: true,
                        includeJobFormTemplates: true,
                    } as GetVisitInput,
                    setLoading,
                    onData: setData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                (notifications: IEventSystemNotification[]) => {
                    return notifications.some(notification => {
                        if (notification.action !== kActionUpdate) {
                            return false;
                        }

                        return notification.data.id === visitId &&
                            (notification.data.repeatingDay === repeatingDay || (!notification.data.repeatingDay && !repeatingDay)) || notification.data.repeats === JobUpdateRepeats.ThisAndAllFuture;
                    });
                },
            );

            return () => {
                subscription.cancel();
            };
        } else {
            setData(undefined);
        }
    }, [open, visitId, repeatingDay]);

    useEffect(() => {
        if (data) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(data.files || []),
                    ],
                };
            });
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            if (!repeatingDay && data.visit.repeating !== VisitRepeating.Never) {
                const newRepeatingDay = DateTimeToRepeatingDayId(DateTime.fromMillis(data.visit.startDate));

                SetVisitDetailModal(appDataContext, {
                    open: true,
                    visitId: visitId,
                    repeatingDay: newRepeatingDay,
                    canNavigateToJob,
                    preselectSelectedTab,
                });
            }
        }
    }, [data]);

    /**
     * New visit chain created with some repeating day action, switch modal.
     */
    const onUpdateVisitId = (params: IOnUpdateVisitIdParams) => {
        const {visitId: newVisitId, repeatingDay} = params;

        if (newVisitId === visitId) {
            return;
        }

        SetVisitDetailModal(appDataContext, {
            visitId: newVisitId,
            repeatingDay: repeatingDay,
        });
    };

    /**
     * Go to Job detail and hide this modal.
     */
    const onNavigateToJob = () => {
        HideVisitDetailModal(appDataContext);

        navigate(routeWithCurrentAsParam(
            jobDetailRoute({
                jobId: data!.job.id,
            }),
        ));
    };

    return (
        <AppModal
            blurBackdrop={true}
            fullScreenOnMobile={true}
            noContentPadding={true}
            title={tt('visitDetail.modal')}
            appBarNoMarginBottom={true}
            onClose={() => HideVisitDetailModal(appDataContext)}
            headerActions={
                <VisitDetailMenu
                    data={data}
                    repeatingDay={repeatingDay}
                    onDelete={() => HideVisitDetailModal(appDataContext)}
                    onUpdateVisitId={onUpdateVisitId}
                    visitId={visitId}
                />
            }
            open={open}>
            {loading && !data ?
                <VisitDetailShimmer/>
                :
                <VisitDetailContent
                    open={open}
                    data={data}
                    visitId={visitId}
                    repeatingDay={repeatingDay}
                    onUpdateVisitId={onUpdateVisitId}
                    onNavigateToJob={canNavigateToJob && data ? onNavigateToJob : undefined}
                    preselectSelectedTab={preselectSelectedTab}
                />
            }
        </AppModal>
    );
}
