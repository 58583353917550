import React, {ReactNode, useContext} from "react";
import {AppDataContext} from "../../../../../../AppData";
import {kAppColors} from "../../../../../../styles/AppThemeProcessor";
import {Box, Step, StepLabel, Typography} from "@mui/material";
import {useStyles} from "./VisitDetailStatusModifierSection";

interface ISingleStepProps {
    index: number;
    completedItemIndex: number;
    color?: string;
    label?: string;
    labelWidget?: ReactNode;
    icon: ReactNode;
}

export default function SingleStepWidget(props: ISingleStepProps) {
    const {
        color,
        index,
        completedItemIndex,
        labelWidget,
        label,
        icon,
    } = props;

    const {classes, cx} = useStyles();

    const appDataContext = useContext(AppDataContext);
    const {darkMode} = appDataContext;
    const inactiveColor = kAppColors.text.secondary(darkMode);
    const isCompleted = index <= completedItemIndex;

    return (
        <Step key={index}
              className={cx(index === 0 ? 'first' : null)}
              sx={{
                  '.MuiStepConnector-line': {
                      backgroundColor: isCompleted ? color : inactiveColor,
                      opacity: isCompleted ? 1 : 0.5,
                  }
              }}
              style={{color: isCompleted ? color : inactiveColor,}}
        >
            <StepLabel
                StepIconProps={{
                    color: isCompleted ? color : inactiveColor,
                }}
                icon={icon}
            >
                <Box className={classes.labelContainer}>
                    {label ?
                        <Typography
                            color={isCompleted ? color : inactiveColor}
                            fontWeight={600}
                            fontSize={12}
                        >{label}</Typography>
                        : labelWidget}
                </Box>
            </StepLabel>
        </Step>
    );
}