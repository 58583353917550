import {FormSwitchProps} from "./InputProps";
import {useStyles as formUseStyles} from "./FormBuilder";
import React from "react";
import {Box, FormControlLabel, Switch, Theme, Typography} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {makeStyles} from "tss-react/mui";
import GreyLabel from "../decorations/GreyLabel";

export const useStyles = makeStyles()((theme: Theme) => ({
    input: {
        '.MuiFormControlLabel-label': {
            fontSize: 12,
            fontWeight: 600,
            color: kAppColors.text.secondaryLighterForDarkMode(theme.palette.mode === "dark")
        },
    },
    container: {
        marginBottom: 4,
    },
    android12Switch: {
        '.MuiSwitch-root': {
            width: 64,
        },
        '.MuiSwitch-switchBase.Mui-checked:hover': {
            backgroundColor: 'rgba(90,199,0, 0.2)',
        },
        '.MuiFormControlLabel-label': {
            marginLeft: 5,
        },
        '& .MuiSwitch-track': {
            opacity: 1 + ' !important',
            background: '#989898',
            flexShrink: 0,
            width: 42 + 'px !important',
            height: 22,
            transform: 'translateY(-4px)',
            borderRadius: 22 / 2,
        },
        '& .isChecked .MuiSwitch-track': {
            '&:before': {
                content: '""',
                position: 'absolute',
                left: 3,
                top: '50%',
                transform: 'translateY(-50%)',
                width: 16,
                height: 16,
                background: kAppColors.green.secondary,
                maskImage: `url("data:image/svg+xml,%3Csvg fill='currentColor' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' width='18px' height='18px'%3E%3Cpath d='M 43.470703 8.9863281 A 1.50015 1.50015 0 0 0 42.439453 9.4394531 L 16.5 35.378906 L 5.5605469 24.439453 A 1.50015 1.50015 0 1 0 3.4394531 26.560547 L 15.439453 38.560547 A 1.50015 1.50015 0 0 0 17.560547 38.560547 L 44.560547 11.560547 A 1.50015 1.50015 0 0 0 43.470703 8.9863281 z'/%3E%3C/svg%3E")`,
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: 16,
            height: 16,
            margin: 2,
        },
        '.Mui-checked': {
            color: kAppColors.green.secondary + ' !important',
        },
        '.Mui-checked+.MuiSwitch-track': {
            backgroundColor: 'rgba( 90, 199, 0, 0.2 )' + ' !important',
        },
        '.MuiSwitch-switchBase': {
            transform: 'translateX(3px)'
        },
        '.MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(24px)'
        },
        '.MuiTypography-root': {
            transition: 'font-weight 0.3s linear',
        }
    },
    Android12SwitchNoLabel: {
        marginRight: 0,
    },
    android12SwitchPrimaryColor: {
        '.MuiSwitch-switchBase:hover, .MuiSwitch-switchBase.Mui-checked:hover': {
            backgroundColor: 'rgba(255,87,34, 0.2)',
        },
        '& .MuiSwitch-track': {
            backgroundColor: 'rgba(255,87,34, 0.2)',
        },
        '& .isChecked .MuiSwitch-track': {
            '&:before': {
                display: "none",
            },
        },
        '.MuiSwitch-switchBase, .Mui-checked': {
            color: kAppColors.primary.main + ' !important',
        },
        '.Mui-checked+.MuiSwitch-track': {
            backgroundColor: 'rgba(255,87,34, 0.2)' + ' !important',
        },
    },
    hasLabelBefore: {
        '.MuiFormControlLabel-label': {
            color: kAppColors.text.primary(theme.palette.mode === "dark") + ' !important',
            marginLeft: '0px !important',
        },
    },
    labelBefore: {
        transition: 'font-weight 0.3s linear',
        fontSize: 12,
        marginRight: 8,
    },
    bold: {
        fontWeight: 700,
    },
    isOn: {
        '.MuiTypography-root': {
            fontWeight: 700,
        },
    },
    containerInner: {
        display: "flex",
        alignItems: "center",
    },
    topLabelPadding: {
        paddingTop: 16,
        paddingBottom: 8,
    },
}));

/**
 * Toggle switch form component.
 */
export default function FormSwitch(props: FormSwitchProps) {
    const {
        index,
        className,
        label,
        value,
        updateValue,
        hidden,
        error,
        errorMessage,
        onSwitch,
        testId,
        switchVariant,
        labelBefore,
        topLabel,
        disabled,
        hideLabel,
    } = props;

    const {classes: formClasses, cx} = formUseStyles();
    const {classes} = useStyles();

    /**
     * OnChange update data of this field.
     */
    const OnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateValue(index, e.target.checked);

        if (onSwitch) {
            onSwitch(e.target.checked);
        }
    };

    const variantClass = cx(
        switchVariant === 'Android12Switch' || switchVariant === 'Android12SwitchNoLabel' || switchVariant === 'Android12SwitchPrimaryColor' ? classes.android12Switch : null,
        switchVariant === 'Android12SwitchNoLabel' ? classes.Android12SwitchNoLabel : null,
        switchVariant === 'Android12SwitchPrimaryColor' ? classes.android12SwitchPrimaryColor : null,
    );

    return (
        <div
            className={cx(classes.container, className, (hidden ? formClasses.hidden : undefined), labelBefore ? classes.hasLabelBefore : null)}>
            {topLabel ? <GreyLabel className={classes.topLabelPadding} text={topLabel}/> : null}
            <Box className={classes.containerInner}>
                {labelBefore ?
                    <Typography
                        className={cx(value ? null : classes.bold, classes.labelBefore)}>{labelBefore}</Typography> : null}
                <FormControlLabel
                    className={cx(classes.input, value && labelBefore ? classes.isOn : null, variantClass)}
                    control={(
                        <Switch
                            className={value ? 'isChecked' : undefined}
                            data-testid={testId}
                            checked={value}
                            disabled={disabled}
                            onChange={OnChange}
                        />
                    )}
                    label={hideLabel ? null : label}
                    labelPlacement="end"/>
            </Box>
        </div>
    );
}
