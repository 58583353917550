import {Box, Theme, Typography} from "@mui/material";
import React from "react";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
    iconSize: {
        '.svgContainer, svg, span': {
            height: 20 + 'px !important',
            width: 20 + 'px !important',
        },
    },
}));

export interface ICustomStatusLikeChipProps {
    text?: string | React.ReactNode;
    textColor?: string;
    backgroundColor?: string;
    variant?: 'outlined' | 'filled';
    icon?: React.ReactNode;
    className?: string;
}

export default function CustomStatusLikeChip(props: ICustomStatusLikeChipProps) {
    const {
        className,
        text,
        textColor,
        backgroundColor,
        variant = 'filled',
        icon,
    } = props;

    const {classes} = useStyles();

    return (
        <Box
            style={{
                display: "flex",
                alignItems: "center",
                height: 30,
                borderRadius: 8,
                paddingTop: 4,
                paddingBottom: 4,
                paddingRight: 8,
                paddingLeft: 8,
                backgroundColor: variant === 'filled' ? backgroundColor : 'transparent',
                border: variant === 'filled' ? 'none' : `1px solid ${textColor}`
            }}
            className={className}
        >
            {icon ? <Box sx={{color: textColor, marginRight: text ? 0.5 : 0}}>
                <Box className={classes.iconSize}>
                    {icon}
                </Box>
            </Box> : null}
            {text ? <Typography
                    style={{
                        fontSize: 12,
                        fontWeight: 700,
                        color: textColor,
                    }}>
                    {text}
                </Typography>
                : null}
        </Box>
    );

}