import {Box, Theme} from "@mui/material";
import AppListItem from "../../listItems/AppListItem";
import React, {useContext, useEffect, useMemo} from "react";
import {makeStyles} from "tss-react/mui";
import {
    CreateFileDocument, CreateFileInput,
    CreateFileMutation,
    CreateFileMutationVariables,
    FileResponse
} from "../../../../generated/graphql/graphql";
import AppIconButton from "../../buttons/AppIconButton";
import {RestApiClientContext} from "../../../../core/RestApiProvider";
import Icons8Download from "../../../../icons/Icons8Download";
import Icons8Document from "../../../../icons/Icons8Document";
import Icons8Eye from "../../../../icons/Icons8Eye";
import {linkLikeButtonStyle} from "../../../../styles/UtilStyles";
import {isPDFFile, storeFileToFirebase} from "../../../../service/StorageService";
import IFileState, {IFileStateType} from "../../../../model/FileState";
import CircularProgressWithLabel from "../../storage/CircularProgressWithLabel";
import {useMutation} from "@apollo/client";
import {processMutationError} from "../../../../service/ErrorService";
import {tt} from "../../../../core/Localization";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import DeleteIcon from "../../../../icons/DeleteIcon";

export const useStyles = makeStyles()((theme: Theme) => ({
    addBreakline: {
        '.MuiTypography-body1': {
            lineBreak: 'anywhere',
        }
    }
}));

export interface IProtocolOtherFileListItemProps {
    file?: FileResponse;
    publicUrl?: string;
    isDetail?: boolean;
    fileState?: IFileState;
    onUpdateFileState?: (updateFileState: (fileState: IFileState) => IFileState, fileUploadFinished?: boolean) => void;
    onDelete?: (uuid: string) => void;
}

/**
 * Component for displaying Protocol other file list item.
 */
export default function ProtocolOtherFileListItem(props: IProtocolOtherFileListItemProps) {
    const {file, publicUrl, isDetail, fileState, onUpdateFileState, onDelete} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restUriBuilder} = restApiClientContext;

    const {classes} = useStyles();
    const linkStyle = linkLikeButtonStyle();

    const [mutateCreateFile, {
        loading: createFileLoading,
    }] = useMutation<CreateFileMutation, CreateFileMutationVariables>(CreateFileDocument);

    useEffect(() => {
        if (onUpdateFileState && fileState && fileState.inputFile && fileState.type === IFileStateType.Select) {
            onUpdateFileState((fileState) => ({
                ...fileState,
                type: IFileStateType.Upload,
            }));

            FileChosen();
        }
    }, [fileState?.inputFile]);

    /**
     * On file chosen upload, send to BE and on success change state to file.
     */
    const FileChosen = () => {
        storeFileToFirebase({
            fileState: fileState!,
            onUpdateFileState: onUpdateFileState!,
            onDelete: onDelete!,
            onComplete: UpdateFileStateToBE,
        });
    };

    /**
     * On file upload success, update file state to BE.
     */
    const UpdateFileStateToBE = async (input: CreateFileInput) => {
        try {
            const variables: CreateFileMutationVariables = {
                input,
            };

            const result = await mutateCreateFile({variables});

            if (!result.errors) {
                onUpdateFileState!((fileState) => ({
                    ...fileState,
                    type: IFileStateType.File,
                    data: result.data?.createFile,
                }), true);
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    const actionsJSX = isDetail ? (
        <>
            {isPDFFile(file!.path) ? (
                <Box pr={1}>
                    <a
                        className={linkStyle.classes.linkButton}
                        href={publicUrl}
                        target={'_blank'}
                    >
                        <Icons8Eye/>
                    </a>
                </Box>
            ) : null}

            <AppIconButton
                variant={"greyBg"}
                placement={"right"}
                onClick={() => {
                    const downloadUrl = restUriBuilder('/firebase/download-file', {fileId: file!.id});

                    window.open(downloadUrl, '_blank');
                }}
            >
                <Icons8Download/>
            </AppIconButton>
        </>
    ) : (
        <Box mr={-1}>
            <AppIconButton
                color={"primary"}
                onClick={() => onDelete!(fileState!.uuid)}
                tooltip={tt('createProtocolBottomSheet.button.delete')}
            >
                <Box sx={{color: kAppColors.red.main}}>
                    <DeleteIcon/>
                </Box>
            </AppIconButton>
        </Box>
    );

    const progressJSX = useMemo(() => {
        const theProgress = fileState?.progress || 0;

        return fileState?.type === IFileStateType.Select || fileState?.type === IFileStateType.Upload ? (
            <CircularProgressWithLabel value={theProgress}/>
        ) : null;
    }, [fileState]);

    return (
        <>
            <AppListItem
                className={classes.addBreakline}
                variant={"smaller-title"}
                title={file?.name || fileState?.inputFile?.name || ''}
                customAvatar={progressJSX || <Icons8Document/>}
                actionWidget={actionsJSX}
            />
        </>
    );
}
