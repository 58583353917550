import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import FormBuilder, {IInputsData} from "./FormBuilder";
import Debouncer from "../../../utils/Debouncer";
import {kUserInputDebounce} from "../../../core/constants";

export interface IVisitBaseFormProps {
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    initialized: boolean;
}

/**
 * Form component to debounce user input.
 */
export default function DebouncedForm(props: IVisitBaseFormProps) {
    const {inputs, setInputs, initialized} = props;

    const setParentInputsDebouncer = useRef(new Debouncer(kUserInputDebounce));
    const [baseInputs, setBaseInputs] = useState<IInputsData>({});

    useEffect(() => {
        return () => {
            setParentInputsDebouncer.current?.dispose();
        };
    }, []);

    useEffect(() => {
        setBaseInputs({
            ...inputs,
        });
    }, [inputs]);

    /**
     * Set component baseInputs right away.
     * Use Debouncer to update parent component inputs after delay.
     */
    const setBaseInputsDebounced = (inputs: SetStateAction<IInputsData>) => {
        setBaseInputs(inputs);

        if (initialized) {
            setParentInputsDebouncer.current!.run(() => {
                setInputs(inputs);
            });
        } else {
            setInputs(inputs);
        }
    };

    return (
        <FormBuilder
            inputs={baseInputs}
            setInputs={setBaseInputsDebounced}
        />
    );
}
