import {Option} from "../ui/components/form/InputProps";
import {tt} from "../core/Localization";


/**
 * Convert User address into single line.
 */
export function addressToSingleLine(address: {
    street: string,
    city: string,
    country: string,
    state: string,
    zipcode: string
} | NullOrUndefined, streetOnly?: boolean, skipZipcode?: boolean): string | undefined {
    if (address) {
        if (streetOnly) {
            return address.street;
        }

        const parts: string[] = [];

        const zipcode = skipZipcode ? '' : address.zipcode;

        if (address.street) {
            parts.push(address.street);
        }
        if (`${zipcode} ${address.city}`.trim()) {
            parts.push(`${zipcode} ${address.city}`.trim());
        } else if (address.state) {
            parts.push(address.state);
        }
        if (address.country) {
            parts.push(countryLabelForCode(address.country));
        }

        return parts.join(', ');
    }

    return '';
}

/**
 * Check if billing address has any field set.
 */
export function hasBillingAddress(address: {
    street: string,
    city: string,
    country: string,
    state: string,
    zipcode: string,
    businessId: string,
    taxId: string,
} | NullOrUndefined): boolean {
    return !!(address && (address.street || address.city || address.state || address.country || address.zipcode || address.businessId || address.taxId));
}

/**
 * Convert single line address into Google Maps link.
 */
export function addressMapsLink(address: string | undefined): string | undefined {
    if (address) {
        return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    }

    return;
}

/**
 * Find Country for code.
 */
export function countryLabelForCode(code: string): string {
    for (const country of kCountries) {
        if (country.code.toLowerCase() === code.toLowerCase()) {
            return tt(country.label);
        }
    }

    return '';
}

/**
 * Find country for code.
 */
export function countryOptionForCode(code: string): Option | undefined {
    return kAllCountriesAsOptions().find((country) => country.value === code.toLowerCase());
}

/**
 * Get all countries as options.
 */
export function kAllCountriesAsOptions(): Option[] {
    return kCountries.map((country) => ({
        ...country,
        label: tt(country.label),
        value: country.code.toLowerCase(),
    }));
}

export interface ICountryType {
    code: string;
    label: string;
    phone: string;
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const kCountries: readonly ICountryType[] = [
    { code: 'AD', label: 'common.country.ad', phone: '376' },
    { code: 'AE', label: 'common.country.ae', phone: '971' },
    { code: 'AF', label: 'common.country.af', phone: '93' },
    { code: 'AG', label: 'common.country.ag', phone: '1-268' },
    { code: 'AI', label: 'common.country.ai', phone: '1-264' },
    { code: 'AL', label: 'common.country.al', phone: '355' },
    { code: 'AM', label: 'common.country.am', phone: '374' },
    { code: 'AO', label: 'common.country.ao', phone: '244' },
    { code: 'AQ', label: 'common.country.aq', phone: '672' },
    { code: 'AR', label: 'common.country.ar', phone: '54' },
    { code: 'AS', label: 'common.country.as', phone: '1-684' },
    { code: 'AT', label: 'common.country.at', phone: '43' },
    { code: 'AU', label: 'common.country.au', phone: '61' },
    { code: 'AW', label: 'common.country.aw', phone: '297' },
    { code: 'AX', label: 'common.country.ax', phone: '358' },
    { code: 'AZ', label: 'common.country.az', phone: '994' },
    { code: 'BA', label: 'common.country.ba', phone: '387' },
    { code: 'BB', label: 'common.country.bb', phone: '1-246' },
    { code: 'BD', label: 'common.country.bd', phone: '880' },
    { code: 'BE', label: 'common.country.be', phone: '32' },
    { code: 'BF', label: 'common.country.bf', phone: '226' },
    { code: 'BG', label: 'common.country.bg', phone: '359' },
    { code: 'BH', label: 'common.country.bh', phone: '973' },
    { code: 'BI', label: 'common.country.bi', phone: '257' },
    { code: 'BJ', label: 'common.country.bj', phone: '229' },
    { code: 'BL', label: 'common.country.bl', phone: '590' },
    { code: 'BM', label: 'common.country.bm', phone: '1-441' },
    { code: 'BN', label: 'common.country.bn', phone: '673' },
    { code: 'BO', label: 'common.country.bo', phone: '591' },
    { code: 'BR', label: 'common.country.br', phone: '55' },
    { code: 'BS', label: 'common.country.bs', phone: '1-242' },
    { code: 'BT', label: 'common.country.bt', phone: '975' },
    { code: 'BV', label: 'common.country.bv', phone: '47' },
    { code: 'BW', label: 'common.country.bw', phone: '267' },
    { code: 'BY', label: 'common.country.by', phone: '375' },
    { code: 'BZ', label: 'common.country.bz', phone: '501' },
    { code: 'CA', label: 'common.country.ca', phone: '1' },
    { code: 'CC', label: 'common.country.cc', phone: '61' },
    { code: 'CD', label: 'common.country.cd', phone: '243' },
    { code: 'CF', label: 'common.country.cf', phone: '236' },
    { code: 'CG', label: 'common.country.cg', phone: '242' },
    { code: 'CH', label: 'common.country.ch', phone: '41' },
    { code: 'CI', label: 'common.country.ci', phone: '225' },
    { code: 'CK', label: 'common.country.ck', phone: '682' },
    { code: 'CL', label: 'common.country.cl', phone: '56' },
    { code: 'CM', label: 'common.country.cm', phone: '237' },
    { code: 'CN', label: 'common.country.cn', phone: '86' },
    { code: 'CO', label: 'common.country.co', phone: '57' },
    { code: 'CR', label: 'common.country.cr', phone: '506' },
    { code: 'CU', label: 'common.country.cu', phone: '53' },
    { code: 'CV', label: 'common.country.cv', phone: '238' },
    { code: 'CW', label: 'common.country.cw', phone: '599' },
    { code: 'CX', label: 'common.country.cx', phone: '61' },
    { code: 'CY', label: 'common.country.cy', phone: '357' },
    { code: 'CZ', label: 'common.country.cz', phone: '420' },
    { code: 'DE', label: 'common.country.de', phone: '49' },
    { code: 'DJ', label: 'common.country.dj', phone: '253' },
    { code: 'DK', label: 'common.country.dk', phone: '45' },
    { code: 'DM', label: 'common.country.dm', phone: '1-767' },
    { code: 'DO', label: 'common.country.do', phone: '1-809' },
    { code: 'DZ', label: 'common.country.dz', phone: '213' },
    { code: 'EC', label: 'common.country.ec', phone: '593' },
    { code: 'EE', label: 'common.country.ee', phone: '372' },
    { code: 'EG', label: 'common.country.eg', phone: '20' },
    { code: 'EH', label: 'common.country.eh', phone: '212' },
    { code: 'ER', label: 'common.country.er', phone: '291' },
    { code: 'ES', label: 'common.country.es', phone: '34' },
    { code: 'ET', label: 'common.country.et', phone: '251' },
    { code: 'FI', label: 'common.country.fi', phone: '358' },
    { code: 'FJ', label: 'common.country.fj', phone: '679' },
    { code: 'FK', label: 'common.country.fk', phone: '500' },
    { code: 'FM', label: 'common.country.fm', phone: '691' },
    { code: 'FO', label: 'common.country.fo', phone: '298' },
    { code: 'FR', label: 'common.country.fr', phone: '33' },
    { code: 'GA', label: 'common.country.ga', phone: '241' },
    { code: 'GB', label: 'common.country.gb', phone: '44' },
    { code: 'GD', label: 'common.country.gd', phone: '1-473' },
    { code: 'GE', label: 'common.country.ge', phone: '995' },
    { code: 'GF', label: 'common.country.gf', phone: '594' },
    { code: 'GG', label: 'common.country.gg', phone: '44' },
    { code: 'GH', label: 'common.country.gh', phone: '233' },
    { code: 'GI', label: 'common.country.gi', phone: '350' },
    { code: 'GL', label: 'common.country.gl', phone: '299' },
    { code: 'GM', label: 'common.country.gm', phone: '220' },
    { code: 'GN', label: 'common.country.gn', phone: '224' },
    { code: 'GP', label: 'common.country.gp', phone: '590' },
    { code: 'GQ', label: 'common.country.gq', phone: '240' },
    { code: 'GR', label: 'common.country.gr', phone: '30' },
    { code: 'GS', label: 'common.country.gs', phone: '500' },
    { code: 'GT', label: 'common.country.gt', phone: '502' },
    { code: 'GU', label: 'common.country.gu', phone: '1-671' },
    { code: 'GW', label: 'common.country.gw', phone: '245' },
    { code: 'GY', label: 'common.country.gy', phone: '592' },
    { code: 'HK', label: 'common.country.hk', phone: '852' },
    { code: 'HM', label: 'common.country.hm', phone: '672' },
    { code: 'HN', label: 'common.country.hn', phone: '504' },
    { code: 'HR', label: 'common.country.hr', phone: '385' },
    { code: 'HT', label: 'common.country.ht', phone: '509' },
    { code: 'HU', label: 'common.country.hu', phone: '36' },
    { code: 'ID', label: 'common.country.id', phone: '62' },
    { code: 'IE', label: 'common.country.ie', phone: '353' },
    { code: 'IL', label: 'common.country.il', phone: '972' },
    { code: 'IM', label: 'common.country.im', phone: '44' },
    { code: 'IN', label: 'common.country.in', phone: '91' },
    { code: 'IO', label: 'common.country.io', phone: '246' },
    { code: 'IQ', label: 'common.country.iq', phone: '964' },
    { code: 'IR', label: 'common.country.ir', phone: '98' },
    { code: 'IS', label: 'common.country.is', phone: '354' },
    { code: 'IT', label: 'common.country.it', phone: '39' },
    { code: 'JE', label: 'common.country.je', phone: '44' },
    { code: 'JM', label: 'common.country.jm', phone: '1-876' },
    { code: 'JO', label: 'common.country.jo', phone: '962' },
    { code: 'JP', label: 'common.country.jp', phone: '81' },
    { code: 'KE', label: 'common.country.ke', phone: '254' },
    { code: 'KG', label: 'common.country.kg', phone: '996' },
    { code: 'KH', label: 'common.country.kh', phone: '855' },
    { code: 'KI', label: 'common.country.ki', phone: '686' },
    { code: 'KM', label: 'common.country.km', phone: '269' },
    { code: 'KN', label: 'common.country.kn', phone: '1-869' },
    { code: 'KP', label: 'common.country.kp', phone: '850' },
    { code: 'KR', label: 'common.country.kr', phone: '82' },
    { code: 'KW', label: 'common.country.kw', phone: '965' },
    { code: 'KY', label: 'common.country.ky', phone: '1-345' },
    { code: 'KZ', label: 'common.country.kz', phone: '7' },
    { code: 'LA', label: 'common.country.la', phone: '856' },
    { code: 'LB', label: 'common.country.lb', phone: '961' },
    { code: 'LC', label: 'common.country.lc', phone: '1-758' },
    { code: 'LI', label: 'common.country.li', phone: '423' },
    { code: 'LK', label: 'common.country.lk', phone: '94' },
    { code: 'LR', label: 'common.country.lr', phone: '231' },
    { code: 'LS', label: 'common.country.ls', phone: '266' },
    { code: 'LT', label: 'common.country.lt', phone: '370' },
    { code: 'LU', label: 'common.country.lu', phone: '352' },
    { code: 'LV', label: 'common.country.lv', phone: '371' },
    { code: 'LY', label: 'common.country.ly', phone: '218' },
    { code: 'MA', label: 'common.country.ma', phone: '212' },
    { code: 'MC', label: 'common.country.mc', phone: '377' },
    { code: 'MD', label: 'common.country.md', phone: '373' },
    { code: 'ME', label: 'common.country.me', phone: '382' },
    { code: 'MF', label: 'common.country.mf', phone: '590' },
    { code: 'MG', label: 'common.country.mg', phone: '261' },
    { code: 'MH', label: 'common.country.mh', phone: '692' },
    { code: 'MK', label: 'common.country.mk', phone: '389' },
    { code: 'ML', label: 'common.country.ml', phone: '223' },
    { code: 'MM', label: 'common.country.mm', phone: '95' },
    { code: 'MN', label: 'common.country.mn', phone: '976' },
    { code: 'MO', label: 'common.country.mo', phone: '853' },
    { code: 'MP', label: 'common.country.mp', phone: '1-670' },
    { code: 'MQ', label: 'common.country.mq', phone: '596' },
    { code: 'MR', label: 'common.country.mr', phone: '222' },
    { code: 'MS', label: 'common.country.ms', phone: '1-664' },
    { code: 'MT', label: 'common.country.mt', phone: '356' },
    { code: 'MU', label: 'common.country.mu', phone: '230' },
    { code: 'MV', label: 'common.country.mv', phone: '960' },
    { code: 'MW', label: 'common.country.mw', phone: '265' },
    { code: 'MX', label: 'common.country.mx', phone: '52' },
    { code: 'MY', label: 'common.country.my', phone: '60' },
    { code: 'MZ', label: 'common.country.mz', phone: '258' },
    { code: 'NA', label: 'common.country.na', phone: '264' },
    { code: 'NC', label: 'common.country.nc', phone: '687' },
    { code: 'NE', label: 'common.country.ne', phone: '227' },
    { code: 'NF', label: 'common.country.nf', phone: '672' },
    { code: 'NG', label: 'common.country.ng', phone: '234' },
    { code: 'NI', label: 'common.country.ni', phone: '505' },
    { code: 'NL', label: 'common.country.nl', phone: '31' },
    { code: 'NO', label: 'common.country.no', phone: '47' },
    { code: 'NP', label: 'common.country.np', phone: '977' },
    { code: 'NR', label: 'common.country.nr', phone: '674' },
    { code: 'NU', label: 'common.country.nu', phone: '683' },
    { code: 'NZ', label: 'common.country.nz', phone: '64' },
    { code: 'OM', label: 'common.country.om', phone: '968' },
    { code: 'PA', label: 'common.country.pa', phone: '507' },
    { code: 'PE', label: 'common.country.pe', phone: '51' },
    { code: 'PF', label: 'common.country.pf', phone: '689' },
    { code: 'PG', label: 'common.country.pg', phone: '675' },
    { code: 'PH', label: 'common.country.ph', phone: '63' },
    { code: 'PK', label: 'common.country.pk', phone: '92' },
    { code: 'PL', label: 'common.country.pl', phone: '48' },
    { code: 'PM', label: 'common.country.pm', phone: '508' },
    { code: 'PN', label: 'common.country.pn', phone: '64' },
    { code: 'PR', label: 'common.country.pr', phone: '1' },
    { code: 'PT', label: 'common.country.pt', phone: '351' },
    { code: 'PW', label: 'common.country.pw', phone: '680' },
    { code: 'PY', label: 'common.country.py', phone: '595' },
    { code: 'QA', label: 'common.country.qa', phone: '974' },
    { code: 'RE', label: 'common.country.re', phone: '262' },
    { code: 'RO', label: 'common.country.ro', phone: '40' },
    { code: 'RS', label: 'common.country.rs', phone: '381' },
    { code: 'RU', label: 'common.country.ru', phone: '7' },
    { code: 'RW', label: 'common.country.rw', phone: '250' },
    { code: 'SA', label: 'common.country.sa', phone: '966' },
    { code: 'SB', label: 'common.country.sb', phone: '677' },
    { code: 'SC', label: 'common.country.sc', phone: '248' },
    { code: 'SD', label: 'common.country.sd', phone: '249' },
    { code: 'SE', label: 'common.country.se', phone: '46' },
    { code: 'SG', label: 'common.country.sg', phone: '65' },
    { code: 'SH', label: 'common.country.sh', phone: '290' },
    { code: 'SI', label: 'common.country.si', phone: '386' },
    { code: 'SJ', label: 'common.country.sj', phone: '47' },
    { code: 'SK', label: 'common.country.sk', phone: '421' },
    { code: 'SL', label: 'common.country.sl', phone: '232' },
    { code: 'SM', label: 'common.country.sm', phone: '378' },
    { code: 'SN', label: 'common.country.sn', phone: '221' },
    { code: 'SO', label: 'common.country.so', phone: '252' },
    { code: 'SR', label: 'common.country.sr', phone: '597' },
    { code: 'SS', label: 'common.country.ss', phone: '211' },
    { code: 'ST', label: 'common.country.st', phone: '239' },
    { code: 'SV', label: 'common.country.sv', phone: '503' },
    { code: 'SX', label: 'common.country.sx', phone: '1-721' },
    { code: 'SY', label: 'common.country.sy', phone: '963' },
    { code: 'SZ', label: 'common.country.sz', phone: '268' },
    { code: 'TC', label: 'common.country.tc', phone: '1-649' },
    { code: 'TD', label: 'common.country.td', phone: '235' },
    { code: 'TF', label: 'common.country.tf', phone: '262' },
    { code: 'TG', label: 'common.country.tg', phone: '228' },
    { code: 'TH', label: 'common.country.th', phone: '66' },
    { code: 'TJ', label: 'common.country.tj', phone: '992' },
    { code: 'TK', label: 'common.country.tk', phone: '690' },
    { code: 'TL', label: 'common.country.tl', phone: '670' },
    { code: 'TM', label: 'common.country.tm', phone: '993' },
    { code: 'TN', label: 'common.country.tn', phone: '216' },
    { code: 'TO', label: 'common.country.to', phone: '676' },
    { code: 'TR', label: 'common.country.tr', phone: '90' },
    { code: 'TT', label: 'common.country.tt', phone: '1-868' },
    { code: 'TV', label: 'common.country.tv', phone: '688' },
    { code: 'TZ', label: 'common.country.tz', phone: '255' },
    { code: 'UA', label: 'common.country.ua', phone: '380' },
    { code: 'UG', label: 'common.country.ug', phone: '256' },
    { code: 'US', label: 'common.country.us', phone: '1' },
    { code: 'UY', label: 'common.country.uy', phone: '598' },
    { code: 'UZ', label: 'common.country.uz', phone: '998' },
    { code: 'VA', label: 'common.country.va', phone: '379' },
    { code: 'VC', label: 'common.country.vc', phone: '1-784' },
    { code: 'VE', label: 'common.country.ve', phone: '58' },
    { code: 'VG', label: 'common.country.vg', phone: '1-284' },
    { code: 'VI', label: 'common.country.vi', phone: '1-340' },
    { code: 'VN', label: 'common.country.vn', phone: '84' },
    { code: 'VU', label: 'common.country.vu', phone: '678' },
    { code: 'WF', label: 'common.country.wf', phone: '681' },
    { code: 'WS', label: 'common.country.ws', phone: '685' },
    { code: 'XK', label: 'common.country.xk', phone: '383' },
    { code: 'YE', label: 'common.country.ye', phone: '967' },
    { code: 'YT', label: 'common.country.yt', phone: '262' },
    { code: 'ZA', label: 'common.country.za', phone: '27' },
    { code: 'ZM', label: 'common.country.zm', phone: '260' },
    { code: 'ZW', label: 'common.country.zw', phone: '263' }
];
