import AppListItem from "../listItems/AppListItem";
import {usePopupState} from "material-ui-popup-state/hooks";
import React, {useContext, useId} from "react";
import {EmployeeJoinedUserResponse, FileResponse, NoteItemResponse} from "../../../generated/graphql/graphql";
import AppIconButton from "../buttons/AppIconButton";
import {UserFullName, UserPhotoUrl} from "../../../service/UserService";
import {DateTime} from "luxon";
import DescriptionWidget from "../jobs/visits/visitDetail/DescriptionWidget";
import PermissionValid, {hasSomePermissions} from "../permissions/PermissionValid";
import {kActionDelete, kActionUpdate} from "../../../core/constants";
import {AppDataContext} from "../../../AppData";
import {bindMenu} from "material-ui-popup-state";
import MenuItem from "@mui/material/MenuItem";
import PencilIcon from "../../../icons/PencilIcon";
import {tt} from "../../../core/Localization";
import {Box, Divider} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import Menu from "@mui/material/Menu";
import DeleteIcon from "../../../icons/DeleteIcon";
import MoreFilledIcon from "../../../icons/MoreFilledIcon";

export interface INoteItemListItemProps {
    permission: string;
    employee: EmployeeJoinedUserResponse | NullOrUndefined;
    data: NoteItemResponse;
    files: FileResponse[] | NullOrUndefined;
    isFirst?: boolean;
    onEditNote: (noteItem: NoteItemResponse) => void;
    onDeleteNote: (noteItem: NoteItemResponse) => void;
}

export default function NoteItemListItem(props: INoteItemListItemProps) {
    const {
        permission,
        employee,
        data,
        files,
        isFirst,
        onEditNote,
        onDeleteNote,
    } = props;

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const appDataContext = useContext(AppDataContext);
    const {employeePermissionsMap, storage} = appDataContext;

    const employeeName = employee ? UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname) : '';

    return (
        <>
            {isFirst ? null : <Divider/>}

            <AppListItem
                variant={"smaller-title"}
                profileImage={
                    UserPhotoUrl(employee?.user, files, storage.publicUrlsForFiles)
                }
                description={DateTime.fromMillis(data.updatedAt).toFormat('H:mm · d.M.yyyy')}
                title={employeeName}
                belowListItemWidget={(
                    <DescriptionWidget
                        variant={'2lines'}
                        content={data.note}
                    />
                )}
                actionWidget={
                    hasSomePermissions([
                        {permission: permission, requiredPermissions: [kActionUpdate]},
                        {permission: permission, requiredPermissions: [kActionDelete]},
                    ], employeePermissionsMap) ? (
                        <AppIconButton
                            variant={"greyBg"}
                            onClick={(e) => {
                                settingsPopupState.open(e);
                            }}
                        >
                            <MoreFilledIcon/>
                        </AppIconButton>
                    ) : null
                }
            />

            <Menu {...bindMenu(settingsPopupState)}>
                <PermissionValid
                    permission={permission}
                    requiredPermissions={[kActionUpdate]}
                >
                    <MenuItem key={useId()} onClick={() => {
                        settingsPopupState.close();
                        onEditNote(data);
                    }}>
                        <PencilIcon/>
                        {tt('common.edit')}
                    </MenuItem>
                </PermissionValid>

                <PermissionValid
                    permission={permission}
                    requiredPermissions={[kActionDelete]}
                >
                    <MenuItem key={useId()} onClick={() => {
                        settingsPopupState.close();
                        onDeleteNote(data);
                    }}>
                        <Box sx={{color: kAppColors.red.confirmButton}}><DeleteIcon/></Box>
                        {tt('common.delete')}
                    </MenuItem>
                </PermissionValid>
            </Menu>
        </>
    );
}
