import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {AppDataContext} from "../../../AppData";
import {tt} from "../../../core/Localization";
import AppModal from "../modals/AppModal";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import {Box, InputAdornment, Theme, Typography} from "@mui/material";
import SearchIcon from "../../../icons/SearchIcon";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import {
    AddUserAsWorkerAction,
    AddUserAsWorkerInput,
    AddUserAsWorkerProfileResponse,
    EmployeeJoinedUserResponse,
    GetAddUserAsWorkerProfileInput
} from "../../../generated/graphql/graphql";
import {processMutationError, processQueryError} from "../../../service/ErrorService";
import {Loader} from "@mantine/core";
import {UserFullName} from "../../../service/UserService";
import AppButton from "../buttons/AppButton";
import {makeStyles} from "tss-react/mui";
import {useNavigate} from "react-router-dom";
import {routeWithCurrentAsParam} from "../../../utils/Utils";
import {workerDetailRoute} from "../../screens/workers/WorkerDetailScreen";
import WorkersAndProfileForm, {IWorkersFormUpdate} from "./WorkersAndProfileForm";
import TextDivider from "../decorations/TextDivider";
import {SuccessToast} from "../../../service/ToastService";

export const useStyles = makeStyles()((theme: Theme) => ({
    button: {
        "@media (max-width: 767px)": {
            flexGrow: 1,
        }
    }
}));

export interface IAddUserAsWorkerModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    modalAboveModals?: boolean;
}

/**
 * Modal to create new or add existing User as Worker.
 */
export default function AddUserAsWorkerModal(props: IAddUserAsWorkerModalProps) {
    const {open, setOpen, modalAboveModals} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet, restApiPost} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const navigate = useNavigate();
    const {classes} = useStyles();

    const [data, setData] = useState<AddUserAsWorkerProfileResponse>();
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [triggerSubmit, setTriggerSubmit] = useState<number>(0);

    const [inputs, setInputs] = useState<IInputsData>({
        email: {
            type: InputType.Text,
            label: '',
            placeholder: tt('addUserAsWorker.modal.email.placeholder'),
            hideLabel: true,
            inputVariant: 'standard',
            extraStyle: 'thin',
            value: '',
            required: true,
            isClearable: true,
            innerPrefixJSX: (
                <InputAdornment position={"start"}>
                    {dataLoading ? <Loader/> : <SearchIcon/>}
                </InputAdornment>
            ),
        },
    });

    const [createUserForm, setCreateUserForm] = useState<IInputsData>({
        password: {
            type: InputType.Password,
            label: `${tt('common.password')}*`,
            value: '',
            required: true,
            requireMinLength: 8,
            validateOnChange: true,
            grid: {
                sm: 6,
                xs: 12
            },
        },
    });

    useEffect(() => {
        if (open) {
            setData(undefined);
            setInputs((prev) => {
                return {
                    ...prev,
                    email: {
                        ...prev.email,
                        value: '',
                    },
                };
            });
            setCreateUserForm((prev) => {
                return {
                    ...prev,
                    password: {
                        ...prev.password,
                        value: '',
                    },
                };
            });
        }
    }, [open]);

    useEffect(() => {
        setInputs((prev) => {
            return {
                ...prev,
                email: {
                    ...prev.email,
                    innerPrefixJSX: (
                        <InputAdornment position={"start"}>
                            {dataLoading ? <Loader/> : <SearchIcon/>}
                        </InputAdornment>
                    ),
                },
            };
        });
    }, [dataLoading]);

    useEffect(() => {
        const shouldShowPassWordError = (createUserForm.password.value.length > 0 && createUserForm.password.value.length < 8) && !createUserForm.password.error;
        const shouldHidePassError = (createUserForm.password.value.length == 0 || createUserForm.password.value.length > 7) && !!createUserForm.password.error;

        if (shouldShowPassWordError) {
            setCreateUserForm(
                prev => {
                    return {
                        ...prev,
                        password: {
                            ...prev.password,
                            error: true,
                            errorMessage: `${tt('common.isNotValidMinLength').replace('$length', `${createUserForm.password.requireMinLength}`)}.`
                        }
                    };
                }
            );
        }

        if (shouldHidePassError) {
            setCreateUserForm(
                prev => {
                    return {
                        ...prev,
                        password: {
                            ...prev.password,
                            error: false,
                            errorMessage: undefined,
                        }
                    };
                }
            )
        }
    }, [createUserForm]);

    const email = inputs.email.value.trim();

    useEffect(() => {
        if (email && email.length > 0) {
            restApiGet({
                uri: '/company/employee/add-user-as-worker-profile',
                params: {
                    companyId: companyId,
                    emailExact: email,
                } as GetAddUserAsWorkerProfileInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading: setDataLoading,
                onData: setData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        } else {
            setData(undefined);
        }
    }, [email]);

    /**
     * Go to Employee detail screen.
     */
    const toDetail = () => {
        if (data && data.employee) {
            setOpen(false);

            navigate(routeWithCurrentAsParam(workerDetailRoute(data.employee.id)));
        }
    };

    /**
     * Send action to active Employee to BE.
     */
    const activate = () => {
        const input: AddUserAsWorkerInput = {
            companyId: companyId!,
            action: AddUserAsWorkerAction.ActivateEmployee,
            employeeId: data!.employee!.id,
        };

        restApiPost({
            uri: '/company/employee/add-user-as-worker',
            params: input,
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading: setSubmitLoading,
            onData: (data) => {
                const response = data ? data as EmployeeJoinedUserResponse : undefined;

                if (response) {
                    setOpen(false);

                    SuccessToast(tt('addUserAsWorker.modal.userIsAlreadyWorker.inactive.activate.success'));

                    navigate(routeWithCurrentAsParam(workerDetailRoute(response.id)));
                }
            },
            onError: (error) => processMutationError(error),
        });
    };

    /**
     * Send action to create Employee to BE.
     */
    const createEmployee = (update: IWorkersFormUpdate) => {
        const input: AddUserAsWorkerInput = {
            companyId: companyId!,
            action: AddUserAsWorkerAction.CreateEmployee,
            userId: data!.user!.id,
            name: update.inputs.firstName.value,
            surname: update.inputs.lastName.value,
            role: update.inputs.position.value,
            phoneNumber: update.inputs.phone.value,
            contactEmail: update.inputs.email.value,
            address: update.addressFragment || undefined,
            hourRate: update.inputs.hourlyWage.value ? parseFloat(update.inputs.hourlyWage.value) : undefined,
            contractType: update.inputs.contractType.value ? update.inputs.contractType.value : undefined,
            distanceRate: update.inputs.distanceRate.value ? parseFloat(update.inputs.distanceRate.value) : undefined,
        };

        restApiPost({
            uri: '/company/employee/add-user-as-worker',
            params: input,
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading: setSubmitLoading,
            onData: (data) => {
                const response = data ? data as EmployeeJoinedUserResponse : undefined;

                if (response) {
                    setOpen(false);

                    SuccessToast(tt('addUserAsWorker.modal.userExistsWorkerNot.add.success'));

                    navigate(routeWithCurrentAsParam(workerDetailRoute(response.id)));
                }
            },
            onError: (error) => processMutationError(error),
        });
    };

    /**
     * Send action to create User to BE.
     */
    const createUser = (update: IWorkersFormUpdate) => {
        if (ValidateForm(createUserForm, setCreateUserForm)) {
            const input: AddUserAsWorkerInput = {
                companyId: companyId!,
                action: AddUserAsWorkerAction.CreateUser,
                email: email,
                password: createUserForm.password.value,
                name: update.inputs.firstName.value,
                surname: update.inputs.lastName.value,
                role: update.inputs.position.value,
                phoneNumber: update.inputs.phone.value,
                contactEmail: update.inputs.email.value,
                address: update.addressFragment || undefined,
                hourRate: update.inputs.hourlyWage.value ? parseFloat(update.inputs.hourlyWage.value) : undefined,
                contractType: update.inputs.contractType.value ? update.inputs.contractType.value : undefined,
                distanceRate: update.inputs.distanceRate.value ? parseFloat(update.inputs.distanceRate.value) : undefined,
            };

            restApiPost({
                uri: '/company/employee/add-user-as-worker',
                params: input,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading: setSubmitLoading,
                onData: (data) => {
                    const response = data ? data as EmployeeJoinedUserResponse : undefined;

                    if (response) {
                        setOpen(false);

                        SuccessToast(tt('addUserAsWorker.modal.userDoesNotExist.create.success'));

                        navigate(routeWithCurrentAsParam(workerDetailRoute(response.id)));
                    }
                },
                onError: (error) => processMutationError(error),
            });
        }
    };

    const infoJSX = useMemo(() => {
        if (data) {
            if (data.user && data.employee && data.validOnFirebase) {
                return (
                    <Typography paragraph={true}>
                        {(data.employee.active ?
                                tt('addUserAsWorker.modal.userIsAlreadyWorker') :
                                tt('addUserAsWorker.modal.userIsAlreadyWorker.inactive')
                        ).replace('{fullName}', UserFullName(data.employee.name || data.user.name, data.employee.surname || data.user.surname))}
                    </Typography>
                );
            } else if (data.user && data.validOnFirebase) {
                return (
                    <Typography paragraph={true}>
                        {tt('addUserAsWorker.modal.userExistsWorkerNot')}
                    </Typography>
                );
            } else {
                return (
                    <Typography paragraph={true}>
                        {tt('addUserAsWorker.modal.userDoesNotExist')}
                    </Typography>
                );
            }
        }

        return null;
    }, [data]);

    const formsJSX = useMemo(() => {
        if (data) {
            if (data.user && !data.employee && data.validOnFirebase) {
                return (
                    <>
                        <WorkersAndProfileForm
                            firstName={data.user.name}
                            lastName={data.user.surname}
                            phone={data.user.phoneNumber}
                            email={data.user.email}
                            onSubmit={createEmployee}
                            triggerSubmit={triggerSubmit}
                            submitHidden={true}
                        />
                    </>
                );
            } else if (!data.user || !data.validOnFirebase) {
                return (
                    <>
                        <FormBuilder inputs={createUserForm} setInputs={setCreateUserForm}/>

                        <Box pb={2}/>

                        <TextDivider text={tt('addUserAsWorker.modal.workerDetails')}/>

                        <Box pb={2}/>

                        <WorkersAndProfileForm
                            onSubmit={createUser}
                            triggerSubmit={triggerSubmit}
                            submitHidden={true}
                        />
                    </>
                );
            }
        }

        return null;
    }, [data, createUserForm, triggerSubmit]);

    const actionsJSX = useMemo(() => {
        if (data) {
            if (data.user && data.employee && !data.employee.active && data.validOnFirebase) {
                return (
                    <Box>
                        <AppButton
                            className={classes.button}
                            sx={{mr: 1}}
                            variant={"contained"}
                            fullWidth={false}
                            onClick={activate}
                            isLoading={submitLoading}>
                            {tt('addUserAsWorker.modal.userIsAlreadyWorker.inactive.activate')}
                        </AppButton>

                        <AppButton
                            className={classes.button}
                            variant="outlined"
                            fullWidth={false}
                            onClick={toDetail}
                            isLoading={false}>
                            {tt('addUserAsWorker.modal.workerDetail')}
                        </AppButton>
                    </Box>
                );
            } else if (data.user && !data.employee && data.validOnFirebase) {
                return (
                    <AppButton
                        className={classes.button}
                        variant={"contained"}
                        fullWidth={false}
                        onClick={() => setTriggerSubmit(prev => prev + 1)}
                        isLoading={submitLoading}>
                        {tt('addUserAsWorker.modal.userExistsWorkerNot.add')}
                    </AppButton>
                );
            } else if (!data.user || !data.validOnFirebase) {
                return (
                    <AppButton
                        className={classes.button}
                        variant={"contained"}
                        fullWidth={false}
                        onClick={() => setTriggerSubmit(prev => prev + 1)}
                        isLoading={submitLoading}>
                        {tt('addUserAsWorker.modal.userDoesNotExist.create')}
                    </AppButton>
                );
            }
        }

        return null;
    }, [data]);

    return (
        <AppModal setOpen={setOpen}
                  title={tt('addUserAsWorker.modal.title')}
                  open={open}
                  fullScreenOnMobile={true}
                  blurBackdrop={true}
                  modalAboveModals={modalAboveModals}
        >
            <FormBuilder inputs={inputs} setInputs={setInputs}/>

            {infoJSX}

            {formsJSX}

            {actionsJSX}
        </AppModal>
    );
}
