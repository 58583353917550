import {iconUseStyles} from "../styles/IconStyles";

export interface IToolIconIconProps {
    className?: string;
}

export default function JalousieIcon(props: IToolIconIconProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 23.976562 5.9785156 A 1.50015 1.50015 0 0 0 23.757812 6 L 7.5 6 A 1.50015 1.50015 0 1 0 7.5 9 L 22.5 9 L 22.5 11 L 7.5 11 A 1.50015 1.50015 0 1 0 7.5 14 L 22.5 14 L 22.5 16 L 7.5 16 A 1.50015 1.50015 0 1 0 7.5 19 L 22.5 19 L 22.5 21 L 7.5 21 A 1.50015 1.50015 0 1 0 7.5 24 L 40.5 24 A 1.50015 1.50015 0 1 0 40.5 21 L 25.5 21 L 25.5 19 L 40.5 19 A 1.50015 1.50015 0 1 0 40.5 16 L 25.5 16 L 25.5 14 L 40.5 14 A 1.50015 1.50015 0 1 0 40.5 11 L 25.5 11 L 25.5 9 L 40.5 9 A 1.50015 1.50015 0 1 0 40.5 6 L 24.246094 6 A 1.50015 1.50015 0 0 0 23.976562 5.9785156 z M 9 26 L 9 36.5 C 9 39.533 11.467 42 14.5 42 L 33.5 42 C 36.533 42 39 39.533 39 36.5 L 39 35.724609 C 38.529 35.892609 38.029 36 37.5 36 C 36.971 36 36.471 35.892609 36 35.724609 L 36 36.5 C 36 37.878 34.878 39 33.5 39 L 14.5 39 C 13.122 39 12 37.878 12 36.5 L 12 26 L 9 26 z M 36 26 L 36 29.503906 A 2.5 2.5 0 0 0 37.5 34 A 2.5 2.5 0 0 0 39 29.501953 L 39 26 L 36 26 z"/>
            </svg>
        </span>
    );
}
