import AppListItem from "../listItems/AppListItem";
import {getPermissionsSectionIcon, getPermissionTitle} from "../../../service/PermissionsService";
import Menu from "@mui/material/Menu";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MenuItem from "@mui/material/MenuItem";
import PencilIcon from "../../../icons/PencilIcon";
import {tt} from "../../../core/Localization";
import React, {useContext, useId} from "react";
import AppIconButton from "../buttons/AppIconButton";
import MoreFilledIcon from "../../../icons/MoreFilledIcon";
import {usePopupState} from "material-ui-popup-state/hooks";
import {SetEditPermissionsModal} from "../modals/AppModals";
import {AppDataContext} from "../../../AppData";
import {EmployeeRole} from "../../../generated/graphql/graphql";
import {IUpdatePermissionsParams} from "./EditPermissionsModal";
import PermissionsListItemChipsSection from "./PermissionsListItemChipsSection";
import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material";


export const useStyles = makeStyles()((theme: Theme) => ({
    textsContainerMaxWidth: {
        '.textsContainer': {
            maxWidth: 'calc(100% - 116px)',
        }
    },
}));

export interface ISinglePermissionListItemProps {
    role: EmployeeRole;
    permission: string;
    employeePermissionsMap: Record<string, string[]>;
    applyForAllOfRole?: EmployeeRole;
    updatePermissions?: (params: IUpdatePermissionsParams) => void;
}

export default function SinglePermissionListItem(props: ISinglePermissionListItemProps) {
    const {
        role,
        permission,
        employeePermissionsMap,
        applyForAllOfRole,
        updatePermissions,
    } = props;

    const {classes} = useStyles();
    const appDataContext = useContext(AppDataContext);

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    return (
        <>
            <AppListItem
                className={classes.textsContainerMaxWidth}
                variant={"smaller-title"}
                customAvatarInCircle={getPermissionsSectionIcon(permission)}
                title={getPermissionTitle(permission)}
                customBottomWidget={(
                    <PermissionsListItemChipsSection
                        employeePermissionsMap={employeePermissionsMap}
                        permission={permission}/>
                )}
                actionWidget={updatePermissions ? (
                    <>
                        <AppIconButton
                            variant={"greyBg"}
                            placement={"right"}
                            {...bindTrigger(settingsPopupState)}
                        >
                            <MoreFilledIcon/>
                        </AppIconButton>

                        <Menu {...bindMenu(settingsPopupState)}>
                            <MenuItem key={'productEditKey'} onClick={() => {
                                settingsPopupState.close();
                                SetEditPermissionsModal(
                                    appDataContext,
                                    {
                                        open: true,
                                        role,
                                        permission,
                                        employeePermissionsMap,
                                        applyForAllOfRole,
                                        updatePermissions,
                                    },
                                );
                            }}>
                                <PencilIcon/>
                                {tt('common.edit')}
                            </MenuItem>
                        </Menu>
                    </>
                ) : <></>}
            />
        </>
    );
}
