import {makeStyles} from "tss-react/mui";
import {Grid, GridSize, Theme} from "@mui/material";
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {LocalizationProvider} from "@mui/x-date-pickers";
import React, {Dispatch, FocusEventHandler, ReactNode, SetStateAction, useContext} from "react";
import TextInput from "./TextInput";
import {
    AutoCompleteOption,
    ButtonSelectProps,
    ButtonTextFieldProps,
    CheckBoxProps,
    DatePickerProps,
    DatesPickerProps,
    FormSwitchProps,
    FormToggleButtonsProps,
    IChipsAutocompleteProps,
    IChipSwitchProps,
    IconCheckBoxProps,
    IFormTimePickerProps,
    IGooglePlacesAutocompleteField,
    IMapyCZAutocompleteField,
    InputProps,
    ITextAutocompleteFieldProps,
    Option,
    RadioButtonGroupProps,
    SelectProps,
    TextAreaProps,
} from "./InputProps";
import PasswordInput from "./PasswordInput";
import TextArea from "./TextArea";
import Select from "./Select";
import FormDatePicker from "./DatePicker";
import FormCheckBox from "./CheckBox";
import {validate as isEmail} from "email-validator";
import {isUri} from "valid-url";
import {tt} from "../../../core/Localization";
import IconCheckBox from "./IconCheckBox";
import ButtonTextField from "./ButtonTextField";
import ChipsAutocomplete from "./ChipsAutocomplete";
import ButtonSelect from "./ButtonSelect";
import GooglePlacesAutocompleteField from "./GooglePlacesAutocompleteField";
import FormSwitch from "./FormSwitch";
import TextAutocompleteField from "./TextAutocompleteField";
import EditorInput from "./EditorInput";
import FormTimePicker from "./FormTimePicker";
import FormToggleButtons from "./FormToggleButtons";
import ChipSwitch from "./ChipSwitch";
import MapyCZAutocompleteField from "./MapyCZAutocompleteField";
import {Cx} from "tss-react";
import {Draggable, DraggableProps, Droppable, DroppableProps} from 'react-beautiful-dnd';
import {
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
    FilterOptionsState
} from "@mui/base/useAutocomplete/useAutocomplete";
import RadioButtonGroup from "./RadioButtonGroup";
import {AppDataContext} from "../../../AppData";
import DatesPicker from "./DatesPicker";
import {DateTime} from "luxon";

export const useStyles = makeStyles()((theme: Theme) => ({
    hidden: {
        display: 'none',
    },
    gridItem: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
}));

export enum InputType {
    None,
    Text,
    Password,
    ButtonTextField,
    ButtonSelect,
    TextArea,
    File,
    Select,
    CustomRenderer,
    DatePicker,
    CheckBox,
    IconCheckbox,
    ChipAutocomplete,
    GooglePlacesAutocomplete,
    MapyCZAutocomplete,
    Switch,
    TextAutocomplete,
    Editor,
    FormTimePicker,
    FormToggleButtons,
    ChipSwitch,
    RadioGroup,
    DatesPicker,
}

export interface IInputsItem {
    userData?: any;
    type: InputType;
    className?: string;
    label: string;
    toggleButtonText?: string;
    value: any;
    numbersOnly?: boolean;
    decimalPoints?: number;
    readOnly?: boolean;
    hidden?: boolean;
    minRows?: string | number;
    error?: boolean;
    errorMessage?: string;
    helperText?: ReactNode;
    required?: boolean;
    requiredMessage?: string;
    requireUri?: boolean;
    requireUriMessage?: string;
    requireEmail?: boolean;
    requireEmailMessage?: string;
    requireEqualTo?: string;
    requireEqualToMessage?: string;
    requireMinLength?: number;
    maxChars?: number;
    requireMinLengthMessage?: string;
    options?: Option[];
    autocompleteOptions?: AutoCompleteOption[];
    onChange?: (index: string, value: string) => void;
    render?: (inputs: IInputsData) => React.ReactNode;
    suffixJSX?: () => React.ReactNode;
    prefixJSX?: React.ReactNode;
    innerSuffixJSX?: React.ReactNode;
    innerPrefixJSX?: React.ReactNode;
    icon?: React.ReactNode;
    dateFormat?: string;
    disablePast?: boolean;
    clearText?: string;
    grid?: IInputGrid;
    placeholder?: string;
    isClearable?: boolean;
    toggleVisible?: boolean;
    toggleVisibleValue?: boolean;
    toggleVisibleText?: string;
    validateOnChange?: boolean;
    inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;
    paperClass?: string;
    testId?: string;
    onSwitch?: (value: boolean) => void;
    pickerVisible?: boolean;
    topLabel?: string;
    showCharCounter?: boolean;
    changeThisToCloseInput?: number;
    overlayEndSuffixJSX?: (inputProps: any) => React.ReactNode;
    placeId?: string;
    onToggleCheckbox?: (value: boolean) => void;
    draggable?: IInputsItemDraggableProps;
    switchVariant?: 'Android12Switch' | 'Android12SwitchPrimaryColor' | undefined;
    onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onEditorFocus?: Function;
    onEditorBlur?: Function;
    size?: 'small' | 'medium';
    hideLabel?: boolean;
    onAutocompleteValueChanged?: (event: React.SyntheticEvent, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => void;
    onNewValueAdded?: (value: string | null) => void;
    disabled?: boolean;
    freeSolo?: boolean;
    uniqueValues?: boolean;
    avatar?: React.ReactNode;
    toggleButtonsVariant?: 'default' | 'calendar' | '4weeks' | 'chips' | 'oneLineButtons';
    singleValue?: boolean;
    inputVariant?: 'filled' | 'standard';
    extraStyle?: 'thin' | 'default';
    startDate?: DateTime;
    endDate?: DateTime;
    datesPickerVariant?: 'default' | 'excludeDays' | undefined;
    skipWeekends?: boolean;
    labelBefore?: string;
    fullWidth?: boolean;
    removeZeroOnFocus?: boolean;
    hideAddNewOption?: boolean;
    clearOnBlur?: boolean;
    updateOptionsForQuery?: (query: string) => void;
    filterOptions?: (options: AutoCompleteOption[], state: FilterOptionsState<AutoCompleteOption>) => AutoCompleteOption[];
}

export interface IInputsItemDraggableProps extends Partial<DraggableProps> {
    droppableId: string;
    addToDraggableId?: string;
}

export interface IInputsData {
    [index: string]: IInputsItem;
}

export interface IInputGrid {
    lg?: boolean | GridSize;
    md?: boolean | GridSize;
    sm?: boolean | GridSize;
    xl?: boolean | GridSize;
    xs?: boolean | GridSize;
}

export interface IFormBuilderProps {
    className?: string;
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    droppables?: Record<string, Partial<DroppableProps>>;
}

/**
 * Component for rendering Form based on simple configuration.
 */
export default function FormBuilder(props: IFormBuilderProps) {
    const {className, inputs, setInputs, droppables} = props;

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const {cx} = useStyles();

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={language}>
            <Grid className={cx(className)} container={true} spacing={2} sx={{mt: 0}}>
                <RenderInputs inputs={inputs} setInputs={setInputs} droppables={droppables}/>
            </Grid>
        </LocalizationProvider>
    );
}

interface IRenderInputsProps {
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    droppables?: Record<string, Partial<DroppableProps>>;
}

/**
 * Render inputs based on data.
 */
function RenderInputs(props: IRenderInputsProps) {
    const {inputs, setInputs, droppables} = props;

    const {classes, cx} = useStyles();

    /**
     * Update fields prop value.
     */
    const UpdateProp = (index: string, key: string, value: any) => {
        setInputs(prev => {
            const newInputs = {...prev};

            const theField = newInputs[index] as any;
            theField[key] = value;

            return newInputs;
        });
    };

    /**
     * Update fields value inside inputs data.
     */
    const UpdateValue = (index: string, value: any) => {
        setInputs(prev => {
            const newInputs = {...prev};

            newInputs[index].value = value;

            return newInputs;
        });
    };

    const children: React.ReactNode[] = [];
    const inputsForDroppables: Record<string, string[]> = {};
    const inputsToAddToDraggableId: Record<string, string[]> = {};

    for (const indexOf of Object.keys(inputs)) {
        const input = inputs[indexOf];

        if (input.draggable) {
            if (input.draggable.addToDraggableId) {
                if (!inputsToAddToDraggableId[input.draggable.addToDraggableId]) {
                    inputsToAddToDraggableId[input.draggable.addToDraggableId] = [];
                }

                inputsToAddToDraggableId[input.draggable.addToDraggableId].push(indexOf);

                continue;
            }

            if (!inputsForDroppables[input.draggable.droppableId!]) {
                inputsForDroppables[input.draggable.droppableId!] = [];
            }

            inputsForDroppables[input.draggable.droppableId!].push(indexOf);
        }
    }

    for (let index in inputs) {
        if (inputs.hasOwnProperty(index)) {
            const droppableId = inputs[index].draggable?.droppableId;

            if (droppableId) {
                if (inputsForDroppables[droppableId] && inputsForDroppables[droppableId].length > 0) {
                    const inputsForDroppable = inputsForDroppables[droppableId];
                    const droppableProps = droppables![droppableId];

                    const droppableChildren: ReactNode[] = [];
                    for (const index of inputsForDroppable) {
                        const draggableChildren: ReactNode[] = [
                            RenderItem({
                                inputs,
                                index,
                                input: inputs[index],
                                classes,
                                cx,
                                UpdateProp,
                                UpdateValue,
                            }),
                        ];

                        if (inputs[index].draggable && inputs[index].draggable!.draggableId && inputsToAddToDraggableId[inputs[index].draggable!.draggableId!]) {
                            for (const subIndexOf of inputsToAddToDraggableId[inputs[index].draggable!.draggableId!]) {
                                draggableChildren.push(
                                    RenderItem({
                                        inputs,
                                        index: subIndexOf,
                                        input: inputs[subIndexOf],
                                        classes,
                                        cx,
                                        UpdateProp,
                                        UpdateValue,
                                    }),
                                );
                            }
                        }

                        droppableChildren.push(
                            droppableProps ?
                                <Draggable
                                    key={index}
                                    draggableId={inputs[index].draggable!.draggableId!}
                                    index={inputs[index].draggable!.index!}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            {draggableChildren}
                                        </div>
                                    )}
                                </Draggable> : RenderItem({
                                    inputs,
                                    index,
                                    input: inputs[index],
                                    classes,
                                    cx,
                                    UpdateProp,
                                    UpdateValue,
                                })
                        );
                    }

                    if (droppableProps) {
                        children.push(
                            <Droppable
                                key={droppableId}
                                droppableId={droppableId} type={droppableProps.type}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={{backgroundColor: 'transparent', width: '100%'}}
                                        {...provided.droppableProps}
                                    >
                                        {droppableChildren}

                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        );
                    } else {
                        children.push(
                            <div
                                key={droppableId}
                                style={{backgroundColor: 'transparent', width: '100%'}}
                            >
                                {droppableChildren}
                            </div>
                        );
                    }

                    delete inputsForDroppables[droppableId];
                }
            } else {
                children.push(RenderItem({
                    inputs,
                    index,
                    input: inputs[index],
                    classes,
                    cx,
                    UpdateProp,
                    UpdateValue,
                }));
            }
        }
    }

    return (
        <>
            {children}
        </>
    );
}

interface IRenderItemProps {
    inputs: IInputsData;
    index: string;
    input: IInputsItem;
    classes: Record<string, string>;
    cx: Cx;
    UpdateProp: (index: string, key: string, value: any) => void;
    UpdateValue: (index: string, value: any) => void;
}

/**
 * Render input based on data.
 */
function RenderItem(props: IRenderItemProps): ReactNode {
    const {inputs, index, input, classes, cx, UpdateProp, UpdateValue} = props;

    switch (input.type) {
        case InputType.Text: {
            const inputProps: InputProps = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, inputProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <TextInput {...inputProps} index={index} updateProp={UpdateProp} updateValue={UpdateValue}/>
                </Grid>
            );
        }
        case InputType.Password: {
            const inputProps: InputProps = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, inputProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <PasswordInput {...inputProps} index={index} updateProp={UpdateProp}
                                   updateValue={UpdateValue}/>
                </Grid>
            );
        }
        case InputType.ButtonTextField: {
            const inputProps: ButtonTextFieldProps = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, inputProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <ButtonTextField
                        {...inputProps}
                        index={index}
                        updateProp={UpdateProp}
                        updateValue={UpdateValue}
                    />
                </Grid>
            );
        }

        case InputType.TextArea: {
            if (!input.minRows) {
                throw Error('Not valid TextAreaProps');
            }

            const textAreaProps: TextAreaProps = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, textAreaProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <TextArea
                        {...textAreaProps}
                        index={index}
                        updateProp={UpdateProp}
                        updateValue={UpdateValue}/>
                </Grid>
            );
        }
        case InputType.Select: {
            if (!input.options) {
                throw Error('Not valid SelectProps');
            }

            const selectProps: SelectProps = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, selectProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <Select {...selectProps} index={index} updateProp={UpdateProp}
                            updateValue={UpdateValue}/>
                </Grid>
            );
        }
        case InputType.ButtonSelect: {
            if (!input.options) {
                throw Error('Not valid ButtonSelectProps');
            }

            const buttonSelectProps: ButtonSelectProps = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, buttonSelectProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <ButtonSelect {...buttonSelectProps} index={index} updateProp={UpdateProp}
                                  updateValue={UpdateValue}/>
                </Grid>
            );
        }
        case InputType.CustomRenderer: {
            if (!input.render) {
                throw Error('Not valid CustomRenderer');
            }

            return (
                <Grid className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined)} key={index}
                      item={true} xs={12} {...input.grid}>
                    <div>{input.render(inputs)}</div>
                </Grid>
            );
        }
        case InputType.DatePicker: {
            if (typeof input.value !== 'number' && typeof input.value !== 'undefined') {
                throw Error('Not valid DatePickerProps');
            }
            if (typeof input.dateFormat !== 'string') {
                throw Error('Not valid DatePickerProps');
            }

            const datePickerProps: DatePickerProps = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, datePickerProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <FormDatePicker {...datePickerProps} index={index} updateProp={UpdateProp}
                                    updateValue={UpdateValue}/>
                </Grid>
            );
        }
        case InputType.CheckBox: {
            if (typeof input.value !== 'boolean') {
                throw Error('Not valid CheckBoxProps');
            }

            const checkBoxProps: CheckBoxProps = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, checkBoxProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <FormCheckBox {...checkBoxProps} index={index} updateProp={UpdateProp}
                                  updateValue={UpdateValue}/>
                </Grid>
            );
        }
        case InputType.IconCheckbox: {
            if (typeof input.value !== 'boolean') {
                throw Error('Not valid CheckBoxProps');
            }

            const iconCheckBoxProps: IconCheckBoxProps = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, iconCheckBoxProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <IconCheckBox {...iconCheckBoxProps} index={index} updateProp={UpdateProp}
                                  updateValue={UpdateValue}/>
                </Grid>
            );
        }
        case InputType.ChipAutocomplete: {

            const chipAutocompleteProps: IChipsAutocompleteProps = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, chipAutocompleteProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <ChipsAutocomplete {...chipAutocompleteProps} index={index} updateProp={UpdateProp}
                                       updateValue={UpdateValue}/>
                </Grid>
            );
        }

        case InputType.GooglePlacesAutocomplete: {
            if (typeof input.value !== 'string') {
                throw Error('Not valid IGooglePlacesAutocompleteField');
            }

            const autocompleteProps: IGooglePlacesAutocompleteField = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, autocompleteProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <GooglePlacesAutocompleteField {...autocompleteProps} index={index} updateProp={UpdateProp}
                                                   updateValue={UpdateValue}/>
                </Grid>
            );
        }
        case InputType.MapyCZAutocomplete: {
            const autocompleteProps: IMapyCZAutocompleteField = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, autocompleteProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <MapyCZAutocompleteField {...autocompleteProps} index={index} updateProp={UpdateProp}
                                             updateValue={UpdateValue}/>
                </Grid>
            );
        }
        case InputType.Switch: {
            if (typeof input.value !== 'boolean') {
                throw Error('Not valid FormSwitchProps');
            }

            const switchProps: FormSwitchProps = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, switchProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <FormSwitch {...switchProps} index={index} updateProp={UpdateProp}
                                updateValue={UpdateValue}/>
                </Grid>
            );
        }

        case InputType.TextAutocomplete: {
            const textAutocompleteProps: ITextAutocompleteFieldProps = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, textAutocompleteProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <TextAutocompleteField {...textAutocompleteProps} index={index} updateProp={UpdateProp}
                                           updateValue={UpdateValue}/>
                </Grid>
            );
        }
        case InputType.Editor: {
            const inputProps: InputProps = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, inputProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <EditorInput {...inputProps} index={index} updateProp={UpdateProp}
                                 updateValue={UpdateValue}/>
                </Grid>
            );
        }

        case InputType.FormTimePicker: {
            if (typeof input.value !== 'undefined' && typeof input.value !== 'number') {
                throw Error('Not valid TimePickerProps');
            }

            const formTimePicker: IFormTimePickerProps = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, formTimePicker.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <FormTimePicker
                        {...formTimePicker}
                        index={index}
                        updateProp={UpdateProp}
                        updateValue={UpdateValue}
                    />
                </Grid>
            );
        }

        case InputType.FormToggleButtons: {
            if (input.value !== '' && input.value.isArray) {
                throw Error('Not valid ToggleButtonProps');
            }

            const formToggleButtonsProps: FormToggleButtonsProps = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, formToggleButtonsProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <FormToggleButtons
                        {...formToggleButtonsProps}
                        index={index}
                        updateProp={UpdateProp}
                        updateValue={UpdateValue}
                    />
                </Grid>
            );
        }

        case InputType.ChipSwitch: {

            const chipSwitchProps: IChipSwitchProps = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, chipSwitchProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <ChipSwitch
                        {...chipSwitchProps}
                        index={index}
                        updateProp={UpdateProp}
                        updateValue={UpdateValue}
                    />
                </Grid>
            );
        }

        case InputType.RadioGroup: {
            if (!input.options) {
                throw Error('Not valid RadioButtonGroupProps');
            }

            const radioGroupProps: RadioButtonGroupProps = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, radioGroupProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <RadioButtonGroup {...radioGroupProps} index={index} updateProp={UpdateProp}
                                      updateValue={UpdateValue}/>
                </Grid>
            );
        }
        case InputType.DatesPicker: {
            const datesProps: DatesPickerProps = input as any;

            return (
                <Grid
                    className={cx(classes.gridItem, input.hidden ? classes.hidden : undefined, datesProps.className)}
                    key={index} item={true}
                    xs={12} {...input.grid}>
                    <DatesPicker {...datesProps} index={index} updateProp={UpdateProp}
                                 updateValue={UpdateValue}/>
                </Grid>
            );
        }

    }
}

/**
 * Validate fields of form.
 */
export function ValidateForm(inputs: IInputsData, setInputs: (inputs: IInputsData) => void): boolean {
    let valid = true;

    for (let index in inputs) {
        if (inputs.hasOwnProperty(index)) {
            const input = inputs [index];

            const inputName = () => {
                if (input.required) {
                    if (input.label.charAt(input.label.length - 1) == '*') {
                        return input.label.slice(0, -1);
                    } else {
                        return input.label;
                    }
                }
                return input.label;
            }

            input.error = false;

            if (input.required) {
                if (`${input.value}`.length === 0) {
                    input.error = true;
                    input.errorMessage = input.requiredMessage || `${inputName()} ${tt('common.isRequiredField')}.`;
                    valid = false;
                }
            }

            if (!input.error && input.requireUri) {
                if (input.value.length > 0 && !isUri(input.value)) {
                    input.error = true;
                    input.errorMessage = input.requireUriMessage || `${inputName()} ${tt('common.isNotValidUrl')}.`;
                    valid = false;
                }
            }

            if (!input.error && input.requireEmail) {
                if (input.value.length > 0 && !isEmail(input.value)) {
                    input.error = true;
                    input.errorMessage = input.requireEmailMessage || `${inputName()} ${tt('common.isNotValidEmail')}.`;
                    valid = false;
                }
            }

            if (!input.error && input.requireEqualTo) {
                const equalToInput = inputs[input.requireEqualTo];

                if (input.value !== equalToInput.value) {
                    input.error = true;
                    input.errorMessage = input.requireEqualToMessage || `${input.label} must be equal to ${equalToInput.label}.`;
                    valid = false;
                }
            }

            if (!input.error && input.requireMinLength) {
                if (input.value.length < input.requireMinLength) {
                    input.error = true;
                    input.errorMessage = input.requireMinLengthMessage || `${inputName()} ${tt('common.isNotValidMinLength').replace('$length', `${input.requireMinLength}`)}.`;
                    valid = false;
                }
            }
        }
    }

    const newObject = {...inputs};

    setInputs(newObject);

    return valid;
}
