import React, {Dispatch, SetStateAction} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Fade, Modal, Paper, Theme, Typography} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import ResponsiveContainer from "../screens/ResponsiveContainer";
import AppIconButton from "../buttons/AppIconButton";
import Icons8Left from "../../../icons/Icons8Left";
import CloseIcon from "../../../icons/CloseIcon";
import {useSpotlight} from "@mantine/spotlight";

export const useStyles = makeStyles()((theme: Theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '.MuiBackdrop-root': {
            background: 'rgba(0, 0, 0, 0.6)',
        },
    },
    modalAboveModals: {
        zIndex: '1301 !important',
    },
    modalActions: {
        display: "flex",
        justifyContent: "flex-end",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    modalPaper: {
        overflowY: "auto",
        position: "relative",
        maxHeight: '90vh',
        "@media (max-width: 767px)": {
            maxHeight: '100vh',
        },
        minWidth: '240px',
        maxWidth: '767px',
        width: '100%',
        outline: 'none',
        borderRadius: 12,
    },
    contentContainer: {
        paddingRight: 16,
        paddingLeft: 16,
    },
    headerContainer: {
        paddingRight: 16,
        paddingLeft: 8,
        paddingTop: 8,
        marginBottom: 16,
        height: 64,
        display: "flex",
        alignItems: "center",
        position: "sticky",
        top: 0,
        zIndex: 100,
        backgroundColor: kAppColors.background.mobileAppbar(theme.palette.mode === "dark"),
        backdropFilter: `saturate(180%) blur(10px)`,
        transition: 'all 0.1s linear',
        borderBottom: `1px solid transparent`
    },
    headerContainerNoMarginBottom: {
        marginBottom: 0,
    },
    whiteTextAppBarVariant: {
        backgroundColor: "transparent",
        'svg': {
            color: "white",
        },
        'p': {
            color: "white",
        },
        '.MuiIconButton-sizeMedium': {
            '&:hover': {
                background: '#a2a5a9',
            }
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 600,
        "@media (max-width: 767px)": {
            fontSize: 16,
        }
    },
    subtitle: {
        fontSize: 14,
        fontWeight: 500,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
    },
    closeButton: {
        marginRight: theme.spacing(1.25),
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
    },
    centeredHeader: {
        paddingTop: 20,
        paddingLeft: 16,
        paddingRight: 16,
    },
    centeredHeaderTitle:
        {
            fontSize: 20,
            fontWeight: 500,
            textAlign: "center",
            marginBottom: 16,
            "@media (max-width: 767px)": {
                fontSize: 16,
            }
        },
    centeredHeaderSubtitle: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontSize: 14,
        fontWeight: 500,
        textAlign: "center",
        marginBottom: 16,
    },
    blurBackdrop: {
        '.MuiBackdrop-root': {
            backdropFilter: `saturate(180%) blur(10px)`,
        }
    },
    fullScreen: {
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        minWidth: '100%',
        maxWidth: '100%',
        width: '100%',
        height: '100%',
        outline: 'none',
        borderRadius: 0,
        maxHeight: '100%',
    },
    headerActions: {
        marginLeft: "auto",
    }
}));

export interface Props {
    title?: string;
    subtitle?: string | React.ReactNode;
    className?: string;
    paperClassName?: string;
    headerClass?: string;
    open: boolean;
    setOpen?: Dispatch<SetStateAction<boolean>>;
    onClose?: Function;
    children: React.ReactNode;
    actions?: React.ReactNode;
    arrowBackButton?: true;
    centeredHeader?: boolean;
    noContentPadding?: boolean;
    blurBackdrop?: boolean;
    fullScreenOnMobile?: boolean;
    fullScreen?: boolean;
    isMobile?: boolean;
    headerActions?: React.ReactNode;
    modalAboveModals?: boolean;
    whiteTextAppBarVariant?: boolean;
    appBarNoMarginBottom?: boolean;
    hideActionsSection?: boolean;
}

function AppModal(props: Props) {
    return (
        <ResponsiveContainer
            smallPhoneScreen={<Body {...props} isMobile={true}/>}
            largePhoneScreen={<Body  {...props} isMobile={true}/>}
            tabletScreen={<Body {...props} />}
            smallDesktopScreen={<Body {...props} />}
            largeDesktopScreen={<Body {...props} />}
            extraLargeDesktopScreen={<Body {...props} />}
        />
    );
}

function Body(props: Props) {
    const {
        className,
        paperClassName,
        headerClass,
        open,
        children,
        actions,
        setOpen,
        onClose,
        title,
        subtitle,
        noContentPadding,
        centeredHeader,
        blurBackdrop,
        fullScreenOnMobile,
        isMobile,
        fullScreen,
        arrowBackButton,
        headerActions,
        modalAboveModals,
        whiteTextAppBarVariant,
        appBarNoMarginBottom,
        hideActionsSection,
    } = props;

    const {classes, cx} = useStyles();

    const modalClass = cx(
        classes.modal,
        blurBackdrop ? classes.blurBackdrop : undefined,
        modalAboveModals ? classes.modalAboveModals : undefined,
        className,
    );

    const paperClass =
        cx(classes.modalPaper, paperClassName,
            fullScreenOnMobile && isMobile || fullScreen ? classes.fullScreen : undefined,
        );

    const headerContainerClass = cx(
        classes.headerContainer,
        appBarNoMarginBottom ? classes.headerContainerNoMarginBottom : null,
        whiteTextAppBarVariant ? classes.whiteTextAppBarVariant : null,
        headerClass,
    );

    const theContentContainerClass = cx(
        noContentPadding == true ? undefined : classes.contentContainer,
    );

    const closeIconJSX = arrowBackButton ? <Icons8Left/> : <CloseIcon/>;

    const subtitleJSX = subtitle == null ? undefined : <Typography
        className={centeredHeader ? classes.centeredHeaderSubtitle : classes.subtitle}>{subtitle}</Typography>;

    const header = title == null ? undefined : (
        centeredHeader ? <Box className={classes.centeredHeader}>
                <Typography className={classes.centeredHeaderTitle}>{title}</Typography>
                {subtitleJSX}
            </Box> :
            <Box className={headerContainerClass}>
                <AppIconButton className={classes.closeButton} onClick={() => {
                    onClose?.();
                    setOpen?.(false)
                }}>{closeIconJSX}</AppIconButton>
                <Box className={cx('headerTitleContainer')}>
                    <Typography className={classes.title}>{title}</Typography>
                    {subtitleJSX}
                </Box>
                {headerActions != null ? <Box className={classes.headerActions}>{headerActions}</Box> : null}
            </Box>
    );

    const spotlight = useSpotlight();

    return (
        <Modal
            disableEnforceFocus={spotlight.opened}
            onClose={() => {
                // setOpen?.(false)
            }} className={modalClass} open={open}>
            <Fade in={open}>
                <Paper elevation={1} className={paperClass}>
                    {header}
                    <div className={theContentContainerClass}>
                        {children}
                    </div>
                    {hideActionsSection ? null :
                        <div className={classes.modalActions}>
                            {actions}
                        </div>
                    }
                </Paper>
            </Fade>
        </Modal>
    );
}

export default AppModal;
