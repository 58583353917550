import React from "react";
import {Theme, Tooltip} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";

const useStyles = makeStyles()((theme: Theme) => ({
    button: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "transparent",
        color: theme.palette.mode === "dark" ? '#BABABB' : kAppColors.text.secondaryLightModeGrey,
        border: "none",
        outline: "none",
        width: 52,
        height: 52,
        transition: "ease-in-out",
        borderRadius: 10,
        cursor: "pointer",
        '&:hover': {
            background: kAppColors.hoverColor.grey(theme.palette.mode === "dark"),
        },
    },
    buttonSelected: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.light,
        ':hover': {
            background: kAppColors.hoverColor.main,
        }
    },
    highlightedVariant: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    }
}));

export interface IDrawerNavigationButtonProps {
    testId: string;
    children?: React.ReactNode;
    variant?: 'default' | 'highlighted';
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    tooltip: string;
    isSelected?: boolean;
    forceIconColor?: string,
    placement?: | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
}

export default function DrawerNavigationButton(props: IDrawerNavigationButtonProps) {
    const {children, tooltip, isSelected, placement, onClick, variant, testId} = props;

    const {classes, cx} = useStyles();

    const theVariant = variant || 'default';
    const thePlacement = placement || 'right';

    let buttonClassName;

    buttonClassName = cx(
        classes.button,
        theVariant === 'highlighted' ? classes.highlightedVariant : undefined,
        isSelected ? classes.buttonSelected : undefined,
    );

    return (
        <Tooltip title={tooltip} placement={thePlacement}>
            <button data-testid={testId} type="button" className={buttonClassName} onClick={onClick}>
                {children}
            </button>
        </Tooltip>
    );
}
