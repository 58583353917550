declare global {
    interface Window {
        dataLayer?: any;
    }
}

/**
 * Use Google Analytics for DataLayer tracking events.
 */
export function trackDataLayerEvent(event: string, parameters?: any) {
    if (window.dataLayer) {
        window.dataLayer.push({
            'event': event,
            ...parameters
        });
    }
}
