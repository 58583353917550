import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material";

export const iconUseStyles = makeStyles()((theme: Theme) => ({
    icon: {
        width: 28,
        height: 28,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    iconBigger: {
        width: 48,
        height: 48,
    },
}));
