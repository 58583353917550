import {DatesPickerProps} from "./InputProps";
import {useStyles as formUseStyles} from "./FormBuilder";
import GreyLabel from "../decorations/GreyLabel";
import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {AppDataContext} from "../../../AppData";
import {Calendar} from "@mantine/dates";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {DateTime} from "luxon";

const useStyles = makeStyles()((theme: Theme) => ({
    topLabelPadding: {
        paddingTop: 16,
        paddingBottom: 16,
    },
    calendar: {
        padding: 4,
        background: kAppColors.grey.inputBackground(theme.palette.mode === "dark"),
        borderRadius: 8,
        '.mantine-CalendarHeader-calendarHeader, .mantine-MonthsList-monthsList, .mantine-YearLevel-yearLevel, .mantine-Month-month': {
            width: '100%',
            maxWidth: '100%',
        },
    },
    today: {
        border: `1px solid ${kAppColors.primary.main}`
    },
    excludeDaysVariant: {
        maxWidth: 300,
        '.mantine-Day-day.mantine-Calendar-day': {
            cursor: "default",
            borderRadius: '0.25rem',
            color: theme.palette.mode === "dark" ? '#5c5f66' : '#ced4da',
            background: "transparent",
            'pointerEvents': 'none',
        },
        '.mantine-Day-day.mantine-Calendar-day[data-in-range]': {
            'pointerEvents': 'auto',
            cursor: "pointer",
            backgroundColor: "transparent",
            color: theme.palette.mode === "dark" ? '#C1C2C5' : '#000',
        },
        '.mantine-Day-day.mantine-Calendar-day[data-in-range]:focus': {},
        '.mantine-Day-day.mantine-Calendar-day[data-in-range][data-weekend]': {
            color: '#ff8787',
        },
        '.mantine-Day-day.mantine-Calendar-day[data-in-range][data-selected], .mantine-Day-day.mantine-Calendar-day[data-in-range][data-selected][data-weekend]': {
            backgroundColor: kAppColors.primary.main,
            color: "white"
        }
    },
}));

/**
 * Form component for multiple custom Dates input.
 */
export default function DatesPicker(props: DatesPickerProps) {
    const {
        className,
        hidden,
        index,
        label,
        value,
        error,
        errorMessage,
        updateValue,
        disablePast,
        topLabel,
        disabled,
        startDate,
        endDate,
        skipWeekends,
        datesPickerVariant,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const {classes: formClasses} = formUseStyles();
    const {classes, cx} = useStyles();

    const now = DateTime.now().startOf('day').toMillis();


    const [daysOnInterval, setDaysOnInterval] = useState<Array<DateTime>>([]);

    const theCalendarClass = cx(
        classes.calendar,
        datesPickerVariant === "excludeDays" ? classes.excludeDaysVariant : null,
    );

    /**
     * Get dates to exclude from
     */
    function getDaysOnInterval(startDate?: DateTime, endDate?: DateTime, skipWeekends?: boolean): DateTime[] {
        if (startDate && endDate) {
            let dates = [];
            let tempStart = startDate;
            while (tempStart <= endDate) {
                if (skipWeekends) {
                    if (tempStart.weekday != 6 && tempStart.weekday != 7) {
                        dates.push(tempStart.startOf("day"));
                    }
                } else {
                    dates.push(tempStart.startOf("day"));
                }
                tempStart = tempStart.plus({day: 1});
            }

            return dates;
        }

        return [];
    }

    useEffect(() => {
        setDaysOnInterval(getDaysOnInterval(startDate, endDate, skipWeekends));
    }, [skipWeekends, startDate, endDate]);


    /**
     * Check if Date is included in value.
     */
    const isDateIncluded = (date: Date): boolean => {
        if (datesPickerVariant === "excludeDays") {
            //check if should be highlighted
            const isInRange = daysOnInterval.find(value => value.toMillis() === date.getTime());
            const isExcluded = value?.find(val => val === date.getTime());
            return (isInRange != null && isExcluded == null);
        }

        //check if should be highlighted and if should be selected
        return !!value?.includes(date.getTime());
    };

    /**
     * OnChange update data of this field.
     */
    const handleSelect = (date: Date) => {
        const dateValue = date.getTime();

        const newValue = value ? [...value] : [];

        if (datesPickerVariant === "excludeDays" && startDate && endDate) {
            const isIncluded = daysOnInterval.find(value => value.toMillis() === date.getTime());

            if (!isIncluded) {
                return;
            }

            if (!!value?.includes(date.getTime())) {
                newValue.splice(newValue.indexOf(dateValue), 1);
            } else {
                newValue.push(dateValue);
            }

            updateValue(index, newValue);

        } else {
            if (isDateIncluded(date)) {
                newValue.splice(newValue.indexOf(dateValue), 1);
            } else {
                newValue.push(dateValue);
            }

            updateValue(index, newValue);
        }


    };

    const resolveRange = (date: Date) => {
        if (datesPickerVariant === "excludeDays" && startDate && endDate) {
            const isIncluded = daysOnInterval.find(value => value.toMillis() === date.getTime());

            return isIncluded != null;
        }

        return false;
    };

    return (
        <div className={cx(className, (hidden ? formClasses.hidden : undefined))}>
            {topLabel ? <GreyLabel className={classes.topLabelPadding} text={topLabel}/> : null}

            {!topLabel ? <GreyLabel text={label}/> : null}

            <Calendar
                key={startDate?.toMillis()}
                className={theCalendarClass}
                locale={language}
                defaultDate={startDate?.toJSDate()}
                getDayProps={(date) => ({
                    selected: isDateIncluded(date),
                    className: date.getTime() === now ? classes.today : undefined,
                    onClick: disabled ? undefined : () => handleSelect(date),
                    inRange: resolveRange(date),
                })}
                placeholder={""}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
            />
        </div>
    );
}
