import React, {Dispatch, SetStateAction, useContext, useEffect} from "react";
import FormBuilder, {IInputsData, InputType} from "../../form/FormBuilder";
import {tt} from "../../../../core/Localization";
import {DateTime} from "luxon";
import {Box, Theme, Typography} from "@mui/material";
import {
    Create4WeeksOptions,
    CreateMonthDayPickerOptions,
    CreateWeekdayOptions,
} from "../../../../utils/DateUtils";
import {AppDataContext} from "../../../../AppData";
import VisitsSummarySection from "./VisitsSummarySection";
import {CreateVisitInput, VisitRepeating, VisitRepeatModifier} from "../../../../generated/graphql/graphql";
import {makeStyles} from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
    textSuffix: {
        whiteSpace: "nowrap",
    },
    oneLineCheckbox: {
        flexBasis: "unset",
        maxWidth: "none !important",
        whiteSpace: "nowrap",
    },
    skipDaysCalendar: {
        paddingTop: 12,
    }
}));

export interface IOneOrMoreTimeVisitsFormProps {
    selectedTab: string;
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    isRecurringVisit?: boolean;
    customVisit?: boolean;
    resetForms: number;
    visit?: CreateVisitInput;
}

export default function VisitForm(props: IOneOrMoreTimeVisitsFormProps) {
    const {
        selectedTab,
        inputs,
        setInputs,
        isRecurringVisit,
        customVisit,
        resetForms,
        visit,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;
    const {classes} = useStyles();

    useEffect(() => {
        let monthDaysTypeValue = VisitRepeatModifier.DayOfMonth;
        if (visit && visit.repeatModifier) {
            if (visit.repeatModifier === VisitRepeatModifier.DayOfMonth || visit.repeatModifier === VisitRepeatModifier.DayOfMonthOrLastDay) {
                monthDaysTypeValue = VisitRepeatModifier.DayOfMonth;
            }
            if (visit.repeatModifier === VisitRepeatModifier.DayOfWeek || visit.repeatModifier === VisitRepeatModifier.DayOfWeekOrLastDay) {
                monthDaysTypeValue = VisitRepeatModifier.DayOfWeek;
            }
        }

        setInputs({
            startTime: {
                testId: 'createNewJobFormStartTime',
                type: InputType.FormTimePicker,
                label: `${tt('common.startTime')}*`,
                value: visit?.startTime || DateTime.now().set({
                    hour: 8,
                    minute: 0,
                    second: 0,
                    millisecond: 0
                }).toMillis(),
                required: true,
                grid: {
                    sm: 3,
                    xs: 6,
                }
            },
            endTime: {
                testId: 'createNewJobFormEndTime',
                type: InputType.FormTimePicker,
                label: `${tt('common.endTime')}*`,
                value: visit?.endTime || DateTime.now().set({hour: 9, minute: 0, second: 0, millisecond: 0}).toMillis(),
                required: true,
                grid: {
                    sm: 3,
                    xs: 6,
                }
            },
            startDate: {
                testId: 'createNewJobFormStartDate',
                type: InputType.DatePicker,
                label: `${tt('common.startDate')}*`,
                value: visit?.startDate || DateTime.now().set({
                    hour: 8,
                    minute: 0,
                    second: 0,
                    millisecond: 0
                }).toMillis(),
                dateFormat: 'dd.MM.yyyy',
                disablePast: false,
                required: true,
                grid: {
                    sm: 3,
                    xs: 6,
                },
            },
            endDate: {
                testId: 'createNewJobFormSEndDate',
                type: InputType.DatePicker,
                label: `${tt('common.endDate')}*`,
                value: visit?.repeatEndDate || visit?.endDate || DateTime.now().set({
                    hour: 9,
                    minute: 0,
                    second: 0,
                    millisecond: 0
                }).toMillis(),
                dateFormat: 'dd.MM.yyyy',
                disablePast: false,
                required: true,
                grid: {
                    sm: 3,
                    xs: 6,
                },
            },
            frequency: {
                testId: 'createNewVisitFrequencyId',
                type: InputType.Select,
                label: tt('common.frequency'),
                options: [
                    {label: tt('common.daily'), value: VisitRepeating.Daily},
                    {label: tt('common.weekly'), value: VisitRepeating.Weekly},
                    {label: tt('common.monthly'), value: VisitRepeating.Monthly},
                    {label: tt('common.yearly'), value: VisitRepeating.Yearly},
                ],
                value: visit?.repeating || VisitRepeating.Daily,
                hidden: !isRecurringVisit,
                required: true,
                grid: {
                    sm: 6,
                    xs: 12,
                },
            },
            every: {
                testId: 'frequencySpecificationEveryInputId',
                type: InputType.Text,
                numbersOnly: true,
                inputMode: "decimal",
                label: tt('common.every'),
                value: visit?.every || 1,
                hidden: !isRecurringVisit,
                required: true,
                grid: {
                    sm: 6,
                    xs: 12,
                },
            },
            weekdayPicker: {
                testId: 'weekdayPickerInputTestId',
                type: InputType.FormToggleButtons,
                label: '',
                value: visit?.repeatWeekDays.map(day => day.toString()) || [],
                options: CreateWeekdayOptions(language),
                hidden: !isRecurringVisit,
                grid: {
                    sm: 6,
                    xs: 12,
                },
            },
            monthDaysType: {
                testId: 'createNewVisitMonthDaysType',
                type: InputType.Select,
                label: tt('common.monthDaysType'),
                options: [
                    {label: tt('common.dayOfMonth'), value: VisitRepeatModifier.DayOfMonth},
                    {label: tt('common.dayOfWeek'), value: VisitRepeatModifier.DayOfWeek},
                ],
                value: monthDaysTypeValue,
                hidden: !isRecurringVisit,
                required: true,
                grid: {
                    sm: 6,
                    xs: 12,
                },
            },
            monthDayPicker: {
                testId: 'monthDayPickerInputTestId',
                type: InputType.FormToggleButtons,
                label: '',
                toggleButtonsVariant: 'calendar',
                value: visit?.monthDays?.map(day => day.toString()) || [],
                options: CreateMonthDayPickerOptions(),
                hidden: !isRecurringVisit,
                grid: {
                    sm: 6,
                    xs: 12,
                },
            },
            monthWeekDayPicker: {
                testId: 'monthWeekDayPickerInputTestId',
                type: InputType.FormToggleButtons,
                label: '',
                toggleButtonsVariant: '4weeks',
                value: visit?.monthWeekDays || [],
                options: Create4WeeksOptions(language),
                hidden: !isRecurringVisit,
                grid: {
                    sm: 6,
                    xs: 12,
                },
            },
            datesPicker: {
                testId: 'datesPickerInputTestId',
                type: InputType.DatesPicker,
                label: '',
                value: visit?.customDates?.map(date => date.timestamp) || [],
                hidden: !customVisit,
                grid: {
                    sm: 6,
                    xs: 12,
                },
            },
            wholeDay: {
                className: classes.oneLineCheckbox,
                testId: 'wholeDayInputTestId',
                type: InputType.CheckBox,
                label: tt('newVisitScreen.checkbox.wholeDay'),
                value: visit?.wholeDay || false,
                grid: {
                    sm: 3,
                    xs: 6,
                },
            },
            skipWeekends: {
                className: classes.oneLineCheckbox,
                testId: 'skipWeekendsInputTestId',
                type: InputType.CheckBox,
                label: tt('newVisitScreen.checkbox.skipWeekends'),
                value: visit?.repeatModifier === VisitRepeatModifier.SkipWeekends || false,
                hidden: isRecurringVisit,
                grid: {
                    sm: 3,
                    xs: 6,
                },
            },
            skipOtherDaysCheckbox: {
                className: classes.oneLineCheckbox,
                testId: 'skipOtherDaysCheckboxTestId',
                type: InputType.CheckBox,
                label: tt('newVisitScreen.checkbox.skipOtherDays'),
                value: !!(visit && visit.skipDays.length > 0),
                hidden: true,
                grid: {
                    sm: 3,
                    xs: 6,
                },
            },
            scheduleLater: {
                className: classes.oneLineCheckbox,
                testId: 'scheduleLaterInputTestId',
                type: InputType.CheckBox,
                label: tt('newVisitScreen.checkbox.scheduleLater'),
                value: visit?.scheduleLater || false,
                hidden: isRecurringVisit || customVisit,
                grid: {
                    sm: 3,
                    xs: 6,
                },
            },
            neverEnding: {
                className: classes.oneLineCheckbox,
                testId: 'neverEndingInputTestId',
                type: InputType.CheckBox,
                label: tt('newVisitScreen.checkbox.neverEnding'),
                value: visit && isRecurringVisit ? !visit.repeatEndDate : false,
                hidden: !isRecurringVisit,
                grid: {
                    sm: 3,
                    xs: 6,
                },
            },
            skipOtherDays: {
                className: classes.skipDaysCalendar,
                testId: 'skipOtherDaysTestId',
                datesPickerVariant: 'excludeDays',
                type: InputType.DatesPicker,
                label: '',
                value: visit?.skipDays || [],
                hidden: true,
                grid: {
                    sm: 12,
                    xs: 12,
                },
            },
        });
    }, []);

    const startDate = inputs.startDate?.value ? DateTime.fromMillis(inputs.startDate.value) : DateTime.now();
    const endDate = inputs.endDate?.value ? DateTime.fromMillis(inputs.endDate.value) : DateTime.now();
    const isSingleDay = startDate.hasSame(endDate, 'day');

    useEffect(() => {
        if (resetForms > 0) {
            setInputs(prev => {
                return {
                    ...prev,
                    startTime: {
                        ...prev.startTime,
                        value: DateTime.now().set({hour: 8, minute: 0, second: 0, millisecond: 0}).toMillis(),
                    },
                    endTime: {
                        ...prev.endTime,
                        value: DateTime.now().set({hour: 9, minute: 0, second: 0, millisecond: 0}).toMillis(),
                    },
                    startDate: {
                        ...prev.startDate,
                        value: DateTime.now().set({hour: 8, minute: 0, second: 0, millisecond: 0}).toMillis(),
                    },
                    endDate: {
                        ...prev.endDate,
                        value: DateTime.now().set({hour: 9, minute: 0, second: 0, millisecond: 0}).toMillis(),
                    },
                    frequency: {
                        ...prev.frequency,
                        value: VisitRepeating.Daily,
                    },
                    every: {
                        ...prev.every,
                        value: 1,
                    },
                    weekdayPicker: {
                        ...prev.weekdayPicker,
                        value: [],
                    },
                    monthDaysType: {
                        ...prev.monthDaysType,
                        value: VisitRepeatModifier.DayOfMonth,
                    },
                    monthDayPicker: {
                        ...prev.monthDayPicker,
                        value: [],
                    },
                    monthWeekDayPicker: {
                        ...prev.monthWeekDayPicker,
                        value: [],
                    },
                    wholeDay: {
                        ...prev.wholeDay,
                        value: false,
                    },
                    skipWeekends: {
                        ...prev.skipWeekends,
                        value: false,
                    },
                    scheduleLater: {
                        ...prev.scheduleLater,
                        value: false,
                    },
                    skipOtherDaysCheckbox: {
                        ...prev.skipOtherDaysCheckbox,
                        value: false,
                    },
                    neverEnding: {
                        ...prev.neverEnding,
                        value: false,
                    },
                    skipOtherDays: {
                        ...prev.skipOtherDays,
                        value: [],
                    },
                };
            });
        }
    }, [resetForms]);

    const theStartTime = inputs.startTime?.value;
    const theEndTime = inputs.endTime?.value;
    const theStartDate = inputs.startDate?.value;
    const theEndDate = inputs.endDate?.value;
    const theFrequency = inputs.frequency?.value;
    const theScheduleLater = inputs.scheduleLater?.value;
    const theNeverEnding = inputs.neverEnding?.value;
    const theWholeDay = inputs.wholeDay?.value;

    useEffect(() => {
        if (theStartTime && theStartDate && theStartTime > theEndTime) {
            const theStartDate2 = DateTime.fromMillis(theStartDate).startOf('day');
            const theEndDate2 = DateTime.fromMillis(theEndDate).startOf('day');

            if (theStartDate2.toMillis() === theEndDate2.toMillis()) {
                setInputs(prev => {
                    return {
                        ...prev,
                        endTime: {
                            ...prev.endTime,
                            value: theStartTime,
                        },
                    };
                });
            }
        }
    }, [theStartTime, theStartDate]);

    useEffect(() => {
        if (theStartDate && theEndDate && theStartTime && theEndTime && theStartTime > theEndTime) {
            const theStartDate2 = DateTime.fromMillis(theStartDate).startOf('day');
            const theEndDate2 = DateTime.fromMillis(theEndDate).startOf('day');

            if (theStartDate2.toMillis() === theEndDate2.toMillis()) {
                setInputs(prev => {
                    return {
                        ...prev,
                        startTime: {
                            ...prev.startTime,
                            value: theEndTime,
                        },
                    };
                });
            }
        }
    }, [theEndTime, theEndDate]);

    useEffect(() => {
        if (theStartDate && theEndDate && theStartDate > theEndDate) {
            setInputs(prev => {
                return {
                    ...prev,
                    endDate: {
                        ...prev.endDate,
                        value: theStartDate,
                    },
                };
            });
        }
    }, [theStartDate]);

    useEffect(() => {
        if (theStartDate && theEndDate && theStartDate > theEndDate) {
            setInputs(prev => {
                return {
                    ...prev,
                    startDate: {
                        ...prev.startDate,
                        value: theEndDate,
                    },
                };
            });
        }
    }, [theEndDate]);

    useEffect(() => {
        let suffix: React.ReactNode | undefined;
        switch (theFrequency) {
            case VisitRepeating.Daily:
                suffix = <Typography
                    className={classes.textSuffix}>{tt('newVisitScreen.input.everyDays.suffix')}</Typography>;
                break;
            case VisitRepeating.Weekly:
                suffix = <Typography
                    className={classes.textSuffix}>{tt('newVisitScreen.input.everyWeeks.suffix')}</Typography>;
                break;
            case VisitRepeating.Monthly:
                suffix = <Typography
                    className={classes.textSuffix}>{tt('newVisitScreen.input.everyMonths.suffix')}</Typography>;
                break;
            case VisitRepeating.Yearly:
                suffix = <Typography
                    className={classes.textSuffix}>{tt('newVisitScreen.input.everyYears.suffix')}</Typography>;
                break;
        }

        setInputs(prev => {
            return {
                ...prev,
                every: {
                    ...prev.every,
                    innerSuffixJSX: suffix,
                }
            }
        })
    }, [theFrequency]);

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                endDate: {
                    ...prev.endDate,
                    hidden: theNeverEnding || !isRecurringVisit,
                }
            }
        })
    }, [theNeverEnding]);

    const selectedMonthDaysType = inputs.monthDaysType?.value;

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                startTime: {
                    ...prev.startTime,
                    hidden: theScheduleLater || theWholeDay,
                },
                startDate: {
                    ...prev.startDate,
                    hidden: theScheduleLater || customVisit,
                    grid: theWholeDay ? {
                        sm: 6,
                        xs: 12,
                    } : {
                        sm: 3,
                        xs: 6,
                    },
                },
                endTime: {
                    ...prev.endTime,
                    hidden: theScheduleLater || theWholeDay,
                },
                endDate: {
                    ...prev.endDate,
                    hidden: theScheduleLater || theNeverEnding || customVisit,
                    grid: theWholeDay ? {
                        sm: 6,
                        xs: 12,
                    } : {
                        sm: 3,
                        xs: 6,
                    },
                },
                frequency: {
                    ...prev.frequency,
                    hidden: theScheduleLater || !isRecurringVisit || customVisit,
                },
                every: {
                    ...prev.every,
                    hidden: theScheduleLater || !isRecurringVisit || customVisit,
                },
                weekdayPicker: {
                    ...prev.weekdayPicker,
                    hidden: theScheduleLater || !isRecurringVisit || theFrequency != VisitRepeating.Weekly || customVisit,
                },
                monthDaysType: {
                    ...prev.monthDaysType,
                    hidden: theScheduleLater || !isRecurringVisit || theFrequency != VisitRepeating.Monthly || customVisit,
                },
                monthDayPicker: {
                    ...prev.monthDayPicker,
                    hidden: theScheduleLater || !isRecurringVisit || theFrequency != VisitRepeating.Monthly || customVisit || selectedMonthDaysType === VisitRepeatModifier.DayOfWeek,
                },
                monthWeekDayPicker: {
                    ...prev.monthWeekDayPicker,
                    hidden: theScheduleLater || !isRecurringVisit || theFrequency != VisitRepeating.Monthly || customVisit || selectedMonthDaysType === VisitRepeatModifier.DayOfMonth,
                },
                wholeDay: {
                    ...prev.wholeDay,
                    hidden: theScheduleLater,
                },
                skipWeekends: {
                    ...prev.skipWeekends,
                    hidden: theScheduleLater || (!isRecurringVisit && isSingleDay) || (isRecurringVisit && theFrequency != VisitRepeating.Daily) || customVisit,
                },
                skipOtherDaysCheckbox: {
                    ...prev.skipOtherDaysCheckbox,
                    hidden: isSingleDay || customVisit || isRecurringVisit,
                },
                datesPicker: {
                    ...prev.datesPicker,
                    hidden: theScheduleLater || !customVisit,
                },
                skipOtherDays: {
                    ...prev.skipOtherDays,
                    hidden: isSingleDay || customVisit || isRecurringVisit || !inputs?.skipOtherDaysCheckbox?.value,
                    startDate: startDate,
                    endDate: endDate,
                    skipWeekends: inputs?.skipWeekends?.value,
                },
            };
        });
    }, [
        theScheduleLater,
        theFrequency,
        theNeverEnding,
        isRecurringVisit,
        isSingleDay,
        customVisit,
        selectedMonthDaysType,
        theWholeDay,
        inputs?.skipWeekends?.value,
        inputs?.skipOtherDaysCheckbox?.value,
        inputs?.startDate?.value,
        inputs?.endDate?.value,
    ]);

    const showSummary = !theScheduleLater && !theNeverEnding && (isRecurringVisit || customVisit);

    return (
        <Box pt={2}>
            <FormBuilder inputs={inputs} setInputs={setInputs}/>

            <Box pb={2}/>

            {showSummary ? (
                <VisitsSummarySection
                    selectedTab={selectedTab}
                    inputs={inputs}
                />
            ) : undefined}
        </Box>
    );
}
