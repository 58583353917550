import {Box, IconButton, Theme, Tooltip, Typography, useTheme} from "@mui/material";
import React from "react";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
    button: {
        position: "relative",
        background: kAppColors.background.contactIconButton(theme.palette.mode === "dark"),
        color: kAppColors.text.black,
        'svg': {
            color: kAppColors.text.primary(theme.palette.mode === 'dark'),
        },
        ':hover': {
            backgroundColor: kAppColors.hoverColor.contactIconButton(theme.palette.mode === "dark"),
        },
    },
    buttonPrimary: {
        'svg': {
            color: kAppColors.text.white,
        },
        background: kAppColors.primary.main,
        ':focused': {
            background: kAppColors.primary.main,
        },
        ':hover': {
            background: kAppColors.primary.main,
        },
    },
    showCount: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 20,
        width: 20,
        height: 20,
        background: kAppColors.red.confirmButton,
        position: "absolute",
        right: 1,
        top: 2,
    },
    counterText: {
        color: "white",
        fontSize: 10,
        fontWeight: 500,
    },
    hoverForPrimaryColor: {
        ':hover': {
            background: kAppColors.hoverColor.mainLighter,
        }
    }
}));

export interface IAppIconButtonProps {
    count?: number;
    children?: React.ReactNode;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    size?: "small" | "medium" | "large" | undefined;
    color?: "default" | "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined;
    tooltip?: string;
    variant?: 'greyBg' | 'primaryBg' | undefined,
    placement?: | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
}

export default function AppIconButton(props: IAppIconButtonProps) {
    const {
        tooltip,
        placement,
        variant,
        className,
        count,
        children,
        onClick,
    } = props;

    const theme = useTheme();
    const {classes, cx} = useStyles();

    const buttonClass = cx(
        className,
        variant === "greyBg" ? classes.button : undefined,
        variant === "primaryBg" ? classes.buttonPrimary : undefined,
        props.color === 'primary' ? classes.hoverForPrimaryColor : undefined,
    );

    const theOnClick: React.MouseEventHandler<HTMLButtonElement> | undefined = onClick ? (e) => {
        e.stopPropagation();

        onClick(e);
    } : undefined;

    const counterJSX = count ? (
        <Box className={classes.showCount}>
            <Typography className={classes.counterText}>{count < 10 ? count : '9+'}</Typography>
        </Box>
    ) : null;

    let contentJSX = (
        <IconButton
            sx={{color: !props.color ? kAppColors.text.primary(theme.palette.mode === "dark") : undefined}}
            {...props}
            className={buttonClass}
            onClick={theOnClick}>
            {children}
            {counterJSX}
        </IconButton>
    );

    if (tooltip) {
        contentJSX = (
            <Tooltip
                title={tooltip}
                placement={placement}
            >
                {contentJSX}
            </Tooltip>
        );
    }

    return (
        <>{contentJSX}</>
    );
}
