import HeadlineWithButton from "../../screenSections/detailListPreviewSection/HeadlineWithButton";
import {tt} from "../../../core/Localization";
import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import ProductListItem from "./ProductListItem";
import AddProductModalBottomSheet from "./AddProductModalBottomSheet";
import {makeStyles} from "tss-react/mui";
import {Box, Divider, Theme, Typography} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {AppDataContext} from "../../../AppData";
import {
    AddVisitProductInput,
    CreateProductInput,
    CreateProductTemplateDocument,
    CreateProductTemplateMutation,
    CreateProductTemplateMutationVariables, EmployeeJoinedUserResponse, FileResponse,
    JobUpdateRepeats,
    ProductResponse,
    UpdateVisitProductInput,
} from "../../../generated/graphql/graphql";
import {IProductFormUpdate} from "./ProductForm";
import EditProductModalBottomSheet, {IEditProductModalBottomSheetData} from "./EditProductModalBottomSheet";
import {v4 as uuidv4} from "uuid";
import {HideConfirmModal, SetConfirmModal} from "../modals/AppModals";
import {CalculateProductsPrice, PriceDisplay} from "../../../service/CompanyService";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {processMutationError} from "../../../service/ErrorService";
import FormBuilder, {IInputsData, InputType} from "../form/FormBuilder";
import GreyLabel from "../decorations/GreyLabel";
import Icons8Plus from "../../../icons/Icons8Plus";
import {kActionCreate, kPermissionsProducts} from "../../../core/constants";

const useStyles = makeStyles()((theme: Theme) => ({
    totalSectionContainerOuter: {
        marginTop: 16,
        borderTop: `dashed 1px ${kAppColors.border(theme.palette.mode === "dark")}`,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
        display: "flex",
        justifyContent: "end",
        "@media (max-width: 576px)": {
            justifyContent: "unset",
            width: '100%',
        },
    },
    totalSectionContainerInner: {
        maxWidth: 340,
        width: '100%',
        "@media (max-width: 576px)": {
            maxWidth: '100%',
        },
    },
    singleRow: {
        display: "flex",
    },
    singleCell: {
        padding: 8,
        width: '33.3%',
        display: "flex",
        justifyContent: "end",
        textOverflow: "ellipsis",
    },
    totalValueContainer: {
        borderTop: `3px solid ${kAppColors.green.main}`,
        display: "flex",
        justifyContent: "end",
        "@media (max-width: 576px)": {
            width: "auto",
            marginLeft: "auto",
        }
    },
    label: {
        fontSize: 12,
        textAlign: "end",
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontWeight: 600,
        textTransform: "uppercase",
        marginBottom: 4,
    },
    value: {
        textAlign: "end",
        fontWeight: 600,
    },
    total: {
        padding: 8,
        fontWeight: 600,
        fontSize: 20,
    },
}));

interface IServicesSectionProps {
    isNewJobCreation?: boolean;
    createProductsInput?: CreateProductInput[];
    setCreateProductsInput?: Dispatch<SetStateAction<CreateProductInput[]>>;
    isVisitDetail?: boolean;
    products?: ProductResponse[];
    files?: FileResponse[] | NullOrUndefined;
    employees?: EmployeeJoinedUserResponse[];
    mutateLoading?: boolean;
    addVisitProduct?: (input: Partial<AddVisitProductInput>) => Promise<void>;
    updateVisitProduct?: (input: Partial<UpdateVisitProductInput>) => Promise<void>;
    deleteProductOfVisit?: (repeats: JobUpdateRepeats, productId: number) => Promise<void>;
    isRepeating?: boolean;
    repeats?: JobUpdateRepeats;
    setRecurringConfirmActionCallback?: (recurringConfirmActionCallback: ((repeats: JobUpdateRepeats) => void) | ((repeats: JobUpdateRepeats) => Promise<void>) | undefined) => void;
    setRecurringEditModal?: Dispatch<SetStateAction<boolean>>;
    setRepeats?: Dispatch<SetStateAction<JobUpdateRepeats>>;
    multiplier?: number;
    canEdit?: boolean;
}

export default function ProductsSection(props: IServicesSectionProps) {
    const {
        isNewJobCreation,
        createProductsInput,
        setCreateProductsInput,
        isVisitDetail,
        products,
        files,
        employees,
        mutateLoading,
        addVisitProduct,
        updateVisitProduct,
        deleteProductOfVisit,
        isRepeating,
        repeats,
        setRecurringConfirmActionCallback,
        setRecurringEditModal,
        setRepeats,
        multiplier,
        canEdit,
    } = props;

    const {classes, cx} = useStyles();
    const appDataContext = useContext(AppDataContext);
    const {storage, currency, language, companyId, company, employeeId} = appDataContext;

    const [addProduct, setAddProduct] = useState<boolean>(false);
    const [total, setTotal] = useState<string>();
    const [editData, setEditData] = useState<IEditProductModalBottomSheetData>();
    const [editProduct, setEditProduct] = useState<boolean>(false);

    const companyHasVat = company?.hasVat || false;

    const [mutateCreate, {
        loading: loadingCreate,
    }] = useResettableMutation<CreateProductTemplateMutation, CreateProductTemplateMutationVariables>(CreateProductTemplateDocument);

    useEffect(() => {
        if (createProductsInput) {
            setTotal(CalculateProductsPrice({
                products: createProductsInput,
                currency,
                language,
                returnZeroInsteadOfUndefined: true,
                multiplier: multiplier,
            }, companyHasVat));
        }
        if (products) {
            setTotal(CalculateProductsPrice({
                products: products,
                currency,
                language,
                returnZeroInsteadOfUndefined: true,
                multiplier: multiplier,
            }, companyHasVat));
        }
    }, [createProductsInput, products, companyHasVat, multiplier]);

    /**
     * If new Job adds to inputs list.
     * On detail mutate new Product to BE.
     */
    const onSubmit = async (input: IProductFormUpdate, saveAsTemplate: boolean, templateId?: number) => {
        let theTemplateId = templateId;

        if (saveAsTemplate) {
            try {
                const variables: CreateProductTemplateMutationVariables = {
                    input: {
                        companyId: companyId!,
                        type: input.inputs.type.value,
                        name: input.inputs.name.value,
                        description: input.inputs.description.value,
                        price: parseFloat(input.inputs.price.value || '0'),
                        vatRate: parseFloat(input.inputs.vatRate.value || '0'),
                        unitName: input.inputs.unitOfMeasure.value,
                        sortOrder: 0,
                    },
                };

                const result = await mutateCreate({variables});

                if (!result.errors) {
                    theTemplateId = result.data?.createProductTemplate.id;
                }
            } catch (e) {
                processMutationError(e);

                return;
            }
        }

        if (setCreateProductsInput) {
            setCreateProductsInput(prev => {
                return [...prev, {
                    uuid: uuidv4(),
                    name: input.inputs.name.value,
                    description: input.inputs.description.value,
                    price: parseFloat(input.inputs.price.value || '0'),
                    vatRate: parseFloat(input.inputs.vatRate.value || '0'),
                    unitName: input.inputs.unitOfMeasure.value,
                    type: input.inputs.type.value,
                    sortOrder: 0,
                    unitCount: parseFloat(input.inputs.quantity.value || '0'),
                    templateId: theTemplateId,
                    employeeId: employeeId!,
                }];
            });
        }

        if (addVisitProduct) {
            await addVisitProduct({
                uuid: uuidv4(),
                name: input.inputs.name.value,
                description: input.inputs.description.value,
                price: parseFloat(input.inputs.price.value || '0'),
                vatRate: parseFloat(input.inputs.vatRate.value || '0'),
                unitName: input.inputs.unitOfMeasure.value,
                type: input.inputs.type.value,
                unitCount: parseFloat(input.inputs.quantity.value || '0'),
                templateId: theTemplateId,
                employeeId: employeeId!,
            });
        }

        setAddProduct(false);
    };

    /**
     * Start edit Product.
     * Used to edit existing Products.
     */
    const startUpdate = (id: number, item: ProductResponse) => {
        setEditData({
            id: id,
            uuid: item.uuid!,
            name: item.name,
            description: item.description,
            price: item.price,
            type: item.type,
            unitOfMeasure: item.unitName,
            quantity: item.unitCount,
            vatRate: item.vatRate,
        });

        setEditProduct(true);
    };

    /**
     * If new Job update inputs list.
     * On detail mutate update Product to BE.
     */
    const onUpdate = async (input: IProductFormUpdate) => {
        if (setCreateProductsInput) {
            setCreateProductsInput(prev => {
                return prev.map((item, index) => {
                    if (index === editData!.id) {
                        return {
                            ...item,
                            name: input.inputs.name.value,
                            description: input.inputs.description.value,
                            price: parseFloat(input.inputs.price.value || '0'),
                            vatRate: parseFloat(input.inputs.vatRate.value || '0'),
                            unitName: input.inputs.unitOfMeasure.value,
                            type: input.inputs.type.value,
                            unitCount: parseFloat(input.inputs.quantity.value || '0'),
                        };
                    }

                    return item;
                });
            });
        }

        if (updateVisitProduct) {
            await updateVisitProduct({
                productId: editData!.id,
                productUuid: editData!.uuid,
                name: input.inputs.name.value,
                description: input.inputs.description.value,
                price: parseFloat(input.inputs.price.value || '0'),
                vatRate: parseFloat(input.inputs.vatRate.value || '0'),
                unitName: input.inputs.unitOfMeasure.value,
                unitCount: parseFloat(input.inputs.quantity.value || '0'),
            });
        }

        setEditProduct(false);
    };

    /**
     * Start delete Product.
     * Used to delete Products.
     */
    const startDelete = (repeats: JobUpdateRepeats, id: number) => {
        SetConfirmModal(appDataContext, {
            open: true,
            title: tt('deleteJobProduct.popup.title'),
            subtitle: tt('deleteJobProduct.popup.description'),
            cancelButtonText: tt('common.close'),
            confirmationButtonText: tt('deleteJobProduct.popup.confirm'),
            onConfirm: async () => {
                onDelete(repeats, id)
                    .catch(e => console.error(e));

                HideConfirmModal(appDataContext);
            },
            children: <></>,
        });
    };

    /**
     * If new Visit remove from inputs list.
     * On detail mutate delete Product to BE.
     */
    const onDelete = async (repeats: JobUpdateRepeats, id: number) => {
        if (setCreateProductsInput) {
            setCreateProductsInput(prev => {
                return prev.filter((item, index) => index !== id);
            });
        }

        if (deleteProductOfVisit) {
            await deleteProductOfVisit(repeats, id);
        }
    };

    const productsJSX = useMemo(() => {
        if (createProductsInput) {
            return [...createProductsInput]
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((item, index) => (
                    <ProductListItem
                        key={index}
                        isNewJobCreation={isNewJobCreation}
                        data={{
                            ...item,
                            id: index,
                        }}
                        files={files}
                        onUpdate={(id: number) => {
                            setEditData({
                                id: id,
                                uuid: item.uuid,
                                name: item.name,
                                description: item.description,
                                price: item.price,
                                type: item.type,
                                unitOfMeasure: item.unitName,
                                quantity: item.unitCount,
                                vatRate: item.vatRate,
                            });

                            setEditProduct(true);
                        }}
                        onDelete={(id: number) => onDelete(JobUpdateRepeats.Single, id)}
                        canEdit={true}
                    />
                ));
        }

        if (products) {
            return [...products]
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((item, index) => (
                    <ProductListItem
                        key={index}
                        isNewJobCreation={isNewJobCreation}
                        data={{
                            ...item,
                        }}
                        files={files}
                        employee={employees?.find(employee => employee.id === item.employeeId)}
                        onUpdate={(id: number) => {
                            if (setRecurringConfirmActionCallback && setRepeats && setRecurringEditModal) {
                                setRecurringConfirmActionCallback((repeats: JobUpdateRepeats) => startUpdate(id, item));

                                if (isRepeating) {
                                    setRepeats(JobUpdateRepeats.Single);
                                    setRecurringEditModal(true);
                                } else {
                                    startUpdate(id, item);
                                }
                            } else {
                                startUpdate(id, item);
                            }
                        }}
                        onDelete={(id: number) => {
                            if (setRecurringConfirmActionCallback && setRepeats && setRecurringEditModal) {
                                setRecurringConfirmActionCallback((repeats: JobUpdateRepeats) => startDelete(repeats, id));

                                if (isRepeating) {
                                    setRepeats(JobUpdateRepeats.Single);
                                    setRecurringEditModal(true);
                                } else {
                                    startDelete(JobUpdateRepeats.Single, id);
                                }
                            } else {
                                startDelete(JobUpdateRepeats.Single, id);
                            }
                        }}
                        canEdit={canEdit}
                    />
                ));
        }

        return undefined;
    }, [createProductsInput, products, employees, multiplier, canEdit, isNewJobCreation, files, storage.publicUrlsForFiles]);

    const productsTotalsJSX = useMemo(() => {
        const linesPerVatRate: {
            vatRate: number;
            totalExclVat: number;
            totalVat: number;
        }[] = [];
        if (companyHasVat) {
            if (createProductsInput) {
                createProductsInput
                    .forEach(item => {
                        const thePrice = item.price ?? 0;
                        const theQuantity = item.unitCount ?? 0;
                        const theVatRate = item.vatRate ?? 0;
                        const theTotalExclVat = thePrice * theQuantity;
                        const theTotalVat = (thePrice * theQuantity) * (theVatRate / 100);

                        const theExistingLine = linesPerVatRate.find(line => line.vatRate === theVatRate);

                        if (theExistingLine) {
                            theExistingLine.totalExclVat += theTotalExclVat;
                            theExistingLine.totalVat += theTotalVat;
                        } else {
                            linesPerVatRate.push({
                                vatRate: theVatRate,
                                totalExclVat: theTotalExclVat,
                                totalVat: theTotalVat,
                            });
                        }
                    });
            }

            if (products) {
                products
                    .forEach(item => {
                        const thePrice = item.price ?? 0;
                        const theQuantity = item.unitCount ?? 0;
                        const theVatRate = item.vatRate ?? 0;
                        const theTotalExclVat = thePrice * theQuantity;
                        const theTotalVat = (thePrice * theQuantity) * (theVatRate / 100);

                        const theExistingLine = linesPerVatRate.find(line => line.vatRate === theVatRate);

                        if (theExistingLine) {
                            theExistingLine.totalExclVat += theTotalExclVat;
                            theExistingLine.totalVat += theTotalVat;
                        } else {
                            linesPerVatRate.push({
                                vatRate: theVatRate,
                                totalExclVat: theTotalExclVat,
                                totalVat: theTotalVat,
                            });
                        }
                    });
            }

            if (linesPerVatRate.length > 0) {
                return linesPerVatRate.map((item, index) => (
                    <Box key={index} className={classes.singleRow}>
                        <Box className={classes.singleCell}>
                            <Typography className={classes.value}>{`${item.vatRate || 0} %`}</Typography>
                        </Box>
                        <Box className={classes.singleCell}>
                            <Typography
                                className={classes.value}>{`${multiplier ? `${multiplier}   x   ` : ''}${PriceDisplay(item.totalExclVat, currency, language, true)}`}</Typography>
                        </Box>
                        <Box className={classes.singleCell}>
                            <Typography
                                className={classes.value}>{`${multiplier ? `${multiplier}   x   ` : ''}${PriceDisplay(item.totalVat, currency, language, true)}`}</Typography>
                        </Box>
                    </Box>
                ));
            }
        }

        return undefined;
    }, [createProductsInput, products, multiplier]);

    const totalSectionJSX = (
        <>
            {productsJSX && productsJSX.length > 0 ? (
                <Box className={classes.totalSectionContainerOuter}>
                    <Box className={classes.totalSectionContainerInner}>
                        {companyHasVat ? <Box className={classes.singleRow}>
                            <Box className={classes.singleCell}>
                                <Typography className={classes.label}>
                                    {tt('servicesSection.overview.vatRate')}
                                </Typography>
                            </Box>
                            <Box className={classes.singleCell}>
                                <Typography className={classes.label}>
                                    {tt('servicesSection.overview.base')}
                                </Typography>
                            </Box>
                            <Box className={classes.singleCell}>
                                <Typography className={classes.label}>
                                    {tt('servicesSection.overview.vat')}
                                </Typography>
                            </Box>
                        </Box> : null}

                        {productsTotalsJSX}

                        <Box
                            className={classes.totalValueContainer}>
                            <Typography
                                className={classes.total}>{total}</Typography>
                        </Box>
                    </Box>
                </Box>
            ) : null}
        </>
    );

    return (
        <>
            <HeadlineWithButton
                title={tt('products.section.title')}
                iconJSX={<Icons8Plus/>}
                onClick={!isVisitDetail || canEdit ? () => {
                    if (setRecurringConfirmActionCallback && setRepeats && setRecurringEditModal) {
                        setRecurringConfirmActionCallback((repeats: JobUpdateRepeats) => setAddProduct(true));

                        if (isRepeating) {
                            setRepeats(JobUpdateRepeats.Single);
                            setRecurringEditModal(true);
                        } else {
                            setAddProduct(true);
                        }
                    } else {
                        setAddProduct(true);
                    }
                } : undefined}
                buttonText={tt('products.section.addItem')}
                permission={kPermissionsProducts}
                requiredPermissions={[kActionCreate]}
            />

            {productsJSX}

            {totalSectionJSX}

            <AddProductModalBottomSheet
                open={addProduct}
                setOpen={setAddProduct}
                mutateLoading={mutateLoading || loadingCreate}
                onSubmit={onSubmit}
            />

            {editData ? (
                <EditProductModalBottomSheet
                    open={editProduct}
                    setOpen={setEditProduct}
                    data={editData}
                    mutateLoading={mutateLoading || loadingCreate}
                    onSubmit={onUpdate}
                />
            ) : undefined}
        </>
    );
}
