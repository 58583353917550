import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8ProductProps {
    className?: string;
}

export default function Icons8Product(props: IIcons8ProductProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 8.5 5 C 7.1364058 5 6 6.1364058 6 7.5 L 6 14.5 C 6 15.692499 6.8698775 16.712342 8 16.949219 L 8 38.5 C 8 40.967501 10.032499 43 12.5 43 L 35.5 43 C 37.967501 43 40 40.967501 40 38.5 L 40 16.949219 C 41.130122 16.712342 42 15.692499 42 14.5 L 42 7.5 C 42 6.1364058 40.863594 5 39.5 5 L 8.5 5 z M 9 8 L 39 8 L 39 14 L 38.5 14 L 9.5 14 L 9 14 L 9 8 z M 11 17 L 37 17 L 37 38.5 C 37 39.346499 36.346499 40 35.5 40 L 12.5 40 C 11.653501 40 11 39.346499 11 38.5 L 11 17 z M 19.5 21 A 1.50015 1.50015 0 1 0 19.5 24 L 28.5 24 A 1.50015 1.50015 0 1 0 28.5 21 L 19.5 21 z"/>
            </svg>
        </span>
    );
}



