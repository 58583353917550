import {makeStyles} from "tss-react/mui";
import {Box, Theme, Typography} from "@mui/material";
import React, {Dispatch, SetStateAction} from "react";
import {tt} from "../../../core/Localization";
import ModalBottomSheet from "../ModalBottomSheet";
import {FlavorByEnvironment} from "../../../flavor-config";
import BottomSheetModalAppbar from "./BottomSheetModalAppbar";
import {EmployeeJoinedUserResponse, EmployeeRole} from "../../../generated/graphql/graphql";
import Icons8QRCode from "../../../icons/Icons8QRCode";
import {UserFullName} from "../../../service/UserService";
import AppIconButton from "../buttons/AppIconButton";
import {SuccessToast} from "../../../service/ToastService";
import Icons8Copy from "../../../icons/Icons8Copy";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import AppButton from "../buttons/AppButton";
import {invitationInstructionsRoute} from "../../screens/invitation/InvitationInstructionsScreen";

export const useStyles = makeStyles()((theme: Theme) => ({
    qrContainer: {
        width: 200,
        margin: "auto",
    },
    removeHorizontalMargin: {
        marginRight: -16,
        marginLeft: -16,
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
        textAlign: "center",
        strong: {
            fontWeight: 700,
        }
    },
    text: {
        fontWeight: 500,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        textAlign: "center",
    },
    codeSpan: {
        cursor: "pointer",
        color: kAppColors.primary.main,
        fontWeight: "bold",
    },
    link: {
        textAlign: "center",
        color: kAppColors.primary.main,
        fontWeight: "bold",
    },
    verticalLine: {
        width: 1,
        background: kAppColors.border(theme.palette.mode === "dark"),
        height: 80,
        margin: "auto",
        marginTop: 16,
        marginBottom: 16,
        "@media (max-width: 767px)": {
            height: 40,
        },
    },
    contentContainer: {
        width: '100%',
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    check: {
        fontSize: 20,
    },
    buttons: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        flexFlow: "wrap",
        '.MuiButton-contained': {
            borderRadius: 100,
            height: 44,
        },
        'button': {
            flexShrink: 0,
            marginLeft: 4,
            marginRight: 4,
            marginBottom: 8
        }
    },
}));


export interface IInviteWorkerModalBottomSheetProps {
    setInviteCode: Dispatch<SetStateAction<string | undefined>>;
    inviteCode: string | undefined;
    workerData: EmployeeJoinedUserResponse;
    setOpenQRCode: Dispatch<SetStateAction<boolean>>;
}

export default function InviteWorkerModalBottomSheet(props: IInviteWorkerModalBottomSheetProps) {
    const {
        setInviteCode,
        inviteCode,
        workerData,
        setOpenQRCode,
    } = props;

    const {classes, cx} = useStyles();

    const fullName = UserFullName(workerData?.name || workerData?.user?.name, workerData?.surname || workerData?.user?.surname);

    const instructionsUrl = `${FlavorByEnvironment()!.webUrl}${invitationInstructionsRoute(Object.keys(EmployeeRole).indexOf(workerData.role || EmployeeRole.None), inviteCode!)}`;

    const instructionsWithCode = `${workerData?.role === EmployeeRole.Worker ? tt('inviteWorkerModal.multilineTextfield.content') : tt('inviteWorkerModal.multilineTextfield.content.notEmployee')} ${instructionsUrl}`;

    const step1JSX = <>
        <Typography className={classes.title}>{tt('inviteWorkerModal.step1.downloadApp')}</Typography>
        <Typography className={classes.text}>{tt('inviteWorkerModal.step1.description')}
            <span
                onClick={() => {
                    navigator.clipboard.writeText(inviteCode!);
                    SuccessToast(tt('common.copiedToClipboard'));
                }}
                className={classes.codeSpan}>&nbsp;{inviteCode}</span></Typography>
        <Box pb={2}/>
        <Box className={classes.buttons}>
            <AppButton
                variant={"contained"}
                onClick={() => {
                    navigator.clipboard.writeText(instructionsWithCode);
                    SuccessToast(tt('common.copiedToClipboard'));
                }}
            >
                <Icons8Copy/>
                <span>{tt('inviteWorkerModal.step1.button.copyInvitation')}</span>
            </AppButton>
            <AppIconButton
                tooltip={tt('inviteWorkerModal.step1.qrCodeButton.tooltip')}
                variant={"greyBg"}
                onClick={() => setOpenQRCode(true)}
            >
                <Icons8QRCode/>
            </AppIconButton>
        </Box>
    </>;

    return (
        <ModalBottomSheet
            blurBackdrop={true}
            hideHeader={true}
            open={!!inviteCode}
            setOpen={(open) => setInviteCode(open ? inviteCode : undefined)}
            children={
                <>
                    <BottomSheetModalAppbar
                        title={tt('inviteWorkerModal.title')}
                        noBorderBottom={true}
                        onClose={() => setInviteCode(undefined)}
                    ></BottomSheetModalAppbar>

                    <Box className={classes.contentContainer}>
                        <Box pb={2}/>

                        {step1JSX}

                        <Box className={classes.verticalLine}/>
                        <Typography
                            className={classes.title}
                            dangerouslySetInnerHTML={{__html: `<p>${tt('inviteWorkerModal.step2.sendInvitationTo').replace('$fullname', `<strong>${fullName}</strong>`)}</p>`}}
                        ></Typography>
                        <Box className={classes.verticalLine}/>
                        <Typography
                            className={classes.title}
                            dangerouslySetInnerHTML={{__html: `<p>${tt('inviteWorkerModal.step3.followsInvitationInstructions').replace('$firstname', `<strong>${workerData?.name || workerData?.user?.name}</strong>`)}</p>`}}
                        ></Typography>
                        <a className={classes.link} href={instructionsUrl}
                           target="_blank">{tt("inviteWorkerModal.step3.link.seeInstructions")}</a>
                        <Box pb={1}/>

                        <Box className={classes.verticalLine}/>
                        <Typography className={classes.title}>{tt('invitation.screen.step4.done')}
                            <span className={classes.check}>&nbsp;✅</span>
                        </Typography>
                        <Typography className={classes.text}>{tt('inviteWorkerModal.step4.description')}</Typography>

                        <Box pb={4}/>
                    </Box>
                </>
            }
        />
    );
}
