import {Box, Divider, Grid, Skeleton, Theme} from "@mui/material";
import React from "react";
import AppListItemShimmer from "./AppListItemShimmer";
import {makeStyles} from "tss-react/mui";


export const useStyles = makeStyles()((theme: Theme) => ({
    rowContainer: {
        paddingLeft: 16,
        paddingBottom: 8,
        paddingTop: 8,
    },
    smallRowContainer: {
        paddingLeft: 16,
        paddingBottom: 11,
        paddingTop: 11,
        alignItems: "center",
    },
    tabContainer: {
        height: 50,
        paddingLeft: 16,
        display: 'flex',
        alignItems: "center",
    }
}));


export default function DetailScreenShimmer() {
    const {classes} = useStyles();

    return (
        <>
            <AppListItemShimmer/>
            <Box sx={{mb: 2}}/>
            <Divider/>
            <Grid container className={classes.rowContainer}>
                <Skeleton variant={"rectangular"} width={90} height={57}/>
                <Box pl={1}/>
                <Skeleton variant={"rectangular"} width={90} height={57}/>
                <Box pl={1}/>
                <Skeleton variant={"rectangular"} width={90} height={57}/>
            </Grid>
            <Divider/>

            <Box className={classes.tabContainer}>
                <Skeleton variant={"rectangular"} width={80} height={20}/>
            </Box>
            <Divider/>

            <Grid container className={classes.smallRowContainer}>
                <Skeleton variant={"circular"} width={28} height={28}/>
                <Box pl={1}/>
                <Skeleton variant={"rectangular"} width={200} height={16}/>
            </Grid>
            <Grid container className={classes.smallRowContainer}>
                <Skeleton variant={"circular"} width={28} height={28}/>
                <Box pl={1}/>
                <Skeleton variant={"rectangular"} width={160} height={16}/>
            </Grid>
            <Grid container className={classes.smallRowContainer}>
                <Skeleton variant={"circular"} width={28} height={28}/>
                <Box pl={1}/>
                <Skeleton variant={"rectangular"} width={200} height={16}/>
            </Grid>

            <Box pb={1.25}/>
        </>
    );

}