import AppButton from "../buttons/AppButton";
import React, {useRef} from "react";
import {genericStyles} from "../../../styles/UtilStyles";
import AppIconButton from "../buttons/AppIconButton";

export interface IFileUploadButtonProps {
    iconJSX?: React.ReactNode;
    text?: string;
    fileAccept?: string;
    fileChosen: (e: React.ChangeEvent<HTMLInputElement>) => void;
    multiple?: boolean;
    tooltip?: string;
}

/**
 * Component for uploading files to the Firebase Storage.
 */
export default function FileUploadButton(props: IFileUploadButtonProps) {
    const {iconJSX, text, fileAccept, fileChosen, multiple, tooltip} = props;

    const {classes: genericClasses} = genericStyles();

    const inputRef = useRef<HTMLInputElement>(null);
    const accept = fileAccept || "image/*";

    /**
     * Choose file button clicked.
     */
    const onCLick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const buttonJSX = iconJSX ? (
        <AppIconButton onClick={onCLick} tooltip={tooltip} color="primary">
            {iconJSX}
        </AppIconButton>
    ) : (
        <AppButton onClick={onCLick} variant={"textThin"} tooltip={tooltip}>{text}</AppButton>
    );

    return (
        <>
            <input ref={inputRef} className={genericClasses.hidden} type="file" accept={accept} onChange={fileChosen} multiple={multiple}/>

            {buttonJSX}
        </>
    );
}
