import React, {ReactNode} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, ListItem, ListItemAvatar, ListItemText, Theme} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import AppAvatar from "../AppAvatar";


const useStyles = makeStyles()((theme: Theme) => ({
    listItem: {
        minHeight: 0,
        paddingLeft: 16,
        paddingRight: 16,
        ':hover': {
            background: kAppColors.hoverColor.grey(theme.palette.mode === "dark"),
            cursor: "pointer",
        },
        marginBottom: 0,
        alignItems: "start",
    },
    noMarginBottom: {
        marginBottom: 0,
    },
    noHover: {
        ':hover': {
            background: "transparent",
            cursor: "default",
        }
    },
    text: {
        '.MuiTypography-body1': {
            fontSize: 13,
            whiteSpace: "pre-wrap",
        },
        '.MuiListItemText-secondary': {
            whiteSpace: "pre-wrap",
            fontSize: 12,
        },
    },
    listItemAvatar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 27,
        width: 27,
        'span, svg': {
            height: 18,
            width: 18,
        }
    },
    textContainer: {
        paddingRight: 8,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        flexGrow: 1,
        margin: 0,
    },
    mainContainer: {
        flexGrow: 1,
    },
}));

export interface ISmallAppListItem {
    title: string,
    className?: string,
    description?: string | NullOrUndefined,
    noMarginBottom?: boolean,
    onClick?: React.MouseEventHandler<HTMLLIElement>,
    customAvatar?: ReactNode,
}

export default function SmallAppListItem(props: ISmallAppListItem) {
    const {
        title,
        description,
        onClick,
        noMarginBottom,
        className,
        customAvatar
    } = props;


    const {classes, cx} = useStyles();

    const listItemClass = cx(
        classes.listItem,
        noMarginBottom ? classes.noMarginBottom : undefined,
        onClick == null ? classes.noHover : undefined,
        className,
    );

    return (
        <ListItem onClick={onClick} className={listItemClass}>
            <ListItemAvatar className={classes.listItemAvatar}>
                {customAvatar}
            </ListItemAvatar>

            <Box className={classes.textContainer}>
                <ListItemText
                    className={classes.text}
                    primary={title}
                    secondary={description}/>
            </Box>
        </ListItem>
    )
}

