import {VisitDetailResponse} from "../../../../../generated/graphql/graphql";
import AppChip from "../../../chips/AppChip";
import AppAvatar from "../../../AppAvatar";
import {tt} from "../../../../../core/Localization";
import React, {useContext} from "react";
import {JobNameOrSequenceId} from "../../../../../service/JobService";
import {UserFullName, UserPhotoUrl} from "../../../../../service/UserService";
import {AppDataContext} from "../../../../../AppData";

export interface IVisitDetailJobChipProps {
    data?: VisitDetailResponse | NullOrUndefined;
    onNavigateToJob?: VoidFunction;
}

/**
 * Component for Job Chip on Visit Detail.
 */
export default function VisitDetailJobChip(props: IVisitDetailJobChipProps) {
    const {data, onNavigateToJob} = props;

    const appDataContext = useContext(AppDataContext);
    const {storage} = appDataContext;

    if (!data) {
        return null;
    }

    const job = data.job;
    const responsiblePerson = data.responsiblePerson;

    return (
            <AppChip
                onClick={onNavigateToJob}
                chipstyle={"secondaryThinSmallerRadius"}
                icon={
                    <AppAvatar
                        img={
                            UserPhotoUrl(responsiblePerson.user, data?.files, storage.publicUrlsForFiles)
                        }
                        tooltip={UserFullName(responsiblePerson.name || responsiblePerson.user?.name, responsiblePerson.surname || responsiblePerson.user?.surname)}
                        tooltipDescription={tt('common.responsiblePerson')}
                        title={UserFullName(responsiblePerson.name || responsiblePerson.user?.name, responsiblePerson.surname || responsiblePerson.user?.surname)}
                        variant={"tiny"}
                    />
                }
                label={`${tt('common.job')} ${JobNameOrSequenceId(job)}`}
            />
    );
}
