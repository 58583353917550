import {iconUseStyles} from "../styles/IconStyles";


export interface IWorkerIconProps {
    className?: string;
}

export default function WorkerIcon(props: IWorkerIconProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px"
                 preserveAspectRatio="xMidYMid meet" focusable="false">
                <path
                    d="M 23.976562,0.97851562 A 1.50015,1.50015 0 0 0 22.5,2.5 V 3.3046875 C 17.861974,3.971099 14.284378,7.4320708 13.40625,12 H 12.5 a 1.50015,1.50015 0 1 0 0,3 h 0.703125 C 13.735276,20.567854 18.297007,25 24,25 29.702993,25 34.264724,20.567854 34.796875,15 H 35.5 a 1.50015,1.50015 0 1 0 0,-3 H 34.59375 C 33.715622,7.4320708 30.138026,3.971099 25.5,3.3046875 V 2.5 A 1.50015,1.50015 0 0 0 23.976562,0.97851562 Z M 22.5,6.3007812 v 1.2089844 a 1.50015,1.50015 0 1 0 3,0 V 6.3007812 C 28.513631,6.8890984 30.825571,9.0584222 31.597656,12 H 16.402344 C 17.174429,9.0584222 19.486369,6.8890984 22.5,6.3007812 Z M 16.201172,15 H 31.798828 C 31.289478,18.936283 28.080701,22 24,22 19.919299,22 16.710522,18.936283 16.201172,15 Z M 12.5,29 C 10.032486,29 8,31.032486 8,33.5 v 1.199219 c 0,2.942744 1.8661333,5.581282 4.710938,7.394531 2.582688,1.646179 6.111064,2.583673 10.130859,2.763672 A 1.50015,1.50015 0 0 0 23.5,45 h 0.5 0.5 a 1.50015,1.50015 0 0 0 0.65625,-0.142578 C 29.176916,44.677687 32.705939,43.740206 35.289062,42.09375 38.133867,40.280501 40,37.641963 40,34.699219 V 33.5 C 40,31.032486 37.967514,29 35.5,29 Z m 0,3 h 23 c 0.846194,0 1.5,0.653806 1.5,1.5 v 1.199219 c 0,1.667256 -1.074023,3.430983 -3.324219,4.865234 C 31.425585,40.998705 28.066961,42 24,42 19.933039,42 16.574415,40.998705 14.324219,39.564453 12.074023,38.130202 11,36.366475 11,34.699219 V 33.5 C 11,32.653806 11.653806,32 12.5,32 Z"></path>
            </svg>
        </span>
    );
}
