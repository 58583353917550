import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8CheckAllProps {
    className?: string;
}

export default function Icons8CheckAll(props: IIcons8CheckAllProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 8.5 4 C 6.0324991 4 4 6.0324991 4 8.5 L 4 34.5 C 4 36.967501 6.0324991 39 8.5 39 L 34.5 39 C 36.967501 39 39 36.967501 39 34.5 L 39 8.5 C 39 6.0324991 36.967501 4 34.5 4 L 8.5 4 z M 8.5 7 L 34.5 7 C 35.346499 7 36 7.6535009 36 8.5 L 36 34.5 C 36 35.346499 35.346499 36 34.5 36 L 8.5 36 C 7.6535009 36 7 35.346499 7 34.5 L 7 8.5 C 7 7.6535009 7.6535009 7 8.5 7 z M 41 9.2753906 L 41 35.5 C 41 38.532 38.533 41 35.5 41 L 9.2753906 41 C 9.8963906 42.742 11.547 44 13.5 44 L 35.5 44 C 40.187 44 44 40.187 44 35.5 L 44 13.5 C 44 11.547 42.742 9.8973906 41 9.2753906 z M 28.470703 14.986328 A 1.50015 1.50015 0 0 0 27.439453 15.439453 L 19.5 23.378906 L 16.560547 20.439453 A 1.50015 1.50015 0 1 0 14.439453 22.560547 L 18.439453 26.560547 A 1.50015 1.50015 0 0 0 20.560547 26.560547 L 29.560547 17.560547 A 1.50015 1.50015 0 0 0 28.470703 14.986328 z"/>
            </svg>
        </span>
    );
}




















