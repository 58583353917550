import {iconUseStyles} from "../styles/IconStyles";


export interface IIcons8ReplaceProps {
    className?: string;
}


export default function Icons8Replace(props: IIcons8ReplaceProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26.675" viewBox="0 0 26 26.675">
                <path fill="currentColor" id="icons8-replace"
                      d="M.841,0A.855.855,0,0,0,0,.867V4.855a.855.855,0,0,0,.854.855H4.843A.855.855,0,1,0,4.843,4H2.755a8.519,8.519,0,0,1,11.052-.466.855.855,0,1,0,1.054-1.346,10.246,10.246,0,0,0-13.152.442V.867A.855.855,0,0,0,.841,0ZM12.249,14.825a.855.855,0,1,0,0,1.709h2.086A8.517,8.517,0,0,1,3.284,17,.855.855,0,1,0,2.23,18.346,10.246,10.246,0,0,0,15.382,17.9v1.763a.855.855,0,1,0,1.709,0V15.679a.855.855,0,0,0-.854-.855Z"
                      transform="matrix(0.799, -0.602, 0.602, 0.799, 0, 10.286)"/>
            </svg>
        </span>
    );
}

