import {makeStyles} from "tss-react/mui";
import {InputAdornment, TextField, Theme} from "@mui/material";
import {DatePickerProps} from "./InputProps";
import {useStyles as formUseStyles} from "./FormBuilder";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {DateTime} from 'luxon';
import GreyLabel from "../decorations/GreyLabel";
import {useEffect, useRef} from "react";
import Debouncer from "../../../utils/Debouncer";
import {kUserInputDebounce} from "../../../core/constants";

const useStyles = makeStyles()((theme: Theme) => ({
    datePicker: {
        position: 'relative',
    },
    clear: {
        display: 'block',
        position: 'absolute',
        top: 26,
        right: 48,
    },
    topLabelPadding: {
        paddingTop: 16,
        paddingBottom: 16,
    },
}));

/**
 * Form component for Date input.
 */
export default function FormDatePicker(props: DatePickerProps) {
    const {
        className,
        hidden,
        index,
        label,
        value,
        error,
        errorMessage,
        updateValue,
        dateFormat,
        disablePast,
        topLabel,
        disabled,
    } = props;

    const {classes: formClasses} = formUseStyles();
    const {classes, cx} = useStyles();

    const updateValueTimeout = useRef(new Debouncer(kUserInputDebounce));

    useEffect(() => {
        return () => {
            updateValueTimeout.current?.dispose();
        }
    }, []);

    /**
     * OnChange update data of this field.
     */
    const OnChange = (date: any) => {
        const dateTime = date as DateTime;

        if (date && !date.invalid) {
            updateValueTimeout.current?.dispose();

            updateValue(index, dateTime.toJSDate().getTime());
        } else {
            updateValueTimeout.current!.run(() => {
                updateValue(index, value);
            });
        }
    };

    const theValue = value ? new Date(value) : null;

    return (
        <div className={cx(classes.datePicker, className, (hidden ? formClasses.hidden : undefined))}>
            {topLabel ? <GreyLabel className={classes.topLabelPadding} text={topLabel}/> : null}
            <DatePicker
                DialogProps={{
                    style: {
                        zIndex: 1400
                    }
                }}
                PopperProps={
                    {
                        style: {zIndex: 1400}
                    }
                }
                label={label}
                inputFormat={dateFormat}
                value={theValue}
                renderInput={(params) => {
                    return (<TextField
                        {...params}
                        InputProps={
                            {
                                ...params.InputProps,
                                endAdornment:
                                    params.InputProps?.endAdornment ||
                                    <InputAdornment position={"end"}>
                                        <svg
                                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false" aria-hidden="true" viewBox="0 0 24 24"
                                            data-testid="CalendarIcon">
                                            <path
                                                d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"></path>
                                        </svg>
                                    </InputAdornment>
                            }
                        }
                        error={error}
                        variant="filled"
                        helperText={error ? errorMessage : undefined}
                        fullWidth={true} margin="none"/>);
                }}
                onChange={OnChange} disablePast={disablePast}
                disabled={disabled}
            />
        </div>
    );
}
