import React from "react";
import MailIcon from "../icons/MailIcon";
import {LoginProvider} from "../generated/graphql/graphql";
import Icons8Google from "../icons/Icons8Google";
import Icons8Facebook from "../icons/Icons8Facebook";
import Icons8AppleLogo from "../icons/Icons8AppleLogo";
import LoginMethodEmailEnvelopeIcon from "../icons/LoginMethodEmailEnvelopeIcon";

/**
 * Get svg icon for an Industry by its name.
 */
export function getLoginProviderIcon(loginProvider: LoginProvider) {
    let icon = <MailIcon/>;

    switch (loginProvider) {
        case LoginProvider.Apple:
            icon = <Icons8AppleLogo/>;
            break;
        case LoginProvider.Facebook:
            icon = <Icons8Facebook/>;
            break;
        case LoginProvider.Google:
            icon = <Icons8Google/>;
            break;
        case LoginProvider.Email:
            icon = <LoginMethodEmailEnvelopeIcon/>;
            break;
        case LoginProvider.None:
            icon = <LoginMethodEmailEnvelopeIcon/>;
            break;
        default:
            return <LoginMethodEmailEnvelopeIcon/>;
    }

    return icon;
}