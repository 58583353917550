import {iconUseStyles} from "../styles/IconStyles";

export interface IMediaIconProps {
    className?: string;
}

export default function MediaIcon(props: IMediaIconProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg id="Vrstva_2" data-name="Vrstva 2" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 267.33 240"
                 width="24px" height="24px">
            <g id="Media">
                <g>
                    <path strokeWidth={0} fill="currentColor"
                          d="M246.55,115.33h-83.1c-11.46,0-20.78,9.32-20.78,20.78v83.1c0,11.46,9.32,20.78,20.78,20.78h83.1c11.46,0,20.78-9.32,20.78-20.78v-83.1c0-11.46-9.32-20.78-20.78-20.78ZM168.52,225.03l35.8-34.63c.41-.39.95-.4,1.36,0l35.79,34.63h-72.95ZM252.36,214.73l-36.27-35.09c-2.97-2.87-6.91-4.46-11.09-4.46s-8.12,1.58-11.09,4.46l-36.27,35.09v-78.62c0-3.26,2.55-5.81,5.81-5.81h83.1c3.26,0,5.81,2.55,5.81,5.81v78.62Z"/>
                    <path strokeWidth={0} fill="currentColor"
                          d="M226.3,138.32c-5.13,0-9.89,2.05-13.07,5.63-2.83,3.18-4.38,7.38-4.38,11.83s1.56,8.65,4.38,11.83c3.18,3.58,7.95,5.63,13.07,5.63s9.89-2.05,13.08-5.63c2.83-3.18,4.38-7.38,4.38-11.83s-1.56-8.65-4.38-11.83c-3.18-3.58-7.95-5.63-13.07-5.63ZM226.3,161.5c-3.16,0-5.72-2.56-5.72-5.72s2.56-5.72,5.72-5.72,5.72,2.56,5.72,5.72-2.56,5.72-5.72,5.72Z"/>
                    <path strokeWidth={0} fill="currentColor"
                          d="M73.4,183.14v-83.64h-3.8l-11.11,8.23v75.41c0,13.25,10.1,24.16,22.36,24.16h52.15v-16.11h-52.15c-4.2,0-7.45-3.51-7.45-8.05Z"/>
                    <path strokeWidth={0} fill="currentColor"
                          d="M160.38,88.02h42.24v17.48h14.91v-24.93c0-1.98-.79-3.87-2.18-5.27l-.08-.08L150.74,10.68c-1.4-1.4-3.29-2.18-5.27-2.18h-19.47v14.91h12.01v42.24c0,12.26,10.1,22.36,22.36,22.36ZM152.92,33.95l39.16,39.16h-31.7c-4.21,0-7.46-3.25-7.46-7.46v-31.7Z"/>
                    <path strokeWidth={0} fill="currentColor"
                          d="M69.12,46.12h-35.4c-1.72-.01-3.4.64-4.65,1.86-1.26,1.22-1.96,2.86-1.99,4.62-.02,1.75.63,3.41,1.86,4.67,1.22,1.26,2.86,1.96,4.62,1.99h35.38s.06,0,.1,0c1.72,0,3.34-.66,4.58-1.86,1.26-1.22,1.97-2.86,1.99-4.62.05-3.62-2.85-6.61-6.48-6.66Z"/>
                    <path strokeWidth={0} fill="currentColor"
                          d="M79.97,27.13h-46.25c-1.72-.04-3.4.64-4.65,1.86-1.26,1.22-1.96,2.86-1.99,4.62-.02,1.75.63,3.41,1.86,4.67,1.22,1.26,2.86,1.96,4.59,1.99.05,0,.09,0,.13,0,.03,0,.05,0,.05,0h46.09c1.76.01,3.41-.64,4.67-1.86,1.26-1.22,1.97-2.86,1.99-4.62.05-3.62-2.85-6.61-6.48-6.66Z"/>
                    <path strokeWidth={0} fill="currentColor"
                          d="M93.39,86.39c11.1,0,20.14-9.03,20.14-20.13V20.14c0-11.1-9.03-20.14-20.14-20.14H20.13C9.03,0,0,9.03,0,20.14v46.12c0,11.1,9.03,20.13,20.13,20.13h1.57v12.42c0,3.42,2,6.54,5.21,8.15,1.39.69,2.87,1.04,4.32,1.04,1.91,0,3.77-.59,5.33-1.76l26.46-19.85h30.37ZM56.89,74.57l-22.04,16.53v-11.28c0-3.62-2.95-6.57-6.57-6.57h-8.14c-3.92,0-7-3.07-7-7V20.14c0-3.92,3.07-7,7-7h73.25c3.92,0,7,3.07,7,7v46.12c0,3.92-3.07,7-7,7h-32.56c-1.41,0-2.81.47-3.94,1.32Z"/>
                </g>
            </g>
            </svg>
        </span>
    );
}

