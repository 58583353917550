import {makeStyles} from "tss-react/mui";
import {Avatar, Theme, Tooltip, Typography} from "@mui/material";
import {kAppColors} from "../../styles/AppThemeProcessor";
import {getInitials} from "../../utils/Utils";
import React from "react";
import StyledTooltipContent from "./decorations/StyledTooltipContent";

export const useStyles = makeStyles()((theme: Theme) => ({
    avatar: {
        height: 56,
        width: 56,
        border: `1px solid ${kAppColors.border(theme.palette.mode === "dark")}`,
        background: "transparent",
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        'p': {
            whiteSpace: "nowrap",
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
        }
    },
    initials: {
        fontSize: 22,
        textTransform: "uppercase",
        letterSpacing: -1,
    },
    initialsSmall: {
        fontSize: 17,
    },
    avatarSmall: {
        height: 40,
        width: 40,
    },
    initialsExtraSmall: {
        fontSize: 12,
    },
    avatarExtraSmall: {
        height: 28,
        width: 28,
    },
    initialsTiny: {
        fontSize: 12,
    },
    tiny: {
        height: 24,
        width: 24,
    },
    avatarNoBorder: {
        border: "none !important",
    },
}));

export interface IAppAvatarProps {
    title?: string | NullOrUndefined;
    img?: string | NullOrUndefined;
    icon?: React.ReactNode;
    variant?: 'tiny' | 'extraSmall' | 'small' | 'regular' | undefined;
    className?: string,
    tooltip?: string,
    tooltipDescription?: string,
    tooltipRedDescription?: string,
}

export default function AppAvatar(props: IAppAvatarProps) {
    const {img, className, title, variant, icon, tooltip, tooltipRedDescription, tooltipDescription} = props;

    const {classes, cx} = useStyles();

    const theAvatarClass = cx(
        className,
        classes.avatar,
        variant === "small" ? classes.avatarSmall : undefined,
        variant === "extraSmall" ? classes.avatarExtraSmall : undefined,
        variant === "tiny" ? classes.tiny : undefined,
        img != null ? classes.avatarNoBorder : undefined,
    );

    const theInitialsClass = cx(
        classes.initials,
        variant === "small" ? classes.initialsSmall : undefined,
        variant === "extraSmall" ? classes.initialsExtraSmall : undefined,
        variant === "tiny" ? classes.initialsTiny : undefined,
    );

    const avatarInitialsJSX = img == null && icon == null && title != null ?
        <Typography
            className={theInitialsClass}>{getInitials(title)}</Typography> : null;

    const avatarJSX = <Avatar
        className={theAvatarClass}
        alt={title || undefined}
        src={img || undefined}>
        {icon != null ? icon : avatarInitialsJSX}
    </Avatar>;

    return (
        <>
            {tooltip != null || tooltipDescription || tooltipRedDescription ?
                <Tooltip
                    title={<StyledTooltipContent
                        title={tooltip}
                        description={tooltipDescription}
                        redDescription={tooltipRedDescription}
                    />}
                >{avatarJSX}</Tooltip> : avatarJSX}
        </>
    );
}
