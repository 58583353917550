import {makeStyles} from "tss-react/mui";
import {Box, Theme, Typography} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import React from "react";

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        textAlign: "center",
    },
    title: {
        fontWeight: 700,
        color: kAppColors.text.tertiary(theme.palette.mode === "dark"),
        fontSize: 14,

    },
    description: {
        fontSize: 14,
        color: kAppColors.text.tertiary(theme.palette.mode === "dark")
    },
    redDescription: {
        fontSize: 14,
        color: kAppColors.red.main,
    }
}));

export interface IStyledTooltipContentProps {
    title?: string | NullOrUndefined;
    redDescription?: string | NullOrUndefined;
    description?: string | NullOrUndefined;
}

export default function StyledTooltipContent(props: IStyledTooltipContentProps) {
    const {title, description, redDescription} = props;

    const {classes} = useStyles();

    return (
        <Box className={classes.container}>
            {title ? <Typography className={classes.title}>{title}</Typography> : null}
            {description ? <Typography className={classes.description}>{description}</Typography> : null}
            {redDescription ? <Typography className={classes.redDescription}>{redDescription}</Typography> : null}
        </Box>
    );
}

