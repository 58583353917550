import {iconUseStyles} from "../styles/IconStyles";


export interface IIcon8FacebookProps {
    className?: string;
}

export default function Icon8Facebook(props: IIcon8FacebookProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg id="icons8-facebook" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path id="Path_37" data-name="Path 37" d="M15,5A10,10,0,1,0,25,15,10,10,0,0,0,15,5Z"
                      transform="translate(-5 -5)" fill="#039be5"/>
                <path id="Path_38" data-name="Path 38"
                      d="M21.784,21.351h2.589l.407-2.63h-3V17.283c0-1.093.357-2.062,1.379-2.062H24.8v-2.3a13.952,13.952,0,0,0-2.052-.124c-2.408,0-3.82,1.272-3.82,4.169v1.75H16.457v2.63h2.476v7.23a9.072,9.072,0,0,0,2.851.022Z"
                      transform="translate(-10.429 -8.704)" fill="#fff"/>
            </svg>
        </span>
    );
}








