import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {AppDataContext} from "../../../../../AppData";
import {tt} from "../../../../../core/Localization";
import AppListItem from "../../../listItems/AppListItem";
import {CheckCircle} from "@mui/icons-material";
import ModalBottomSheet from "../../../ModalBottomSheet";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import AppButton from "../../../buttons/AppButton";
import {
    EmployeeJoinedUserResponsePage,
    EmployeePermissionsMapResponse,
    GetEmployeesJoinedUsersInput
} from "../../../../../generated/graphql/graphql";
import ListShimmer from "../../../shimmers/ListShimmer";
import {UserFullName, UserPhotoUrl, UserRoleTitle} from "../../../../../service/UserService";
import EmptyListText from "../../../textComponents/EmptyListText";
import {SortEmployeesByName} from "../../../../../service/EmployeeService";
import {processQueryError} from "../../../../../service/ErrorService";
import {RestApiClientContext} from "../../../../../core/RestApiProvider";
import {hasPermission} from "../../../permissions/PermissionValid";
import {kActionUpdate, kPermissionsVisitCanBeResponsible} from "../../../../../core/constants";

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        maxHeight: '80vh',
        height: '100vh',
        overflow: "auto",
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
    },
}));

export interface IChooseResponsiblePersonModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onSave: (id: number) => void;
    existingChosenPerson?: number;
    loading?: boolean;
    modalAboveModals?: boolean;
}

export default function ChooseResponsiblePersonModalBottomSheet(props: IChooseResponsiblePersonModalBottomSheetProps) {
    const {
        open,
        setOpen,
        onSave,
        existingChosenPerson,
        loading: loadingProp,
        modalAboveModals,
    } = props;

    const {classes} = useStyles();

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, storage, setStorage} = appDataContext;

    const [chosenPerson, setChosenPerson] = useState<number>();

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<EmployeeJoinedUserResponsePage | undefined>(undefined);
    useEffect(() => {
        if (open) {
            restApiGet({
                uri: '/company/employee/search-joined-users',
                params: {
                    companyId: companyId!,
                    active: true,
                    includePermissionsMapJSON: true,
                } as GetEmployeesJoinedUsersInput,
                setLoading: setLoading,
                onData: setData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        }
    }, [open, companyId]);

    useEffect(() => {
        if (data && open) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(data.files || []),
                    ],
                };
            });
        }
    }, [data, open]);

    useEffect(() => {
        if (open) {
            setChosenPerson(existingChosenPerson);
        }
    }, [open]);

    const content = loading && !data ?
        (
            <ListShimmer items={3} showIcon={false}/>
        ) : data && data.content.length > 0 ?
            SortEmployeesByName(
                data!.content
                    .filter(person => {
                        const permissionsForEmployee = data!.employeePermissions?.find((permissions: EmployeePermissionsMapResponse) => permissions.employeeId === person.id);

                        return permissionsForEmployee && hasPermission(kPermissionsVisitCanBeResponsible, [kActionUpdate], JSON.parse(permissionsForEmployee.permissionsMapJSON));
                    })
            )
                .map(person => {
                    return (
                        <AppListItem
                            key={person.id}
                            profileImage={
                                UserPhotoUrl(person.user, data?.files, storage.publicUrlsForFiles)
                            }
                            variant={"smaller-title"}
                            onClick={() => setChosenPerson(person.id)}
                            title={UserFullName(person?.name || person?.user?.name, person?.surname || person?.user?.surname)}
                            description={UserRoleTitle(person.role)}
                            actionWidget={
                                chosenPerson === person.id ? <CheckCircle color={"primary"}/> : <></>
                            }
                        />
                    );
                })
            : (
                <EmptyListText text={tt('workers.screen.emptyListMessage')}/>
            );

    return (
        <ModalBottomSheet
            tallOnMobile={true}
            blurBackdrop={true}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
            modalAboveModals={modalAboveModals}
            children={
                <Box className={classes.container}>
                    <BottomSheetModalAppbar
                        onClose={() => setOpen(false)}
                        title={tt('common.choseResponsiblePerson')}
                    ></BottomSheetModalAppbar>

                    {content}

                    <Box sx={{pb: 10}}/>

                    <Box className={classes.buttonContainer}>
                        <AppButton
                            variant={"contained"}
                            disabled={chosenPerson === null || loadingProp}
                            fullWidth={true}
                            onClick={() => {
                                onSave(chosenPerson!);

                                setOpen(false);
                            }}>
                            {tt('common.save')}
                        </AppButton>
                    </Box>
                </Box>
            }
        />
    );
}
