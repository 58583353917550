import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8UserWithLockProps {
    className?: string;
}

export default function Icons8UserWithLock(props: IIcons8UserWithLockProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" width="24px"
                 height="24px">
                <defs>
                    <clipPath id="clip-1">
                        <path
                            d="M0,256v-256h256v256h-7.55617c3.23799,-4.84014 5.12759,-10.65547 5.12759,-16.90476v-38.09524c0,-11.8019 -6.74286,-22.04952 -16.57143,-27.11619c-5.40952,-23.71048 -26.67429,-41.45524 -52,-41.45524c-25.32571,0 -46.59048,17.74476 -52,41.45524c-9.82857,5.06667 -16.57143,15.31429 -16.57143,27.11619v38.09524c0,6.24929 1.88961,12.06462 5.12759,16.90476z"
                            id="overlayBgMask" fill="none"></path>
                    </clipPath>
                </defs>
                <g clipPath="url(#clip-1)"
                   fill="none"
                   fillRule="nonzero"
                   stroke="none"
                   strokeWidth="1"
                   strokeLinecap="butt"
                   strokeLinejoin="miter"
                   strokeMiterlimit="10"
                >
                    <g transform="scale(5.33333,5.33333)" fill="currentColor">
                        <path
                            d="M24,4c-5.50508,0 -10,4.49492 -10,10c0,5.50508 4.49492,10 10,10c5.50508,0 10,-4.49492 10,-10c0,-5.50508 -4.49492,-10 -10,-10zM24,7c3.88376,0 7,3.11624 7,7c0,3.88376 -3.11624,7 -7,7c-3.88376,0 -7,-3.11624 -7,-7c0,-3.88376 3.11624,-7 7,-7zM11.97852,28c-2.17981,0 -3.97852,1.79871 -3.97852,3.97852v1.52148c0,3.60417 2.27927,6.39223 5.30664,8.0625c3.02737,1.67027 6.86146,2.4375 10.69336,2.4375c3.8319,0 7.66599,-0.76723 10.69336,-2.4375c2.58128,-1.42415 4.52398,-3.69988 5.06836,-6.5625h0.24023v-3.02148c0,-2.17981 -1.80066,-3.97852 -3.98047,-3.97852zM11.97852,31h24.04297c0.55819,0 0.98047,0.42033 0.98047,0.97852v0.02148h-0.00195v1.5c0,2.39583 -1.34573,4.10777 -3.75586,5.4375c-2.41013,1.32973 -5.82604,2.0625 -9.24414,2.0625c-3.4181,0 -6.83401,-0.73277 -9.24414,-2.0625c-2.41013,-1.32973 -3.75586,-3.04167 -3.75586,-5.4375v-1.52148c0,-0.55819 0.42033,-0.97852 0.97852,-0.97852z"></path>
                    </g>
                </g>
                <g fill="currentColor"
                   fillRule="nonzero"
                   stroke="none"
                   strokeWidth="1"
                   strokeLinecap="butt"
                   strokeLinejoin="miter"
                   strokeMiterlimit="10"
                >
                    <g transform="translate(131.66667,147.66667) scale(7.61905,7.61905)" id="overlay">
                        <path
                            d="M12,5c0,-2.757 -2.243,-5 -5,-5c-2.757,0 -5,2.243 -5,5c-1.105,0 -2,0.895 -2,2v5c0,1.105 0.895,2 2,2h10c1.105,0 2,-0.895 2,-2v-5c0,-1.105 -0.895,-2 -2,-2zM7,2c1.654,0 3,1.346 3,3h-6c0,-1.654 1.346,-3 3,-3z"></path>
                    </g>
                </g>
            </svg>
        </span>
    );
}
