import Grid from "@mui/material/Grid";
import {Divider, Theme, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";

export const useStyles = makeStyles()((theme: Theme) => ({
        divider: {
            flexGrow: 1,
        },
        text: {
            fontSize: 12,
            fontWeight: "bold",
            marginRight: 8,
            marginLeft: 8,
            color: kAppColors.text.secondary(theme.palette.mode === "dark"),
            textTransform: "uppercase",
        },
    })
);

export interface ITextDividerProps {
    text: string;
}

export default function TextDivider(props: ITextDividerProps) {
    const {text} = props;

    const {classes} = useStyles();

    return (
        <Grid container alignItems={"center"}>
            <Divider className={classes.divider}></Divider>
            <Typography className={classes.text}>{text}</Typography>
            <Divider className={classes.divider}></Divider>
        </Grid>
    );
}