import {makeStyles} from "tss-react/mui";
import {Box, Divider, Theme, Typography} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import React from "react";
import AppIconButton from "../buttons/AppIconButton";
import CloseIcon from "../../../icons/CloseIcon";

export const useStyles = makeStyles()((theme: Theme) => ({
    appbar: {
        width: '100%',
        maxWidth: '100%',
        position: "sticky",
        top: 0,
        zIndex: 100,
        backgroundColor: kAppColors.background.mobileAppbar(theme.palette.mode === "dark"),
        backdropFilter: `saturate(180%) blur(10px)`,
        transition: 'all 0.1s linear',
        borderBottom: `1px solid transparent`,
        marginBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
    },
    appbarInnerContainer: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 56,
    },
    content: {
        flexGrow: 1,
    },
    appbarDivider: {
        marginLeft: -16,
        marginRight: -16,
    },
    title: {
        fontSize: 20,
        fontWeight: 600,
        marginLeft: 8,
        lineHeight: 1.1,
        "@media (max-width: 767px)": {
            fontSize: 16,
        }
    }
}));

export interface IPaperAppbarProps {
    title?: string;
    children?: React.ReactNode;
    bottomContent?: React.ReactNode;
    onClose: React.MouseEventHandler<HTMLButtonElement>;
    customClose?: React.ReactNode;
    hideClose?: boolean;
    noBorderBottom?: boolean;
}

export default function PaperAppbar(props: IPaperAppbarProps) {
    const {
        children,
        bottomContent,
        onClose,
        customClose,
        hideClose,
        title,
        noBorderBottom,
    } = props;
    const {classes, cx} = useStyles();

    const theAppbarClass = cx(
        classes.appbar,
    );

    const contentJSX = children == null ? <></> : children;

    const titleJSX = title != null ? <Typography className={classes.title}>{title}</Typography> : null;

    const closeJSX = !hideClose ? (
        <AppIconButton
            onClick={onClose}
        >
            <CloseIcon/>
        </AppIconButton>
    ) : null;

    return (
        <Box className={theAppbarClass}>
            <Box className={classes.appbarInnerContainer}>
                <Box className={classes.content}>
                    {contentJSX}
                    {titleJSX}
                </Box>
                {customClose || closeJSX}
            </Box>
            {bottomContent}
            {
                noBorderBottom ? null : <Box className={classes.appbarDivider}><Divider/></Box>
            }
        </Box>
    );
}
