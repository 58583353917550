import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {Dispatch, SetStateAction, useContext, useEffect, useId, useMemo, useState} from "react";
import {AppDataContext} from "../../../AppData";
import {usePopupState} from "material-ui-popup-state/hooks";
import FormBuilder, {IInputsData, InputType} from "../form/FormBuilder";
import {tt} from "../../../core/Localization";
import {
    ClientPureResponsePage,
    GetClientsInput} from "../../../generated/graphql/graphql";
import AppChip from "../chips/AppChip";
import AppAvatar from "../AppAvatar";
import Icons8Company from "../../../icons/Icons8Company";
import UserIcon from "../../../icons/UserIcon";
import Menu from "@mui/material/Menu";
import {bindMenu} from "material-ui-popup-state";
import FilterButton from "../buttons/FilterButton";
import ListShimmer from "../shimmers/ListShimmer";
import AppListItem from "../listItems/AppListItem";
import EmptyListText from "../textComponents/EmptyListText";
import {processQueryError} from "../../../service/ErrorService";
import { RestApiClientContext } from "../../../core/RestApiProvider";
import { kTopicClients } from "../../../core/constants";

export const useStyles = makeStyles()((theme: Theme) => ({
    menuOffset: {
        '.MuiPaper-root': {
            width: '90%',
            maxWidth: 400,
        },
        transform: 'translateY(8px)',
        '.MuiListItem-root': {
            borderRadius: 0,
        }
    },
    chip: {
        marginRight: 8,
        marginTop: 6,
        marginBottom: 2,
        borderRadius: 40,
        paddingLeft: 0,
        paddingRight: 4,
        fontSize: 14,
        height: 32,
        fontWeight: 600,
        background: kAppColors.background.autocompleteChip(theme.palette.mode === "dark"),
        color: kAppColors.text.primary(theme.palette.mode === 'dark'),
        ':hover': {
            background: kAppColors.background.autocompleteChip(theme.palette.mode === "dark"),
        },
        '.MuiChip-icon.MuiChip-icon': {
            marginLeft: 3,
        },
        '.MuiChip-deleteIcon': {
            color: theme.palette.mode === 'dark' ? kAppColors.text.white : kAppColors.text.secondaryLightModeGrey,
        }
    },
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
    },
    input: {
        '.MuiInputBase-root': {
            flexDirection: "column",
            alignItems: "start",
        }
    },
    iconColor: {
        color: kAppColors.text.primary(theme.palette.mode === 'dark'),
    }
}));

export interface IFilterByClientFilterProps {
    selected: number | undefined;
    setSelected: Dispatch<SetStateAction<number | undefined>>;
    validClients?: number[];
    isDisabled?: boolean;
}

export default function FilterByClientFilter(props: IFilterByClientFilterProps) {
    const {selected, setSelected, validClients, isDisabled} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const {classes} = useStyles();

    const clientsFilterMenu = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            className: classes.input,
            type: InputType.Text,
            label: '',
            value: '',
            required: true,
            size: "small",
            hideLabel: true,
            placeholder: tt('jobsScreen.filterByClient.input.placeholder'),
        },
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<ClientPureResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            const subscription = subscribe(
                kTopicClients,
                {
                    uri: '/client/search-pure',
                    params: {
                        companyId,
                        search: '',
                    } as GetClientsInput,
                    setLoading,
                    onData: setData,
                    onError: (error) => processQueryError(appDataContext, error),
                },
                (notifications) => true,
            );

            return () => subscription.cancel();
        } else {
            setData(null);
        }
    }, [companyId]);

    useEffect(() => {
        const client = data?.content.find(element => element.id === selected);
        const title = client?.name;

        setInputs(prev => {
            return {
                ...prev,
                search: {
                    ...prev.search,
                    innerPrefixJSX: (
                        <Box className={classes.chipsContainer}>
                            {client ? (
                                <AppChip
                                    icon={
                                        <AppAvatar
                                            variant={"extraSmall"}
                                            icon={client.company ? <Icons8Company className={classes.iconColor}/> :
                                                <UserIcon className={classes.iconColor}/>}
                                        />
                                    }
                                    onDelete={
                                        () => {
                                            setSelected(undefined);
                                        }
                                    }
                                    label={title}
                                    className={classes.chip}
                                />
                            ) : null}
                        </Box>
                    ),
                }
            };
        });
    }, [selected, data]);

    const content = useMemo(
        () => {
            const theSearch = inputs.search.value.toLowerCase();

            const theData = data?.content
                .filter(client => {
                    if (validClients && !validClients.includes(client.id)) {
                        return false;
                    }

                    return client.name.toLowerCase().includes(theSearch);
                });

            return loading && !theData ?
                <ListShimmer items={2}/> : theData && theData.length > 0 ?
                    theData.slice(0, 5)
                        .map(client => {
                            return (
                                <Box
                                    key={client.id}
                                    mr={-1}
                                    ml={-1}
                                >
                                    <AppListItem
                                        onClick={() => {
                                            ///can select max 1 client
                                            setSelected(client.id);
                                            clientsFilterMenu.close();
                                        }}
                                        variant={"smaller-title"}
                                        customAvatarInCircle={client.company ? <Icons8Company/> : <UserIcon/>}
                                        title={client.name}
                                        description={client.company ? tt('common.companyClient') : undefined}
                                        actionWidget={<></>}
                                    />
                                </Box>
                            );
                        })
                    : <EmptyListText text={tt('jobs.screen.clientFilter.emptyListMessage')}/>;
        },
        [data, inputs.search.value, selected, validClients]
    );

    const buttonTitle = useMemo(
        () => {
            const client = data?.content?.find(element => element.id === selected);

            return client?.name;
        }, [data, selected]
    );

    return (
        <>
            <FilterButton
                title={selected && buttonTitle ? buttonTitle : tt('jobs.screen.filterByClient.button')}
                onClick={
                    (e) => {
                        clientsFilterMenu.setOpen(true, e);
                    }
                }
                isSelected={selected != undefined}
                isDisabled={isDisabled}
            />

            <Menu className={classes.menuOffset}  {...bindMenu(clientsFilterMenu)}>
                <FormBuilder inputs={inputs} setInputs={setInputs}/>

                {content}
            </Menu>
        </>
    );
}
