import {Box, Theme, Typography} from "@mui/material";
import {tt} from "../../../core/Localization";
import React from "react";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
    totalHoursContainerOuter: {
        borderTop: `dashed 1px ${kAppColors.border(theme.palette.mode === "dark")}`,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
        display: "flex",
        justifyContent: "end",
        "@media (max-width: 576px)": {
            justifyContent: "unset",
            width: '100%',
        },
    },
    totalHoursContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
        paddingRight: 60,
        "@media (max-width: 576px)": {
            paddingRight: 0,
            width: '100%',
        },
    },
    totalSingleRow: {
        display: "flex",
        "@media (max-width: 576px)": {
            width: '100%',
            justifyContent: "space-between",
        }
    },
    totalExtraSpaceContainer: {
        width: 140,
        display: "flex",
        justifyContent: "end",
        "@media (max-width: 576px)": {
            width: "auto",
            marginLeft: "auto",
        }
    },
    workersTotalHours: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontWeight: 600,
    },
    workersTotalMoney: {
        fontWeight: 600,
    },
}));

export interface ITimesheetsTotalSectionProps {
    moneyTotal?: string;
    timeTotal?: string
    distanceTotal?: string;
}

export default function TimesheetsTotalSection(props: ITimesheetsTotalSectionProps) {
    const {
        moneyTotal,
        timeTotal,
        distanceTotal,
    } = props;

    const {classes} = useStyles();

    return <Box className={classes.totalHoursContainerOuter}>
        <Box className={classes.totalHoursContainer}>
            <Box className={classes.totalSingleRow}>
                <Typography
                    className={classes.workersTotalMoney}>{tt('newJob.workersSection.timesheets.total')}:</Typography>
                <Box className={classes.totalExtraSpaceContainer}>
                    <Typography
                        className={classes.workersTotalMoney}>{moneyTotal || ''}</Typography>
                </Box>
            </Box>

            {timeTotal ? <Box className={classes.totalSingleRow}>
                <Box className={classes.totalExtraSpaceContainer}>
                    <Typography
                        className={classes.workersTotalHours}>
                        {timeTotal}
                    </Typography>
                </Box>
            </Box> : null}

            {distanceTotal ? <Box className={classes.totalSingleRow}>
                <Box className={classes.totalExtraSpaceContainer}>
                    <Typography
                        className={classes.workersTotalHours}>
                        {distanceTotal}
                    </Typography>
                </Box>
            </Box> : null}
        </Box>
    </Box>;
}
