import {Box, Divider, Grid, Skeleton, Theme} from "@mui/material";
import React from "react";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import OneLineListItemShimmer from "../OneLineListItemShimmer";


export const useStyles = makeStyles()((theme: Theme) => ({
    rowContainer: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 8,
        paddingTop: 8,
    },
    tabContainer: {
        height: 50,
        paddingLeft: 16,
        display: 'flex',
        alignItems: "center",
        overflow: "clip",
        'span': {
            marginRight: 8,
            flexShrink: 0,
        }
    },
    mobile: {
        display: "none",
        "@media (max-width: 767px)": {
            display: "block",
        }
    },
    desktop: {
        "@media (max-width: 767px)": {
            display: "none",
        }
    },
    stepperContainer: {
        display: "flex",
        alignItems: "center",
        paddingTop: 24,
        paddingLeft: 56,
        paddingRight: 56,
    },
    stepperDivider: {
        background: kAppColors.border(theme.palette.mode === "dark"),
        flexGrow: 1,
        height: 2,
    },
    stepperLabelsContainer: {
        paddingTop: 16,
        paddingLeft: 38,
        paddingRight: 38,
        paddingBottom: 8,
        display: "flex",
        justifyContent: "space-between",
    },
    section: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 8,
        paddingTop: 8,
        display: "flex",
        alignItems: "center",
    }
}));


export default function VisitDetailShimmer() {
    const {cx, classes} = useStyles();

    return (
        <>
            <Box sx={{mb: 2}}/>
            <Box sx={{mb: 2, ml: 2, mr: 2,}}>
                <Skeleton variant={"rectangular"} width={80} height={20}/>
                <Box className={cx(classes.stepperContainer, classes.desktop)}>
                    <Box mr={1} ml={1}>
                        <Skeleton variant={"rectangular"} width={24} height={24}/>
                    </Box>
                    <Box className={classes.stepperDivider}></Box>
                    <Box mr={1} ml={1}>
                        <Skeleton variant={"rectangular"} width={24} height={24}/>
                    </Box>
                    <Box className={classes.stepperDivider}></Box>
                    <Box mr={1} ml={1}>
                        <Skeleton variant={"rectangular"} width={24} height={24}/>
                    </Box>
                    <Box className={classes.stepperDivider}></Box>
                    <Box mr={1} ml={1}>
                        <Skeleton variant={"rectangular"} width={24} height={24}/>
                    </Box>
                </Box>
                <Box className={cx(classes.stepperLabelsContainer, classes.desktop)}>
                    <Skeleton variant={"rectangular"} width={80} height={16}/>
                    <Skeleton variant={"rectangular"} width={80} height={16}/>
                    <Skeleton variant={"rectangular"} width={80} height={16}/>
                    <Skeleton variant={"rectangular"} width={80} height={16}/>
                </Box>

                <Box className={cx(classes.mobile)}>
                    <Box pt={2}></Box>
                    <Skeleton variant={"rectangular"} width={'100%'} height={52}/>
                </Box>
            </Box>
            <Divider/>
            <Grid container className={classes.rowContainer}>
                <Skeleton variant={"rectangular"} width={90} height={57}/>
                <Box pl={1}/>
                <Skeleton variant={"rectangular"} width={90} height={57}/>
                <Box pl={1}/>
                <Skeleton variant={"rectangular"} width={90} height={57}/>
            </Grid>
            <Divider/>
            <Box
                pt={1}
                pb={1}
                pl={2}
                pr={2}
            > <Skeleton variant={"rectangular"} width={140} height={28}/></Box>

            <Divider/>
            <Box className={classes.tabContainer}>
                <Skeleton variant={"rectangular"} width={80} height={20}/>
                <Skeleton variant={"rectangular"} width={80} height={20}/>
                <Skeleton variant={"rectangular"} width={80} height={20}/>
                <Skeleton variant={"rectangular"} width={80} height={20}/>
                <Skeleton variant={"rectangular"} width={80} height={20}/>
            </Box>
            <Divider/>
            <OneLineListItemShimmer/>
            <Divider/>
            <Box className={classes.section}>
                <Box mr={'auto'}><Skeleton variant={"rectangular"} width={180} height={18}/></Box>
                <Skeleton variant={"rectangular"} width={28} height={28}/>
                <Box pr={2}/>
                <Skeleton variant={"rectangular"} width={28} height={28}/>
            </Box>
            <Divider/>
            <Box className={classes.section}>
                <Box mr={'auto'}><Skeleton variant={"rectangular"} width={90} height={18}/></Box>
                <Skeleton variant={"rectangular"} width={28} height={28}/>
                <Box pr={2}/>
                <Skeleton variant={"rectangular"} width={28} height={28}/>
            </Box>
            <Divider/>
            <Box pb={2}/>
            <Box pr={2} pl={2}>
                <Skeleton variant={"rectangular"} width={300} height={18}/>
            </Box>
            <Box pb={1.25}/>
        </>
    );
}