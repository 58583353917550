import {Box, Divider, Theme, Typography} from "@mui/material";
import CheckBoxItemWithAvatar from "../jobs/visits/visitDetail/CheckBoxItemWithAvatar";
import React, {ReactElement, useContext, useEffect, useMemo, useRef, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {
    EmployeeJoinedUserResponse,
    FileResponse,
    JobFormElementFragment,
    JobFormElementType,
    JobFormResponse,
    UpdateJobFormElementDoneStateElementInput
} from "../../../generated/graphql/graphql";
import {DateTime} from "luxon";
import {UserFullName, UserPhotoUrl} from "../../../service/UserService";
import HeadlineWithButton from "../../screenSections/detailListPreviewSection/HeadlineWithButton";
import {tt} from "../../../core/Localization";
import TextInput from "../form/TextInput";
import Select from "../form/Select";
import FormDatePicker from "../form/DatePicker";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {UpdateJobFormElementDoneCallback} from "../jobs/visits/visitDetail/VisitDetailContent";
import Debouncer from "../../../utils/Debouncer";
import {
    kActionUpdate,
    kOneLineTextLimit,
    kPermissionsFormsFillElement,
    kThemeAnimationDuration,
    kUserInputDebounce
} from "../../../core/constants";
import RadioButtonGroup from "../form/RadioButtonGroup";
import LabelWithAvatar from "./LabelWithAvatar";
import {v4 as uuidv4} from 'uuid';
import {AppDataContext} from "../../../AppData";
import FormTimePicker from "../form/FormTimePicker";
import {hasPermission} from "../permissions/PermissionValid";
import Icons8ClipBoardList from "../../../icons/Icons8ClipBoardList";
import ProgressBarWidget from "../ProgressBarWidget";
import AppListItem from "../listItems/AppListItem";
import FilterButton from "../buttons/FilterButton";
import {calculateJobFormElementStats, calculateJobFormStats} from "../../../service/JobFormService";

export const useStyles = makeStyles()((theme: Theme) => ({
    inputContainer: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    prefixContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 42,
        height: 54,
        svg: {
            color: kAppColors.grey.disabledInput(theme.palette.mode === "dark"),
        }
    },
    oneThird: {
        width: '33%',
        "@media (max-width: 767px)": {
            width: '100%',
        }
    },
    extraSpaceOnMobile: {
        "@media (max-width: 767px)": {
            paddingBottom: 100,
        }
    },
    chipsContainer: {
        display: 'flex',
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
        overflowX: "auto",
        'button': {
            flexShrink: 0,
            marginRight: 8,
        },
    },
    subLabelDescription: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontSize: 12,
    }
}));

export interface IJobFormDetailContentProps {
    jobFormResponse: JobFormResponse | NullOrUndefined;
    employeesData?: Array<EmployeeJoinedUserResponse> | NullOrUndefined;
    files?: FileResponse[] | NullOrUndefined;
    canEdit?: boolean | NullOrUndefined;
    updateJobFormElementDone?: UpdateJobFormElementDoneCallback | NullOrUndefined;
    overrideName?: string | NullOrUndefined;
}

export default function JobFormDetailContent(props: IJobFormDetailContentProps) {
    const {
        jobFormResponse,
        employeesData,
        files,
        canEdit,
        updateJobFormElementDone,
        overrideName,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {language, employeePermissionsMap, storage} = appDataContext;

    const {classes, cx} = useStyles();

    const [invalidate, setInvalidate] = useState<number>(0);
    const [displayOnlyRequired, setDisplayOnlyRequired] = useState<boolean>(false);

    const updateValueTimeout = useRef(new Debouncer(kUserInputDebounce));
    const valuesToUpdate = useRef<Array<UpdateJobFormElementDoneStateElementInput>>([]);
    const optimisticValues = useRef<Array<UpdateJobFormElementDoneStateElementInput>>([]);

    useEffect(() => {
        return () => {
            updateValueTimeout.current?.dispose();
        };
    }, []);

    useEffect(() => {
        valuesToUpdate.current = [];
        optimisticValues.current = [];
    }, [jobFormResponse]);

    const formStats = useMemo(() => {
        if (jobFormResponse) {
            return calculateJobFormStats(jobFormResponse);
        }

        return null;
    }, [jobFormResponse]);

    if (!jobFormResponse) {
        return null;
    }

    const formUUID = jobFormResponse.uuid!;

    let theCanEdit = canEdit;

    if (canEdit && updateJobFormElementDone && !hasPermission(kPermissionsFormsFillElement, [kActionUpdate], employeePermissionsMap)) {
        theCanEdit = false;
    }

    /**
     * Update valuesToUpdate with new value.
     * Override existing value if already exists by jobFormElementUUID.
     */
    const updateValuesToUpdate = (update: UpdateJobFormElementDoneStateElementInput, milliseconds?: number) => {
        const existing = valuesToUpdate.current.find(value => value.jobFormElementUUID === update.jobFormElementUUID);

        if (existing) {
            existing.done = update.done;
            existing.doneValue = update.doneValue;
        } else {
            valuesToUpdate.current.push(update);
        }

        const values = [...valuesToUpdate.current];

        updateValueTimeout.current?.run(() => {
            if (updateJobFormElementDone) {
                updateJobFormElementDone!(
                    formUUID,
                    values,
                    () => {
                        for (const valueOf of values) {
                            const existing = optimisticValues.current.find(value => value.jobFormElementUUID === valueOf.jobFormElementUUID);

                            if (existing) {
                                existing.done = valueOf.done;
                                existing.doneValue = valueOf.doneValue;
                            } else {
                                optimisticValues.current.push(valueOf);
                            }
                        }

                        setInvalidate(invalidate + 1);
                    },
                );
            }
        }, milliseconds);

        setInvalidate(invalidate + 1);
    };

    /**
     * Get JSX element for JobFormElementFragment.
     */
    const getJSXElement = (
        element: JobFormElementFragment,
        index: number,
        doneAt: DateTime | undefined,
        employee: EmployeeJoinedUserResponse | NullOrUndefined,
        optimisticValue: UpdateJobFormElementDoneStateElementInput | undefined,
    ) => {
        const elementStats = calculateJobFormElementStats(element, jobFormResponse);

        const avatarJSX = (
            <LabelWithAvatar
                isRequired={elementStats.isRequired}
                isDone={elementStats.isDone}
                label={element.label}
                workerName={employee ? UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname) : undefined}
                date={employee && element.doneAt ? DateTime.fromMillis(element.doneAt) : undefined}
                img={
                    UserPhotoUrl(employee?.user, files, storage.publicUrlsForFiles)
                }
            />
        );

        let doneValue = element.doneValue || '';
        if (optimisticValue) {
            doneValue = optimisticValue.doneValue || '';
        }

        switch (element.type) {
            case JobFormElementType.Checkbox:
                return (
                    <CheckBoxItemWithAvatar
                        key={index}
                        isRequired={elementStats.isRequired}
                        isDone={optimisticValue?.done || element.done}
                        onTap={theCanEdit ? () => updateValuesToUpdate({
                            jobFormElementUUID: element.uuid!,
                            done: !(optimisticValue?.done || element.done),
                        }, kThemeAnimationDuration) : undefined}
                        title={element.label}
                        date={doneAt}
                        avatarName={employee ? UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname) : undefined}
                        img={
                            UserPhotoUrl(employee?.user, files, storage.publicUrlsForFiles)
                        }
                    />
                );
            case JobFormElementType.Text:
                return (
                    <Box
                        key={index}
                        className={classes.inputContainer}
                    >
                        {avatarJSX}

                        <Box pb={0.5}/>

                        <TextInput
                            index={element.label}
                            label={tt('common.placeholderAnswer')}
                            minRows={1}
                            value={doneValue}
                            updateValue={(index: string, value: any) => updateValuesToUpdate({
                                jobFormElementUUID: element.uuid!,
                                done: true,
                                doneValue: value,
                            })}
                            updateProp={() => {
                            }}
                            disabled={!theCanEdit}
                            maxChars={kOneLineTextLimit}
                            showCharCounter={true}
                        />
                    </Box>
                );
            case JobFormElementType.MultilineText:
                return (
                    <Box
                        key={index}
                        className={classes.inputContainer}
                    >
                        {avatarJSX}

                        <Box pb={0.5}/>

                        <TextInput
                            index={element.label}
                            label={tt('common.placeholderAnswer')}
                            minRows={4}
                            value={doneValue}
                            updateValue={(index: string, value: any) => updateValuesToUpdate({
                                jobFormElementUUID: element.uuid!,
                                done: true,
                                doneValue: value,
                            })}
                            updateProp={() => {
                            }}
                            disabled={!theCanEdit}
                        />
                    </Box>
                );
            case JobFormElementType.Number:
                return (
                    <Box
                        key={index}
                        className={cx(classes.inputContainer, classes.oneThird)}
                    >
                        {avatarJSX}

                        <Box pb={0.5}/>

                        <TextInput
                            index={element.label}
                            label={tt('common.placeholderAnswer')}
                            inputMode={"decimal"}
                            numbersOnly={true}
                            minRows={1}
                            value={doneValue}
                            updateValue={(index: string, value: any) => updateValuesToUpdate({
                                jobFormElementUUID: element.uuid!,
                                done: true,
                                doneValue: value,
                            })}
                            updateProp={() => {
                            }}
                            disabled={!theCanEdit}
                        />
                    </Box>
                );
            case JobFormElementType.Dropdown:
                return (
                    <Box
                        key={index}
                        className={classes.inputContainer}
                    >
                        {avatarJSX}

                        <Box pb={0.5}/>

                        <Select
                            index={element.label}
                            label={tt('jobForm.dropdown.placeholder')}
                            value={doneValue}
                            updateValue={(index: string, value: any) => updateValuesToUpdate({
                                jobFormElementUUID: element.uuid!,
                                done: true,
                                doneValue: value,
                            }, kThemeAnimationDuration)}
                            updateProp={() => {
                            }}
                            options={[
                                {
                                    value: '',
                                    label: tt('jobForm.dropdown.placeholder'),
                                },
                                ...element.options.map((option, index) => {
                                    return {
                                        value: option,
                                        label: option,
                                    };
                                }),
                            ]}
                            disabled={!theCanEdit}
                        />
                    </Box>
                );
            case JobFormElementType.Datepicker:
                let theValue = element.doneValue ? parseInt(element.doneValue) : null;
                if (optimisticValue?.doneValue) {
                    theValue = parseInt(optimisticValue.doneValue);
                }

                return (
                    <Box className={cx(classes.inputContainer, classes.oneThird)} key={index}>
                        {avatarJSX}

                        <Box pb={0.5}/>

                        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={language}>
                            <FormDatePicker
                                index={element.label}
                                label={tt('jobForm.datePicker.placeholder')}
                                updateValue={(index: string, value: any) => updateValuesToUpdate({
                                    jobFormElementUUID: element.uuid!,
                                    done: true,
                                    doneValue: value.toString(),
                                }, kThemeAnimationDuration)}
                                updateProp={() => {
                                }}
                                value={theValue}
                                dateFormat={'dd.MM.yyyy'}
                                disabled={!theCanEdit}
                            />
                        </LocalizationProvider>
                    </Box>
                );
            case JobFormElementType.Timepicker:
                let theTimeValue = element.doneValue ? parseInt(element.doneValue) : undefined;
                if (optimisticValue?.doneValue) {
                    theTimeValue = parseInt(optimisticValue.doneValue);
                }

                return (
                    <Box className={cx(classes.inputContainer, classes.oneThird)} key={index}>
                        {avatarJSX}

                        <Box pb={0.5}/>

                        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={language}>
                            <FormTimePicker
                                index={element.label}
                                label={tt('jobForm.timePicker.placeholder')}
                                updateValue={(index: string, value: any) => updateValuesToUpdate({
                                    jobFormElementUUID: element.uuid!,
                                    done: true,
                                    doneValue: value.toString(),
                                }, kThemeAnimationDuration)}
                                updateProp={() => {
                                }}
                                value={theTimeValue}
                                disabled={!theCanEdit}
                            />
                        </LocalizationProvider>
                    </Box>
                );
            case JobFormElementType.Radio:
                return (
                    <Box key={index}>
                        <Box className={classes.inputContainer}><LabelWithAvatar
                            isRequired={elementStats.isRequired}
                            isDone={elementStats.isDone}
                            label={element.label}
                        />
                        </Box>
                        <Box pb={0.5}/>

                        <RadioButtonGroup
                            index={element.label}
                            label={element.label}
                            value={optimisticValue?.doneValue || element.doneValue || ''}
                            updateValue={(index: string, value: any) => updateValuesToUpdate({
                                jobFormElementUUID: element.uuid!,
                                done: true,
                                doneValue: value,
                            }, kThemeAnimationDuration)}
                            updateProp={() => {
                            }}
                            disabled={!theCanEdit}
                            options={element.options.map((option) => {
                                return {value: option, label: option};
                            })}
                            avatar={employee ?
                                <LabelWithAvatar
                                    workerName={employee ? UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname) : undefined}
                                    date={employee && element.doneAt ? DateTime.fromMillis(element.doneAt) : undefined}
                                    img={
                                        UserPhotoUrl(employee?.user, files, storage.publicUrlsForFiles)
                                    }
                                /> : null
                            }
                        />
                    </Box>
                );
        }
    };

    const contentJSX = jobFormResponse.sections.map((section: string, sectionIndex: number) => {
        const updates: Array<UpdateJobFormElementDoneStateElementInput> = [];

        let sectionElements = jobFormResponse.elements.filter((element) => {
            return element.sectionIndex === sectionIndex;
        })
            .sort((a, b) => a.position - b.position);
        const elementsJSX: ReactElement[] = [];

        sectionElements = sectionElements.map((element) => {
            if (!element.uuid) {
                element.uuid = uuidv4();
            }

            return element;
        });

        let index = 0;
        while (sectionElements.length > 0) {
            const element = sectionElements.shift()!;
            let nextElement = sectionElements[0];
            const isLastCheckbox = element.type === JobFormElementType.Checkbox && nextElement && nextElement.type !== JobFormElementType.Checkbox;
            const isLastRadioButton = element.type === JobFormElementType.Radio && nextElement && nextElement.type !== JobFormElementType.Radio;

            let mainElement = element.groupId ? sectionElements.find((item) => item.groupId === element.groupId && !element.options.includes(item.label)) : undefined;
            if (element.groupId && !mainElement && !element.options.includes(element.label)) {
                mainElement = element;
            }

            if (mainElement) {
                const elementStats = calculateJobFormElementStats(mainElement, jobFormResponse);

                if (displayOnlyRequired && !elementStats.isRequired) {
                    continue;
                }

                elementsJSX.push(
                    <Box
                        key={index}
                        className={classes.inputContainer}
                    >
                        <LabelWithAvatar
                            isRequired={elementStats.isRequired}
                            isDone={elementStats.isDone}
                            label={mainElement.label}
                        />

                        {elementStats.isRequired ? (
                            <Typography
                                className={classes.subLabelDescription}>{tt('formModal.label.chooseAtLeastOneOption')}</Typography>
                        ) : null}
                    </Box>
                );
                index++;

                let elementsForOptions = sectionElements
                    .filter((item) => item.groupId == element.groupId && element.options.includes(item.label));

                const currentExists = elementsForOptions.find((item) => item.id == element.id);
                if (!currentExists && element.id !== mainElement!.id) {
                    elementsForOptions.push(element);
                }

                elementsForOptions = elementsForOptions.sort((a, b) => {
                    const indexA = element.options.findIndex((option) => option == a.label);
                    const indexB = element.options.findIndex((option) => option == b.label);

                    return indexA - indexB;
                });

                sectionElements = sectionElements.filter((item) => item.groupId !== mainElement!.groupId);
                nextElement = sectionElements[0];

                for (const elementOfOptions of elementsForOptions) {
                    const indexOfElementOfOptions = elementsForOptions.findIndex((item) => item.id == elementOfOptions.id);
                    const isLastCheckboxOfOptions = nextElement && elementOfOptions.type === JobFormElementType.Checkbox && indexOfElementOfOptions == elementsForOptions.length - 1;

                    const doneAt = elementOfOptions.doneAt ? DateTime.fromMillis(elementOfOptions.doneAt) : undefined;
                    const employee = employeesData && elementOfOptions.doneByEmployeeId ? employeesData.find(employee => employee.id === elementOfOptions.doneByEmployeeId) : undefined;

                    let optimisticValue = optimisticValues.current.find(value => value.jobFormElementUUID === elementOfOptions.uuid);
                    if (elementOfOptions.type === JobFormElementType.Text || elementOfOptions.type === JobFormElementType.MultilineText || elementOfOptions.type === JobFormElementType.Number) {
                        optimisticValue = valuesToUpdate.current.find(value => value.jobFormElementUUID === elementOfOptions.uuid);
                    }

                    if (elementOfOptions.type === JobFormElementType.Checkbox) {
                        updates.push({
                            jobFormElementUUID: elementOfOptions.uuid!,
                            done: !(optimisticValue?.done || elementOfOptions.done),
                        });
                    }

                    switch (elementOfOptions.type) {
                        case JobFormElementType.Checkbox:
                            elementsJSX.push(
                                <Box
                                    key={index}
                                    pb={isLastCheckboxOfOptions ? 2 : 0}
                                >
                                    {
                                        getJSXElement(
                                            elementOfOptions,
                                            index,
                                            doneAt,
                                            employee,
                                            optimisticValue,
                                        )!
                                    }
                                </Box>
                            );
                            break;
                        case JobFormElementType.Text:
                            elementsJSX.push(
                                getJSXElement(
                                    elementOfOptions,
                                    index,
                                    doneAt,
                                    employee,
                                    optimisticValue,
                                )!
                            );
                            break;
                        case JobFormElementType.MultilineText:
                            elementsJSX.push(
                                getJSXElement(
                                    elementOfOptions,
                                    index,
                                    doneAt,
                                    employee,
                                    optimisticValue,
                                )!
                            );
                            break;
                        case JobFormElementType.Number:
                            elementsJSX.push(
                                getJSXElement(
                                    elementOfOptions,
                                    index,
                                    doneAt,
                                    employee,
                                    optimisticValue,
                                )!
                            );
                            break;
                        case JobFormElementType.Dropdown:
                            elementsJSX.push(
                                getJSXElement(
                                    elementOfOptions,
                                    index,
                                    doneAt,
                                    employee,
                                    optimisticValue,
                                )!
                            );
                            break;
                        case JobFormElementType.Datepicker:
                            elementsJSX.push(
                                getJSXElement(
                                    elementOfOptions,
                                    index,
                                    doneAt,
                                    employee,
                                    optimisticValue,
                                )!
                            );
                            break;
                        case JobFormElementType.Timepicker:
                            elementsJSX.push(
                                getJSXElement(
                                    elementOfOptions,
                                    index,
                                    doneAt,
                                    employee,
                                    optimisticValue,
                                )!
                            );
                            break;
                        case JobFormElementType.Radio:
                            elementsJSX.push(
                                getJSXElement(
                                    elementOfOptions,
                                    index,
                                    doneAt,
                                    employee,
                                    optimisticValue,
                                )!
                            );
                            break;
                    }

                    index++;
                }

                continue;
            }

            const elementStats = calculateJobFormElementStats(element, jobFormResponse);

            if (displayOnlyRequired && !elementStats.isRequired) {
                continue;
            }

            const doneAt = element.doneAt ? DateTime.fromMillis(element.doneAt) : undefined;
            const employee = employeesData && element.doneByEmployeeId ? employeesData.find(employee => employee.id === element.doneByEmployeeId) : undefined;

            let optimisticValue = optimisticValues.current.find(value => value.jobFormElementUUID === element.uuid);
            if (element.type === JobFormElementType.Text || element.type === JobFormElementType.MultilineText || element.type === JobFormElementType.Number) {
                optimisticValue = valuesToUpdate.current.find(value => value.jobFormElementUUID === element.uuid);
            }

            if (element.type === JobFormElementType.Checkbox) {
                updates.push({
                    jobFormElementUUID: element.uuid!,
                    done: !(optimisticValue?.done || element.done),
                });
            }

            switch (element.type) {
                case JobFormElementType.Checkbox:
                    elementsJSX.push(
                        <Box
                            key={index}
                            pb={isLastCheckbox ? 2 : 0}
                        >
                            {
                                getJSXElement(
                                    element,
                                    index,
                                    doneAt,
                                    employee,
                                    optimisticValue,
                                )!
                            }
                        </Box>
                    );
                    break;
                case JobFormElementType.Text:
                    elementsJSX.push(
                        getJSXElement(
                            element,
                            index,
                            doneAt,
                            employee,
                            optimisticValue,
                        )!
                    );
                    break;
                case JobFormElementType.MultilineText:
                    elementsJSX.push(
                        getJSXElement(
                            element,
                            index,
                            doneAt,
                            employee,
                            optimisticValue,
                        )!
                    );
                    break;
                case JobFormElementType.Number:
                    elementsJSX.push(
                        getJSXElement(
                            element,
                            index,
                            doneAt,
                            employee,
                            optimisticValue,
                        )!
                    );
                    break;
                case JobFormElementType.Dropdown:
                    elementsJSX.push(
                        getJSXElement(
                            element,
                            index,
                            doneAt,
                            employee,
                            optimisticValue,
                        )!
                    );
                    break;
                case JobFormElementType.Datepicker:
                    elementsJSX.push(
                        getJSXElement(
                            element,
                            index,
                            doneAt,
                            employee,
                            optimisticValue,
                        )!
                    );
                    break;
                case JobFormElementType.Timepicker:
                    elementsJSX.push(
                        getJSXElement(
                            element,
                            index,
                            doneAt,
                            employee,
                            optimisticValue,
                        )!
                    );
                    break;
                case JobFormElementType.Radio:
                    elementsJSX.push(
                        <Box
                            key={index}
                            pb={isLastRadioButton ? 2 : 0}
                        >
                            {
                                getJSXElement(
                                    element,
                                    index,
                                    doneAt,
                                    employee,
                                    optimisticValue,
                                )!
                            }
                        </Box>
                    );
                    break;
            }

            index++;
        }

        return (
            <div key={`${section}-${index}`}>
                {sectionIndex > 0 ? <Divider/> : null}
                {section != '' ?
                    <HeadlineWithButton
                        title={section}
                        onClick={theCanEdit && updates.length > 0 ? () => {
                            const hasAnyNotDone = updates.find(update => update.done);

                            if (hasAnyNotDone) {
                                updates.forEach(update => {
                                    update.done = true;
                                });
                            }

                            for (const updateOf of updates) {
                                updateValuesToUpdate(updateOf);
                            }
                        } : undefined}
                        buttonText={tt('common.selectAll')}
                    />
                    : <Box pb={1}/>}

                {elementsJSX}

                <Box pb={2}></Box>
            </div>
        );
    });

    const theName = overrideName || jobFormResponse.name;

    return (
        <>
            {theName ?
                <>

                    <AppListItem
                        customAvatarInCircle={<Icons8ClipBoardList/>}
                        title={theName}
                        actionWidget={<></>}
                        customBottomWidget={formStats?.hasRequired ? (
                            <Box pb={1}>
                                <Box width={160}>
                                    <ProgressBarWidget done={formStats.doneRequired} total={formStats.totalRequired}/>
                                </Box>
                            </Box>
                        ) : null}
                    />
                    <Box pb={1}/>
                    <Divider/>
                    <Box pb={2}/>
                </>
                : null
            }

            {formStats?.hasRequired ? (
                <Box className={cx('styledScrollbar', classes.chipsContainer)}>
                    <FilterButton
                        noCheckmark={true}
                        isSelected={!displayOnlyRequired}
                        hideChevronDown={true}
                        title={tt('formModal.filter.wholeForm')}
                        onClick={() => setDisplayOnlyRequired(false)}
                    />

                    <FilterButton
                        noCheckmark={true}
                        isSelected={displayOnlyRequired}
                        hideChevronDown={true}
                        title={tt('formModal.filter.requiredOnly')}
                        onClick={() => setDisplayOnlyRequired(true)}
                    />
                </Box>
            ) : null}

            {contentJSX}
            <Box className={classes.extraSpaceOnMobile}></Box>
        </>
    );
}
