import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8WarehouseProps {
    className?: string;
}

export default function Icons8Warehouse(props: IIcons8WarehouseProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 24 5.015625 C 22.851301 5.015625 21.70304 5.3892757 20.753906 6.1367188 A 1.50015 1.50015 0 0 0 20.751953 6.1367188 L 8.859375 15.507812 C 7.0554772 16.929445 6 19.101786 6 21.398438 L 6 39.488281 C 6 41.403236 7.5850452 42.988281 9.5 42.988281 L 38.5 42.988281 C 40.414955 42.988281 42 41.403236 42 39.488281 L 42 21.398438 C 42 19.101786 40.944523 16.929445 39.140625 15.507812 L 27.246094 6.1367188 C 26.29696 5.3892758 25.148699 5.015625 24 5.015625 z M 24 8.0078125 C 24.489801 8.0078125 24.979759 8.1705836 25.390625 8.4941406 L 37.285156 17.865234 C 38.368508 18.719618 39 20.019609 39 21.398438 L 39 39.488281 C 39 39.783326 38.795045 39.988281 38.5 39.988281 L 36 39.988281 L 36 32.5 C 36 30.585045 34.414955 29 32.5 29 L 31 29 L 31 22.5 C 31 20.585045 29.414955 19 27.5 19 L 20.5 19 C 18.585045 19 17 20.585045 17 22.5 L 17 29 L 15.5 29 C 13.585045 29 12 30.585045 12 32.5 L 12 39.988281 L 9.5 39.988281 C 9.2049548 39.988281 9 39.783326 9 39.488281 L 9 21.398438 C 9 20.019088 9.6307412 18.71765 10.714844 17.863281 L 22.609375 8.4941406 C 23.020241 8.1705836 23.510199 8.0078125 24 8.0078125 z M 20.5 22 L 27.5 22 C 27.795045 22 28 22.204955 28 22.5 L 28 29 L 20 29 L 20 22.5 C 20 22.204955 20.204955 22 20.5 22 z M 15.5 32 L 22.5 32 L 22.5 39.988281 L 15 39.988281 L 15 32.5 C 15 32.204955 15.204955 32 15.5 32 z M 25.5 32 L 32.5 32 C 32.795045 32 33 32.204955 33 32.5 L 33 39.988281 L 25.5 39.988281 L 25.5 32 z"/>
                </svg>
        </span>
    );
}