import {iconUseStyles} from "../styles/IconStyles";

export interface IEuroProps {
    className?: string;
}

export default function EuroIcon(props: IEuroProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <linearGradient id="EGkjG~2l3CnKXUvWzfKQya" x1="15.002" x2="33.584" y1="6.494" y2="42.647"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#0176d0"/>
                    <stop offset="1" stopColor="#16538c"/>
                </linearGradient>
                <path fill="url(#EGkjG~2l3CnKXUvWzfKQya)"
                      d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"/>
                <path
                    d="M31.497,33.094l-0.533-2.468c-0.099-0.452-0.387-0.838-0.791-1.061	c-0.411-0.225-0.898-0.262-1.342-0.099c-0.866,0.317-1.888,0.507-2.731,0.507c-1.044,0-2.495-0.231-3.474-1.333	c-0.159-0.18-0.301-0.389-0.425-0.629h6.233c0.871,0,1.579-0.708,1.579-1.579v-1.167c0-0.57-0.304-1.071-0.759-1.348	c0.455-0.278,0.759-0.778,0.759-1.349v-1.167c0-0.871-0.708-1.579-1.579-1.579h-6.101c0.107-0.186,0.225-0.352,0.35-0.495	c0.759-0.929,1.853-1.4,3.252-1.4c1.061,0,2.047,0.268,2.687,0.492c0.424,0.148,0.895,0.111,1.289-0.102	c0.396-0.214,0.685-0.589,0.792-1.028l0.63-2.564c0.193-0.786-0.225-1.593-0.971-1.876c-1.02-0.388-2.677-0.85-4.787-0.85	c-3.249,0-6.199,1.305-8.318,3.685c-1.024,1.185-1.784,2.575-2.265,4.139h-0.852c-0.871,0-1.579,0.708-1.579,1.579v1.167	c0,0.57,0.304,1.071,0.759,1.349c-0.455,0.277-0.759,0.778-0.759,1.348v1.167c0,0.871,0.708,1.579,1.579,1.579h0.733	c0.358,1.467,0.982,2.766,1.851,3.859C18.71,34.495,21.913,36,25.511,36c1.741,0,3.598-0.406,5.093-1.114	C31.286,34.564,31.654,33.827,31.497,33.094z"
                    opacity=".05"/>
                <path
                    d="M31.009,33.198l-0.534-2.466c-0.067-0.311-0.265-0.576-0.543-0.729	c-0.283-0.156-0.622-0.181-0.929-0.068c-0.919,0.337-2.004,0.538-2.903,0.538c-1.146,0-2.745-0.261-3.849-1.501	c-0.335-0.378-0.598-0.859-0.797-1.46h6.98c0.595,0,1.079-0.484,1.079-1.079v-1.167c0-0.595-0.484-1.079-1.079-1.079h-7.597v-0.024	c0-0.166,0-0.341,0.004-0.515h7.593c0.595,0,1.079-0.484,1.079-1.079v-1.167c0-0.595-0.484-1.079-1.079-1.079h-6.887	c0.201-0.527,0.457-0.979,0.749-1.311c0.857-1.051,2.082-1.584,3.639-1.584c1.131,0,2.176,0.283,2.853,0.52	c0.293,0.103,0.614,0.077,0.885-0.07c0.272-0.147,0.471-0.405,0.545-0.708l0.63-2.564c0.133-0.541-0.152-1.096-0.663-1.29	c-1.386-0.527-3.022-0.817-4.609-0.817c-3.104,0-5.922,1.245-7.939,3.512c-1.056,1.221-1.817,2.67-2.269,4.312h-1.227	c-0.595,0-1.079,0.484-1.079,1.079v1.167c0,0.595,0.484,1.079,1.079,1.079h0.736c-0.008,0.161-0.016,0.322-0.016,0.482v0.057h-0.721	c-0.595,0-1.079,0.484-1.079,1.079v1.167c0,0.595,0.484,1.079,1.079,1.079h1.134c0.322,1.554,0.945,2.919,1.849,4.058	c1.891,2.498,4.948,3.931,8.388,3.931c1.669,0,3.448-0.389,4.879-1.066C30.862,34.211,31.116,33.703,31.009,33.198z M29.732,17.052	L29.732,17.052L29.732,17.052L29.732,17.052z"
                    opacity=".07"/>
                <path fill="#ffe357"
                      d="M30.519,33.303c0.06,0.279-0.084,0.557-0.342,0.679C29.039,34.52,27.351,35,25.51,35 c-3.208,0-6.155-1.31-7.989-3.732c-0.883-1.113-1.538-2.521-1.833-4.256H14.14c-0.32,0-0.579-0.259-0.579-0.579v-1.167 c0-0.32,0.259-0.579,0.579-0.579h1.221c0-0.164,0-0.36,0-0.556c0-0.328,0.033-0.655,0.033-0.982H14.14 c-0.32,0-0.579-0.259-0.579-0.579v-1.167c0-0.32,0.259-0.579,0.579-0.579h1.615c0.425-1.768,1.211-3.273,2.259-4.485 C19.879,14.244,22.498,13,25.576,13c1.741,0,3.283,0.348,4.431,0.785c0.28,0.106,0.427,0.412,0.355,0.703l-0.63,2.564 c-0.085,0.346-0.443,0.542-0.779,0.425c-0.819-0.287-1.888-0.548-3.018-0.548c-1.571,0-3.011,0.524-4.027,1.768 c-0.459,0.524-0.818,1.277-1.048,2.128h7.573c0.32,0,0.579,0.259,0.579,0.579v1.167c0,0.32-0.259,0.579-0.579,0.579h-8.064 c-0.033,0.328-0.033,0.688-0.033,1.015c0,0.197,0,0.327,0,0.524h8.097c0.32,0,0.579,0.259,0.579,0.579v1.167 c0,0.32-0.259,0.579-0.579,0.579h-7.638c0.229,0.982,0.589,1.735,1.081,2.291c1.048,1.179,2.586,1.67,4.222,1.67 c1.132,0,2.282-0.277,3.075-0.568c0.35-0.128,0.733,0.069,0.811,0.433L30.519,33.303z"/>
            </svg>
        </span>
    );
}





