import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8PavilionProps {
    className?: string;
}

export default function Icons8Pavilion(props: IIcons8PavilionProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
              <path
                  d="M 24 5 C 23.3 5 23.026564 5.1773438 22.726562 5.2773438 A 1.50015 1.50015 0 0 0 22.560547 5.3417969 L 6.9980469 12.666016 A 1.50015 1.50015 0 0 0 6.7285156 12.792969 L 3.8613281 14.142578 A 1.5001654 1.5001654 0 0 0 5.1386719 16.857422 L 6 16.451172 L 6 41.5 A 1.50015 1.50015 0 1 0 9 41.5 L 9 15.041016 L 23.789062 8.0800781 C 23.849866 8.0666363 23.918761 8.0527153 23.976562 8.0390625 C 24.066609 8.0655465 24.22222 8.0876722 24.128906 8.0410156 A 1.50015 1.50015 0 0 0 24.162109 8.0566406 L 39 15.041016 L 39 41.5 A 1.50015 1.50015 0 1 0 42 41.5 L 42 16.451172 L 42.861328 16.857422 A 1.5001655 1.5001655 0 1 0 44.138672 14.142578 L 41.259766 12.787109 A 1.50015 1.50015 0 0 0 41 12.666016 L 25.470703 5.3574219 C 24.912932 5.0785364 24.7 5 24 5 z"/>
              </svg>
        </span>
    );
}
























