import * as React from 'react';
import TextField from '@mui/material/TextField';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {IFormTimePickerProps} from "./InputProps";
import GreyLabel from "../decorations/GreyLabel";
import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material";
import {useStyles as formUseStyles} from "./FormBuilder";
import {DateTime} from "luxon";
import {useContext, useEffect, useRef, useState} from "react";
import Debouncer from "../../../utils/Debouncer";
import {kUserInputDebounce} from "../../../core/constants";
import {AppDataContext} from "../../../AppData";


const useStyles = makeStyles()((theme: Theme) => ({
    topLabelPadding: {
        paddingTop: 16,
        paddingBottom: 16,
    }
}));

export default function FormTimePicker(props: IFormTimePickerProps) {
    const {
        index,
        className,
        value,
        updateValue,
        hidden,
        label,
        topLabel,
        disabled,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const {classes: formClasses} = formUseStyles();
    const {classes, cx} = useStyles();

    const updateValueTimeout = useRef(new Debouncer(kUserInputDebounce));

    useEffect(() => {
        return () => {
            updateValueTimeout.current?.dispose();
        }
    }, []);

    const lastVal = useRef<any>(null);
    const OnChangeField = (val: any) => {
        lastVal.current = val;

        if (!val || isNaN(val.$H) || isNaN(val.$m)) {
            return;
        }

        const dateValue = value ? DateTime.fromMillis(value) : null;

        const theDate = (dateValue ? dateValue.set({
            hour: val.$H >= 24 ? 0 : val.$H,
            minute: val.$m,
        }) : DateTime.fromObject({
            hour: val.$H >= 24 ? 0 : val.$H,
            minute: val.$m,
        })).toMillis();

        lastVal.current = null;
        updateValueTimeout.current!.run(() => {
            updateValue(index, theDate);
        });
    };

    const resetValue = () => {
        if (lastVal.current) {
            updateValue(index, DateTime.fromObject({
                hour: lastVal.current && isNaN(lastVal.current.$H) ? 0 : lastVal.current.$H,
                minute: lastVal.current && isNaN(lastVal.current.$m) ? 0 : lastVal.current.$m
            }).toMillis());
        }
    };

    const theValue = value ? DateTime.fromMillis(value).toJSDate() : null;

    return (
        <div className={cx(className, (hidden ? formClasses.hidden : undefined))}>
            {topLabel ? <GreyLabel className={classes.topLabelPadding} text={topLabel}/> : null}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
                <TimePicker
                    DialogProps={{
                        style: {
                            zIndex: 1400
                        }
                    }}
                    PopperProps={
                        {
                            sx: {zIndex: 1400},
                        }
                    }
                    value={theValue}
                    disabled={disabled}
                    inputFormat={"HH:mm"}
                    ampm={false}
                    ampmInClock={false}
                    renderInput={(params) => <TextField
                        {...params}
                        fullWidth={true}
                        variant="filled"
                        label={label}
                        onBlur={resetValue}
                    />}
                    onChange={(e: any) => OnChangeField(e)}
                />
            </LocalizationProvider>
        </div>
    );
}
