import React, {useContext} from "react";
import {AppDataContext} from "../../../AppData";
import {usePopupState} from "material-ui-popup-state/hooks";
import AppIconButton from "../buttons/AppIconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {ChangeLanguage, tt} from "../../../core/Localization";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import ENIcon from "../../../icons/languages/ENIcon";
import SKIcon from "../../../icons/languages/SKIcon";
import CZIcon from "../../../icons/languages/CZIcon";
import DEIcon from "../../../icons/languages/DEIcon";
import UKIcon from "../../../icons/languages/UKIcon";
import RUIcon from "../../../icons/languages/RUIcon";
import {useMutation} from "@apollo/client";
import {
    UserUpdateLanguageDocument,
    UserUpdateLanguageMutation,
    UserUpdateLanguageMutationVariables
} from "../../../generated/graphql/graphql";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import {AppContext} from "../../../App";

export interface ILanguageMenuProps {
    className?: string;
}

export default function LanguagesMenu(props: ILanguageMenuProps) {
    const {className} = props;

    const appContext = useContext(AppContext);
    const {authUser} = appContext;
    const appDataContext = useContext(AppDataContext);
    const {language, setLanguage} = appDataContext;

    const [mutateLanguage, {
        loading: languageLoading,
        error: languageError,
        data: languageData,
    }] = useMutation<UserUpdateLanguageMutation, UserUpdateLanguageMutationVariables>(UserUpdateLanguageDocument);

    /**
     * Mutate language to BE for current User.
     */
    const UpdateLanguage = async (language: string) => {
        if (!authUser) {
            return;
        }

        try {
            const variables: UserUpdateLanguageMutationVariables = {
                input: {
                    language,
                },
            };

            const result = await mutateLanguage({variables});

            if (!result.errors) {
                SuccessToast(tt('languageMenu.update.success'));
            }
        } catch (e) {
            console.error(e);

            ErrorToast(tt('common.mutation.error'));
        }
    };

    const languagesPopupState = usePopupState({
        variant: 'popover',
        popupId: 'popup-languages',
    });

    let currentLanguageJSX;

    switch (language) {
        case 'en':
            currentLanguageJSX = <ENIcon/>;
            break;
        case 'cs':
            currentLanguageJSX = <CZIcon/>;
            break;
        case 'sk':
            currentLanguageJSX = <SKIcon/>;
            break;
        case 'de':
            currentLanguageJSX = <DEIcon/>;
            break;
        case 'uk':
            currentLanguageJSX = <UKIcon/>;
            break;
        case 'ru':
            currentLanguageJSX = <RUIcon/>;
            break;
    }

    return (
        <>
            <AppIconButton tooltip={tt('languageMenu.choseLanguage')}
                           className={className} {...bindTrigger(languagesPopupState)}>
                {currentLanguageJSX}
            </AppIconButton>

            <Menu {...bindMenu(languagesPopupState)}>
                <MenuItem selected={language === 'de'}
                          onClick={() => {
                              ChangeLanguage('de', setLanguage);
                              languagesPopupState.close();

                              UpdateLanguage('de');
                          }
                          }>
                    <DEIcon/>
                    Deutch
                </MenuItem>
                <MenuItem selected={language === 'en'}
                          onClick={() => {
                              ChangeLanguage('en', setLanguage);
                              languagesPopupState.close();

                              UpdateLanguage('en');
                          }
                          }>
                    <ENIcon/>
                    English
                </MenuItem>
                <MenuItem selected={language === 'cs'}
                          onClick={() => {
                              ChangeLanguage('cs', setLanguage);
                              languagesPopupState.close();

                              UpdateLanguage('cs');
                          }
                          }>
                    <CZIcon/>
                    Čeština
                </MenuItem>
                <MenuItem selected={language === 'sk'}
                          onClick={() => {
                              ChangeLanguage('sk', setLanguage);
                              languagesPopupState.close();

                              UpdateLanguage('sk');
                          }
                          }>
                    <SKIcon/>
                    Slovenčina
                </MenuItem>
                <MenuItem selected={language === 'ru'}
                          onClick={() => {
                              ChangeLanguage('ru', setLanguage);
                              languagesPopupState.close();

                              UpdateLanguage('ru');
                          }
                          }>
                    <RUIcon/>
                    Русский
                </MenuItem>
                <MenuItem selected={language === 'uk'}
                          onClick={() => {
                              ChangeLanguage('uk', setLanguage);
                              languagesPopupState.close();

                              UpdateLanguage('uk');
                          }
                          }>
                    <UKIcon/>
                    Українська
                </MenuItem>
            </Menu>
        </>
    );
}
