import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8DownloadProps {
    className?: string;
}

/**
 * Component representing Icons8Download svg icon.
 */
export default function Icons8Download(props: IIcons8DownloadProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 23.970703 5.9726562 A 2.0002 2.0002 0 0 0 22 8 L 22 27.171875 L 19.414062 24.585938 A 2.0002 2.0002 0 0 0 17.978516 23.980469 A 2.0002 2.0002 0 0 0 16.585938 27.414062 L 22.585938 33.414062 A 2.0002 2.0002 0 0 0 25.414062 33.414062 L 31.414062 27.414062 A 2.0002 2.0002 0 1 0 28.585938 24.585938 L 26 27.171875 L 26 8 A 2.0002 2.0002 0 0 0 23.970703 5.9726562 z M 12 17 C 8.7099679 17 6 19.709968 6 23 L 6 36 C 6 39.290032 8.7099679 42 12 42 L 36 42 C 39.290032 42 42 39.290032 42 36 L 42 23 C 42 19.709968 39.290032 17 36 17 L 33 17 A 2.0002 2.0002 0 1 0 33 21 L 36 21 C 37.127968 21 38 21.872032 38 23 L 38 36 C 38 37.127968 37.127968 38 36 38 L 12 38 C 10.872032 38 10 37.127968 10 36 L 10 23 C 10 21.872032 10.872032 21 12 21 L 15 21 A 2.0002 2.0002 0 1 0 15 17 L 12 17 z"/>
            </svg>
        </span>
    );
}
