import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import React, {ReactNode} from "react";
import ResponsiveContainer from "../../screens/ResponsiveContainer";

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 8,
        paddingLeft: 16,
    },
    innerContainer: {
        display: "flex",
        alignItems: "center",
        minHeight: 52,
        "@media (max-width: 767px)": {
            marginBottom: 8,
        },
    },
    formControlContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        marginRight: "auto",
        marginBottom: -20,
    },
}));

export interface IJobDetailVisitsTabHeadlineWidgetProps {
    yearsWidget: ReactNode;
    visitsSwitch: ReactNode;
    addIconButton: ReactNode;
}

export default function JobDetailVisitsTabHeadlineWidget(props: IJobDetailVisitsTabHeadlineWidgetProps) {
    const {
        yearsWidget,
        visitsSwitch,
        addIconButton,
    } = props;

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body isMobile={isMobile}
                  yearsWidget={yearsWidget}
                  visitsSwitch={visitsSwitch}
                  addIconButton={addIconButton}
            />
        );
    }

    return (
        <ResponsiveContainer
            smallPhoneScreen={bodyJSX(true)}
            largePhoneScreen={bodyJSX(true)}
            tabletScreen={bodyJSX()}
            smallDesktopScreen={bodyJSX()}
            largeDesktopScreen={bodyJSX()}
            extraLargeDesktopScreen={bodyJSX()}
        />
    );
}

interface IBodyProps {
    isMobile?: boolean;
    yearsWidget: ReactNode;
    visitsSwitch: ReactNode;
    addIconButton: ReactNode;
}

function Body(props: IBodyProps) {
    const {
        yearsWidget,
        visitsSwitch,
        addIconButton,
        isMobile,
    } = props;

    const {classes} = useStyles();

    return (
        <Box className={classes.container}>
            <Box className={classes.innerContainer}>
                <Box className={classes.formControlContainer}>{isMobile ? null : visitsSwitch}{yearsWidget}</Box>
                {addIconButton}
            </Box>
            {isMobile ? <Box pr={1}>{visitsSwitch}</Box> : null}
        </Box>
    );
}