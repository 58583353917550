import {iconUseStyles} from "../../styles/IconStyles";

export interface ISKIconProps {
    className?: string;
}

export default function UKIcon(props: ISKIconProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                 width="24"
                 height="24"
                 viewBox="0 0 48 48">
                <g>
                    <path fill="#FDD835" d="M44,24H4c0,11.046,8.954,20,20,20S44,35.046,44,24z"/>
                    <path fill="#2196F3" d="M24,4C12.954,4,4,12.954,4,24h40C44,12.954,35.046,4,24,4z"/>
                </g>
            </svg>
        </span>
    );
}
