import AppModal from "./AppModal";
import {tt} from "../../../core/Localization";
import AppButton from "../buttons/AppButton";
import React, {Dispatch, SetStateAction} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import ImageCropper from "../storage/ImageCropper";

export const useStyles = makeStyles()((theme: Theme) => ({
    modalPaper: {
        maxWidth: 600,
    },
}));

export interface ICropImageModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onSave: (blob: Blob | null) => void;
    img: string;
    title?: string;
    isLoading?: boolean;
}

export default function (props: ICropImageModalProps) {
    const {
        open,
        setOpen,
        onSave,
        img,
        title,
        isLoading
    } = props;

    const {classes} = useStyles();

    const [blob, setBlob] = React.useState<Blob | null>(null);

    return (
        <AppModal
            blurBackdrop={true}
            paperClassName={classes.modalPaper}
            open={open}
            setOpen={setOpen}
            title={title || tt('common.image.cropModal.title')}
            actions={
                <AppButton
                    fullWidth={true}
                    variant={"contained"}
                    onClick={() => {
                        onSave(blob);
                    }}
                    isLoading={isLoading}
                >
                    {tt('common.save')}
                </AppButton>}
        >
            <Box pb={2}/>

            <ImageCropper
                img={img}
                onCropComplete={(croppedArea, croppedAreaPixels) => {
                    const canvasEle = document.createElement("canvas");
                    canvasEle.width = croppedAreaPixels.width;
                    canvasEle.height = croppedAreaPixels.height;

                    const context = canvasEle.getContext("2d");

                    let imageObj1 = new Image();
                    imageObj1.src = img;
                    imageObj1.onload = function () {
                        context?.drawImage(
                            imageObj1,
                            croppedAreaPixels.x,
                            croppedAreaPixels.y,
                            croppedAreaPixels.width,
                            croppedAreaPixels.height,
                            0,
                            0,
                            croppedAreaPixels.width,
                            croppedAreaPixels.height
                        );

                        canvasEle.toBlob((blob) => {
                            setBlob(blob);
                        });
                    }
                }
                }
            />

            <Box pb={3}/>
        </AppModal>
    );
}
