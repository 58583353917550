import {makeStyles} from "tss-react/mui";
import {Autocomplete, Box, IconButton, InputAdornment, TextField, Theme} from "@mui/material";
import {IGooglePlacesAutocompleteField} from "./InputProps";
import {useStyles as formUseStyles} from "./FormBuilder";
import React, {useEffect, useRef, useState} from "react";
import AppButton from "../buttons/AppButton";
import DeleteIcon from "../../../icons/DeleteIcon";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {AddressesForTerms, IAddressesForTermsResult} from "../../../service/MapsService";
import {Close} from "@mui/icons-material";

const useStyles = makeStyles()((theme: Theme) => ({
    suffix: {
        marginLeft: theme.spacing(2),
    },
    icon: {
        color: kAppColors.red.confirmButton,
        'svg': {height: 21, width: 21}
    },
    suffixContainer: {
        display: "flex"
    },
    input: {
        '.MuiInputBase-formControl': {
            paddingTop: 0,
            paddingRight: 12 + 'px !important',
            paddingBottom: 0,
            paddingLeft: 0,
        },
        '.MuiInputBase-input': {
            display: "block",
            paddingTop: 25 + 'px !important',
            paddingBottom: 8 + 'px !important',
            paddingLeft: 12 + 'px !important',
        },
    },
    paper: {
        backgroundColor: kAppColors.background.menuPaper(theme.palette.mode === "dark") + ' !important',
        borderRadius: 10,
        backgroundImage: "unset",
    }
}));

declare global {
    interface Window {
        google: any;
    }
}

/**
 * Form component for input with Google Places autocomplete.
 */
export default function GooglePlacesAutocompleteField(props: IGooglePlacesAutocompleteField) {
    const {
        index,
        className,
        label,
        value,
        updateValue,
        updateProp,
        hidden,
        error,
        errorMessage,
        helperText,
        toggleVisible,
        toggleVisibleValue,
        toggleVisibleText,
        suffixJSX,
        placeholder,
        isClearable,
        testId,
        placeId,
        disabled,
    } = props;

    const {classes: formClasses, cx} = formUseStyles();
    const {classes} = useStyles();

    const [service, setService] = useState<any>();
    const [options, setOptions] = useState<IAddressesForTermsResult[]>([]);

    let showClearButton = false;
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (toggleVisible && value && !toggleVisibleValue) {
            updateProp(index, 'toggleVisibleValue', true);
        }
    }, [toggleVisibleValue, value]);

    /**
     * Search Google api for data.
     */
    const Search = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const terms = e.target.value.trim();

        updateValue(index, terms);

        if (!terms) {
            setOptions([]);
            return;
        }

        const theService = service || new window.google.maps.places.AutocompleteService();
        if (!service) {
            setService(theService);
        }

        setOptions(await AddressesForTerms(theService, terms));
    };

    /**
     * OnChange update data of this field.
     */
    const OnChangeField = (e: React.SyntheticEvent, value: any) => {
        if (isClearable) {
            updateClearButton();
        }

        const selectedOption = options.find((option) => option.description === value);

        if (selectedOption) {
            updateValue(index, selectedOption.description);
            updateProp(index, 'placeId', selectedOption.placeId);
        } else {
            updateValue(index, value);
        }
    };

    function clear() {
        updateValue(index, '');
        ref.current!.focus();
    }

    function updateClearButton() {
        const shouldHideSuffix = value === '' && endAdornmentJSX != null;
        const shouldShow = value !== '' && endAdornmentJSX == null;

        if (shouldHideSuffix) {
            showClearButton = false;
        }
        if (shouldShow) {
            showClearButton = true;
        }
    }

    const helperTextJSX = error ? errorMessage : helperText;

    const endAdornmentJSX = toggleVisible ? (
        <InputAdornment position="end">
            <Box className={classes.suffixContainer}>
                <>
                    {suffixJSX == null ? null : suffixJSX()}

                    <IconButton
                        aria-label="toggle input visibility"
                        onClick={() => {
                            updateProp(index, 'toggleVisibleValue', false);

                            updateValue(index, '');
                            updateProp(index, 'placeId', undefined);
                        }
                        }
                        edge="end"
                    >
                        <DeleteIcon className={classes.icon}/>
                    </IconButton>
                </>
            </Box>
        </InputAdornment>
    ) : (isClearable && value != '' ? <IconButton onClick={clear}>
        <Close/>
    </IconButton> : undefined);

    const content = !toggleVisible || toggleVisibleValue ? (
        <Autocomplete
            disabled={disabled}
            data-testid={testId}
            componentsProps={{
                paper: {
                    className: classes.paper,
                },
            }}
            ref={ref}
            disablePortal={true}
            freeSolo={true}
            value={value}
            options={options.map((option) => option.description)}
            renderInput={(params) => {
                const inputProps = params.InputProps;
                inputProps.endAdornment = endAdornmentJSX;

                return (
                    <TextField {...params} className={classes.input} label={label} variant="filled" error={error}
                               helperText={helperTextJSX} fullWidth={true} margin="none"
                               onChange={Search}
                               InputProps={inputProps}
                               placeholder={placeholder}
                    />
                );
            }}
            onChange={OnChangeField}
        />
    ) : (
        <Box pt={1}>
            <AppButton
                testId={`${testId}ShowInputButton`}
                sx={{py: 0, height: '36px !important', mb: 2, display: hidden ? 'none' : 'block'}}
                onClick={() => updateProp(index, 'toggleVisibleValue', !toggleVisibleValue)}>
                {toggleVisibleText}
            </AppButton>
        </Box>
    );

    return (
        <div className={cx(className, (hidden ? formClasses.hidden : undefined))}>
            {content}
        </div>
    );
}
