import {makeStyles} from "tss-react/mui";
import {Box, Theme, Typography} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {tt} from "../../../core/Localization";
import React, {useContext, useMemo} from "react";
import {
    calculateTimesheets,
    CalculateTotalPrice,
    distanceDisplay,
    HoursMinutesDisplayRaw,
    paymentTypeToItemPaymentType,
    PriceDisplay
} from "../../../service/CompanyService";
import {AppDataContext} from "../../../AppData";
import {CreateJobEmployeeTimesheetItemInput, CreateJobOfferSeatInput} from "../../../generated/graphql/graphql";

const useStyles = makeStyles()((theme: Theme) => ({
    totalSectionContainerOuter: {
        marginTop: 16,
        borderTop: `dashed 1px ${kAppColors.border(theme.palette.mode === "dark")}`,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
        display: "flex",
        justifyContent: "end",
        "@media (max-width: 576px)": {
            justifyContent: "unset",
            width: '100%',
        },
    },
    totalSectionContainerInner: {
        maxWidth: 340,
        width: '100%',
        "@media (max-width: 576px)": {
            maxWidth: '100%',
        },
    },
    singleRow: {
        justifyContent: "end",
        display: "flex",
    },
    singleCell: {
        padding: 8,
        width: '33.3%',
        display: "flex",
        justifyContent: "end",
        textOverflow: "ellipsis",
    },
    totalValueContainer: {
        borderTop: `3px solid ${kAppColors.red.main}`,
        display: "flex",
        justifyContent: "end",
        "@media (max-width: 576px)": {
            width: "auto",
            marginLeft: "auto",
        }
    },
    totalValueContainerWithoutVAT: {
        border: 'none',
    },
    label: {
        fontSize: 12,
        textAlign: "end",
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontWeight: 600,
        textTransform: "uppercase",
        marginBottom: 4,
    },
    value: {
        textAlign: "end",
        fontWeight: 600,
    },
    total: {
        padding: 8,
        fontWeight: 600,
        fontSize: 20,
    },
}));

export interface IWorkersTotalCostSectionProps {
    timesheets: CreateJobEmployeeTimesheetItemInput[],
    jobOfferSeats: CreateJobOfferSeatInput[],
    multiplier?: number,
}

export default function NewJobTimesheetsTotalSection(props: IWorkersTotalCostSectionProps) {
    const {
        timesheets,
        jobOfferSeats,
        multiplier,
    } = props;

    const {classes} = useStyles();
    const appDataContext = useContext(AppDataContext);
    const {currency, language} = appDataContext;

    return useMemo(() => {
        const stats = calculateTimesheets({
            timesheets: timesheets,
            countNotApproved: true,
        });

        let totalHours = stats.hours;
        let totalMinutes = stats.minutes;

        jobOfferSeats.forEach((value, index) => {
            totalHours += value.hours || 0;
            totalMinutes += value.minutes || 0;
        });

        totalHours += Math.floor(totalMinutes / 60);
        totalMinutes = totalMinutes % 60;

        let totalMoney = stats.totalPrice;

        totalMoney += CalculateTotalPrice({
            timesheets: jobOfferSeats.map(seat => ({
                paymentType: paymentTypeToItemPaymentType(seat.paymentType),
                hours: seat.hours,
                minutes: seat.minutes,
                hourRate: seat.hourRate,
                fixedPrice: seat.fixedPrice,
                approved: true,
            })),
            currency,
            language,
        });


        return (
            <Box className={classes.totalSectionContainerOuter}>
                <Box className={classes.totalSectionContainerInner}>
                    <Box className={classes.singleRow}>
                        <Box className={classes.singleCell}>
                            <Typography className={classes.label}>
                                {tt('workersTotalCostSection.time')}
                            </Typography>
                        </Box>
                        
                        <Box className={classes.singleCell}>
                            <Typography className={classes.label}>
                                {tt('workersTotalCostSection.distance')}
                            </Typography>
                        </Box>

                        <Box className={classes.singleCell}>
                            <Typography className={classes.label}>
                                {tt('workersTotalCostSection.costs')}
                            </Typography>
                        </Box>
                    </Box>

                    <Box
                        className={classes.singleRow}>
                        <Box className={classes.singleCell}>
                            <Typography
                                className={classes.value}>
                                {HoursMinutesDisplayRaw({
                                    hours: totalHours,
                                    minutes: totalMinutes,
                                    returnZeroHoursInsteadOfUndefined: true,
                                    language,
                                })}
                            </Typography>
                        </Box>

                        <Box className={classes.singleCell}>
                            <Typography
                                className={classes.value}>
                                {distanceDisplay(stats.travel.distance, language, true)}
                            </Typography>
                        </Box>

                        <Box className={classes.singleCell}>
                            <Typography
                                className={classes.value}>
                                {multiplier ? `${multiplier}   x   ` : null}
                                {PriceDisplay(totalMoney, currency, language, true)}
                            </Typography>
                        </Box>
                    </Box>

                    <Box
                        className={classes.totalValueContainer}>
                        <Typography
                            className={classes.total}>{multiplier ? PriceDisplay(multiplier * totalMoney, currency, language, true) : PriceDisplay(totalMoney, currency, language, true)}</Typography>
                    </Box>
                </Box>
            </Box>
        );
    }, [timesheets, jobOfferSeats, multiplier, currency, language]);
}
