import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8PaintRollerProps {
    className?: string;
}

export default function Icons8PaintRoller(props: IIcons8PaintRollerProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
               <path
                   d="M 9.5 5 C 7.5850452 5 6 6.5850452 6 8.5 L 6 14.5 C 6 16.414955 7.5850452 18 9.5 18 L 34.5 18 C 36.414955 18 38 16.414955 38 14.5 L 38 13 L 39.5 13 C 40.346499 13 41 13.653501 41 14.5 L 41 19.1875 C 41 19.876252 40.54766 20.461165 39.880859 20.636719 L 25.353516 24.460938 C 23.384316 24.979384 21.998047 26.775252 21.998047 28.8125 L 21.998047 30 L 21.5 30 C 20.136406 30 19 31.136406 19 32.5 L 19 42.5 C 19 43.863594 20.136406 45 21.5 45 L 25.5 45 C 26.863594 45 28 43.863594 28 42.5 L 28 32.5 C 28 31.136406 26.863594 30 25.5 30 L 24.998047 30 L 24.998047 28.8125 C 24.998047 28.123748 25.450387 27.536882 26.117188 27.361328 L 40.644531 23.539062 C 42.613731 23.020616 44 21.224748 44 19.1875 L 44 14.5 C 44 12.032499 41.967501 10 39.5 10 L 38 10 L 38 8.5 C 38 6.5850452 36.414955 5 34.5 5 L 9.5 5 z M 9.5 8 L 34.5 8 C 34.795045 8 35 8.2049548 35 8.5 L 35 14.5 C 35 14.795045 34.795045 15 34.5 15 L 9.5 15 C 9.2049548 15 9 14.795045 9 14.5 L 9 8.5 C 9 8.2049548 9.2049548 8 9.5 8 z M 22 33 L 25 33 L 25 42 L 22 42 L 22 33 z"/>
               </svg>
        </span>
    );
}
























