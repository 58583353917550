import {makeStyles} from "tss-react/mui";
import {Box, Button, Theme, Typography} from "@mui/material";
import {MouseEventHandler} from "react";
import {kAppColors} from "../../../styles/AppThemeProcessor";

const useStyles = makeStyles()((theme: Theme) => ({
    base: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 20,
        paddingRight: 20,
        minHeight: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    primary: {
        minHeight: 40,
        backgroundColor: theme.palette.mode === "dark" ? 'white' : kAppColors.text.secondaryLightModeGrey,
        color: theme.palette.mode === "dark" ? 'white' : kAppColors.text.secondaryLightModeGrey,
        ':hover': {
            backgroundColor: theme.palette.mode === "dark" ? 'white' : kAppColors.text.secondaryLightModeGrey,
        },
        '.textSuffix': {
            color: kAppColors.text.tertiary(theme.palette.mode === "dark"),
        }
    },
    secondary: {
        border: `1px solid ${kAppColors.border(theme.palette.mode === "dark")}`,
        backgroundColor: theme.palette.mode === "dark" ? '#202020' : 'white',
        color: theme.palette.mode === "dark" ? '#292929' : '#EEF0F3',
        ':hover': {
            backgroundColor: theme.palette.mode === "dark" ? '#292929' : '#EEF0F3',
        },
        '.textSuffix': {
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
        }
    },
    text1: {
        fontSize: 12,
        fontWeight: 500,
        color: kAppColors.text.tertiary(theme.palette.mode === "dark"),
        lineHeight: 1,
        paddingBottom: 4,
        whiteSpace: "nowrap",
    },
    text2: {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 1,
        color: kAppColors.text.tertiary(theme.palette.mode === "dark"),
        whiteSpace: "nowrap",
    },
    svgPrimary: {
        'svg': {
            color: kAppColors.text.tertiary(theme.palette.mode === "dark"),
        }
    },
    text1Secondary: {
        color: theme.palette.mode === "dark" ? 'white' : '#3e3e3e',
    },
    text2Secondary: {
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
    },
    svgSecondary: {
        'svg': {
            color: theme.palette.mode === "dark" ? 'white' : '#3e3e3e',
        }
    },
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    isDisabled: {
        opacity: 0.5,
        pointerEvents: "none",
        cursor: "default",
    },
}));

export interface ITwoLIneChipProps {
    className?: string;
    text1: string;
    text2?: string;
    variant?: 'primary' | 'secondary' | undefined;
    onClick?: MouseEventHandler;
    suffix?: React.ReactNode;
    prefix?: React.ReactNode;
    isDisabled?: boolean;
}

export default function TwoLineChip(props: ITwoLIneChipProps) {
    const {variant, className, text1, text2, onClick, suffix, prefix, isDisabled} = props;
    const {classes, cx} = useStyles();

    const theChipClass = cx(
        classes.base,
        className,
        variant == 'primary' || variant == undefined ? classes.primary : undefined,
        variant == 'secondary' ? classes.secondary : undefined,
        isDisabled ? classes.isDisabled : undefined,
    );

    const contentJSX = text2 == null ?
        <Typography className={cx(classes.text2, variant === "secondary" ? classes.text2Secondary : null)}>
            {text1}
        </Typography> : <Box>
            <Typography className={cx(classes.text1, variant === "secondary" ? classes.text1Secondary : null)}>
                {text1}
            </Typography>
            <Typography className={cx(classes.text2, variant === "secondary" ? classes.text2Secondary : null)}>
                {text2}
            </Typography>
        </Box>;

    return (
        <Button
            onClick={onClick}
            className={theChipClass}
        >
            <Box className={cx(classes.container, variant === "secondary" ? classes.svgSecondary : classes.svgPrimary)}>
                {prefix}
                {contentJSX}
                {suffix}
            </Box>
        </Button>
    );
}
