import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef} from "react";
import Debouncer from "../../utils/Debouncer";

export const kTransitionalRoute = '/transitional/:target';

export const kTransitionDuration = 150;

/**
 * Create route for transitional screen.
 * Target is uri-encoded.
 */
export function transitionalRoute(target: string) {
    return kTransitionalRoute.replace(':target', encodeURIComponent(target));
}

/**
 * Transitional screen component without any content.
 * Used for certain processes that require a screen to be shown, but restrict API access.
 */
export default function TransitionalScreen() {
    const {target} = useParams();
    const navigate = useNavigate();

    const navigateToTargetDebouncer = useRef(new Debouncer(kTransitionDuration));

    useEffect(() => {
        if (target) {
            navigateToTargetDebouncer.current!.run(() => {
                navigate(decodeURIComponent(target));
            });
        } else {
            console.error('TCH_d', 'No target provided for transitional screen.');
        }

        return () => {
            navigateToTargetDebouncer.current?.dispose();
        };
    }, [target]);

    return null;
}
