import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8LayersProps {
    className?: string;
}

export default function Icons8Layers(props: IIcons8LayersProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 14.394531 6 C 11.986481 6 9.8462087 7.5805753 9.1386719 9.8828125 L 7.2011719 16.177734 C 6.323902 19.031289 8.5168504 22 11.501953 22 L 33.605469 22 C 36.01292 22 38.154421 20.419161 38.861328 18.117188 L 38.861328 18.115234 L 40.796875 11.824219 L 40.796875 11.822266 C 41.674145 8.9688031 39.481197 6 36.496094 6 L 14.394531 6 z M 14.394531 9 L 36.496094 9 C 37.546304 9 38.238802 9.9375655 37.929688 10.941406 L 35.992188 17.236328 C 35.668557 18.291424 34.709419 19 33.605469 19 L 11.501953 19 C 10.451743 19 9.7592455 18.062435 10.068359 17.058594 L 12.005859 10.765625 C 12.330323 9.7098622 13.290581 9 14.394531 9 z M 40.699219 18.894531 C 40.090219 20.695531 38.863016 22.137438 37.291016 23.023438 L 35.994141 27.234375 C 35.669141 28.290375 34.710469 29 33.605469 29 L 11.501953 29 C 11.020953 29 10.580922 28.777625 10.294922 28.390625 C 10.008922 28.003625 9.9263594 27.518594 10.068359 27.058594 L 11.017578 23.970703 C 9.9875781 23.895703 9.01525 23.586359 8.15625 23.068359 L 7.2011719 26.175781 C 6.7771719 27.554781 7.0258125 29.010875 7.8828125 30.171875 C 8.7398125 31.333875 10.058953 32 11.501953 32 L 33.605469 32 C 36.035469 32 38.148281 30.440188 38.863281 28.117188 L 40.800781 21.824219 C 41.102781 20.841219 41.056219 19.820531 40.699219 18.894531 z M 40.699219 28.894531 C 40.090219 30.695531 38.863016 32.137438 37.291016 33.023438 L 35.994141 37.234375 C 35.669141 38.290375 34.710469 39 33.605469 39 L 11.501953 39 C 11.020953 39 10.580922 38.777625 10.294922 38.390625 C 10.008922 38.003625 9.9263594 37.518594 10.068359 37.058594 L 11.017578 33.970703 C 9.9875781 33.895703 9.01525 33.586359 8.15625 33.068359 L 7.2011719 36.175781 C 6.7771719 37.554781 7.0258125 39.010875 7.8828125 40.171875 C 8.7398125 41.333875 10.058953 42 11.501953 42 L 33.605469 42 C 36.035469 42 38.148281 40.440188 38.863281 38.117188 L 40.800781 31.824219 C 41.102781 30.841219 41.056219 29.820531 40.699219 28.894531 z"/>
            </svg>
        </span>
    );
}


