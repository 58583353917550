import {
    GpsLocationStatus,
    JobOfferSeatResponse,
    JobPureResponse,
    JobResponse,
    JobStatsResponse,
    Maybe,
    VisitStatus,
    VisitStatusModifier
} from "../generated/graphql/graphql";
import {DateTime} from "luxon";
import {tt} from "../core/Localization";
import React, {ReactNode} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Theme, Tooltip, useTheme} from "@mui/material";
import VisitStatusChip from "../ui/components/chips/VisitStatusChip";
import Icons8Reset from "../icons/Icons8Reset";
import {kAppColors, kVisitStatusColors} from "../styles/AppThemeProcessor";
import AppChip from "../ui/components/chips/AppChip";
import CustomStatusLikeChip from "../ui/components/chips/CustomStatusLikeChip";

export const kJobsListDisplayLimitPage = 30;

export const useStyles = makeStyles()((theme: Theme) => ({
    marginRight: {
        marginRight: 4,
        marginBottom: 4,
    },
    verticalDivider: {
        width: 2,
        height: 22,
        background: kAppColors.border(theme.palette.mode === "dark"),
        marginRight: 4,
    },
}));

/**
 * Find single JobOfferSeat from DB for uuid and optional repeatingDay.
 */
export function findJobOfferSeatForUuid(visitOfferSeats: JobOfferSeatResponse[] | NullOrUndefined, uuid: string | NullOrUndefined, repeatingDay?: number): JobOfferSeatResponse | undefined {
    if (visitOfferSeats && uuid) {
        if (!repeatingDay) {
            return visitOfferSeats.find((offerSeat) => offerSeat.uuid === uuid && !offerSeat.repeatingDay);
        } else {
            const theJobOfferSeat = visitOfferSeats.find((offerSeat) => offerSeat.uuid === uuid && offerSeat.repeatingDay === repeatingDay);

            return theJobOfferSeat || visitOfferSeats.find((offerSeat) => offerSeat.uuid === uuid && !offerSeat.repeatingDay);
        }
    }

    return undefined;
}

/**
 * Check if JobOfferSeatResponse full seat and substitutes.
 */
export function isJobOfferSeatFull(visitOfferSeat: JobOfferSeatResponse): boolean {
    const substituteCount = visitOfferSeat.substituteCount;
    const requiredEmployees = substituteCount + 1;

    return visitOfferSeat.acceptedIds.length >= requiredEmployees;
}

/**
 * Check if JobOfferSeatResponse has any Employee accepted.
 */
export function isJobOfferSeatAccepted(visitOfferSeat: JobOfferSeatResponse): boolean {
    return visitOfferSeat.acceptedIds.length > 0;
}

/**
 * Display Job name or use only sequenceId.
 */
export function JobNameOrSequenceId(job: JobResponse | JobPureResponse): string {
    if (job.name) {
        return `#${job.sequenceId} · ${job.name}`;
    }

    return `#${job.sequenceId}`;
}

/**
 * DateRangeTypes
 * */
export enum DateRangeType {
    none = '',
    custom = '0',
    yesterday = '1',
    today = '2',
    tomorrow = '3',
    thisWeek = '4',
    lastWeek = '5',
    thisMonth = '6',
    lastMonth = '7',
    nextMonth = '8',
    thisYear = '9',
}

export function getDateRangeIntervalData(dateRangeIntervalType: DateRangeType)
    : { title: string, fromDate: DateTime | undefined, toDate: DateTime | undefined } {

    let theTitle;
    let theFromDate;
    let theToDate;

    switch (dateRangeIntervalType) {
        case DateRangeType.custom:
            theTitle = tt('jobs.screen.jobsFilter.custom');
            break;
        case DateRangeType.yesterday:
            theTitle = tt('jobs.screen.jobsFilter.yesterday');
            theFromDate = DateTime.now().minus({day: 1}).startOf('day');
            theToDate = DateTime.now().minus({day: 1}).endOf('day');
            break;
        case DateRangeType.today:
            theTitle = tt('jobs.screen.jobsFilter.today');
            theFromDate = DateTime.now().startOf('day');
            theToDate = DateTime.now().endOf('day');
            break;
        case DateRangeType.tomorrow:
            theTitle = tt('jobs.screen.jobsFilter.tomorrow');
            theFromDate = DateTime.now().plus({day: 1}).startOf('day');
            theToDate = DateTime.now().plus({day: 1}).endOf('day');
            break;
        case DateRangeType.thisWeek:
            theTitle = tt('jobs.screen.jobsFilter.thisWeek');
            theFromDate = DateTime.now().startOf('week');
            theToDate = DateTime.now().endOf('week');
            break;
        case DateRangeType.lastWeek:
            theTitle = tt('jobs.screen.jobsFilter.lastWeek');
            theFromDate = DateTime.now().minus({week: 1}).startOf('week');
            theToDate = DateTime.now().minus({week: 1}).endOf('week');
            break;
        case DateRangeType.thisMonth:
            theTitle = tt('jobs.screen.jobsFilter.thisMonth');
            theFromDate = DateTime.now().startOf('month');
            theToDate = DateTime.now().endOf('month');
            break;
        case DateRangeType.lastMonth:
            theTitle = tt('jobs.screen.jobsFilter.lastMonth');
            theFromDate = DateTime.now().minus({month: 1}).startOf('month');
            theToDate = DateTime.now().minus({month: 1}).endOf('month');
            break;
        case DateRangeType.nextMonth:
            theTitle = tt('jobs.screen.jobsFilter.nextMonth');
            theFromDate = DateTime.now().plus({month: 1}).startOf('month');
            theToDate = DateTime.now().plus({month: 1}).endOf('month');
            break;
        case DateRangeType.thisYear:
            theTitle = tt('jobs.screen.jobsFilter.thisYear');
            theFromDate = DateTime.now().startOf('year');
            theToDate = DateTime.now().endOf('year');
            break;
    }

    return {title: theTitle || '', fromDate: theFromDate, toDate: theToDate};
}

/**
 * Convert to GpsLocationStatus to index.
 */
export function GpsLocationStatusToIndex(status: GpsLocationStatus | Maybe<GpsLocationStatus> | undefined): number {
    switch (status) {
        case GpsLocationStatus.None:
            return 0;
        case GpsLocationStatus.ServiceAndPermissionDisabled:
            return 1;
        case GpsLocationStatus.ServiceDisabled:
            return 2;
        case GpsLocationStatus.PermissionDisabled:
            return 3;
        case GpsLocationStatus.ProvidersRestricted:
            return 4;
        case GpsLocationStatus.FusedUnsupported:
            return 5;
        case GpsLocationStatus.DiagnosesOk:
            return 6;
        default:
            return 0;
    }
}

/**
 * Filter check Job is valid for VisitStatus and VisitStatusModifier.
 */
export function FilterCheckJobIsValidForVisitStatusAndVisitStatusModifier(
    job: JobResponse,
    visitStatus: VisitStatus | undefined,
    visitStatusModifier: VisitStatusModifier | undefined,
): boolean {
    const stats = job.stats;

    let theStatusFilter = !visitStatus || visitStatus === VisitStatus.None;

    if (visitStatus && visitStatus !== VisitStatus.None && stats) {
        switch (visitStatus) {
            case VisitStatus.ScheduleLater:
                theStatusFilter = stats.scheduleLater > 0;
                break;
            case VisitStatus.Travelling:
                theStatusFilter = stats.travelling > 0;
                break;
            case VisitStatus.Scheduled:
                theStatusFilter = stats.scheduled > 0;
                break;
            case VisitStatus.InProgress:
                theStatusFilter = stats.inProgress > 0;
                break;
            case VisitStatus.Done:
                theStatusFilter = stats.done > 0;
                break;
            case VisitStatus.Canceled:
                theStatusFilter = stats.canceled > 0;
                break;
            case VisitStatus.Unfinished:
                theStatusFilter = stats.unfinished > 0;
                break;
            case VisitStatus.JobOffer:
                theStatusFilter = stats.jobOffer > 0;
                break;
            case VisitStatus.Closed:
                theStatusFilter = stats.closed > 0;
                break;
        }
    }

    if (visitStatus === VisitStatus.Closed && visitStatusModifier && stats) {
        switch (visitStatusModifier) {
            case VisitStatusModifier.Invoiced:
                theStatusFilter = stats.invoiced > 0;
                break;
            case VisitStatusModifier.Paid:
                theStatusFilter = stats.paid > 0;
                break;
            case VisitStatusModifier.NotPaid:
                theStatusFilter = stats.notPaid > 0;
                break;
        }
    }

    return theStatusFilter;
}

export function GetJobVisitsChips(stats: Maybe<JobStatsResponse> | undefined): Array<ReactNode> {
    let chips = [];

    const theme = useTheme();

    const {classes} = useStyles();

    if (stats?.hasRepeatingVisits) {
        chips.push(
            <Tooltip
                key={'repeating'}
                title={tt('jobItem.chipTooltip.containsRepeatingVisits')}
                slotProps={{
                    popper: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -4],
                                },
                            },
                        ],
                    },
                }}
            >
                <Box className={classes.marginRight}>
                    <CustomStatusLikeChip
                        textColor={kVisitStatusColors.scheduled.text}
                        backgroundColor={kVisitStatusColors.scheduled.background(theme.palette.mode === "dark")}
                        icon={<Icons8Reset/>}
                    />
                </Box>
            </Tooltip>
        )
    }

    if (stats?.unfinished) {
        chips.push(
            <Box
                key={'unfinished'}
            >
                <VisitStatusChip
                    className={classes.marginRight}
                    status={VisitStatus.Unfinished}
                    customValue={stats.unfinished > 9 ? '9+' : stats.unfinished}
                />
            </Box>
        )
    }

    if (stats?.scheduleLater) {
        chips.push(
            <Box
                key={'scheduleLater'}
            >
                <VisitStatusChip
                    className={classes.marginRight}
                    status={VisitStatus.ScheduleLater}
                    customValue={stats.scheduleLater > 9 ? '9+' : stats.scheduleLater}
                />
            </Box>
        )
    }

    if (stats?.jobOffer) {
        chips.push(
            <Box
                key={'jobOffer'}
            >
                <VisitStatusChip
                    className={classes.marginRight}
                    status={VisitStatus.JobOffer}
                    customValue={stats.jobOffer > 9 ? '9+' : stats.jobOffer}
                />
            </Box>
        )
    }

    if (stats?.scheduled) {
        chips.push(
            <Box
                key={'scheduled'}
            >
                <VisitStatusChip
                    className={classes.marginRight}
                    status={VisitStatus.Scheduled}
                    customValue={stats.scheduled > 9 ? '9+' : stats.scheduled}
                />
            </Box>
        )
    }

    if (stats?.travelling) {
        chips.push(
            <Box
                key={'travelling'}
            >
                <VisitStatusChip
                    className={classes.marginRight}
                    status={VisitStatus.Travelling}
                    customValue={stats.travelling > 9 ? '9+' : stats.travelling}
                />
            </Box>
        )
    }

    if (stats?.inProgress) {
        chips.push(
            <Box
                key={'inProgress'}
            >
                <VisitStatusChip
                    className={classes.marginRight}
                    status={VisitStatus.InProgress}
                    customValue={stats.inProgress > 9 ? '9+' : stats.inProgress}
                />
            </Box>
        )
    }

    if (stats?.done) {
        chips.push(
            <Box
                key={'done'}
            >
                <VisitStatusChip
                    className={classes.marginRight}
                    status={VisitStatus.Done}
                    customValue={stats.done > 9 ? '9+' : stats.done}
                />
            </Box>
        )
    }

    let modifiersChips = [];
    if (stats?.closed) {
        modifiersChips.push(
            <Box
                key={'closed'}
            >
                <VisitStatusChip
                    className={classes.marginRight}
                    status={VisitStatus.Closed}
                    customValue={stats.closed > 9 ? '9+' : stats.closed}
                />
            </Box>
        )
    }

    if (stats?.invoiced) {
        modifiersChips.push(
            <Box
                key={'invoiced'}
            >
                <VisitStatusChip
                    className={classes.marginRight}
                    statusModifier={VisitStatusModifier.Invoiced}
                    customValue={stats.invoiced > 9 ? '9+' : stats.invoiced}
                />
            </Box>
        )
    }

    if (stats?.paid) {
        modifiersChips.push(
            <Box
                key={'paid'}
            >
                <VisitStatusChip
                    className={classes.marginRight}
                    statusModifier={VisitStatusModifier.Paid}
                    customValue={stats.paid > 9 ? '9+' : stats.paid}
                />
            </Box>
        )
    }

    if (stats?.notPaid) {
        modifiersChips.push(
            <Box
                key={'notPaid'}
            >
                <VisitStatusChip
                    className={classes.marginRight}
                    statusModifier={VisitStatusModifier.NotPaid}
                    customValue={stats.notPaid > 9 ? '9+' : stats.notPaid}
                />
            </Box>
        )
    }

    if (stats?.canceled) {
        modifiersChips.push(
            <Box
                key={'canceled'}
            >
                <VisitStatusChip
                    className={classes.marginRight}
                    status={VisitStatus.Canceled}
                    customValue={stats.canceled > 9 ? '9+' : stats.canceled}
                />
            </Box>
        )
    }

    if (chips.length && modifiersChips.length) {
        chips.push(
            <Box
                key={'divider'}
                className={classes.verticalDivider}/>
        )
    }

    return chips.concat(modifiersChips);
}
