import {makeStyles} from "tss-react/mui";
import {Box, Skeleton, Theme} from "@mui/material";
import {ChevronRight} from "@mui/icons-material";
import React from "react";

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'flex',
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
        paddingBottom: 8,
    },
    textsContainer: {
        marginLeft: 16,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    iconContainer: {
        display: "flex",
        alignItems: "center",
        marginLeft: 'auto',
    }
}));

export interface IAppListItemShimmerProps {
    showIcon?: boolean;
}

export default function AppListItemShimmer(props: IAppListItemShimmerProps) {
    const {showIcon} = props;
    const {classes} = useStyles();

    const secondaryActionJSX = showIcon ? <ChevronRight/> : undefined;

    return (
        <Box className={classes.container}>
            <Skeleton variant={"circular"} height={56} width={56}></Skeleton>
            <Box className={classes.textsContainer}>
                <Skeleton variant={"rectangular"} width={200} height={16}></Skeleton>
                <Box pb={0.5}/>
                <Skeleton variant={"rectangular"} width={160} height={14}></Skeleton>
            </Box>
            <Box className={classes.iconContainer}>
                {secondaryActionJSX}
            </Box>
        </Box>
    );
}