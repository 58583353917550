import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8WashingMachineProps {
    className?: string;
}

export default function Icons8WashingMachine(props: IIcons8WashingMachineProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
               <path
                   d="M 13.5 5 C 9.9280619 5 7 7.9280619 7 11.5 L 7 36.5 C 7 40.071938 9.9280619 43 13.5 43 L 34.5 43 C 38.071938 43 41 40.071938 41 36.5 L 41 11.5 C 41 7.9280619 38.071938 5 34.5 5 L 13.5 5 z M 13.5 8 L 34.5 8 C 36.450062 8 38 9.5499381 38 11.5 L 38 36.5 C 38 38.450062 36.450062 40 34.5 40 L 13.5 40 C 11.549938 40 10 38.450062 10 36.5 L 10 11.5 C 10 9.5499381 11.549938 8 13.5 8 z M 14.5 11 A 1.5 1.5 0 0 0 14.5 14 A 1.5 1.5 0 0 0 14.5 11 z M 19.5 11 A 1.5 1.5 0 0 0 19.5 14 A 1.5 1.5 0 0 0 19.5 11 z M 33 11 A 2 2 0 0 0 33 15 A 2 2 0 0 0 33 11 z M 24 16 C 18.494917 16 14 20.494921 14 26 C 14 31.505079 18.494917 36 24 36 C 29.505083 36 34 31.505079 34 26 C 34 20.494921 29.505083 16 24 16 z M 24 19 C 27.650605 19 30.525398 21.787727 30.869141 25.34375 C 28.320572 26.53695 27.017795 25.92413 24.894531 25.101562 C 23.012659 24.372512 20.484645 23.658105 17.294922 24.523438 C 17.986039 21.380738 20.640878 19 24 19 z M 19.925781 26.970703 C 20.17857 26.953787 20.420959 26.956362 20.652344 26.976562 C 21.748949 27.072302 22.701123 27.468638 23.810547 27.898438 C 25.562069 28.576988 27.793422 29.294583 30.472656 28.632812 C 29.436426 31.196834 26.948191 33 24 33 C 20.692928 33 18.068151 30.69193 17.324219 27.623047 C 18.309254 27.239966 19.167415 27.021452 19.925781 26.970703 z"/>
               </svg>
        </span>
    );
}
























