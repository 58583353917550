import {
    VisitRepeatDayResponse,
    VisitRepeating,
    VisitRepeatModifier,
    VisitResponse
} from "../../../../../generated/graphql/graphql";
import {DateTime} from "luxon";
import {makeStyles} from "tss-react/mui";
import {Box, Theme, Typography} from "@mui/material";
import React, {useContext} from "react";
import {kAppColors} from "../../../../../styles/AppThemeProcessor";
import {tt} from "../../../../../core/Localization";
import {MillisToDate} from "../../../../../utils/DateUtils";
import {AppDataContext} from "../../../../../AppData";
import {VisitRepeatingToString} from "../../../../../service/VisitService";

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: "flex",
        flexWrap: 'wrap',
        maxWidth: 600,
        flexShrink: 0,
        marginLeft: -16,
        marginRight: -16,
    },
    singleColumn: {
        width: '25%',
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
        "@media (max-width: 767px)": {
            width: '50%',
        },
    },
    text: {
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
        fontSize: 14,
        fontWeight: 600,
    },
    label: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontSize: 12,
        fontWeight: 600,
    },
}));

export interface IJobDetailRepeatInfoProps {
    visitData?: VisitResponse | NullOrUndefined;
    repeatDayData?: VisitRepeatDayResponse | NullOrUndefined;
}

/**
 * Component to display job repeat info.
 */
export default function VisitDetailRepeatInfo(props: IJobDetailRepeatInfoProps) {
    const {visitData, repeatDayData} = props;

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const {classes} = useStyles();

    const isRepeating = visitData?.repeating !== VisitRepeating.Never;

    if (!visitData || !isRepeating) {
        return null;
    }

    const startDate = MillisToDate(visitData.startDate, true, language);
    const endDate = visitData.repeatEndDate ? MillisToDate(visitData.repeatEndDate, true, language) : undefined;
    const repeatEndDate = visitData.repeatEndDate ? MillisToDate(visitData.repeatEndDate, true, language) : undefined;
    const every = visitData.every;
    const repeatWeekDays = repeatDayData?.repeatWeekDays || visitData.repeatWeekDays;

    const repeatWeekDaysToString = visitData.repeating === VisitRepeating.Weekly ? repeatWeekDays.map((value, index) =>
        (DateTime.fromMillis(repeatDayData?.startDate || visitData.startDate, {locale: language})).set({weekday: value as any}).weekdayLong
    ) : undefined;

    let monthInfo: string | undefined = undefined;
    if (visitData.repeating === VisitRepeating.Monthly) {
        if (visitData.repeatModifier === VisitRepeatModifier.DayOfMonth || visitData.repeatModifier === VisitRepeatModifier.DayOfMonthOrLastDay) {
            monthInfo = tt('jobDetailScreen.repeatSpecs.monthly.dayOfMonth');
        } else if (visitData.repeatModifier === VisitRepeatModifier.LastDayOfMonth) {
            monthInfo = tt('jobDetailScreen.repeatSpecs.monthly.lastDayOfMonth');
        } else if (visitData.repeatModifier === VisitRepeatModifier.DayOfWeek || visitData.repeatModifier === VisitRepeatModifier.DayOfWeekOrLastDay) {
            monthInfo = tt('jobDetailScreen.repeatSpecs.monthly.dayOfWeek');
        } else if (visitData.repeatModifier === VisitRepeatModifier.LastDayOfWeek) {
            monthInfo = tt('jobDetailScreen.repeatSpecs.monthly.lastDayOfWeek')
                .replace('$weekday', DateTime.fromMillis(visitData.startDate).toFormat('EEEE', {locale: language}));
        }
    }

    return (
        <Box className={classes.container}>
            <RepeatingJobInfoColumnItem
                label={tt('jobDetailScreen.repeatSpecs.label.frequency')}
                text={VisitRepeatingToString(visitData.repeating)}
            />

            <RepeatingJobInfoColumnItem
                label={tt('jobDetailScreen.repeatSpecs.label.interval')}
                text={`${every}`}
            />

            {repeatWeekDaysToString ?
                <RepeatingJobInfoColumnItem
                    label={tt('jobDetailScreen.repeatSpecs.label.days')}
                    text={repeatWeekDaysToString.join(', ')}
                />
                : null}

            {monthInfo ?
                <RepeatingJobInfoColumnItem
                    label={tt('jobDetailScreen.repeatSpecs.label.monthInfo')}
                    text={monthInfo}
                />
                : null}

            {visitData.repeatModifier === VisitRepeatModifier.SkipWeekends ?
                <RepeatingJobInfoColumnItem
                    label={tt('jobDetailScreen.repeatSpecs.label.skipWeekends')}
                    text={tt('jobDetailScreen.repeatSpecs.value.skipWeekends.yes')}
                />
                : null}

            <Box width={'100%'}/>

            <RepeatingJobInfoColumnItem
                label={tt('jobDetailScreen.repeatSpecs.label.startDate')}
                text={startDate}
            />

            {endDate ? <RepeatingJobInfoColumnItem
                label={tt('jobDetailScreen.repeatSpecs.label.endDate')}
                text={endDate}
            /> : <RepeatingJobInfoColumnItem
                label={tt('jobDetailScreen.repeatSpecs.label.endDate')}
                text={tt('jobDetailScreen.repeatSpecs.never')}
            />}
        </Box>
    );
}

export interface IRepeatingJobInfoColumnProps {
    label: string;
    text: string;
}

export function RepeatingJobInfoColumnItem(props: IRepeatingJobInfoColumnProps) {
    const {label, text} = props;
    const {classes, cx} = useStyles();

    return (
        <Box className={classes.singleColumn}>
            <Typography className={classes.label}>{label}</Typography>
            <Typography className={classes.text}>{text}</Typography>
        </Box>
    );
}
