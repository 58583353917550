import {makeStyles} from "tss-react/mui";
import {Box, Link, Theme, Tooltip, Typography} from "@mui/material";
import React from "react";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {tt} from "../../../core/Localization";
import {linkLikeButtonStyle} from "../../../styles/UtilStyles";

export interface IDetailContactRowProps {
    title?: string | NullOrUndefined;
    description?: string | NullOrUndefined;
    notFilledText?: string | NullOrUndefined;
    href?: string;
    icon?: React.ReactNode;
    customIconComponent?: React.ReactNode,
    openInNewTab?: boolean;
    disabled?: boolean;
    iconTooltip?: string;
}

export const useStyles = makeStyles()((theme: Theme) => ({
    noHrefLink: {
        'svg': {
            color: kAppColors.text.primary(theme.palette.mode === 'dark'),
        },
        background: "transparent",
        pointerEvents: "none",
        ':hover': {
            background: "transparent",
        }
    },
    text: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.3,
    },
    contactItem: {
        minHeight: 46,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 6,
        paddingBottom: 6,
        display: "flex",
        alignItems: "center",
    },
    description: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 18 / 14,
    },
    notFilledText: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontStyle: 'italic',
    },
    textContainer: {
        marginRight: 'auto',
    }
}));

export default function DetailContactRow(props: IDetailContactRowProps) {
    const {
        title,
        description,
        href,
        icon,
        openInNewTab,
        disabled,
        customIconComponent,
        iconTooltip,
        notFilledText,
    } = props;

    const {classes, cx} = useStyles();

    const contactLinkStyle = linkLikeButtonStyle();

    const theContactLinkClass = cx(
        contactLinkStyle.classes.linkButton,
        href == null ? classes.noHrefLink : undefined,
    );

    const theTextClass = cx(
        classes.text,
        disabled || title === undefined ? classes.notFilledText : undefined,
    );

    const descriptionJSX = description == null ? undefined :
        <Typography className={classes.description}>{description}</Typography>

    const iconJSX = customIconComponent ?? (iconTooltip != null ?
        <Tooltip
            placement={"right"}
            title={iconTooltip}
        >
            <Link className={theContactLinkClass}
                  href={href}
                  target={openInNewTab ? "_blank" : undefined}
                  rel={openInNewTab ? "noreferrer" : undefined}>
                <Box>{icon}</Box>
            </Link>
        </Tooltip> : <Link
            className={theContactLinkClass}
            href={href}
            target={openInNewTab ? "_blank" : undefined}
            rel={openInNewTab ? "noreferrer" : undefined}>
            <Box>{icon}</Box>
        </Link>);

    return (

        <Box className={classes.contactItem}>
            <Box className={classes.textContainer}>
                <Typography
                    className={theTextClass}>{!title ? (notFilledText ? notFilledText : tt('common.notFilledIn')) : title}</Typography>
                {descriptionJSX}
            </Box>
            {disabled ? null : iconJSX}
        </Box>

    );
}
