import {makeStyles} from "tss-react/mui";
import {Box, Skeleton, Theme} from "@mui/material";
import React from "react";

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        marginBottom: 0,
        height: 59,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

export default function JobsDateHeadlineShimmer() {
    const {classes} = useStyles();

    return (
        <Box className={classes.container}>
            <Skeleton variant={"rectangular"} width={140} height={26}></Skeleton>
        </Box>
    );
}