import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import React, {useContext, useId, useState} from "react";
import {AppDataContext} from "../../../../../AppData";
import {usePopupState} from "material-ui-popup-state/hooks";
import {
    CreateJobOfferSeatInput, EmployeeJoinedUserResponse, FileResponse,
    ItemPaymentType,
    JobOfferSeatResponse, VisitStatus,
} from "../../../../../generated/graphql/graphql";
import {
    CalculateTotalPrice,
    HourRateDisplay,
    HoursMinutesDisplay,
    paymentTypeToItemPaymentType,
    TotalPriceDisplay
} from "../../../../../service/CompanyService";
import {tt} from "../../../../../core/Localization";
import AppListItem from "../../../listItems/AppListItem";
import AppIconButton from "../../../buttons/AppIconButton";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MoreFilledIcon from "../../../../../icons/MoreFilledIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {kAppColors} from "../../../../../styles/AppThemeProcessor";
import CloseIcon from "../../../../../icons/CloseIcon";
import VisitStatusChip from "../../../chips/VisitStatusChip";
import PencilIcon from "../../../../../icons/PencilIcon";
import Icons8CloneFigure from "../../../../../icons/Icons8CloneFigure";
import AppAvatar from "../../../AppAvatar";
import JobOfferModalBottomSheet, {IJobOfferData} from "../../../modals/job/JobOfferModalBottomSheet";
import {DateTime} from "luxon";
import ListItemAvatarsWidget from "../../ListItemAvatarsWidget";
import {arrayContainsAll} from "../../../../../utils/Utils";
import AppChip from "../../../chips/AppChip";
import PermissionValid from "../../../permissions/PermissionValid";
import {kActionDelete, kActionUpdate, kPermissionsWorkers} from "../../../../../core/constants";

const useStyles = makeStyles()((theme: Theme) => ({
    timesheet: {
        textAlign: 'right',
        paddingRight: 16,
    },
    timesheetSecondline: {
        color: '#6F6F6F',
        fontSize: 14,
    },
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        paddingLeft: 70,
    }
}));

export interface IJobOfferItemProps {
    isNewJobCreation?: boolean;
    canEdit?: boolean;
    index: number;
    data?: JobOfferSeatResponse;
    newJobExistingData?: CreateJobOfferSeatInput;
    onDelete: () => void;
    onDuplicate: () => void;
    onUpdate: (data: IJobOfferData) => void;
    showOfferTag?: boolean;
    excludeEmployeeIds?: number[];
    jobEmployeeData?: EmployeeJoinedUserResponse[] | NullOrUndefined;
    files?: FileResponse[] | NullOrUndefined;
}

export default function VisitOfferItem(props: IJobOfferItemProps) {
    const {
        isNewJobCreation,
        canEdit,
        index,
        data,
        newJobExistingData,
        onDelete,
        onDuplicate,
        onUpdate,
        showOfferTag,
        excludeEmployeeIds,
        jobEmployeeData,
        files,
    } = props;

    const theCanEdit = canEdit ?? true;
    const isCancelled = data?.cancelled ?? false;

    const appDataContext = useContext(AppDataContext);
    const {currency, language} = appDataContext;

    const [bottomSheetOffers, setBottomSheetOffers] = useState<boolean>(false);

    const {classes} = useStyles();

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    let offerTotalPrice = 0;

    let timesheetHoursChip;
    let timesheetTotalChip;

    if (data) {
        const props = {
            paymentType: paymentTypeToItemPaymentType(data.paymentType),
            hours: data.hours,
            minutes: data.minutes,
            approved: true,
            hourRate: data.hourRate,
            fixedPrice: data.fixedPrice,
        };

        const hoursMinutes = HoursMinutesDisplay({
            timesheets: [props],
            language,
        });

        if (hoursMinutes) {
            timesheetHoursChip = (
                <Box pr={0.5} pb={0.5}>
                    <AppChip
                        chipstyle={"outlined"}
                        label={hoursMinutes}
                    />
                </Box>
            );
        }

        const totalPrice = TotalPriceDisplay({
            timesheets: [props],
            currency,
            language,
        });

        if (totalPrice) {
            let thisHourRate = props.paymentType === ItemPaymentType.Hourly ? ` (${HourRateDisplay(props.hourRate || 0, currency, language)})` : '';

            timesheetTotalChip = (
                <Box pr={0.5} pb={0.5}>
                    <AppChip
                        chipstyle={"outlined"}
                        label={`${totalPrice}${thisHourRate}`}
                    />
                </Box>
            );
        }

        offerTotalPrice = CalculateTotalPrice({
            timesheets: [props],
            currency,
            language,
        });
    }
    if (newJobExistingData) {
        const props = {
            paymentType: paymentTypeToItemPaymentType(newJobExistingData.paymentType),
            hours: newJobExistingData.hours,
            minutes: newJobExistingData.minutes,
            hourRate: newJobExistingData.hourRate || 0,
            fixedPrice: newJobExistingData.fixedPrice || 0,
            approved: true,
        };

        const hoursMinutes = HoursMinutesDisplay({
            timesheets: [props],
            language,
        });

        if (hoursMinutes) {
            timesheetHoursChip = (
                <Box pr={0.5} pb={0.5}>
                    <AppChip
                        chipstyle={"outlined"}
                        label={hoursMinutes}
                    />
                </Box>
            );
        }

        const totalPrice = TotalPriceDisplay({
            timesheets: [props],
            currency,
            language,
        });

        if (totalPrice) {
            let thisHourRate = props.paymentType === ItemPaymentType.Hourly ? ` (${HourRateDisplay(props.hourRate, currency, language)})` : '';
            timesheetTotalChip = (
                <Box pr={0.5} pb={0.5}>
                    <AppChip
                        chipstyle={"outlined"}
                        label={`${totalPrice}${thisHourRate}`}
                    />
                </Box>
            );
        }
    }

    const toSolveJSX = data && arrayContainsAll(data.rejectedIds, data.employeeIds) ? (
        <Box pr={0.5} pb={0.5}><VisitStatusChip status={VisitStatus.Unfinished}/></Box>
    ) : undefined;

    const title = tt('offerItem.title').replace('{index}', (index + 1).toString());

    return (
        <>
            <Box>
                <AppListItem
                    noMarginBottom={true}
                    customAvatar={<AppAvatar title={'? ?'}/>}
                    variant={"smaller-title"}
                    title={title}
                    actionWidget={
                        <>
                            {theCanEdit ? (
                                <AppIconButton
                                    variant={"greyBg"}
                                    placement={"right"}
                                    {...bindTrigger(settingsPopupState)}
                                >
                                    <MoreFilledIcon/>
                                </AppIconButton>
                            ) : undefined}
                        </>
                    }
                />

                <Box
                    pl={2}
                    pr={2}
                >
                    <Box className={classes.chipsContainer}>
                        {toSolveJSX}
                        {showOfferTag && !isCancelled ?
                            <Box pr={0.5} pb={0.5}><VisitStatusChip status={VisitStatus.JobOffer}/></Box> : null}
                        {isCancelled ? <Box pr={0.5} pb={0.5}><VisitStatusChip status={VisitStatus.Canceled}/></Box> : null}
                        {timesheetHoursChip}
                        {timesheetTotalChip}
                    </Box>
                </Box>
                <Box
                    pb={1}
                >
                    <ListItemAvatarsWidget
                        isNewJob={!showOfferTag}
                        offerTotalPrice={offerTotalPrice}
                        substituteCount={data?.substituteCount || newJobExistingData?.substituteCount || 0}
                        substituteEmployeeIds={data?.acceptedIds || []}
                        employeeIds={data?.employeeIds || newJobExistingData?.employeeIds || []}
                        rejectedEmployeeIds={data?.rejectedIds || []}
                        jobEmployeeData={jobEmployeeData}
                        files={files}
                    />
                </Box>
            </Box>

            <Menu {...bindMenu(settingsPopupState)}>
                <PermissionValid
                    permission={kPermissionsWorkers}
                    requiredPermissions={[kActionUpdate]}
                    forceValid={isNewJobCreation}
                >
                    <>
                        <MenuItem key={'offerDetailEditKey'} onClick={() => {
                            settingsPopupState.close();

                            setBottomSheetOffers(true);
                        }}>
                            <PencilIcon/>
                            {tt('offer.menu.edit')}
                        </MenuItem>

                        <MenuItem key={'offerDuplicateKey'} onClick={() => {
                            settingsPopupState.close();

                            onDuplicate();
                        }}>
                            <Icons8CloneFigure/>
                            {tt('offer.menu.duplicate')}
                        </MenuItem>
                    </>
                </PermissionValid>

                <PermissionValid
                    permission={kPermissionsWorkers}
                    requiredPermissions={[kActionDelete]}
                    forceValid={isNewJobCreation}
                >
                    <MenuItem key={'offerDetailDeleteKey'} onClick={() => {
                        settingsPopupState.close();

                        onDelete();
                    }}>
                        <Box sx={{color: kAppColors.red.confirmButton}}><CloseIcon/></Box>
                        {tt('common.removeFromList')}
                    </MenuItem>
                </PermissionValid>
            </Menu>

            <JobOfferModalBottomSheet
                title={title}
                existingEmployeeIds={data?.employeeIds || newJobExistingData?.employeeIds || []}
                open={bottomSheetOffers}
                setOpen={setBottomSheetOffers}
                data={data}
                newJobExistingData={newJobExistingData}
                onSave={onUpdate}
                excludeEmployeeIds={excludeEmployeeIds}
                notificationsCheckbox={!isNewJobCreation}
            />
        </>
    );
}
