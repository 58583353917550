import {InputProps} from "./InputProps";
import {useStyles as formUseStyles} from "./FormBuilder";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {tt} from "../../../core/Localization";


/**
 * Form component for Password input.
 */

export default function PasswordInput(props: InputProps) {
    const {
        index,
        validateOnChange,
        updateProp,
        className,
        label,
        value,
        updateValue,
        hidden,
        error,
        errorMessage,
        requireMinLength,
        disabled,
    } = props;
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const {classes: formClasses, cx} = formUseStyles();

    /**
     * OnChange update data of this field.
     */
    const OnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateValue(index, e.target.value);

        if (validateOnChange && requireMinLength) {
            const shouldShowPassWordError = value.length > 0 && value.length < requireMinLength!;
            const shouldHidePassError = value.length == 0 || value.length >= requireMinLength!;

            if (shouldShowPassWordError) {
                updateProp(
                    index, 'error', true);
                updateProp(
                    index, 'errorMessage', `${tt('common.isNotValidMinLength').replace('$length', `${requireMinLength}`)}.`);
            }

            if (shouldHidePassError) {
                updateProp(
                    index, 'error', false);
                updateProp(
                    index, 'errorMessage', null);
            }
        }
    };

    const HandleClickShowPassword = () => {
        setShowPassword(
            !showPassword
        );
    };


    return (
        <div className={cx(className, (hidden ? formClasses.hidden : undefined))}>
            <TextField
                variant={"filled"}
                label={label}
                type={showPassword ? "text" : "password"}
                value={value}
                disabled={disabled}
                error={error}
                fullWidth={true} margin="none" helperText={error ? errorMessage : undefined}
                onChange={OnChange}
                InputProps={
                    {
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={HandleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                        </InputAdornment>
                    }
                }
            />
        </div>
    );
}
