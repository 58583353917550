import {iconUseStyles} from "../styles/IconStyles";

export interface IKeyIconProps {
    className?: string;
}

/**
 * Component representing Home svg icon.
 */
export default function KeyIcon(props: IKeyIconProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg id="Icons-sm" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 28 28">
                <rect id="Rectangle_28" data-name="Rectangle 28" width="28" height="28" fill="none"/>
                <g id="icons8-key_1_" data-name="icons8-key (1)" transform="translate(4 4)">
                    <path fill="currentColor" id="icons8-key_1_2" data-name="icons8-key (1)"
                          d="M18.158,5a6.854,6.854,0,0,0-6.842,6.842,6.535,6.535,0,0,0,.185,1.33l-6.27,6.27A.79.79,0,0,0,5,20v4.211A.79.79,0,0,0,5.789,25H10a.79.79,0,0,0,.789-.789V22.895h1.842a.79.79,0,0,0,.789-.789V20.263h1.842a.79.79,0,0,0,.789-.789V18.313a6.752,6.752,0,0,0,2.105.371A6.842,6.842,0,0,0,18.158,5Zm0,1.579a5.263,5.263,0,1,1-2.516,9.887.79.79,0,0,0-1.168.693v1.525H12.632a.79.79,0,0,0-.789.789v1.842H10a.79.79,0,0,0-.789.789v1.316H6.579V20.327l6.31-6.31a.79.79,0,0,0,.2-.77,5.246,5.246,0,0,1,5.067-6.668Zm1.053,2.632a1.579,1.579,0,1,0,1.579,1.579A1.579,1.579,0,0,0,19.211,9.211Z"
                          transform="translate(-5 -5)"/>
                </g>
            </svg>
        </span>
    );
}


