import {iconUseStyles} from "../styles/IconStyles";

export interface IDarkModeIconProps {
    className?: string;
}

export default function DarkModeIcon(props: IDarkModeIconProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <g id="icons8-do-not-disturb-ios" transform="translate(0 0)">
                    <path id="icons8-do-not-disturb-ios-2" data-name="icons8-do-not-disturb-ios"
                          d="M11.432,4.822a.766.766,0,0,0-.293.071A10.425,10.425,0,0,0,15.46,24.82a10.528,10.528,0,0,0,9.468-6.139.766.766,0,0,0-1.054-1A8.679,8.679,0,0,1,12.139,5.946a.766.766,0,0,0-.707-1.125Z"
                          transform="translate(-5 -4.821)"/>
                </g>
            </svg>
        </span>
    );
}