import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8UserProps {
    className?: string;
}

export default function Icons8User(props: IIcons8UserProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 5.484375 3.984375 A 1.50015 1.50015 0 0 0 4.4394531 6.5605469 L 14.296875 16.417969 C 15.186823 19.998147 18.001853 22.813177 21.582031 23.703125 L 25.878906 28 L 11.978516 28 C 9.7987044 28 8 29.798705 8 31.978516 L 8 33.5 C 8 36.702606 9.80912 39.293989 12.326172 40.96875 C 14.843224 42.643511 18.077773 43.566273 21.447266 43.880859 C 24.816758 44.195446 28.326746 43.892669 31.416016 42.935547 C 33.743139 42.214556 35.861911 41.119159 37.427734 39.548828 L 41.439453 43.560547 A 1.50015 1.50015 0 1 0 43.560547 41.439453 L 6.5605469 4.4394531 A 1.50015 1.50015 0 0 0 5.484375 3.984375 z M 24 4 C 20.31 4 17.091375 6.0140938 15.359375 8.9960938 L 17.587891 11.224609 C 18.664891 8.7436094 21.128 7 24 7 C 27.86 7 31 10.14 31 14 C 31 16.872 29.256391 19.335109 26.775391 20.412109 L 29.003906 22.640625 C 31.985906 20.908625 34 17.69 34 14 C 34 8.486 29.514 4 24 4 z M 34.363281 28 L 39.992188 33.630859 C 39.993187 33.587859 40 33.545953 40 33.501953 L 40.001953 31.978516 C 40.001953 29.784516 38.215484 28 36.021484 28 L 34.363281 28 z M 11.978516 31 L 28.878906 31 L 35.300781 37.421875 C 34.176361 38.53588 32.521039 39.45323 30.529297 40.070312 C 27.890254 40.887943 24.71932 41.173945 21.726562 40.894531 C 18.733805 40.615118 15.924042 39.760646 13.988281 38.472656 C 12.052521 37.184667 11 35.628894 11 33.5 L 11 31.978516 C 11 31.420326 11.420326 31 11.978516 31 z"/>
            </svg>
        </span>
    );
}



