import {
    EmployeeJoinedUserResponse,
    JobUpdateRepeats,
    UpdateVisitStatusModifierInput,
    VisitDetailResponse,
    VisitResponse,
    VisitStatus,
    VisitStatusModifier
} from "../../../../../generated/graphql/graphql";
import VisitDetailStatusModifierSection from "./statusWidgets/VisitDetailStatusModifierSection";
import {Box, Theme, Tooltip} from "@mui/material";
import FormBuilder, {IInputsData, InputType} from "../../../form/FormBuilder";
import {tt} from "../../../../../core/Localization";
import VisitDetailInvoiceDeliverySection from "./VisitDetailInvoiceDeliverySection";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import VisitStatusSection from "./statusWidgets/VisitDetailStatusSection";
import {UserFullName, UserRoleTitle} from "../../../../../service/UserService";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../../../styles/AppThemeProcessor";
import {
    getVisitStatusModifierColor,
    getVisitStatusModifierIcon,
    getVisitStatusModifierTitle
} from "../../../../../service/VisitService";
import Icons8LockOutlined from "../../../../../icons/Icons8LockOutlined";
import {SuccessToast} from "../../../../../service/ToastService";
import {processMutationError} from "../../../../../service/ErrorService";
import {IOnUpdateVisitIdParams} from "../../../modals/job/visits/VisitDetailModal";
import {FetchPolicy, RestApiClientContext} from "../../../../../core/RestApiProvider";
import PermissionValid from "../../../permissions/PermissionValid";
import {kActionUpdate, kPermissionsJobs} from "../../../../../core/constants";

export const useStyles = makeStyles()((theme: Theme) => ({
    statusModifierSelect: {
        ".MuiFormControl-root": {
            '.MuiFormLabel-root': {
                paddingLeft: 48,
                color: kAppColors.text.primary(theme.palette.mode === "dark"),
            },
            '.MuiSelect-select span': {
                position: "relative",
                top: -8,
            }
        },
        ".MuiInputBase-root": {
            background: "transparent !important",
            border: `1px solid ${kAppColors.text.primary(theme.palette.mode === "dark")}`,
            fontWeight: 600 + ' !important',
            '.MuiSelect-icon': {
                color: kAppColors.text.primary(theme.palette.mode === "dark"),
            }
        },

        ".Invoiced .MuiFormControl-root": {
            '.MuiFormLabel-root': {
                color: getVisitStatusModifierColor(VisitStatusModifier.Invoiced),
            },
            ".MuiInputBase-root": {
                color: getVisitStatusModifierColor(VisitStatusModifier.Invoiced),
                border: `1px solid ${getVisitStatusModifierColor(VisitStatusModifier.Invoiced)}`,
                '.MuiSelect-icon': {
                    color: getVisitStatusModifierColor(VisitStatusModifier.Invoiced),
                }
            },
        },
        ".Paid .MuiFormControl-root": {
            '.MuiFormLabel-root': {
                color: getVisitStatusModifierColor(VisitStatusModifier.Paid),
            },
            ".MuiInputBase-root": {
                color: getVisitStatusModifierColor(VisitStatusModifier.Paid),
                border: `1px solid ${getVisitStatusModifierColor(VisitStatusModifier.Paid)}`,
                '.MuiSelect-icon': {
                    color: getVisitStatusModifierColor(VisitStatusModifier.Paid),
                }
            },
        },
        ".NotPaid .MuiFormControl-root": {
            '.MuiFormLabel-root': {
                color: getVisitStatusModifierColor(VisitStatusModifier.NotPaid),
            },
            ".MuiInputBase-root": {
                color: getVisitStatusModifierColor(VisitStatusModifier.NotPaid),
                border: `1px solid ${getVisitStatusModifierColor(VisitStatusModifier.NotPaid)}`,
                '.MuiSelect-icon': {
                    color: getVisitStatusModifierColor(VisitStatusModifier.NotPaid),
                }
            },
        },
    },
    closeJobSwitchForm: {
        width: "unset",
        margin: "unset",
        '.MuiFormControlLabel-root': {
            marginRight: 0,
            marginLeft: -20,
        },
        '.MuiFormControlLabel-label': {
            display: "none",
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
            textTransform: "unset",
        }
    },
    buttonContainer: {
        paddingBottom: 16,
        display: "flex",
        alignItems: "center",
        overflowX: "auto",
    },
}));

export interface IVisitDetailStatusSectionProps {
    visitId: number;
    repeatingDay?: number;
    data: VisitDetailResponse | NullOrUndefined;
    onUpdateVisitId: (params: IOnUpdateVisitIdParams) => void;
    closeJobSwitchInput: IInputsData;
    setCloseJobSwitchInput: Dispatch<SetStateAction<IInputsData>>;
    showWasCanceledByEmployee: EmployeeJoinedUserResponse | undefined;
    showWasCanceledByJobOffer: boolean;
    historyLogCancelledByManager: EmployeeJoinedUserResponse | undefined;
    setWorkersTab: Function;
}

/**
 * Component to display the status of a visit.
 */
export default function VisitDetailStatusSection(props: IVisitDetailStatusSectionProps) {
    const {
        visitId,
        repeatingDay,
        data,
        onUpdateVisitId,
        closeJobSwitchInput,
        setCloseJobSwitchInput,
        showWasCanceledByEmployee,
        historyLogCancelledByManager,
        showWasCanceledByJobOffer,
        setWorkersTab,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiPost} = restApiClientContext;

    const {classes, cx} = useStyles();

    const visitData = data?.visit;
    const repeatingDayData = data?.visit.repeatingDayData;
    const theVisitStatus = repeatingDayData?.status || visitData?.status || VisitStatus.Scheduled;
    const theVisitStatusModifier = repeatingDayData?.statusModifier || visitData?.statusModifier;

    /**
     * Mutate update of StatusModifier to BE.
     */
    const updateVisitStatusModifier = (value: VisitStatusModifier) => {
        restApiPost({
            uri: '/job/visit/update-status-modifier',
            params: {
                visitId,
                repeatingDay,
                repeats: JobUpdateRepeats.Single,
                statusModifier: value,
            } as UpdateVisitStatusModifierInput,
            fetchPolicy: FetchPolicy.NetworkOnly,
            onData: (data: VisitResponse) => {
                if (data) {
                    SuccessToast(tt('visitDetailContent.updateStatusModifier.success'));

                    if (data.id) {
                        onUpdateVisitId({
                            visitId: data.id,
                            repeatingDay: repeatingDay!,
                        });
                    }
                }
            },
            onError: (error) => processMutationError(error),
        });
    };

    const [jobStatusModifierForm, setJobStatusModifierForm] = useState<IInputsData>({
        statusModifier: {
            testId: 'jobStatusModifierSelect',
            type: InputType.Select,
            label: tt('visitDetail.screen.VisitStatusModifier.select.label.jobStatus'),
            value: VisitStatusModifier.None,
            options: [
                {
                    icon: <Icons8LockOutlined/>,
                    value: VisitStatusModifier.None,
                    label: getVisitStatusModifierTitle(VisitStatusModifier.None)
                },
                {
                    icon: <Box
                        sx={{color: getVisitStatusModifierColor(VisitStatusModifier.Invoiced)}}>{getVisitStatusModifierIcon(VisitStatusModifier.Invoiced)}</Box>,
                    value: VisitStatusModifier.Invoiced,
                    label: getVisitStatusModifierTitle(VisitStatusModifier.Invoiced)
                },
                {
                    icon: <Box
                        sx={{color: getVisitStatusModifierColor(VisitStatusModifier.Paid)}}>{getVisitStatusModifierIcon(VisitStatusModifier.Paid)}</Box>,
                    value: VisitStatusModifier.Paid,
                    label: getVisitStatusModifierTitle(VisitStatusModifier.Paid)
                },
                {
                    icon: <Box
                        sx={{color: getVisitStatusModifierColor(VisitStatusModifier.NotPaid)}}>{getVisitStatusModifierIcon(VisitStatusModifier.NotPaid)}</Box>,
                    value: VisitStatusModifier.NotPaid,
                    label: getVisitStatusModifierTitle(VisitStatusModifier.NotPaid)
                },
            ],
            onChange: (index: string, value: string) => {
                updateVisitStatusModifier(value as any);
            },
        },
    });

    const [invoiceVisitSwitchInput, setInvoiceVisitSwitchInput] = useState<IInputsData>({
        invoiced: {
            testId: 'formInvoicedSwitch',
            type: InputType.Switch,
            label: getVisitStatusModifierTitle(VisitStatusModifier.Invoiced),
            value: theVisitStatusModifier === VisitStatusModifier.Invoiced || theVisitStatusModifier === VisitStatusModifier.Paid || theVisitStatusModifier === VisitStatusModifier.NotPaid,
            switchVariant: "Android12Switch",
            onSwitch: (value) => {
                if (value) {
                    updateVisitStatusModifier(VisitStatusModifier.Invoiced);
                } else {
                    updateVisitStatusModifier(VisitStatusModifier.None);
                }
            },
        },
    });

    useEffect(() => {
        if (theVisitStatusModifier) {
            setJobStatusModifierForm(prev => {
                return {
                    ...prev,
                    statusModifier: {
                        ...prev.statusModifier,
                        value: theVisitStatusModifier,
                        onChange: (index: string, value: string) => {
                            updateVisitStatusModifier(value as any);
                        },
                    },
                };
            });

            setInvoiceVisitSwitchInput(prev => {
                return {
                    ...prev,
                    invoiced: {
                        ...prev.invoiced,
                        value: theVisitStatusModifier === VisitStatusModifier.Invoiced || theVisitStatusModifier === VisitStatusModifier.Paid || theVisitStatusModifier === VisitStatusModifier.NotPaid,
                        onSwitch: (value) => {
                            if (value) {
                                updateVisitStatusModifier(VisitStatusModifier.Invoiced);
                            } else {
                                updateVisitStatusModifier(VisitStatusModifier.None);
                            }
                        },
                    },
                };
            });
        } else {
            setInvoiceVisitSwitchInput(prev => {
                return {
                    ...prev,
                    invoiced: {
                        ...prev.invoiced,
                        value: false,
                        onSwitch: (value) => {
                            if (value) {
                                updateVisitStatusModifier(VisitStatusModifier.Invoiced);
                            } else {
                                updateVisitStatusModifier(VisitStatusModifier.None);
                            }
                        },
                    },
                };
            });
        }
    }, [theVisitStatusModifier, visitData]);

    return theVisitStatus === VisitStatus.Closed ? (
        <>
            <VisitDetailStatusModifierSection
                mobileWidget={
                    <PermissionValid
                        permission={kPermissionsJobs}
                        requiredPermissions={[kActionUpdate]}
                    >
                        <Box
                            className={classes.statusModifierSelect}
                            pl={2} pr={2}>
                            <FormBuilder
                                className={cx(theVisitStatusModifier)}
                                inputs={jobStatusModifierForm}
                                setInputs={setJobStatusModifierForm}
                            />
                        </Box>
                    </PermissionValid>

                }
                closeVisitSwitch={
                    <PermissionValid
                        permission={kPermissionsJobs}
                        requiredPermissions={[kActionUpdate]}
                    >
                        <Tooltip title={tt('visitDetail.screen.closeJob.tooltip')}>
                            <Box>
                                <FormBuilder
                                    className={classes.closeJobSwitchForm}
                                    inputs={closeJobSwitchInput}
                                    setInputs={setCloseJobSwitchInput}
                                />
                            </Box>
                        </Tooltip>
                    </PermissionValid>
                }
                invoiceSwitch={
                    <PermissionValid
                        permission={kPermissionsJobs}
                        requiredPermissions={[kActionUpdate]}
                    >
                        <Tooltip
                            title={getVisitStatusModifierTitle(VisitStatusModifier.Invoiced)}
                        >
                            <Box>
                                <FormBuilder
                                    className={classes.closeJobSwitchForm}
                                    inputs={invoiceVisitSwitchInput}
                                    setInputs={setInvoiceVisitSwitchInput}
                                />
                            </Box>
                        </Tooltip>
                    </PermissionValid>
                }
                statusModifier={theVisitStatusModifier}
                updateVisitStatusModifier={updateVisitStatusModifier}/>
            {
                theVisitStatusModifier === VisitStatusModifier.Invoiced || theVisitStatusModifier === VisitStatusModifier.Paid || theVisitStatusModifier === VisitStatusModifier.NotPaid ?
                    <>
                        <VisitDetailInvoiceDeliverySection
                            jobId={visitId}
                            repeatingDay={repeatingDay}
                            invoiceNumber={repeatingDayData?.invoiceNumber || visitData?.invoiceNumber}
                            deliveryNumber={repeatingDayData?.deliveryNumber || visitData?.deliveryNumber}
                            onUpdateJobId={onUpdateVisitId}
                        />
                    </>
                    : null
            }
        </>
    ) : (
        <Box className={classes.buttonContainer}>
            <VisitStatusSection
                canceledByName={showWasCanceledByEmployee ?
                    UserFullName(showWasCanceledByEmployee!.name || showWasCanceledByEmployee?.user?.name, showWasCanceledByEmployee!.surname || showWasCanceledByEmployee?.user?.surname) : historyLogCancelledByManager ?
                        UserFullName(historyLogCancelledByManager!.name || historyLogCancelledByManager?.user?.name, historyLogCancelledByManager!.surname || historyLogCancelledByManager?.user?.surname) : undefined}
                canceledByPosition={UserRoleTitle(historyLogCancelledByManager?.role)}
                status={repeatingDayData?.status || visitData?.status}
                jobOfferSeats={data?.jobOfferSeat}
                showWasCanceledByJobOffer={showWasCanceledByJobOffer}
                setWorkersTab={setWorkersTab}
                closeVisitSwitch={
                    <PermissionValid
                        permission={kPermissionsJobs}
                        requiredPermissions={[kActionUpdate]}
                    >
                        <Tooltip title={tt('visitDetail.screen.closeJob.tooltip')}>
                            <Box>
                                <FormBuilder
                                    className={classes.closeJobSwitchForm}
                                    inputs={closeJobSwitchInput}
                                    setInputs={setCloseJobSwitchInput}
                                />
                            </Box>
                        </Tooltip>
                    </PermissionValid>
                }
            />
        </Box>
    );
}
