import {makeStyles} from "tss-react/mui";
import {LinearProgress, Theme} from "@mui/material";
import {kAppColors} from "../../styles/AppThemeProcessor";

const useStyles = makeStyles()((theme: Theme) => ({
    progressBar: {
        borderRadius: 50,
        height: 6,
        width: '100%',
        backgroundColor: theme.palette.mode === "dark" ? 'rgba(238, 240, 243, 0.2)' : '#C6C6C6',
        '.MuiLinearProgress-bar': {
            borderRadius: 50,
            backgroundColor: kAppColors.green.main,
        }
    }
}));

export interface IProgressBarWidgetProps {
    done: number,
    total: number,
}

export default function ProgressBarWidget(props: IProgressBarWidgetProps) {
    const {done, total} = props;

    const {classes, cx} = useStyles();

    return (<LinearProgress
        className={classes.progressBar}
        variant={"determinate"}
        value={(done / (total / 100))}/>);
}