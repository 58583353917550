import TimotyLogo from "../../icons/TimotyLogo";
import {Box, Theme, Typography} from "@mui/material";
import {tt} from "../../core/Localization";
import React from "react";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../styles/AppThemeProcessor";

const useStyles = makeStyles()((theme: Theme) => ({
    centerContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    textStyle: {
        fontWeight: 500,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
    },
    primaryColorText: {
        fontWeight: 600,
        color: kAppColors.primary.main,
    }
}));

export interface ILogoWithTextProps {
    description?: string;
    primaryColorDescription?: boolean;
}

export default function LogoWithText(props: ILogoWithTextProps) {
    const {description, primaryColorDescription} = props;

    const {classes, cx} = useStyles();

    const descriptionStyleClass = cx(
        classes.textStyle,
        primaryColorDescription ? classes.primaryColorText : undefined,
    );

    const descriptionText = description == null ? tt('welcome.screen.description') : description;

    return (
        <Box className={classes.centerContent}>
            <TimotyLogo size={65}></TimotyLogo>
            <Box sx={{mb: 1.5}}/>
            <Typography variant={"h1"} fontSize={24}>{tt('app.name')}</Typography>
            <Box sx={{mb: 1}}/>
            <Typography className={descriptionStyleClass}>{descriptionText}</Typography>
            <Box sx={{mb: 4}}/>
        </Box>
    );
}