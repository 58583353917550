import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8TimeMachineProps {
    className?: string;
}

/**
 * Component representing Icons8TimeMachine svg icon.
 */
export default function Icons8TimeMachine(props: IIcons8TimeMachineProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 24 4 C 18.556727 4 13.611893 6.1873196 10 9.7285156 L 10 8 A 2.0002 2.0002 0 0 0 7.9707031 5.9726562 A 2.0002 2.0002 0 0 0 6 8 L 6 15 A 2.0002 2.0002 0 0 0 7.9199219 16.996094 A 2.0002 2.0002 0 0 0 8.8222656 17 L 15 17 A 2.0002 2.0002 0 1 0 15 13 L 12.388672 13 C 15.301199 9.9245705 19.415571 8 24 8 C 32.860089 8 40 15.139911 40 24 C 40 32.860089 32.860089 40 24 40 C 15.139911 40 8 32.860089 8 24 A 2.0002 2.0002 0 1 0 4 24 C 4 35.021911 12.978089 44 24 44 C 35.021911 44 44 35.021911 44 24 C 44 12.978089 35.021911 4 24 4 z M 23.970703 12.972656 A 2.0002 2.0002 0 0 0 22 15 L 22 26 A 2.0002 2.0002 0 0 0 24 28 L 31 28 A 2.0002 2.0002 0 1 0 31 24 L 26 24 L 26 15 A 2.0002 2.0002 0 0 0 23.970703 12.972656 z"/>
            </svg>
        </span>
    );
}
