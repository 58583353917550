import React, {Dispatch, SetStateAction, useContext} from "react";
import {useNavigate} from "react-router-dom";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {
    CreateLocationInputPlaceInput,
    CreateLocationPlacesDocument,
    CreateLocationPlacesMutation,
    CreateLocationPlacesMutationVariables
} from "../../../generated/graphql/graphql";
import AppModal from "../modals/AppModal";
import {tt} from "../../../core/Localization";
import {IInputsData, ValidateForm} from "../form/FormBuilder";
import LocationPlaceForm from "./LocationPlaceForm";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import {PlaceDetailRoute} from "../../screens/places/PlaceDetailScreen";
import {PlacesRoute} from "../../screens/places/PlacesScreen";
import {Box, Link, Theme, Typography} from "@mui/material";
import {SetConfirmModal} from "../modals/AppModals";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {AppDataContext} from "../../../AppData";


export const useStyles = makeStyles()((theme: Theme) => ({
    helperButton: {
        color: kAppColors.text.primary(theme.palette.mode === "dark") + ' !important',
        textDecoration: "underline",
        cursor: "pointer",
    },
    helperModalText: {
        textAlign: "center",
        color: kAppColors.text.secondaryLighterForDarkMode(theme.palette.mode == "dark"),
        'strong': {
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
        }
    },
}));

export interface ICreateLocationPlaceModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    clientId: number;
    locationId: number;
    navigation?: boolean;
    onCreate?: (ids: number[]) => void;
}

/**
 * Modal component to create new Place for Location.
 */
export default function CreateLocationPlaceModal(props: ICreateLocationPlaceModalProps) {
    const {
        open,
        setOpen,
        clientId,
        locationId,
        navigation,
        onCreate,
    } = props;

    const appDataContext = useContext(AppDataContext);

    const {classes} = useStyles();

    const navigate = useNavigate();
    const theNavigation = navigation === undefined ? true : navigation;

    const [mutateCreate, {
        loading,
        error,
        data,
    }] = useResettableMutation<CreateLocationPlacesMutation, CreateLocationPlacesMutationVariables>(CreateLocationPlacesDocument);

    /**
     * Mutate Place to BE and close modal if success.
     */
    const CreatePlace = async (inputs: IInputsData, setInputs: Dispatch<SetStateAction<IInputsData>>, placesCount: number) => {
        if (ValidateForm(inputs, setInputs)) {
            try {
                const places: CreateLocationInputPlaceInput[] = [];
                for (let i = 1; i <= placesCount; i++) {
                    places.push({
                        name: inputs[`place_${i}_name`].value,
                        type: inputs[`place_${i}_type`].value.newValue || inputs[`place_${i}_type`].value.value,
                        icon: inputs[`place_${i}_type`].userData || '',
                    });
                }

                const variables: CreateLocationPlacesMutationVariables = {
                    input: {
                        locationId: locationId,
                        places,
                    },
                };

                const result = await mutateCreate({variables});

                if (!result.errors) {
                    setOpen(false);

                    const newIds: number[] = result.data!.createLocationPlaces.newLocationPlaces.map((place) => place.id);

                    if (theNavigation) {
                        if (newIds.length > 1) {
                            navigate(PlacesRoute(clientId, locationId));
                        } else {
                            const count = newIds.length;
                            navigate(PlaceDetailRoute(clientId, locationId, newIds[count - 1]));
                        }
                    }

                    SuccessToast(tt('createLocationPlace.modal.success'));

                    if (onCreate) {
                        onCreate(newIds);
                    }
                }
            } catch (e) {
                console.error(e);

                ErrorToast(tt('common.mutation.error'));
            }
        }
    };

    return (
        <AppModal
            open={open}
            setOpen={setOpen}
            title={tt('common.newPlace')}
            fullScreenOnMobile={true}
            blurBackdrop={true}
            headerActions={
                <Link className={classes.helperButton}
                      onClick={() => {
                          SetConfirmModal(
                              appDataContext,
                              {
                                  blurBackdrop: true,
                                  open: true,
                                  title: tt('placesHelperModal.title'),
                                  subtitle: undefined,
                                  children:
                                      <Typography className={classes.helperModalText}
                                                  dangerouslySetInnerHTML={{__html: `<p>${tt('placesHelperModal.text').replace('&&bold', `<Strong>`).replace('&&boldEnd', `</Strong>`)}</p>`}}></Typography>,
                                  cancelButtonText: tt('common.close'),
                                  onConfirm: undefined,
                              }
                          );
                      }}
                >
                    {tt('createLocation.modal.helperText')}
                </Link>
            }
        >
            <LocationPlaceForm
                onSubmit={CreatePlace}
                updateLoading={loading}
                hideHelperLink={true}
                multiple={true}/>
        </AppModal>
    );
}
