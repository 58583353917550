import React, {useContext} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Divider, Theme, Typography} from "@mui/material";
import DetailColumnItem from "./DetailColumnItem";
import {tt} from "../../../core/Localization";
import {AppDataContext} from "../../../AppData";
import {LoginProvider} from "../../../generated/graphql/graphql";
import {getLoginProviderIcon} from "../../../service/LoginProviderService";
import {PriceDisplay} from "../../../service/CompanyService";


export const useStyles = makeStyles()((theme: Theme) => ({
    hourRate: {
        fontSize: 20,
        fontWeight: 500,
        lineHeight: 1,
    },
    container: {
        display: "flex",
        overflowX: "auto",
        alignItems: "start",
    },
    logiNmethods: {
        height: 28,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
}));


export interface IUserStatusRowProps {
    loginProviders?: LoginProvider[];
    hourRate?: number | NullOrUndefined;
    contractType?: string | NullOrUndefined;
    hourRateShowZero?: boolean;
    noBorder?: boolean;
    distanceRate?: number | NullOrUndefined;
    distanceRateShowZero?: boolean;
}

export default function UserStatusRow(props: IUserStatusRowProps) {
    const {loginProviders, hourRate, hourRateShowZero, noBorder, contractType, distanceRate, distanceRateShowZero} = props;
    const {classes, cx} = useStyles();

    const appDataContext = useContext(AppDataContext);
    const {currency, language} = appDataContext;

    const theHourRate = hourRateShowZero && !hourRate ? '0' : hourRate;
    const theDistanceRate = distanceRateShowZero && !distanceRate ? '0' : distanceRate;

    const hourRateJSX = theHourRate ? (
        <DetailColumnItem
            title={tt('workerDetail.screen.hourRate')}>
            <Typography
                className={classes.hourRate}>{PriceDisplay(theHourRate === '0' ? 0 : theHourRate, currency, language, hourRateShowZero)}</Typography>
        </DetailColumnItem>
    ) : null;

    const distanceRateJSX = theDistanceRate ? (
        <DetailColumnItem
            title={tt('workerDetail.screen.distanceRate')}>
            <Typography
                className={classes.hourRate}>{PriceDisplay(theDistanceRate === '0' ? 0 : theDistanceRate, currency, language, distanceRateShowZero)}</Typography>
        </DetailColumnItem>
    ) : null;

    const contractTypeJSX = contractType ? (
        <DetailColumnItem
            title={tt('workerDetail.screen.contractType')}>
            <Typography className={classes.hourRate}>{contractType}</Typography>
        </DetailColumnItem>
    ) : null;

    const loginMethodColumnsJSX =
        loginProviders == null || loginProviders.length === 0 ? null :
            <DetailColumnItem key={'loginMethodsKey'}
                              title={tt('workerDetail.screen.loginMethod')}>
                <Box className={classes.logiNmethods}>{
                    loginProviders.map(item => {
                        return <Box sx={{pr: 0.4, pl: 0.4}} key={item}>{getLoginProviderIcon(item)}</Box>;
                    })
                }
                </Box>
            </DetailColumnItem>;

    return (
        <>
            <Box className={cx('styledScrollbar', classes.container)}>
                {loginMethodColumnsJSX}

                {hourRateJSX}

                {distanceRateJSX}

                {contractTypeJSX}
            </Box>
            {noBorder ? null : <Divider/>}
        </>
    );
}
