import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import AppModal from "../../AppModal";
import {tt} from "../../../../../core/Localization";
import FormBuilder, {IInputsData, InputType} from "../../../form/FormBuilder";
import {makeStyles} from "tss-react/mui";
import {Box, Theme, Typography} from "@mui/material";
import {kAppColors, kContentWidthNarrow} from "../../../../../styles/AppThemeProcessor";
import AppButton from "../../../buttons/AppButton";

export const useStyles = makeStyles()((theme: Theme) => ({
    modalPaper: {
        maxWidth: kContentWidthNarrow,
        overflow: "hidden",
    },
    chip: {
        flexGrow: 1,
    },
    confirmChip: {
        flexGrow: 1,
    },
    description: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark")
    }
}));

export interface IDuplicateOptionsProps {
    attachments?: boolean;
    forms?: boolean;
    workers?: boolean;
    products?: boolean;
    materials?: boolean;
}

export interface IDuplicateVisitOptionsModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onSave: (props: IDuplicateOptionsProps) => void;
    showFormsOption?: boolean;
    showAttachmentsOption?: boolean;
    showProductsOption?: boolean;
    showWorkersOption?: boolean;
    showMaterialsOption?: boolean;
}

export default function DuplicateVisitOptionsModal(props: IDuplicateVisitOptionsModalProps) {
    const {
        open,
        setOpen,
        onSave,
        showFormsOption,
        showAttachmentsOption,
        showWorkersOption,
        showProductsOption,
        showMaterialsOption,
    } = props;

    const {classes, cx} = useStyles();

    const [inputs, setInputs] = useState<IInputsData>({});

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                attachments: {
                    type: InputType.CheckBox,
                    label: tt('duplicateVisitOptionsModal.checkbox.attachment'),
                    value: true,
                    hidden: !showAttachmentsOption,
                },
                forms: {
                    type: InputType.CheckBox,
                    label: tt('duplicateVisitOptionsModal.checkbox.forms'),
                    value: true,
                    hidden: !showFormsOption,
                },
                workers: {
                    type: InputType.CheckBox,
                    label: tt('duplicateVisitOptionsModal.checkbox.workers'),
                    value: true,
                    hidden: !showWorkersOption,
                },
                products: {
                    type: InputType.CheckBox,
                    label: tt('duplicateVisitOptionsModal.checkbox.servicesAndProducts'),
                    value: true,
                    hidden: !showProductsOption,
                },
                materials: {
                    type: InputType.CheckBox,
                    label: tt('duplicateVisitOptionsModal.checkbox.materials'),
                    value: true,
                    hidden: !showMaterialsOption,
                },
            }
        });
    }, [open]);

    return (
        <AppModal
            paperClassName={classes.modalPaper}
            title={tt('duplicateVisitOptionsModal.title')}
            open={open}
            setOpen={setOpen}
            modalAboveModals={true}
            actions={(
                <>
                    <AppButton
                        className={classes.confirmChip}
                        key={'confirmButtonKey'}
                        onClick={() => onSave({
                            attachments: inputs.attachments.value,
                            forms: inputs.forms.value,
                            workers: inputs.workers.value,
                            products: inputs.products.value,
                            materials: inputs.materials.value,
                        })}
                        variant={"contained"}

                    >{tt('duplicateVisitOptionsModal.submitButton')}</AppButton>
                </>
            )}
        >
            <Typography className={classes.description}>{tt('duplicateVisitOptionsModal.description')}</Typography>
            <Box pb={2}/>
            <FormBuilder inputs={inputs} setInputs={setInputs}/>
            <Box pb={2}/>
        </AppModal>
    );
}
