import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8PhoneProps {
    className?: string;
}

export default function Icons8PhoneFilled(props: IIcons8PhoneProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M22.916,6.625l1.719,4.055c0.749,1.767,0.334,3.845-1.028,5.137l-3.212,3.048c-0.362,0.344-0.55,0.843-0.469,1.335	c0.31,1.881,0.997,3.736,2.061,5.566c1.062,1.826,2.355,3.38,3.88,4.661c0.404,0.339,0.965,0.427,1.465,0.26l3.747-1.25	c1.725-0.575,3.603,0.086,4.661,1.64l2.465,3.621c1.23,1.807,1.009,4.299-0.517,5.831l-1.636,1.642	c-1.628,1.634-3.953,2.227-6.104,1.556c-5.078-1.584-9.746-6.286-14.006-14.107c-4.266-7.832-5.771-14.478-4.516-19.936	c0.528-2.297,2.163-4.126,4.298-4.808l2.153-0.688C19.897,3.545,22.052,4.587,22.916,6.625z"/>
            </svg>
        </span>
    );
}




















