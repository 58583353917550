import React, {useContext, useEffect, useMemo, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Divider, Theme, Typography} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import {AddVisitMaterialInput, EmployeeJoinedUserResponse, MaterialResponse} from "../../../generated/graphql/graphql";
import {tt} from "../../../core/Localization";
import {DateTime} from "luxon";
import {UserFullName} from "../../../service/UserService";
import AppChip from "../chips/AppChip";
import AppIconButton from "../buttons/AppIconButton";
import HeadlineWithButton from "../../screenSections/detailListPreviewSection/HeadlineWithButton";
import DeleteIcon from "../../../icons/DeleteIcon";
import PencilIcon from "../../../icons/PencilIcon";
import {unitNameAndCountText} from "../../../service/VisitService";
import {AppDataContext} from "../../../AppData";
import CloseIcon from "../../../icons/CloseIcon";


export const useStyles = makeStyles()((theme: Theme) => ({
    deleteIcon: {
        color: kAppColors.red.confirmButton,
    },
    switchSectionContainer: {
        width: '100%',
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 8,
        '.MuiGrid-root': {
            padding: 0,
            flexBasis: 0,
            width: "auto",
            marginLeft: "auto",
        }
    },
    headerIconsContainer: {
        display: "flex",
        alignItems: "center",
    },
    saveChip: {
        height: 30,
    },
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "start",
        marginBottom: -8,
        '.MuiChip-root, statusChip': {
            marginBottom: 8,
        }
    },
    footerText: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontSize: 12,
        fontWeight: 600
    },
}));

export interface IEditVisitMaterialModalSectionProps {
    startEditMode?: boolean;
    data?: MaterialResponse;
    addMaterial?: Partial<AddVisitMaterialInput>;
    createdByEmployee?: EmployeeJoinedUserResponse;
    onDelete: (uuid: string) => void;
    onUpdate: (uuid: string, data: IInputsData) => void;
}

export default function EditVisitMaterialModalSection(props: IEditVisitMaterialModalSectionProps) {
    const {
        startEditMode,
        data,
        addMaterial,
        createdByEmployee,
        onDelete,
        onUpdate,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const {classes} = useStyles();

    const [editMode, setEditMode] = useState<boolean>(startEditMode || false);
    const [inputs, setInputs] = useState<IInputsData>({});

    function setForms(data: MaterialResponse | NullOrUndefined, addMaterial: Partial<AddVisitMaterialInput> | NullOrUndefined) {
        setInputs(prev => {
                return {
                    ...prev,
                    quantity: {
                        testId: 'addMaterialQuantityInput',
                        type: InputType.Text,
                        numbersOnly: true,
                        inputMode: "decimal",
                        label: `${tt('material.form.label.quantity')}*`,
                        value: data?.unitCount?.toString() || addMaterial?.unitCount?.toString() || '',
                        required: true,
                        grid: {
                            sm: 6,
                            xs: 6,
                        },
                    },
                    unitOfMeasure: {
                        testId: 'materialUnitOfMeasureInput',
                        type: InputType.Text,
                        label: tt('material.form.label.unitOfMeasure'),
                        placeholder: tt('material.form.placeholder.unitOfMeasure'),
                        value: data?.unitName || addMaterial?.unitName || '',
                        required: false,
                        grid: {
                            sm: 6,
                            xs: 6,
                        },
                    },
                }
            }
        );
    }

    useEffect(() => {
        setForms(data, addMaterial);
    }, [data, addMaterial]);

    const SubmitForm = () => {
        if (ValidateForm(inputs, setInputs)) {
            setEditMode(!editMode);
            onUpdate(data?.uuid || addMaterial!.uuid!, inputs);
        }
    };

    const authoredJSX = useMemo(() => {
        if (data) {
            const isTodayCreated = data.createdAt && DateTime.fromMillis(data.createdAt).startOf('day').toMillis() === DateTime.now().startOf('day').toMillis();
            const createdAtText = data.createdAt ? (
                isTodayCreated ? `${tt('common.today')}, ${DateTime.fromMillis(data.createdAt!).toFormat('H:mm')}` : DateTime.fromMillis(data.createdAt!).toFormat('H:mm · d.M.yyyy')
            ) : tt('common.date.now');

            return createdByEmployee ? (
                <Box p={2}>
                    <Typography className={classes.footerText}>
                        {tt('jobDetail.screen.footerNote.created')}
                        &nbsp;
                        {createdAtText}
                        &nbsp;
                        ·
                        &nbsp;
                        {UserFullName(createdByEmployee.name || createdByEmployee.user?.name, createdByEmployee.surname || createdByEmployee.user?.surname)}
                    </Typography>
                </Box>
            ) : null;
        }

        return null;
    }, [data, createdByEmployee]);

    const editJSX = editMode ? (
        <>
            <AppChip
                className={classes.saveChip}
                onClick={() => {
                    SubmitForm();
                }}
                chipstyle={"primary"}
                label={tt('common.save')}
            />

            {data ? (
                <>
                    <Box pl={1}/>

                    <AppIconButton
                        tooltip={tt('common.close')}
                        onClick={() => {
                            setEditMode(!editMode);
                        }}
                    >
                        <CloseIcon/>
                    </AppIconButton>
                </>
            ) : null}
        </>
    ) : (
        <AppIconButton
            tooltip={tt('common.edit')}
            onClick={() => {
                setEditMode(!editMode);
            }}
        >
            <PencilIcon/>
        </AppIconButton>
    );

    return (
        <>
            <Divider/>

            <HeadlineWithButton
                title={data?.name || addMaterial?.name || ''}
                customAction={
                    <Box className={classes.headerIconsContainer}>
                        {editJSX}

                        <Box pl={1}/>

                        <AppIconButton
                            tooltip={tt('common.delete')}
                            onClick={() => onDelete(data?.uuid || addMaterial!.uuid!)}
                        >
                            <DeleteIcon className={classes.deleteIcon}/>
                        </AppIconButton>
                    </Box>
                }
            />

            <Box sx={{pr: 2, pl: 2}}>
                {editMode ? (
                    <Box pt={1}>
                        <FormBuilder inputs={inputs} setInputs={setInputs}/>
                    </Box>
                ) : (
                    <Box className={classes.chipsContainer} pb={1}>
                        {data ? (
                            <AppChip
                                chipstyle={"outlined"}
                                label={unitNameAndCountText(data.unitName, data.unitCount, language)}
                            />
                        ) : null}

                        {addMaterial ? (
                            <AppChip
                                chipstyle={"outlined"}
                                label={unitNameAndCountText(addMaterial.unitName, addMaterial.unitCount, language)}
                            />
                        ) : null}
                    </Box>
                )}
            </Box>

            {authoredJSX}
        </>
    );
}
