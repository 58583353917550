import {iconUseStyles} from "../styles/IconStyles";
import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material";


export const useStyles = makeStyles()((theme: Theme) => ({
    defaultIcon: {
        width: 13,
    },
}));

export interface ISmartphoneIcons8Props {
    className?: string;
}

export default function Icons8Smartphone(props: ISmartphoneIcons8Props) {
    const {className} = props;

    const {classes: iconClasses, cx} = iconUseStyles();
    const {classes} = useStyles();

    const theClassName = cx(
        iconClasses.icon,
        classes.defaultIcon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg id="icons8-smartphone" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 13.2 22">
                <path id="Path_31" data-name="Path 31"
                      d="M12,23.8V6.2A2.206,2.206,0,0,1,14.2,4H23a2.206,2.206,0,0,1,2.2,2.2V23.8A2.206,2.206,0,0,1,23,26H14.2A2.206,2.206,0,0,1,12,23.8Z"
                      transform="translate(-12 -4)" fill="#37474f"/>
                <path id="Path_32" data-name="Path 32"
                      d="M24.334,7H15.549A.521.521,0,0,0,15,7.553V23.584a.521.521,0,0,0,.549.553h8.785a.521.521,0,0,0,.549-.553V7.553A.521.521,0,0,0,24.334,7Z"
                      transform="translate(-13.342 -5.342)" fill="#bbdefb"/>
                <path id="Path_33" data-name="Path 33" d="M21,40h3.317v1.106H21Z" transform="translate(-16.058 -20.211)"
                      fill="#78909c"/>
            </svg>
        </span>
    );
}


