import AppListItem from "../../../listItems/AppListItem";
import Icons8Company from "../../../../../icons/Icons8Company";
import UserIcon from "../../../../../icons/UserIcon";
import {tt} from "../../../../../core/Localization";
import PhoneMenu from "../../../menus/PhoneMenu";
import {Box, Divider, Link, Tooltip} from "@mui/material";
import AppAccordion from "../../../modals/AppAccordion";
import {
    AssignClientContactsToVisitDocument,
    AssignClientContactsToVisitMutation,
    AssignClientContactsToVisitMutationVariables,
    ClientContactResponsePage,
    GetClientContactsForIdsInput,
    JobUpdateRepeats,
    UpdateVisitLocationDocument,
    UpdateVisitLocationMutationVariables,
    UpdateVisitLocationPlaceDocument,
    UpdateVisitLocationPlaceMutationVariables,
    VisitDetailResponse,
    VisitRepeating,
    VisitStatus
} from "../../../../../generated/graphql/graphql";
import AppIconButton from "../../../buttons/AppIconButton";
import Icons8Plus from "../../../../../icons/Icons8Plus";
import Icons8Contacts from "../../../../../icons/Icons8Contacts";
import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import ListShimmer from "../../../shimmers/ListShimmer";
import VisitDetailClientContact from "./VisitDetailClientContact";
import {useMutation} from "@apollo/client";
import {processMutationError, processQueryError} from "../../../../../service/ErrorService";
import {AppDataContext} from "../../../../../AppData";
import {SuccessToast} from "../../../../../service/ToastService";
import HeadlineWithButton from "../../../../screenSections/detailListPreviewSection/HeadlineWithButton";
import Icons8Location from "../../../../../icons/Icons8Location";
import Icons8Here from "../../../../../icons/Icons8-here";
import {LocationNameDisplay, LocationTypeDisplay} from "../../../../../service/LocationService";
import Icons8PhoneFilled from "../../../../../icons/Icons8PhoneFilled";
import ShowOnMapMenu from "../../../menus/ShowOnMapMenu";
import {IOnUpdateVisitIdParams} from "../../../modals/job/visits/VisitDetailModal";
import ChooseClientContactModalBottomSheet from "../../../modals/job/editJob/ChooseClientContactModalBottomSheet";
import {linkLikeButtonStyle} from "../../../../../styles/UtilStyles";
import CreateContactPersonModal from "../../../contactPerson/CreateContactPersonModal";
import {uniqueArray} from "../../../../../utils/Utils";
import CreateLocationModal from "../../../locations/CreateLocationModal";
import ChooseLocationModalBottomSheet from "../../../modals/job/editJob/ChooseLocationModalBottomSheet";
import CreateLocationPlaceModal from "../../../places/CreateLocationPlaceModal";
import ChoosePlaceModalBottomSheet from "../../../modals/job/editJob/ChoosePlaceModalBottomSheet";
import PermissionValid, {hasSomePermissions} from "../../../permissions/PermissionValid";
import {
    kActionUpdate, kActionView,
    kPermissionsClients, kPermissionsVisitClientDetails, kPermissionsVisitClientDetailsContacts,
    kTopicClientContacts} from "../../../../../core/constants";
import { RestApiClientContext } from "../../../../../core/RestApiProvider";
import {addressToSingleLine} from "../../../../../utils/AddressUtils";
import {displayClientTaxInfoAsText} from "../../../../../service/ClientService";

export interface IVisitDetailClientTabProps {
    canEdit: boolean;
    visitId: number;
    repeatingDay?: number;
    setRecurringConfirmActionCallback: (recurringConfirmActionCallback: ((repeats: JobUpdateRepeats) => void) | ((repeats: JobUpdateRepeats) => Promise<void>) | undefined) => void;
    setRecurringEditModal: Dispatch<SetStateAction<boolean>>;
    repeats: JobUpdateRepeats;
    setRepeats: Dispatch<SetStateAction<JobUpdateRepeats>>;
    onUpdateVisitId: (params: IOnUpdateVisitIdParams) => void;
    data?: VisitDetailResponse | NullOrUndefined;
}

/**
 * Component for the client tab in the visit detail view.
 */
export default function VisitDetailClientTab(props: IVisitDetailClientTabProps) {
    const {
        canEdit,
        visitId,
        repeatingDay,
        setRecurringConfirmActionCallback,
        setRecurringEditModal,
        repeats,
        onUpdateVisitId,
        data,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {employeePermissionsMap} = appDataContext;

    const contactLinkStyle = linkLikeButtonStyle();

    const [chooseContactsModal, setChooseContactsModal] = useState(false);
    const [createContactModal, setCreateContactModal] = useState<boolean>(false);
    const [bottomSheetLocations, setBottomSheetLocations] = useState<boolean>(false);
    const [bottomSheetCreateLocation, setBottomSheetCreateLocation] = useState<boolean>(false);
    const [bottomSheetPlaces, setBottomSheetPlaces] = useState<boolean>(false);
    const [bottomSheetCreatePlace, setBottomSheetCreatePlace] = useState<boolean>(false);

    const visitData = data?.visit;
    const isRepeating = data?.visit.repeating !== VisitRepeating.Never;
    const repeatingDayData = data?.visit.repeatingDayData;
    const theVisitStatus = repeatingDayData?.status || visitData?.status || VisitStatus.Scheduled;
    const clientData = data?.client;
    const existingContactIds = repeatingDayData?.clientContactIds || visitData?.clientContactIds || [];
    const locationData = data?.location;
    const placeData = data?.place;

    const [contactsLoading, setContactsLoading] = useState(false);
    const [contactsData, setContactsData] = useState<ClientContactResponsePage | NullOrUndefined>();
    useEffect(() => {
        const clientContactIds = data?.visit.repeatingDayData?.clientContactIds || data?.visit.clientContactIds || [];

        if (clientContactIds.length > 0) {
            const subscription = subscribe(
                kTopicClientContacts,
                {
                    uri: '/client/contacts-for-ids',
                    params: {
                        clientContactIds,
                    } as GetClientContactsForIdsInput,
                    setLoading: setContactsLoading,
                    onData: setContactsData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                () => true,
            );

            return () => subscription.cancel();
        } else {
            setContactsData(null);
        }
    }, [data]);

    const [mutateClientContacts, {
        loading: assignClientContactsLoading,
    }] = useMutation<AssignClientContactsToVisitMutation, AssignClientContactsToVisitMutationVariables>(AssignClientContactsToVisitDocument);

    const [mutateUpdateVisitLocation, {
        loading: updateVisitLocationLoading,
    }] = useMutation(UpdateVisitLocationDocument);

    const [mutateUpdateVisitLocationPlace, {
        loading: updateVisitLocationPlaceLoading,
    }] = useMutation(UpdateVisitLocationPlaceDocument);

    /**
     * Mutate update assigned Client Contacts to BE.
     */
    const updateClientContacts = async (repeats: JobUpdateRepeats, clientContactIds: number[]) => {
        try {
            const variables: AssignClientContactsToVisitMutationVariables = {
                input: {
                    visitId,
                    repeatingDay,
                    repeats,
                    clientContacts: clientContactIds,
                },
            };

            const result = await mutateClientContacts({variables});

            if (!result.errors) {
                setChooseContactsModal(false);

                SuccessToast(tt('jobDetailContent.updateClientContacts.success'));

                if (result.data!.assignClientContactsToVisit.id) {
                    onUpdateVisitId({
                        visitId: result.data!.assignClientContactsToVisit.id,
                        repeatingDay: repeatingDay!,
                    });
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * Mutate update Location to BE.
     */
    const updateLocation = async (repeats: JobUpdateRepeats, locationId: number, sendNotifications: boolean) => {
        try {
            const variables: UpdateVisitLocationMutationVariables = {
                input: {
                    visitId,
                    repeatingDay,
                    repeats,
                    locationId,
                    sendNotifications,
                },
            };

            const result = await mutateUpdateVisitLocation({variables});

            if (!result.errors) {
                SuccessToast(tt('jobDetailContent.updateLocation.success'));

                if (result.data!.updateVisitLocation.id) {
                    onUpdateVisitId({
                        visitId: result.data!.updateVisitLocation.id,
                        repeatingDay: repeatingDay!,
                    });
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * Mutate update Location Place to BE.
     */
    const updateLocationPlace = async (repeats: JobUpdateRepeats, placeId: number) => {
        try {
            const variables: UpdateVisitLocationPlaceMutationVariables = {
                input: {
                    visitId,
                    repeatingDay,
                    repeats,
                    locationPlaceId: placeId,
                },
            };

            const result = await mutateUpdateVisitLocationPlace({variables});

            if (!result.errors) {
                SuccessToast(tt('jobDetailContent.updateLocationPlace.success'));

                if (result.data!.updateVisitLocationPlace.id) {
                    onUpdateVisitId({
                        visitId: result.data!.updateVisitLocationPlace.id,
                        repeatingDay: repeatingDay!,
                    });
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    const clientJSX = clientData && hasSomePermissions([{
        permission: kPermissionsClients,
        requiredPermissions: [kActionView]
    }, {permission: kPermissionsVisitClientDetails, requiredPermissions: [kActionView]}], employeePermissionsMap) ? (
        <AppListItem
            customAvatar={data?.client.company ? <Icons8Company/> : <UserIcon/>}
            title={data?.client.name || tt('common.notFilledIn')}
            description={displayClientTaxInfoAsText({client: data?.client, ignoreTaxId: true, displayBillingAddress: true})}
            variant={"smaller-title"}
            actionWidget={
                data?.client.phoneNumber && hasSomePermissions([
                    {permission: kPermissionsClients, requiredPermissions: [kActionView]},
                    {permission: kPermissionsVisitClientDetailsContacts, requiredPermissions: [kActionView]},
                ], employeePermissionsMap) ? <PhoneMenu
                    phone={data.client.phoneNumber}
                    showMarginRight={true}/> : <></>
            }
        />
    ) : null;

    const contactsJSX = hasSomePermissions([{
        permission: kPermissionsClients,
        requiredPermissions: [kActionView]
    }, {permission: kPermissionsVisitClientDetails, requiredPermissions: [kActionView]}], employeePermissionsMap) ? (
        <AppAccordion
            summaryText={
                tt('common.contactPersons.count')
                    .replace('{count}', (contactsData?.content.length || 0).toString())
            }
            actionsJSX={
                !(theVisitStatus === VisitStatus.Closed && contactsData?.content.length === 0) ? (
                        clientData && !clientData.deleted ?
                            <>
                                <PermissionValid
                                    permission={kPermissionsClients}
                                    requiredPermissions={[kActionUpdate]}
                                >
                                    <AppIconButton
                                        onClick={() => {
                                            setRecurringConfirmActionCallback(
                                                () => setChooseContactsModal(true)
                                            );

                                            if (isRepeating) {
                                                setRecurringEditModal(true);
                                            } else {
                                                setChooseContactsModal(true);
                                            }
                                        }}
                                        color="primary"
                                        tooltip={tt('common.select')}
                                    >
                                        <Icons8Contacts/>
                                    </AppIconButton>
                                </PermissionValid>

                                <PermissionValid
                                    permission={kPermissionsClients}
                                    requiredPermissions={[kActionUpdate]}
                                >
                                    <AppIconButton
                                        onClick={() => {
                                            setRecurringConfirmActionCallback(
                                                () => setCreateContactModal(true)
                                            );

                                            if (isRepeating) {
                                                setRecurringEditModal(true);
                                            } else {
                                                setCreateContactModal(true);
                                            }
                                        }}
                                        color="primary"
                                        tooltip={tt('visitDetail.screen.button.newContactPerson.label')}
                                    >
                                        <Icons8Plus/>
                                    </AppIconButton>
                                </PermissionValid>
                            </> : null)
                    : null
            }
            disabled={(contactsData?.content.length || 0) === 0}
        >
            {contactsLoading && !contactsData ? (
                <ListShimmer items={1}/>
            ) : (
                contactsData?.content.map(contact => (
                        <VisitDetailClientContact
                            key={`contact-${contact.id}`}
                            canEdit={canEdit}
                            isRepeating={isRepeating}
                            contact={contact}
                            existingContactIds={existingContactIds}
                            setRecurringConfirmActionCallback={setRecurringConfirmActionCallback}
                            setRecurringEditModal={setRecurringEditModal}
                            updateClientContacts={(clientContactIds: number[]) => {
                                updateClientContacts(repeats, clientContactIds);
                            }}
                        />
                    )
                )
            )}
        </AppAccordion>
    ) : null;

    const placeJSX = locationData ? (
        <>
            <Divider/>
            <HeadlineWithButton
                title={tt('common.place')}
                customAction={(
                    <>
                        {
                            clientData && !clientData.deleted && canEdit ? (
                                <PermissionValid
                                    permission={kPermissionsClients}
                                    requiredPermissions={[kActionUpdate]}
                                >
                                    <AppIconButton
                                        onClick={() => {
                                            setRecurringConfirmActionCallback(
                                                () => setBottomSheetPlaces(true)
                                            );

                                            if (isRepeating) {
                                                setRecurringEditModal(true);
                                            } else {
                                                setBottomSheetPlaces(true);
                                            }
                                        }}
                                        color="primary"
                                        tooltip={tt('common.select')}
                                    >
                                        <Icons8Here/>
                                    </AppIconButton>

                                    <AppIconButton
                                        onClick={() => {
                                            setRecurringConfirmActionCallback(
                                                () => setBottomSheetCreatePlace(true)
                                            );

                                            if (isRepeating) {
                                                setRecurringEditModal(true);
                                            } else {
                                                setBottomSheetCreatePlace(true);
                                            }
                                        }}
                                        color="primary"
                                        tooltip={tt('visitDetail.screen.button.newLocationPlace.label')}
                                    >
                                        <Icons8Plus/>
                                    </AppIconButton>
                                    <Box pr={1}/>
                                </PermissionValid>
                            ) : null}
                    </>
                )}
            />

            {placeData ? (
                <AppListItem
                    customAvatar={<Icons8Here/>}
                    title={placeData.name}
                    description={LocationTypeDisplay(placeData.type || '')}
                    variant={"smaller-title"}
                />
            ) : null}
        </>
    ) : undefined;

    const locationJSX = hasSomePermissions([{
        permission: kPermissionsClients,
        requiredPermissions: [kActionView]
    }, {permission: kPermissionsVisitClientDetails, requiredPermissions: [kActionView]}], employeePermissionsMap) ? (
        <>
            <Divider/>

            <HeadlineWithButton
                title={tt('common.location')}
                customAction={(
                    <>
                        {
                            clientData && !clientData.deleted && canEdit ? (
                                <PermissionValid
                                    permission={kPermissionsClients}
                                    requiredPermissions={[kActionUpdate]}
                                >
                                    <AppIconButton
                                        onClick={() => {
                                            setRecurringConfirmActionCallback(
                                                () => setBottomSheetLocations(true)
                                            );

                                            if (isRepeating) {
                                                setRecurringEditModal(true);
                                            } else {
                                                setBottomSheetLocations(true);
                                            }
                                        }}
                                        color="primary"
                                        tooltip={tt('common.select')}
                                    >
                                        <Icons8Location/>
                                    </AppIconButton>

                                    <AppIconButton
                                        onClick={() => {
                                            setRecurringConfirmActionCallback(
                                                () => setBottomSheetCreateLocation(true)
                                            );

                                            if (isRepeating) {
                                                setRecurringEditModal(true);
                                            } else {
                                                setBottomSheetCreateLocation(true);
                                            }
                                        }}
                                        color="primary"
                                        tooltip={tt('visitDetail.screen.button.newLocation.label')}
                                    >
                                        <Icons8Plus/>
                                    </AppIconButton>
                                    <Box pr={1}/>
                                </PermissionValid>
                            ) : null}
                    </>
                )}
            />

            {locationData ?
                <AppListItem
                    customAvatar={<Icons8Location/>}
                    title={LocationNameDisplay(locationData?.name, locationData?.type || '')}
                    description={`${locationData?.name ? LocationTypeDisplay(locationData?.type || '') : ''}\n${addressToSingleLine(locationData?.address)}`.trim()}
                    variant={"smaller-title"}
                    actionWidget={<>
                        {
                            locationData?.phoneNumber && hasSomePermissions([
                                {permission: kPermissionsClients, requiredPermissions: [kActionView]},
                                {permission: kPermissionsVisitClientDetailsContacts, requiredPermissions: [kActionView]},
                            ], employeePermissionsMap) ? (
                                <Tooltip
                                    placement={"right"}
                                    title={tt('common.call')}>
                                    <Link className={contactLinkStyle.classes.linkButton}
                                          href={`tel:${locationData.phoneNumber}`}
                                          target={"_blank"}
                                          rel={"noreferrer"}>
                                        <Box><Icons8PhoneFilled/></Box>
                                    </Link>
                                </Tooltip>) : null
                        }
                        {
                            locationData?.address.longitude ? (
                                <ShowOnMapMenu address={locationData.address}/>
                            ) : null
                        }
                    </>}
                />
                : null}

            {placeJSX}
        </>
    ) : null;

    const modalsJSX = clientData && !clientData.deleted ? (
        <>
            <ChooseClientContactModalBottomSheet
                open={chooseContactsModal}
                setOpen={setChooseContactsModal}
                clientId={visitData?.clientId || 0}
                existingContactIds={existingContactIds}
                onSave={(ids: number[]) => {
                    updateClientContacts(repeats, ids);
                }}
                canSaveToClear={true}
                updateLoading={assignClientContactsLoading}
                modalAboveModals={true}
            />

            <CreateContactPersonModal
                open={createContactModal}
                setOpen={setCreateContactModal}
                clientId={clientData?.id || 0}
                navigation={false}
                onCreate={(id: number) => {
                    const newExistingContactIds = existingContactIds ? [...existingContactIds] : [];
                    newExistingContactIds.push(id);

                    updateClientContacts(repeats, uniqueArray(newExistingContactIds));
                }}
            />

            <CreateLocationModal
                clientId={clientData?.id || 0}
                open={bottomSheetCreateLocation}
                setOpen={setBottomSheetCreateLocation}
                navigation={false}
                onCreate={(id: number, sendNotifications: boolean) => {
                    updateLocation(repeats, id, sendNotifications);
                }}
                modalAboveModals={true}
                askToSendNotifications={true}
            />

            <ChooseLocationModalBottomSheet
                clientId={clientData?.id || 0}
                open={bottomSheetLocations}
                setOpen={setBottomSheetLocations}
                onSave={(id: number, sendNotifications: boolean) => {
                    updateLocation(repeats, id, sendNotifications);
                }}
                existingLocationId={locationData?.id}
                modalAboveModals={true}
                askToSendNotifications={true}
            />

            <CreateLocationPlaceModal
                onCreate={(ids: number[]) => {
                    updateLocationPlace(repeats, ids[0]);
                }}
                open={bottomSheetCreatePlace}
                setOpen={setBottomSheetCreatePlace}
                locationId={locationData?.id || 0}
                clientId={clientData?.id || 0}
                navigation={false}/>

            <ChoosePlaceModalBottomSheet
                multipleSelect={false}
                clientId={clientData?.id || 0}
                locationId={locationData?.id || 0}
                existingLocationPlaceIds={placeData ? [placeData.id] : []}
                open={bottomSheetPlaces}
                setOpen={setBottomSheetPlaces}
                onSave={(ids: number[]) => {
                    updateLocationPlace(repeats, ids[0]);
                }}
            />
        </>
    ) : undefined;

    return (
        <>
            {clientJSX}

            {clientJSX && contactsJSX ? <Divider/> : null}

            {contactsJSX}

            {locationJSX}

            {modalsJSX}
        </>
    );
}
