import AppListItemShimmer from "./AppListItemShimmer";
import React from "react";

interface IListShimmerProps {
    items?: number;
    showIcon?: boolean;
}

export default function ListShimmer(props: IListShimmerProps) {
    const {items, showIcon = true} = props;

    const theItems = items || 6;

    const itemsJSX = [];
    for (let i = 0; i < theItems; i++) {
        itemsJSX.push(<AppListItemShimmer key={i} showIcon={showIcon}/>);
    }

    return (
        <>
            {itemsJSX}
        </>
    );
}
