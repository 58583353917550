import {makeStyles} from "tss-react/mui";
import {Box, Divider, Theme} from "@mui/material";
import {kAppColors} from "../../../../../styles/AppThemeProcessor";
import {Dispatch, SetStateAction, useContext} from "react";
import ModalBottomSheet from "../../../ModalBottomSheet";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import {tt} from "../../../../../core/Localization";
import AppListItem from "../../../listItems/AppListItem";
import Icons8RightArrow from "../../../../../icons/Icons8RightArrow";
import VisitStatusChip from "../../../chips/VisitStatusChip";
import {
    EmployeeJoinedUserResponse,
    FileResponse,
    JobLogEvent,
    JobLogResponse,
    VisitStatus,
    VisitStatusModifier,
} from "../../../../../generated/graphql/graphql";
import AppAvatar from "../../../AppAvatar";
import {AppDataContext} from "../../../../../AppData";
import {UserFullName, UserPhotoUrl, UserRoleTitle} from "../../../../../service/UserService";
import {DateTime} from "luxon";
import EmptyListText from "../../../textComponents/EmptyListText";
import HammerIcon from "../../../../../icons/HammerIcon";

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        maxHeight: '80vh',
        height: 'unset',
        overflow: "auto",
    },
    statusContainer: {
        display: "flex",
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
        paddingBottom: 24,
    },
    iconContainer: {
        marginLeft: 12,
        marginRight: 16,
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
    },
    divider: {
        marginBottom: 16,
    },
    marginRight: {
        marginRight: 16,
    },
    smallerIcon: {
        width: 18,
        height: 18,
    }
}));

export interface IJobHistoryModalBottomSheetProps {
    history: JobLogResponse[] | NullOrUndefined;
    employees: EmployeeJoinedUserResponse[] | NullOrUndefined;
    files: FileResponse[] | NullOrUndefined;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    modalAboveModals?: boolean;
}

export default function (props: IJobHistoryModalBottomSheetProps) {
    const {history, employees, files, open, setOpen, modalAboveModals} = props;

    const appDataContext = useContext(AppDataContext);
    const {storage} = appDataContext;

    const {classes} = useStyles();

    let isFirst = true;

    const jobMiniAvatar = (
        <Box mr={2}>
            <AppAvatar variant={"extraSmall"} icon={<HammerIcon className={classes.smallerIcon}/>}/>
        </Box>
    );

    const historyJSX = history && employees ? history.map(log => {
        const currentEmployee = employees.find(e => e.id === log.currentEmployeeId);
        const employee = employees.find(e => e.id === log.employeeId);

        if (currentEmployee) {
            const dividerJSX = !isFirst ? <Divider className={classes.divider}/> : null;
            const createdDate = DateTime.fromMillis(log.createdAt);

            let statusJSX;

            if (employee && (log.event === JobLogEvent.EmployeeResponseToJobOfferSeatAccepted || log.event === JobLogEvent.EmployeeResponseToJobOfferSeatRejected)) {
                statusJSX = (
                    <Box className={classes.statusContainer}>
                        <AppAvatar
                            img={
                                UserPhotoUrl(employee.user, files, storage.publicUrlsForFiles)
                            }
                            className={classes.marginRight}
                            title={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                            variant={"extraSmall"}/>

                        {log.previousEmployeeStatus ? (
                            <>
                                <VisitStatusChip employeeStatus={log.previousEmployeeStatus}/>

                                <Box className={classes.iconContainer}>
                                    <Icons8RightArrow/>
                                </Box>
                            </>
                        ) : null}

                        <VisitStatusChip status={VisitStatus.JobOffer}/>

                        <Box className={classes.iconContainer}>
                            <Icons8RightArrow/>
                        </Box>

                        <VisitStatusChip jobLogEvent={log.event}/>
                    </Box>
                );
            } else if (log.employeeStatus && log.previousEmployeeStatus && employee) {
                statusJSX = (
                    <Box className={classes.statusContainer}>
                        <AppAvatar
                            img={
                                UserPhotoUrl(employee.user, files, storage.publicUrlsForFiles)
                            }
                            className={classes.marginRight}
                            title={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                            variant={"extraSmall"}/>

                        {log.previousEmployeeStatus ? (
                            <>
                                <VisitStatusChip employeeStatus={log.previousEmployeeStatus}/>

                                <Box className={classes.iconContainer}>
                                    <Icons8RightArrow/>
                                </Box>
                            </>
                        ) : null}

                        <VisitStatusChip employeeStatus={log.employeeStatus!}/>
                    </Box>
                );
            } else if (log.visitStatus && log.previousVisitStatus) {
                if (log.visitStatus === VisitStatus.Closed || log.previousVisitStatus === VisitStatus.Closed || log.visitStatus === VisitStatus.JobOffer || log.previousVisitStatus === VisitStatus.JobOffer) {
                    statusJSX = (
                        <Box className={classes.statusContainer}>
                            {jobMiniAvatar}

                            {log.previousVisitStatus ? (
                                <>
                                    <VisitStatusChip status={log.previousVisitStatus}/>

                                    <Box className={classes.iconContainer}>
                                        <Icons8RightArrow/>
                                    </Box>
                                </>
                            ) : null}

                            <VisitStatusChip status={log.visitStatus!}/>
                        </Box>
                    );
                } else {
                    return;
                }
            } else if (log.event === JobLogEvent.UpdateStatusModifier) {
                statusJSX = (
                    <Box className={classes.statusContainer}>
                        {jobMiniAvatar}

                        {log.statusModifier && log.statusModifier !== VisitStatusModifier.None ? (
                            <>
                                <VisitStatusChip statusModifier={log.statusModifier}/>

                                <Box className={classes.iconContainer}>
                                    <Icons8RightArrow/>
                                </Box>
                            </>
                        ) : (
                            <>
                                <VisitStatusChip status={log.visitStatus!}/>

                                <Box className={classes.iconContainer}>
                                    <Icons8RightArrow/>
                                </Box>
                            </>
                        )}

                        {log.statusModifier === VisitStatusModifier.None ?
                            <VisitStatusChip status={log.visitStatus!}/>
                            :
                            <VisitStatusChip statusModifier={log.statusModifier!}/>
                        }
                    </Box>
                );
            } else {
                return null;
            }

            isFirst = false;

            const authorJSX = employee ? (
                <AppListItem
                    profileImage={
                        UserPhotoUrl(currentEmployee.user, files, storage.publicUrlsForFiles)
                    }
                    variant={"smaller-title"}
                    title={UserFullName(currentEmployee.name || currentEmployee.user?.name, currentEmployee.surname || currentEmployee.user?.surname)}
                    description={`${UserRoleTitle(currentEmployee.role)} - ${createdDate.toFormat("d.M.yyyy H:mm")}`}
                    actionWidget={
                        <></>
                    }
                />
            ) : (
                <AppListItem
                    profileImage={
                        UserPhotoUrl(currentEmployee.user, files, storage.publicUrlsForFiles)
                    }
                    variant={"smaller-title"}
                    title={UserFullName(currentEmployee.name || currentEmployee.user?.name, currentEmployee.surname || currentEmployee.user?.surname)}
                    description={`${UserRoleTitle(currentEmployee.role)} - ${createdDate.toFormat("d.M.yyyy H:mm")}`}
                    actionWidget={
                        <></>
                    }
                />
            );

            return (
                <div key={log.id}>
                    {dividerJSX}

                    {authorJSX}

                    {statusJSX}
                </div>
            );
        }

        return null;
    }) : null;

    return (
        <ModalBottomSheet
            blurBackdrop={true}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
            modalAboveModals={modalAboveModals}>
            <Box className={classes.container}>

                <BottomSheetModalAppbar
                    onClose={() => setOpen(false)}
                    title={tt('common.jobHistory')}
                    noBorderBottom={true}
                />

                {historyJSX ? historyJSX :
                    <EmptyListText text={tt('jobHistoryModal.emptyList.message')}/>
                }

                <Box sx={{pb: 8}}/>
            </Box>
        </ModalBottomSheet>
    );
}
