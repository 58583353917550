import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8EyeOutlinedProps {
    className?: string;
}

/**
 * Component representing Icons8EyeOutlined svg icon.
 */
export default function Icons8EyeOutlinedIcon(props: IIcons8EyeOutlinedProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px"
                 preserveAspectRatio="xMidYMid meet" focusable="false">
                <path
                    d="M 23.986328 9 C 12.666705 9 2.6928719 16.845918 0.046875 27.126953 A 1.5002454 1.5002454 0 0 0 2.953125 27.873047 C 5.2331281 19.014082 14.065951 12 23.986328 12 C 33.906705 12 42.767507 19.01655 45.046875 27.873047 A 1.5002454 1.5002454 0 0 0 47.953125 27.126953 C 45.306493 16.84345 35.305951 9 23.986328 9 z M 24.001953 17 C 18.681885 17 14.337891 21.343999 14.337891 26.664062 C 14.337891 31.984127 18.681885 36.330078 24.001953 36.330078 C 29.322021 36.330078 33.667969 31.984126 33.667969 26.664062 C 33.667969 21.343999 29.322021 17 24.001953 17 z M 24.001953 20 C 27.700702 20 30.667969 22.965317 30.667969 26.664062 C 30.667969 30.36281 27.700702 33.330078 24.001953 33.330078 C 20.303205 33.330078 17.337891 30.362809 17.337891 26.664062 C 17.337891 22.965317 20.303205 20 24.001953 20 z"/>
            </svg>
        </span>
    );
}
