import {iconUseStyles} from "../../styles/IconStyles";

export interface ICZIconProps {
    className?: string;
}

export default function CZIcon(props: ICZIconProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg id="icons8-czech-republic" xmlns="http://www.w3.org/2000/svg" width="19.999" height="19.999"
                 viewBox="0 0 19.999 19.999">
                <path id="Path_83" data-name="Path 83" d="M18.316,24,8.565,30.359A10,10,0,0,0,26.282,24h-7.97Z"
                      transform="translate(-6.283 -14)" fill="#ff3d00"/>
                <path id="Path_84" data-name="Path 84"
                      d="M16.282,4A9.979,9.979,0,0,0,8.564,7.641L18.312,14h7.97A10,10,0,0,0,16.282,4Z"
                      transform="translate(-6.282 -4)" fill="#d7dfe2"/>
                <path id="Path_85" data-name="Path 85" d="M16.03,17.641,6.282,11.282A10,10,0,0,0,6.282,24l9.751-6.357Z"
                      transform="translate(-4 -7.641)" fill="#3f51b5"/>
            </svg>
        </span>
    );
}
