import {Box, Divider, Theme, Tooltip, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../../../styles/AppThemeProcessor";
import DescriptionWidget from "./DescriptionWidget";
import AppTabsComponent from "../../../AppTabsComponent";
import {tt} from "../../../../../core/Localization";
import VisitInfoSection from "./VisitInfoSection";
import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {
    AddJobOfferSeatToVisitDocument,
    AddJobOfferSeatToVisitMutation,
    AddJobOfferSeatToVisitMutationVariables,
    AddVisitJobFormDocument,
    AddVisitJobFormMutation,
    AddVisitJobFormMutationVariables,
    AddVisitProductDocument,
    AddVisitProductInput,
    AddVisitProductMutation,
    AddVisitProductMutationVariables,
    CloseJobOfferSeatOfVisitDocument,
    CloseJobOfferSeatOfVisitMutation,
    CloseJobOfferSeatOfVisitMutationVariables,
    CloseVisitDocument,
    CloseVisitMutation,
    CloseVisitMutationVariables,
    CreateJobFormTemplateInput,
    CreateJobOfferSeatInput,
    CreateVisitJobFormInput,
    DeleteJobOfferSeatFromVisitDocument,
    DeleteJobOfferSeatFromVisitMutation,
    DeleteJobOfferSeatFromVisitMutationVariables,
    DeleteVisitJobFormDocument,
    DeleteVisitJobFormMutation,
    DeleteVisitJobFormMutationVariables,
    DeleteVisitProductDocument,
    DeleteVisitProductMutation,
    DeleteVisitProductMutationVariables,
    EmployeeJoinedUserResponse,
    EmployeeRole,
    JobEmployeeDataResponse,
    JobEmployeeStatus, JobFormPureResponse,
    JobUpdateRepeats,
    OpenVisitDocument,
    OpenVisitMutation,
    OpenVisitMutationVariables,
    SetJobEmployeeStatusAsIgnoredOfVisitDocument,
    SetJobEmployeeStatusAsIgnoredOfVisitMutation,
    SetJobEmployeeStatusAsIgnoredOfVisitMutationVariables,
    UpdateJobEmployeeStatusOfVisitDocument,
    UpdateJobEmployeeStatusOfVisitMutation,
    UpdateJobEmployeeStatusOfVisitMutationVariables,
    UpdateJobFormElementDoneStateElementInput,
    UpdateJobOfferSeatOfVisitDocument,
    UpdateJobOfferSeatOfVisitMutation,
    UpdateJobOfferSeatOfVisitMutationVariables,
    UpdateVisitAssignedEmployeesAction,
    UpdateVisitAssignedEmployeesDocument,
    UpdateVisitAssignedEmployeesMutation,
    UpdateVisitAssignedEmployeesMutationVariables,
    UpdateVisitJobFormElementDoneStateDocument,
    UpdateVisitJobFormElementDoneStateMutation,
    UpdateVisitJobFormElementDoneStateMutationVariables,
    UpdateVisitProductDocument,
    UpdateVisitProductInput,
    UpdateVisitProductMutation,
    UpdateVisitProductMutationVariables,
    VisitDetailResponse,
    VisitRepeating,
    VisitStatus,
} from "../../../../../generated/graphql/graphql";
import HeadlineWithButton from "../../../../screenSections/detailListPreviewSection/HeadlineWithButton";
import {DateTime} from "luxon";
import {usePopupState} from "material-ui-popup-state/hooks";
import Menu from "@mui/material/Menu";
import {bindMenu} from "material-ui-popup-state";
import MenuItem from "@mui/material/MenuItem";
import {useMutation} from "@apollo/client";
import {SuccessToast} from "../../../../../service/ToastService";
import {UserFullName} from "../../../../../service/UserService";
import {AppDataContext} from "../../../../../AppData";
import {getVisitStatusTitle, VisitNameOrSequenceId} from "../../../../../service/VisitService";
import EditRecurringVisitModal from "../../../modals/EditRecurringVisitModal";
import VisitDetailEmployee from "./VisitDetailEmployee";
import ChooseWorkersModalBottomSheet from "../../../modals/job/editJob/ChooseWorkersModalBottomSheet";
import VisitDetailRepeatInfo from "./VisitDetailRepeatInfo";
import CloseIcon from "../../../../../icons/CloseIcon";
import ChooseJobFormModalBottomSheet, {
    IChooseJobFormModalBottomSheetOnSaveParams
} from "../../../modals/job/editJob/ChooseJobFormModalBottomSheet";
import JobFormItem from "../../newJob/JobFormItem";
import {HideConfirmModal, SetConfirmModal} from "../../../modals/AppModals";
import VisitOfferItem from "./VisitOfferItem";
import JobOfferModalBottomSheet, {IJobOfferData} from "../../../modals/job/JobOfferModalBottomSheet";
import {arrayContainsAll, uniqueArray} from "../../../../../utils/Utils";
import Icons8Reset from "../../../../../icons/Icons8Reset";
import FormBuilder, {IInputsData, InputType} from "../../../form/FormBuilder";
import {processMutationError} from "../../../../../service/ErrorService";
import {useSearchParams} from "react-router-dom";
import ProductsSection from "../../../products/ProductsSection";
import VisitProtocolsTab from "./visitProtocols/VisitProtocolsTab";
import WorkerTimesheetModalBottomSheet from "../../../modals/timesheet/WorkerTimesheetModalBottomSheet";
import {FormatDateWithIfTodayModification} from "../../../../../utils/DateUtils";
import {findJobOfferSeatForUuid, isJobOfferSeatAccepted} from "../../../../../service/JobService";
import Icons8Contacts from "../../../../../icons/Icons8Contacts";
import Icons8Plus from "../../../../../icons/Icons8Plus";
import VisitDetailJobChip from "./VisitDetailJobChip";
import Icons8Reseller from "../../../../../icons/Icons8Reseller";
import CreateWorkerModal from "../../../workers/CreateWorkerModal";
import Icons8ClipBoardList from "../../../../../icons/Icons8ClipBoardList";
import CreateJobFormModal, {INewJobFormModalPropsOnCreateParams} from "../../../modals/jobForms/CreateJobFormModal";
import {IOnUpdateVisitIdParams} from "../../../modals/job/visits/VisitDetailModal";
import VisitDetailClientTab from "./VisitDetailClientTab";
import VisitDetailStatusSection from "./VisitDetailStatusSection";
import VisitDetailMultidayInfoSection from "./VisitDetailMultidayInfoSection";
import MaterialsSection from "../../../materials/MaterialsSection";
import {
    kActionCreate,
    kActionUpdate,
    kActionView,
    kPermissionsClients, kPermissionsCompanyConfiguration,
    kPermissionsForms,
    kPermissionsMaterials,
    kPermissionsProducts,
    kPermissionsVisitAttachments,
    kPermissionsVisitClientDetails,
    kPermissionsVisitProtocols,
    kPermissionsVisitWorkers,
    kPermissionsWorkers
} from "../../../../../core/constants";
import {hasPermission, hasSomePermissions} from "../../../permissions/PermissionValid";
import PeopleIcon from "../../../../../icons/PeopleIcon";
import WorkersIcon from "../../../../../icons/WorkersIcon";
import Icons8Product from "../../../../../icons/Icons8Product";
import Icons8Layers from "../../../../../icons/Icons8Layers";
import Icons8Attachment from "../../../../../icons/Icons8Attachment";
import AppAccordion from "../../../modals/AppAccordion";
import MediaIcon from "../../../../../icons/MediaIcon";
import VisitDetailAttachmentsTab from "./VisitDetailAttachmentsTab";
import SettingsIcon from "../../../../../icons/SettingsIcon";
import EditVisitConfigurationsModalBottomSheet
    from "../../../modals/job/visits/EditVisitConfigurationsModalBottomSheet";

export const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        fontSize: 20,
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
        fontWeight: "bold",
    },
    noTitleText: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontSize: 20,
        fontWeight: 600,
    },
    removeHorizontalMargins: {
        marginRight: -16,
        marginLeft: -16,
        marginBottom: 16,
    },
    footerText: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontSize: 12,
        fontWeight: 600
    },
    repeatInfoSectionContainer: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    repeatableInfoSection: {
        display: "flex",
        alignItems: "center",
        'p': {
            fontSize: 14,
            fontWeight: 500,
            color: kAppColors.text.primary(theme.palette.mode === "dark")
        }
    },
    closeJobSwitchForm: {
        width: "unset",
        margin: "unset",
        '.MuiFormControlLabel-label': {
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
            textTransform: "unset",
        }
    },
    mobileOnly: {
        display: "none",
        "@media (max-width: 767px)": {
            display: "block"
        }
    },
    jobChipContainer: {
        maxWidth: '100%',
        paddingTop: 16,
        paddingBottom: 16,
        display: "flex",
        alignItems: "center",
        '.MuiGrid-item > div': {
            marginBottom: 0,
        },
        '.MuiFormControlLabel-root .MuiTypography-root': {
            textTransform: "none",
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
            fontWeight: 500,
        },
        '.MuiChip-root': {
            "@media (max-width: 767px)": {
                maxWidth: '50%',
            }
        }
    },
}));

export interface IVisitDetailContentProps {
    open: boolean;
    data?: VisitDetailResponse | NullOrUndefined;
    visitId: number;
    repeatingDay?: number;
    onUpdateVisitId: (params: IOnUpdateVisitIdParams) => void;
    setCanLoadingAlways?: Dispatch<SetStateAction<boolean>>;
    onNavigateToJob?: VoidFunction;
    preselectSelectedTab?: string | NullOrUndefined;
}

let recurringConfirmActionCallback: ((repeats: JobUpdateRepeats) => void) | ((repeats: JobUpdateRepeats) => Promise<void>) | undefined;

export default function VisitDetailContent(props: IVisitDetailContentProps) {
    const {
        open,
        data,
        visitId,
        repeatingDay,
        onUpdateVisitId,
        setCanLoadingAlways,
        onNavigateToJob,
        preselectSelectedTab,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {companyId, employeePermissionsMap, employeeId, storage} = appDataContext;

    const {classes, cx} = useStyles();

    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedTab, setSelectedTab] = useState<string>(
        preselectSelectedTab ? preselectSelectedTab : (searchParams.get('visitDetailContent-selectedTab') ? searchParams.get('visitDetailContent-selectedTab')! : 'client')
    );
    const [recurringEditModal, setRecurringEditModal] = useState(false);
    const [repeats, setRepeats] = useState<JobUpdateRepeats>(JobUpdateRepeats.Single);
    const [bottomSheetWorkers, setBottomSheetWorkers] = useState<boolean>(false);
    const [bottomSheetWorkersConfiguration, setBottomSheetWorkersConfiguration] = useState<boolean>(false);
    const [createWorkerModal, setCreateWorkerModal] = useState<boolean>(false);
    const [showWasCanceledByEmployee, setShowWasCanceledByEmployee] = useState<EmployeeJoinedUserResponse>();
    const [showWasCanceledByJobOffer, setShowWasCanceledByJobOffer] = useState<boolean>(false);
    const [historyLogCancelledByManager, setHistoryLogCancelledByManager] = useState<EmployeeJoinedUserResponse>();
    const [bottomSheetOffers, setBottomSheetOffers] = useState<boolean>(false);
    const [jobOfferSeatDuplicateData, setJobOfferSeatDuplicateData] = useState<CreateJobOfferSeatInput>();
    const [chooseJobForm, setChooseJobForm] = useState(false);
    const [createJobFormModal, setCreateJobFormModal] = useState<boolean>(false);
    const [timesheetModal, setTimesheetModal] = useState<boolean>(false);
    const [timesheetModalProps, setTimesheetModalProps] = useState<IJobDetailEmployeeTimesheetModalProps | undefined>(undefined);

    const [mutateAssignedEmployees, {
        loading: assignEmployeesLoading,
    }] = useMutation<UpdateVisitAssignedEmployeesMutation, UpdateVisitAssignedEmployeesMutationVariables>(UpdateVisitAssignedEmployeesDocument);

    const [mutateUpdateJobEmployeeStatus, {
        loading: updateJobEmployeeStatusLoading,
    }] = useMutation<UpdateJobEmployeeStatusOfVisitMutation, UpdateJobEmployeeStatusOfVisitMutationVariables>(UpdateJobEmployeeStatusOfVisitDocument);

    const [mutateSetJobEmployeeStatusAsIgnored, {
        loading: setJobEmployeeStatusAsIgnoredLoading,
    }] = useMutation<SetJobEmployeeStatusAsIgnoredOfVisitMutation, SetJobEmployeeStatusAsIgnoredOfVisitMutationVariables>(SetJobEmployeeStatusAsIgnoredOfVisitDocument);

    const [mutateAddVisitJobForm, {
        loading: addJobJobFormLoading,
    }] = useMutation<AddVisitJobFormMutation, AddVisitJobFormMutationVariables>(AddVisitJobFormDocument);

    const [mutateDeleteVisitJobForm, {
        loading: deleteJobJobFormLoading,
    }] = useMutation<DeleteVisitJobFormMutation, DeleteVisitJobFormMutationVariables>(DeleteVisitJobFormDocument);

    const [mutateUpdateVisitJobFormElementDone, {
        loading: updateJobJobFormElementDoneLoading,
    }] = useMutation<UpdateVisitJobFormElementDoneStateMutation, UpdateVisitJobFormElementDoneStateMutationVariables>(UpdateVisitJobFormElementDoneStateDocument);

    const [mutateAddJobOfferSeatToVisit, {
        loading: addJobOfferSeatToJobLoading,
    }] = useMutation<AddJobOfferSeatToVisitMutation, AddJobOfferSeatToVisitMutationVariables>(AddJobOfferSeatToVisitDocument);

    const [mutateUpdateJobOfferSeatOfVisit, {
        loading: updateJobOfferSeatOfJobLoading,
    }] = useMutation<UpdateJobOfferSeatOfVisitMutation, UpdateJobOfferSeatOfVisitMutationVariables>(UpdateJobOfferSeatOfVisitDocument);

    const [mutateCloseJobOfferSeatOfVisit, {
        loading: closeJobOfferSeatOfJobLoading,
    }] = useMutation<CloseJobOfferSeatOfVisitMutation, CloseJobOfferSeatOfVisitMutationVariables>(CloseJobOfferSeatOfVisitDocument);

    const [mutateDeleteJobOfferSeatFromVisit, {
        loading: deleteJobOfferSeatFromJobLoading,
    }] = useMutation<DeleteJobOfferSeatFromVisitMutation, DeleteJobOfferSeatFromVisitMutationVariables>(DeleteJobOfferSeatFromVisitDocument);

    const [mutateCloseVisit, {
        loading: closeJobLoading,
    }] = useMutation<CloseVisitMutation, CloseVisitMutationVariables>(CloseVisitDocument);

    const [mutateOpenVisit, {
        loading: openJobLoading,
    }] = useMutation<OpenVisitMutation, OpenVisitMutationVariables>(OpenVisitDocument);

    const [mutateAddVisitProduct, {
        loading: addVisitProductLoading,
    }] = useMutation<AddVisitProductMutation, AddVisitProductMutationVariables>(AddVisitProductDocument);

    const [mutateUpdateJobProduct, {
        loading: updateJobProductLoading,
    }] = useMutation<UpdateVisitProductMutation, UpdateVisitProductMutationVariables>(UpdateVisitProductDocument);

    const [mutateDeleteJobProduct, {
        loading: deleteJobProductLoading,
    }] = useMutation<DeleteVisitProductMutation, DeleteVisitProductMutationVariables>(DeleteVisitProductDocument);

    const visitData = data?.visit;
    const isRepeating = data?.visit.repeating !== VisitRepeating.Never;
    const repeatingDayData = data?.visit.repeatingDayData;
    const locationData = data?.location;
    const assignedEmployeeIds = repeatingDayData ? repeatingDayData.employeeIds : visitData?.employeeIds;
    const theVisitStatus = repeatingDayData?.status || visitData?.status || VisitStatus.Scheduled;
    const theVisitStatusModifier = repeatingDayData?.statusModifier || visitData?.statusModifier;
    const canEdit = theVisitStatus !== VisitStatus.Canceled && theVisitStatus !== VisitStatus.Closed;

    let start: DateTime = visitData?.startDate ? DateTime.fromMillis(visitData.startDate) : DateTime.now();

    if (repeatingDayData) {
        start = repeatingDayData.startDate ? DateTime.fromMillis(repeatingDayData.startDate) : start;
    } else if (isRepeating && repeatingDay && visitData) {
        const jobStartTime = DateTime.fromMillis(visitData.startTime);
        start = DateTime.fromMillis(repeatingDay).set({hour: jobStartTime.hour, minute: jobStartTime.minute});
    }

    useEffect(() => {
        if (open && preselectSelectedTab) {
            setSelectedTab(preselectSelectedTab);
        }
    }, [open, preselectSelectedTab]);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('visitDetailContent-selectedTab', selectedTab);

            return prev;
        });
    }, [selectedTab]);

    useEffect(() => {
        if (data && assignedEmployeeIds) {
            let newEmployee: EmployeeJoinedUserResponse | undefined;

            for (const logOf of data.history!) {
                if (logOf.employeeStatus === JobEmployeeStatus.CanceledByManager && logOf.employeeId && assignedEmployeeIds.includes(logOf.employeeId)) {
                    newEmployee = data.employees.find(employee => employee.id === logOf.currentEmployeeId)

                    break;
                }
            }

            setHistoryLogCancelledByManager(
                newEmployee
            );
        } else if (historyLogCancelledByManager) {
            setHistoryLogCancelledByManager(undefined);
        }
    }, [data, assignedEmployeeIds]);

    useEffect(() => {
        // check for unread canceled statuses
        if (data && assignedEmployeeIds) {
            let newShowWasCanceledByEmployee: EmployeeJoinedUserResponse | undefined;

            assignedEmployeeIds.forEach(employeeId => {
                let employeeJobData = data!.employeeJobData.find(employeeJobData => employeeJobData.employeeId === employeeId && repeatingDay && employeeJobData.repeatingDay === repeatingDay);
                employeeJobData = employeeJobData ? employeeJobData : data!.employeeJobData.find(employeeJobData => employeeJobData.employeeId === employeeId && !employeeJobData.repeatingDay);

                if (employeeJobData?.status === JobEmployeeStatus.CanceledByWorker && !employeeJobData?.ignoreForVisitStatus) {
                    newShowWasCanceledByEmployee = data.employees.find(employee => employee.id === employeeId);
                }
            });

            setShowWasCanceledByEmployee(newShowWasCanceledByEmployee);
        } else {
            //no employees -> set unread to false
            if (showWasCanceledByEmployee) {
                setShowWasCanceledByEmployee(undefined);
            }
        }
    }, [data, assignedEmployeeIds]);

    useEffect(() => {
        if (data) {
            const toSolveJobOfferSeat = data.jobOfferSeat
                .find(jobOfferSeat => !jobOfferSeat.completed && !jobOfferSeat.deleted && jobOfferSeat.acceptedIds.length === 0);

            if (toSolveJobOfferSeat && arrayContainsAll(toSolveJobOfferSeat.rejectedIds, toSolveJobOfferSeat.employeeIds)) {
                setShowWasCanceledByJobOffer(true);
            } else {
                setShowWasCanceledByJobOffer(false);
            }
        }
    }, [data]);

    useEffect(() => {
        if (theVisitStatus) {
            if (theVisitStatus === VisitStatus.Closed) {
                setCloseJobSwitchInput(prev => {
                    return {
                        ...prev,
                        closeJob: {
                            ...prev.closeJob,
                            value: true,
                            onSwitch: (value) => {
                                if (value) {
                                    closeVisit();
                                } else {
                                    openVisit();
                                }
                            },
                        },
                    };
                });
            } else {
                setCloseJobSwitchInput(prev => {
                    return {
                        ...prev,
                        closeJob: {
                            ...prev.closeJob,
                            value: false,
                            onSwitch: (value) => {
                                if (value) {
                                    closeVisit();
                                } else {
                                    openVisit();
                                }
                            },
                        },
                    };
                });
            }
        }
    }, [theVisitStatus, visitData]);

    useEffect(() => {
        if (setCanLoadingAlways) {
            setCanLoadingAlways(
                !(timesheetModal && !!timesheetModalProps)
            );
        }
    }, [setCanLoadingAlways, timesheetModal, timesheetModalProps]);

    /**
     * Mutate close of Visit to BE.
     */
    const closeVisit = async () => {
        try {
            const variables: CloseVisitMutationVariables = {
                input: {
                    visitId,
                    repeatingDay,
                    repeats: JobUpdateRepeats.Single,
                },
            };

            const result = await mutateCloseVisit({variables});

            if (!result.errors) {
                SuccessToast(tt('visitDetailContent.closeJob.success'));

                if (result.data!.closeVisit.id) {
                    onUpdateVisitId({
                        visitId: result.data!.closeVisit.id,
                        repeatingDay: repeatingDay!,
                    });
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * Mutate open of Visit to BE.
     */
    const openVisit = async () => {
        try {
            const variables: OpenVisitMutationVariables = {
                input: {
                    visitId,
                    repeatingDay,
                    repeats: JobUpdateRepeats.Single,
                },
            };

            const result = await mutateOpenVisit({variables});

            if (!result.errors) {
                SuccessToast(tt('visitDetailContent.openVisit.success'));

                if (result.data!.openVisit.id) {
                    onUpdateVisitId({
                        visitId: result.data!.openVisit.id,
                        repeatingDay: repeatingDay!,
                    });
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * On confirm recurring modal call correct action by recurringConfirmAction.
     */
    const onRecurringConfirm = (repeats: JobUpdateRepeats) => {
        setRepeats(repeats);

        recurringConfirmActionCallback!(repeats);
    };

    /**
     * Mutate update Employees of Visit to BE.
     */
    const updateAssignedEmployees = async (repeats: JobUpdateRepeats, employeeIds: number[], isRemovalTypeUpdate?: boolean) => {
        try {
            const variables: UpdateVisitAssignedEmployeesMutationVariables = {
                input: {
                    action: isRemovalTypeUpdate ? UpdateVisitAssignedEmployeesAction.DeleteSingle : UpdateVisitAssignedEmployeesAction.UpdateAssigned,
                    visitId,
                    repeatingDay,
                    repeats,
                    employeeIds,
                },
            };

            const result = await mutateAssignedEmployees({variables});

            if (!result.errors) {
                setBottomSheetWorkers(false);

                if (isRemovalTypeUpdate) {
                    SuccessToast(tt('visitDetailContent.updateAssignedEmployees.delete.confirm.title'));
                } else {
                    SuccessToast(tt('visitDetailContent.updateAssignedEmployees.success'));
                }

                if (result.data!.updateVisitAssignedEmployees.id) {
                    onUpdateVisitId({
                        visitId: result.data!.updateVisitAssignedEmployees.id,
                        repeatingDay: repeatingDay!,
                    });
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * Mutate update JobEmployeeStatus to BE.
     */
    const updateJobEmployeeStatus = async (employeeId: number, status: JobEmployeeStatus) => {
        try {
            const variables: UpdateJobEmployeeStatusOfVisitMutationVariables = {
                input: {
                    visitId,
                    repeatingDay,
                    repeats,
                    employeeId,
                    status,
                },
            };

            const result = await mutateUpdateJobEmployeeStatus({variables});

            if (!result.errors) {
                SuccessToast(tt('visitDetailContent.updateJobEmployeeStatus.success'));

                if (result.data!.updateJobEmployeeStatusOfVisit.id) {
                    onUpdateVisitId({
                        visitId: result.data!.updateJobEmployeeStatusOfVisit.id,
                        repeatingDay: repeatingDay!,
                    });
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * Mutate set JobEmployeeStatus as ignored to BE.
     */
    const ignoreJobEmployeeStatus = async (employeeId: number) => {
        try {
            const variables: SetJobEmployeeStatusAsIgnoredOfVisitMutationVariables = {
                input: {
                    visitId,
                    repeatingDay,
                    repeats,
                    employeeId,
                },
            };

            const result = await mutateSetJobEmployeeStatusAsIgnored({variables});

            if (!result.errors) {
                SuccessToast(tt('visitDetailContent.ignoreJobEmployeeStatus.success'));

                if (result.data!.setJobEmployeeStatusAsIgnoredOfVisit.id) {
                    onUpdateVisitId({
                        visitId: result.data!.setJobEmployeeStatusAsIgnoredOfVisit.id,
                        repeatingDay: repeatingDay!,
                    });
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * Mutate add JobForm to Job to BE.
     */
    const addAssignedJobForm = async (repeats: JobUpdateRepeats, jobForms: Array<CreateVisitJobFormInput>) => {
        try {
            const variables: AddVisitJobFormMutationVariables = {
                input: {
                    visitId,
                    repeatingDay,
                    repeats,
                    jobForms,
                },
            };

            const result = await mutateAddVisitJobForm({variables});

            if (!result.errors) {
                SuccessToast(tt('visitDetailContent.addAssignedJobForm.success'));

                if (result.data!.addVisitJobForm.id) {
                    onUpdateVisitId({
                        visitId: result.data!.addVisitJobForm.id,
                        repeatingDay: repeatingDay!,
                    });
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * Mutate delete JobForm from Visit to BE.
     */
    const deleteAssignedJobForm = async (repeats: JobUpdateRepeats, jobFormUUID: string) => {
        try {
            const variables: DeleteVisitJobFormMutationVariables = {
                input: {
                    visitId,
                    repeatingDay,
                    repeats,
                    jobFormUUID,
                },
            };

            const result = await mutateDeleteVisitJobForm({variables});

            if (!result.errors) {
                SuccessToast(tt('visitDetailContent.deleteAssignedJobForm.success'));

                if (result.data!.deleteVisitJobForm.id) {
                    onUpdateVisitId({
                        visitId: result.data!.deleteVisitJobForm.id,
                        repeatingDay: repeatingDay!,
                    });
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * Mutate update of JobFormElement done to BE.
     */
    const updateJobFormElementDone = async (jobFormUUID: string, elements: Array<UpdateJobFormElementDoneStateElementInput>, onSuccessCallback: VoidFunction) => {
        try {
            const variables: UpdateVisitJobFormElementDoneStateMutationVariables = {
                input: {
                    visitId,
                    repeatingDay,
                    elements,
                    jobFormUUID,
                },
            };

            const result = await mutateUpdateVisitJobFormElementDone({variables});

            if (!result.errors) {
                onSuccessCallback();

                SuccessToast(tt('visitDetailContent.updateJobFormElementDone.success'));
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * Mutate add new JobOfferSeat to BE.
     */
    const addJobOfferSeat = async (repeats: JobUpdateRepeats, offerSeatData: IJobOfferData, message?: string) => {
        try {
            const variables: AddJobOfferSeatToVisitMutationVariables = {
                input: {
                    visitId,
                    repeatingDay,
                    repeats,
                    companyId: companyId!,
                    paymentType: offerSeatData.type,
                    hours: offerSeatData.hours,
                    minutes: offerSeatData.minutes,
                    hourRate: offerSeatData.hourRate,
                    fixedPrice: offerSeatData.fixedPrice,
                    employeeIds: offerSeatData.employeeIds,
                    substituteCount: offerSeatData.substituteCount,
                    sendNotifications: offerSeatData.sendNotifications,
                },
            };

            const result = await mutateAddJobOfferSeatToVisit({variables});

            if (!result.errors) {
                SuccessToast(message || tt('visitDetailContent.addJobOfferSeat.success'));

                if (result.data!.addJobOfferSeatToVisit.id) {
                    onUpdateVisitId({
                        visitId: result.data!.addJobOfferSeatToVisit.id,
                        repeatingDay: repeatingDay!,
                    });
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * Mutate update JobOfferSeat to BE.
     */
    const updateJobOfferSeat = async (jobOfferSeatId: number, offerSeatData: IJobOfferData) => {
        try {
            const variables: UpdateJobOfferSeatOfVisitMutationVariables = {
                input: {
                    jobOfferSeatId,
                    visitId,
                    repeatingDay,
                    repeats: JobUpdateRepeats.Single,
                    companyId: companyId!,
                    paymentType: offerSeatData.type,
                    hours: offerSeatData.hours,
                    minutes: offerSeatData.minutes,
                    hourRate: offerSeatData.hourRate,
                    fixedPrice: offerSeatData.fixedPrice,
                    employeeIds: offerSeatData.employeeIds,
                    substituteCount: offerSeatData.substituteCount,
                    sendNotifications: offerSeatData.sendNotifications,
                },
            };

            const result = await mutateUpdateJobOfferSeatOfVisit({variables});

            if (!result.errors) {
                SuccessToast(tt('jobDetailContent.updateJobOfferSeat.success'));

                if (result.data!.updateJobOfferSeatOfVisit.id) {
                    onUpdateVisitId({
                        visitId: result.data!.updateJobOfferSeatOfVisit.id,
                        repeatingDay: repeatingDay!,
                    });
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * Mutate to close JobOfferSeat of Job.
     * This means that all pending Employees are removed.
     */
    const closeJobOfferSeat = async (repeats: JobUpdateRepeats, jobOfferSeatId: number) => {
        SetConfirmModal(appDataContext, {
            open: true,
            title: tt('visitDetailContent.closeJobOfferSeat.confirmModal.title'),
            subtitle: tt('visitDetailContent.closeJobOfferSeat.confirmModal.description'),
            confirmationButtonText: tt('visitDetailContent.closeJobOfferSeat.confirmModal.confirmButton'),
            cancelButtonText: tt('common.close'),
            children: <></>,
            onConfirm: async () => {
                HideConfirmModal(appDataContext);

                try {
                    const variables: CloseJobOfferSeatOfVisitMutationVariables = {
                        input: {
                            repeatingDay,
                            repeats,
                            jobOfferSeatId,
                        },
                    };

                    const result = await mutateCloseJobOfferSeatOfVisit({variables});

                    if (!result.errors) {
                        SuccessToast(tt('visitDetailContent.closeJobOfferSeat.success'));

                        if (result.data!.closeJobOfferSeatOfVisit.id) {
                            onUpdateVisitId({
                                visitId: result.data!.closeJobOfferSeatOfVisit.id,
                                repeatingDay: repeatingDay!,
                            });
                        }
                    }
                } catch (e) {
                    processMutationError(e);
                }
            },
        });
    };

    /**
     * Mutate delete JobOfferSeat to BE.
     */
    const deleteJobOfferSeat = async (repeats: JobUpdateRepeats, jobOfferSeatId: number) => {
        SetConfirmModal(appDataContext, {
            open: true,
            title: tt('visitDetailContent.deleteJobOfferSeat.confirmModal.title'),
            subtitle: tt('visitDetailContent.deleteJobOfferSeat.confirmModal.description'),
            confirmationButtonText: tt('common.remove'),
            cancelButtonText: tt('common.close'),
            children: <></>,
            onConfirm: async () => {
                HideConfirmModal(appDataContext);

                try {
                    const variables: DeleteJobOfferSeatFromVisitMutationVariables = {
                        input: {
                            repeatingDay,
                            repeats,
                            jobOfferSeatId,
                        },
                    };

                    const result = await mutateDeleteJobOfferSeatFromVisit({variables});

                    if (!result.errors) {
                        SuccessToast(tt('visitDetailContent.deleteJobOfferSeat.success'));

                        if (result.data!.deleteJobOfferSeatFromVisit.id) {
                            onUpdateVisitId({
                                visitId: result.data!.deleteJobOfferSeatFromVisit.id,
                                repeatingDay: repeatingDay!,
                            });
                        }
                    }
                } catch (e) {
                    processMutationError(e);
                }
            },
        });
    };

    /**
     * Mutate add Product to Visit to BE.
     */
    const addProductToVisit = async (input: Partial<AddVisitProductInput>) => {
        try {
            const variables: AddVisitProductMutationVariables = {
                input: {
                    visitId,
                    repeatingDay,
                    repeats,
                    uuid: input.uuid!,
                    name: input.name!,
                    description: input.description,
                    price: input.price!,
                    vatRate: input.vatRate,
                    unitName: input.unitName!,
                    type: input.type!,
                    unitCount: input.unitCount,
                    saveAsTemplate: false,
                    employeeId: input.employeeId!,
                },
            };

            const result = await mutateAddVisitProduct({variables});

            if (!result.errors) {
                SuccessToast(tt('visitDetailContent.addProduct.success'));

                if (result.data!.addVisitProduct.id) {
                    onUpdateVisitId({
                        visitId: result.data!.addVisitProduct.id,
                        repeatingDay: repeatingDay!,
                    });
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * Mutate update Product to Visit to BE.
     */
    const updateProductOfJob = async (input: Partial<UpdateVisitProductInput>) => {
        try {
            const variables: UpdateVisitProductMutationVariables = {
                input: {
                    visitId,
                    repeatingDay,
                    repeats,
                    employeeId: employeeId!,
                    productId: input.productId!,
                    productUuid: input.productUuid!,
                    name: input.name!,
                    description: input.description,
                    price: input.price!,
                    vatRate: input.vatRate,
                    unitName: input.unitName!,
                    unitCount: input.unitCount,
                },
            };

            const result = await mutateUpdateJobProduct({variables});

            if (!result.errors) {
                SuccessToast(tt('visitDetailContent.updateProduct.success'));

                if (result.data!.updateVisitProduct.id) {
                    onUpdateVisitId({
                        visitId: result.data!.updateVisitProduct.id,
                        repeatingDay: repeatingDay!,
                    });
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * Mutate delete Product to Job to BE.
     */
    const deleteProductOfJob = async (repeats: JobUpdateRepeats, productId: number) => {
        try {
            const theProduct = data!.products.find(product => product.id === productId);

            const variables: DeleteVisitProductMutationVariables = {
                input: {
                    visitId,
                    repeatingDay,
                    repeats,
                    productId: theProduct!.id,
                    productUuid: theProduct!.uuid!,
                },
            };

            const result = await mutateDeleteJobProduct({variables});

            if (!result.errors) {
                SuccessToast(tt('visitDetailContent.deleteProduct.success'));

                if (result.data!.deleteVisitProduct.id) {
                    onUpdateVisitId({
                        visitId: result.data!.deleteVisitProduct.id,
                        repeatingDay: repeatingDay!,
                    });
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    const morePopupState = usePopupState({
        variant: 'popover',
        popupId: 'popup-detail-more',
    });

    const menuJSX = (
        <Menu {...bindMenu(morePopupState)}>
            <MenuItem key={'deleteItem'}
                      onClick={() => {
                          morePopupState.close();
                      }}>
                <Box color={kAppColors.red.confirmButton}><CloseIcon/></Box>
                {tt('common.delete')}
            </MenuItem>
        </Menu>
    );

    const [closeJobSwitchInput, setCloseJobSwitchInput] = useState<IInputsData>({
        closeJob: {
            testId: 'formCloseJobSwitch',
            type: InputType.Switch,
            label: getVisitStatusTitle(VisitStatus.Closed),
            value: false,
            switchVariant: "Android12Switch",
            onSwitch: (value) => {
                if (value) {
                    closeVisit();
                } else {
                    openVisit();
                }
            },
        },
    });

    const repeatJobInfoJSX = isRepeating ? (
        <>
            <Divider/>
            <AppAccordion
                summaryWidget={<Box className={classes.repeatableInfoSection}>
                    <Icons8Reset/><Box
                    pl={1}/><Typography>{tt('visitDetail.screen.label.recurringVisit')}</Typography>
                </Box>}
            >
                <Box pr={2} pl={2}>
                    <VisitDetailRepeatInfo visitData={visitData} repeatDayData={repeatingDayData}/>
                </Box>
            </AppAccordion>
        </>
    ) : (
        <VisitDetailMultidayInfoSection
            visitData={visitData}
            visitRepeatingDay={repeatingDay}
            visitRepeatDay={repeatingDayData}
        />
    );

    const filteredAssignedEmployeeIds = useMemo(() => {
        let newFilteredAssignedEmployeeIds = assignedEmployeeIds ? [...assignedEmployeeIds] : undefined;

        if (newFilteredAssignedEmployeeIds) {
            newFilteredAssignedEmployeeIds.sort((a, b) => {
                let employeeJobDataA = data!.employeeJobData.find(employeeJobData => employeeJobData.employeeId === a && repeatingDay && employeeJobData.repeatingDay === repeatingDay);
                employeeJobDataA = employeeJobDataA ? employeeJobDataA : data!.employeeJobData.find(employeeJobData => employeeJobData.employeeId === a && !employeeJobData.repeatingDay);
                let employeeJobDataB = data!.employeeJobData.find(employeeJobData => employeeJobData.employeeId === b && repeatingDay && employeeJobData.repeatingDay === repeatingDay);
                employeeJobDataB = employeeJobDataB ? employeeJobDataB : data!.employeeJobData.find(employeeJobData => employeeJobData.employeeId === b && !employeeJobData.repeatingDay);

                const offerSeatA = findJobOfferSeatForUuid(
                    data?.jobOfferSeat,
                    employeeJobDataA?.jobOfferSeatUuid,
                    repeatingDay,
                );
                const offerSeatB = findJobOfferSeatForUuid(
                    data?.jobOfferSeat,
                    employeeJobDataB?.jobOfferSeatUuid,
                    repeatingDay,
                );

                if (offerSeatA && !offerSeatB) {
                    return 1;
                } else if (!offerSeatA && offerSeatB) {
                    return -1;
                }

                return 0;
            });

            const employeeIdsByRole: number[] = [];

            for (const role of [EmployeeRole.Admin, EmployeeRole.Manager, EmployeeRole.HeadOfWorkers, EmployeeRole.Worker]) {
                newFilteredAssignedEmployeeIds.forEach(employeeId => {
                    const employee = data!.employees.find(employee => employee.id === employeeId);

                    if (employee && employee.role === role) {
                        employeeIdsByRole.push(employeeId);
                    }
                });
            }

            newFilteredAssignedEmployeeIds.forEach(employeeId => {
                if (!employeeIdsByRole.includes(employeeId)) {
                    employeeIdsByRole.push(employeeId);
                }
            });

            newFilteredAssignedEmployeeIds = employeeIdsByRole;
        }

        return newFilteredAssignedEmployeeIds;
    }, [assignedEmployeeIds]);

    const jobOfferSeatsExcludeEmployeeIds = data && data.visit.repeatingDayData ? [
        ...data.visit.repeatingDayData.employeeIds,
    ] : data ? [
        ...data.visit.employeeIds,
    ] : [];

    const employeesJSX: React.ReactNode | undefined = filteredAssignedEmployeeIds && data ? filteredAssignedEmployeeIds
        .map((employeeId, index) => {
            const employee = data.employees.find(employee => employee.id === employeeId)!;

            let employeeJobData = data?.employeeJobData.find(employeeJobData => employeeJobData.employeeId === employeeId && repeatingDay && employeeJobData.repeatingDay === repeatingDay);
            employeeJobData = employeeJobData ? employeeJobData : data?.employeeJobData.find(employeeJobData => employeeJobData.employeeId === employeeId && !employeeJobData.repeatingDay);

            const timesheet = data?.employeeTimesheet.filter(timesheet => timesheet.employeeId === employeeId && !timesheet.deleted);

            const offerSeat = findJobOfferSeatForUuid(
                data?.jobOfferSeat,
                employeeJobData?.jobOfferSeatUuid,
                repeatingDay,
            );

            return (
                <VisitDetailEmployee
                    visitId={visitId}
                    repeatingDay={repeatingDay}
                    repeats={repeats}
                    status={theVisitStatus || VisitStatus.Scheduled}
                    key={employeeId}
                    canEdit={canEdit}
                    isRepeating={isRepeating}
                    employee={employee}
                    employeeJobData={employeeJobData}
                    employeeTimesheet={timesheet || []}
                    existingEmployeeIds={assignedEmployeeIds || []}
                    setRecurringConfirmActionCallback={(action: ((repeats: JobUpdateRepeats) => void) | ((repeats: JobUpdateRepeats) => Promise<void>) | undefined) => recurringConfirmActionCallback = action}
                    setRecurringEditModal={setRecurringEditModal}
                    updateAssignedEmployees={updateAssignedEmployees}
                    updateJobEmployeeStatus={updateJobEmployeeStatus}
                    ignoreJobEmployeeStatus={ignoreJobEmployeeStatus}
                    setRepeats={setRepeats}
                    jobOfferSeat={offerSeat}
                    onUpdateJobOfferSeat={(data: IJobOfferData) => updateJobOfferSeat(
                        offerSeat!.id,
                        {
                            type: data.type,
                            fixedPrice: data.fixedPrice || 0,
                            hours: data.hours || 0,
                            minutes: data.minutes || 0,
                            hourRate: data.hourRate || 0,
                            employeeIds: data.employeeIds,
                            substituteCount: data.substituteCount,
                            sendNotifications: data.sendNotifications,
                        },
                    )}
                    closeJobOfferSeat={() => {
                        recurringConfirmActionCallback = (repeats: JobUpdateRepeats) => closeJobOfferSeat(repeats, offerSeat!.id);

                        if (isRepeating) {
                            setRepeats(JobUpdateRepeats.Single);
                            closeJobOfferSeat(JobUpdateRepeats.Single, offerSeat!.id);
                        } else {
                            closeJobOfferSeat(JobUpdateRepeats.Single, offerSeat!.id);
                        }
                    }}
                    jobOfferSeatExcludeEmployeeIds={jobOfferSeatsExcludeEmployeeIds}
                    onUpdateVisitId={onUpdateVisitId}
                    setOpenTimesheetModal={(props: IJobDetailEmployeeTimesheetModalProps) => {
                        setTimesheetModalProps(props);
                        setTimesheetModal(true);
                    }}
                    location={data?.location}
                    jobLogForEmployee={data?.history!.filter(jobLog => jobLog.employeeId === employeeId)}
                    gpsLocationsForEmployee={data?.gpsLocations.filter(gpsLocation => gpsLocation.employeeId === employeeId)}
                    jobEmployeeData={data?.employees}
                    files={data?.files}
                />
            );
        }) : undefined;

    const offerSeatsJSX = data?.jobOfferSeat
        .filter(offerSeat => !offerSeat.deleted && !offerSeat.completed && !isJobOfferSeatAccepted(offerSeat))
        .map((offerSeat, index) => {
            return (
                <VisitOfferItem
                    key={offerSeat.id}
                    canEdit={canEdit}
                    index={index}
                    data={offerSeat}
                    showOfferTag={true}
                    excludeEmployeeIds={jobOfferSeatsExcludeEmployeeIds}
                    onDelete={() => {
                        recurringConfirmActionCallback = (repeats: JobUpdateRepeats) => deleteJobOfferSeat(repeats, offerSeat.id);

                        if (isRepeating) {
                            setRepeats(JobUpdateRepeats.Single);
                            deleteJobOfferSeat(JobUpdateRepeats.Single, offerSeat.id);
                        } else {
                            deleteJobOfferSeat(JobUpdateRepeats.Single, offerSeat.id);
                        }
                    }}
                    onDuplicate={() => {
                        const theEmployeeIds = offerSeat.employeeIds.filter(id => !jobOfferSeatsExcludeEmployeeIds.includes(id));

                        setJobOfferSeatDuplicateData({
                            paymentType: offerSeat.paymentType,
                            fixedPrice: offerSeat.fixedPrice || 0,
                            hours: offerSeat.hours || 0,
                            minutes: offerSeat.minutes || 0,
                            hourRate: offerSeat.hourRate || 0,
                            employeeIds: theEmployeeIds,
                            substituteCount: offerSeat.substituteCount,
                        });

                        setBottomSheetOffers(true);
                    }}
                    onUpdate={(data: IJobOfferData) => updateJobOfferSeat(
                        offerSeat.id,
                        {
                            type: data.type,
                            fixedPrice: data.fixedPrice || 0,
                            hours: data.hours || 0,
                            minutes: data.minutes || 0,
                            hourRate: data.hourRate || 0,
                            employeeIds: data.employeeIds,
                            substituteCount: data.substituteCount,
                            sendNotifications: data.sendNotifications,
                        },
                    )}
                    jobEmployeeData={data?.employees}
                    files={data?.files}
                />
            );
        });

    const workersJSX = (
        <>
            <HeadlineWithButton
                title={tt('common.workers')}
                /*iconJSX={<SettingsIcon/>}
                buttonText={tt('visitDetail.screen.workers.configuration')}
                onClick={canEdit ? () => {
                    recurringConfirmActionCallback = () => setBottomSheetWorkersConfiguration(true);

                    if (isRepeating) {
                        setRecurringEditModal(true);
                    } else {
                        setBottomSheetWorkersConfiguration(true);
                    }
                } : undefined}
                permission={kPermissionsCompanyConfiguration}
                requiredPermissions={[kActionUpdate]}*/
                button2OnClick={canEdit ? () => {
                    recurringConfirmActionCallback = () => setBottomSheetWorkers(true);

                    if (isRepeating) {
                        setRecurringEditModal(true);
                    } else {
                        setBottomSheetWorkers(true);
                    }
                } : undefined}
                button2Text={tt('common.select')}
                icon2JSX={<Icons8Contacts/>}
                permission2={kPermissionsWorkers}
                requiredPermissions2={[kActionUpdate]}
                button3Text={tt('visitDetail.screen.button.offer')}
                button3OnClick={canEdit ? () => {
                    recurringConfirmActionCallback = () => setBottomSheetOffers(true);

                    if (isRepeating) {
                        setRepeats(JobUpdateRepeats.Single);
                        setBottomSheetOffers(true);
                    } else {
                        setBottomSheetOffers(true);
                    }
                } : undefined}
                icon3JSX={<Icons8Reseller/>}
                permission3={kPermissionsWorkers}
                requiredPermissions3={[kActionUpdate]}
                button4OnClick={canEdit ? () => {
                    recurringConfirmActionCallback = () => setCreateWorkerModal(true);

                    if (isRepeating) {
                        setRecurringEditModal(true);
                    } else {
                        setCreateWorkerModal(true);
                    }
                } : undefined}
                button4Text={tt('common.newWorker')}
                icon4JSX={<Icons8Plus/>}
                permission4={kPermissionsWorkers}
                requiredPermissions4={[kActionCreate]}
            />

            {employeesJSX}

            {offerSeatsJSX}
        </>
    );

    const jobFormsJSX = data?.jobForms
        .sort((a, b) => {
            const aTemplate: JobFormPureResponse | undefined = a.templateId ? data?.jobFormTemplates?.find(template => template.id === a.templateId) : undefined;
            const bTemplate: JobFormPureResponse | undefined = b.templateId ? data?.jobFormTemplates?.find(template => template.id === b.templateId) : undefined;

            const aPos: number = aTemplate ? aTemplate.position : a.position;
            const bPos: number = bTemplate ? bTemplate.position : b.position;

            if (aPos === bPos) {
                return 0;
            }

            return aPos > bPos ? 1 : -1;
        })
        .map((jobForm, index) => {
            return (
                <JobFormItem
                    key={jobForm.uuid}
                    visitId={visitId}
                    repeatingDay={repeatingDay}
                    data={jobForm}
                    preview={true}
                    onDelete={canEdit ? (id: number) => {
                        SetConfirmModal(appDataContext, {
                            open: true,
                            title: tt('visitDetailContent.updateAssignedJobForm.delete.confirm.title'),
                            confirmationButtonText: tt('common.delete'),
                            cancelButtonText: tt('common.close'),
                            children: <></>,
                            onConfirm: () => {
                                HideConfirmModal(appDataContext);

                                recurringConfirmActionCallback = (repeats: JobUpdateRepeats) => deleteAssignedJobForm(repeats, jobForm.uuid!);

                                if (isRepeating) {
                                    setRecurringEditModal(true);
                                } else {
                                    deleteAssignedJobForm(repeats, jobForm.uuid!);
                                }
                            },
                        });
                    } : undefined}
                    updateJobFormElementDone={updateJobFormElementDone}
                    canEdit={canEdit}
                    employeesData={data?.employees}
                    files={data?.files}
                />
            );
        });

    const jobFormTabJSX = (
        <>
            <HeadlineWithButton
                title={tt('common.jobForm')}
                iconJSX={<Icons8ClipBoardList/>}
                onClick={canEdit ? () => {
                    recurringConfirmActionCallback = (repeats: JobUpdateRepeats) => setChooseJobForm(true);

                    if (isRepeating) {
                        setRecurringEditModal(true);
                    } else {
                        setChooseJobForm(true);
                    }
                } : undefined}
                buttonText={tt('common.select')}
                permission={kPermissionsForms}
                requiredPermissions={[kActionUpdate]}
                icon2JSX={<Icons8Plus/>}
                button2OnClick={canEdit ? () => {
                    recurringConfirmActionCallback = (repeats: JobUpdateRepeats) => setCreateJobFormModal(true);

                    if (isRepeating) {
                        setRecurringEditModal(true);
                    } else {
                        setCreateJobFormModal(true);
                    }
                } : undefined}
                button2Text={tt('visitDetail.button.createNewForm')}
                permission2={kPermissionsForms}
                requiredPermissions2={[kActionCreate]}
            />

            {jobFormsJSX}

            <CreateJobFormModal
                open={createJobFormModal}
                setOpen={setCreateJobFormModal}
                onCreate={(params: INewJobFormModalPropsOnCreateParams) => {
                    if (params.id) {
                        addAssignedJobForm(repeats, [{jobFormId: params.id}]);
                    } else if (params.input) {
                        addAssignedJobForm(repeats, [{input: params.input}]);
                    }
                }}
                saveAsSingleOrTemplate={true}
            />

            <ChooseJobFormModalBottomSheet
                open={chooseJobForm}
                setOpen={setChooseJobForm}
                onSave={(params: IChooseJobFormModalBottomSheetOnSaveParams) => {
                    if (params.ids) {
                        addAssignedJobForm(repeats, params.ids.map(id => ({jobFormId: id})));
                    } else if (params.input) {
                        addAssignedJobForm(repeats, [{input: params.input}]);
                    }
                }}
                modalAboveModals={true}
                saveAsSingleOrTemplate={true}
            />
        </>
    );

    const authoredJSX = data ? (
        <Box p={2}>
            <Typography className={classes.footerText}>
                {tt('jobDetail.screen.footerNote.created')}
                &nbsp;
                {FormatDateWithIfTodayModification(undefined, data.visit.createdAt)}
                &nbsp;
                ·
                &nbsp;
                {UserFullName(data.createdByEmployee.name || data.createdByUser.name, data.createdByEmployee.surname || data.createdByUser.surname)}
            </Typography>
        </Box>
    ) : undefined;

    const jobOfferModalBottomSheetJSX = (
        <JobOfferModalBottomSheet
            modalAboveModals={true}
            existingEmployeeIds={jobOfferSeatDuplicateData?.employeeIds || []}
            open={bottomSheetOffers}
            setOpen={setBottomSheetOffers}
            newJobExistingData={jobOfferSeatDuplicateData}
            excludeEmployeeIds={jobOfferSeatsExcludeEmployeeIds}
            onSave={(data: IJobOfferData) => {
                setJobOfferSeatDuplicateData(undefined);

                addJobOfferSeat(
                    repeats,
                    {
                        type: data.type,
                        fixedPrice: data.fixedPrice || 0,
                        hours: data.hours || 0,
                        minutes: data.minutes || 0,
                        hourRate: data.hourRate || 0,
                        employeeIds: data.employeeIds,
                        substituteCount: data.substituteCount,
                        sendNotifications: data.sendNotifications,
                    },
                );
            }}
            notificationsCheckbox={true}
        />
    );

    const tabsData: Array<{
        'index': string,
        'icon'?: React.ReactNode,
        'label': string,
        'content': React.ReactNode,
        'showUnread'?: boolean,
    }> = [];

    if (hasSomePermissions([{
        permission: kPermissionsClients,
        requiredPermissions: [kActionView],
    }, {
        permission: kPermissionsVisitClientDetails,
        requiredPermissions: [kActionView],
    }], employeePermissionsMap)) {
        tabsData.push({
            index: 'client',
            label: tt('common.client'),
            icon: <Icons8Contacts/>,
            content: (
                <VisitDetailClientTab
                    canEdit={canEdit}
                    visitId={visitId}
                    repeatingDay={repeatingDay}
                    setRecurringConfirmActionCallback={(action: ((repeats: JobUpdateRepeats) => void) | ((repeats: JobUpdateRepeats) => Promise<void>) | undefined) => recurringConfirmActionCallback = action}
                    setRecurringEditModal={setRecurringEditModal}
                    repeats={repeats}
                    setRepeats={setRepeats}
                    onUpdateVisitId={onUpdateVisitId}
                    data={data}
                />
            ),
        });
    }

    if (hasSomePermissions([{
        permission: kPermissionsWorkers,
        requiredPermissions: [kActionView],
    }, {
        permission: kPermissionsVisitWorkers,
        requiredPermissions: [kActionView],
    }], employeePermissionsMap)) {
        tabsData.push({
            index: 'workers',
            label: tt('common.workers'),
            icon: <WorkersIcon/>,
            content: workersJSX,
            showUnread: !!showWasCanceledByEmployee || showWasCanceledByJobOffer,
        });
    }

    if (hasPermission(kPermissionsProducts, [kActionView], employeePermissionsMap)) {
        tabsData.push({
            index: 'products',
            label: tt('visitDetailTab.title.products'),
            icon: <Icons8Product/>,
            content: (
                <ProductsSection
                    isVisitDetail={true}
                    products={data?.products}
                    files={data?.files}
                    employees={data?.employees}
                    mutateLoading={addVisitProductLoading || updateJobProductLoading}
                    addVisitProduct={addProductToVisit}
                    updateVisitProduct={updateProductOfJob}
                    deleteProductOfVisit={deleteProductOfJob}
                    repeats={repeats}
                    isRepeating={isRepeating}
                    setRecurringConfirmActionCallback={(action: ((repeats: JobUpdateRepeats) => void) | ((repeats: JobUpdateRepeats) => Promise<void>) | undefined) => recurringConfirmActionCallback = action}
                    setRecurringEditModal={setRecurringEditModal}
                    setRepeats={setRepeats}
                    canEdit={canEdit}
                />
            ),
        });
    }

    if (hasPermission(kPermissionsMaterials, [kActionView], employeePermissionsMap)) {
        tabsData.push({
            index: 'materials',
            label: tt('visitDetailTab.title.materials'),
            icon: <Icons8Layers/>,
            content: (
                <MaterialsSection
                    isVisitDetail={true}
                    materials={data?.materials}
                    files={data?.files}
                    visitId={visitId}
                    repeatingDay={repeatingDay}
                    repeats={repeats}
                    isRepeating={isRepeating}
                    setRecurringConfirmActionCallback={(action: ((repeats: JobUpdateRepeats) => void) | ((repeats: JobUpdateRepeats) => Promise<void>) | undefined) => recurringConfirmActionCallback = action}
                    setRecurringEditModal={setRecurringEditModal}
                    setRepeats={setRepeats}
                    employees={data?.employees}
                    canEdit={canEdit}
                />
            )
        });
    }

    if (hasPermission(kPermissionsForms, [kActionView], employeePermissionsMap) && !(theVisitStatus === VisitStatus.Closed && data?.jobForms.length === 0)) {
        tabsData.push({
            index: 'jobForm',
            label: tt('common.jobForm'),
            icon: <Icons8ClipBoardList/>,
            content: jobFormTabJSX,
        });
    }

    const documents = (data?.visit.repeatingDayData ? data?.visit.repeatingDayData.attachmentFileIds : data?.visit?.attachmentFileIds) || [];
    if (hasPermission(kPermissionsVisitAttachments, [kActionView], employeePermissionsMap) && !(theVisitStatus === VisitStatus.Closed && documents.length === 0)) {
        tabsData.push({
            index: 'attachments',
            label: tt('common.attachment'),
            icon: <Icons8Attachment/>,
            content: (
                <VisitDetailAttachmentsTab
                    canEdit={canEdit}
                    visitId={visitId}
                    repeatingDay={repeatingDay}
                    data={data}
                />
            ),
        });
    }

    if (hasPermission(kPermissionsVisitProtocols, [kActionView], employeePermissionsMap)) {
        tabsData.push({
            index: 'protocol',
            label: tt('common.protocol'),
            icon: <MediaIcon/>,
            content: (
                <VisitProtocolsTab
                    visitId={visitId}
                    repeatingDay={repeatingDay}
                    canEdit={canEdit}
                    onUpdateVisitId={onUpdateVisitId}
                    files={data?.files || []}
                />
            ),
        });
    }

    const tabsIndexes: Array<string> = tabsData.map(tab => tab.index);
    useEffect(() => {
        const isTabValid = tabsIndexes.includes(selectedTab);
        if (!isTabValid) {
            if (tabsIndexes.length > 0) {
                setSelectedTab(tabsIndexes[0]);
            } else {
                setSelectedTab('');
            }
        }
    }, [tabsIndexes, selectedTab]);

    const timesheetModalJSX = timesheetModalProps ? (
        <WorkerTimesheetModalBottomSheet
            variant="edit"
            open={timesheetModal}
            setOpen={setTimesheetModal}
            employee={timesheetModalProps.employee}
            files={data?.files || []}
            visitId={visitId}
            repeatingDay={repeatingDay}
            repeats={repeats}
            employeeJobData={timesheetModalProps.employeeJobData}
            onUpdateVisitId={onUpdateVisitId}
        />
    ) : undefined;

    return (
        <>
            <Box className={classes.jobChipContainer}>
                <Box pl={2}/>
                <VisitDetailJobChip
                    data={data}
                    onNavigateToJob={onNavigateToJob}
                />

                {theVisitStatus === VisitStatus.Done || theVisitStatus === VisitStatus.Closed ?
                    <Box className={classes.mobileOnly}>
                        <Tooltip title={tt('visitDetail.screen.closeJob.tooltip')}>
                            <Box>
                                <FormBuilder
                                    className={classes.closeJobSwitchForm}
                                    inputs={closeJobSwitchInput}
                                    setInputs={setCloseJobSwitchInput}/>
                            </Box>
                        </Tooltip>
                    </Box>
                    : null}

                <Box pr={2}/>
            </Box>

            <VisitDetailStatusSection
                visitId={visitId}
                repeatingDay={repeatingDay}
                data={data}
                onUpdateVisitId={onUpdateVisitId}
                closeJobSwitchInput={closeJobSwitchInput}
                setCloseJobSwitchInput={setCloseJobSwitchInput}
                showWasCanceledByEmployee={showWasCanceledByEmployee}
                showWasCanceledByJobOffer={showWasCanceledByJobOffer}
                historyLogCancelledByManager={historyLogCancelledByManager}
                setWorkersTab={() => setSelectedTab('workers')}
            />

            {data ? <Box>
                <Divider/>
                <Box pl={2} pr={2} pt={1}>
                    {VisitNameOrSequenceId(data.visit, repeatingDayData) ?
                        <Typography className={classes.title}>
                            {data ? VisitNameOrSequenceId(data.visit, repeatingDayData) : ''}
                        </Typography>
                        : <Typography className={classes.noTitleText}>{tt('visitItem.withoutTitle')}</Typography>}

                    {repeatingDayData?.description || data?.visit.description ? <Box pb={1}/> : null}

                    <DescriptionWidget
                        content={repeatingDayData?.description || data?.visit.description}
                    />
                </Box>
            </Box> : null}

            <Divider/>

            <VisitInfoSection
                data={data}
                repeatingDay={repeatingDay}
            />

            {repeatJobInfoJSX}

            <Divider/>

            {tabsData.length > 0 ? (
                <>
                    <AppTabsComponent
                        controlledValue={selectedTab}
                        onTabChange={setSelectedTab}
                        data={tabsData}
                        iconsOnly={true}
                    />

                    <Divider/>
                </>
            ) : null}

            {authoredJSX}

            {menuJSX}

            <EditRecurringVisitModal
                open={recurringEditModal}
                setOpen={setRecurringEditModal}
                onConfirm={onRecurringConfirm}/>

            <CreateWorkerModal
                setOpen={setCreateWorkerModal}
                open={createWorkerModal}
                navigation={false}
                onCreate={(id: number) => {
                    const newAssignedEmployeeIds = assignedEmployeeIds ? [...assignedEmployeeIds] : [];
                    newAssignedEmployeeIds.push(id);

                    updateAssignedEmployees(repeats, uniqueArray(newAssignedEmployeeIds));
                }}
            />

            <ChooseWorkersModalBottomSheet
                open={bottomSheetWorkers}
                setOpen={setBottomSheetWorkers}
                existingEmployeeIds={assignedEmployeeIds || []}
                onSave={(ids: number[]) => updateAssignedEmployees(repeats, ids)}
                canSaveToClear={true}
                loading={assignEmployeesLoading}
                modalAboveModals={true}
                visitId={visitData?.id}
                repeatingDay={repeatingDay}
                showInactive={true}
            />

            <EditVisitConfigurationsModalBottomSheet
                open={bottomSheetWorkersConfiguration}
                setOpen={setBottomSheetWorkersConfiguration}
                visitId={visitId}
                repeats={repeats}
                repeatingDay={repeatingDay}
                onUpdateVisitId={onUpdateVisitId}
            />

            {timesheetModalJSX}

            {jobOfferModalBottomSheetJSX}
        </>
    );
}

export interface IJobDetailEmployeeTimesheetModalProps {
    employee: EmployeeJoinedUserResponse;
    employeeJobData?: JobEmployeeDataResponse;
}

export type UpdateJobFormElementDoneCallback = (jobFormUUID: string, elements: Array<UpdateJobFormElementDoneStateElementInput>, onSuccessCallback: VoidFunction) => Promise<void>;
