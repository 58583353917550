import {makeStyles} from "tss-react/mui";
import {Box, Divider, Theme, Typography, useTheme} from "@mui/material";
import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {
    CreateJobOfferSeatInput,
    EmployeeJoinedUserResponsePage,
    EmployeeRole,
    GetEmployeesJoinedUsersIncludingInactiveInput,
    JobOfferSeatResponse,
    PaymentType} from "../../../../generated/graphql/graphql";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import {AppDataContext} from "../../../../AppData";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../form/FormBuilder";
import {tt} from "../../../../core/Localization";
import {CalculateJobOfferPrice, PriceDisplay} from "../../../../service/CompanyService";
import ModalBottomSheet from "../../ModalBottomSheet";
import BottomSheetModalAppbar from "../BottomSheetModalAppbar";
import AppButton from "../../buttons/AppButton";
import AppTabsComponent from "../../AppTabsComponent";
import AppAvatar from "../../AppAvatar";
import {UserFullName, UserPhotoUrl} from "../../../../service/UserService";
import HeadlineWithButton from "../../../screenSections/detailListPreviewSection/HeadlineWithButton";
import ChooseWorkersModalBottomSheet from "./editJob/ChooseWorkersModalBottomSheet";
import GreyLabel from "../../decorations/GreyLabel";
import {Option} from "../../form/InputProps";
import {processQueryError} from "../../../../service/ErrorService";
import {RestApiClientContext} from "../../../../core/RestApiProvider";

export const useStyles = makeStyles()((theme: Theme) => ({
    buttonContainer: {
        paddingRight: 16,
        paddingLeft: 16,
        paddingBottom: 16,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
    },
    hourlyRateInput: {
        marginLeft: "auto",
        'input': {
            textAlign: "end",
        }
    },
    container: {
        maxHeight: '80vh',
        overflow: "auto",
    },
    totalAmount: {
        textAlign: "end",
        fontSize: 18,
        paddingTop: 20,
        marginRight: 16,
        fontWeight: 700,
    },
    dottedBorder: {
        height: 1,
        background: `linear-gradient(to right, transparent 33%, ${kAppColors.border(theme.palette.mode === "dark")} 0%) bottom/12px 1px repeat-x`,
    },
    avatarsContainer: {
        display: 'flex',
        paddingTop: 8,
        paddingRight: 16,
        paddingLeft: 16,
        paddingBottom: 8,
        marginBottom: 8,
        alignItems: "center",
        overflowX: "auto",
    },
    marginRight: {
        marginRight: 4,
    },
    opacity: {
        opacity: 0.5,
    },
    workersDescription: {
        marginTop: -16,
    },
    descriptionText: {
        color: kAppColors.text.secondaryLighterForDarkMode(theme.palette.mode === "dark"),
    },
    howDoesItWork: {
        whiteSpace: "break-spaces",
        fontSize: 12,
    }
}));

export interface IJobOfferData {
    type: PaymentType;
    fixedPrice: number;
    hours: number;
    minutes: number;
    hourRate: number;
    employeeIds: Array<number>;
    substituteCount: number;
    sendNotifications: boolean;
}

export interface IJobOfferBottomSheetProps {
    title?: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    data?: JobOfferSeatResponse;
    newJobExistingData?: CreateJobOfferSeatInput;
    onSave: (data: IJobOfferData) => Promise<void> | void;
    existingEmployeeIds: number[];
    excludeEmployeeIds?: number[];
    modalAboveModals?: boolean;
    notificationsCheckbox?: boolean;
}

export default function JobOfferModalBottomSheet(props: IJobOfferBottomSheetProps) {
    const {
        title,
        open,
        setOpen,
        data,
        newJobExistingData,
        onSave,
        existingEmployeeIds,
        excludeEmployeeIds,
        modalAboveModals,
        notificationsCheckbox,
    } = props;

    const {classes, cx} = useStyles();
    const theme = useTheme();

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {storage, setStorage, currency, language, companyId} = appDataContext;

    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.Hourly);
    const [bottomSheetWorkers, setBottomSheetWorkers] = useState<boolean>(false);
    const [assignedEmployeeIds, setAssignedEmployeeIds] = useState<Array<number>>([]);

    const acceptedEmployeeIds = data?.acceptedIds || [];
    const rejectedEmployeeIds = data?.rejectedIds || [];

    const [employeesLoading, setEmployeesLoading] = useState<boolean>(false);
    const [employeesData, setEmployeesData] = useState<EmployeeJoinedUserResponsePage | undefined>(undefined);
    useEffect(() => {
        if (open && !bottomSheetWorkers) {
            restApiGet({
                uri: '/company/employee/search-joined-users-including-inactive',
                params: {
                    companyId: companyId!,
                } as GetEmployeesJoinedUsersIncludingInactiveInput,
                setLoading: setEmployeesLoading,
                onData: setEmployeesData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        }
    }, [open, companyId, bottomSheetWorkers]);

    useEffect(() => {
        if (employeesData) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(employeesData.files || []),
                    ],
                };
            });
        }
    }, [employeesData]);

    useEffect(() => {
        if (open) {
            setAssignedEmployeeIds([...existingEmployeeIds]);
        }
    }, [open]);

    const [inputs, setInputs] = useState<IInputsData>({
        type: {
            testId: 'workerTimesheetModalTypeInput',
            value: PaymentType.Hourly,
            label: '',
            type: InputType.ChipSwitch,
            options: [
                {label: tt('workerTimesheetForm.type.hourRate'), value: PaymentType.Hourly},
                {label: tt('workerTimesheetForm.type.fixed'), value: PaymentType.Fixed},
                {label: tt('workerTimesheetForm.type.doNotTrack'), value: PaymentType.DoNotTrack},
            ]
        },
        fixedPrice: {
            testId: 'workerTimesheetModalFixedPriceInput',
            className: classes.hourlyRateInput,
            type: InputType.Text,
            numbersOnly: true,
            label: tt('workerTimesheetForm.input.label.fixedPrice'),
            value: '',
            required: false,
            inputMode: "decimal",
            innerSuffixJSX: <Typography
                sx={{
                    pl: 0.8,
                    mt: 2.5,
                    fontSize: 14,
                    color: kAppColors.text.primary(theme.palette.mode === 'dark'),
                }}>&nbsp;{currency}</Typography>,
            grid: {
                sm: 4,
            },
            hidden: paymentType !== PaymentType.Fixed,
        },
        hours: {
            testId: 'workerTimesheetModalHoursInput',
            type: InputType.Select,
            label: `${tt('workerTimesheetForm.hoursSelect.label')}`,
            options: [
                {label: `0 ${tt('common.hoursAbbreviation')}`, value: '0'},
                {label: `1 ${tt('common.hoursAbbreviation')}`, value: '1'},
                {label: `2 ${tt('common.hoursAbbreviation')}`, value: '2'},
                {label: `3 ${tt('common.hoursAbbreviation')}`, value: '3'},
                {label: `4 ${tt('common.hoursAbbreviation')}`, value: '4'},
                {label: `5 ${tt('common.hoursAbbreviation')}`, value: '5'},
                {label: `6 ${tt('common.hoursAbbreviation')}`, value: '6'},
                {label: `7 ${tt('common.hoursAbbreviation')}`, value: '7'},
                {label: `8 ${tt('common.hoursAbbreviation')}`, value: '8'},
                {label: `9 ${tt('common.hoursAbbreviation')}`, value: '9'},
                {label: `10 ${tt('common.hoursAbbreviation')}`, value: '10'},
                {label: `11 ${tt('common.hoursAbbreviation')}`, value: '11'},
                {label: `12 ${tt('common.hoursAbbreviation')}`, value: '12'},
                {label: `13 ${tt('common.hoursAbbreviation')}`, value: '13'},
                {label: `14 ${tt('common.hoursAbbreviation')}`, value: '14'},
            ],
            value: '0',
            grid: {
                sm: 4,
                xs: 6,
            },
            hidden: paymentType !== PaymentType.Hourly,
        },
        minutes: {
            testId: 'workerTimesheetModalMinutesInput',
            type: InputType.Select,
            label: `${tt('workerTimesheetForm.minutesSelect.label')}`,
            options: [
                {label: `0 ${tt('common.minutesAbbreviation')}`, value: '0'},
                {label: `5 ${tt('common.minutesAbbreviation')}`, value: '5'},
                {label: `10 ${tt('common.minutesAbbreviation')}`, value: '10'},
                {label: `15 ${tt('common.minutesAbbreviation')}`, value: '15'},
                {label: `20 ${tt('common.minutesAbbreviation')}`, value: '20'},
                {label: `25 ${tt('common.minutesAbbreviation')}`, value: '25'},
                {label: `30 ${tt('common.minutesAbbreviation')}`, value: '30'},
                {label: `35 ${tt('common.minutesAbbreviation')}`, value: '35'},
                {label: `40 ${tt('common.minutesAbbreviation')}`, value: '40'},
                {label: `45 ${tt('common.minutesAbbreviation')}`, value: '45'},
                {label: `50 ${tt('common.minutesAbbreviation')}`, value: '50'},
                {label: `55 ${tt('common.minutesAbbreviation')}`, value: '55'},
            ],
            value: '0',
            grid: {
                sm: 4,
                xs: 6,
            },
            hidden: paymentType !== PaymentType.Hourly,
        },
        hourRate: {
            testId: 'workerTimesheetModalHourRateInput',
            className: classes.hourlyRateInput,
            type: InputType.Text,
            numbersOnly: true,
            label: tt('common.hourRate'),
            value: '',
            required: false,
            helperText: tt('workerTimesheetModal.hourRate.helperText'),
            inputMode: "decimal",
            innerSuffixJSX: <Typography
                sx={{
                    pl: 0.8,
                    mt: 2.5,
                    fontSize: 14,
                    color: kAppColors.text.primary(theme.palette.mode === 'dark'),
                }}>&nbsp;{currency}</Typography>,
            grid: {
                sm: 4,
                xs: 12,
            },
            hidden: paymentType !== PaymentType.Hourly,
        },
    });

    const [backupInputs, setBackupInputs] = useState<IInputsData>({
        substituteCount: {
            testId: 'jobOfferModalBackupNumberInput',
            value: 0,
            label: tt('jobOfferModal.label.numberOfBackups') + '*',
            type: InputType.Select,
            options: [
                {label: '0', value: 0},
                {label: '1', value: 1},
                {label: '2', value: 2},
                {label: '3', value: 3},
            ],
            grid: {
                sm: 4,
                xs: 12,
            },
            required: true,
        },
    });

    const [footerInputs, setFooterInputs] = useState<IInputsData>({
        notifications: {
            testId: 'jobOfferModalSendNotificationsInput',
            type: InputType.CheckBox,
            label: tt('jobOfferModal.checkbox.sendNotificationsToWorkers'),
            value: notificationsCheckbox || false,
            hidden: !notificationsCheckbox,
        },
    });

    const [inputsTab2, setInputsTab2] = useState<IInputsData>({
        category: {
            testId: 'offersTab2CategorySwitch',
            value: 1,
            label: '',
            type: InputType.ChipSwitch,
            options: [
                {label: tt('jobOffersModal.switch.label.workCancellation'), value: 1},
                {label: tt('jobOffersModal.switch.label.workOffer'), value: 2},
            ]
        },
    });

    useEffect(() => {
        setBackupInputs((prev: IInputsData) => {
            const newOptions: Option[] = [];

            for (let i = 0; i < assignedEmployeeIds.length; i++) {
                newOptions.push({
                    label: i.toString(),
                    value: i,
                });
            }

            if (newOptions.length === 0) {
                newOptions.push({
                    label: '0',
                    value: 0,
                });
            }

            return {
                ...prev,
                substituteCount: {
                    ...prev.substituteCount,
                    value: prev.substituteCount.value >= newOptions.length ? newOptions.length - 1 : prev.substituteCount.value,
                    options: newOptions,
                },
            };
        });
    }, [assignedEmployeeIds]);

    useEffect(() => {
        setTotalAmount(CalculateJobOfferPrice({
            jobOffer: {
                paymentType: paymentType,
                fixedPrice: parseFloat(inputs.fixedPrice.value || 0),
                hourRate: parseFloat(inputs.hourRate.value || 0),
                hours: parseInt(inputs.hours.value || 0),
                minutes: parseInt(inputs.minutes.value || 0),
            }
        }));
    }, [inputs]);

    useEffect(() => {
        if (inputs.type.value !== paymentType) {
            setPaymentType(inputs.type.value);
        }
    }, [inputs.type.value]);

    useEffect(() => {
        setInputs((prev: IInputsData) => {
            return {
                ...prev,
                hours: {
                    ...prev.hours,
                    hidden: paymentType !== PaymentType.Hourly,
                },
                minutes: {
                    ...prev.minutes,
                    hidden: paymentType !== PaymentType.Hourly,
                },
                hourRate: {
                    ...prev.hourRate,
                    hidden: paymentType !== PaymentType.Hourly,
                },
                fixedPrice: {
                    ...prev.fixedPrice,
                    hidden: paymentType !== PaymentType.Fixed,
                },
            };
        });
    }, [paymentType]);

    useEffect(() => {
        if (open && data) {
            setInputs((prev: IInputsData) => {
                return {
                    ...prev,
                    type: {
                        ...prev.type,
                        value: data.paymentType || PaymentType.Hourly,
                    },
                    hours: {
                        ...prev.hours,
                        value: data.hours || '0',
                    },
                    minutes: {
                        ...prev.minutes,
                        value: data.minutes || '0',
                    },
                    hourRate: {
                        ...prev.hourRate,
                        value: data.hourRate || '',
                    },
                    fixedPrice: {
                        ...prev.fixedPrice,
                        value: data.fixedPrice || '',
                    },
                };
            });

            setBackupInputs((prev: IInputsData) => {
                return {
                    ...prev,
                    substituteCount: {
                        ...prev.substituteCount,
                        value: data.substituteCount || 0,
                    },
                };
            });
        }

        if (open && newJobExistingData) {
            setInputs((prev: IInputsData) => {
                return {
                    ...prev,
                    type: {
                        ...prev.type,
                        value: newJobExistingData.paymentType || PaymentType.Hourly,
                    },
                    hours: {
                        ...prev.hours,
                        value: newJobExistingData.hours || '0',
                    },
                    minutes: {
                        ...prev.minutes,
                        value: newJobExistingData.minutes || '0',
                    },
                    hourRate: {
                        ...prev.hourRate,
                        value: newJobExistingData.hourRate || '',
                    },
                    fixedPrice: {
                        ...prev.fixedPrice,
                        value: newJobExistingData.fixedPrice || '',
                    },
                };
            });

            setBackupInputs((prev: IInputsData) => {
                return {
                    ...prev,
                    substituteCount: {
                        ...prev.substituteCount,
                        value: newJobExistingData.substituteCount || 0,
                    },
                };
            });
        }

        setFooterInputs((prev: IInputsData) => {
            return {
                ...prev,
                notifications: {
                    ...prev.notifications,
                    value: notificationsCheckbox || false,
                    hidden: !notificationsCheckbox,
                },
            };
        });
    }, [open]);

    const chosenEmployeesData = employeesData?.content && assignedEmployeeIds ?
        employeesData?.content.filter((worker) => assignedEmployeeIds.includes(worker.id))
        : [];

    const employeesJSX =
        chosenEmployeesData.length > 0 ?
            <Box className={cx('styledScrollbar', classes.avatarsContainer)}>
                {
                    chosenEmployeesData.map((employee, index) => {
                        const isRejected = rejectedEmployeeIds.includes(employee.id);

                        return (
                            <Box key={employee.id}
                                 className={cx(classes.marginRight, isRejected ? classes.opacity : null)}>
                                <AppAvatar
                                    img={
                                        UserPhotoUrl(employee.user, employeesData?.files, storage.publicUrlsForFiles)
                                    }
                                    tooltip={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                                    title={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                                    tooltipRedDescription={isRejected ? tt('avatar.tooltip.rejectedOffer') : undefined}
                                    variant={"small"}
                                />
                            </Box>
                        );
                    })}
            </Box> : <>
                <Box sx={{pb: 2}}/>
                <Box pl={2} pr={2}>
                    <AppButton
                        fullWidth={true}
                        variant={"dashed"}
                        onClick={() => setBottomSheetWorkers(true)}
                    >
                        {tt('common.select')}
                    </AppButton>
                    <Box sx={{pb: 2}}/>
                </Box>
            </>;

    const firstTabJSX = (
        <>
            {chosenEmployeesData.length > 0 ?
                <HeadlineWithButton
                    title={tt('jobOfferModal.label.workers')}
                    buttonText={tt('common.select')}
                    onClick={() => {
                        setBottomSheetWorkers(true);
                    }}
                /> : <Box pl={2} pr={2} pt={2}>
                    <GreyLabel text={tt('jobOfferModal.label.workers')}/>
                </Box>
            }

            {employeesJSX}

            <Divider/>

            <Box sx={{pr: 2, pl: 2, pt: 2}}>
                <GreyLabel text={tt('jobOfferModal.label.substitutes')}/>
                <Box pb={2}/>
                <FormBuilder inputs={backupInputs} setInputs={setBackupInputs}/>
            </Box>

            <Divider/>

            <Box sx={{pr: 2, pl: 2, pt: 2}}>
                <GreyLabel text={tt('jobOfferModal.label.reward')}/>
                <Box pb={2}/>
                <FormBuilder inputs={inputs} setInputs={setInputs}/>
            </Box>

            {
                paymentType === PaymentType.Hourly || paymentType === PaymentType.Fixed ? (
                    <>
                        <Box className={classes.dottedBorder}/>

                        <Typography
                            className={classes.totalAmount}>{`${tt('common.total')}: ${PriceDisplay(totalAmount, currency, language, true)}`}</Typography>
                    </>
                ) : undefined
            }

            <Box pb={2}/>

            <Box sx={{pr: 2, pl: 2, pt: 2}}>
                <FormBuilder inputs={footerInputs} setInputs={setFooterInputs}/>
            </Box>

            <Box pb={2}/>

            <Box pb={8}/>

            <Box className={classes.buttonContainer}>
                <AppButton
                    variant={"contained"}
                    fullWidth={true}
                    disabled={totalAmount === 0 && inputs.type.value !== PaymentType.DoNotTrack}
                    onClick={() => {
                        if (ValidateForm(inputs, setInputs) && ValidateForm(backupInputs, setBackupInputs) && ValidateForm(footerInputs, setFooterInputs)) {
                            setOpen(false);

                            onSave({
                                type: inputs.type.value,
                                hours: parseInt(inputs.hours.value || 0),
                                minutes: parseInt(inputs.minutes.value || 0),
                                hourRate: parseFloat(inputs.hourRate.value || 0),
                                fixedPrice: parseFloat(inputs.fixedPrice.value || 0),
                                employeeIds: assignedEmployeeIds,
                                substituteCount: parseInt(backupInputs.substituteCount.value || 0),
                                sendNotifications: footerInputs.notifications.value || false,
                            });
                        }
                    }}
                >
                    {tt('common.save')}
                </AppButton>
            </Box>
        </>
    );

    const secondTabJsx = (
        <Box p={2}>
            <GreyLabel text={tt('jobOffer.bottomSheet.label.whenToUse')}/>
            <Box pb={2}/>
            <FormBuilder inputs={inputsTab2} setInputs={setInputsTab2}/>
            {inputsTab2.category.value === 1 ?
                <Typography
                    className={classes.howDoesItWork}>{tt('jobOffer.bottomSheet.tab.howDoesItWork.text1')}</Typography>
                :
                <Typography
                    className={classes.howDoesItWork}>{tt('jobOffer.bottomSheet.tab.howDoesItWork.text2')}</Typography>
            }
            <Box pb={3}/>
        </Box>
    );

    return (
        <>
            <ModalBottomSheet
                modalAboveModals={modalAboveModals}
                blurBackdrop={true}
                open={open}
                setOpen={setOpen}
                hideHeader={true}>
                <Box className={classes.container}>
                    <BottomSheetModalAppbar
                        onClose={() => setOpen(false)}
                        title={title || tt('jobOffer.bottomSheet.title')}
                        noBorderBottom={true}
                    />

                    <AppTabsComponent
                        variant={"fullWidth"}
                        data={[
                            {label: tt('jobOffer.bottomSheet.tab.label.setting'), content: firstTabJSX},
                            {label: tt('jobOffer.bottomSheet.tab.label.howDoesItWork'), content: secondTabJsx}
                        ]}
                    />
                </Box>
            </ModalBottomSheet>

            <ChooseWorkersModalBottomSheet
                open={bottomSheetWorkers}
                setOpen={setBottomSheetWorkers}
                existingEmployeeIds={assignedEmployeeIds || []}
                onSave={(ids: number[]) => setAssignedEmployeeIds(ids)}
                canSaveToClear={true}
                modalAboveModals={true}
                showInactive={true}
                excludeEmployeeIds={excludeEmployeeIds}
                filterByEmployeeRoles={[EmployeeRole.Worker]}
            />
        </>
    );
}
