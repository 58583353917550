import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import React, {Dispatch, SetStateAction} from "react";
import {tt} from "../../../core/Localization";
import GreyLabel from "../decorations/GreyLabel";
import {QRCodeSVG} from "qrcode.react";
import AppModal from "./AppModal";

export const useStyles = makeStyles()((theme: Theme) => ({
    qrContainer: {
        width: 200,
        margin: "auto",
    },
    removeHorizontalMargin: {
        marginRight: -16,
        marginLeft: -16,
    },
    text: {
        fontWeight: 500,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        textAlign: "center",
    },
    contentContainer: {
        width: '100%',
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 16,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
}));


export interface IInvitationQRCodeModalProps {
    open: boolean;
    urlWithParams: string;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function InvitationQRCodeModal(props: IInvitationQRCodeModalProps) {
    const {
        open,
        setOpen,
        urlWithParams,
    } = props;

    const {classes, cx} = useStyles();

    return (
        <AppModal
            blurBackdrop={true}
            open={open}
            setOpen={setOpen}
            title={tt('inviteWorkerQRCodeModal.title')}
            children={
                <>
                    <Box className={classes.contentContainer}>
                        <GreyLabel
                            text={tt('inviteWorkerModal.label.scanCode')}
                            centered={true}
                        />
                        <Box p={4}/>
                        <Box className={classes.qrContainer}>
                            <QRCodeSVG
                                width={180}
                                height={180}
                                value={urlWithParams}/>
                        </Box>
                        <Box p={3}/>
                    </Box>
                </>
            }
        />
    );
}