import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../../../App";
import {useNavigate, useParams} from "react-router-dom";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import AppPaper from "../../components/paper/AppPaper";
import AppChip from "../../components/chips/AppChip";
import {AddOutlined} from "@mui/icons-material";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {locationDetailRoute} from "../locations/LocationDetailScreen";
import {
    GetLocationProfileInput,
    LocationPlaceResponse,
    LocationProfileResponse
} from "../../../generated/graphql/graphql";
import ListShimmer from "../../components/shimmers/ListShimmer";
import EmptyListText from "../../components/textComponents/EmptyListText";
import AppListItem from "../../components/listItems/AppListItem";
import {LocationNameDisplay, LocationTypeDisplay} from "../../../service/LocationService";
import {PlaceDetailRoute} from "./PlaceDetailScreen";
import CreateLocationPlaceModal from "../../components/places/CreateLocationPlaceModal";
import IBreadcrumb from "../../../model/Breadcrumb";
import {kClientsBreadcrumb, kClientsRoute} from "../clients/ClientsScreen";
import {clientDetailRoute} from "../clients/ClientDetailScreen";
import {AppDataContext, SetAppBreadcrumbs} from "../../../AppData";
import AppBreadCrumb from "../../components/breadCrumb/AppBreadCrumb";
import Icons8Here from "../../../icons/Icons8-here";
import IEventSystemListener from "../../../model/EventSystemListener";
import {listenToEventSystem, unListenToEventSystem} from "../../../service/EventSystemService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import {processQueryError} from "../../../service/ErrorService";
import {
    kActionDelete,
    kActionUpdate,
    kPermissionsClients,
    kTopicClients,
    kTopicLocationPlaces,
    kTopicLocations
} from "../../../core/constants";
import PermissionValid from "../../components/permissions/PermissionValid";
import {RestApiClientContext} from "../../../core/RestApiProvider";

export const kPlacesRoute = '/clients/:clientId/locations/:locationId/places';

/**
 * Shorthand to create Route url.
 */
export function PlacesRoute(clientId: string | number, locationId: string | number): string {
    return kPlacesRoute
        .replace(':locationId', `${locationId}`)
        .replace(':clientId', `${clientId}`);
}

/**
 * Screen component for list of Location Places.
 */
export default function PlacesScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);

    const {clientId, locationId} = useParams();

    const [createPlace, setCreatePlace] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<LocationProfileResponse | NullOrUndefined>();
    const [refetch, setRefetch] = useState<number>(0);
    useEffect(() => {
        if (locationId) {
            const subscription = subscribe(
                kTopicLocations,
                {
                    uri: '/location/profile',
                    params: {
                        locationId: parseInt(locationId),
                    } as GetLocationProfileInput,
                    setLoading,
                    onData: setData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                (notifications: IEventSystemNotification[]) => {
                    return notifications.some(notification => {
                        if (notification.action !== kActionUpdate) {
                            return false;
                        }

                        return notification.data.id === parseInt(locationId!);
                    });
                },
            );

            return () => subscription.cancel();
        } else {
            setLoading(false);
            setData(null);
        }
    }, [locationId, refetch]);

    /**
     * Set breadcrumbs.
     */
    const SetBreadcrumbs = () => {
        const breadcrumbs: IBreadcrumb[] = [kClientsBreadcrumb];

        if (data) {
            breadcrumbs.push({
                key: 'client-detail',
                text: data!.client.name,
                route: clientDetailRoute(clientId!),
            });

            breadcrumbs.push({
                key: 'location-detail',
                text: LocationNameDisplay(data!.location.name, data!.location.type, data!.location.address),
                route: locationDetailRoute(clientId!, locationId!),
            });

            breadcrumbs.push({
                key: 'location-places',
                text: tt('places.screen.title'),
                route: PlacesRoute(clientId!, locationId!),
            });
        }

        SetAppBreadcrumbs(appDataContext, breadcrumbs);
    };

    useEffect(() => {
        setTitle(tt('places.screen.title'));

        SetBreadcrumbs();
    }, []);

    useEffect(() => {
        SetBreadcrumbs();
    }, [data]);

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                isMobile={isMobile}
                loading={loading}
                data={data}
                setCreatePlace={setCreatePlace}
            />
        );
    }

    return (
        <>
            <EventSystemListeners refetch={() => setRefetch(refetch + 1)}/>

            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}/>

            <CreateLocationPlaceModal open={createPlace} setOpen={setCreatePlace} clientId={parseInt(clientId!)}
                                      locationId={parseInt(locationId!)}/>
        </>
    );
}

interface IEventSystemListenersProps {
    refetch: VoidFunction;
}

/**
 * Component for EventSystem listeners.
 */
function EventSystemListeners(props: IEventSystemListenersProps) {
    const {refetch} = props;

    const {clientId, locationId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicClients,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(clientId!);
                });

                if (doDelete) {
                    navigate(kClientsRoute);
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [clientId]);

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicLocations,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(locationId!);
                });

                if (doDelete) {
                    if (clientId) {
                        navigate(clientDetailRoute(clientId!));
                    } else {
                        navigate(kClientsRoute);
                    }
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [locationId, clientId]);

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicLocationPlaces,
            callback: (notifications: IEventSystemNotification[]) => {
                const update = notifications.some(notification => {
                    return notification.data.locationId === parseInt(locationId!);
                });

                if (update) {
                    refetch();
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [refetch, locationId]);

    return null;
}

interface IBodyProps {
    isMobile?: boolean;
    loading: boolean;
    data?: LocationProfileResponse | NullOrUndefined;
    setCreatePlace: Dispatch<SetStateAction<boolean>>;
}

/**
 * Screen body contents.
 */
function Body(props: IBodyProps) {
    const {isMobile, loading, data, setCreatePlace} = props;

    const {clientId, locationId} = useParams();

    const content = useMemo(() => {
        return loading && !data ?
            <ListShimmer items={2}/> : data && data!.location.places.length > 0 ?
                data!.location.places.map(locationPlace => (
                    <LocationPlaceListItem key={locationPlace.id} clientId={parseInt(clientId!)}
                                           locationId={parseInt(locationId!)} data={locationPlace}/>
                ))
                : <EmptyListText text={tt('places.screen.emptyListMessage')}/>;
    }, [data]);

    return (
        <ScreenContent
            showBreadCrumb={true}
            appBar={!isMobile} noContentPadding={isMobile} navigationDrawer={!isMobile} bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    isMobile={isMobile}
                    title={tt('common.locationPlaces')}
                    backRoute={locationDetailRoute(clientId!, locationId!)}>

                    {isMobile ? <></> : (
                        <PermissionValid
                            permission={kPermissionsClients}
                            requiredPermissions={[kActionUpdate]}
                        >
                            <AppChip
                                onClick={() => setCreatePlace(true)}
                                key={'addPlaceChip'} label={tt('common.newPlace')}
                                icon={<AddOutlined/>}/>
                        </PermissionValid>
                    )}

                </PaperAppbar>

                {isMobile ? <AppBreadCrumb/> : null}

                {content}
            </AppPaper>
        </ScreenContent>
    );
}

interface ILocationPlaceListItemProps {
    clientId: number;
    locationId: number;
    data: LocationPlaceResponse;
}

/**
 * Component for LocationPlace list item.
 */
function LocationPlaceListItem(props: ILocationPlaceListItemProps) {
    const {clientId, locationId, data} = props;

    const navigate = useNavigate();

    return (
        <AppListItem
            customAvatarInCircle={<Icons8Here/>}
            title={data.name}
            description={LocationTypeDisplay(data.type)}
            variant={"smaller-title"}
            onClick={() => navigate(`${PlaceDetailRoute(clientId, locationId, data.id)}?list=1`)}
        />
    );
}
