import {Box, Theme, Typography} from "@mui/material";
import React, {useContext, useMemo} from "react";
import {AppDataContext} from "../../../../../AppData";
import {tt} from "../../../../../core/Localization";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../../../styles/AppThemeProcessor";
import {VisitDetailResponse} from "../../../../../generated/graphql/graphql";
import {
    CalculateProductsPriceWithoutVat,
    CalculateProductsPriceWithVat,
    calculateTimesheets,
    distanceDisplay,
    HoursMinutesDisplayRaw,
    PriceDisplay
} from "../../../../../service/CompanyService";
import {visitDateTimes} from "../../../../../service/VisitService";
import PermissionValid from "../../../permissions/PermissionValid";
import {kPermissionsProducts, kPermissionsTimesheets} from "../../../../../core/constants";

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: "flex",
        overflowX: "auto",
    },
    columnContainer: {
        flexShrink: 0,
        justifyContent: "center",
        textAlign: "center",
        paddingRight: 16,
        paddingLeft: 16,
        paddingTop: 8,
        paddingBottom: 8,
    },
    columnItemTitle: {
        fontSize: 12,
        fontWeight: 700,
        textTransform: "uppercase",
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        marginBottom: 8,
        textAlign: "center",
    },
    text1: {
        display: "block",
        textAlign: "center",
        paddingBottom: 8,
        fontSize: 20,
        fontWeight: 500,
        lineHeight: 1,
    },
    text2: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontSize: 12,
        fontWeight: 700,
        textAlign: "center",
    },
}));

export interface IJobInfoSectionProps {
    data?: VisitDetailResponse | NullOrUndefined;
    repeatingDay?: number;
}

export default function VisitInfoSection(props: IJobInfoSectionProps) {
    const {data, repeatingDay} = props;

    const visitData = data?.visit;
    const repeatingDayData = data?.visit.repeatingDayData;
    const theProducts = data?.products;

    const {classes, cx} = useStyles();

    const appDataContext = useContext(AppDataContext);
    const {currency, language, company} = appDataContext;

    const dateTimes = useMemo(() => {
        return visitDateTimes(visitData, repeatingDayData, repeatingDay);
    }, [visitData, repeatingDayData, repeatingDay]);

    const stats = useMemo(() => {
        return data ? calculateTimesheets({
            timesheets: data.employeeTimesheet,
            repeatingDay,
            filterByEmployeeIds: repeatingDayData ? repeatingDayData.employeeIds : data.visit.employeeIds,
            filterByJobEmployeeData: data.employeeJobData,
        }) : null;
    }, [data, repeatingDay]);

    const secondLineStats = useMemo(() => {
        return stats ?
            `${HoursMinutesDisplayRaw({
                hours: stats.hours,
                minutes: stats.minutes,
                language,
                returnZeroHoursInsteadOfUndefined: true,
            })} · ${distanceDisplay(stats.travel.distance, language, true)}` :
            undefined;
    }, [stats, language]);

    let wholeDayJSX = (
        <ColumnItem
            title={tt('visitItem.label.wholeDay')}
            text1={dateTimes.start.toFormat('EEEE d.M.yyyy', {locale: language})}
        />
    );

    if (!dateTimes.isSingleDay) {
        wholeDayJSX = (
            <>
                <ColumnItem
                    title={tt('common.start')}
                    text1={dateTimes.start.toFormat('EEEE d.M.yyyy', {locale: language})}
                    text2={tt('visitItem.label.wholeDay')}
                />

                {dateTimes.end ? (
                    <ColumnItem
                        title={tt('common.end')}
                        text1={dateTimes.end.toFormat('EEEE d.M.yyyy', {locale: language})}
                        text2={tt('visitItem.label.wholeDay')}
                    />
                ) : null}
            </>
        );
    }

    const startJSX = (
        <ColumnItem
            title={tt('common.start')}
            text1={dateTimes.start.toFormat('H:mm', {locale: language})}
            text2={dateTimes.start.toFormat('EEEE d.M.yyyy', {locale: language})}
        />
    );

    const endJSX = dateTimes.end ? (
        <ColumnItem
            title={tt('common.end')}
            text1={dateTimes.end.toFormat('H:mm', {locale: language})}
            text2={dateTimes.end.toFormat('EEEE d.M.yyyy', {locale: language})}
        />
    ) : undefined;

    const companyHasVat = company?.hasVat || false;

    return (
        <Box className={cx('styledScrollbar', classes.container)}>
            {dateTimes.wholeDay ? wholeDayJSX :
                <>
                    {startJSX}
                    {endJSX}
                </>
            }

            {!!theProducts && theProducts.length ? (
                <PermissionValid
                    permission={kPermissionsProducts}
                >
                    <ColumnItem
                        title={tt('common.productsCost')}
                        text1={
                            companyHasVat ? CalculateProductsPriceWithVat({
                                products: theProducts,
                                currency,
                                language,
                                returnZeroInsteadOfUndefined: true,
                            }) || '' : CalculateProductsPriceWithoutVat({
                                products: theProducts,
                                currency,
                                language,
                                returnZeroInsteadOfUndefined: true
                            }) || ''
                        }
                        text2={companyHasVat ? tt('common.price.withoutVat').replace('{price}', CalculateProductsPriceWithoutVat({
                            products: theProducts,
                            currency,
                            language
                        })) : undefined}
                    />
                </PermissionValid>
            ) : undefined}
            {PriceDisplay(repeatingDayData?.employeeTotalPrice || visitData?.employeeTotalPrice || 0, currency, language) ? (
                <PermissionValid
                    permission={kPermissionsTimesheets}
                >
                    <ColumnItem
                        title={tt('common.workersCost')}
                        text1={PriceDisplay(repeatingDayData?.employeeTotalPrice || visitData?.employeeTotalPrice || 0, currency, language, true)!}
                        text2={secondLineStats}
                    />
                </PermissionValid>
            ) : null}

        </Box>
    );
}

export interface IColumnItemProps {
    title: string;
    text1: string;
    text2?: string;
}

export function ColumnItem(props: IColumnItemProps) {
    const {title, text1, text2} = props;
    const {classes, cx} = useStyles();

    return (
        <Box className={classes.columnContainer}>
            <Typography className={classes.columnItemTitle}>{title}</Typography>
            <Typography className={classes.text1}>{text1}</Typography>
            {text2 ? <Typography className={classes.text2}>{text2}</Typography> : null}
        </Box>
    );
}
