import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8LayersWithPlusProps {
    className?: string;
}

export default function Icons8LayersWithPlus(props: IIcons8LayersWithPlusProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" width="24px" height="24px">
            <defs>
                <clipPath id="clip-1">
                    <path
                        d="M0,256v-256h256v256h-40.10967c19.20941,-10.77768 32.13997,-31.32826 32.13997,-55c0,-34.90909 -28.12121,-63.0303 -63.0303,-63.0303c-34.90909,0 -63.0303,28.12121 -63.0303,63.0303c0,23.67174 12.93056,44.22232 32.13997,55z"
                        id="overlayBgMask" fill="none"></path>
                </clipPath>
            </defs>
            <g clipPath="url(#clip-1)"
               fill="none"
               fillRule="nonzero"
               stroke="none"
               strokeWidth="1"
               strokeLinecap="butt"
               strokeLinejoin="miter"
               strokeMiterlimit="10"
               strokeDasharray=""
            >
                <g transform="scale(5.33333,5.33333)" fill="currentColor">
                    <path
                        d="M14.39453,6c-2.43,0 -4.54086,1.55981 -5.25586,3.88281l-1.9375,6.29297c-0.424,1.379 -0.17536,2.83705 0.68164,3.99805c0.857,1.161 2.17614,1.82617 3.61914,1.82617h22.10352c2.43,0 4.54181,-1.55981 5.25781,-3.88281l1.9375,-6.29297c0.424,-1.379 0.17536,-2.83705 -0.68164,-3.99805c-0.857,-1.161 -2.17809,-1.82617 -3.62109,-1.82617zM40.69922,18.89453c-1.033,3.058 -3.84975,5.10547 -7.09375,5.10547h-22.10352c-1.208,0 -2.3527,-0.33264 -3.3457,-0.93164l-0.95508,3.10742c-0.424,1.379 -0.17536,2.83705 0.68164,3.99805c0.857,1.161 2.17614,1.82617 3.61914,1.82617h22.10352c2.43,0 4.54281,-1.55981 5.25781,-3.88281l1.9375,-6.29297c0.302,-0.983 0.25544,-2.00369 -0.10156,-2.92969zM40.69922,28.89453c-1.032,3.058 -3.84975,5.10547 -7.09375,5.10547h-22.10352c-1.209,0 -2.3527,-0.33169 -3.3457,-0.92969l-0.95508,3.10547c-0.424,1.379 -0.17536,2.83705 0.68164,3.99805c0.857,1.161 2.17614,1.82617 3.61914,1.82617h22.10352c2.43,0 4.54281,-1.55981 5.25781,-3.88281l1.9375,-6.29297c0.301,-0.983 0.25544,-2.00369 -0.10156,-2.92969z"></path>
                </g>
            </g>
            <g fill="currentColor"
               fillRule="nonzero"
               stroke="none"
               strokeWidth="1"
               strokeLinecap="butt"
               strokeLinejoin="miter"
               strokeMiterlimit="10"
               strokeDasharray=""
               strokeDashoffset="0"
            >
                <g transform="translate(131.66667,147.66667) scale(4.84848,4.84848)" id="overlay">
                    <path
                        d="M11,0c-6.075,0 -11,4.925 -11,11c0,6.075 4.925,11 11,11c6.075,0 11,-4.925 11,-11c0,-6.075 -4.925,-11 -11,-11zM18,12h-6v6c0,0.552 -0.448,1 -1,1c-0.552,0 -1,-0.448 -1,-1v-6h-6c-0.552,0 -1,-0.448 -1,-1c0,-0.552 0.448,-1 1,-1h6v-6c0,-0.552 0.448,-1 1,-1c0.552,0 1,0.448 1,1v6h6c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1z"></path>
                </g>
            </g>
        </svg>
        </span>
    );
}