import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import ModalBottomSheet from "../../../ModalBottomSheet";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import {tt} from "../../../../../core/Localization";
import AppButton from "../../../buttons/AppButton";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../../form/FormBuilder";
import ContentPadding from "../../../paper/ContentPadding";
import {
    GetVisitInput,
    JobUpdateRepeats,
    UpdateVisitDetailsDocument,
    UpdateVisitDetailsMutation,
    UpdateVisitDetailsMutationVariables,
    VisitResponse
} from "../../../../../generated/graphql/graphql";
import {useMutation} from "@apollo/client";
import {SuccessToast} from "../../../../../service/ToastService";
import {AppDataContext} from "../../../../../AppData";
import {kLargePhoneBreakpoint} from "../../../../../styles/AppThemeProcessor";
import {processMutationError, processQueryError} from "../../../../../service/ErrorService";
import {kOneLineTextLimit} from "../../../../../core/constants";
import {IOnUpdateVisitIdParams} from "./VisitDetailModal";
import {RestApiClientContext} from "../../../../../core/RestApiProvider";


export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        maxHeight: '95vh',
        height: '100vh',
        overflow: "auto",
    },
}));


export interface IEditVisitDetailNameAndDescriptionModalBottomSheetProps {
    visitId: number;
    repeatingDay?: number;
    repeats: JobUpdateRepeats;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onUpdateVisitId: (params: IOnUpdateVisitIdParams) => void;
    modalAboveModals?: boolean;
}

export default function EditVisitDetailNameAndDescriptionModalBottomSheet(props: IEditVisitDetailNameAndDescriptionModalBottomSheetProps) {
    const {visitId, repeatingDay, repeats, open, setOpen, onUpdateVisitId, modalAboveModals} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);

    const [showExtraSpace, setShowExtraSpace] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<VisitResponse | undefined>(undefined);
    useEffect(() => {
        if (open) {
            restApiGet({
                uri: '/job/visit',
                params: {
                    visitId,
                    repeatingDay,
                } as GetVisitInput,
                setLoading: setLoading,
                onData: setData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        }
    }, [open, visitId, repeatingDay]);

    const [mutateUpdate, {
        loading: updateLoading,
    }] = useMutation<UpdateVisitDetailsMutation, UpdateVisitDetailsMutationVariables>(UpdateVisitDetailsDocument);

    /**
     * Mutate Visit details update to BE.
     */
    const handleUpdate = async () => {
        if (ValidateForm(inputs, setInputs)) {
            try {
                const updateVariables: UpdateVisitDetailsMutationVariables = {
                    input: {
                        visitId: visitId,
                        repeatingDay,
                        name: inputs.name.value || undefined,
                        description: inputs.description.value || undefined,
                        repeats,
                        sendNotifications: inputs.notifications.value,
                    },
                };

                const result = await mutateUpdate({variables: updateVariables});

                if (!result.errors) {
                    SuccessToast(tt('visitDetails.modal.success'));

                    setTimeout(() => {
                        setOpen(false);

                        if (result.data!.updateVisitDetails.id) {
                            onUpdateVisitId({
                                visitId: result.data!.updateVisitDetails.id,
                                repeatingDay: repeatingDay!,
                            });
                        }
                    }, 1);
                }
            } catch (e) {
                processMutationError(e);
            }
        }
    };

    const [inputs, setInputs] = useState<IInputsData>({
        name: {
            testId: 'createJobFormAddNameInput',
            type: InputType.ButtonTextField,
            label: tt('common.name'),
            toggleButtonText: tt('common.addName'),
            value: '',
            maxChars: kOneLineTextLimit,
            showCharCounter: true,
        },
        description: {
            type: InputType.Editor,
            toggleButtonText: tt('common.addDescription'),
            value: '',
            label: '',
            placeholder: tt('visitJob.screen.descriptionInput.placeholder'),
            onEditorFocus: () => {
                if (window.innerWidth < kLargePhoneBreakpoint) {
                    setShowExtraSpace(true);
                }
            },
            onEditorBlur: () => {
                if (window.innerWidth < kLargePhoneBreakpoint) {
                    setShowExtraSpace(false);
                }
            },
        },
        notifications: {
            testId: 'editJobNameAndDescriptionFormSendNotificationsInput',
            type: InputType.CheckBox,
            label: tt('common.visit.checkbox.sendNotificationsToWorkers'),
            value: true,
        },
    });

    useEffect(() => {
        if (data && open) {
            setInputs(prev => {
                return {
                    ...prev,
                    name: {
                        ...prev.name,
                        value: data.repeatingDayData?.name || data.name || '',
                    },
                    description: {
                        ...prev.description,
                        value: data.repeatingDayData?.description || data.description || '',
                    },
                    notifications: {
                        ...prev.notifications,
                        value: true,
                    },
                };
            });
        }
    }, [data, open]);

    return (
        <ModalBottomSheet
            modalAboveModals={modalAboveModals}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
        >
            <BottomSheetModalAppbar
                noBorderBottom={true}
                onClose={() => setOpen(false)}
                title={tt('common.nameAndDescription')}>
            </BottomSheetModalAppbar>

            <ContentPadding>
                <FormBuilder inputs={inputs} setInputs={setInputs}/>

                <Box sx={{pb: 2}}/>

                <AppButton
                    variant={"contained"}
                    fullWidth={true}
                    onClick={handleUpdate}
                    disabled={loading}
                    isLoading={updateLoading}
                >
                    {tt('common.save')}
                </AppButton>
                {showExtraSpace ? <Box sx={{height: 110}}/> : null}
            </ContentPadding>
        </ModalBottomSheet>
    );
}
