import {
    Box,
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Theme
} from "@mui/material";
import React, {useRef, useState} from "react";
import ChevronDownIcon from "../../../icons/ChevronDownIcon";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {Loader} from "@mantine/core";

export const useStyles = makeStyles()((theme: Theme) => ({
    paper: {
        borderRadius: 10,
        background: kAppColors.background.menuPaper(theme.palette.mode === "dark")
    },
    loading: {
        cursor: "default",
        'button:hover': {
            background: kAppColors.primary.main
        },
    },
    contentWhileLoadingOuter: {
        position: "relative",
    },
    contentWhileLoading: {
        opacity: 0,
    },
    loadingSpinnerContainer: {
        display: "flex",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
    },
}));

export interface ISplitButtonProps {
    className?: string;
    options: string[];
    text: string;
    onChange?: (index: number, value: string) => void;
    onClick: (index: number, value: string) => void;
    disabled?: boolean;
    isInModal?: boolean;
    isLoading?: boolean;
}

export default function SplitButton(props: ISplitButtonProps) {
    const {
        text,
        options,
        onChange,
        onClick,
        disabled,
        isInModal,
        className,
        isLoading,
    } = props;

    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(1);

    const {cx, classes} = useStyles();

    const theClassname = cx(
        className,
        isLoading ? classes.loading : null,
    );

    const handleClick = () => {
        if (!isLoading) {
            onClick(selectedIndex, options[selectedIndex]);
        }
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        if (!isLoading) {
            setSelectedIndex(index);
            setOpen(false);
            if (onChange) {
                onChange(index, options[index]);
            }
        }
    };

    const handleToggle = () => {
        if (!isLoading) {
            setOpen((prevOpen) => !prevOpen);
        }
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const buttonContentJSX = isLoading ? <Box className={classes.contentWhileLoadingOuter}>
        <Box className={classes.loadingSpinnerContainer}>
            <Loader color={'white'} size="sm"/>
        </Box>
        <Box className={classes.contentWhileLoading}>
            {text}
        </Box>
    </Box> : <>{text}</>;

    return (
        <>
            <ButtonGroup
                className={theClassname}
                disabled={disabled && !isLoading}
                fullWidth={true}
                disableElevation={true}
                variant="contained"
                ref={anchorRef}
                aria-label="split button">
                <Button
                    fullWidth={true}
                    onClick={handleClick}
                >
                    {buttonContentJSX}
                </Button>
                <Button
                    fullWidth={false}
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ChevronDownIcon/>
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: isInModal ? 1301 : 1,
                    py: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal={false}
                placement={"bottom-end"}
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper className={classes.paper}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );


}