import {Dispatch, SetStateAction, useContext, useEffect, useId, useMemo, useState} from "react";
import {AppContext} from "../../../App";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import {Box, Divider, InputAdornment, Menu, MenuItem, Theme} from "@mui/material";
import EmptyListText from "../../components/textComponents/EmptyListText";
import {tt} from "../../../core/Localization";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import AppChip from "../../components/chips/AppChip";
import {AddOutlined} from "@mui/icons-material";
import SearchIcon from "../../../icons/SearchIcon";
import {useNavigate, useSearchParams} from "react-router-dom";
import FormBuilder, {IInputsData, InputType} from "../../components/form/FormBuilder";
import ListShimmer from "../../components/shimmers/ListShimmer";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {AppDataContext} from "../../../AppData";
import {kMobileMenuRoute} from "../mobile/MobileMenuScreen";
import {makeStyles} from "tss-react/mui";
import {GetProductIcon, GetProductTypeTitle, SortProductsByName} from "../../../service/ProductService";
import FilterButton from "../../components/buttons/FilterButton";
import {usePopupState} from "material-ui-popup-state/hooks";
import {bindMenu} from "material-ui-popup-state";
import {productDetailRoute} from "./ProductDetailScreen";
import CreateProductTemplateModal from "../../components/products/CreateProductTemplateModal";
import AppIconButton from "../../components/buttons/AppIconButton";
import Icons8BoxPlus from "../../../icons/Icons8BoxPlus";
import AppListItem from "../../components/listItems/AppListItem";
import {
    GetProductsInput,
    ProductResponse,
    ProductResponsePage,
    ProductType
} from "../../../generated/graphql/graphql";
import {processQueryError} from "../../../service/ErrorService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import {PriceDisplay} from "../../../service/CompanyService";
import {kActionCreate, kPermissionsProducts, kTopicProductTemplates} from "../../../core/constants";
import PermissionValid from "../../components/permissions/PermissionValid";
import { RestApiClientContext } from "../../../core/RestApiProvider";

export const kProductsRoute = '/products';

export const useStyles = makeStyles()((theme: Theme) => ({
    chipsContainer: {
        display: 'flex',
        paddingBottom: 16,
        overflowX: "auto",
        'button': {
            flexShrink: 0,
            marginRight: 8,
        },
    },
    menuOffset: {
        transform: 'translateY(8px)',
    },
    listItemChipsContainer: {
        display: 'flex',
    }
}));

export default function ProductsListScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;
    
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ProductResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            const subscription = subscribe(
                kTopicProductTemplates,
                {
                    uri: '/product/search-by-company',
                    params: {
                        companyId: companyId,
                        isTemplate: true,
                    } as GetProductsInput,
                    setLoading,
                    onData: setData,
                    onError: (error) => processQueryError(appDataContext, error),
                },
                (notifications: IEventSystemNotification[]) => true,
            );

            return () => subscription.cancel();
        } else {
            setLoading(false);
            setData(null);
        }
    }, [companyId]);

    useEffect(() => {
        setTitle(tt('productsList.screen.title'));
    }, []);

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            type: InputType.Text,
            label: '',
            placeholder: tt('productsList.screen.searchForProductOrService'),
            hideLabel: true,
            inputVariant: 'standard',
            extraStyle: 'thin',
            value: '',
            required: true,
            isClearable: true,
            innerPrefixJSX: (
                <InputAdornment position={"start"}>
                    <SearchIcon/>
                </InputAdornment>
            ),
        },
    });

    const [modal, setModal] = useState<boolean>(false);
    const [ordered, setOrdered] = useState<boolean>(
        searchParams.get('ordered') ? searchParams.get('ordered') === 'true' : true,
    );
    const [type, setType] = useState<ProductType | null>
    (
        searchParams.get('type') === 'null' ? null : searchParams.get('type') as any,
    );

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('ordered', ordered.toString());
            return prev;
        });
    }, [ordered]);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('type', type ?? 'null');
            return prev;
        });
    }, [type]);

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                loading={loading}
                data={data}
                search={inputs.search.value.toLowerCase()}
                inputs={inputs}
                setInputs={setInputs}
                setModal={setModal}
                isMobile={isMobile}
                type={type}
                setType={setType}
                ordered={ordered}
                setOrdered={setOrdered}
            />
        );
    }

    return (
        <>
            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}
            />

            <CreateProductTemplateModal setOpen={setModal} open={modal}/>
        </>
    );
}

interface IBodyProps {
    loading: boolean;
    data: ProductResponsePage | NullOrUndefined;
    search: string;
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    setModal: Dispatch<SetStateAction<boolean>>;
    isMobile?: boolean;
    setType: Dispatch<SetStateAction<ProductType | null>>;
    type?: ProductType | null;
    setOrdered: Dispatch<SetStateAction<boolean>>;
    ordered: boolean;
}

function Body(props: IBodyProps) {
    const {
        loading,
        data,
        search,
        inputs,
        setInputs,
        setModal,
        isMobile,
        setType,
        type,
        setOrdered,
        ordered,
    } = props;


    const navigate = useNavigate();
    const {classes, cx} = useStyles();

    const appDataContext = useContext(AppDataContext);
    const {currency, language, company} = appDataContext;

    const isProductFilterMenu = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const content = useMemo(
        () => {
            let theData: ProductResponse[] | undefined = data?.content.filter(item => {
                if (type && item.type !== type) {
                    return false;
                }

                return !item.deleted && item.name.toLowerCase().includes(search.toLowerCase());
            });

            if (ordered && theData) {
                theData = SortProductsByName(theData);
            }

            return loading && !theData ?
                <ListShimmer items={2}/> : theData && theData.length > 0 ?
                    theData
                        .map(item => {
                            return (
                                <AppListItem
                                    key={item.id}
                                    variant={"smaller-title"}
                                    customAvatarInCircle={GetProductIcon(item.type)}
                                    onClick={() => {
                                        navigate(productDetailRoute(item.id));
                                    }}
                                    title={item.name}
                                    customBottomWidget={
                                        <Box className={classes.listItemChipsContainer}>
                                            <Box pr={0.5}>
                                                <AppChip
                                                    chipstyle={"outlined"}
                                                    label={PriceDisplay(item?.price, currency, language)}
                                                />
                                            </Box>

                                            {company?.hasVat ? (
                                                <AppChip
                                                    chipstyle={"outlined"}
                                                    label={tt('common.vatRate.percentage').replace('{vatRate}', (item.vatRate || 0).toString())}
                                                />
                                            ) : undefined}
                                        </Box>
                                    }
                                />
                            );
                        })
                    : <EmptyListText text={tt('productsList.screen.emptyListMessage')}/>;
        },
        [data, search, type, ordered]
    );

    const typeFilterTitle = !type ? tt('products.filter.all') : GetProductTypeTitle(type, true);

    const productsFiltersJSX = (
        <Box
            className={classes.chipsContainer}>
            <FilterButton
                title={typeFilterTitle}
                onClick={(e) => {
                    isProductFilterMenu.setOpen(true, e);
                }
                }
                isSelected={type !== null}
            />

            <Menu className={classes.menuOffset}  {...bindMenu(isProductFilterMenu)}>
                <MenuItem
                    key={'All'}
                    onClick={() => {
                        isProductFilterMenu.setOpen(false);

                        setType(null);
                    }
                    }
                >{tt('products.filter.all')}</MenuItem>

                <MenuItem
                    key={'products'}
                    onClick={() => {
                        isProductFilterMenu.setOpen(false);

                        setType(ProductType.Product);
                    }
                    }
                >{GetProductTypeTitle(ProductType.Product, true)}</MenuItem>

                <MenuItem
                    key={'services'}
                    onClick={() => {
                        isProductFilterMenu.setOpen(false);

                        setType(ProductType.Service);
                    }
                    }
                >{GetProductTypeTitle(ProductType.Service, true)}</MenuItem>
            </Menu>

            <FilterButton
                title={tt('common.filter.orderAlphabetically')}
                onClick={() =>
                    setOrdered(!ordered)
                }
                isSelected={ordered}
                hideChevronDown={true}
            />
        </Box>
    );

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    noMarginBottom={true}
                    isMobile={isMobile}
                    title={tt('productsList.screen.title')}
                    hideBackButton={!isMobile}
                    backRoute={isMobile ? kMobileMenuRoute : undefined}
                    bottomContent={<Box pt={1}>
                        <FormBuilder inputs={inputs} setInputs={setInputs}/>
                        {productsFiltersJSX}
                        <Divider sx={{mr: -2, ml: -2}}/>
                    </Box>}
                >
                    <PermissionValid
                        permission={kPermissionsProducts}
                        requiredPermissions={[kActionCreate]}
                    >
                        {isMobile ? (
                            <AppIconButton
                                variant={"primaryBg"}
                                key={'newServiceButtonMobileKey'}
                                onClick={() => setModal(true)}
                            >
                                <Icons8BoxPlus/>
                            </AppIconButton>
                        ) : (
                            <AppChip
                                onClick={() => {
                                    setModal(true)
                                }}
                                key={'addProductChip'}
                                label={tt('productsList.screen.new')}
                                icon={<AddOutlined/>}
                            />
                        )}
                    </PermissionValid>
                </PaperAppbar>

                {content}

                <Box sx={{pb: 0.5}}/>
            </AppPaper>
        </ScreenContent>
    );
}
