import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8MultilineTextProps {
    className?: string;
}

export default function Icons8MultilineText(props: IIcons8MultilineTextProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 7.5 9 A 1.50015 1.50015 0 1 0 7.5 12 L 40.5 12 A 1.50015 1.50015 0 1 0 40.5 9 L 7.5 9 z M 7.5 16 A 1.50015 1.50015 0 1 0 7.5 19 L 40.5 19 A 1.50015 1.50015 0 1 0 40.5 16 L 7.5 16 z M 7.5 23 A 1.50015 1.50015 0 1 0 7.5 26 L 40.5 26 A 1.50015 1.50015 0 1 0 40.5 23 L 7.5 23 z M 7.5 30 A 1.50015 1.50015 0 1 0 7.5 33 L 40.5 33 A 1.50015 1.50015 0 1 0 40.5 30 L 7.5 30 z M 7.5 37 A 1.50015 1.50015 0 1 0 7.5 40 L 29.5 40 A 1.50015 1.50015 0 1 0 29.5 37 L 7.5 37 z"/>
            </svg>
        </span>
    );
}



