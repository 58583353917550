import {iconUseStyles} from "../../styles/IconStyles";

export interface IENIconProps {
    className?: string;
}

export default function ENIcon(props: IENIconProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg id="icons8-usa" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path id="Path_40" data-name="Path 40"
                      d="M17.031,4.5V4.46A10.028,10.028,0,0,0,4,14.024c0,.338.018.673.051,1h12.98Z"
                      transform="translate(-4 -4)" fill="#3f51b5"/>
                <path id="Path_41" data-name="Path 41" d="M30.112,4.959c-.038-.013-.074-.029-.112-.041v.041Z"
                      transform="translate(-17 -4.458)" fill="none"/>
                <path id="Path_42" data-name="Path 42" d="M30,9.5h4.6A10.075,10.075,0,0,0,32.989,8H30Z"
                      transform="translate(-17.016 -5.995)" fill="#eceff1"/>
                <path id="Path_43" data-name="Path 43"
                      d="M24,26H4.1a9.943,9.943,0,0,0,.277,1.5H23.723A10,10,0,0,0,24,26Z"
                      transform="translate(-4.05 -15.002)" fill="#eceff1"/>
                <path id="Path_44" data-name="Path 44"
                      d="M23.987,38H9.729a10.024,10.024,0,0,0,1.885,1.5H22.1A10.024,10.024,0,0,0,23.987,38Z"
                      transform="translate(-6.858 -21.007)" fill="#eceff1"/>
                <path id="Path_45" data-name="Path 45"
                      d="M24,32H5.675a10.011,10.011,0,0,0,.815,1.5H23.181A10.056,10.056,0,0,0,24,32Z"
                      transform="translate(-4.836 -18.003)" fill="#eceff1"/>
                <path id="Path_46" data-name="Path 46" d="M30,15.5h6.373a9.978,9.978,0,0,0-.708-1.5H30Z"
                      transform="translate(-17.016 -9.001)" fill="#eceff1"/>
                <path id="Path_47" data-name="Path 47" d="M30,21.5h6.991a10.084,10.084,0,0,0-.176-1.5H30Z"
                      transform="translate(-17.016 -12.002)" fill="#eceff1"/>
                <path id="Path_48" data-name="Path 48" d="M30,6.5h2.989A9.98,9.98,0,0,0,30.112,5H30Z"
                      transform="translate(-17.005 -4.499)" fill="#f44336"/>
                <path id="Path_49" data-name="Path 49" d="M30,12.5h5.665A10.061,10.061,0,0,0,34.6,11H30Z"
                      transform="translate(-17.016 -7.501)" fill="#f44336"/>
                <path id="Path_50" data-name="Path 50" d="M30,18.5h6.815a9.926,9.926,0,0,0-.443-1.5H30Z"
                      transform="translate(-17.016 -10.501)" fill="#f44336"/>
                <path id="Path_51" data-name="Path 51"
                      d="M30,24.5h6.966c.033-.33.051-.664.051-1,0-.169-.017-.334-.025-.5H30Z"
                      transform="translate(-17.016 -13.502)" fill="#f44336"/>
                <path id="Path_52" data-name="Path 52"
                      d="M24,29H4.656a9.9,9.9,0,0,0,.51,1.5H23.489A9.957,9.957,0,0,0,24,29Z"
                      transform="translate(-4.327 -16.502)" fill="#f44336"/>
                <path id="Path_53" data-name="Path 53"
                      d="M23.992,35H7.3a10.025,10.025,0,0,0,1.211,1.5H22.781A10.053,10.053,0,0,0,23.992,35Z"
                      transform="translate(-5.649 -19.511)" fill="#f44336"/>
                <path id="Path_54" data-name="Path 54" d="M13.5,41a9.875,9.875,0,0,0,10.476,0Z"
                      transform="translate(-8.74 -22.504)" fill="#f44336"/>
                <path id="Path_55" data-name="Path 55"
                      d="M9.5,13l.233.5.519.078-.376.386.09.544L9.5,14.247l-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-6.369 -8.501)" fill="#fff"/>
                <path id="Path_56" data-name="Path 56"
                      d="M13.5,13l.233.5.519.078-.376.386.09.544-.466-.257-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-8.376 -8.501)" fill="#fff"/>
                <path id="Path_57" data-name="Path 57"
                      d="M6.028,14.735l.466.257-.09-.544.376-.386-.519-.078,0-.005q-.248.427-.455.88Z"
                      transform="translate(-4.9 -8.99)" fill="#fff"/>
                <path id="Path_58" data-name="Path 58"
                      d="M17.5,13l.233.5.519.078-.376.386.09.544-.466-.257-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-10.376 -8.501)" fill="#fff"/>
                <path id="Path_59" data-name="Path 59"
                      d="M21.5,13l.233.5.519.078-.376.386.09.544-.466-.257-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-12.377 -8.501)" fill="#fff"/>
                <path id="Path_60" data-name="Path 60"
                      d="M25.252,13l.233.5.519.078-.376.386.09.544-.466-.257-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-14.252 -8.501)" fill="#fff"/>
                <path id="Path_61" data-name="Path 61"
                      d="M9.5,21l.233.5.519.078-.376.386.09.544L9.5,22.247l-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-6.369 -12.502)" fill="#fff"/>
                <path id="Path_62" data-name="Path 62"
                      d="M13.5,21l.233.5.519.078-.376.386.09.544-.466-.257-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-8.376 -12.502)" fill="#fff"/>
                <path id="Path_63" data-name="Path 63"
                      d="M17.5,21l.233.5.519.078-.376.386.09.544-.466-.257-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-10.376 -12.502)" fill="#fff"/>
                <path id="Path_64" data-name="Path 64"
                      d="M5.5,21l.233.5.519.078-.376.386.09.544L5.5,22.247l-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-4.374 -12.502)" fill="#fff"/>
                <path id="Path_65" data-name="Path 65"
                      d="M21.5,21l.233.5.519.078-.376.386.09.544-.466-.257-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-12.377 -12.502)" fill="#fff"/>
                <path id="Path_66" data-name="Path 66"
                      d="M25.252,21l.233.5.519.078-.376.386.09.544-.466-.257-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-14.252 -12.502)" fill="#fff"/>
                <path id="Path_67" data-name="Path 67"
                      d="M10.5,17l.233.5.519.078-.376.386.09.544-.466-.257-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-6.868 -10.501)" fill="#fff"/>
                <path id="Path_68" data-name="Path 68"
                      d="M14.5,17l.233.5.519.078-.376.386.09.544-.466-.257-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-8.876 -10.501)" fill="#fff"/>
                <path id="Path_69" data-name="Path 69"
                      d="M6.5,17l.233.5.519.078-.376.386.09.544L6.5,18.247l-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-4.873 -10.501)" fill="#fff"/>
                <path id="Path_70" data-name="Path 70"
                      d="M18.5,17l.233.5.519.078-.376.386.09.544-.466-.257-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-10.876 -10.501)" fill="#fff"/>
                <path id="Path_71" data-name="Path 71"
                      d="M22.5,17l.233.5.519.078-.376.386.09.544-.466-.257-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-12.877 -10.501)" fill="#fff"/>
                <path id="Path_72" data-name="Path 72"
                      d="M26.252,17l.233.5.519.078-.376.386.09.544-.466-.257-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-14.752 -10.501)" fill="#fff"/>
                <path id="Path_73" data-name="Path 73"
                      d="M10.5,9l.233.5.519.078-.376.386.09.544-.466-.257-.466.257.09-.544L9.75,9.573l.519-.078Z"
                      transform="translate(-6.868 -6.494)" fill="#fff"/>
                <path id="Path_74" data-name="Path 74"
                      d="M14.5,9l.233.5.519.078-.376.386.09.544-.466-.257-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-8.876 -6.494)" fill="#fff"/>
                <path id="Path_75" data-name="Path 75"
                      d="M18.5,9l.233.5.519.078-.376.386.09.544-.466-.257-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-10.876 -6.494)" fill="#fff"/>
                <path id="Path_76" data-name="Path 76"
                      d="M22.5,9l.233.5.519.078-.376.386.09.544-.466-.257-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-12.877 -6.494)" fill="#fff"/>
                <path id="Path_77" data-name="Path 77"
                      d="M26.252,9l.233.5L27,9.573l-.376.386.09.544-.466-.257-.466.257.09-.544L25.5,9.573l.519-.078Z"
                      transform="translate(-14.752 -6.494)" fill="#fff"/>
                <path id="Path_78" data-name="Path 78"
                      d="M14.252,7.033l-.09-.544.376-.386-.276-.042q-.443.217-.86.476l-.082.5.466-.257Z"
                      transform="translate(-8.661 -5.028)" fill="#fff"/>
                <path id="Path_79" data-name="Path 79"
                      d="M17.5,5l.233.5.519.078-.376.386.09.544L17.5,6.247l-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-10.376 -4.499)" fill="#fff"/>
                <path id="Path_80" data-name="Path 80"
                      d="M21.5,5l.233.5.519.078-.376.386.09.544L21.5,6.247l-.466.257.09-.544-.376-.386.519-.078Z"
                      transform="translate(-12.377 -4.499)" fill="#fff"/>
                <path id="Path_81" data-name="Path 81"
                      d="M25.252,5l.233.5L26,5.574l-.376.386.09.544-.466-.257-.466.257.09-.544L24.5,5.573l.519-.078Z"
                      transform="translate(-14.252 -4.499)" fill="#fff"/>
            </svg>
        </span>
    );
}
