import {makeStyles} from "tss-react/mui";
import {Box, Popover, TextField, Theme} from "@mui/material";
import {kAppColors} from "../../../../../styles/AppThemeProcessor";
import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {AppDataContext} from "../../../../../AppData";
import {bindPopover, usePopupState} from "material-ui-popup-state/hooks";
import FormBuilder, {IInputsData, InputType} from "../../../form/FormBuilder";
import {tt} from "../../../../../core/Localization";
import EmptyListText from "../../../textComponents/EmptyListText";
import AppChip from "../../../chips/AppChip";
import AppAvatar from "../../../AppAvatar";
import FilterButton from "../../../buttons/FilterButton";
import Menu from "@mui/material/Menu";
import {bindMenu} from "material-ui-popup-state";
import AppListItem from "../../../listItems/AppListItem";
import IVisitEvent from "../../../../../model/VisitEvent";
import {VisitDateWithDynamic, VisitNameOrSequenceId} from "../../../../../service/VisitService";
import HammerIcon from "../../../../../icons/HammerIcon";
import {truncateString} from "../../../../../utils/Utils";

export const useStyles = makeStyles()((theme: Theme) => ({
    menuOffset: {
        '.MuiPaper-root': {
            width: '90%',
            maxWidth: 400,
            borderRadius: 10,
            top: 120 + 'px !important',
        },
        transform: 'translateY(8px)',
        '.MuiListItem-root': {
            borderRadius: 0,
        },
    },
    chip: {
        marginRight: 8,
        marginTop: 6,
        marginBottom: 2,
        borderRadius: 40,
        paddingLeft: 0,
        paddingRight: 4,
        fontSize: 14,
        height: 32,
        fontWeight: 600,
        background: kAppColors.background.autocompleteChip(theme.palette.mode === "dark"),
        color: kAppColors.text.primary(theme.palette.mode === 'dark'),
        ':hover': {
            background: kAppColors.background.autocompleteChip(theme.palette.mode === "dark"),
        },
        '.MuiChip-icon.MuiChip-icon': {
            marginLeft: 3,
        },
        '.MuiChip-deleteIcon': {
            color: theme.palette.mode === 'dark' ? kAppColors.text.white : kAppColors.text.secondaryLightModeGrey,
        }
    },
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
    },
    input: {
        '.MuiInputBase-root': {
            flexDirection: "column",
            alignItems: "start",
        }
    },
    iconColor: {
        color: kAppColors.text.primary(theme.palette.mode === 'dark'),
    }
}));


export interface IVisitsByNameFilterProps {
    selected: string | undefined;
    setSelected: Dispatch<SetStateAction<string | undefined>>;
    visits: IVisitEvent[] | undefined;
}

export default function VisitsByNameFilter(props: IVisitsByNameFilterProps) {
    const {selected, setSelected, visits} = props;

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const {classes} = useStyles();

    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'popup-visits-byName-filter',
    });

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            className: classes.input,
            type: InputType.Text,
            label: '',
            value: '',
            required: true,
            size: "small",
            hideLabel: true,
            placeholder: tt('visits.screen.filterByName.input.label'),
        },
    });

    useEffect(() => {
        const visit = visits?.find(element => {
            if (element.dynamicId) {
                return element.dynamicId === selected;
            }

            return element.id === parseInt(selected || "");
        });
        const title = visit ? truncateString(VisitNameOrSequenceId(visit, visit.visitRepeatDay), 35) : undefined;

        setInputs(prev => {
            return {
                ...prev,
                search: {
                    ...prev.search,
                    innerPrefixJSX: (
                        <Box className={classes.chipsContainer}>
                            {visit ? <AppChip
                                icon={
                                    <AppAvatar
                                        variant={"extraSmall"}
                                        icon={<HammerIcon className={classes.iconColor}/>}
                                    />
                                }
                                onDelete={
                                    () => {
                                        setSelected(undefined);
                                    }
                                }
                                label={title}
                                className={classes.chip}
                            /> : null}
                        </Box>
                    ),
                }
            };
        });
    }, [selected, visits]);

    const search = inputs.search.value;

    const content = useMemo(
        () => {
            const theSearch = search.toLowerCase();

            const theData = visits?.filter(job => {
                const theName = VisitNameOrSequenceId(job, job.visitRepeatDay).toLowerCase();

                return theName.includes(theSearch);
            });

            return theData && theData.length > 0 ?
                theData.slice(0, 5)
                    .map(visit => {
                        return (
                            <Box
                                key={visit.dynamicId || visit.id}
                                mr={-1}
                                ml={-1}
                            >
                                <AppListItem
                                    onClick={() => {
                                        ///can select max 1 job
                                        setSelected(visit.dynamicId || visit.id);
                                        popupState.close();
                                    }}
                                    variant={"smaller-title"}
                                    customAvatarInCircle={<HammerIcon/>}
                                    title={VisitNameOrSequenceId(visit, visit.visitRepeatDay)}
                                    description={VisitDateWithDynamic(visit, visit.visitRepeatDay, true)}
                                    actionWidget={<></>}
                                />
                            </Box>
                        );
                    })
                : <EmptyListText text={tt('visits.screen.filterByName.list.emptyListMessage')}/>;
        },
        [visits, search, selected]
    );

    const buttonTitle = useMemo(
        () => {
            const visit = visits?.find(element => {
                if (element.dynamicId) {
                    return element.dynamicId === selected;
                }

                return element.id === parseInt(selected || "");
            });

            return visit ? truncateString(VisitNameOrSequenceId(visit, visit.visitRepeatDay), 35) : undefined;
        }, [visits, selected]
    );

    return (
        <>
            <FilterButton
                title={selected ? buttonTitle : tt('visits.screen.filterByName.button')}
                onClick={
                    (e) => {
                        popupState.setOpen(true, e);
                    }
                }
                isSelected={selected != undefined}
            />

            <Popover className={classes.menuOffset}  {...bindPopover(popupState)}>
                <Box>
                    <Box
                        pt={1}
                        pl={1}
                        pr={1}
                    >
                        <FormBuilder inputs={inputs} setInputs={setInputs}/>
                    </Box>

                    {content}
                </Box>
            </Popover>
        </>
    );
}
