import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8Key2Props {
    className?: string;
}

export default function Icons8Key2(props: IIcons8Key2Props) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px"
                 preserveAspectRatio="xMidYMid meet" focusable="false">
                <path
                    d="M 30 5 C 22.838262 5 17 10.838262 17 18 C 17 18.882345 17.182742 19.703331 17.351562 20.527344 L 5.4394531 32.439453 A 1.50015 1.50015 0 0 0 5 33.5 L 5 41.5 A 1.50015 1.50015 0 0 0 6.5 43 L 14.5 43 A 1.50015 1.50015 0 0 0 16 41.5 L 16 39 L 19.5 39 A 1.50015 1.50015 0 0 0 21 37.5 L 21 34 L 24.5 34 A 1.50015 1.50015 0 0 0 26 32.5 L 26 30.294922 C 27.271145 30.709396 28.595703 31 30 31 C 37.161738 31 43 25.161738 43 18 C 43 10.838262 37.161738 5 30 5 z M 30 8 C 35.540262 8 40 12.459738 40 18 C 40 23.540262 35.540262 28 30 28 C 28.262518 28 26.639202 27.56058 25.21875 26.785156 A 1.50015 1.50015 0 0 0 23 28.101562 L 23 31 L 19.5 31 A 1.50015 1.50015 0 0 0 18 32.5 L 18 36 L 14.5 36 A 1.50015 1.50015 0 0 0 13 37.5 L 13 40 L 8 40 L 8 34.121094 L 19.988281 22.132812 A 1.50015 1.50015 0 0 0 20.373047 20.669922 C 20.133878 19.809089 20 18.919659 20 18 C 20 12.459738 24.459738 8 30 8 z M 32 13 A 3 3 0 0 0 32 19 A 3 3 0 0 0 32 13 z"/>
            </svg>
        </span>
    );
}