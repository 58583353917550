import ModalBottomSheet from "../../../ModalBottomSheet";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {tt} from "../../../../../core/Localization";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import ContentPadding from "../../../paper/ContentPadding";
import {
    JobUpdateRepeats,
    UpdateVisitInvoiceDeliveryNumbersDocument,
    UpdateVisitInvoiceDeliveryNumbersMutation,
    UpdateVisitInvoiceDeliveryNumbersMutationVariables,
} from "../../../../../generated/graphql/graphql";
import {SuccessToast} from "../../../../../service/ToastService";
import {processMutationError} from "../../../../../service/ErrorService";
import {useMutation} from "@apollo/client";
import FormBuilder, {IInputsData, InputType} from "../../../form/FormBuilder";
import {Box} from "@mui/material";
import AppButton from "../../../buttons/AppButton";
import {IOnUpdateVisitIdParams} from "./VisitDetailModal";

export interface IEditVisitInvoiceDeliveryNumbersModalBottomSheetProps {
    visitId: number;
    repeatingDay?: number;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    deliveryNumber?: string | NullOrUndefined;
    invoiceNumber?: string | NullOrUndefined;
    onUpdateVisitId: (params: IOnUpdateVisitIdParams) => void;
    modalAboveModals?: boolean;
}

/**
 * Modal bottom sheet for editing the invoice and delivery numbers of a Visit.
 */
export default function EditVisitInvoiceDeliveryNumbersModalBottomSheet(props: IEditVisitInvoiceDeliveryNumbersModalBottomSheetProps) {
    const {
        visitId,
        repeatingDay,
        open,
        setOpen,
        deliveryNumber,
        invoiceNumber,
        onUpdateVisitId,
        modalAboveModals,
    } = props;

    const [mutateUpdateJobInvoiceDeliveryNumbers, {
        loading: updateJobInvoiceDeliveryNumbersLoading,
    }] = useMutation<UpdateVisitInvoiceDeliveryNumbersMutation, UpdateVisitInvoiceDeliveryNumbersMutationVariables>(UpdateVisitInvoiceDeliveryNumbersDocument);

    /**
     * Mutate update of Invoice Delivery numbers to BE.
     */
    const updateUpdateJobInvoiceDeliveryNumbers = async (deliveryNumber?: string, invoiceNumber?: string) => {
        try {
            const variables: UpdateVisitInvoiceDeliveryNumbersMutationVariables = {
                input: {
                    visitId: visitId,
                    repeatingDay,
                    repeats: JobUpdateRepeats.Single,
                    deliveryNumber,
                    invoiceNumber,
                },
            };

            const result = await mutateUpdateJobInvoiceDeliveryNumbers({variables});

            if (!result.errors) {
                setOpen(false);

                SuccessToast(tt('jobDetailContent.updateInvoiceDeliveryNumbers.success'));

                if (result.data!.updateVisitInvoiceDeliveryNumbers.id) {
                    onUpdateVisitId({
                        visitId: result.data!.updateVisitInvoiceDeliveryNumbers.id,
                        repeatingDay: repeatingDay!,
                    });
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    const [inputs, setInputs] = useState<IInputsData>({
        invoiceNumber: {
            testId: 'editJobInvoiceDeliveryNumbersModalBottomSheet.invoiceNumber',
            type: InputType.ButtonTextField,
            label: tt('common.invoiceNumber'),
            toggleButtonText: tt('common.addInvoiceNumber'),
            value: '',
            grid: {
                sm: 6,
                xs: 12,
            },
        },
        deliveryNumber: {
            testId: 'editJobInvoiceDeliveryNumbersModalBottomSheet.deliveryNumber',
            type: InputType.ButtonTextField,
            label: tt('common.deliveryNumber'),
            toggleButtonText: tt('common.addDeliveryNumber'),
            value: '',
            grid: {
                sm: 6,
                xs: 12,
            },
        },
    });

    useEffect(() => {
        if (open) {
            setInputs({
                invoiceNumber: {
                    ...inputs.invoiceNumber,
                    value: invoiceNumber || '',
                },
                deliveryNumber: {
                    ...inputs.deliveryNumber,
                    value: deliveryNumber || '',
                },
            });
        }
    }, [open]);

    return (
        <ModalBottomSheet
            modalAboveModals={modalAboveModals}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
        >
            <BottomSheetModalAppbar
                noBorderBottom={true}
                onClose={() => setOpen(false)}
                title={tt('jobEditJobInvoiceDeliveryNumbers.invoiceDelivery')}>
            </BottomSheetModalAppbar>

            <ContentPadding>
                <FormBuilder inputs={inputs} setInputs={setInputs}/>

                <Box sx={{pb: 2}}/>

                <AppButton
                    variant={"contained"}
                    fullWidth={true}
                    onClick={() => {
                        updateUpdateJobInvoiceDeliveryNumbers(inputs.deliveryNumber.value, inputs.invoiceNumber.value);
                    }}
                    isLoading={updateJobInvoiceDeliveryNumbersLoading}
                >
                    {tt('common.save')}
                </AppButton>
            </ContentPadding>
        </ModalBottomSheet>
    );
}
