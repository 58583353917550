import {Theme, Typography} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import React from "react";
import {makeStyles} from "tss-react/mui";


export const useStyles = makeStyles()((theme: Theme) => ({
        text: {
            fontSize: 12,
            fontWeight: 600,
            color: kAppColors.text.secondary(theme.palette.mode === "dark"),
            textTransform: "uppercase",
        },
        centered: {
            textAlign: "center",
        },
        horizontalPadding: {
            paddingRight: 16,
            paddingLeft: 16,
        }
    })
);

export interface IGreyLabelProps {
    text: string;
    className?: string;
    horizontalPadding?: boolean;
    centered?: boolean;
}

export default function GreyLabel(props: IGreyLabelProps) {
    const {text, className, horizontalPadding, centered} = props;

    const {classes, cx} = useStyles();

    const theClassName = cx(
        classes.text,
        className,
        centered ? classes.centered : undefined,
        horizontalPadding ? classes.horizontalPadding : undefined,
    );

    return (
        <Typography className={theClassName}>{text}</Typography>
    );
}