import React, {useContext} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, ListItem, ListItemAvatar, Theme, Typography} from "@mui/material";
import {kAppColors} from "../../../../../../styles/AppThemeProcessor";
import ClockIcon from "../../../../../../icons/ClockIcon";
import {DateTime} from "luxon";
import {AppDataContext} from "../../../../../../AppData";
import {tt} from "../../../../../../core/Localization";


const useStyles = makeStyles()((theme: Theme) => ({
    listItem: {
        minHeight: 0,
        paddingLeft: 16,
        paddingRight: 16,
        marginBottom: 0,
        alignItems: "start",
        cursor: "default",
    },
    noMarginBottom: {
        marginBottom: 0,
    },
    text1: {
        fontSize: 13,
        fontWeight: 600,
    },
    text2: {
        fontSize: 11,
        color: kAppColors.text.secondary(theme.palette.mode === "dark")
    },
    listItemAvatar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 27,
        width: 27,
        'span, svg': {
            height: 18,
            width: 18,
        }
    },
    textContainer: {
        paddingRight: 8,
        display: "flex",
        flexGrow: 1,
        margin: 0,
    },
    mainContainer: {
        flexGrow: 1,
    },
    firstColumn: {
        marginRight: 10,
    },
    secondColumn: {}
}));

export interface IVisitPreviewStartEndItemProps {
    start: DateTime;
    end: DateTime | undefined | null;
    isWholeDay?: boolean;
    isSingleDay?: boolean;
}

export default function VisitPreviewStartEndItem(props: IVisitPreviewStartEndItemProps) {
    const {
        start,
        end,
        isWholeDay,
        isSingleDay,
    } = props;


    const {classes, cx} = useStyles();
    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const listItemClass = cx(
        classes.listItem,
    );

    let wholeDayJSX = (
        <Box className={classes.firstColumn}>
            <Typography className={classes.text1}>{tt('visitItem.label.wholeDay')}</Typography>
            <Typography
                className={classes.text2}>{start.toFormat('d.M.yyyy', {locale: language})}</Typography>
        </Box>
    );

    if (!isSingleDay) {
        wholeDayJSX = (
            <>
                <Box className={classes.firstColumn}>
                    <Typography className={classes.text1}>{tt('visitItem.label.wholeDay')}</Typography>
                    <Typography
                        className={classes.text2}>{start.toFormat('d.M.yyyy', {locale: language})}</Typography>
                </Box>

                {end ? (
                    <Box className={classes.firstColumn}>
                        <Typography className={classes.text1}>{tt('visitItem.label.wholeDay')}</Typography>
                        <Typography
                            className={classes.text2}>{end.toFormat('d.M.yyyy', {locale: language})}</Typography>
                    </Box>
                ) : null}
            </>
        );
    }

    return (
        <ListItem className={listItemClass}>
            <ListItemAvatar className={classes.listItemAvatar}>
                <ClockIcon/>
            </ListItemAvatar>

            <Box className={classes.textContainer}>
                {isWholeDay ? wholeDayJSX : (
                    <>
                        <Box className={classes.firstColumn}>
                            <Typography
                                className={classes.text1}>{start.toFormat('H:mm', {locale: language})}</Typography>
                            <Typography
                                className={classes.text2}>{start.toFormat('d.M.yyyy', {locale: language})}</Typography>
                        </Box>

                        {end ?
                            <Box className={classes.secondColumn}>
                                <Typography
                                    className={classes.text1}>{end.toFormat('H:mm', {locale: language})}</Typography>
                                <Typography
                                    className={classes.text2}>{end.toFormat('d.M.yyyy', {locale: language})}</Typography>
                            </Box>
                            : null}
                    </>
                )}
            </Box>
        </ListItem>
    )
}

