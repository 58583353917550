import React, {useContext, useEffect, useId, useState} from "react";
import {AppDataContext} from "../../../AppData";
import {PopupState, usePopupState} from "material-ui-popup-state/hooks";
import AppChip from "../chips/AppChip";
import Menu from "@mui/material/Menu";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import {Box, ButtonBase, Card, Divider, Grid, Theme, Tooltip, Typography} from "@mui/material";
import {tt} from "../../../core/Localization";
import KeyIcon from "../../../icons/KeyIcon";
import CompanyIcon from "../../../icons/CompanyIcon";
import AppListItem from "../listItems/AppListItem";
import {makeStyles} from "tss-react/mui";
import {useNavigate} from "react-router-dom";
import SettingsIcon from "../../../icons/SettingsIcon";
import Icons8Replace from "../../../icons/Icons8Replace";
import {AddOutlined} from "@mui/icons-material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {
    CompanyResponse,
    GetCompanyInput,
    GetUserJoinedCompaniesInput,
    UserJoinedCompaniesResponse
} from "../../../generated/graphql/graphql";
import {UserRoleTitle, UserStartSwitchCompany} from "../../../service/UserService";
import {kCompanyDetailRoute} from "../../screens/company/CompanyDetailScreen";
import ResponsiveContainer from "../screens/ResponsiveContainer";
import ChevronDownIcon from "../../../icons/ChevronDownIcon";
import ModalBottomSheet from "../ModalBottomSheet";
import AddIcon from "../../../icons/AddIcon";
import {SetCreateCompanyModal, SetJoinCompanyModal} from "../modals/AppModals";
import {kDashboardRoute} from "../../screens/dashboard/DashboardScreen";
import {AppContext} from "../../../App";
import {trackDataLayerEvent} from "../../../service/AnalyticsService";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import {
    kActionDelete,
    kActionUpdate,
    kActionView,
    kPermissionsCompany,
    kTopicCompanies,
    kTopicUsers
} from "../../../core/constants";
import {processQueryError} from "../../../service/ErrorService";
import {hasPermission} from "../permissions/PermissionValid";
import {listenToEventSystem, unListenToEventSystem} from "../../../service/EventSystemService";
import IEventSystemListener from "../../../model/EventSystemListener";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import AppAvatar from "../AppAvatar";
import AvatarSubscriptionProgressDecoration from "../progressIndicators/AvatarTrialProgressDecoration";

const useStyles = makeStyles()((theme: Theme) => ({
    menu: {
        '.MuiPaper-root': {
            top: '72px !important',
        },
        '.MuiPopover-paper': {
            padding: 0,
            'li': {
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 8,
                paddingBottom: 8,
                borderRadius: 0,
                marginBottom: 0,
            }
        }
    },
    rotateIconOnHover: {
        ':hover .MuiListItemSecondaryAction-root  svg': {
            transition: 'transform .7s ease-in-out',
            transform: 'rotate(-360deg)',
        }
    },
    tooltipPopper: {
        '.MuiTooltip-tooltip': {
            marginTop: '18px !important',
        }
    },
    titleTextStyle: {
        fontSize: 20,
        fontWeight: 600,
        paddingTop: 22,
        paddingRight: 16,
        paddingLeft: 16,
        paddingBottom: 20,
    },
    labelTextStyle: {
        textTransform: "uppercase",
        fontSize: 12,
        fontWeight: 700,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        paddingRight: 16,
        paddingLeft: 16,
        paddingBottom: 8,
    },
    iconColor: {
        color: kAppColors.text.primary(theme.palette.mode === 'dark')
    },
    // Mobile
    buttonBase: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 8,
        paddingRight: 8,
        marginRight: "auto",
        marginLeft: 8,
    },
    card: {
        textAlign: "left",
        boxShadow: "none",
        background: "none",
        backgroundImage: "none",
    },
    appTitle: {
        fontSize: 20,
        fontWeight: 700,
        lineHeight: 1.2,
        color: kAppColors.text.primary(theme.palette.mode === "dark")
    },
    companyName: {
        fontSize: 14,
        fontWeight: 500,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
    },
    chevron: {
        position: "relative",
        top: -3,
        marginLeft: 0,
        marginBottom: -8,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
    },
    bottomSheetTitleTextStyle: {
        fontSize: 20,
        fontWeight: 600,
        padding: 16,
    },
    iconWithEmptySpace: {
        width: 56,
        height: 56,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    noCompaniesAppTitleContainer: {
        paddingLeft: 16,
        height: 58,
        display: "flex",
        alignItems: "center",
    }
}));

export default function ChooseCompanyMenu() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {authUser} = appContext;
    const uid = authUser?.uid;

    const appDataContext = useContext(AppDataContext);
    const {companyId, storage, setStorage, employeePermissionsMap} = appDataContext;

    const {classes} = useStyles();
    const navigate = useNavigate();

    const companiesPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });
    const popupOpen = companiesPopupState.isOpen;
    const [bottomSheet, setBottomSheet] = useState<boolean>(false);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<UserJoinedCompaniesResponse | NullOrUndefined>();
    const [singleData, setSingleData] = useState<CompanyResponse | NullOrUndefined>();
    const [refetch, setRefetch] = useState(0);
    useEffect(() => {
        if (popupOpen || bottomSheet) {
            const subscription = subscribe(
                kTopicUsers,
                {
                    uri: '/user/joined-companies',
                    params: {
                        includeCompanySubscriptions: true,
                    } as GetUserJoinedCompaniesInput,
                    setLoading,
                    onData: (data) => {
                        setData(data);

                        if (data) {
                            setStorage((prev) => {
                                return {
                                    filesToProcess: [
                                        ...prev.filesToProcess,
                                        ...(data as UserJoinedCompaniesResponse).files,
                                    ],
                                };
                            });
                        }
                    },
                    onError: (error) => {
                        processQueryError(appDataContext, error);
                    },
                },
                (notifications) => {
                    return notifications.some((notification) => {
                        return notification.action === kActionUpdate
                            && notification.data['uid'] === uid;
                    });
                },
            );

            return () => {
                subscription.cancel();
            };
        } else if (companyId) {
            const subscription = subscribe(
                kTopicCompanies,
                {
                    uri: '/company',
                    params: {
                        companyId,
                    } as GetCompanyInput,
                    onData: setSingleData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                (notifications) => {
                    return notifications.some(notification => {
                        return notification.action === kActionUpdate;
                    });
                },
            );

            return () => subscription.cancel();
        }
    }, [companyId, refetch, uid, popupOpen, bottomSheet]);

    const selectedCompany = data ? data.companies.find((company) => company.id == companyId) : undefined;
    const companyName = selectedCompany?.name || singleData?.name || '...';

    let companies = data ? data.companies : null;
    if (companies) {
        const currentIndex = companies.findIndex(company => company.id === companyId);

        const currentCompany = companies[currentIndex];

        if (currentCompany) {
            const newCompanies = companies.filter(company => company.id !== companyId);
            companies = [currentCompany].concat(newCompanies);
        }
    }

    const companiesJSX = companies ? companies.map((company) => {
            const employeeData = data!.employees.find((employee) => employee.companyId == company.id);

            let onClick: React.MouseEventHandler<HTMLLIElement> | undefined = undefined;
            let actionWidget: React.ReactNode | undefined = undefined;

            if (company.id != companyId) {
                onClick = () => {
                    companiesPopupState.close();
                    setBottomSheet(false);
                    SwitchCompany(company.id);
                };
                actionWidget = <Icons8Replace/>;
            }
            if (company.id == companyId && hasPermission(kPermissionsCompany, [kActionView], employeePermissionsMap)) {
                onClick = () => navigate(kCompanyDetailRoute);
                actionWidget = <SettingsIcon/>;
            }

            const subscription = data?.companySubscriptions?.find((subscription) => subscription.companyId == company.id);

            return (
                <AppListItem
                    className={company.id != companyId ? classes.rotateIconOnHover : undefined}
                    key={company.id}
                    onClick={onClick}
                    title={company.name}
                    description={employeeData ? UserRoleTitle(employeeData.role) : undefined}
                    variant={"smaller-title"}
                    customAvatar={
                        <AvatarSubscriptionProgressDecoration
                            subscription={subscription}
                            child={<AppAvatar
                                img={storage.publicUrlsForIds[company.logoFileId]}
                                icon={<CompanyIcon className={classes.iconColor}/>}
                            />}
                        />
                    }
                    actionWidget={actionWidget || <></>}
                    noMarginBottom={true}
                />
            );
        }) :
        null;

    /**
     * Switch whole app another Company.
     */
    const SwitchCompany = (companyId: number) => {
        UserStartSwitchCompany(appDataContext, companyId);

        setTimeout(() => {
            navigate(kDashboardRoute);
        }, 100);
    };

    const BodyJSX = <Body
        refetch={() => setRefetch(refetch + 1)}
        loading={loading}
        companyName={companyName}
        companiesJSX={companiesJSX}
        companiesPopupState={companiesPopupState}
    />;
    const MobileBodyJSX = <MobileBody
        refetch={() => setRefetch(refetch + 1)}
        loading={loading}
        companyName={companyName}
        companiesJSX={companiesJSX}
        companiesPopupState={companiesPopupState}
        bottomSheet={bottomSheet}
        setBottomSheet={setBottomSheet}
    />;

    return (
        <ResponsiveContainer
            smallPhoneScreen={MobileBodyJSX}
            largePhoneScreen={MobileBodyJSX}
            tabletScreen={BodyJSX}
            smallDesktopScreen={BodyJSX}
            largeDesktopScreen={BodyJSX}
            extraLargeDesktopScreen={BodyJSX}
        />
    );
}

interface IBodyProps {
    refetch: VoidFunction;
    loading: boolean;
    companyName: string;
    companiesJSX: React.ReactNode;
    companiesPopupState: PopupState;
    bottomSheet?: boolean;
    setBottomSheet?: React.Dispatch<React.SetStateAction<boolean>>;

}

function Body(props: IBodyProps) {
    const {companyName, companiesJSX, companiesPopupState, refetch} = props;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const {classes} = useStyles();

    return (
        <>
            <EventSystemListeners
                companyId={companyId || 0}
                refetch={refetch}
            />

            <Tooltip
                classes={{popper: classes.tooltipPopper}}
                title={tt('menu.tooltip.activeCompany')}>
                <Box display={"inline-block"}>
                    <AppChip
                        chipstyle={'secondary'}
                        label={companyName}
                        {...bindTrigger(companiesPopupState)}
                    />
                </Box>
            </Tooltip>
            <Menu className={classes.menu} {...bindMenu(companiesPopupState)}>
                <Typography className={classes.titleTextStyle}>{tt('choseCompanyPopup.title')}</Typography>

                {companiesJSX}

                <Box pb={2}/>
                <Grid container pr={2} pl={2}>
                    <AppChip chipstyle={'primary'}
                             onClick={
                                 () => {
                                     companiesPopupState.close();

                                     trackDataLayerEvent('next_company_form_modal_start');

                                     SetCreateCompanyModal(appDataContext, {
                                         newCompanyOpen: true,
                                         singleStep: true,
                                         cleanFormOnOpen: true,
                                     });
                                 }

                             }
                             icon={<AddOutlined/>}
                             label={tt('common.createCompany')}/>
                    <Box pl={2}/>
                    <AppChip chipstyle={'secondary'}
                             onClick={
                                 () => {
                                     companiesPopupState.close();

                                     SetJoinCompanyModal(appDataContext, {
                                         open: true,
                                     });
                                 }
                             }
                             icon={<KeyIcon/>}
                             label={tt('common.joinCompany')}/>
                </Grid>
                <Box pb={2}/>
            </Menu>
        </>
    );
}

function MobileBody(props: IBodyProps) {
    const {companyName, companiesJSX, setBottomSheet, bottomSheet, refetch} = props;

    const appContext = useContext(AppContext);
    const {authUser} = appContext;
    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const noCompaniesMode = !companyId && authUser && authUser.signInResponse && authUser.signInResponse.data && authUser.signInResponse.data.companyIds.length == 0;

    const {classes, cx} = useStyles();

    const contentJSX = noCompaniesMode ?
        <Box className={classes.noCompaniesAppTitleContainer}>
            <Typography className={classes.appTitle}>{tt('app.name')}</Typography>
        </Box> : <>
            <ButtonBase className={classes.buttonBase}>
                <Card className={classes.card}
                      onClick={() => {
                          setBottomSheet!(true);
                      }
                      }>

                    <Typography className={classes.appTitle}>{tt('app.name')}</Typography>
                    <Grid container><Typography
                        className={classes.companyName}>{companyName}</Typography><ChevronDownIcon
                        className={classes.chevron}/></Grid>
                </Card>
            </ButtonBase>

            <ModalBottomSheet snapPoints={[900, 400, 100, 0]} open={bottomSheet!} setOpen={setBottomSheet!}
                              children={
                                  <Box>
                                      <Typography
                                          className={classes.bottomSheetTitleTextStyle}>{tt('choseCompanyPopup.title')}
                                      </Typography>

                                      {companiesJSX}

                                      <Box pb={2}/>
                                      <Divider/>
                                      <Box pb={2}/>
                                      <AppListItem
                                          title={tt('common.createCompany')}
                                          variant={"smaller-title"}
                                          customAvatar={<AddIcon className={classes.iconWithEmptySpace}/>}
                                          actionWidget={<></>}
                                          onClick={() => {
                                              setBottomSheet!(false);

                                              trackDataLayerEvent('next_company_form_modal_start');

                                              SetCreateCompanyModal(appDataContext, {
                                                  newCompanyOpen: true,
                                                  singleStep: true,
                                                  cleanFormOnOpen: true,
                                              });
                                          }}
                                      ></AppListItem>
                                      <AppListItem title={tt('common.joinCompany')} variant={"smaller-title"}
                                                   customAvatar={<KeyIcon className={classes.iconWithEmptySpace}/>}
                                                   actionWidget={<></>}
                                                   onClick={() => {
                                                       setBottomSheet!(false);

                                                       SetJoinCompanyModal(appDataContext, {
                                                           open: true,
                                                       });
                                                   }}
                                      ></AppListItem>
                                  </Box>
                              }
            />
        </>;

    return (
        <>
            <EventSystemListeners
                companyId={companyId || 0}
                refetch={refetch}
            />

            {contentJSX}
        </>
    );
}

interface IEventSystemListenersProps {
    refetch: VoidFunction;
    companyId: number;
}

/**
 * Component for EventSystem listeners.
 */
function EventSystemListeners(props: IEventSystemListenersProps) {
    const {refetch, companyId} = props;

    useEffect(() => {
        if (companyId) {
            const eventSystemListener: IEventSystemListener = {
                topic: kTopicCompanies,
                callback: (notifications: IEventSystemNotification[]) => {
                    const isDelete = notifications.some(notification => {
                        return notification.action === kActionDelete && notification.companyId === companyId;
                    });

                    if (isDelete) {
                        return;
                    }

                    const update = notifications.some(notification => {
                        return notification.data.id == companyId;
                    });

                    if (update) {
                        refetch();
                    }
                },
            };

            listenToEventSystem(eventSystemListener);

            return () => {
                unListenToEventSystem(eventSystemListener);
            };
        }
    }, [companyId, refetch]);

    return null;
}
