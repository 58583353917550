import {VisitStatus, VisitStatusModifier} from "../../../../../../generated/graphql/graphql";
import React, {ReactNode, useContext} from "react";
import {Box, Stepper, Theme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../../../../styles/AppThemeProcessor";
import {
    getVisitStatusIcon,
    getVisitStatusModifierColor,
    getVisitStatusModifierIcon,
    getVisitStatusModifierTitle,
    getVisitStatusTextColor,
    getVisitStatusTitle
} from "../../../../../../service/VisitService";
import {AppDataContext} from "../../../../../../AppData";
import Icons8LockOutlined from "../../../../../../icons/Icons8LockOutlined";
import AppChip from "../../../../chips/AppChip";
import {tt} from "../../../../../../core/Localization";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import {usePopupState} from "material-ui-popup-state/hooks";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ChevronDownIcon from "../../../../../../icons/ChevronDownIcon";
import SingleStepWidget from "./SingleStepWidget";


export const useStyles = makeStyles()((theme: Theme) => ({
    stepper: {
        '.MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
            marginTop: 4,
        },
        '.MuiStepConnector-line': {
            border: "none",
            height: 2,
            opacity: 0.5,
        },
        '.first .MuiStepConnector-horizontal': {
            display: "none",
        },
        '.MuiStep-root': {
            minHeight: 75,
        },
        '.MuiStepLabel-labelContainer .MuiStepLabel-label': {
            position: "absolute",
            top: 30,
            left: 0,
            right: 0,
        }
    },
    labelContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        '.MuiBox-root': {
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: 4,
        }
    },
    switchOffset: {
        marginTop: -18,
        '.MuiFormControlLabel-root .MuiTypography-root': {
            textTransform: "none",
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
            fontWeight: 500,
        }
    },
    desktop: {
        "@media (max-width: 767px)": {
            display: "none"
        }
    },
    mobile: {
        display: "none",
        "@media (max-width: 767px)": {
            display: "block"
        }
    },
    chipRow: {
        display: "flex",
        alignItems: "center",
        padding: 0 + ' !important',
        'span': {
            position: "relative",
            top: 1,
            left: 4,
        }
    },
    smallerChip: {
        fontSize: 12,
        height: 26,
    },
    chipOffset: {
        marginTop: -4
    }
}));


export interface IVisitDetailStatusModifierSectionProps {
    updateVisitStatusModifier: Function;
    mobileWidget: ReactNode;
    statusModifier: VisitStatusModifier | undefined | null;
    closeVisitSwitch: ReactNode;
    invoiceSwitch: ReactNode;
}

export default function VisitDetailStatusModifierSection(props: IVisitDetailStatusModifierSectionProps) {
    const {
        updateVisitStatusModifier,
        mobileWidget,
        statusModifier,
        closeVisitSwitch,
        invoiceSwitch,
    } = props;

    const {classes} = useStyles();

    const appDataContext = useContext(AppDataContext);
    const {darkMode} = appDataContext;
    let completedItemIndex = 1;
    let firstPartColor = getVisitStatusTextColor(VisitStatus.Closed, darkMode);
    let secondPartColor = getVisitStatusTextColor(VisitStatus.Done, darkMode);

    let paymentMenuText = tt('visitDetail.screen.statusWidget.menu.label.payment');

    if (statusModifier == VisitStatusModifier.Paid) {
        firstPartColor = getVisitStatusTextColor(VisitStatus.Done, darkMode);
        completedItemIndex = 3;
        paymentMenuText = getVisitStatusModifierTitle(VisitStatusModifier.Paid);
    } else if (statusModifier == VisitStatusModifier.NotPaid) {
        firstPartColor = getVisitStatusTextColor(VisitStatus.Done, darkMode);
        secondPartColor = getVisitStatusModifierColor(statusModifier!);
        paymentMenuText = getVisitStatusModifierTitle(VisitStatusModifier.NotPaid);
        completedItemIndex = 3;
    } else if (statusModifier == VisitStatusModifier.Invoiced) {
        firstPartColor = getVisitStatusModifierColor(statusModifier!);
        completedItemIndex = 2;
    }

    const step0JSX = <SingleStepWidget
        color={firstPartColor}
        index={0}
        completedItemIndex={completedItemIndex}
        icon={getVisitStatusIcon(VisitStatus.Done)}
        label={getVisitStatusTitle(VisitStatus.Done)}
    />;

    const step1JSX = <SingleStepWidget
        color={firstPartColor}
        index={1}
        completedItemIndex={completedItemIndex}
        icon={<Icons8LockOutlined/>}
        labelWidget={<Box className={classes.switchOffset}>{closeVisitSwitch}</Box>}
    />;

    const step2JSX = <SingleStepWidget
        color={firstPartColor}
        index={2}
        completedItemIndex={completedItemIndex}
        icon={getVisitStatusModifierIcon(VisitStatusModifier.Invoiced)}
        labelWidget={<Box className={classes.switchOffset}>{invoiceSwitch}</Box>}
    />;

    const paymentPopupState = usePopupState({
        variant: 'popover',
        popupId: 'popup-payment',
    });

    const step3JSX = <SingleStepWidget
        color={secondPartColor}
        index={3}
        completedItemIndex={completedItemIndex}
        icon={statusModifier === VisitStatusModifier.NotPaid ? getVisitStatusModifierIcon(VisitStatusModifier.NotPaid) : getVisitStatusModifierIcon(VisitStatusModifier.Paid)}
        label={completedItemIndex < 2 ? tt('visitDetail.screen.statusWidget.inactivePaymentItem.label.paid') : null}
        labelWidget={
            completedItemIndex < 2 ? null :
                <Box className={classes.chipOffset}>{
                    <>
                        <AppChip
                            className={classes.smallerChip}
                            chipstyle={'secondary'}
                            label={<Box className={classes.chipRow}>{paymentMenuText} <ChevronDownIcon/></Box>}
                            {...bindTrigger(paymentPopupState)}
                        />

                        <Menu {...bindMenu(paymentPopupState)}>
                            <MenuItem key={'menuItem1'} onClick={() => {
                                paymentPopupState.close();
                                updateVisitStatusModifier(VisitStatusModifier.Invoiced);
                            }}>
                                {tt('visitDetail.screen.statusWidget.menu.label.payment')}
                            </MenuItem>
                            <MenuItem key={'menuItem2'} onClick={() => {
                                paymentPopupState.close();
                                updateVisitStatusModifier(VisitStatusModifier.Paid);
                            }}>
                                {getVisitStatusModifierTitle(VisitStatusModifier.Paid)}
                            </MenuItem>
                            <MenuItem key={'menuItem3'} onClick={() => {
                                paymentPopupState.close();
                                updateVisitStatusModifier(VisitStatusModifier.NotPaid);
                            }}>
                                {getVisitStatusModifierTitle(VisitStatusModifier.NotPaid)}
                            </MenuItem>
                        </Menu>
                    </>
                }</Box>}
    />;

    return (<>
        <Box className={classes.desktop}>
            <Box sx={{width: '100%'}} pt={1}>
                <Stepper
                    className={classes.stepper}
                    activeStep={completedItemIndex}
                    alternativeLabel
                >
                    {step0JSX}
                    {step1JSX}
                    {step2JSX}
                    {step3JSX}
                </Stepper>
            </Box>
        </Box>
        <Box className={classes.mobile}>
            {mobileWidget}
        </Box>
    </>);
}