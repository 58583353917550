import LocalizedStrings from 'react-localization';
import {Dispatch, SetStateAction} from "react";
import { enUS, skSK, csCZ, deDE, ukUA, ruRU } from '@mui/material/locale';

export const kStorageLanguage = 'localization_language';

export const kDefaultLanguage = 'en';
export const kSupportedLanguages = ['en', 'sk', 'cs', 'de', 'uk', 'ru'];
export const localizedStrings = new LocalizedStrings({
    en: require('../assets/translations/en.json'),
    sk: require('../assets/translations/sk.json'),
    cs: require('../assets/translations/cs.json'),
    de: require('../assets/translations/de.json'),
    uk: require('../assets/translations/uk.json'),
    ru: require('../assets/translations/ru.json'),
});
export const localizationForTheme = {
    en: enUS,
    sk: skSK,
    cs: csCZ,
    de: deDE,
    uk: ukUA,
    ru: ruRU,
};

// Include localizations for components dependant on dayjs
require('dayjs/locale/en');
require('dayjs/locale/sk');
require('dayjs/locale/cs');
require('dayjs/locale/de');
require('dayjs/locale/uk');
require('dayjs/locale/ru');

/**
 * Initialize language.
 */
export function InitLanguage(setLanguage: Dispatch<SetStateAction<string>>) {
    const storedLanguage = localStorage.getItem(kStorageLanguage);

    if (storedLanguage && kSupportedLanguages.includes(storedLanguage)) {
        localizedStrings.setLanguage(storedLanguage);

        setLanguage(storedLanguage);

        SetHtmlLang(storedLanguage);
    } else {
        const browserLanguage = localizedStrings.getLanguage();

        setLanguage(browserLanguage);

        SetHtmlLang(browserLanguage);
    }
}

/**
 * Change language to different supported and save to WIP.
 */
export function ChangeLanguage(language: string, setLanguage: Dispatch<SetStateAction<string>>) {
    if (kSupportedLanguages.includes(language)) {
        localizedStrings.setLanguage(language);

        localStorage.setItem(kStorageLanguage, language);

        setLanguage(language);

        SetHtmlLang(language);
    }
}

/**
 * Shorthand for translations and to make JsTrions analysis easier to set up.
 */
export function tt(key: string) {
    return localizedStrings[key] || key;
}

/**
 * Set html lang attribute.
 */
export function SetHtmlLang(language: string) {
    document.documentElement.lang = language;
}
