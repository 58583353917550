import {IMapyCZAutocompleteField} from "./InputProps";
import React, {useState} from "react";
import {Autocomplete, TextField, Theme} from "@mui/material";
import {AddressesForTermsMapyCZ, IAddressesForTermsMapyCZResult} from "../../../service/MapsService";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {useStyles as formUseStyles} from "./FormBuilder";
import {FilterOptionsState} from "@mui/base/useAutocomplete/useAutocomplete";

const useStyles = makeStyles()((theme: Theme) => ({
    suffix: {
        marginLeft: theme.spacing(2),
    },
    icon: {
        color: kAppColors.red.confirmButton,
        'svg': {height: 21, width: 21}
    },
    suffixContainer: {
        display: "flex"
    },
    input: {
        '.MuiInputBase-formControl': {
            paddingTop: 0,
            paddingRight: 12 + 'px !important',
            paddingBottom: 0,
            paddingLeft: 0,
        },
        '.MuiInputBase-input': {
            display: "block",
            paddingTop: 25 + 'px !important',
            paddingBottom: 8 + 'px !important',
            paddingLeft: 12 + 'px !important',
        },
    },
    paper: {
        backgroundColor: kAppColors.background.menuPaper(theme.palette.mode === "dark") + ' !important',
        borderRadius: 10,
        backgroundImage: "unset",
    }
}));

declare global {
    interface Window {
        L: any;
    }
}

/**
 * Form component for input with MapyCZ autocomplete.
 */
export default function MapyCZAutocompleteField(props: IMapyCZAutocompleteField) {
    const {
        index,
        className,
        label,
        value,
        updateValue,
        updateProp,
        hidden,
        error,
        errorMessage,
        helperText,
        disabled,
        testId,
        placeholder,
    } = props;

    const {classes: formClasses, cx} = formUseStyles();
    const {classes} = useStyles();

    const [options, setOptions] = useState<IAddressesForTermsMapyCZResult[]>([]);

    /**
     * Search Google api for data.
     */
    const Search = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const terms = e.target.value.trim();

        updateValue(index, terms);

        if (!terms) {
            setOptions([]);
            return;
        }

        const addresses = await AddressesForTermsMapyCZ(terms);

        if (terms === e.target.value.trim()) {
            setOptions(addresses);
        }
    };

    /**
     * OnChange update data of this field.
     */
    const OnChangeField = (e: React.SyntheticEvent, value: any) => {
        const selectedOption = options.find((option) => option.description === value);

        if (selectedOption) {
            updateValue(index, selectedOption.description);
        } else {
            updateValue(index, value);
        }
    };

    const helperTextJSX = error ? errorMessage : helperText;

    return (
        <div className={cx(className, (hidden ? formClasses.hidden : undefined))}>
            <Autocomplete
                disabled={disabled}
                data-testid={testId}
                componentsProps={{
                    paper: {
                        className: classes.paper,
                    },
                }}
                disablePortal={true}
                freeSolo={true}
                value={value}
                options={options.map((option) => option.description)}
                filterOptions={(options, state) => options}
                renderInput={(params) => {
                    return (
                        <TextField {...params} className={classes.input} label={label} variant="filled" error={error}
                                   helperText={helperTextJSX} fullWidth={true} margin="none"
                                   onChange={Search}
                                   InputProps={params.InputProps}
                                   placeholder={placeholder}
                        />
                    );
                }}
                onChange={OnChangeField}
            />
        </div>
    );
}
