import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8PowerOffButtonProps {
    className?: string;
    size?: number;
}

export default function Icons8PowerOffButton(props: IIcons8PowerOffButtonProps) {
    const {className, size} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName} style={{width: size, height: size}}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 24 4 C 12.972066 4 4 12.972074 4 24 C 4 35.027926 12.972066 44 24 44 C 35.027934 44 44 35.027926 44 24 C 44 12.972074 35.027934 4 24 4 z M 24 7 C 33.406615 7 41 14.593391 41 24 C 41 33.406609 33.406615 41 24 41 C 14.593385 41 7 33.406609 7 24 C 7 14.593391 14.593385 7 24 7 z M 23.976562 9.9785156 A 1.50015 1.50015 0 0 0 22.5 11.5 L 22.5 23.5 A 1.50015 1.50015 0 1 0 25.5 23.5 L 25.5 11.5 A 1.50015 1.50015 0 0 0 23.976562 9.9785156 z M 29.503906 12.384766 A 1.50015 1.50015 0 0 0 28.78125 15.214844 C 31.888733 16.909834 34 20.19732 34 24 C 34 29.540262 29.540262 34 24 34 C 18.459738 34 14 29.540262 14 24 C 14 20.19732 16.110785 16.910967 19.21875 15.214844 A 1.50015 1.50015 0 0 0 18.453125 12.388672 A 1.50015 1.50015 0 0 0 17.78125 12.582031 C 13.739215 14.787908 11 19.08468 11 24 C 11 31.161738 16.838262 37 24 37 C 31.161738 37 37 31.161738 37 24 C 37 19.08468 34.261267 14.787041 30.21875 12.582031 A 1.50015 1.50015 0 0 0 29.503906 12.384766 z"/>
            </svg>
        </span>
    );
}













