import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import FullSizedContainer from "../../components/FullSizedContainer";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {tt} from "../../../core/Localization";
import AppButton from "../../components/buttons/AppButton";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../components/form/FormBuilder";
import {kSignInRoute} from "./SignInScreen";
import LanguagesMenu from "../../components/menus/LanguagesMenu";
import ContentPadding from "../../components/paper/ContentPadding";
import LogoWithText from "../../components/LogoWithText";
import {kContentWidthNarrow} from "../../../styles/AppThemeProcessor";
import {getAuth, sendPasswordResetEmail} from "firebase/auth";
import {AppDataContext} from "../../../AppData";
import {useNavigate} from "react-router-dom";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import DarkModeMenu from "../../components/menus/DarkModeMenu";


export const kForgottenPasswordRoute = '/forgotten-password';

export default function ForgottenPasswordScreen() {
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    useEffect(() => {
        setTitle(tt('forgottenPassword.screen.title'));
    }, []);


    return (
        <ResponsiveContainer
            smallPhoneScreen={<Body isMobile={true}/>}
            largePhoneScreen={<Body isMobile={true}/>}
            tabletScreen={<Body/>}
            smallDesktopScreen={<Body/>} largeDesktopScreen={<Body/>}
            extraLargeDesktopScreen={<Body/>}/>
    );
}


interface IBodyProps {
    isMobile?: boolean;
}

function Body(props: IBodyProps) {
    const {isMobile} = props;

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    const [inputs, setInputs] = useState<IInputsData>({
        email: {
            type: InputType.Text,
            label: `${tt('common.email')}*`,
            value: '',
            required: true,
            requireEmail: true,
        },
    });

    /**
     * Send forgotten password reset request fo Firebase Auth.
     */
    const ResetPassword = async () => {
        if (ValidateForm(inputs, setInputs)) {
            setLoading(true);
            try {
                const auth = getAuth();
                auth.languageCode = language;

                await sendPasswordResetEmail(auth, inputs.email.value);

                SuccessToast(tt('forgottenPassword.reset.success'));

                navigate(kSignInRoute);
            } catch (e: any) {
                setLoading(false);
                console.error(e);
                ErrorToast(tt('common.userNotFound.error'));
            }
        }
    };

    return (<FullSizedContainer topAlignedContent={isMobile}>
        <AppPaper
            showPaddingTop={true}
            sx={{maxWidth: isMobile ? undefined : kContentWidthNarrow}}>
            <PaperAppbar
                isMobile={isMobile}
                title={tt('forgottenPassword.screen.title')}
                children={<><LanguagesMenu/><DarkModeMenu/></>}
                backRoute={kSignInRoute}></PaperAppbar>
            <ContentPadding isNarrow={isMobile}>
                <LogoWithText description={tt('forgottenPassword.screen.description')}/>
                <FormBuilder inputs={inputs} setInputs={setInputs}/>

                <AppButton
                    isLoading={loading}
                    onClick={ResetPassword}
                    fullWidth={true}
                    variant="contained"
                >{tt('forgottenPassword.screen.renewPassword')}</AppButton>
            </ContentPadding>
        </AppPaper>
    </FullSizedContainer>);
}
