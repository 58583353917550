import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import {useNavigate, useParams} from "react-router-dom";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import PaperAppbar from "../../components/paper/PaperAppbar";
import ContentPadding from "../../components/paper/ContentPadding";
import {contactPersonDetailRoute, ContactPersonDetailRouteKind} from "./ContactPersonDetailScreen";
import ContactPersonForm from "../../components/contactPerson/ContactPersonForm";
import {IInputsData, ValidateForm} from "../../components/form/FormBuilder";
import {
    ClientContactProfileResponse,
    GetClientContactInput,
    UpdateClientContactDocument,
    UpdateClientContactMutation,
    UpdateClientContactMutationVariables
} from "../../../generated/graphql/graphql";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import IBreadcrumb from "../../../model/Breadcrumb";
import {kClientsBreadcrumb} from "../clients/ClientsScreen";
import {clientDetailRoute} from "../clients/ClientDetailScreen";
import {UserFullName} from "../../../service/UserService";
import {AppDataContext, SetAppBreadcrumbs} from "../../../AppData";
import AppBreadCrumb from "../../components/breadCrumb/AppBreadCrumb";
import {LocationNameDisplay} from "../../../service/LocationService";
import {locationDetailRoute} from "../locations/LocationDetailScreen";
import {PlaceDetailRoute} from "../places/PlaceDetailScreen";
import {processQueryError} from "../../../service/ErrorService";
import { FetchPolicy, RestApiClientContext } from "../../../core/RestApiProvider";

export const kContactPersonEditRoute = '/clients/:clientId/contact-person/:contactId/edit';
export const kContactPersonEditFromListRoute = '/clients/:clientId/contact-persons/:contactId/edit';
export const kContactPersonEditFromLocationRoute = '/clients/:clientId/locations/:locationId/contact-person/:contactId/edit';
export const kContactPersonEditFromPlaceRoute = '/clients/:clientId/locations/:locationId/places/:placeId/contact-person/:contactId/edit';

export enum ContactPersonEditRouteKind {
    default,
    fromList,
    fromLocation,
    fromPlace,
}

export function contactPersonEditRoute(
    clientId: string | number,
    contactId: string | number,
    kind?: ContactPersonEditRouteKind,
    locationId?: string | number,
    placeId?: string | number,
): string {
    if (kind === ContactPersonEditRouteKind.fromList) {
        return kContactPersonEditFromListRoute
            .replace(':contactId', `${contactId}`)
            .replace(':clientId', `${clientId}`);
    }

    if (kind === ContactPersonEditRouteKind.fromLocation) {
        if (!locationId) {
            throw Error('LocationId is required for route');
        }

        return kContactPersonEditFromLocationRoute
            .replace(':contactId', `${contactId}`)
            .replace(':clientId', `${clientId}`)
            .replace(':locationId', `${locationId}`);
    }

    if (kind === ContactPersonEditRouteKind.fromPlace) {
        if (!locationId) {
            throw Error('LocationId is required for route');
        }
        if (!placeId) {
            throw Error('PlaceId is required for route');
        }

        return kContactPersonEditFromPlaceRoute
            .replace(':contactId', `${contactId}`)
            .replace(':clientId', `${clientId}`)
            .replace(':locationId', `${locationId}`)
            .replace(':placeId', `${placeId}`);
    }

    return kContactPersonEditRoute
        .replace(':contactId', `${contactId}`)
        .replace(':clientId', `${clientId}`);
}

export default function ContactPersonEditScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;
    
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);

    const {contactId, clientId, locationId, placeId} = useParams();
    const navigate = useNavigate();

    let kindOfRoute = ContactPersonEditRouteKind.default;

    if (locationId && placeId && contactPersonEditRoute(clientId!, contactId!, ContactPersonEditRouteKind.fromPlace, locationId, placeId) === window.location.pathname) {
        kindOfRoute = ContactPersonEditRouteKind.fromPlace;
    } else if (locationId && contactPersonEditRoute(clientId!, contactId!, ContactPersonEditRouteKind.fromLocation, locationId) === window.location.pathname) {
        kindOfRoute = ContactPersonEditRouteKind.fromLocation;
    } else if (contactPersonEditRoute(clientId!, contactId!, ContactPersonEditRouteKind.fromList) === window.location.pathname) {
        kindOfRoute = ContactPersonEditRouteKind.fromList;
    }

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<ClientContactProfileResponse | NullOrUndefined>();
    useEffect(() => {
        if (contactId && clientId) {
            restApiGet({
                uri: '/client/contact-profile',
                params: {
                    clientContactId: parseInt(contactId!),
                    includeLocationId: locationId ? parseInt(locationId) : undefined,
                    includePlaceId: placeId ? parseInt(placeId) : undefined,
                } as GetClientContactInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading,
                onData: setData,
                onError: (error: any) => processQueryError(appDataContext, error),
            });
        } else {
            setLoading(false);
            setData(null);
        }
    }, [contactId, clientId, locationId, placeId]);

    const locationData = data?.location;
    const placeData = data?.locationPlace;

    const [mutateUpdate, {
        loading: updateLoading,
        error: updateError,
        data: updateData,
    }] = useResettableMutation<UpdateClientContactMutation, UpdateClientContactMutationVariables>(UpdateClientContactDocument);

    /**
     * Set breadcrumbs.
     */
    const SetBreadcrumbs = () => {
        const breadcrumbs: IBreadcrumb[] = [kClientsBreadcrumb];

        if (data) {
            breadcrumbs.push({
                key: 'client-detail',
                text: data!.client.name,
                route: clientDetailRoute(clientId!),
            });

            if ((kindOfRoute === ContactPersonEditRouteKind.fromLocation || kindOfRoute === ContactPersonEditRouteKind.fromPlace) && locationData) {
                breadcrumbs.push({
                    key: 'location-detail',
                    text: LocationNameDisplay(locationData.name, locationData.type, locationData.address),
                    route: locationDetailRoute(clientId!, locationId!),
                });
            }

            if (kindOfRoute === ContactPersonEditRouteKind.fromPlace && placeData) {
                breadcrumbs.push({
                    key: 'place-detail',
                    text: placeData.name,
                    route: PlaceDetailRoute(clientId!, locationId!, placeId!),
                });
            }

            let detailRoute = contactPersonDetailRoute(parseInt(clientId!), contactId!);

            if (kindOfRoute === ContactPersonEditRouteKind.fromPlace) {
                detailRoute = contactPersonDetailRoute(clientId!, contactId!, ContactPersonDetailRouteKind.fromPlace, locationId, placeId);
            } else if (kindOfRoute === ContactPersonEditRouteKind.fromLocation) {
                detailRoute = contactPersonDetailRoute(clientId!, contactId!, ContactPersonDetailRouteKind.fromLocation, locationId);
            } else if (kindOfRoute === ContactPersonEditRouteKind.fromList) {
                detailRoute = contactPersonDetailRoute(clientId!, contactId!, ContactPersonDetailRouteKind.fromList);
            }

            breadcrumbs.push({
                key: 'client-contact',
                text: UserFullName(data!.clientContact.name, data!.clientContact.surname),
                route: detailRoute,
            });

            breadcrumbs.push({
                key: 'client-contact-edit',
                text: tt('clientEdit.screen.title'),
                route: '',
            });
        }

        SetAppBreadcrumbs(appDataContext, breadcrumbs);
    };

    useEffect(() => {
        setTitle(tt('contactPersonEdit.screen.title'));

        SetBreadcrumbs();
    }, []);

    useEffect(() => {
        SetBreadcrumbs();
    }, [data]);

    const updateContactPerson = async (inputs: IInputsData, setInputs: Dispatch<SetStateAction<IInputsData>>) => {
        if (ValidateForm(inputs, setInputs)) {
            try {
                const variables: UpdateClientContactMutationVariables = {
                    input: {
                        clientContactId: parseInt(contactId!),
                        name: inputs.name.value,
                        surname: inputs.surname.value,
                        phoneNumber: inputs.phone.value,
                        role: inputs.role.value,
                    },
                };

                const result = await mutateUpdate({variables});

                if (!result.errors) {
                    navigate(contactPersonDetailRoute(clientId!, contactId!));

                    SuccessToast(tt('contactPersonEdit.screen.success'));
                }
            } catch (e) {
                console.error(e);

                ErrorToast(tt('common.mutation.error'));
            }
        }
    };


    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                kindOfRoute={kindOfRoute}
                loading={loading || updateLoading}
                data={data}
                isMobile={isMobile}
                onSubmit={updateContactPerson}
            />
        );
    }

    return (
        <ResponsiveContainer
            smallPhoneScreen={bodyJSX(true)}
            largePhoneScreen={bodyJSX(true)}
            tabletScreen={bodyJSX()}
            smallDesktopScreen={bodyJSX()}
            largeDesktopScreen={bodyJSX()}
            extraLargeDesktopScreen={bodyJSX()}
        />
    );
}

interface IBodyProps {
    kindOfRoute: ContactPersonEditRouteKind;
    loading: boolean;
    data?: ClientContactProfileResponse | NullOrUndefined;
    isMobile?: boolean;
    onSubmit: (inputs: IInputsData, setInputs: Dispatch<SetStateAction<IInputsData>>) => void;
}

function Body(props: IBodyProps) {
    const {
        kindOfRoute,
        loading,
        data,
        isMobile,
        onSubmit,
    } = props;

    const {contactId, clientId, locationId, placeId} = useParams();

    let backRoute = contactPersonDetailRoute(parseInt(clientId!), contactId!);

    if (kindOfRoute === ContactPersonEditRouteKind.fromPlace) {
        backRoute = contactPersonDetailRoute(clientId!, contactId!, ContactPersonDetailRouteKind.fromPlace, locationId, placeId);
    } else if (kindOfRoute === ContactPersonEditRouteKind.fromLocation) {
        backRoute = contactPersonDetailRoute(clientId!, contactId!, ContactPersonDetailRouteKind.fromLocation, locationId);
    } else if (kindOfRoute === ContactPersonEditRouteKind.fromList) {
        backRoute = contactPersonDetailRoute(clientId!, contactId!, ContactPersonDetailRouteKind.fromList);
    }

    return (
        <ScreenContent
            showBreadCrumb={true}
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}
            >
                <PaperAppbar isMobile={isMobile}
                             title={tt('contactPersonEdit.screen.title')}
                             backRoute={backRoute}
                             cancelIconBackButton={true}/>

                {isMobile ? <AppBreadCrumb/> : null}

                <ContentPadding>
                    <ContactPersonForm
                        isEdit={true}
                        phone={data?.clientContact.phoneNumber}
                        name={data?.clientContact.name}
                        surname={data?.clientContact.surname}
                        role={data?.clientContact.role}
                        onSubmit={onSubmit}
                        loading={loading}
                        loadingData={!data && loading}
                    />
                </ContentPadding>
            </AppPaper>
        </ScreenContent>
    );
}
