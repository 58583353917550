import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import AppButton from "../buttons/AppButton";
import {tt} from "../../../core/Localization";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Divider, Theme, Tooltip} from "@mui/material";
import GreyLabel from "../decorations/GreyLabel";
import AppIconButton from "../buttons/AppIconButton";
import ChevronDownIcon from "../../../icons/ChevronDownIcon";
import DeleteIcon from "../../../icons/DeleteIcon";
import Icons8MoveGrabber from "../../../icons/Icons8MoveGrabber";
import Icons8CheckedCheckbox from "../../../icons/Icons8CheckedCheckbox";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {JobFormElementFragment, JobFormElementType, JobFormResponse} from "../../../generated/graphql/graphql";
import {combineJobFormResponse} from "../../../service/JobFormService";
import {DragDropContext, DroppableProps, DropResult, ResponderProvided} from 'react-beautiful-dnd';
import {v4 as uuidv4} from 'uuid';
import Icons8MultilineText from "../../../icons/Icons8MultilineText";
import Icons8DropdownIcon from "../../../icons/Icons8Dropdown";
import Icons8Number from "../../../icons/Icons8Number";
import CalendarIcon from "../../../icons/CalendarIcon";
import Icons8TextOneLine from "../../../icons/Icons8TextOneLine";
import RadioButtonIcon from "../../../icons/RadioButtonOn";
import Icons8CheckAll from "../../../icons/Icons8CheckAll";
import PencilIcon from "../../../icons/PencilIcon";
import {AppDataContext} from "../../../AppData";
import EditFormSectionTitleModal from "./EditFormSectionTitleModal";
import ClockIcon from "../../../icons/ClockIcon";
import FormSwitch from "../form/FormSwitch";
import SplitButton from "../buttons/SplitButton";

export const useStyles = makeStyles()((theme: Theme) => ({
    hourlyRateInput: {
        'input': {
            textAlign: "end",
        }
    },
    bold: {
        fontWeight: 700,
    },
    submitRightContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    button: {
        "@media (max-width: 767px)": {
            flexGrow: 1,
        }
    },
    container: {
        maxHeight: '80vh',
        height: '490px',
        overflow: "auto",
    },
    divider: {
        marginLeft: -16,
        marginRight: -16,
        marginBottom: 8,
    },
    addSectionButton: {
        marginBottom: 8,
    },
    deleteIcon: {
        color: kAppColors.red.confirmButton,
    },
    toolsContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        marginBottom: 8,
        marginLeft: -8,
    },
    sectionHeader: {
        width: '100%',
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        paddingBottom: 8,
    },
    sectionLabel: {
        marginRight: 'auto',
    },
    chevronUp: {
        'svg': {
            transform: 'rotate(180deg)',
        }
    },
    addWidgetLabel: {
        textTransform: "uppercase",
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        marginRight: 8,
    },
    suffixContainer: {
        marginBottom: 20,
    },
    checkBoxPrefixContainer: {
        marginBottom: 20,
        marginLeft: -8,
        marginRight: 4,
    },
    prefixContainer: {
        marginLeft: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 42,
        height: 54,
        marginBottom: 20,
        svg: {
            color: kAppColors.grey.disabledInput(theme.palette.mode === "dark"),
        }
    },
    prefixCheckbox: {
        marginBottom: 20,
        marginLeft: 10,
    },
    optionsInput: {
        '&.MuiGrid-item': {
            marginLeft: 68,
        }
    },
    elementRequired: {
        display: "inline-flex",
        verticalAlign: "middle",
        marginBottom: 0,
    },
    submitRightSplitButton: {
        flexShrink: 1,
        width: "unset",
        marginLeft: "auto",
        "@media (max-width: 767px)": {
            flexShrink: 0,
            marginLeft: 0,
            flexGrow: 1,
            width: '100%',
        }
    }
}));

export interface ICreateAndEditJobFormFormProps {
    companyId: number;
    isTemplate: boolean;
    isEdit?: boolean;
    loading?: boolean;
    onChange: (jobForm: JobFormResponse) => void;
    onSubmit: (jobForm: JobFormResponse, saveTemplate?: boolean) => void;
    submitRight?: boolean;
    existingData?: JobFormResponse | NullOrUndefined;
    isDuplication?: boolean;
    loadingUpdate?: boolean;
    saveAsSingleOrTemplate?: boolean;
}

export default function (props: ICreateAndEditJobFormFormProps) {
    const {
        companyId,
        isTemplate,
        isEdit,
        onChange,
        onSubmit,
        loading,
        submitRight,
        existingData,
        isDuplication,
        loadingUpdate,
        saveAsSingleOrTemplate,
    } = props;

    const {classes, cx} = useStyles();

    const appDataContext = useContext(AppDataContext);

    const [inputs, setInputs] = useState<IInputsData>({});
    const [sections, setSections] = useState<string[]>([]);
    const [elements, setElements] = useState<Record<string, JobFormElementFragment[]>>({});
    const [droppables, setDroppables] = useState<Record<string, Partial<DroppableProps>>>({});
    const [invalidateForm, setInvalidateForm] = useState<boolean>(false);
    const [jobFormResponse, setJobFormResponse] = useState<JobFormResponse | null>(null);
    const [editSection, setEditSection] = useState<number | null>(null);
    const [sectionTitle, setSectionTitle] = useState<string>();

    useEffect(() => {
        FormData(
            {
                classes,
                inputs,
                setInputs,
                sections,
                setSections,
                elements,
                setElements,
                invalidateForm,
                setInvalidateForm,
                droppables,
                setDroppables,
                editSectionTitle,
            },
        );
    }, [invalidateForm]);

    useEffect(() => {
        if (existingData) {
            const {name, sections, elements} = existingData;

            const newElements: Record<string, JobFormElementFragment[]> = {};

            const theName = isDuplication ? `${name || ''} ${tt('createAndEditJobForm.name.duplicate')}` : name || '';

            const newInputs: IInputsData = {
                name: {
                    type: InputType.Text,
                    label: `${tt('common.name')}*`,
                    value: theName,
                    required: true,
                },
            };

            for (let i = 0; i < sections.length; i++) {
                newInputs[`section_${i}_name`] = {
                    type: InputType.Text,
                    label: `${tt('common.sectionName')}*`,
                    value: sections[i] || '',
                    required: true,
                };

                let sectionElements = elements.filter((element) => element.sectionIndex === i)
                    .sort((a, b) => a.position - b.position);
                newElements[i] = [];

                while (sectionElements.length > 0) {
                    let element = {...sectionElements.shift()!};

                    if (element.groupId) {
                        const groupElements = elements.filter((item) => item.groupId == element.groupId);

                        const mainElement = groupElements.find((item) => !element.options.includes(item.label));

                        element = {...(mainElement || element)};
                    }

                    delete element.__typename;

                    if (isDuplication) {
                        delete element.id;
                        delete element.uuid;
                        delete element.createdAt;
                    }

                    newElements[i].push(element);

                    if (element.groupId) {
                        sectionElements = sectionElements.filter((item) => item.groupId != element.groupId);
                    }
                }

                for (let j = 0; j < sectionElements.length; j++) {
                    newInputs[`section_${i}_element_${j}_name`] = {
                        type: InputType.Text,
                        label: `${tt('common.checkbox')}*`,
                        value: sectionElements[j].label || '',
                        required: true,
                    };
                }
            }

            setSections(sections.map((section) => section));

            setElements(newElements);

            setInputs(newInputs);

            setInvalidateForm(!invalidateForm);
        }
    }, [existingData]);

    useEffect(() => {
        const newJobFormResponse = combineJobFormResponse({
            companyId,
            isTemplate,
            sections,
            elements,
            inputs,
            existingData,
            isDuplication,
        });

        setJobFormResponse(newJobFormResponse);

        onChange(newJobFormResponse);
    }, [inputs, sections, elements, invalidateForm]);

    /**
     * On drag and drop we reorder elements in section.
     */
    const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
        const droppableId = result.destination!.droppableId!;
        const oldIndex = result.source.index;
        const newIndex = result.destination!.index;

        const sectionIndex = Object.keys(droppables).indexOf(droppableId);
        const newElements = {...elements};

        const sectionElements = newElements[sectionIndex];

        const [removed] = sectionElements.splice(oldIndex, 1);

        sectionElements.splice(newIndex, 0, removed);

        for (let i = 0; i < sectionElements.length; i++) {
            sectionElements[i].position = i;
        }

        const newInputs = {...inputs};
        const newInputs2 = {...inputs};
        const keys = Object.keys(newInputs);
        const keysForIndexInSection: Record<number, string[]> = {};

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const keyParts = key.split('_');

            if (keyParts.length > 5) {
                const lastParts = keyParts.slice(4);

                keyParts[4] = lastParts.join('_');
            }

            const elementSectionIndex = parseInt(keyParts[1]);
            const elementElementIndex = parseInt(keyParts[3]);

            if (elementSectionIndex === sectionIndex && !isNaN(elementElementIndex)) {
                if (!keysForIndexInSection[elementElementIndex]) {
                    keysForIndexInSection[elementElementIndex] = [];
                }

                keysForIndexInSection[elementElementIndex].push(key);
            }
        }

        const keysForIndexInSectionAsArray: Array<string[]> = Object.values(keysForIndexInSection);

        const [removedKeys] = keysForIndexInSectionAsArray.splice(oldIndex, 1);

        keysForIndexInSectionAsArray.splice(newIndex, 0, removedKeys);

        for (let i = 0; i < keysForIndexInSectionAsArray.length; i++) {
            const keysForIndex = keysForIndexInSectionAsArray[i];

            for (const keysForIndexOf of keysForIndex) {
                const key = keysForIndexOf;
                const keyParts = key.split('_');

                keyParts[3] = i.toString();
                const newKey = keyParts.join('_');

                newInputs[newKey] = newInputs2[key];
            }
        }

        setElements(newElements);

        setInputs(newInputs);

        setDroppables({});

        setInvalidateForm(!invalidateForm);
    };

    /**
     * Submit form to callback with required data.
     */
    const SubmitForm = (saveTemplate?: boolean) => {
        if (ValidateForm(inputs, setInputs)) {
            const newJobFormResponse = combineJobFormResponse({
                companyId,
                isTemplate,
                sections,
                elements,
                inputs,
                existingData,
                isDuplication,
            });

            onSubmit(newJobFormResponse, saveTemplate);
        }
    };

    const editSectionTitle = (index: number, currentValue: string) => {
        setSectionTitle(currentValue);

        setEditSection(index);
    };

    const saveSectionTitle = (newValue: string) => {
        const newSections = [];

        for (let i = 0; i < sections.length; i++) {
            if (i === editSection) {
                newSections.push(newValue);
            } else {
                newSections.push(sections[i]);
            }
        }

        setSections(newSections);

        setInputs(prev => {
            return {
                ...prev,
                [`section_${editSection}_name`]: {
                    ...prev[`section_${editSection}_name`],
                    value: newValue || '',
                }
            };
        });

        setEditSection(null);

        setInvalidateForm(!invalidateForm);
    };

    let submitJSX;
    if (saveAsSingleOrTemplate) {
        submitJSX = (
            <SplitButton
                className={cx(classes.button, classes.submitRightSplitButton)}
                isInModal={true}
                disabled={loading}
                isLoading={loadingUpdate}
                onClick={() => SubmitForm()}
                onChange={() => SubmitForm(true)}
                text={isEdit ? tt('common.save') : tt('common.create')}
                options={[
                    tt('createAndEditJobForm.createAndSaveTemplate'),
                ]}
            />
        );

        submitJSX = (
            <div className={classes.submitRightContainer}>
                {submitJSX}
            </div>
        );
    } else {
        submitJSX = (
            <AppButton
                testId={'workerProfileFormSubmitButton'}
                className={classes.button}
                variant={"contained"}
                fullWidth={!submitRight}
                isLoading={loadingUpdate}
                onClick={() => SubmitForm()}
                disabled={loading}>
                {isEdit ? tt('common.save') : tt('common.create')}
            </AppButton>
        );

        submitJSX = !submitRight ? submitJSX : (
            <div className={classes.submitRightContainer}>
                {submitJSX}
            </div>
        );
    }

    return (
        <>
            <DragDropContext
                onDragEnd={onDragEnd}
            >
                <FormBuilder inputs={inputs} setInputs={setInputs} droppables={droppables}/>
            </DragDropContext>

            {submitJSX}

            <EditFormSectionTitleModal
                open={editSection !== null}
                setOpen={() => setEditSection(null)}
                title={sectionTitle || ''}
                onSubmit={saveSectionTitle}
            />
        </>
    );
}

interface IFormDataProps {
    classes: Record<string, string>;
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    sections: string[];
    setSections: Dispatch<SetStateAction<string[]>>;
    elements: Record<string, JobFormElementFragment[]>;
    setElements: Dispatch<SetStateAction<Record<string, JobFormElementFragment[]>>>;
    invalidateForm: boolean;
    setInvalidateForm: React.Dispatch<React.SetStateAction<boolean>>;
    droppables: Record<string, Partial<DroppableProps>>;
    setDroppables: Dispatch<Record<string, Partial<DroppableProps>>>;
    editSectionTitle: (index: number, currentValue: string) => void;
}

/**
 * Form data based on state.
 */
function FormData(props: IFormDataProps) {
    const {
        classes,
        inputs,
        setInputs,
        sections,
        setSections,
        elements,
        setElements,
        invalidateForm,
        setInvalidateForm,
        droppables,
        setDroppables,
        editSectionTitle,
    } = props;

    if (sections.length === 0) {
        setSections(['']);

        setInvalidateForm(!invalidateForm);

        return;
    }

    /**
     * Add new section.
     */
    const AddSection = () => {
        const newSections = [...sections, ''];

        setSections(newSections);

        setInvalidateForm(!invalidateForm);
    };

    /**
     * Remove section for index.
     */
    const RemoveSection = (inputs: IInputsData, index: number) => {
        const newSections = [...sections];

        newSections.splice(index, 1);

        const newInputs = {...inputs};
        const keys = Object.keys(newInputs);

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const keyParts = key.split('_');

            const sectionIndex = parseInt(keyParts[1]);

            if (keyParts.length > 3) {
                const lastParts = keyParts.slice(2);

                keyParts[2] = lastParts.join('_');
            }

            if (sectionIndex === index) {
                delete newInputs[key];
            } else if (sectionIndex > index) {
                const newKey = `${keyParts[0]}_${sectionIndex - 1}_${keyParts[2]}`;

                newInputs[newKey] = newInputs[key];

                delete newInputs[key];
            }
        }

        const addSectionButton = {...newInputs.addSectionButton};
        delete newInputs.addSectionButton;
        newInputs.addSectionButton = addSectionButton;

        const newElements = {...elements};
        const elementKeys = Object.keys(newElements);

        for (let i = 0; i < elementKeys.length; i++) {
            const key = elementKeys[i];

            const sectionIndex = parseInt(key);

            if (sectionIndex === index) {
                delete newElements[key];
            } else if (sectionIndex > index) {
                const newKey = sectionIndex - 1;

                newElements[newKey] = newElements[key];
                for (let j = 0; j < newElements[newKey].length; j++) {
                    newElements[newKey][j].sectionIndex = newKey;
                }

                delete newElements[key];
            }
        }

        setSections(newSections);

        setInputs(newInputs);

        setElements(newElements);

        setInvalidateForm(!invalidateForm);
    };

    /**
     * Move section up or down by one position.
     */
    const MoveSection = (inputs: IInputsData, index: number, direction: 'up' | 'down') => {
        const newSections = [...sections];
        let newIndex = 0;

        if (direction === 'up') {
            newIndex = index - 1;

            if (newIndex < 0) {
                return;
            }

            const tmp = newSections[newIndex];

            newSections[newIndex] = newSections[index];
            newSections[index] = tmp;
        } else {
            newIndex = index + 1;

            if (newIndex >= newSections.length) {
                return;
            }

            const tmp = newSections[newIndex];

            newSections[newIndex] = newSections[index];
            newSections[index] = tmp;
        }

        const newInputs = {...inputs};
        const newInputs2 = {...inputs};
        const keys = Object.keys(newInputs);

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const keyParts = key.split('_');

            const sectionIndex = parseInt(keyParts[1]);

            if (sectionIndex === index) {
                delete newInputs[key];
            } else if (sectionIndex === newIndex) {
                delete newInputs[key];
            }
        }

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const keyParts = key.split('_');

            const sectionIndex = parseInt(keyParts[1]);

            if (keyParts.length > 3) {
                const lastParts = keyParts.slice(2);

                keyParts[2] = lastParts.join('_');
            }

            if (sectionIndex === index) {
                const newKey = `${keyParts[0]}_${newIndex}_${keyParts[2]}`;

                newInputs[newKey] = newInputs2[key];
            } else if (sectionIndex === newIndex) {
                const newKey = `${keyParts[0]}_${index}_${keyParts[2]}`;

                newInputs[newKey] = newInputs2[key];
            }
        }

        const addSectionButton = {...newInputs.addSectionButton};
        delete newInputs.addSectionButton;
        newInputs.addSectionButton = addSectionButton;

        const newElements = {...elements};
        const newElements2 = {...elements};
        const elementKeys = Object.keys(newElements);

        for (let i = 0; i < elementKeys.length; i++) {
            const key = elementKeys[i];

            const sectionIndex = parseInt(key);

            if (sectionIndex === index) {
                delete newElements[key];
            } else if (sectionIndex === newIndex) {
                delete newElements[key];
            }
        }

        for (let i = 0; i < elementKeys.length; i++) {
            const key = elementKeys[i];

            const sectionIndex = parseInt(key);

            if (sectionIndex === index) {
                const newKey = newIndex;

                newElements[newKey] = newElements2[key];
                for (let j = 0; j < newElements[newKey].length; j++) {
                    newElements[newKey][j].sectionIndex = newKey;
                }
            } else if (sectionIndex === newIndex) {
                const newKey = index;

                newElements[newKey] = newElements2[key];
                for (let j = 0; j < newElements[newKey].length; j++) {
                    newElements[newKey][j].sectionIndex = newKey;
                }
            }
        }

        setSections(newSections);

        setInputs(newInputs);

        setElements(newElements);

        setInvalidateForm(!invalidateForm);
    };

    /**
     * Add new element to section for index.
     */
    const AddElement = (index: number, type: JobFormElementType, groupId?: string) => {
        const newElements = {...elements};

        newElements[index] = newElements[index] || [];

        newElements[index].push({
            sectionIndex: index,
            label: '',
            type,
            position: newElements[index].length,
            done: false,
            options: [],
            groupId,
            required: false,
        });

        setElements(newElements);

        setInvalidateForm(!invalidateForm);
    };

    /**
     * Remove element from section.
     */
    const RemoveElement = (inputs: IInputsData, sectionIndex: number, elementIndex: number) => {
        const newElements = {...elements};

        newElements[sectionIndex].splice(elementIndex, 1);

        for (let i = 0; i < newElements[sectionIndex].length; i++) {
            newElements[sectionIndex][i].position = i;
        }

        const newInputs = {...inputs};
        const keys = Object.keys(newInputs);

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const keyParts = key.split('_');

            if (keyParts.length > 5) {
                const lastParts = keyParts.slice(4);

                keyParts[4] = lastParts.join('_');
            }

            const elementSectionIndex = parseInt(keyParts[1]);
            const elementElementIndex = parseInt(keyParts[3]);

            if (elementSectionIndex === sectionIndex && elementElementIndex === elementIndex) {
                delete newInputs[key];
            } else if (elementSectionIndex === sectionIndex && elementElementIndex > elementIndex) {
                const newKey = `${keyParts[0]}_${sectionIndex}_${keyParts[2]}_${elementElementIndex - 1}_${keyParts[4]}`;

                newInputs[newKey] = newInputs[key];

                delete newInputs[key];
            }
        }

        setElements(newElements);

        setInputs(newInputs);

        setInvalidateForm(!invalidateForm);
    };

    const newInputs: IInputsData = {
        name: {
            type: InputType.Text,
            label: `${tt('common.name')}*`,
            value: inputs.name?.value || '',
            required: true,
        },
        divider: {
            type: InputType.CustomRenderer,
            label: '',
            value: '',
            render: () => (
                <Divider className={classes.divider}/>
            ),
        },
    };

    const newDroppables: Record<string, Partial<DroppableProps>> = {};

    for (let i = 0; i < sections.length; i++) {
        const sectionName = sections[i];
        const sectionElements = elements[i] || [];

        const droppableId = `${sectionName}_${i}_${uuidv4()}`;

        newDroppables[droppableId] = {
            droppableId,
            type: `section_${i}`,
        };

        newInputs[`section_${i}_header`] = {
            type: InputType.CustomRenderer,
            label: '',
            value: '',
            render: () => (
                <Box className={classes.sectionHeader}>
                    <GreyLabel className={classes.sectionLabel}
                               text={sectionName ? sectionName : tt('newJobForm.modal.sectionLabel')}/>

                    <AppIconButton
                        tooltip={tt('formEdit.sectionHeader.button.down')}
                        onClick={() => MoveSection(newInputs, i, 'down')}>
                        <ChevronDownIcon/>
                    </AppIconButton>

                    <AppIconButton
                        tooltip={tt('formEdit.sectionHeader.button.up')}
                        onClick={() => MoveSection(newInputs, i, 'up')}>
                        <ChevronDownIcon className={classes.chevronUp}/>
                    </AppIconButton>

                    <AppIconButton
                        tooltip={tt('formEdit.sectionHeader.button.editSectionTitle')}
                        onClick={() => {
                            editSectionTitle(i, sectionName);
                        }}>
                        <PencilIcon/>
                    </AppIconButton>

                    {i > 0 ? (
                        <AppIconButton
                            tooltip={tt('formEdit.sectionHeader.button.deleteSection')}
                            onClick={() => RemoveSection(newInputs, i)}>
                            <DeleteIcon className={classes.deleteIcon}/>
                        </AppIconButton>
                    ) : undefined}
                </Box>
            ),
        };

        newInputs[`section_${i}_name`] = {
            type: InputType.Text,
            label: `${tt('common.sectionName')}*`,
            value: inputs[`section_${i}_name`]?.value || sectionName || '',
            hidden: true,
        };

        for (let j = 0; j < sectionElements.length; j++) {
            const sectionElement = sectionElements[j];

            switch (sectionElement.type) {
                case JobFormElementType.Checkbox:
                    newInputs[`section_${i}_element_${j}_name`] = {
                        type: InputType.Text,
                        label: `${tt('common.checkbox')}*`,
                        value: inputs[`section_${i}_element_${j}_name`]?.value || sectionElement?.label || '',
                        suffixJSX: () => (
                            <Box className={classes.suffixContainer}>
                                <ToggleRequiredElement
                                    i={i}
                                    j={j}
                                    sectionElement={sectionElement}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    elements={elements}
                                    setElements={setElements}
                                    invalidateForm={invalidateForm}
                                    setInvalidateForm={setInvalidateForm}
                                />

                                <AppIconButton onClick={() => {
                                }}>
                                    <Icons8MoveGrabber/>
                                </AppIconButton>

                                <AppIconButton onClick={() => RemoveElement(newInputs, i, j)}>
                                    <DeleteIcon className={classes.deleteIcon}/>
                                </AppIconButton>
                            </Box>
                        ),
                        prefixJSX: sectionElement.groupId ? (
                            <Box className={classes.prefixContainer}>
                                <Icons8CheckAll/>
                            </Box>
                        ) : (
                            <Box className={classes.prefixContainer}>
                                <Icons8CheckedCheckbox/>
                            </Box>
                        ),
                        required: true,
                        draggable: {
                            index: j,
                            draggableId: `section_${i}_element_${j}_name`,
                            droppableId
                        },
                    };

                    if (sectionElement.groupId) {
                        newInputs[`section_${i}_element_${j}_options`] = {
                            type: InputType.ChipAutocomplete,
                            label: `${tt('jobForm.element.options.label')}*`,
                            value: inputs[`section_${i}_element_${j}_options`]?.value || sectionElement?.options.map(option => ({
                                label: option,
                                value: option,
                            })) || [],
                            options: [],
                            freeSolo: true,
                            uniqueValues: true,
                            placeholder: tt('jobForm.element.options.placeholder'),
                            required: true,
                            className: classes.optionsInput,
                            draggable: {
                                addToDraggableId: `section_${i}_element_${j}_name`,
                                droppableId,
                            },
                        };
                    }
                    break;
                case JobFormElementType.Text:
                    newInputs[`section_${i}_element_${j}_name`] = {
                        type: InputType.Text,
                        label: `${tt('jobForm.elementTooltip.text')}*`,
                        value: inputs[`section_${i}_element_${j}_name`]?.value || sectionElement?.label || '',
                        suffixJSX: () => (
                            <Box className={classes.suffixContainer}>
                                <ToggleRequiredElement
                                    i={i}
                                    j={j}
                                    sectionElement={sectionElement}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    elements={elements}
                                    setElements={setElements}
                                    invalidateForm={invalidateForm}
                                    setInvalidateForm={setInvalidateForm}
                                />

                                <AppIconButton onClick={() => {
                                }}>
                                    <Icons8MoveGrabber/>
                                </AppIconButton>

                                <AppIconButton onClick={() => RemoveElement(newInputs, i, j)}>
                                    <DeleteIcon className={classes.deleteIcon}/>
                                </AppIconButton>
                            </Box>
                        ),
                        prefixJSX: (
                            <Box className={classes.prefixContainer}>
                                <Icons8TextOneLine/>
                            </Box>
                        ),
                        required: true,
                        draggable: {
                            index: j,
                            draggableId: `section_${i}_element_${j}_name`,
                            droppableId
                        },
                    };
                    break;
                case JobFormElementType.MultilineText:
                    newInputs[`section_${i}_element_${j}_name`] = {
                        type: InputType.Text,
                        label: `${tt('jobForm.elementTooltip.multilineText')}*`,
                        value: inputs[`section_${i}_element_${j}_name`]?.value || sectionElement?.label || '',
                        suffixJSX: () => (
                            <Box className={classes.suffixContainer}>
                                <ToggleRequiredElement
                                    i={i}
                                    j={j}
                                    sectionElement={sectionElement}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    elements={elements}
                                    setElements={setElements}
                                    invalidateForm={invalidateForm}
                                    setInvalidateForm={setInvalidateForm}
                                />

                                <AppIconButton onClick={() => {
                                }}>
                                    <Icons8MoveGrabber/>
                                </AppIconButton>

                                <AppIconButton onClick={() => RemoveElement(newInputs, i, j)}>
                                    <DeleteIcon className={classes.deleteIcon}/>
                                </AppIconButton>
                            </Box>
                        ),
                        prefixJSX: (
                            <Box className={classes.prefixContainer}>
                                <Icons8MultilineText/>
                            </Box>
                        ),
                        required: true,
                        draggable: {
                            index: j,
                            draggableId: `section_${i}_element_${j}_name`,
                            droppableId
                        },
                    };
                    break;
                case JobFormElementType.Dropdown:
                    newInputs[`section_${i}_element_${j}_name`] = {
                        type: InputType.Text,
                        label: `${tt('jobForm.elementTooltip.dropdown')}*`,
                        value: inputs[`section_${i}_element_${j}_name`]?.value || sectionElement?.label || '',
                        suffixJSX: () => (
                            <Box className={classes.suffixContainer}>
                                <ToggleRequiredElement
                                    i={i}
                                    j={j}
                                    sectionElement={sectionElement}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    elements={elements}
                                    setElements={setElements}
                                    invalidateForm={invalidateForm}
                                    setInvalidateForm={setInvalidateForm}
                                />

                                <AppIconButton onClick={() => {
                                }}>
                                    <Icons8MoveGrabber/>
                                </AppIconButton>

                                <AppIconButton onClick={() => RemoveElement(newInputs, i, j)}>
                                    <DeleteIcon className={classes.deleteIcon}/>
                                </AppIconButton>
                            </Box>
                        ),
                        prefixJSX: (
                            <Box className={classes.prefixContainer}>
                                <Icons8DropdownIcon/>
                            </Box>
                        ),
                        required: true,
                        draggable: {
                            index: j,
                            draggableId: `section_${i}_element_${j}_name`,
                            droppableId,
                        },
                    };

                    newInputs[`section_${i}_element_${j}_options`] = {
                        type: InputType.ChipAutocomplete,
                        label: `${tt('jobForm.element.options.label')}*`,
                        value: inputs[`section_${i}_element_${j}_options`]?.value || sectionElement?.options.map(option => ({
                            label: option,
                            value: option,
                        })) || [],
                        options: [],
                        freeSolo: true,
                        placeholder: tt('jobForm.element.options.placeholder'),
                        required: true,
                        className: classes.optionsInput,
                        draggable: {
                            addToDraggableId: `section_${i}_element_${j}_name`,
                            droppableId,
                        },
                    };
                    break;
                case JobFormElementType.Number:
                    newInputs[`section_${i}_element_${j}_name`] = {
                        type: InputType.Text,
                        label: `${tt('jobForm.elementTooltip.number')}*`,
                        value: inputs[`section_${i}_element_${j}_name`]?.value || sectionElement?.label || '',
                        suffixJSX: () => (
                            <Box className={classes.suffixContainer}>
                                <ToggleRequiredElement
                                    i={i}
                                    j={j}
                                    sectionElement={sectionElement}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    elements={elements}
                                    setElements={setElements}
                                    invalidateForm={invalidateForm}
                                    setInvalidateForm={setInvalidateForm}
                                />

                                <AppIconButton onClick={() => {
                                }}>
                                    <Icons8MoveGrabber/>
                                </AppIconButton>

                                <AppIconButton onClick={() => RemoveElement(newInputs, i, j)}>
                                    <DeleteIcon className={classes.deleteIcon}/>
                                </AppIconButton>
                            </Box>
                        ),
                        prefixJSX: (
                            <Box className={classes.prefixContainer}>
                                <Icons8Number/>
                            </Box>
                        ),
                        required: true,
                        draggable: {
                            index: j,
                            draggableId: `section_${i}_element_${j}_name`,
                            droppableId
                        },
                    };
                    break;
                case JobFormElementType.Datepicker:
                    newInputs[`section_${i}_element_${j}_name`] = {
                        type: InputType.Text,
                        label: `${tt('jobForm.elementTooltip.datePicker')}*`,
                        value: inputs[`section_${i}_element_${j}_name`]?.value || sectionElement?.label || '',
                        suffixJSX: () => (
                            <Box className={classes.suffixContainer}>
                                <ToggleRequiredElement
                                    i={i}
                                    j={j}
                                    sectionElement={sectionElement}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    elements={elements}
                                    setElements={setElements}
                                    invalidateForm={invalidateForm}
                                    setInvalidateForm={setInvalidateForm}
                                />

                                <AppIconButton onClick={() => {
                                }}>
                                    <Icons8MoveGrabber/>
                                </AppIconButton>

                                <AppIconButton onClick={() => RemoveElement(newInputs, i, j)}>
                                    <DeleteIcon className={classes.deleteIcon}/>
                                </AppIconButton>
                            </Box>
                        ),
                        prefixJSX: (
                            <Box className={classes.prefixContainer}>
                                <CalendarIcon/>
                            </Box>
                        ),
                        required: true,
                        draggable: {
                            index: j,
                            draggableId: `section_${i}_element_${j}_name`,
                            droppableId
                        },
                    };
                    break;
                case JobFormElementType.Timepicker:
                    newInputs[`section_${i}_element_${j}_name`] = {
                        type: InputType.Text,
                        label: `${tt('jobForm.elementTooltip.timePicker')}*`,
                        value: inputs[`section_${i}_element_${j}_name`]?.value || sectionElement?.label || '',
                        suffixJSX: () => (
                            <Box className={classes.suffixContainer}>
                                <ToggleRequiredElement
                                    i={i}
                                    j={j}
                                    sectionElement={sectionElement}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    elements={elements}
                                    setElements={setElements}
                                    invalidateForm={invalidateForm}
                                    setInvalidateForm={setInvalidateForm}
                                />

                                <AppIconButton onClick={() => {
                                }}>
                                    <Icons8MoveGrabber/>
                                </AppIconButton>

                                <AppIconButton onClick={() => RemoveElement(newInputs, i, j)}>
                                    <DeleteIcon className={classes.deleteIcon}/>
                                </AppIconButton>
                            </Box>
                        ),
                        prefixJSX: (
                            <Box className={classes.prefixContainer}>
                                <ClockIcon/>
                            </Box>
                        ),
                        required: true,
                        draggable: {
                            index: j,
                            draggableId: `section_${i}_element_${j}_name`,
                            droppableId
                        },
                    };
                    break;
                case JobFormElementType.Radio:
                    newInputs[`section_${i}_element_${j}_name`] = {
                        type: InputType.Text,
                        label: `${tt('jobForm.elementTooltip.radioButton')}*`,
                        value: inputs[`section_${i}_element_${j}_name`]?.value || sectionElement?.label || '',
                        suffixJSX: () => (
                            <Box className={classes.suffixContainer}>
                                <ToggleRequiredElement
                                    i={i}
                                    j={j}
                                    sectionElement={sectionElement}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    elements={elements}
                                    setElements={setElements}
                                    invalidateForm={invalidateForm}
                                    setInvalidateForm={setInvalidateForm}
                                />

                                <AppIconButton onClick={() => {
                                }}>
                                    <Icons8MoveGrabber/>
                                </AppIconButton>

                                <AppIconButton onClick={() => RemoveElement(newInputs, i, j)}>
                                    <DeleteIcon className={classes.deleteIcon}/>
                                </AppIconButton>
                            </Box>
                        ),
                        prefixJSX: (
                            <Box className={classes.prefixContainer}>
                                <RadioButtonIcon/>
                            </Box>
                        ),
                        required: true,
                        draggable: {
                            index: j,
                            draggableId: `section_${i}_element_${j}_name`,
                            droppableId
                        },
                    };

                    newInputs[`section_${i}_element_${j}_options`] = {
                        type: InputType.ChipAutocomplete,
                        label: `${tt('jobForm.element.options.label')}*`,
                        value: inputs[`section_${i}_element_${j}_options`]?.value || sectionElement?.options.map(option => ({
                            label: option,
                            value: option,
                        })) || [],
                        options: [],
                        freeSolo: true,
                        placeholder: tt('jobForm.element.options.placeholder'),
                        required: true,
                        className: classes.optionsInput,
                        draggable: {
                            addToDraggableId: `section_${i}_element_${j}_name`,
                            droppableId,
                        },
                    };
                    break;
            }
        }

        newInputs[`section_${i}_widgets`] = {
            type: InputType.CustomRenderer,
            label: '',
            value: '',
            render: () => (
                <Box className={classes.toolsContainer}>
                    <AppIconButton
                        color={"primary"}
                        tooltip={tt('common.checkbox')}
                        onClick={() => AddElement(i, JobFormElementType.Checkbox)}
                    >
                        <Icons8CheckedCheckbox/>
                    </AppIconButton>
                    <Box sx={{pr: 1}}/>
                    <AppIconButton
                        color={"primary"}
                        tooltip={tt('jobForm.elementTooltip.checkboxGroup')}
                        onClick={() => AddElement(i, JobFormElementType.Checkbox, uuidv4())}
                    >
                        <Icons8CheckAll/>
                    </AppIconButton>
                    <Box sx={{pr: 1}}/>
                    <AppIconButton
                        color={"primary"}
                        tooltip={tt('jobForm.elementTooltip.text')}
                        onClick={() => AddElement(i, JobFormElementType.Text)}
                    >
                        <Icons8TextOneLine/>
                    </AppIconButton>
                    <Box sx={{pr: 1}}/>
                    <AppIconButton
                        color={"primary"}
                        tooltip={tt('jobForm.elementTooltip.multilineText')}
                        onClick={() => AddElement(i, JobFormElementType.MultilineText)}
                    >
                        <Icons8MultilineText/>
                    </AppIconButton>
                    <Box sx={{pr: 1}}/>
                    <AppIconButton
                        color={"primary"}
                        tooltip={tt('jobForm.elementTooltip.dropdown')}
                        onClick={() => AddElement(i, JobFormElementType.Dropdown)}
                    >
                        <Icons8DropdownIcon/>
                    </AppIconButton>
                    <Box sx={{pr: 1}}/>
                    <AppIconButton
                        color={"primary"}
                        tooltip={tt('jobForm.elementTooltip.number')}
                        onClick={() => AddElement(i, JobFormElementType.Number)}
                    >
                        <Icons8Number/>
                    </AppIconButton>
                    <Box sx={{pr: 1}}/>
                    <AppIconButton
                        color={"primary"}
                        tooltip={tt('jobForm.elementTooltip.datePicker')}
                        onClick={() => AddElement(i, JobFormElementType.Datepicker)}
                    >
                        <CalendarIcon/>
                    </AppIconButton>
                    <Box sx={{pr: 1}}/>
                    <AppIconButton
                        color={"primary"}
                        tooltip={tt('jobForm.elementTooltip.timePicker')}
                        onClick={() => AddElement(i, JobFormElementType.Timepicker)}
                    >
                        <ClockIcon/>
                    </AppIconButton>
                    <Box sx={{pr: 1}}/>
                    <AppIconButton
                        color={"primary"}
                        tooltip={tt('jobForm.elementTooltip.radioButton')}
                        onClick={() => AddElement(i, JobFormElementType.Radio)}
                    >
                        <RadioButtonIcon/>
                    </AppIconButton>
                </Box>
            ),
        };

        newInputs[`section_${i}_divider`] = {
            type: InputType.CustomRenderer,
            label: '',
            value: '',
            render: () => (
                <Divider className={classes.divider}/>
            ),
        };
    }

    newInputs.addSectionButton = {
        type: InputType.CustomRenderer,
        label: '',
        value: '',
        render: () => (
            <AppButton
                className={classes.addSectionButton}
                variant={"textThin"}
                onClick={AddSection}
            >{tt('newJobForm.button.addSection')}</AppButton>
        ),
    };

    setInputs(newInputs);

    setDroppables(newDroppables);
}

interface IToggleRequiredElementProps {
    i: number;
    j: number;
    sectionElement: JobFormElementFragment;
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    elements: Record<string, JobFormElementFragment[]>;
    setElements: Dispatch<SetStateAction<Record<string, JobFormElementFragment[]>>>;
    invalidateForm: boolean;
    setInvalidateForm: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Component to toggle is required element.
 */
function ToggleRequiredElement(props: IToggleRequiredElementProps) {
    const {sectionElement, i, j, inputs, setInputs, elements, setElements, invalidateForm, setInvalidateForm} = props;

    const {classes} = useStyles();

    return (
        <Tooltip
            title={tt('formEdit.element.required')}
        >
            <div className={classes.elementRequired}>
                <FormSwitch
                    className={classes.elementRequired}
                    label={""}
                    index={`section_${i}_element_${j}_required`}
                    value={inputs[`section_${i}_element_${j}_required`]?.value || sectionElement?.required || false}
                    updateProp={(index: string, key: string, value: any) => {
                        //do nothing
                    }}
                    updateValue={(index: string, value: any) => {
                        const newElements = {...elements};

                        newElements[i][j].required = value;

                        setElements(newElements);

                        setInvalidateForm(!invalidateForm);

                        /*const newInputs = {...inputs};

                        newInputs[index].value = value;

                        setInputs(newInputs);

                        setInvalidateForm(!invalidateForm);*/
                    }}
                    switchVariant="Android12SwitchNoLabel"
                    hideLabel={true}
                />
            </div>
        </Tooltip>
    );
}
