import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import AppIconButton from "../../components/buttons/AppIconButton";
import PencilIcon from "../../../icons/PencilIcon";
import {Box, Divider, Theme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import AppTabsComponent from "../../components/AppTabsComponent";
import {useNavigate, useParams} from "react-router-dom";
import {AppDataContext} from "../../../AppData";
import DetailScreenShimmer from "../../components/shimmers/DetailScreenShimmer";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import MoreFilledIcon from "../../../icons/MoreFilledIcon";
import {usePopupState} from "material-ui-popup-state/hooks";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "../../../icons/DeleteIcon";
import Menu from "@mui/material/Menu";
import DetailContactRow from "../../screenSections/contactSection/DetailContactRow";
import {GetProductIcon, GetProductTypeTitle} from "../../../service/ProductService";
import DetailColumnItem from "../../screenSections/userStatussRow/DetailColumnItem";
import {kProductsRoute} from "./ProductsListScreen";
import {productEditRoute} from "./ProductEditScreen";
import {HideConfirmModal, SetConfirmModal} from "../../components/modals/AppModals";
import AppListItem from "../../components/listItems/AppListItem";
import {
    DeleteProductTemplateDocument,
    DeleteProductTemplateMutation,
    DeleteProductTemplateMutationVariables,
    GetProductInput,
    ProductResponse
} from "../../../generated/graphql/graphql";
import {useMutation} from "@apollo/client";
import {processMutationError, processQueryError} from "../../../service/ErrorService";
import IEventSystemListener from "../../../model/EventSystemListener";
import {
    listenToEventSystem,
    unListenToEventSystem
} from "../../../service/EventSystemService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import {getBackRoute} from "../../../utils/Utils";
import {SuccessToast} from "../../../service/ToastService";
import {ColumnItem} from "../../components/jobs/visits/visitDetail/VisitInfoSection";
import {AddVatToPrice, GetVatAmount, PriceDisplay} from "../../../service/CompanyService";
import {kActionDelete, kActionUpdate, kPermissionsProducts, kTopicProductTemplates} from "../../../core/constants";
import PermissionValid from "../../components/permissions/PermissionValid";
import { RestApiClientContext } from "../../../core/RestApiProvider";

export const kProductDetailRoute = '/products/:productId';

export function productDetailRoute(productId: string | number): string {
    return kProductDetailRoute.replace(':productId', `${productId}`)
}

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: "flex",
        overflowX: "auto",
        alignItems: "start",
    },
}));

export default function ProductDetailScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;
    
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {productId} = useParams();

    const [screenTitle, setScreenTitle] = useState<string>();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ProductResponse | NullOrUndefined>();
    useEffect(() => {
        if (productId) {
            const subscription = subscribe(
                kTopicProductTemplates,
                {
                    uri: '/product',
                    params: {
                        productId: parseInt(productId!),
                    } as GetProductInput,
                    setLoading,
                    onData: setData,
                    onError: (error) => processQueryError(appDataContext, error),
                },
                (notifications: IEventSystemNotification[]) => {
                    return notifications.some(notification => {
                        if (notification.action !== kActionUpdate) {
                            return false;
                        }

                        return notification.data.id === parseInt(productId!);
                    });
                },
            );

            return () => subscription.cancel();
        } else {
            setLoading(false);
            setData(null);
        }
    }, [productId]);

    const [mutateDelete, {
        loading: loadingDelete,
    }] = useMutation<DeleteProductTemplateMutation, DeleteProductTemplateMutationVariables>(DeleteProductTemplateDocument);

    useEffect(() => {
        const newTitle = GetProductTypeTitle(data?.type);

        if (newTitle !== screenTitle && data) {
            setScreenTitle(newTitle);

            setTitle(newTitle);
        }
    }, [data]);

    /**
     * Show confirm modal and if confirmed mutate delete to BE.
     */
    const DeleteProduct = async () => {
        SetConfirmModal(appDataContext, {
            open: true,
            title: tt('deleteProduct.popup.title'),
            subtitle: tt('deleteProduct.popup.description'),
            cancelButtonText: tt('common.close'),
            confirmationButtonText: tt('deleteProduct.popup.confirm'),
            onConfirm: async () => {
                try {
                    const variables: DeleteProductTemplateMutationVariables = {
                        input: {
                            productId: parseInt(productId!),
                        },
                    };

                    const result = await mutateDelete({variables});

                    if (!result.errors) {
                        SuccessToast(tt('deleteProduct.popup.success'));

                        HideConfirmModal(appDataContext);
                    }
                } catch (e) {
                    processMutationError(e);
                }
            },
            children: <></>,
        });
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                loading={loading}
                data={data}
                isMobile={isMobile}
                deleteProduct={DeleteProduct}
            />
        );
    }

    return (
        <>
            <EventSystemListeners />

            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}
            />
        </>
    );
}

interface IEventSystemListenersProps {
}

/**
 * Component for EventSystem listeners.
 */
function EventSystemListeners(props: IEventSystemListenersProps) {
    const {productId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicProductTemplates,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(productId!);
                });

                if (doDelete) {
                    navigate(getBackRoute(kProductsRoute));
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [productId]);

    return null;
}

interface IBodyProps {
    loading: boolean;
    data?: ProductResponse | NullOrUndefined;
    isMobile?: boolean;
    deleteProduct: VoidFunction;
}

function Body(props: IBodyProps) {
    const {loading, data, isMobile, deleteProduct} = props;

    const navigate = useNavigate();

    const {classes, cx} = useStyles();

    const appDataContext = useContext(AppDataContext);
    const {currency, language, company} = appDataContext;

    const companyHasVat = company?.hasVat || false;

    const infoJSX = data ? (
        <>
            <Divider/>

            <AppTabsComponent
                data={[{
                    label: GetProductTypeTitle(data.type),
                    content: <Box sx={{mb: 1.25}}>
                        <DetailContactRow
                            title={data.description}
                            disabled={!data.description}
                            notFilledText={tt('common.noDescription')}
                        />
                    </Box>
                }]}
            />
        </>
    ) : null;

    const priceJSX = data ? (
        <>
            {companyHasVat ? (
                <>
                    <ColumnItem
                        title={tt('productDetail.screen.label.unitPrice')}
                        text1={PriceDisplay(data.price, currency, language, true) || ''}
                        text2={PriceDisplay(AddVatToPrice(data.price, data.vatRate), currency, language, true) || ''}
                    />

                    <ColumnItem
                        title={tt('productDetail.screen.label.vatRate')}
                        text1={`${data?.vatRate} %`}
                        text2={PriceDisplay(GetVatAmount(data.price, data.vatRate || 0), currency, language, true) || ''}
                    />
                </>
            ) : (
                <>
                    <DetailColumnItem
                        title={tt('productDetail.screen.label.unitPrice')}
                        description={PriceDisplay(data.price, currency, language, true)}
                    />
                </>
            )}
        </>
    ) : undefined;

    const contentJSX = loading && !data ? <DetailScreenShimmer/> : (
        <>
            <AppListItem
                title={data?.name || ''}
                customAvatarInCircle={GetProductIcon(data?.type)}
                actionWidget={<></>}
            />

            <Box pb={1}/>

            <Divider/>

            <Box className={cx('styledScrollbar', classes.container)}>
                {priceJSX}

                <DetailColumnItem
                    title={tt('productDetail.screen.label.unit')}
                    description={data?.unitName || ''}
                />
            </Box>

            {infoJSX}
        </>
    );

    const productPopupStat = usePopupState({
        variant: 'popover',
        popupId: 'popup-service-detail-more-options',
    });

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}
            >
                <PaperAppbar
                    backRoute={getBackRoute(kProductsRoute)}
                    isMobile={isMobile}
                    title={GetProductTypeTitle(data?.type)}
                >
                    <PermissionValid
                        permission={kPermissionsProducts}
                        requiredPermissions={[kActionUpdate]}
                    >
                        <AppIconButton
                            key={'productDetailEditBtnKey'}
                            onClick={() => {
                                navigate(productEditRoute(data?.id.toString() ?? 0));
                            }
                            }>
                            <PencilIcon/>
                        </AppIconButton>
                    </PermissionValid>

                    <PermissionValid
                        permission={kPermissionsProducts}
                        requiredPermissions={[kActionDelete]}
                    >
                        <AppIconButton
                            key={'moreOptionsBtn'}
                            {...bindTrigger(productPopupStat)}
                        >
                            <MoreFilledIcon/>
                        </AppIconButton>
                    </PermissionValid>
                </PaperAppbar>

                <Menu {...bindMenu(productPopupStat)}>
                    <PermissionValid
                        permission={kPermissionsProducts}
                        requiredPermissions={[kActionDelete]}
                    >
                        <MenuItem key={'deleteProductItem'} onClick={() => {
                            productPopupStat.close();

                            deleteProduct();
                        }}>
                            <Box color={kAppColors.red.confirmButton}><DeleteIcon/></Box>
                            {tt('common.delete')}
                        </MenuItem>
                    </PermissionValid>
                </Menu>

                {contentJSX}
            </AppPaper>
        </ScreenContent>
    );
}
