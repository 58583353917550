import {AddVisitMaterialInput, EmployeeJoinedUserResponse, MaterialResponse} from "../../../generated/graphql/graphql";
import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {AppDataContext} from "../../../AppData";
import {makeStyles} from "tss-react/mui";
import {Box, Divider, Theme, Typography} from "@mui/material";
import ModalBottomSheet from "../ModalBottomSheet";
import BottomSheetModalAppbar from "../modals/BottomSheetModalAppbar";
import {tt} from "../../../core/Localization";
import AppListItem from "../listItems/AppListItem";
import Icons8Layers from "../../../icons/Icons8Layers";
import AppChip from "../chips/AppChip";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import AppButton from "../buttons/AppButton";
import EditVisitMaterialModalSection from "./EditVisitMaterialModalSection";
import {unitNameAndCountText} from "../../../service/VisitService";
import {IInputsData} from "../form/FormBuilder";
import {v4 as uuidv4} from "uuid";

const useStyles = makeStyles()((theme: Theme) => ({
    toolsContainer: {
        paddingLeft: 16,
        paddingRight: 16,
        height: 48,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
    },
    addLabel: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontWeight: 500,
    },
}));

export interface IMaterialsByTemplate {
    materialId?: number;
    materialUuid?: string;
    name: string;
    unitCount: number;
    unitName: string;
    employees: EmployeeJoinedUserResponse[];
}

export interface IEditVisitMaterialModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    data?: IMaterialsByTemplate;
    materials?: MaterialResponse[];
    onAdd: (material: Partial<AddVisitMaterialInput>, input: IInputsData) => void;
    onDelete: (uuid: string) => void;
    onUpdate: (uuid: string, data: IInputsData) => void;
}

export default function EditVisitMaterialModalBottomSheet(props: IEditVisitMaterialModalBottomSheetProps) {
    const {
        open,
        setOpen,
        data,
        materials,
        onAdd,
        onDelete,
        onUpdate,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const {classes} = useStyles();

    const [addMaterials, setAddMaterials] = useState<Partial<AddVisitMaterialInput>[]>([]);

    useEffect(() => {
        if (open) {
            setAddMaterials([]);
        }
    }, [open]);

    /**
     * Add Material input to list for current template.
     */
    const onAddForTemplate = () => {
        if (data && data.materialId) {
            setAddMaterials(prev => {
                return [
                    ...prev,
                    {
                        templateId: data.materialId,
                        uuid: uuidv4(),
                        name: data.name,
                        unitCount: 1,
                        unitName: data.unitName,
                    },
                ];
            });
        }
    };

    const materialsForData = useMemo(() => {
        if (data && materials) {
            return materials.filter(material => {
                if (data.materialId) {
                    return material.templateId === data.materialId;
                } else {
                    return material.uuid === data.materialUuid;
                }
            })
                .sort((a, b) => b.updatedAt - a.updatedAt);
        }

        return null;
    }, [data, materials]);

    useEffect(() => {
        if (data && materialsForData && materialsForData.length === 0 && open) {
            setOpen(false);
        } else if (data && materialsForData && open && addMaterials.length > 0) {
            setAddMaterials(prev => {
                return prev.filter(item => materialsForData.find(material => material.uuid === item.uuid) === undefined)
            });
        }
    }, [data, materialsForData]);

    const sectionsForNewJSX = useMemo(() => {
        return addMaterials.map(material => {
            return (
                <EditVisitMaterialModalSection
                    key={material.uuid}
                    addMaterial={material}
                    onDelete={(uuid: string) => {
                        setAddMaterials(prev => prev.filter(item => item.uuid !== uuid));
                    }}
                    onUpdate={(uuid: string, data: IInputsData) => {
                        onAdd(material, data);
                    }}
                    startEditMode={true}
                />
            );
        });
    }, [addMaterials]);

    const sectionsJSX = useMemo(() => {
        if (data && materialsForData) {
            return materialsForData.map(material => {
                const employee = data.employees.find(employee => employee.id === material.employeeId);

                return (
                    <EditVisitMaterialModalSection
                        key={material.uuid}
                        data={material}
                        onDelete={onDelete}
                        onUpdate={onUpdate}
                        startEditMode={!data?.materialId}
                        createdByEmployee={employee}
                    />
                );
            });
        }

        return null;
    }, [data, materialsForData]);

    return (
        <>
            <ModalBottomSheet
                blurBackdrop={true}
                modalAboveModals={true}
                open={open}
                setOpen={setOpen}
                hideHeader={true}>
                <BottomSheetModalAppbar
                    noBorderBottom={true}
                    onClose={() => setOpen(false)}
                    title={tt('editMaterial.modalBottomSheet.title')}>
                </BottomSheetModalAppbar>

                <AppListItem
                    title={data?.name || ''}
                    customAvatarInCircle={<Icons8Layers/>}
                    actionWidget={<></>}
                    belowListItemWidget={data?.unitCount ? (
                        <AppChip
                            chipstyle={"outlined"}
                            label={unitNameAndCountText(data.unitName, data.unitCount, language)}
                        />
                    ) : null}
                />

                <Box sx={{pb: 1}}/>

                {data?.materialId ? (
                    <>
                        <Divider/>

                        <Box className={classes.toolsContainer}>
                            <Typography
                                className={classes.addLabel}>{tt('editMaterial.bottomSheet.label.add')}</Typography>

                            <Box pr={1}/>

                            <AppButton
                                variant={"textThin"}
                                onClick={onAddForTemplate}
                            >
                                {data?.name || ''}
                            </AppButton>
                        </Box>
                    </>
                ) : null}

                {sectionsForNewJSX}

                {sectionsJSX}

                <Box sx={{pb: 4}}/>
            </ModalBottomSheet>
        </>
    );
}
