import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import ContentPadding from "../../components/paper/ContentPadding";
import FormBuilder, {IInputsData, InputType} from "../../components/form/FormBuilder";
import AppButton from "../../components/buttons/AppButton";
import {makeStyles} from "tss-react/mui";
import {Box, Link, Theme, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {kProfileRoute} from "./ProfileScreen";
import {appModalsStyles, DisplayCloseAccountConfirm} from "../../components/modals/AppModals";
import {AppDataContext} from "../../../AppData";
import {ErrorToast} from "../../../service/ToastService";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {
    OsType,
    UserDeleteRequestDocument,
    UserDeleteRequestMutation,
    UserDeleteRequestMutationVariables
} from "../../../generated/graphql/graphql";
import {FlavorByEnvironment} from "../../../flavor-config";

export const kCloseAccountRoute = '/close-account';


export const useStyles = makeStyles()((theme: Theme) => ({
    headline: {
        fontSize: 16,
        fontWeight: 500,
        paddingBottom: 16,
    },
    text: {
        whiteSpace: 'pre-line',
        fontSize: 14,
        fontWeight: 500,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        paddingBottom: 16,
    },
    link: {
        color: kAppColors.primary.main,
        textDecorationColor: kAppColors.primary.main,
    },
}));

export default function CloseAccountScreen() {
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);

    const {classes} = appModalsStyles();
    const navigate = useNavigate();

    const [mutateDelete, {
        loading: deleteLoading,
        error: deleteError,
        data: deleteData,
    }] = useResettableMutation<UserDeleteRequestMutation, UserDeleteRequestMutationVariables>(UserDeleteRequestDocument);

    useEffect(() => {
        setTitle(tt('closeAccount.screen.title'));
    }, []);

    useEffect(() => {
        if (deleteError) {
            console.error(deleteError);
            ErrorToast(tt('common.mutation.error'));
        }
    }, [deleteError]);

    /**
     * Use mutation to BE to close the account.
     * This is a request, not delete now.
     */
    const DeleteAccount = async () => {
        try {
            const variables: UserDeleteRequestMutationVariables = {
                input: {
                    currentPlatform: OsType.Web,
                },
            };

            const result = await mutateDelete({variables});

            if (!result.errors) {
                navigate(kProfileRoute);

                DisplayCloseAccountConfirm(appDataContext, classes);
            }
        } catch (e) {
            console.error(e);

            ErrorToast(tt('common.mutation.error'));
        }
    };

    function BodyJSX(isMobile?: boolean) {
        return (
            <Body
                isMobile={isMobile}
                loading={deleteLoading}
                deleteAccount={DeleteAccount}
            />
        );
    }

    return (<ResponsiveContainer
            smallPhoneScreen={BodyJSX(true)}
            largePhoneScreen={BodyJSX(true)}
            tabletScreen={BodyJSX()}
            smallDesktopScreen={BodyJSX()}
            largeDesktopScreen={BodyJSX()}
            extraLargeDesktopScreen={BodyJSX()}
        />
    );
}

interface IBodyProps {
    isMobile?: boolean;
    loading: boolean;
    deleteAccount: VoidFunction;
}

function Body(props: IBodyProps) {
    const {isMobile, loading, deleteAccount} = props;

    const {classes} = useStyles();

    const [enabled, setEnabled] = useState<boolean>(false);

    const [inputs, setInputs] = useState<IInputsData>({
        confirmation: {
            type: InputType.Text,
            label: `${tt('closeAccount.screen.confirmationInput.label')}*`,
            value: '',
            required: true,
        },
    });

    useEffect(() => {
        const enableButton = inputs.confirmation.value === 'CLOSE';
        if (enabled && !enableButton) {
            setEnabled(false);
        }
        if (!enabled && enableButton) {
            setEnabled(true);
        }
    }, [inputs]);


    let desc1;
    let desc2;
    let desc3;

    const tosTranslated = tt('closeAccount.screen.text2firstPart');
    let processing = tosTranslated.split('$linkAStart');
    desc1 = processing[0];
    processing = processing[1].split('$linkAEnd');
    desc2 = processing[0];
    desc3 = processing[1];

    return (
        <ScreenContent appBar={!isMobile} noContentPadding={isMobile} navigationDrawer={!isMobile} bottomBar={isMobile}
                       centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar isMobile={isMobile} title={tt('common.closeAccount')} backRoute={kProfileRoute}
                             cancelIconBackButton={true}/>
                <ContentPadding>
                    <Typography className={classes.headline}>{tt('closeAccount.screen.title1')}</Typography>
                    <Typography className={classes.text}>{tt('closeAccount.screen.text1')}</Typography>
                    <Typography className={classes.headline}>{tt('closeAccount.screen.title2')}</Typography>
                    <Typography className={classes.text}>{desc1}
                        <Link
                            className={classes.link}
                            href={FlavorByEnvironment()!.privacyPolicy}
                            target={"_blank"}>{desc2}</Link>
                        {desc3}
                    </Typography>
                    <Box pb={1}/>
                    <FormBuilder inputs={inputs} setInputs={setInputs}/>
                    <AppButton
                        color={"error"}
                        fullWidth={true}
                        variant={"contained"} disabled={!enabled}
                        isLoading={loading}
                        onClick={deleteAccount}>
                        {tt('common.closeAccount')}
                    </AppButton>
                </ContentPadding>
            </AppPaper>
        </ScreenContent>
    );
}
