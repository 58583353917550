import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8DoorProps {
    className?: string;
}

export default function Icons8Door(props: IIcons8DoorProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
             <path
                 d="M 12.5 5 C 10.032499 5 8 7.0324991 8 9.5 L 8 40 L 7.5 40 A 1.50015 1.50015 0 1 0 7.5 43 L 9.2539062 43 A 1.50015 1.50015 0 0 0 9.7402344 43 L 38.253906 43 A 1.50015 1.50015 0 0 0 38.740234 43 L 40.5 43 A 1.50015 1.50015 0 1 0 40.5 40 L 40 40 L 40 9.5 C 40 7.0324991 37.967501 5 35.5 5 L 12.5 5 z M 12.5 8 L 35.5 8 C 36.346499 8 37 8.6535009 37 9.5 L 37 40 L 11 40 L 11 9.5 C 11 8.6535009 11.653501 8 12.5 8 z M 32 23 A 2 2 0 0 0 32 27 A 2 2 0 0 0 32 23 z"/>
             </svg>
        </span>
    );
}
























