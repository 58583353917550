import React, {useContext, useEffect, useId, useState} from "react";
import {AppContext} from "../../../App";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import {Box, Menu, MenuItem} from "@mui/material";
import {tt} from "../../../core/Localization";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {useNavigate, useParams} from "react-router-dom";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {AppDataContext} from "../../../AppData";
import {usePopupState} from "material-ui-popup-state/hooks";
import AppIconButton from "../../components/buttons/AppIconButton";
import PencilIcon from "../../../icons/PencilIcon";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MoreFilledIcon from "../../../icons/MoreFilledIcon";
import DeleteIcon from "../../../icons/DeleteIcon";
import {kJobFormsRoute} from "./JobFormsScreen";
import {HideConfirmModal, SetConfirmModal} from "../../components/modals/AppModals";
import JobFormDetailContent from "../../components/jobForms/JobFormDetailContent";
import {jobFormEditRoute} from "./JobFormEditScreen";
import {
    DeleteJobFormTemplateDocument,
    DeleteJobFormTemplateMutation,
    DeleteJobFormTemplateMutationVariables,
    GetJobFormInput,
    JobFormResponse
} from "../../../generated/graphql/graphql";
import {useMutation} from "@apollo/client";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import FormDetailShimmer from "../../components/shimmers/FormDetailShimmer";
import Icons8Copy from "../../../icons/Icons8Copy";
import CreateJobFormModal from "../../components/modals/jobForms/CreateJobFormModal";
import IEventSystemListener from "../../../model/EventSystemListener";
import {listenToEventSystem, unListenToEventSystem} from "../../../service/EventSystemService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import {processQueryError} from "../../../service/ErrorService";
import {
    kActionCreate,
    kActionDelete,
    kActionUpdate,
    kPermissionsForms,
    kTopicJobFormTemplates
} from "../../../core/constants";
import PermissionValid, {hasSomePermissions} from "../../components/permissions/PermissionValid";
import {RestApiClientContext} from "../../../core/RestApiProvider";

export const kJobFormDetailRoute = '/job-forms/:jobFormId';

/**
 * Route for JobFormDetailScreen with parameters.
 */
export function jobFormDetailRoute(jobFormId: string | number) {
    return kJobFormDetailRoute.replace(':jobFormId', `${jobFormId}`);
}

export default function JobFormDetailScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);

    const {jobFormId} = useParams();

    const [newJobForm, setNewJobForm] = useState(false);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<JobFormResponse | NullOrUndefined>();
    useEffect(() => {
        if (jobFormId) {
            const subscription = subscribe(
                kTopicJobFormTemplates,
                {
                    uri: '/job-form',
                    params: {
                        jobFormId: parseInt(jobFormId!),
                    } as GetJobFormInput,
                    setLoading,
                    onData: setData,
                    onError: (error) => processQueryError(appDataContext, error),
                },
                (notifications: IEventSystemNotification[]) => {
                    return notifications.some(notification => {
                        if (notification.action !== kActionUpdate) {
                            return false;
                        }

                        return notification.data.id === parseInt(jobFormId!);
                    });
                },
            );

            return () => subscription.cancel();
        } else {
            setLoading(false);
            setData(null);
        }
    }, [jobFormId]);

    const [mutateDelete, {
        loading: loadingDelete,
        data: dataDelete,
        error: errorDelete,
    }] = useMutation<DeleteJobFormTemplateMutation, DeleteJobFormTemplateMutationVariables>(DeleteJobFormTemplateDocument);

    useEffect(() => {
        setTitle(tt('jobFormDetail.screen.title'));
    }, []);

    useEffect(() => {
        SetConfirmModal(appDataContext, {
            loading: loadingDelete,
        });
    }, [loadingDelete]);

    /**
     * Duplicate JobForm by prefilling new JobForm with data from current JobForm.
     */
    const DuplicateForm = () => {
        setNewJobForm(true);
    };

    /**
     * Mutation to delete JobForm to BE.
     */
    const DeleteForm = async () => {
        try {
            const variables: DeleteJobFormTemplateMutationVariables = {
                input: {
                    jobFormId: parseInt(jobFormId!),
                },
            };

            const result = await mutateDelete({variables});

            if (!result.errors) {
                HideConfirmModal(appDataContext);

                SuccessToast(tt('jobFormDetail.screen.delete.success'));
            }
        } catch (e) {
            console.error(e);
            ErrorToast(tt('common.mutation.error'));
        }
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                jobFormId={parseInt(jobFormId!)}
                loading={loading}
                data={data}
                isMobile={isMobile}
                duplicateForm={DuplicateForm}
                deleteForm={DeleteForm}
            />
        );
    }

    return (
        <>
            <EventSystemListeners/>

            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}
            />

            <CreateJobFormModal
                open={newJobForm}
                setOpen={setNewJobForm}
                existingData={data}
                isDuplication={true}
            />
        </>
    );
}

interface IEventSystemListenersProps {
}

/**
 * Component for EventSystem listeners.
 */
function EventSystemListeners(props: IEventSystemListenersProps) {
    const navigate = useNavigate();
    const {jobFormId} = useParams();

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicJobFormTemplates,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(jobFormId!);
                });

                if (doDelete) {
                    navigate(kJobFormsRoute);
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [jobFormId]);

    return null;
}

interface IBodyProps {
    jobFormId: number;
    loading: boolean;
    data: JobFormResponse | NullOrUndefined;
    isMobile?: boolean;
    duplicateForm: VoidFunction;
    deleteForm: VoidFunction;
}

function Body(props: IBodyProps) {
    const {jobFormId, loading, data, isMobile, duplicateForm, deleteForm} = props;

    const navigate = useNavigate();
    const appDataContext = useContext(AppDataContext);
    const {employeePermissionsMap} = appDataContext;

    const moreOptionsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    noMarginBottom={true}
                    isMobile={isMobile}
                    title={tt('jobFormDetail.screen.title')}
                    backRoute={kJobFormsRoute}
                    children={
                        <>
                            <PermissionValid
                                permission={kPermissionsForms}
                                requiredPermissions={[kActionUpdate]}
                            >
                                <AppIconButton
                                    key={'jobFormDetailEditBtn'}
                                    tooltip={tt('jobFormDetail.screen.edit')}
                                    onClick={(e) => {
                                        navigate(jobFormEditRoute(jobFormId));
                                    }}
                                >
                                    <PencilIcon/>
                                </AppIconButton>
                            </PermissionValid>

                            {hasSomePermissions([
                                {permission: kPermissionsForms, requiredPermissions: [kActionCreate]},
                                {permission: kPermissionsForms, requiredPermissions: [kActionDelete]},
                            ], employeePermissionsMap) ? (
                                <AppIconButton
                                    key={'jobFormDetailMoreBtn'}
                                    {...bindTrigger(moreOptionsPopupState)}
                                >
                                    <MoreFilledIcon/>
                                </AppIconButton>
                            ) : null}

                            <Menu {...bindMenu(moreOptionsPopupState)}>
                                <PermissionValid
                                    permission={kPermissionsForms}
                                    requiredPermissions={[kActionCreate]}
                                >
                                    <MenuItem
                                        key={'jobFormDetailDuplicateBtn'}
                                        onClick={(e) => {
                                            moreOptionsPopupState.close();
                                            duplicateForm();
                                        }
                                        }
                                    >
                                        <Icons8Copy/>
                                        {tt('jobFormDetail.screen.duplicate')}
                                    </MenuItem>
                                </PermissionValid>

                                <PermissionValid
                                    permission={kPermissionsForms}
                                    requiredPermissions={[kActionDelete]}
                                >
                                    <MenuItem key={'jobFormDetailDeleteKey'} onClick={() => {
                                        moreOptionsPopupState.close();

                                        SetConfirmModal(appDataContext, {
                                            open: true,
                                            title: tt('jobFormDetail.delete.confirm.title'),
                                            subtitle: tt('jobFormDetail.delete.confirm.subtitle'),
                                            confirmationButtonText: tt('common.delete'),
                                            cancelButtonText: tt('common.close'),
                                            children: <></>,
                                            onConfirm: deleteForm,
                                        });
                                    }}>
                                        <Box sx={{color: kAppColors.red.confirmButton}}><DeleteIcon/></Box>
                                        {tt('common.delete')}
                                    </MenuItem>
                                </PermissionValid>
                            </Menu>
                        </>
                    }
                />

                {loading && !data ? <FormDetailShimmer/> :
                    <JobFormDetailContent jobFormResponse={data} canEdit={true}/>
                }
            </AppPaper>
        </ScreenContent>
    );
}
