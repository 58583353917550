import React, {useEffect, useState} from "react";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import {tt} from "../../../core/Localization";
import AppModal from "./AppModal";
import {getFlowStepAsString} from "../../../utils/Utils";
import AppButton from "../buttons/AppButton";
import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material";
import {kContentWidthNarrow} from "../../../styles/AppThemeProcessor";

export const useStyles = makeStyles()((theme: Theme) => ({
    modalPaper: {
        maxWidth: kContentWidthNarrow,
    }
}));


export interface IPersonalInfoModalProps {
    open: boolean;
    onClose: Function;
    onContinue: (inputs: IInputsData) => void;
    loading: boolean;
    blurBackdrop?: boolean;
}

export default function PersonalInfoModal(props: IPersonalInfoModalProps) {
    const {open, onClose, onContinue, loading, blurBackdrop} = props;

    const {classes} = useStyles();

    const [showForm, setShowForm] = useState<boolean>(false);

    const [inputs, setInputs] = useState<IInputsData>({
        firstName: {
            type: InputType.Text,
            label: `${tt('common.firstName')}*`,
            value: '',
            required: true,
        },
        lastName: {
            type: InputType.Text,
            label: `${tt('common.lastName')}*`,
            value: '',
            required: true,
        },
        phone: {
            type: InputType.Text,
            label: tt('common.phone'),
            value: '',
            inputMode: "tel",
        },
        newsletter: {
            type: InputType.CheckBox,
            label: tt('onboarding.modal.personalInfo.newsletter'),
            value: true,
        },
    });

    useEffect(() => {
        if (open) {
            setShowForm(false);
            setShowForm(true);
        } else {
            setShowForm(false);
        }
    }, [open]);

    return (
        <AppModal
            blurBackdrop={blurBackdrop}
            paperClassName={classes.modalPaper}
            onClose={onClose}
            title={tt('onboarding.modal.personalInfo')}
            subtitle={getFlowStepAsString(2, 2)}
            arrowBackButton={true}
            actions={
                <AppButton
                    fullWidth={true}
                    variant={"contained"}
                    onClick={() => {
                        if (ValidateForm(inputs, setInputs)) {
                            onContinue(inputs);
                        }
                    }
                    }
                    isLoading={loading}
                >
                    {tt('personalInfo.modal.setupANewCompany')}
                </AppButton>}
            open={open}>

            {/*<TransitionAnimationWidget show={showForm}>
                <FormBuilder inputs={inputs} setInputs={setInputs}/>
            </TransitionAnimationWidget>*/}
            <FormBuilder inputs={inputs} setInputs={setInputs}/>
        </AppModal>
    );
}
