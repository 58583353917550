import AppModal from "../../AppModal";
import {tt} from "../../../../../core/Localization";
import {HideVisitsCalendarModal} from "../../AppModals";
import React, {useContext} from "react";
import {AppDataContext} from "../../../../../AppData";
import VisitsCalendar, {JobsCalendarVariant} from "../../../jobs/visits/VisitsCalendar";
import {Box} from "@mui/material";

export interface IVisitsCalendarModalProps {
    open: boolean;
    visitIds: number[];
    centerOnVisitId?: number;
    centerOnRepeatingDay?: number;
}

/**
 * Modal component to display Visits iterations in calendar view.
 */
export default function VisitsCalendarModal(props: IVisitsCalendarModalProps) {
    const {open, visitIds, centerOnVisitId, centerOnRepeatingDay} = props;

    const appDataContext = useContext(AppDataContext);

    return (
        <AppModal
            blurBackdrop={true}
            fullScreenOnMobile={true}
            noContentPadding={true}
            title={tt('visitsCalendar.modal.title')}
            appBarNoMarginBottom={true}
            onClose={() => HideVisitsCalendarModal(appDataContext)}
            open={open}
            hideActionsSection={true}
        >
            <Box>
                <VisitsCalendar
                    variant={JobsCalendarVariant.VisitsCalendarModal}
                    subscribeOnOpen={true}
                    open={open}
                    visitIds={visitIds}
                    centerOnVisitId={centerOnVisitId}
                    centerOnRepeatingDay={centerOnRepeatingDay}
                />
            </Box>
        </AppModal>
    );
}
