import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import ContentPadding from "../../components/paper/ContentPadding";
import WorkersAndProfileForm, {IWorkersFormUpdate} from "../../components/workers/WorkersAndProfileForm";
import {
    UserPersonalDetailsDocument,
    UserPersonalDetailsMutation,
    UserPersonalDetailsMutationVariables,
    UserProfileResponse
} from "../../../generated/graphql/graphql";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {useNavigate} from "react-router-dom";
import {kProfileRoute} from "./ProfileScreen";
import {processQueryError} from "../../../service/ErrorService";
import {AppDataContext} from "../../../AppData";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";

export const kProfileEditRoute = '/profile/edit';

export default function ProfileEditScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<UserProfileResponse | NullOrUndefined>();
    useEffect(() => {
        restApiGet({
            uri: '/user/profile',
            params: {},
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading,
            onData: setData,
            onError: (error) => processQueryError(appDataContext, error),
        });
    }, []);

    const [mutateUpdate, {
        loading: updateLoading,
        error: updateError,
        data: updateData,
    }] = useResettableMutation<UserPersonalDetailsMutation, UserPersonalDetailsMutationVariables>(UserPersonalDetailsDocument);

    useEffect(() => {
        setTitle(tt('common.profile'));
    }, []);

    /**
     * Mutate Employee update to BE and back on success.
     */
    const UpdatePersonalData = async (update: IWorkersFormUpdate) => {
        try {
            const variables: UserPersonalDetailsMutationVariables = {
                input: {
                    name: update.inputs.firstName.value,
                    surname: update.inputs.lastName.value,
                    phoneNumber: update.inputs.phone.value,
                    contactEmail: update.inputs.email.value,
                    address: update.addressFragment,
                },
            };

            const result = await mutateUpdate({variables});

            if (!result.errors) {
                navigate(kProfileRoute);

                SuccessToast(tt('profile.screen.success'));
            }
        } catch (e) {
            console.error(e);

            ErrorToast(tt('common.mutation.error'));
        }
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                loading={loading || updateLoading}
                data={data}
                isMobile={isMobile}
                onSubmit={UpdatePersonalData}
            />
        );
    }

    return (
        <ResponsiveContainer
            smallPhoneScreen={bodyJSX(true)}
            largePhoneScreen={bodyJSX(true)}
            tabletScreen={bodyJSX()}
            smallDesktopScreen={bodyJSX()} largeDesktopScreen={bodyJSX()}
            extraLargeDesktopScreen={bodyJSX()}
        />
    );
}


interface IBodyProps {
    isMobile?: boolean;
    loading: boolean;
    data?: UserProfileResponse | NullOrUndefined;
    onSubmit: (update: IWorkersFormUpdate) => void;
}

function Body(props: IBodyProps) {
    const {loading, data, isMobile, onSubmit} = props;

    return (
        <ScreenContent appBar={!isMobile} noContentPadding={isMobile} navigationDrawer={!isMobile} bottomBar={isMobile}
                       centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar isMobile={isMobile} title={tt('common.profile')} backRoute={kProfileRoute}
                             cancelIconBackButton={true}/>

                <ContentPadding>
                    <WorkersAndProfileForm
                        isEdit={true}
                        formType={"profile"}
                        firstName={data?.user.name}
                        lastName={data?.user.surname}
                        phone={data?.user.phoneNumber}
                        email={data?.user.contactEmail}
                        address={data?.user.address}
                        onSubmit={onSubmit}
                        loading={loading}
                    />
                </ContentPadding>
            </AppPaper>
        </ScreenContent>
    );
}
