import React, {MouseEventHandler, ReactNode} from "react";
import {Box, Theme} from "@mui/material";
import Icons8Done from "../../../icons/Icons8Done";
import ChevronDownIcon from "../../../icons/ChevronDownIcon";
import TwoLineChip from "../chips/TwoLineChip";
import {makeStyles} from "tss-react/mui";


export const useStyles = makeStyles()((theme: Theme) => ({
    chevronDown: {
        marginRight: -8,
        paddingLeft: 4,
    },
    checkIcon: {
        marginLeft: -8,
    },
    afterChevron: {
        marginRight: -10,
        paddingLeft: 12,
        color: "unset",
    }
}));

export interface IFilterButtonProps {
    title: string;
    onClick?: MouseEventHandler;
    isSelected?: boolean;
    hideChevronDown?: boolean;
    isDisabled?: boolean;
    suffix?: ReactNode;
    noCheckmark?: boolean;
}

export default function FilterButton(props: IFilterButtonProps) {
    const {
        title,
        onClick,
        isSelected,
        hideChevronDown,
        isDisabled,
        suffix,
        noCheckmark,
    } = props;
    const {classes} = useStyles();

    return (
        <TwoLineChip
            variant={isSelected ? "primary" : 'secondary'}
            text1={title}
            onClick={!isDisabled ? (e) => {
                if (onClick) {
                    onClick(e);
                }
            } : undefined}
            prefix={isSelected && !noCheckmark ?
                <Box pr={0.5} className={classes.checkIcon}><Icons8Done/></Box> : null}
            suffix={
                <>
                    {isSelected || hideChevronDown ? null :
                        <Box pl={0.5} className={classes.chevronDown}><ChevronDownIcon/></Box>
                    }
                    {suffix ?
                        <Box className={classes.afterChevron}>{suffix}</Box> : null}
                </>}
            isDisabled={isDisabled}
        />
    );
}
