import {makeStyles} from "tss-react/mui";
import {Box, Skeleton, Theme} from "@mui/material";
import React from "react";

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'flex',
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 10,
        paddingBottom: 10,
    },
    textsContainer: {
        marginLeft: 16,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    iconContainer: {
        display: "flex",
        alignItems: "center",
        marginLeft: 'auto',
    }
}));

export default function FormDetailShimmer() {
    const {classes} = useStyles();

    return (
        <>
            <Box pb={2}/>
            <Box className={classes.container}>
                <Skeleton variant={"rectangular"} width={200} height={22}></Skeleton>
            </Box>
            <Box pb={1}/>
            <Box className={classes.container}>
                <Skeleton variant={"rectangular"} width={160} height={16}></Skeleton>
            </Box>
            <Box pb={1}/>
            <Box className={classes.container}>
                <Skeleton variant={"rectangular"} height={24} width={24}></Skeleton>
                <Box className={classes.textsContainer}>
                    <Skeleton variant={"rectangular"} width={200} height={16}></Skeleton>
                </Box>
            </Box>
            <Box pb={1}/>
            <Box className={classes.container}>
                <Skeleton variant={"rectangular"} height={24} width={24}></Skeleton>
                <Box className={classes.textsContainer}>
                    <Skeleton variant={"rectangular"} width={200} height={16}></Skeleton>
                </Box>
            </Box>
            <Box pb={1}/>
            <Box className={classes.container}>
                <Skeleton variant={"rectangular"} height={24} width={24}></Skeleton>
                <Box className={classes.textsContainer}>
                    <Skeleton variant={"rectangular"} width={200} height={16}></Skeleton>
                </Box>
            </Box>
        </>
    );
}