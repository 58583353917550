import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import React, {MouseEventHandler, useContext} from "react";
import AppModal from "./AppModal";
import {tt} from "../../../core/Localization";
import AppChip from "../chips/AppChip";
import {kAppColors, kContentWidthNarrow} from "../../../styles/AppThemeProcessor";
import {AppDataContext} from "../../../AppData";
import {HideConfirmModal} from "./AppModals";

export const useStyles = makeStyles()((theme: Theme) => ({
    modalPaper: {
        maxWidth: kContentWidthNarrow,
    },
    chip: {
        flexGrow: 1,
    },
    confirmChip: {
        flexGrow: 1,
    },
    closeButtonContainer: {
        flexGrow: 1,
        display: "flex",
        '.MuiChip-root': {
            margin: "auto",
            minWidth: 190,
        }
    }
}));

export interface IConfirmationModalProps {
    children?: React.ReactNode;
    open: boolean;
    confirmationButtonText?: string;
    cancelButtonText?: string;
    onConfirm?: MouseEventHandler<HTMLDivElement>;
    onCancel?: MouseEventHandler<HTMLDivElement>;
    title: string;
    subtitle?: string | React.ReactNode;
    noContentPadding?: boolean;
    loading?: boolean;
    blurBackdrop?: boolean;
    confirmButtonColor?: string;
}

export default function ConfirmationModal(props: IConfirmationModalProps) {
    const {
        open,
        onConfirm,
        onCancel,
        subtitle,
        noContentPadding,
        confirmationButtonText,
        cancelButtonText,
        title,
        children,
        loading,
        blurBackdrop,
        confirmButtonColor,
    } = props;

    const appDataContext = useContext(AppDataContext);

    const {classes, cx} = useStyles();

    const confirmText = confirmationButtonText == null ? tt('common.confirm') : confirmationButtonText;
    const cancelText = cancelButtonText == null ? tt('common.cancel') : cancelButtonText;

    const actionsJSX = !onConfirm ? (
        <Box key={'cancelButtonKey'} className={classes.closeButtonContainer}>
            <AppChip
                onClick={(event) => {
                    onCancel?.(event);

                    HideConfirmModal(appDataContext);
                }}
                chipstyle={"secondary"}
                label={cancelText}
                disabled={loading}
            />
        </Box>
    ) : (
        <>
            <AppChip
                sx={{
                    backgroundColor: `${confirmButtonColor || kAppColors.red.confirmButton} !important`,
                    ":hover": `${confirmButtonColor || kAppColors.red.confirmButton} !important`,
                }}
                className={classes.confirmChip}
                key={'confirmButtonKey'}
                onClick={onConfirm}
                chipstyle={"primary"}
                label={confirmText}
                isLoading={loading}
            />

            <Box key={'confirmPopupSpaceKey'} pr={2}/>

            <AppChip
                className={classes.chip}
                key={'cancelButtonKey'}
                onClick={(event) => {
                    onCancel?.(event);

                    HideConfirmModal(appDataContext);
                }}
                disabled={loading}
                chipstyle={"secondary"}
                label={cancelText}
            />
        </>
    );

    return (
        <AppModal
            modalAboveModals={true}
            blurBackdrop={blurBackdrop}
            paperClassName={classes.modalPaper}
            noContentPadding={noContentPadding}
            title={title}
            subtitle={subtitle}
            open={open}
            centeredHeader={true}
            actions={actionsJSX}>
            {children}
            <Box pb={1.75}/>
        </AppModal>
    );
}
