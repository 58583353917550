import {iconUseStyles} from "../../styles/IconStyles";

export interface ISKIconProps {
    className?: string;
}

export default function RUIcon(props: ISKIconProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                 width="24"
                 height="24"
                 viewBox="0 0 48 48">
                <path fill="#E0E1E2" d="M24,4C15.421,4,8.125,9.412,5.284,17h37.431C39.875,9.412,32.579,4,24,4z"/>
                <path fill="#3F51B5" d="M44,24c0-2.467-0.468-4.818-1.284-7H5.284C4.468,19.182,4,21.533,4,24s0.468,4.818,1.284,7h37.431
	C43.532,28.818,44,26.467,44,24z"/>
                <path fill="#FF3D00" d="M42.716,31H5.284C8.125,38.588,15.421,44,24,44S39.875,38.588,42.716,31z"/>
            </svg>
        </span>
    );
}
