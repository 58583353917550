import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../../../App";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import {Box, InputAdornment} from "@mui/material";
import {tt} from "../../../core/Localization";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import AppChip from "../../components/chips/AppChip";
import {AddOutlined} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {AppDataContext} from "../../../AppData";
import CreateJobFormModal from "../../components/modals/jobForms/CreateJobFormModal";
import AppListItem from "../../components/listItems/AppListItem";
import Icons8ClipBoardList from "../../../icons/Icons8ClipBoardList";
import {kMobileMenuRoute} from "../mobile/MobileMenuScreen";
import AppIconButton from "../../components/buttons/AppIconButton";
import Icons8ClipBoardListPlus from "../../../icons/Icons8ClipBoardListPlus";
import {jobFormDetailRoute} from "./JobFormDetailScreen";
import {GetJobFormsInput, JobFormResponsePage, UpdateJobFormPositionInput} from "../../../generated/graphql/graphql";
import EmptyListText from "../../components/textComponents/EmptyListText";
import OneLineListItemShimmer from "../../components/shimmers/OneLineListItemShimmer";
import {processMutationError, processQueryError} from "../../../service/ErrorService";
import FormBuilder, {IInputsData, InputType} from "../../components/form/FormBuilder";
import SearchIcon from "../../../icons/SearchIcon";
import {kActionCreate, kActionUpdate, kPermissionsForms, kTopicJobFormTemplates} from "../../../core/constants";
import PermissionValid from "../../components/permissions/PermissionValid";
import PencilIcon from "../../../icons/PencilIcon";
import CloseIcon from "../../../icons/CloseIcon";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import {DragDropContext, Draggable, Droppable, DropResult, ResponderProvided} from "react-beautiful-dnd";
import Icons8MoveGrabber from "../../../icons/Icons8MoveGrabber";
import {SuccessToast} from "../../../service/ToastService";

export const kJobFormsRoute = '/job-forms';

export default function JobFormsScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe, restApiGet, restApiPost} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    useEffect(() => {
        setTitle(tt('jobForms.screen.title'));
    }, []);

    const [editMode, setEditMode] = useState(false);
    const [positionsForForms, setPositionsForForms] = useState<Record<number, number>>({});
    const [newJobForm, setNewJobForm] = useState(false);

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            type: InputType.Text,
            label: '',
            placeholder: tt('jobForms.screen.search.placeholder'),
            hideLabel: true,
            inputVariant: 'standard',
            extraStyle: 'thin',
            value: '',
            required: true,
            isClearable: true,
            innerPrefixJSX: (
                <InputAdornment position={"start"}>
                    <SearchIcon/>
                </InputAdornment>
            ),
        },
    });

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<JobFormResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            /**
             * OnData update data and set positions for later use of drag and drop.
             */
            const onData = (data: JobFormResponsePage) => {
                setData(data);

                if (data) {
                    const newPositionsForForms: Record<number, number> = {};

                    for (let i = 0; i < data.content.length; i++) {
                        newPositionsForForms[data.content[i].id] = data.content[i].position;
                    }

                    setPositionsForForms(newPositionsForForms);
                }
            };

            if (editMode) {
                restApiGet({
                    uri: '/job-form/search-by-company',
                    params: {
                        companyId: companyId,
                        isTemplate: true,
                    } as GetJobFormsInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading,
                    onData,
                    onError: (error) => processQueryError(appDataContext, error),
                });
            } else {
                const subscription = subscribe(
                    kTopicJobFormTemplates,
                    {
                        uri: '/job-form/search-by-company',
                        params: {
                            companyId: companyId,
                            isTemplate: true,
                        } as GetJobFormsInput,
                        setLoading,
                        onData,
                        onError: (error) => processQueryError(appDataContext, error),
                    },
                    (notifications) => true,
                );

                return () => subscription.cancel();
            }
        } else {
            setLoading(false);
            setData(null);
        }
    }, [companyId, editMode]);

    /**
     * On drag and drop update positions of forms.
     */
    const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
        if (result.destination) {
            const oldIndex = result.source.index;
            const newIndex = result.destination!.index;

            const newPositionsForForms = {...positionsForForms};
            let formIdsByPosition = Object.keys(newPositionsForForms).map(e => parseInt(e));
            formIdsByPosition = formIdsByPosition.sort((a, b) => {
                const aPos = newPositionsForForms[a];
                const bPos = newPositionsForForms[b];

                if (aPos === bPos) {
                    return 0;
                }

                return aPos > bPos ? 1 : -1;
            });

            const [removed] = formIdsByPosition.splice(oldIndex, 1);

            formIdsByPosition.splice(newIndex, 0, removed);

            for (let id of formIdsByPosition) {
                newPositionsForForms[id] = formIdsByPosition.indexOf(id);
            }

            setPositionsForForms(newPositionsForForms);
        }
    };

    const [updatePositionsLoading, setUpdatePositionsLoading] = useState(false);
    /**
     * Update form positions to BE.
     */
    const updateJobFormPositions = () => {
        const jobFormIds = Object.keys(positionsForForms).map(e => parseInt(e));
        const positions: number[] = [];

        for (let id of jobFormIds) {
            positions.push(positionsForForms[id]);
        }

        restApiPost({
            uri: '/job-form/update-position',
            params: {
                jobFormIds,
                positions,
            } as UpdateJobFormPositionInput,
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading: setUpdatePositionsLoading,
            onData: (data) => {
                if (data) {
                    SuccessToast(tt('jobForms.screen.updateJobFormPositions.success'));

                    // refresh cache
                    restApiGet({
                        uri: '/job-form/search-by-company',
                        params: {
                            companyId: companyId,
                            isTemplate: true,
                        } as GetJobFormsInput,
                        fetchPolicy: FetchPolicy.NetworkOnly,
                        onData: (data) => {
                            setData(data);
                            setEditMode(false);
                        },
                        onError: (error) => processQueryError(appDataContext, error),
                    });
                }
            },
            onError: (error) => processMutationError(error),
        });
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Body
                    loading={loading}
                    data={data}
                    search={inputs.search.value.toLowerCase()}
                    inputs={inputs}
                    setInputs={setInputs}
                    setModal={setNewJobForm}
                    isMobile={isMobile}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    positionsForForms={positionsForForms}
                    updateJobFormPositions={updateJobFormPositions}
                />
            </DragDropContext>
        );
    }

    return (
        <>
            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}
            />

            <CreateJobFormModal open={newJobForm} setOpen={setNewJobForm}/>
        </>
    );
}

interface IBodyProps {
    loading: boolean;
    data?: JobFormResponsePage | NullOrUndefined;
    search: string;
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    setModal: Dispatch<SetStateAction<boolean>>;
    isMobile?: boolean;
    editMode: boolean;
    setEditMode: Dispatch<SetStateAction<boolean>>;
    positionsForForms: Record<number, number>;
    updateJobFormPositions: VoidFunction;
}

function Body(props: IBodyProps) {
    const {
        loading,
        data,
        search,
        inputs,
        setInputs,
        setModal,
        isMobile,
        editMode,
        setEditMode,
        positionsForForms,
        updateJobFormPositions,
    } = props;

    const navigate = useNavigate();

    const theData = useMemo(() => {
        let theData = data?.content
            .filter((form) => {
                return form.name.toLowerCase().includes(search);
            });

        if (editMode && theData) {
            theData = theData.sort((a, b) => {
                const aPos = positionsForForms[a.id];
                const bPos = positionsForForms[b.id];

                if (aPos === bPos) {
                    return 0;
                }

                return aPos > bPos ? 1 : -1;
            });
        } else if (theData) {
            theData = theData.sort((a, b) => {
                if (a.position === b.position) {
                    return 0;
                }

                return a.position > b.position ? 1 : -1;
            });
        }

        return theData;
    }, [data, positionsForForms, editMode, search]);

    let formsJSX = loading && !theData ? (
            <>
                <OneLineListItemShimmer showIcon={true}/>
                <OneLineListItemShimmer showIcon={true}/>
                <OneLineListItemShimmer showIcon={true}/>
            </>
        ) :
        theData!.length === 0 ? (
                <EmptyListText text={tt('jobForms.screen.emptyListMessage')}/>
            ) :
            theData!.map((form) => {
                const itemJSX = (
                    <AppListItem
                        key={form.id}
                        customAvatar={<Icons8ClipBoardList/>}
                        title={form.name}
                        variant={"smaller-title"}
                        onClick={() => {
                            navigate(jobFormDetailRoute(form.id));
                        }}
                        actionWidget={editMode ? (
                            <AppIconButton onClick={() => {
                            }}>
                                <Icons8MoveGrabber/>
                            </AppIconButton>
                        ) : undefined}
                    />
                );

                return editMode ? (
                    <Draggable
                        key={form.id}
                        draggableId={`forms-${form.id}`}
                        index={positionsForForms[form.id]}
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                {itemJSX}
                            </div>
                        )}
                    </Draggable>
                ) : itemJSX;
            });

    if (editMode && theData && theData.length > 0) {
        const draggableItems = formsJSX;

        formsJSX = (
            <Droppable
                droppableId="forms"
                type="form"
            >
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={{backgroundColor: 'transparent', width: '100%'}}
                        {...provided.droppableProps}
                    >
                        {draggableItems}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );
    }

    const actionsJSX = editMode ? (
        <>
            <Box pr={1}>
                <AppChip
                    onClick={updateJobFormPositions}
                    chipstyle={"primary"}
                    label={tt('jobForms.screen.edit.save')}
                />
            </Box>

            <AppIconButton
                tooltip={tt('jobForms.screen.edit.cancel')}
                onClick={(e) => {
                    setEditMode(false);
                }}
            >
                <CloseIcon/>
            </AppIconButton>
        </>
    ) : (
        <>
            <PermissionValid
                permission={kPermissionsForms}
                requiredPermissions={[kActionUpdate]}
            >
                <Box pr={1}>
                    <AppIconButton
                        tooltip={tt('jobForms.screen.edit')}
                        onClick={(e) => {
                            setEditMode(true);
                        }}
                    >
                        <Icons8MoveGrabber/>
                    </AppIconButton>
                </Box>
            </PermissionValid>

            <PermissionValid
                permission={kPermissionsForms}
                requiredPermissions={[kActionCreate]}
            >
                {isMobile ? (
                    <AppIconButton
                        variant={"primaryBg"}
                        key={'newJobFormButtonMobileKey'}
                        onClick={() => setModal(true)}
                    >
                        <Icons8ClipBoardListPlus/>
                    </AppIconButton>
                ) : (
                    <AppChip
                        onClick={() => setModal(true)}
                        key={'newJobFormButtonKey'} label={tt('common.newJobForm')}
                        icon={<AddOutlined/>}/>
                )}
            </PermissionValid>
        </>
    );

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    noMarginBottom={true}
                    isMobile={isMobile}
                    title={tt('common.jobForms')}
                    hideBackButton={!isMobile}
                    backRoute={kMobileMenuRoute}
                    bottomContent={(
                        <Box pt={1}>
                            <FormBuilder inputs={inputs} setInputs={setInputs}/>
                        </Box>
                    )}
                >
                    {actionsJSX}
                </PaperAppbar>
                {formsJSX}
            </AppPaper>
        </ScreenContent>
    );
}
