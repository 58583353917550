import {Box, Divider, Grid, Link, Theme, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import FormBuilder, {IInputsData, InputType} from "../form/FormBuilder";
import {tt} from "../../../core/Localization";
import {kLocationTypesOptions, LocationTypeOptionByValue} from "../../../service/LocationService";
import AppButton from "../buttons/AppButton";
import AppIconButton from "../buttons/AppIconButton";
import Icons8CloneFigure from "../../../icons/Icons8CloneFigure";
import DeleteIcon from "../../../icons/DeleteIcon";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {SetConfirmModal} from "../modals/AppModals";
import {AppDataContext, IAppDataContext} from "../../../AppData";
import Icons8Here from "../../../icons/Icons8-here";
import PlaceEditScreenShimmer from "../shimmers/screenSpecificShimmers/PlaceEditScreenShimmer";

export const useStyles = makeStyles()((theme: Theme) => ({
    submitRightContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    button: {
        "@media (max-width: 767px)": {
            flexGrow: 1,
        }
    },
    deleteIcon: {
        color: kAppColors.red.confirmButton,
        'svg': {height: 24, width: 24}
    },
    placesDivider: {
        display: "none",
        "@media (max-width: 600px)": {
            display: "block"
        },
        marginLeft: -16,
        marginRight: -16,
        marginBottom: 20,
    },
    suffixIconsContainer: {
        paddingTop: 5,
    },
    addPlaceButton: {
        "@media (max-width: 600px)": {
            marginBottom: 16,
        }
    },
    helperButtonContainer: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        height: 38,
        marginBottom: 11,
    },
    helperButton: {
        color: kAppColors.text.primary(theme.palette.mode === "dark") + ' !important',
        textDecoration: "underline",
        cursor: "pointer",
    },
    helperText: {
        color: theme.palette.mode == 'dark' ? '#989898' : '#65676B',
        fontSize: '0.75rem',
        marginTop: -16,
        marginLeft: 12,
    },
}));

export interface ILocationPlaceFormProps {
    isEdit?: boolean;
    name?: string;
    type?: string;
    icon?: string;
    onSubmit: (inputs: IInputsData, setInputs: Dispatch<SetStateAction<IInputsData>>, placesCount: number) => void;
    loading?: boolean;
    multiple?: boolean;
    hideHelperLink?: boolean;
    dataLoading?: boolean;
    updateLoading?: boolean;
}

/**
 * Form component for Location Place.
 */
export default function LocationPlaceForm(props: ILocationPlaceFormProps) {
    const {
        isEdit,
        name,
        type,
        icon,
        onSubmit,
        loading,
        multiple,
        hideHelperLink,
        dataLoading,
        updateLoading,
    } = props;

    const appDataContext = useContext(AppDataContext);

    const {classes} = useStyles();

    const [placesCount, setPlacesCount] = useState<number>(1);
    const [removePlaceIndex, setRemovePlaceIndex] = useState<number>();
    const [duplicatePlaceIndex, setDuplicatePlaceIndex] = useState<number>();
    const [inputs, setInputs] = useState<IInputsData>({});
    const [invalidateForm, setInvalidateForm] = useState<boolean>(false);

    useEffect(() => {
        FormData(
            {
                appDataContext,
                isEdit,
                inputs,
                setInputs,
                setInvalidateForm,
                placesCount,
                setPlacesCount,
                removePlaceIndex,
                setRemovePlaceIndex,
                duplicatePlaceIndex,
                setDuplicatePlaceIndex,
                classes,
                multiple,
                hideHelperLink,
            },
        );
    }, [placesCount, invalidateForm]);

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                place_1_name: {
                    ...prev.place_1_name,
                    value: name || '',
                },
                place_1_type: {
                    ...prev.place_1_type,
                    value: LocationTypeOptionByValue(type || ''),
                    userData: icon || '',
                },
            };
        });
    }, [name, type, icon]);

    let submitJSX = (
        <AppButton
            className={classes.button}
            variant={"contained"}
            fullWidth={isEdit}
            onClick={() => onSubmit(inputs, setInputs, placesCount)}
            disabled={loading}
            isLoading={updateLoading}
        >
            {isEdit ? tt('common.save') : tt('common.create')}
        </AppButton>
    );

    submitJSX = isEdit ? submitJSX : (
        <div className={classes.submitRightContainer}>
            {submitJSX}
        </div>
    );

    return (
        <>
            {dataLoading ? <PlaceEditScreenShimmer/> : <FormBuilder inputs={inputs} setInputs={setInputs}/>}

            {submitJSX}
        </>
    );
}

interface IFormDataProps {
    appDataContext: IAppDataContext;
    isEdit?: boolean;
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    setInvalidateForm: Dispatch<SetStateAction<boolean>>;
    placesCount: number;
    setPlacesCount: Dispatch<SetStateAction<number>>;
    removePlaceIndex?: number;
    setRemovePlaceIndex: Dispatch<SetStateAction<number | undefined>>;
    duplicatePlaceIndex?: number;
    setDuplicatePlaceIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
    classes: Record<string, string>;
    multiple?: boolean;
    hideHelperLink?: boolean
}

/**
 * Form data based on state.
 */
function FormData(props: IFormDataProps) {
    const {
        appDataContext,
        isEdit,
        inputs,
        setInputs,
        setInvalidateForm,
        placesCount,
        setPlacesCount,
        removePlaceIndex,
        setRemovePlaceIndex,
        duplicatePlaceIndex,
        setDuplicatePlaceIndex,
        classes,
        multiple,
        hideHelperLink,
    } = props;

    /**
     * Add another Place to form.
     */
    const AddPlace = () => setPlacesCount(prev => prev + 1);

    /**
     * Remove Place from form and make sure data for others is not lost.
     */
    const RemovePlace = (i: number) => {
        setRemovePlaceIndex(i);

        setPlacesCount(prev => {
            if (prev - 1 === 0) {
                setTimeout(() => {
                    setInputs(prev => {
                        return {
                            ...prev,
                            addPlaces: {
                                ...prev.addPlaces,
                                value: false,
                            },
                        };
                    });
                }, 1);
            }

            return prev - 1;
        });
    };

    /**
     * Duplicate existing Place.
     */
    const DuplicatePlace = (i: number) => {
        setDuplicatePlaceIndex(i);

        AddPlace();
    };

    const newInputs: IInputsData = {};

    const iterateOver = removePlaceIndex !== undefined ? placesCount + 1 : placesCount;
    let inputIndex = 1;
    let hadDuplicate = false;

    for (let i = 1; i <= iterateOver; i++) {
        if (i === removePlaceIndex) {
            continue;
        }

        let valueIndex = i;

        const prevIndex = i - 1;
        const isDuplicateOfPrev = duplicatePlaceIndex !== undefined && duplicatePlaceIndex === prevIndex;

        if (isDuplicateOfPrev) {
            valueIndex = prevIndex;
            hadDuplicate = true;
        }
        if (hadDuplicate) {
            valueIndex = i - 1;
        }

        newInputs[`place_${inputIndex}_name`] = {
            type: InputType.Text,
            label: `${tt('common.placeName')}*`,
            value: inputs[`place_${valueIndex}_name`]?.value || '',
            required: true,
            grid: {
                sm: 6,
                xs: 12
            },
        };

        ((inputIndex) => {
            newInputs[`place_${inputIndex}_type`] = {
                ...inputs[`place_${valueIndex}_type`],
                type: InputType.TextAutocomplete,
                label: `${tt('common.type')}*`,
                value: inputs[`place_${valueIndex}_type`]?.value || '',
                autocompleteOptions: kLocationTypesOptions(true),
                required: true,
                overlayEndSuffixJSX: () => <Box marginTop={1.75} marginRight={1}><Icons8Here/></Box>,
                suffixJSX: multiple ? () => (
                    <Grid className={classes.suffixIconsContainer}>
                        <AppIconButton
                            tooltip={tt('common.duplicate')}
                            onClick={() => DuplicatePlace(i)}
                        >
                            <Icons8CloneFigure/>
                        </AppIconButton>

                        {
                            placesCount > 1 ? (
                                <AppIconButton
                                    tooltip={tt('common.delete')}
                                    onClick={() => RemovePlace(i)}
                                >
                                    <DeleteIcon className={classes.deleteIcon}/>
                                </AppIconButton>
                            ) : null
                        }
                    </Grid>
                ) : undefined,
                grid: {
                    sm: 6,
                    xs: 12
                },
                helperText: <Typography className={classes.helperText}
                                        paragraph={true}>{tt('common.type.helperText')}</Typography>,
            };
        })(inputIndex);

        if (isEdit) {
            newInputs[`place_${inputIndex}_phone`] = {
                type: InputType.ButtonTextField,
                label: tt('common.phone'),
                toggleButtonText: tt('common.addPhone'),
                value: inputs[`place_${valueIndex}_phone`]?.value || '',
                required: false,
                inputMode: 'tel',
            };
        }

        if (i < placesCount) {
            newInputs[`place_${inputIndex}_divider`] = {
                type: InputType.CustomRenderer,
                label: '',
                value: '',
                render: () => (
                    <Divider className={classes.placesDivider}/>
                ),
            };
        }

        inputIndex++;
    }

    if (multiple) {
        newInputs.addPlace = {
            type: InputType.CustomRenderer,
            label: '',
            value: '',
            grid: {
                xs: 6
            },
            render: () => (
                <Box className={classes.addPlaceButton}>
                    <AppButton
                        variant={"textThin"}
                        fullWidth={false}
                        onClick={AddPlace}>
                        {tt('common.addPlace')}
                    </AppButton>
                </Box>
            ),
        };
        newInputs.link = {
            type: InputType.CustomRenderer,
            label: '',
            value: '',
            grid: {
                xs: 6
            },
            hidden: hideHelperLink,
            render: () => (
                <Box className={classes.helperButtonContainer}>
                    <Link className={classes.helperButton}
                          onClick={() => {
                              SetConfirmModal(
                                  appDataContext,
                                  {
                                      blurBackdrop: true,
                                      open: true,
                                      title: tt('placesHelperModal.title'),
                                      subtitle: undefined,
                                      children:
                                          <Typography className={classes.helperModalText}
                                                      dangerouslySetInnerHTML={{__html: `<p>${tt('placesHelperModal.text').replace('&&bold', `<Strong>`).replace('&&boldEnd', `</Strong>`)}</p>`}}></Typography>,
                                      cancelButtonText: tt('common.close'),
                                      onConfirm: undefined,
                                  }
                              );
                          }}
                    >
                        {tt('createLocation.modal.helperText')}
                    </Link>
                </Box>
            ),
        };
    }

    if (removePlaceIndex !== undefined) {
        setRemovePlaceIndex(undefined);
    }
    if (duplicatePlaceIndex !== undefined) {
        setDuplicatePlaceIndex(undefined);
    }

    setInputs(newInputs);
}
