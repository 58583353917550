import IFileState, {IFileStateType} from "../../../../model/FileState";
import {Box, Theme} from "@mui/material";
import Icons8Done from "../../../../icons/Icons8Done";
import React, {useContext, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import {useMutation} from "@apollo/client";
import {
    CreateFileDocument,
    CreateFileInput,
    CreateFileMutation,
    CreateFileMutationVariables
} from "../../../../generated/graphql/graphql";
import {storeFileToFirebase} from "../../../../service/StorageService";
import {processMutationError} from "../../../../service/ErrorService";
import CircularProgressWithLabel from "../../storage/CircularProgressWithLabel";
import {AppDataContext} from "../../../../AppData";

export const useStyles = makeStyles()((theme: Theme) => ({
    galleryImage: {
        position: "relative",
        cursor: "pointer",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
        height: 80,
        width: 80,
        backgroundSize: "cover",
        flexShrink: 0,
        "@media (max-width: 767px)": {
            flexGrow: 1,
            height: "auto",
            width: "auto",
            paddingTop: '100%',
        }
    },
    check: {
        position: "absolute",
        right: 4,
        top: 4,
        borderRadius: 24,
        width: 24,
        height: 24,
        background: kAppColors.primary.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        'div, svg': {
            width: 18,
            height: 18,
        }
    },
    notChecked: {
        background: "transparent",
        border: `1px solid ${kAppColors.border(theme.palette.mode === "dark")}`,
        backdropFilter: `saturate(180%) blur(10px)`,
        'svg': {
            display: "none",
        }
    },
    spinner: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}));

export interface IUploadProtocolListItemProps {
    fileState: IFileState;
    onUpdateFileState: (updateFileState: (fileState: IFileState) => IFileState, fileUploadFinished?: boolean) => void;
    onDelete: (uuid: string) => void;
    isSelected: boolean;
    onSelect: (uuid: string) => void;
}

/**
 * Component used to display and handle protocol file upload.
 */
export default function UploadProtocolListItem(props: IUploadProtocolListItemProps) {
    const {fileState, onUpdateFileState, onDelete, isSelected, onSelect} = props;

    const appDataContext = useContext(AppDataContext);
    const {storage} = appDataContext;

    const {cx, classes} = useStyles();

    const theProgress = fileState.progress || 0;
    const url = storage.publicUrlsForFiles[fileState.uuid];

    const [mutateCreateFile, {
        loading: createFileLoading,
    }] = useMutation<CreateFileMutation, CreateFileMutationVariables>(CreateFileDocument);

    useEffect(() => {
        if (fileState.inputFile && fileState.type === IFileStateType.Select) {
            onUpdateFileState((fileState) => ({
                ...fileState,
                type: IFileStateType.Upload,
            }));

            FileChosen();
        }
    }, [fileState.inputFile]);

    /**
     * On file chosen upload, send to BE and on success change state to file.
     */
    const FileChosen = () => {
        storeFileToFirebase({
            fileState,
            onUpdateFileState,
            onDelete,
            onComplete: UpdateFileStateToBE,
        });
    };

    /**
     * On file upload success, update file state to BE.
     */
    const UpdateFileStateToBE = async (input: CreateFileInput) => {
        try {
            const variables: CreateFileMutationVariables = {
                input,
            };

            const result = await mutateCreateFile({variables});

            if (!result.errors) {
                onUpdateFileState((fileState) => ({
                    ...fileState,
                    type: IFileStateType.File,
                    data: result.data?.createFile,
                }), true);
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    const progressJSX = fileState.type === IFileStateType.Select || fileState.type === IFileStateType.Upload ? (
        <Box className={classes.spinner}>
            <CircularProgressWithLabel value={theProgress}/>
        </Box>
    ) : null;

    return (
        <Box
            onClick={() => {
                onSelect(fileState.uuid);
            }}
            className={classes.galleryImage}
            style={{
                backgroundImage: url ? `url("${url}")` : undefined,
                backgroundSize: url ? "cover" : undefined,
                backgroundPosition: url ? "center" : undefined,
                backgroundRepeat: url ? "no-repeat" : undefined,
            }}
        >
            {progressJSX}

            <Box className={cx(classes.check, isSelected ? undefined : classes.notChecked,)}>
                <Icons8Done/>
            </Box>
        </Box>
    );
}
