import {iconUseStyles} from "../styles/IconStyles";

export interface ILightModeIconProps {
    className?: string;
}

export default function LightModeIcon(props: ILightModeIconProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <g id="icons8-sun" transform="translate(0 0)">
                    <path id="icons8-sun-2" data-name="icons8-sun" d="M13.988,3.978a.75.75,0,0,0-.738.76v1.5a.75.75,0,0,0,1.5,0v-1.5a.75.75,0,0,0-.762-.76ZM7.451,6.69a.75.75,0,0,0-.522,1.287l1.061,1.06A.75.75,0,1,0,9.051,7.976L7.99,6.916A.75.75,0,0,0,7.451,6.69Zm13.074,0a.75.75,0,0,0-.516.226l-1.061,1.06a.75.75,0,0,0,1.062,1.061l1.061-1.06a.75.75,0,0,0-.546-1.287ZM14,8.487a5.5,5.5,0,1,0,5.5,5.5A5.5,5.5,0,0,0,14,8.487ZM4.75,13.234a.75.75,0,1,0,0,1.5h1.5a.75.75,0,1,0,0-1.5Zm17,0a.75.75,0,1,0,0,1.5h1.5a.75.75,0,1,0,0-1.5ZM8.5,18.7a.75.75,0,0,0-.516.226L6.929,19.99A.75.75,0,1,0,7.99,21.051l1.061-1.06A.75.75,0,0,0,8.5,18.7Zm10.967,0a.75.75,0,0,0-.522,1.287l1.061,1.06a.75.75,0,0,0,1.062-1.061l-1.061-1.06a.75.75,0,0,0-.539-.226Zm-5.483,2.265a.75.75,0,0,0-.738.76v1.5a.75.75,0,0,0,1.5,0v-1.5a.75.75,0,0,0-.762-.76Z" transform="translate(-4 -3.979)"/>
                </g>
            </svg>
        </span>
    );
}


