import {iconUseStyles} from "../styles/IconStyles";

export interface IWorkersProps {
    className?: string;
}

/**
 * Component representing Home svg icon.
 */
export default function WorkersIcon(props: IWorkersProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px"
                 preserveAspectRatio="xMidYMid meet" focusable="false">
                <path
                    d="M 27.5 5 C 26.137055 5 25 6.1370551 25 7.5 L 25 8.0507812 C 19.821385 9.9124445 16.317672 14.508082 15.333984 21.005859 A 2.5 2.5 0 0 0 13 23.5 L 13 23.839844 C 6.9880354 25.944503 3.4215325 31.731543 3.0390625 40 L 1.5 40 A 1.50015 1.50015 0 1 0 1.5 43 L 4.5 43 L 31.5 43 L 34.5 43 A 1.50015 1.50015 0 1 0 34.5 40 L 32.960938 40 C 32.702124 34.404818 30.984491 29.949455 28.037109 27 L 43.5 27 L 46.5 27 A 1.50015 1.50015 0 1 0 46.5 24 L 44.835938 24 C 44.642515 19.985607 43.833273 16.440678 42.162109 13.693359 C 40.483376 10.933595 38.00022 8.9704857 35 7.9082031 L 35 7.5 C 35 6.1370551 33.862945 5 32.5 5 L 27.5 5 z M 28 8 L 32 8 L 32 8.5 L 32 8.9492188 L 32 13.5 A 1.50015 1.50015 0 1 0 35 13.5 L 35 11.289062 C 36.854977 12.170435 38.460185 13.378789 39.599609 15.251953 C 40.925948 17.432396 41.643716 20.451746 41.837891 24 L 23.398438 24 C 23.26445 23.94843 23.136513 23.887634 23 23.839844 L 23 23.5 A 2.5 2.5 0 0 0 20.5 21 L 18.386719 21 C 19.228217 16.03255 21.540224 12.871717 25 11.271484 L 25 13.5 A 1.50015 1.50015 0 1 0 28 13.5 L 28 9.0214844 A 1.50015 1.50015 0 0 0 28 9 L 28 8.5 L 28 8 z M 16 24 L 20 24 L 20 25 A 1.5 1.5 0 0 0 20 25.003906 L 20 25.253906 A 1.50015 1.50015 0 0 0 20 25.740234 L 20 29.5 A 1.50015 1.50015 0 1 0 23 29.5 L 23 27.058594 C 27.237454 29.007085 29.683387 33.50064 30 40 L 6 40 C 6.3507431 33.520022 8.7853195 29.031537 13 27.107422 L 13 29.5 A 1.50015 1.50015 0 1 0 16 29.5 L 16 25 L 16 24 z"></path>
            </svg>
        </span>
    );
}
