import {tt} from "../core/Localization";
import Icons8Product from "../icons/Icons8Product";
import Icons8Services from "../icons/Icons8Services";
import {CreateProductInput, ProductResponse, ProductType} from "../generated/graphql/graphql";
import {v4 as uuidv4} from "uuid";

export function SortProductsByName(products: ProductResponse[], descending?: boolean): ProductResponse[] {
    if (descending) {
        return products.sort((a, b) => {
            return b.name.localeCompare(a.name)
        });
    }

    return products.sort((a, b) => {
        return a.name.localeCompare(b.name)
    });
}

export function GetProductTypeTitle(type: ProductType | NullOrUndefined, plural?: boolean) {
    switch (type) {
        case ProductType.Product:
            return plural ? tt('productType.plural.products') : tt('productType.product');
        case ProductType.Service:
            return plural ? tt('productType.plural.services') : tt('productType.service');
        default:
            return '';
    }
}

export function GetProductIcon(type: ProductType | NullOrUndefined) {
    switch (type) {
        case ProductType.Product:
            return <Icons8Product/>;
        case ProductType.Service:
            return <Icons8Services/>;
        default:
            return <></>;
    }
}

export interface IFilterProductsForJobParams {
    products?: ProductResponse[];
    visitId: number;
    repeatingDay?: number;
    filterDistinctByRepeatingDay?: boolean;
}

/*
 * Filter products by Visit and repeatingDay.
 * Filtered by repeatingDay also removes null repeatingDay for those that have repeatingDay.
 */
export function filterProductsForVisit(params: IFilterProductsForJobParams): ProductResponse[] {
    const {products, visitId, repeatingDay, filterDistinctByRepeatingDay} = params;

    const theFilterDistinctByRepeatingDay = filterDistinctByRepeatingDay === undefined ? true : filterDistinctByRepeatingDay;

    if (!products) {
        return [];
    }

    if (!repeatingDay) {
        return products.filter((product) => {
            return product.visitId === visitId && !product.deleted && !product.isTemplate;
        });
    } else {
        let data = products.filter((product) => {
            return product.visitId === visitId && !product.isTemplate && (product.repeatingDay === repeatingDay || !product.repeatingDay);
        });

        if (theFilterDistinctByRepeatingDay) {
            data = data.filter((item) => {
                if (item.repeatingDay === null) {
                    const repeatDayData = data.find((it) => {
                        return it.repeatingDay === repeatingDay && item.uuid === it.uuid;
                    });

                    if (repeatDayData) {
                        return false;
                    }
                }

                return true;
            });
        }

        return data.filter((product) => {
            return !product.deleted;
        });
    }
}

/**
 * Convert ProductResponse to CreateProductInput.
 */
export function convertProductResponseToCreateInput(product: ProductResponse): CreateProductInput {
    return {
        uuid: uuidv4(),
        name: product.name,
        description: product.description,
        price: product.price,
        vatRate: product.vatRate,
        unitName: product.unitName,
        type: product.type,
        sortOrder: product.sortOrder,
        unitCount: product.unitCount,
        employeeId: product.employeeId!,
    };
}
