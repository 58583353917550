import Menu from "@mui/material/Menu";
import {bindMenu} from "material-ui-popup-state";
import {Box, Divider, MenuItem, Theme, useTheme} from "@mui/material";
import React, {useMemo} from "react";
import {usePopupState} from "material-ui-popup-state/hooks";
import {makeStyles} from "tss-react/mui";
import IVisitEvent from "../../../model/VisitEvent";
import {
    getVisitStatusIcon,
    getVisitStatusModifierColor,
    getVisitStatusModifierIcon,
    getVisitStatusModifierTitle,
    getVisitStatusTextColor,
    getVisitStatusTitle
} from "../../../service/VisitService";
import {JobResponse, VisitStatus, VisitStatusModifier} from "../../../generated/graphql/graphql";
import {tt} from "../../../core/Localization";
import FilterButton from "../buttons/FilterButton";
import VisitStatusChip from "../chips/VisitStatusChip";
import GreyLabel from "../decorations/GreyLabel";

export const useStyles = makeStyles()((theme: Theme) => ({
    menuOffset: {
        '.MuiPaper-root': {
            minWidth: 200,
        },
        transform: 'translateY(8px)',
    },
    notScheduledSuffix: {
        'svg': {
            color: getVisitStatusTextColor(VisitStatus.ScheduleLater, theme.palette.mode === "dark") + ' !important',
        }
    }
}));

export interface IJobsByStatusFilterProps {
    statusFilter: VisitStatus | undefined;
    statusModifierFilter: VisitStatusModifier | undefined;
    isDisabled?: boolean;
    SetFilter: (status?: VisitStatus, statusModifier?: VisitStatusModifier) => void;
    unfilteredVisits?: IVisitEvent[];
    unfilteredJobs?: JobResponse[];
    notScheduledCount?: number;
}

export default function JobsByStatusFilter(props: IJobsByStatusFilterProps) {
    const {
        statusModifierFilter,
        statusFilter,
        isDisabled,
        SetFilter,
        unfilteredJobs,
        notScheduledCount,
    } = props;

    const {classes} = useStyles();
    const theme = useTheme();

    const statusFilters = [
        VisitStatus.Unfinished,
        VisitStatus.ScheduleLater,
        VisitStatus.JobOffer,
        VisitStatus.Scheduled,
        VisitStatus.Travelling,
        VisitStatus.InProgress,
        VisitStatus.Done,
    ];

    const statusModifierFilters = [
        VisitStatusModifier.Invoiced,
        VisitStatusModifier.Paid,
        VisitStatusModifier.NotPaid,
    ];

    const statusFilterTitle = statusModifierFilter ? getVisitStatusModifierTitle(statusModifierFilter) :
        statusFilter ? getVisitStatusTitle(statusFilter) : tt('jobs.filter.status.none');

    const statusFilterMenu = usePopupState({
        variant: 'popover',
        popupId: 'popup-jobs-status-filter',
    });

    const visitStatusesOnUnfilteredVisits = useMemo(() => {
        const statuses: VisitStatus[] = [];
        const statusModifiers: VisitStatusModifier[] = [];

        if (unfilteredJobs) {
            for (const checkStatus of statusFilters) {
                for (const job of unfilteredJobs) {
                    let breakFor = false;

                    if (job.stats) {
                        switch (checkStatus) {
                            case VisitStatus.JobOffer:
                                if (job.stats.jobOffer > 0) {
                                    statuses.push(VisitStatus.JobOffer);
                                    breakFor = true;
                                }
                                break;
                            case VisitStatus.ScheduleLater:
                                if (job.stats.scheduleLater > 0) {
                                    statuses.push(VisitStatus.ScheduleLater);
                                    breakFor = true;
                                }
                                break;
                            case VisitStatus.Scheduled:
                                if (job.stats.scheduled > 0) {
                                    statuses.push(VisitStatus.Scheduled);
                                    breakFor = true;
                                }
                                break;
                            case VisitStatus.Travelling:
                                if (job.stats.travelling > 0) {
                                    statuses.push(VisitStatus.Travelling);
                                    breakFor = true;
                                }
                                break;
                            case VisitStatus.InProgress:
                                if (job.stats.inProgress > 0) {
                                    statuses.push(VisitStatus.InProgress);
                                    breakFor = true;
                                }
                                break;
                            case VisitStatus.Done:
                                if (job.stats.done > 0) {
                                    statuses.push(VisitStatus.Done);
                                    breakFor = true;
                                }
                                break;
                        }
                    }

                    if (breakFor) {
                        break;
                    }
                }
            }

            for (const checkModifier of statusModifierFilters) {
                for (const job of unfilteredJobs) {
                    let breakFor = false;

                    if (job.stats) {
                        switch (checkModifier) {
                            case VisitStatusModifier.Invoiced:
                                if (job.stats.invoiced > 0) {
                                    statusModifiers.push(VisitStatusModifier.Invoiced);
                                    breakFor = true;
                                }
                                break;
                            case VisitStatusModifier.Paid:
                                if (job.stats.paid > 0) {
                                    statusModifiers.push(VisitStatusModifier.Paid);
                                    breakFor = true;
                                }
                                break;
                            case VisitStatusModifier.NotPaid:
                                if (job.stats.notPaid > 0) {
                                    statusModifiers.push(VisitStatusModifier.NotPaid);
                                    breakFor = true;
                                }
                                break;
                        }
                    }

                    if (breakFor) {
                        break;
                    }
                }
            }
        }

        return {
            statuses,
            statusModifiers,
            ongoing: statuses.filter(status => statusFilters.includes(status)).length > 0,
            finished: statuses.includes(VisitStatus.Closed) || statuses.includes(VisitStatus.Canceled) || statusModifiers.length,
        };
    }, [unfilteredJobs]);

    return (
        <>
            <FilterButton
                isSelected={!!statusFilter}
                title={statusFilterTitle}
                onClick={(e) => {
                    statusFilterMenu.setOpen(true, e);
                }}
                isDisabled={isDisabled}
                suffix={notScheduledCount && statusFilter !== VisitStatus.ScheduleLater ? (
                    <Box className={classes.notScheduledSuffix}>
                        <VisitStatusChip
                            status={VisitStatus.ScheduleLater}
                            customValue={notScheduledCount}/>
                    </Box>
                ) : null}
            />

            <Menu className={classes.menuOffset}  {...bindMenu(statusFilterMenu)}>
                <MenuItem
                    key={'All'}
                    onClick={() => {
                        statusFilterMenu.setOpen(false);

                        SetFilter(undefined);
                    }
                    }
                >
                    {tt('jobs.filter.status.all')}
                </MenuItem>

                {visitStatusesOnUnfilteredVisits.ongoing ? (
                    <Box pl={1} pr={1} pt={1.25} pb={1.25}>
                        <GreyLabel text={tt('dashboardScreen.tab.ongoing')}/>
                    </Box>
                ) : undefined}

                {statusFilters
                    .filter(filterType => visitStatusesOnUnfilteredVisits.statuses.includes(filterType))
                    .map(filterType => (
                        <MenuItem
                            key={filterType}
                            onClick={() => {
                                statusFilterMenu.setOpen(false);

                                SetFilter(filterType);
                            }
                            }
                        >
                            <Box
                                sx={{color: getVisitStatusTextColor(filterType, theme.palette.mode === "dark")}}>
                                {getVisitStatusIcon(filterType)}
                            </Box>{getVisitStatusTitle(filterType)}
                        </MenuItem>
                    ))}

                {visitStatusesOnUnfilteredVisits.ongoing && visitStatusesOnUnfilteredVisits.finished ? (
                    <Divider sx={{
                        mr: -2, ml: -2,
                    }}/>
                ) : undefined}

                {visitStatusesOnUnfilteredVisits.finished ? (
                    <Box pl={1} pr={1} pt={1.25} pb={1.25}>
                        <GreyLabel text={tt('dashboardScreen.tab.finished')}/>
                    </Box>
                ) : undefined}

                {visitStatusesOnUnfilteredVisits.statuses.includes(VisitStatus.Closed) ? (
                    <MenuItem
                        key={VisitStatus.Closed}
                        onClick={() => {
                            statusFilterMenu.setOpen(false);

                            SetFilter(VisitStatus.Closed);
                        }
                        }
                    >
                        <Box
                            sx={{color: getVisitStatusTextColor(VisitStatus.Closed, theme.palette.mode === "dark")}}>
                            {getVisitStatusIcon(VisitStatus.Closed)}
                        </Box>{getVisitStatusTitle(VisitStatus.Closed)}
                    </MenuItem>
                ) : undefined}

                {statusModifierFilters
                    .filter(filterType => {
                        return visitStatusesOnUnfilteredVisits.statuses.includes(VisitStatus.Closed) && visitStatusesOnUnfilteredVisits.statusModifiers.includes(filterType);
                    })
                    .map(filterType => (
                        <MenuItem
                            key={filterType}
                            onClick={() => {
                                statusFilterMenu.setOpen(false);

                                SetFilter(VisitStatus.Closed, filterType);
                            }
                            }
                        >
                            <Box
                                sx={{color: getVisitStatusModifierColor(filterType)}}>
                                {getVisitStatusModifierIcon(filterType)}
                            </Box>
                            {getVisitStatusModifierTitle(filterType)}
                        </MenuItem>
                    ))}

                {visitStatusesOnUnfilteredVisits.statuses.includes(VisitStatus.Canceled) ? (
                    <MenuItem
                        key={VisitStatus.Canceled}
                        onClick={() => {
                            statusFilterMenu.setOpen(false);

                            SetFilter(VisitStatus.Canceled);
                        }
                        }
                    >
                        <Box
                            sx={{color: getVisitStatusTextColor(VisitStatus.Canceled, theme.palette.mode === "dark")}}>
                            {getVisitStatusIcon(VisitStatus.Canceled)}
                        </Box>
                        {getVisitStatusTitle(VisitStatus.Canceled)}
                    </MenuItem>
                ) : undefined}
            </Menu>
        </>
    );
}
