import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8AttachmentProps {
    className?: string;
}

export default function Icons8Attachment(props: IIcons8AttachmentProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 32.427734 4.0371094 C 29.597703 4.0371094 26.767786 5.1111204 24.617188 7.2617188 L 4.4394531 27.439453 A 1.50015 1.50015 0 1 0 6.5605469 29.560547 L 26.738281 9.3828125 C 29.893085 6.228009 34.962384 6.228009 38.117188 9.3828125 C 41.271991 12.537616 41.271991 17.606915 38.117188 20.761719 L 19.074219 39.804688 C 17.438523 41.440384 14.83101 41.440384 13.195312 39.804688 C 11.559617 38.16899 11.559616 35.561477 13.195312 33.925781 L 28.560547 18.560547 A 1.50015 1.50015 0 1 0 26.439453 16.439453 L 11.074219 31.804688 C 8.2919146 34.58699 8.2919147 39.143477 11.074219 41.925781 C 13.856523 44.708085 18.413009 44.708085 21.195312 41.925781 L 40.238281 22.882812 C 44.539478 18.581616 44.539478 11.562915 40.238281 7.2617188 C 38.087683 5.1111204 35.257765 4.0371094 32.427734 4.0371094 z"/>
            </svg>
        </span>
    );
}