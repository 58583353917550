import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8AddressProps {
    className?: string;
}

export default function Icons8Address(props: IIcons8AddressProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 24 5 C 17.383 5 12 10.383 12 17 C 12 21.805 17.940422 30.180141 21.482422 34.744141 C 22.090422 35.528141 23.009 35.978516 24 35.978516 C 24.991 35.978516 25.909578 35.528141 26.517578 34.744141 C 30.059578 30.180141 36 21.805 36 17 C 36 10.383 30.617 5 24 5 z M 24 14 C 25.657 14 27 15.343 27 17 C 27 18.657 25.657 20 24 20 C 22.343 20 21 18.657 21 17 C 21 15.343 22.343 14 24 14 z M 11.724609 30 C 10.342609 30 9.0853906 30.816125 8.5253906 32.078125 L 6.3046875 37.078125 C 5.8216875 38.165125 5.9184062 39.40925 6.5664062 40.40625 C 7.2154063 41.40425 8.3129531 42 9.5019531 42 L 38.496094 42 C 39.685094 42 40.783641 41.40425 41.431641 40.40625 C 42.080641 39.40925 42.178313 38.166125 41.695312 37.078125 L 39.474609 32.078125 C 38.913609 30.816125 37.658391 30 36.275391 30 L 33.019531 30 C 32.382531 30.965 31.691312 31.964 30.945312 33 L 36.275391 33 C 36.472391 33 36.652422 33.115875 36.732422 33.296875 L 38.953125 38.296875 C 39.049125 38.512875 38.972016 38.688437 38.916016 38.773438 C 38.860016 38.858438 38.734047 39 38.498047 39 L 9.5019531 39 C 9.2659531 39 9.1370312 38.856484 9.0820312 38.771484 C 9.0270313 38.686484 8.9499219 38.512875 9.0449219 38.296875 L 11.267578 33.296875 C 11.347578 33.116875 11.527609 33 11.724609 33 L 17.052734 33 C 16.305734 31.964 15.616469 30.965 14.980469 30 L 11.724609 30 z"/>
            </svg>
        </span>
    );
}
























