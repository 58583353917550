import {iconUseStyles} from "../styles/IconStyles";

export interface IChevronDownProps {
    className?: string;
}

export default function ChevronDownIcon(props: IChevronDownProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="12.141" height="7.57"
                 viewBox="0 0 12.141 7.57">
                <g id="icons8-forward" transform="translate(12.141) rotate(90)">
                    <path id="icons8-forward-2" data-name="icons8-forward"
                          d="M10,5.93,8.5,7.43,13.07,12,8.5,16.57l1.5,1.5L16.07,12Z" transform="translate(-8.5 -5.93)"/>
                </g>
            </svg>
        </span>
    );
}
