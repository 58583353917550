import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8GalleryProps {
    className?: string;
}

/**
 * Component representing Icons8Gallery svg icon.
 */
export default function Icons8Gallery(props: IIcons8GalleryProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 12.5 5 C 8.3754991 5 5 8.3754991 5 12.5 L 5 30.5 C 5 34.624501 8.3754991 38 12.5 38 L 30.5 38 C 34.624501 38 38 34.624501 38 30.5 L 38 12.5 C 38 8.3754991 34.624501 5 30.5 5 L 12.5 5 z M 12.5 8 L 30.5 8 C 33.003499 8 35 9.9965009 35 12.5 L 35 30.5 C 35 31.197097 34.832123 31.847273 34.554688 32.433594 L 24.273438 22.154297 C 23.513596 21.394455 22.507159 21.013672 21.5 21.013672 C 20.492841 21.013672 19.484451 21.394455 18.724609 22.154297 L 8.4453125 32.433594 C 8.1678771 31.847273 8 31.197097 8 30.5 L 8 12.5 C 8 9.9965009 9.9965009 8 12.5 8 z M 40 11.513672 L 40 31.5 C 40 36.187 36.187 40 31.5 40 L 11.513672 40 C 12.883672 41.818 15.053 43 17.5 43 L 31.5 43 C 37.841 43 43 37.841 43 31.5 L 43 17.5 C 43 15.054 41.818 12.883672 40 11.513672 z M 27.5 13 A 2.5 2.5 0 0 0 27.5 18 A 2.5 2.5 0 0 0 27.5 13 z M 21.5 23.996094 C 21.733581 23.996094 21.966185 24.089232 22.152344 24.275391 L 32.433594 34.554688 C 31.847273 34.832123 31.197097 35 30.5 35 L 12.5 35 C 11.802903 35 11.152727 34.832123 10.566406 34.554688 L 20.845703 24.275391 C 21.031862 24.089232 21.266419 23.996094 21.5 23.996094 z"/>
            </svg>
        </span>
    );
}
