import {Box, Theme, Tooltip} from "@mui/material";
import React, {useContext, useMemo} from "react";
import {makeStyles} from "tss-react/mui";
import AppChip from "../chips/AppChip";
import {
    calculateTimesheets,
    distanceDisplay,
    HoursMinutesDisplayRaw,
    numberDisplay,
    PriceDisplay
} from "../../../service/CompanyService";
import {AppDataContext} from "../../../AppData";
import {ItemPaymentType} from "../../../generated/graphql/graphql";
import {tt} from "../../../core/Localization";
import HammerIcon from "../../../icons/HammerIcon";
import Icons8InTransit from "../../../icons/Icons8InTransit";


export const useStyles = makeStyles()((theme: Theme) => ({
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
    }
}));

export interface ITimesheetsTotalChipsProps {
    timesheets: {
        paymentType: ItemPaymentType;
        fixedPrice?: number | NullOrUndefined;
        hourRate?: number | NullOrUndefined;
        hours?: number | NullOrUndefined;
        minutes?: number | NullOrUndefined;
        approved: boolean;
        deleted?: boolean;
        distance?: number | NullOrUndefined;
        distanceRate?: number | NullOrUndefined;
        employeeId: number;
        uuid: string;
    }[];
    hideZeroType?: boolean;
}

export default function TimesheetsTotalChips(props: ITimesheetsTotalChipsProps) {
    const {timesheets, hideZeroType} = props;

    const {classes} = useStyles();
    const appDataContext = useContext(AppDataContext);
    const {currency, language} = appDataContext;

    const stats = useMemo(() => {
        return calculateTimesheets({
                timesheets: timesheets,
            }
        );
    }, [timesheets]);

    const regularHoursMinutes = HoursMinutesDisplayRaw({
        hours: stats.regular.hours,
        minutes: stats.regular.minutes,
        language,
        returnZeroHoursInsteadOfUndefined: true,
    });
    const regularPrice = PriceDisplay(
        stats.regular.totalPrice,
        currency,
        language,
        true,
    );

    const traverlHoursMinutes = HoursMinutesDisplayRaw({
        hours: stats.travel.hours,
        minutes: stats.travel.minutes,
        language,
        returnZeroHoursInsteadOfUndefined: true,
    });
    const travelPrice = PriceDisplay(
        stats.travel.totalPrice,
        currency,
        language,
        true,
    );

    const jobTotalChip = hideZeroType && stats.regular.timesheets === 0 ? null : (
        <Box pr={1} pb={1}>
            <Tooltip
                title={tt('timesheetsTotalChips.jobTotalChip')}
            >
                <span>
                    <AppChip
                        chipstyle={"outlined"}
                        icon={<HammerIcon/>}
                        label={`${regularHoursMinutes} · ${regularPrice}`}
                    />
                </span>
            </Tooltip>
        </Box>
    );

    const travelTotalChip = hideZeroType && stats.travel.timesheets === 0 ? null : (
        <Box pr={1} pb={1}>
            <Tooltip
                title={tt('timesheetsTotalChips.travelTotalChip')}
            >
                <span>
                    <AppChip
                        chipstyle={"outlined"}
                        icon={<Icons8InTransit/>}
                        label={`${traverlHoursMinutes} · ${travelPrice} · ${distanceDisplay(stats.travel.distance, language, true)}`}
                    />  
                </span>
            </Tooltip>
        </Box>
    );

    return (
        <>
            <Box className={classes.chipsContainer}>
                {jobTotalChip}

                {travelTotalChip}
            </Box>
        </>
    );
}
