import {Box} from "@mui/material";
import {LocationPlaceResponse} from "../../../../generated/graphql/graphql";
import React, {useId} from "react";
import {usePopupState} from "material-ui-popup-state/hooks";
import {tt} from "../../../../core/Localization";
import AppListItem from "../../listItems/AppListItem";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import CloseIcon from "../../../../icons/CloseIcon";
import Icons8Here from "../../../../icons/Icons8-here";
import {LocationTypeDisplay} from "../../../../service/LocationService";
import AppIconButton from "../../buttons/AppIconButton";
import MoreFilledIcon from "../../../../icons/MoreFilledIcon";

export interface INewJobPlaceItemProps {
    data: LocationPlaceResponse;
    onDelete: (id: number) => void;
}

/**
 * place item with dropdown menu.
 **/
export default function NewJobPlaceItem(props: INewJobPlaceItemProps) {
    const {data, onDelete} = props;

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    return (
        <>
            <AppListItem
                customAvatar={<Icons8Here/>}
                title={data.name}
                description={LocationTypeDisplay(data.type || '')}
                variant={"smaller-title"}
                actionWidget={<AppIconButton
                    variant={"greyBg"}
                    placement={"right"}
                    {...bindTrigger(settingsPopupState)}
                >
                    <MoreFilledIcon/>
                </AppIconButton>}
            />

            <Menu {...bindMenu(settingsPopupState)}>
                <MenuItem key={'palceDetailDeleteKey' + data.id} onClick={() => {
                    settingsPopupState.close();
                    onDelete(data.id);
                }}>
                    <Box sx={{color: kAppColors.red.confirmButton}}><CloseIcon/></Box>
                    {tt('common.removeFromList')}
                </MenuItem>
            </Menu>
        </>
    );
}
