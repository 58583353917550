import IInfo from "../../../model/firestore/Info";
import {AppDataContext} from "../../../AppData";
import {useContext} from "react";
import {Alert, Box, IconButton, Theme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {infoSeverityToAlertColor} from "../../../service/InfoService";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {ExternalLink} from "tabler-icons-react";

export const useStyles = makeStyles()((theme: Theme) => ({
    alert: {
        maxWidth: '100%',
        width: '100%',
        marginBottom: theme.spacing(2),
        borderRadius: 10,
        color: "white",
        svg: {
            color: "white",
        },
        '&.MuiAlert-standardError': {
            backgroundColor: kAppColors.red.main,
        },
        '&.MuiAlert-standardWarning': {
            backgroundColor: 'rgba(255,153,34, 0.7)',
        },
        '&.MuiAlert-standardSuccess': {
            backgroundColor: '#5AC700',
        },
        '&.MuiAlert-standardInfo': {
            backgroundColor: kAppColors.background.blue,
        },
    },
    alertContainer: {
        width: '100%',
        "@media (max-width: 767px)": {
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 8,
        },
    }
}));

export interface IInfoNoticeProps {
    info: IInfo;
}

/**
 * Component to display single Info based on parameters.
 */
export default function InfoNotice(props: IInfoNoticeProps) {
    const {info} = props;

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const {classes} = useStyles();

    const translation = info.translations.find((translation) => {
        return translation.language === language.substring(0, 2).toLowerCase();
    });

    const title = translation?.title ?? info.title;
    const text = translation?.text ?? info.text;

    const severity = infoSeverityToAlertColor(info.messageSeverity);

    const actionJSX = info.targetUrl ? (
        <IconButton href={info.targetUrl} target="_blank" color="primary">
            <ExternalLink/>
        </IconButton>
    ) : null;

    return (
        <Box className={classes.alertContainer}>
            <Alert
                className={classes.alert}
                severity={severity}
                action={actionJSX}
            >
                {title}

                {text ? (
                    <>
                        <br/>

                        {text}
                    </>
                ) : null}
            </Alert>
        </Box>
    );
}
