import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import {tt} from "../../../core/Localization";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {Box, Theme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {AppDataContext} from "../../../AppData";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {kSettingsRoute} from "./SettingsScreen";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../components/form/FormBuilder";
import TimelineIcon from "../../../icons/TimelineIcon";
import Grid3Icon from "../../../icons/Grid3Icon";
import CalendarIcon from "../../../icons/CalendarIcon";
import CardIcon from "../../../icons/CardIcon";
import {Option} from "../../components/form/InputProps";
import AppButton from "../../components/buttons/AppButton";
import {capitalizeFirst} from "../../../utils/Utils";
import {useMutation} from "@apollo/client";
import {
    SaveUserPreferencesItemsForKeysDocument,
    SaveUserPreferencesItemsForKeysMutationVariables
} from "../../../generated/graphql/graphql";
import {processMutationError} from "../../../service/ErrorService";
import {SuccessToast} from "../../../service/ToastService";
import {
    kUserPreferencesCalendarTab,
    kUserPreferencesCalendarTabValues,
    kUserPreferencesCalendarView,
    kUserPreferencesCalendarViewValues,
    kUserPreferencesNewJobCalendarVisible
} from "../../../core/constants";
import {useNavigate} from "react-router-dom";

export const kVisitsSettingsRoute = '/settings/visits';

const useStyles = makeStyles()((theme: Theme) => ({
    topEmptySpace: {
        paddingTop: 50,
        "@media (max-width: 599px)": {
            paddingTop: 0,
        }
    }
}));

export default function VisitsSettingsScreen() {
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const navigate = useNavigate();

    const [mutateSaveUserPreferencesItemsForKeys, {
        loading: loadingSaveUserPreferencesItemsForKeys,
    }] = useMutation(SaveUserPreferencesItemsForKeysDocument);

    useEffect(() => {
        setTitle(tt('visitsSettings.screen.title'));
    }, []);

    /**
     * Save calendar related settings to UserPreferences on BE.
     */
    const saveCalendarSettings = async (inputs: IInputsData) => {
        try {
            const variables: SaveUserPreferencesItemsForKeysMutationVariables = {
                input: {
                    companyId: companyId!,
                    items: [
                        {key: kUserPreferencesCalendarTab, valueString: inputs.tab.value[0]},
                        {key: kUserPreferencesCalendarView, valueString: inputs.option.value},
                        {key: kUserPreferencesNewJobCalendarVisible, valueBool: inputs.newJobCalendarVisible.value},
                    ],
                },
            };

            const result = await mutateSaveUserPreferencesItemsForKeys({variables});

            if (!result.errors) {
                SuccessToast(tt('calendarSettings.screen.saveSuccess'));

                navigate(kSettingsRoute);
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                isMobile={isMobile}
                saveCalendarSettings={saveCalendarSettings}
                loadingUpdate={loadingSaveUserPreferencesItemsForKeys}
            />
        );
    }

    return (
        <ResponsiveContainer
            smallPhoneScreen={bodyJSX(true)}
            largePhoneScreen={bodyJSX(true)}
            tabletScreen={bodyJSX()}
            smallDesktopScreen={bodyJSX()}
            largeDesktopScreen={bodyJSX()}
            extraLargeDesktopScreen={bodyJSX()}/>
    );
}

interface IBodyProps {
    isMobile?: boolean;
    saveCalendarSettings: (inputs: IInputsData) => void;
    loadingUpdate?: boolean;
}

function Body(props: IBodyProps) {
    const {
        isMobile,
        saveCalendarSettings,
        loadingUpdate,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {userPreferences} = appDataContext;

    const {classes, cx} = useStyles();

    const [canSetValues, setCanSetValues] = useState<boolean>(true);

    const [inputs, setInputs] = useState<IInputsData>({
        tab: {
            testId: 'jobScreenDefaultSettingsTypeInput',
            topLabel: tt('calendarSettings.screen.defaultView.label'),
            label: '',
            toggleButtonsVariant: 'oneLineButtons',
            type: InputType.FormToggleButtons,
            singleValue: true,
            options: [
                {
                    label: tt('jobsScreen.tab.timeline'),
                    value: kUserPreferencesCalendarTabValues[0],
                    icon: <TimelineIcon/>,
                    showIconWithTooltip: true
                },
                {
                    label: tt('jobsScreen.tab.timeGrid'),
                    value: kUserPreferencesCalendarTabValues[1],
                    icon: <Grid3Icon/>,
                    showIconWithTooltip: true
                },
                {
                    label: tt('jobsScreen.tab.calendar'),
                    value: kUserPreferencesCalendarTabValues[2],
                    icon: <CalendarIcon/>,
                    showIconWithTooltip: true
                },
                {
                    label: tt('jobsScreen.tab.cards'),
                    value: kUserPreferencesCalendarTabValues[3],
                    icon: <CardIcon/>,
                    showIconWithTooltip: true
                },
            ],
            value: [kUserPreferencesCalendarTabValues[0]],
            grid: {
                sm: 6,
                xs: 12,
            },
        },
        option: {
            className: classes.topEmptySpace,
            testId: 'calendarDefaultSettingsTypeInput',
            value: kUserPreferencesCalendarViewValues[0],
            label: tt('calendarSettings.screen.viewInput.label'),
            type: InputType.Select,
            options: [
                {label: capitalizeFirst(tt('common.day')), value: kUserPreferencesCalendarViewValues[0]},
                {label: capitalizeFirst(tt('common.week')), value: kUserPreferencesCalendarViewValues[1]},
                {label: capitalizeFirst(tt('common.month')), value: kUserPreferencesCalendarViewValues[2]},
            ],
            grid: {
                sm: 6,
                xs: 12,
            },
        },
        newJobCalendarVisible: {
            testId: 'newJobCalendarVisibleInput',
            label: tt('calendarSettings.screen.newJobCalendarVisible.label'),
            type: InputType.Switch,
            switchVariant: "Android12Switch",
            value: true,
            grid: {
                sm: 6,
                xs: 12,
            },
        },
    });

    useEffect(() => {
        let options: Option[] = inputs.tab.options || [];
        let value: string = inputs.option.value;

        let viewValue = userPreferences.find(item => item.key === kUserPreferencesCalendarView)?.valueString;

        switch (inputs.tab.value[0]) {
            case 'timeline':
            case 'calendar':
                options = [
                    {label: capitalizeFirst(tt('common.day')), value: kUserPreferencesCalendarViewValues[0]},
                    {label: capitalizeFirst(tt('common.week')), value: kUserPreferencesCalendarViewValues[1]},
                    {label: capitalizeFirst(tt('common.month')), value: kUserPreferencesCalendarViewValues[2]},
                ];

                if (inputs.option.value === '' || inputs.option.value === 'default') {
                    value = 'day';
                }
                break;
            case 'timeGrid':
                options = [
                    {label: capitalizeFirst(tt('common.day')), value: kUserPreferencesCalendarViewValues[0]},
                    {label: capitalizeFirst(tt('common.week')), value: kUserPreferencesCalendarViewValues[1]},
                ];
                if (inputs.option.value === '' || inputs.option.value === 'default' || inputs.option.value === 'month') {
                    value = 'week';
                }
                break;
            case 'cards':
                options = [
                    {label: tt('calendarSettings.screen.cardsOption.default'), value: 'default'},
                ];
                value = 'default';
                break;
        }

        const optionForViewValue = options.find(item => item.value === viewValue);
        if (!optionForViewValue) {
            viewValue = undefined;
        }

        setInputs(
            prev => {
                return {
                    ...prev,
                    option: {
                        ...prev.option,
                        options: options,
                        value: viewValue || value,
                    }
                };
            }
        )
    }, [inputs.tab.value]);

    useEffect(() => {
        if (userPreferences.length > 0) {
            const tabValue = userPreferences.find(item => item.key === kUserPreferencesCalendarTab)?.valueString;
            const viewValue = userPreferences.find(item => item.key === kUserPreferencesCalendarView)?.valueString;
            const newJobCalendarVisible = userPreferences.find(item => item.key === kUserPreferencesNewJobCalendarVisible);

            if ((tabValue || viewValue || newJobCalendarVisible) && canSetValues) {
                setCanSetValues(false);

                setInputs(prev => {
                    return {
                        ...prev,
                        tab: {
                            ...prev.tab,
                            value: [tabValue || kUserPreferencesCalendarTabValues[0]],
                        },
                        option: {
                            ...prev.option,
                            value: viewValue || kUserPreferencesCalendarViewValues[0],
                        },
                        newJobCalendarVisible: {
                            ...prev.newJobCalendarVisible,
                            value: newJobCalendarVisible ? newJobCalendarVisible.valueBool : true,
                        },
                    };
                });
            }
        }
    }, [userPreferences]);

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}
        >
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    isMobile={isMobile}
                    title={tt('visitsSettings.screen.title')}
                    cancelIconBackButton={true}
                    backRoute={kSettingsRoute}
                />
                <Box pl={2} pr={2} pb={2}>
                    <FormBuilder
                        inputs={inputs}
                        setInputs={setInputs}
                    />
                    <Box pb={4}/>
                    <AppButton
                        fullWidth={true}
                        variant={"contained"}
                        isLoading={loadingUpdate}
                        onClick={() => {
                            if (ValidateForm(inputs, setInputs)) {
                                saveCalendarSettings(inputs);
                            }
                        }}
                    >{tt('common.save')}</AppButton>
                </Box>
            </AppPaper>
        </ScreenContent>
    );
}
