import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import React, {useEffect, useState} from "react";
import {tt} from "../../../core/Localization";
import AppButton from "../buttons/AppButton";
import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material";
import WorkerEditScreenShimmer from "../shimmers/screenSpecificShimmers/WorkerEditScreenShimmer";
import SplitButton from "../buttons/SplitButton";

export const useStyles = makeStyles()((theme: Theme) => ({
    submitRightContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    button: {
        "@media (max-width: 767px)": {
            flexGrow: 1,
            width: '100%',
        }
    },
    submitRightSplitButton: {
        flexShrink: 1,
        width: "unset",
        marginLeft: "auto",
        "@media (max-width: 767px)": {
            flexShrink: 0,
            marginLeft: 0,
            flexGrow: 1,
            width: '100%',
        }
    }
}));

export interface IMaterialFormProps {
    isEdit?: boolean;
    updateLoading?: boolean;
    editQuantity?: boolean;
    name?: string;
    unitOfMeasure?: string | NullOrUndefined;
    quantity?: number | NullOrUndefined;
    onSubmit: (update: IMaterialFormUpdate, createAnother?: boolean) => void;
    loading?: boolean;
    loadingData?: boolean;
    submitRight?: boolean;
    createAnotherAble?: boolean;
    resetFormTrigger?: number;
    isModal?: boolean;
}

export interface IMaterialFormUpdate {
    inputs: IInputsData;
}

export default function MaterialForm(props: IMaterialFormProps) {
    const {classes, cx} = useStyles();

    const {
        isEdit,
        editQuantity,
        name,
        quantity,
        unitOfMeasure,
        onSubmit,
        loading,
        loadingData,
        submitRight,
        createAnotherAble,
        resetFormTrigger,
        isModal,
        updateLoading,
    } = props;

    const [inputs, setInputs] = useState<IInputsData>({
        name: {
            testId: 'materialNameInput',
            type: InputType.Text,
            label: tt('material.form.label.name') + '*',
            value: name || '',
            required: true,
        },
        quantity: {
            testId: 'addMaterialQuantityInput',
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('material.form.label.quantity')}*`,
            value: quantity || '',
            required: editQuantity,
            hidden: !editQuantity,
            grid: {
                sm: 6,
                xs: 6,
            },
        },
        unitOfMeasure: {
            testId: 'materialUnitOfMeasureInput',
            type: InputType.Text,
            label: tt('material.form.label.unitOfMeasure'),
            placeholder: tt('material.form.placeholder.unitOfMeasure'),
            value: unitOfMeasure || '',
            required: false,
            grid: editQuantity ? {
                sm: 6,
                xs: 6,
            } : undefined,
        },
    });

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                name: {
                    ...prev.name,
                    value: name || '',
                },
                quantity: {
                    ...prev.quantity,
                    value: quantity || '',
                },
                unitOfMeasure: {
                    ...prev.unitOfMeasure,
                    value: unitOfMeasure || '',
                },
            };
        });
    }, [resetFormTrigger]);

    useEffect(() => {
        const newEditQuantityHidden = !editQuantity;

        if (inputs.quantity.hidden !== newEditQuantityHidden) {
            setInputs(prev => {
                return {
                    ...prev,
                    quantity: {
                        ...prev.quantity,
                        required: false,
                        hidden: !editQuantity,
                        grid: {
                            sm: 3,
                            xs: 6,
                        },
                    },
                    unitOfMeasure: {
                        ...prev.unitOfMeasure,
                        grid: {
                            sm: 3,
                            xs: 6,
                        },
                    },
                };
            });
        }
    }, [editQuantity]);

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                name: {
                    ...prev.name,
                    value: name || '',
                },
                quantity: {
                    ...prev.quantity,
                    value: quantity || '',
                },
                unitOfMeasure: {
                    ...prev.unitOfMeasure,
                    value: unitOfMeasure || '',
                },
            }
        });
    }, [name, quantity, unitOfMeasure,]);

    const SubmitForm = (createAnother: boolean) => {
        if (ValidateForm(inputs, setInputs)) {
            onSubmit({
                inputs: {
                    ...inputs,
                },
            }, createAnother);
        }
    };

    let submitJSX = createAnotherAble ? (
        <SplitButton
            className={cx(classes.button, submitRight ? classes.submitRightSplitButton : null)}
            isInModal={isModal}
            onChange={
                (index) => {
                    SubmitForm(true);
                }
            }
            disabled={loading}
            isLoading={updateLoading}
            onClick={(index, value) => {
                SubmitForm(false);
            }}
            text={isEdit ? tt('common.save') : tt('common.create')}
            options={[
                tt('addMaterialTemplateModal.saveAndCreateAnother'),
            ]}
        />
    ) : (
        <AppButton
            className={classes.button}
            testId={'materialsFormSubmitButton'}
            variant={"contained"}
            fullWidth={!submitRight}
            onClick={() => SubmitForm(false)}
            disabled={loading}
            isLoading={updateLoading}
        >
            {isEdit ? tt('common.save') : tt('common.create')}
        </AppButton>
    );

    submitJSX = !submitRight ? submitJSX : (
        <div className={classes.submitRightContainer}>
            {submitJSX}
        </div>
    );

    return (
        <>
            {loadingData ? <WorkerEditScreenShimmer/> : (
                <>
                    <FormBuilder inputs={inputs} setInputs={setInputs}/>
                </>
            )}

            {submitJSX}
        </>
    );
}
