import {makeStyles} from "tss-react/mui";
import {Box, ListItemAvatar, Theme, Typography} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import React, {MouseEventHandler, ReactNode} from "react";
import {ChevronRight} from "@mui/icons-material";
import AppAvatar from "../AppAvatar";

const useStyles = makeStyles()((theme: Theme) => ({
    listItemMainContainer: {
        width: '100%',
        display: "flex",
        transition: 'background 0.3s linear',
        ':hover': {
            background: kAppColors.hoverColor.grey(theme.palette.mode === "dark"),
            cursor: "pointer",
        },
        '.MuiListItemText-root .MuiTypography-body1': {
            fontWeight: 500,
            textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
        }
    },
    listItem: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
        paddingBottom: 8,
        marginBottom: 4,
        display: "flex",
        alignItems: "center",
        '.MuiListItemText-multiline': {
            marginBottom: 6,
            marginTop: 6,
        }
    },
    listItemInnerContainer: {
        flexGrow: 1,
    },
    noMarginBottom: {
        marginBottom: 0,
    },
    noHover: {
        ':hover': {
            background: "transparent",
            cursor: "default",
        }
    },
    description: {
        margin: 0,
        '.MuiListItemText-secondary': {
            lineHeight: 1.28,
            fontWeight: 500,
            fontSize: 14,
            textOverflow: "ellipsis",
            margin: 0,
            whiteSpace: "pre-wrap",
            color: "#6F6F6F",
        },
    },
    descriptionEmptyStyle: {
        '.MuiListItemText-secondary': {
            fontStyle: "italic",
        }
    },
    smallerTitle: {
        '.MuiTypography-body1': {
            fontSize: 16,
            lineHeight: 21 / 16,
        }
    },
    thin: {
        '.MuiTypography-body1': {
            fontSize: 16 + 'px !important',
            lineHeight: 21 / 16,
        },
        minHeight: 52,
        marginBottom: 0,
        paddingBottom: 0,
        paddingTop: 0,
    },
    listItemAvatar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    textContainer: {
        paddingRight: 8,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        flexGrow: 1,
    },
    mainContainer: {
        flexGrow: 1,
    },
    mainInnerContainer: {
        display: "flex",
        flexGrow: 1,
    },
}));

export interface IAppListItemV2Props {
    listKey?: string;
    title: string | ReactNode,
    className?: string,
    description?: ReactNode | NullOrUndefined,
    secondLine?: ReactNode,
    descriptionEmptyStyle?: boolean,
    profileImage?: string | NullOrUndefined,
    customAvatar?: ReactNode,
    customAvatarInCircle?: ReactNode,
    customBottomWidget?: ReactNode,
    belowListItemWidget?: ReactNode,
    noAvatar?: boolean
    actionWidget?: ReactNode,
    noMarginBottom?: boolean,
    onClick?: MouseEventHandler<HTMLDivElement>,
    variant?: 'smaller-title' | 'thin' | undefined,
    beforeContent?: ReactNode,
}

export default function AppListItemV2(props: IAppListItemV2Props) {
    const {
        title,
        description,
        secondLine,
        onClick,
        actionWidget,
        profileImage,
        variant,
        customAvatar,
        noMarginBottom,
        customAvatarInCircle,
        className,
        noAvatar,
        customBottomWidget,
        belowListItemWidget,
        beforeContent,
        descriptionEmptyStyle,
    } = props;

    const {classes, cx} = useStyles();

    const listItemMainContainerClass = cx(
        classes.listItemMainContainer,
        onClick == null ? classes.noHover : undefined,
    );

    const listItemClass = cx(
        classes.listItem,
        belowListItemWidget || noMarginBottom ? classes.noMarginBottom : undefined,
        variant === "thin" ? classes.thin : undefined,
        className,
    );

    const listItemTextClass = cx(
        variant === 'smaller-title' ? classes.smallerTitle : undefined,
        classes.description,
        descriptionEmptyStyle ? classes.descriptionEmptyStyle : undefined
    );

    const secondaryActionJSX = actionWidget == null ? <ChevronRight/> : actionWidget;

    const avatarJSX = customAvatar != null ? customAvatar :
        <AppAvatar
            icon={customAvatarInCircle}
            title={typeof title === 'string' ? title : undefined}
            img={profileImage}
        />;

    const secondLIneText = secondLine || description;

    return (<Box
            className={listItemMainContainerClass}
            onClick={(e) => {
                if (onClick) {
                    onClick!(e);
                }
            }
            }
        >
            {beforeContent}
            <Box className={classes.listItemInnerContainer}>
                <Box className={cx(listItemClass, "MuiListItem-root MuiListItem-gutters MuiListItem-padding")}>

                    {noAvatar ? null : <ListItemAvatar className={classes.listItemAvatar}>
                        {avatarJSX}
                    </ListItemAvatar>}

                    <Box className={cx(classes.textContainer, 'MuiBox-root')}>
                        <Box className={cx(listItemTextClass, "MuiListItemText-root MuiListItemText-multiline")}>
                            <Typography
                                className={"MuiTypography-root MuiTypography-body1 MuiListItemText-primary"}>{title}
                            </Typography>
                            {secondLIneText ?
                                <Typography
                                    className={"MuiTypography-root MuiTypography-body2 MuiListItemText-secondary"}>
                                    {secondLIneText}
                                </Typography>
                                : null}
                        </Box>
                        {customBottomWidget}
                    </Box>
                    {secondaryActionJSX ?
                        <Box className={"MuiListItemSecondaryAction-root"}>
                            {secondaryActionJSX}
                        </Box>
                        : null
                    }
                </Box>

                {belowListItemWidget ? <Box pb={1} pl={2} pr={2}>
                    {belowListItemWidget}
                </Box> : null}
            </Box>
        </Box>
    );
}