import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8NumberProps {
    className?: string;
}

export default function Icons8Number(props: IIcons8NumberProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 24 4 C 12.954 4 4 12.954 4 24 C 4 35.046 12.954 44 24 44 C 35.046 44 44 35.046 44 24 C 44 12.954 35.046 4 24 4 z M 24 7 C 33.374 7 41 14.626 41 24 C 41 33.374 33.374 41 24 41 C 14.626 41 7 33.374 7 24 C 7 14.626 14.626 7 24 7 z M 24.414062 14.001953 C 24.298094 14.008547 24.182109 14.02875 24.068359 14.0625 L 19.068359 15.5625 C 18.275359 15.8005 17.8245 16.638641 18.0625 17.431641 C 18.3005 18.224641 19.133641 18.674547 19.931641 18.435547 L 23 17.515625 L 23 32.5 C 23 33.329 23.671 34 24.5 34 C 25.329 34 26 33.328 26 32.5 L 26 15.5 C 26 15.025 25.775531 14.579875 25.394531 14.296875 C 25.108781 14.084625 24.761969 13.982172 24.414062 14.001953 z"/>
            </svg>
        </span>
    );
}