import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8InvisibleProps {
    className?: string;
}

/**
 * Component representing Icons8Invisible svg icon.
 */
export default function Icons8InvisibleIcon(props: IIcons8InvisibleProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px"
                 preserveAspectRatio="xMidYMid meet" focusable="false">
                <path
                    d="M 42.470703 3.9863281 A 1.50015 1.50015 0 0 0 41.439453 4.4394531 L 4.4394531 41.439453 A 1.50015 1.50015 0 1 0 6.5605469 43.560547 L 16.916016 33.205078 C 18.6847 35.119215 21.200325 36.330078 24 36.330078 C 29.33 36.330078 33.669922 31.989922 33.669922 26.669922 C 33.669922 23.870103 32.45923 21.347142 30.544922 19.576172 L 35.052734 15.068359 C 39.90447 17.90912 43.668811 22.496845 45.050781 27.869141 C 45.220781 28.549141 45.83 29 46.5 29 C 46.62 29 46.749141 28.989219 46.869141 28.949219 C 47.679141 28.749219 48.159219 27.930859 47.949219 27.130859 C 46.409379 21.128251 42.461227 16.073087 37.277344 12.84375 L 43.560547 6.5605469 A 1.50015 1.50015 0 0 0 42.470703 3.9863281 z M 23.990234 9 C 12.820234 9 2.7507813 16.620859 0.05078125 27.130859 C -0.15921875 27.930859 0.32085937 28.749219 1.1308594 28.949219 C 1.9308594 29.159219 2.7492187 28.679141 2.9492188 27.869141 C 5.2792187 18.819141 14.330234 12 23.990234 12 C 25.700234 12 27.389531 12.209141 29.019531 12.619141 L 31.480469 10.160156 C 29.090469 9.4001562 26.570234 9 23.990234 9 z M 24 17 C 18.67 17 14.339844 21.339922 14.339844 26.669922 C 14.339844 26.869922 14.349375 27.079297 14.359375 27.279297 L 18.150391 23.490234 C 18.760391 22.360234 19.700078 21.420547 20.830078 20.810547 L 24.619141 17.019531 C 24.409141 17.009531 24.21 17 24 17 z M 28.425781 21.695312 C 29.796425 22.916506 30.669922 24.687458 30.669922 26.669922 C 30.669922 30.339922 27.68 33.330078 24 33.330078 C 22.025372 33.330078 20.255983 32.456581 19.035156 31.085938 L 28.425781 21.695312 z"/>
            </svg>
        </span>
    );
}
