import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8MoreProps {
    className?: string;
}

export default function Icons8More(props: IIcons8MoreProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 7.9980469 17.5 C 4.4254024 17.5 1.4980469 20.428424 1.4980469 24 C 1.4980469 27.571938 4.4261088 30.5 7.9980469 30.5 C 11.569985 30.5 14.498047 27.571938 14.498047 24 C 14.498047 20.428062 11.569985 17.5 7.9980469 17.5 z M 23.998047 17.5 C 20.425402 17.5 17.498047 20.428424 17.498047 24 C 17.498047 27.571938 20.426109 30.5 23.998047 30.5 C 27.569985 30.5 30.498047 27.571938 30.498047 24 C 30.498047 20.428062 27.569985 17.5 23.998047 17.5 z M 39.998047 17.5 C 36.425402 17.5 33.498047 20.428424 33.498047 24 C 33.498047 27.571938 36.426109 30.5 39.998047 30.5 C 43.570691 30.5 46.498047 27.571576 46.498047 24 C 46.498047 20.428062 43.569985 17.5 39.998047 17.5 z M 7.9980469 20.5 C 9.9481088 20.5 11.498047 22.049938 11.498047 24 C 11.498047 25.950062 9.9481088 27.5 7.9980469 27.5 C 6.0479849 27.5 4.4980469 25.950062 4.4980469 24 C 4.4980469 22.049576 6.0466913 20.5 7.9980469 20.5 z M 23.998047 20.5 C 25.948109 20.5 27.498047 22.049938 27.498047 24 C 27.498047 25.950062 25.948109 27.5 23.998047 27.5 C 22.047985 27.5 20.498047 25.950062 20.498047 24 C 20.498047 22.049576 22.046691 20.5 23.998047 20.5 z M 39.998047 20.5 C 41.948109 20.5 43.498047 22.049938 43.498047 24 C 43.498047 25.950424 41.949402 27.5 39.998047 27.5 C 38.047985 27.5 36.498047 25.950062 36.498047 24 C 36.498047 22.049576 38.046691 20.5 39.998047 20.5 z"/>
            </svg>
        </span>
    );
}




















