import {ButtonSelectProps} from "./InputProps";
import React, {useEffect, useState} from "react";
import {Box, MenuItem, TextField, Theme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {useStyles as formUseStyles} from "./FormBuilder";
import AppButton from "../buttons/AppButton";

export const useStyles = makeStyles()((theme: Theme) => ({
    select: {
        '.MuiSelect-select': {
            height: '20px !important',
        }
    }
}));

export default function ButtonSelect(props: ButtonSelectProps) {
    const {
        index,
        className,
        label,
        value,
        updateValue,
        options,
        onChange,
        hidden,
        error,
        errorMessage,
        helperText,
        toggleButtonText,
        testId,
        disabled,
    } = props;

    const [showInput, setShowInput] = useState<boolean>(!!value);

    useEffect(() => {
        if (value && !showInput) {
            setShowInput(true);
        }
    }, [showInput, value]);

    const {classes: formClasses, cx} = formUseStyles();
    const {classes} = useStyles();

    const OnChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateValue(index, e.target.value);

        if (onChange) {
            onChange(index, e.target.value);
        }
    };

    const HandleClickToggleInput = () => {
        setShowInput(!showInput);
    };

    const menuItems = options.map(option => (
        <MenuItem key={`${index}-${option.value}`} value={option.value}>{option.icon}{option.label}</MenuItem>
    ));

    const helperTextJSX = error ? errorMessage : helperText;

    const content = showInput ?
        <div className={cx(className, (hidden ? formClasses.hidden : undefined), classes.select)}>
            <TextField
                data-testid={testId}
                label={label}
                value={value}
                variant="filled"
                error={error}
                helperText={helperTextJSX}
                fullWidth={true}
                margin="none"
                select={true}
                disabled={disabled}
                onChange={OnChangeField}>
                {menuItems}
            </TextField>
        </div> :
        <Box pt={1}>
            <AppButton
                testId={`${testId}ShowInputButton`}
                sx={{py: 0, height: '36px !important', mb: 2, display: hidden ? 'none' : 'block'}}
                onClick={HandleClickToggleInput}>{toggleButtonText} </AppButton>
        </Box>;


    return (
        <Box>
            {content}
        </Box>
    );

}
