import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../../../App";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import {Box, InputAdornment} from "@mui/material";
import EmptyListText from "../../components/textComponents/EmptyListText";
import {tt} from "../../../core/Localization";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import AppChip from "../../components/chips/AppChip";
import {AddOutlined} from "@mui/icons-material";
import SearchIcon from "../../../icons/SearchIcon";
import {useNavigate} from "react-router-dom";
import FormBuilder, {IInputsData, InputType} from "../../components/form/FormBuilder";
import ListShimmer from "../../components/shimmers/ListShimmer";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {AppDataContext} from "../../../AppData";
import {kMobileMenuRoute} from "../mobile/MobileMenuScreen";
import AppIconButton from "../../components/buttons/AppIconButton";
import AppListItem from "../../components/listItems/AppListItem";
import Icons8Layers from "../../../icons/Icons8Layers";
import CreateMaterialTemplateModal from "../../components/materials/CreateMaterialTemplateModal";
import {materialDetailRoute} from "./MaterialDetailScreen";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import {GetMaterialsInput, MaterialResponsePage} from "../../../generated/graphql/graphql";
import {processQueryError} from "../../../service/ErrorService";
import Icons8LayersWithPlus from "../../../icons/Icons8LayersWithPlus";
import {kActionCreate, kPermissionsMaterials, kTopicMaterialTemplates} from "../../../core/constants";
import PermissionValid from "../../components/permissions/PermissionValid";

export const kMaterialsRoute = '/materials';

export default function MaterialsListScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<MaterialResponsePage | NullOrUndefined>();
    useEffect(() => {
        const subscription = subscribe(
            kTopicMaterialTemplates,
            {
                uri: '/material/search-by-company',
                params: {
                    companyId: companyId!,
                    isTemplate: true,
                } as GetMaterialsInput,
                setLoading,
                onData: setData,
                onError: (error: any) => processQueryError(appDataContext, error),
            },
            () => true,
        );

        return () => {
            subscription.cancel();
        };
    }, [companyId]);

    useEffect(() => {
        setTitle(tt('materialsList.screen.title'));
    }, []);

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            type: InputType.Text,
            label: '',
            placeholder: tt('materialsList.screen.searchForMaterial'),
            hideLabel: true,
            inputVariant: 'standard',
            extraStyle: 'thin',
            value: '',
            required: true,
            isClearable: true,
            innerPrefixJSX: (
                <InputAdornment position={"start"}>
                    <SearchIcon/>
                </InputAdornment>
            ),
        },
    });

    const [modal, setModal] = useState<boolean>(false);

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                loading={loading}
                data={data}
                search={inputs.search.value.toLowerCase()}
                inputs={inputs}
                setInputs={setInputs}
                setModal={setModal}
                isMobile={isMobile}
            />
        );
    }

    return (
        <>
            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}
            />

            <CreateMaterialTemplateModal setOpen={setModal} open={modal}/>
        </>
    );
}

interface IBodyProps {
    loading: boolean;
    data: MaterialResponsePage | NullOrUndefined;
    search: string;
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    setModal: Dispatch<SetStateAction<boolean>>;
    isMobile?: boolean;
}

function Body(props: IBodyProps) {
    const {
        loading,
        data,
        search,
        inputs,
        setInputs,
        setModal,
        isMobile,
    } = props;

    const navigate = useNavigate();

    const content = useMemo(
        () => {
            const theData = data?.content.filter(item => {
                return !item.deleted && item.name.toLowerCase().includes(search.toLowerCase());
            })
                .sort((a, b) => a.name.localeCompare(b.name));

            return loading && !theData ?
                <ListShimmer items={2}/> : theData && theData.length > 0 ?
                    theData
                        .map(item => {
                            return (
                                <AppListItem
                                    key={item.id}
                                    variant={"smaller-title"}
                                    customAvatar={<Icons8Layers/>}
                                    onClick={() => {
                                        navigate(materialDetailRoute(item.id));
                                    }}
                                    title={item.name}
                                />
                            );
                        })
                    : <EmptyListText text={tt('materialsList.screen.emptyListMessage')}/>;
        },
        [data, search]
    );

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    noMarginBottom={true}
                    isMobile={isMobile}
                    title={tt('materialsList.screen.title')}
                    hideBackButton={!isMobile}
                    backRoute={isMobile ? kMobileMenuRoute : undefined}
                    bottomContent={<Box pt={1}>
                        <FormBuilder inputs={inputs} setInputs={setInputs}/>
                    </Box>}
                >
                    <PermissionValid
                        permission={kPermissionsMaterials}
                        requiredPermissions={[kActionCreate]}
                    >
                        {isMobile ? (
                            <AppIconButton
                                variant={"primaryBg"}
                                key={'newMaterialButtonMobileKey'}
                                onClick={() => setModal(true)}
                            >
                                <Icons8LayersWithPlus/>
                            </AppIconButton>
                        ) : (
                            <AppChip
                                onClick={() => {
                                    setModal(true)
                                }}
                                key={'addMaterialChip'}
                                label={tt('materialsList.screen.new')}
                                icon={<AddOutlined/>}
                            />
                        )}
                    </PermissionValid>
                </PaperAppbar>

                {content}

                <Box sx={{pb: 0.5}}/>
            </AppPaper>
        </ScreenContent>
    );
}
