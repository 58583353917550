import {GetProductTypeTitle} from "../../../service/ProductService";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import {useNavigate, useParams} from "react-router-dom";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import PaperAppbar from "../../components/paper/PaperAppbar";
import ContentPadding from "../../components/paper/ContentPadding";
import ProductForm, {IProductFormUpdate} from "../../components/products/ProductForm";
import {productDetailRoute} from "./ProductDetailScreen";
import {
    GetProductInput,
    ProductResponse,
    UpdateProductTemplateDocument,
    UpdateProductTemplateMutation,
    UpdateProductTemplateMutationVariables
} from "../../../generated/graphql/graphql";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {processMutationError, processQueryError} from "../../../service/ErrorService";
import {SuccessToast} from "../../../service/ToastService";
import {tt} from "../../../core/Localization";
import {AppDataContext} from "../../../AppData";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";

export const kProductEditRoute = '/products/:id/edit';

export function productEditRoute(serviceId: string | number): string {
    return kProductEditRoute.replace(':id', `${serviceId}`)
}

export default function ProductEditScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle, authUser} = appContext;

    const appDataContext = useContext(AppDataContext);

    const {id} = useParams();

    const navigate = useNavigate();

    const [screenTitle, setScreenTitle] = useState<string>();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ProductResponse | NullOrUndefined>();
    useEffect(() => {
        if (id) {
            restApiGet({
                uri: '/product',
                params: {
                    productId: parseInt(id!),
                } as GetProductInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading,
                onData: setData,
                onError: (error) => processQueryError(appDataContext, error),
            });
        } else {
            setLoading(false);
            setData(null);
        }
    }, [id]);

    const [mutateUpdate, {
        loading: updateLoading,
    }] = useResettableMutation<UpdateProductTemplateMutation, UpdateProductTemplateMutationVariables>(UpdateProductTemplateDocument);

    useEffect(() => {
        const newTitle = GetProductTypeTitle(data?.type);

        if (newTitle !== screenTitle && data) {
            setScreenTitle(newTitle);

            setTitle(newTitle);
        }
    }, [data]);

    /**
     * Mutate Product update to BE and back on success.
     */
    const UpdateProduct = async (update: IProductFormUpdate) => {
        try {
            const variables: UpdateProductTemplateMutationVariables = {
                input: {
                    id: parseInt(id!),
                    name: update.inputs.name.value,
                    description: update.inputs.description.value,
                    price: parseFloat(update.inputs.price.value || '0'),
                    vatRate: parseFloat(update.inputs.vatRate.value || '0'),
                    unitName: update.inputs.unitOfMeasure.value,
                },
            };

            const result = await mutateUpdate({variables});

            if (!result.errors) {
                SuccessToast(tt('productEdit.screen.success'));

                navigate(productDetailRoute(id!));
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                loading={loading}
                data={data}
                isMobile={isMobile}
                onSubmit={UpdateProduct}
                updateLoading={updateLoading}
            />
        );
    }

    return (
        <ResponsiveContainer
            smallPhoneScreen={bodyJSX(true)}
            largePhoneScreen={bodyJSX(true)}
            tabletScreen={bodyJSX()}
            smallDesktopScreen={bodyJSX()}
            largeDesktopScreen={bodyJSX()}
            extraLargeDesktopScreen={bodyJSX()}
        />
    );
}

interface IBodyProps {
    loading: boolean;
    updateLoading?: boolean;
    data?: ProductResponse | NullOrUndefined;
    isMobile?: boolean;
    onSubmit: (update: IProductFormUpdate) => void;
}

function Body(props: IBodyProps) {
    const {
        loading,
        data,
        isMobile,
        onSubmit,
        updateLoading,
    } = props;

    const {id} = useParams();

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}
            >
                <PaperAppbar
                    isMobile={isMobile}
                    title={GetProductTypeTitle(data?.type)}
                    backRoute={productDetailRoute(id!)}
                    cancelIconBackButton={true}/>

                <ContentPadding>
                    <ProductForm
                        isEdit={true}
                        name={data?.name ?? ''}
                        vatRate={data?.vatRate ?? 0}
                        unitOfMeasure={data?.unitName ?? ''}
                        description={data?.description ?? ''}
                        price={data?.price ?? 0}
                        onSubmit={onSubmit}
                        loading={loading}
                        updateLoading={updateLoading}
                        loadingData={!data && loading}
                    />
                </ContentPadding>
            </AppPaper>
        </ScreenContent>
    );
}
