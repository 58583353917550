import {makeStyles} from "tss-react/mui";
import {Box, Divider, Skeleton, Theme} from "@mui/material";
import React from "react";

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 16,
        paddingBottom: 16,
    },
    rowContainer: {
        paddingTop: 16,
        paddingBottom: 16,
        display: 'flex',
        alignItems: "center",
        overflowX: "auto",
        '*': {
            flexShrink: 0,
        }
    }
}));

export default function TimesheetModalSectionShimmer() {
    const {classes, cx} = useStyles();

    return (
        <>
            <Box className={classes.container}>
                <Skeleton variant={"rectangular"} width={100} height={14}></Skeleton>
                <Box className={cx(classes.rowContainer, 'styledScrollbar')}>
                    <Skeleton variant={"rectangular"} width={40} height={30}></Skeleton>
                    <Box pr={1}/>
                    <Skeleton variant={"rectangular"} width={60} height={30}></Skeleton>
                    <Box pr={1}/>
                    <Skeleton variant={"rectangular"} width={160} height={30}></Skeleton>
                </Box>
                <Skeleton variant={"rectangular"} width={200} height={14}></Skeleton>
            </Box>
            <Divider/>
            <Box className={classes.container}>
                <Skeleton variant={"rectangular"} width={100} height={14}></Skeleton>
                <Box className={cx(classes.rowContainer, 'styledScrollbar')}>
                    <Skeleton variant={"rectangular"} width={40} height={30}></Skeleton>
                    <Box pr={1}/>
                    <Skeleton variant={"rectangular"} width={60} height={30}></Skeleton>
                    <Box pr={1}/>
                    <Skeleton variant={"rectangular"} width={160} height={30}></Skeleton>
                </Box>
                <Skeleton variant={"rectangular"} width={200} height={14}></Skeleton>
            </Box>
        </>

    );
}