import {Grid, Skeleton} from "@mui/material";
import React from "react";
import {inputShimmerStyles} from "../../../../styles/UtilStyles";

export default function CompanyEditScreenShimmer() {
    const {classes} = inputShimmerStyles();

    return (
        <Grid container columnSpacing={2}>
            <Grid
                item={true}
                xs={12}
            >
                <Skeleton
                    className={classes.inputShimmer}
                    variant={"rectangular"}
                    height={53}
                />
            </Grid>
            <Grid
                item={true}
                xs={12}
            >
                <Skeleton
                    className={classes.inputShimmer}
                    variant={"rectangular"}
                    height={53}
                />
            </Grid>
            <Grid
                item={true}
                sm={6}
                xs={12}
            >
                <Skeleton
                    className={classes.inputShimmer}
                    variant={"rectangular"}
                    height={53}

                />
            </Grid>
            <Grid
                item={true}
                sm={6}
                xs={12}
            >
                <Skeleton
                    className={classes.inputShimmer}
                    variant={"rectangular"}
                    height={53}

                />
            </Grid>
        </Grid>
    );
}