import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8ContactsProps {
    className?: string;
}

export default function Icons8Contacts(props: IIcons8ContactsProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21.818" viewBox="0 0 18 21.818">
                <path fill="currentColor" id="icons8-contacts"
                      d="M11,4A3.012,3.012,0,0,0,8,7V22.818a3.012,3.012,0,0,0,3,3H25.182a.818.818,0,1,0,0-1.636H11a1.348,1.348,0,0,1-1.336-1.091H25.182A.818.818,0,0,0,26,22.273V7a3.012,3.012,0,0,0-3-3Zm0,1.636H23A1.352,1.352,0,0,1,24.364,7V21.454H9.636V7A1.352,1.352,0,0,1,11,5.636Zm6,2.182a2.455,2.455,0,1,0,2.455,2.455A2.467,2.467,0,0,0,17,7.818Zm0,1.636a.818.818,0,1,1-.818.818A.806.806,0,0,1,17,9.455Zm-2.727,4.364a1.376,1.376,0,0,0-1.364,1.364v1.364A2.465,2.465,0,0,0,14.274,18.6a5.422,5.422,0,0,0,2.726.67,5.422,5.422,0,0,0,2.726-.67,2.465,2.465,0,0,0,1.365-2.057V15.182a1.376,1.376,0,0,0-1.364-1.364Zm.273,1.636h4.909v1.091c0,.148-.117.389-.553.643a4.265,4.265,0,0,1-3.8,0c-.436-.254-.553-.5-.553-.643Z"
                      transform="translate(-8 -4)"/>
            </svg>
        </span>
    );
}