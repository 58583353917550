import {v4 as uuidv4} from 'uuid';
import {useEffect, useRef, useState} from "react";
import GoogleMapReact from "google-map-react";
import {AddMapyCZApi, kDefaultMapProps} from "../../../service/MapsService";
import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material";
import { FlavorByEnvironment } from '../../../flavor-config';

declare global {
    interface Window {
        L: any;
    }
}

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        height: "100%",
    },
}));

export interface IMapyCZMarker {
    lat: number;
    lng: number;
}

export interface IMapyCZProps {
    className?: string;
    center?: GoogleMapReact.Coords;
    zoom?: number;
    markers?: IMapyCZMarker[];
}

/**
 * Component for wrapping MapyCZ map.
 */
export default function MapyCZ(props: IMapyCZProps) {
    const {className, center, zoom, markers} = props;

    const {classes, cx} = useStyles();

    const [elementId] = useState(uuidv4());
    const [map, setMap] = useState<any>(null);
    const markersOnMap = useRef<Array<any>>([]);

    const theCenter = center || kDefaultMapProps.center;
    const theZoom = zoom || kDefaultMapProps.zoom;
    const theMarkers = markers || [];

    /**
     * Create MapyCZ map once script loaded.
     */
    const createMap = async () => {
        const loaded = await AddMapyCZApi();

        if (loaded) {
            const config = FlavorByEnvironment();

            const map = window.L.map(elementId, {
                zoomControl: true,
            })
                .setView([theCenter.lat, theCenter.lng], theZoom);

            window.L.tileLayer(`https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=${config!.mapyCZApiKey}`, {
                minZoom: 0,
                maxZoom: 19,
                attribution: '<span class="color-black">&copy; Seznam.cz a.s. a další</span>',
            }).addTo(map);

            const LogoControl = window.L.Control.extend({
                options: {
                    position: 'bottomleft',
                },

                onAdd: function (map: any) {
                    const container = window.L.DomUtil.create('div');
                    const link = window.L.DomUtil.create('a', '', container);

                    link.setAttribute('href', 'http://mapy.cz/');
                    link.setAttribute('target', '_blank');
                    link.innerHTML = '<img src="https://api.mapy.cz/img/api/logo.svg" />';
                    window.L.DomEvent.disableClickPropagation(link);

                    return container;
                },
            });

            new LogoControl().addTo(map);

            setMap(map);
        } else {
            console.error("MapyCZ script not loaded.");
        }
    };

    useEffect(() => {
        createMap()
            .catch(console.error);
    }, []);

    useEffect(() => {
        if (map && center && zoom) {
            map.setView([theCenter.lat, theCenter.lng], theZoom);
        }
    }, [map, theCenter, theZoom]);

    useEffect(() => {
        if (map && markersOnMap.current) {
            for (const marker of markersOnMap.current) {
                marker.remove();
            }
            markersOnMap.current = [];

            for (const marker of theMarkers) {
                const newMarker = window.L.marker([
                    marker.lat, 
                    marker.lng
                ]);

                markersOnMap.current.push(newMarker);

                newMarker.addTo(map);
            }
        }
    }, [map, theMarkers]);

    return (
        <div className={cx(classes.container, className)} id={elementId}/>
    );
}
