import 'react-quill-new/dist/quill.snow.css';
import './EditorInput.css';

import {Box, IconButton, Theme} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import {InputProps} from "./InputProps";
import AppButton from "../buttons/AppButton";
import React, {useEffect, useState} from "react";
import DeleteIcon from "../../../icons/DeleteIcon";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import GreyLabel from "../decorations/GreyLabel";
import ReactQuill, {Quill} from "react-quill-new";

const useStyles = makeStyles()((theme: Theme) => ({
    label: {
        paddingBottom: theme.spacing(2),
    },
    icon: {
        color: kAppColors.red.confirmButton,
        'svg': {height: 21, width: 21}
    },
    trashButton: {
        position: "absolute",
        right: 10,
        bottom: 0,
    },
    container: {
        marginBottom: 20,
        '.ql-editor.ql-blank::before': {
            color: kAppColors.text.inputLabel(theme.palette.mode === "dark"),
            fontStyle: "normal",
        },
        caretColor: kAppColors.primary.main,
        '.ql-container, .ql-editor p': {
            fontSize: 14,
        },
        position: "relative",
        '.ql-toolbar.ql-snow': {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
        },
        '.ql-picker-options': {
            background: kAppColors.background.menuPaper(theme.palette.mode === "dark"),
            border: "none",
            borderRadius: 10,
            boxShadow: "none",
        },
        '.ql-toolbar.ql-snow .ql-picker-options': {
            border: 'none'
        },
        '.ql-toolbar': {
            '.ql-picker-label:hover, .ql-picker-label.ql-active, button:hover, button:focus, button.ql-active': {
                color: kAppColors.primary.main,
                '.ql-stroke': {
                    stroke: kAppColors.primary.main,
                }
            },
            border: "none",
            borderBottom: `1px solid ${kAppColors.border(theme.palette.mode === 'dark')}`,
            background: kAppColors.grey.inputBackground(theme.palette.mode === 'dark'),
        },
        '.ql-container': {
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            border: "none",
            background: kAppColors.grey.inputBackground(theme.palette.mode === 'dark'),
            '.ql-editor': {
                minHeight: 100,
            }
        },
        '.ql-toolbar.ql-snow .ql-picker-label': {
            border: "none",
        },
        '.ql-picker': {
            color: kAppColors.text.primary(theme.palette.mode === 'dark'),
        },
        '.ql-picker-item:hover, .ql-picker-item:focus': {
            color: kAppColors.primary.main + ' !important',
        },
        '.ql-stroke': {
            stroke: kAppColors.text.primary(theme.palette.mode === 'dark'),
        },
        '.ql-snow a': {
            color: kAppColors.primary.main,
        }
    },
}));

/**
 * Text editor with html elements form component.
 */
export default function EditorInput(props: InputProps) {
    const {
        className,
        value,
        label,
        index,
        updateValue,
        toggleButtonText,
        testId,
        hidden,
        changeThisToCloseInput,
        placeholder,
        onEditorFocus,
        onEditorBlur,
    } = props;

    const {classes, cx} = useStyles();

    const [showInput, setShowInput] = useState<boolean>(!!value);

    /**
     * On change on html, parse whole to look for links with bad urls.
     */
    const onHtmlChange = (html: string) => {
        const htmlAsDom = new DOMParser().parseFromString(html, "text/html");
        const links = htmlAsDom.getElementsByTagName("a");

        for (let i = 0; i < links.length; i++) {
            let linkHref = links[i].getAttribute('href');
            if (linkHref) {
                if (/^\S+@\S+\.\S+$/.test(linkHref) && linkHref.indexOf("mailto:") !== 0) {
                    linkHref = "mailto:" + linkHref
                } else if (!/^(https?|tel):/.test(linkHref)) {
                    linkHref = "https://" + linkHref
                }
                links[i].setAttribute('href', linkHref);
            }
        }
        const content = htmlAsDom.body.innerHTML;

        if (content != value) {
            updateValue(index, content);
        }
    };

    useEffect(() => {
        updateValue(index, '');
        setShowInput(false);
    }, [changeThisToCloseInput]);

    useEffect(() => {
        if (value && !showInput) {
            setShowInput(true);
        }
    }, [showInput, value]);

    const HandleClickToggleInput = () => {
        setShowInput(!showInput);
    };

    const trashIconJSX =
        <IconButton
            className={classes.trashButton}
            aria-label="toggle input visibility"
            onClick={() => {
                HandleClickToggleInput();
                updateValue(index, '');
            }
            }
            edge="end"
        >
            <DeleteIcon className={classes.icon}/>
        </IconButton>;

    const contentJSX = toggleButtonText == null || showInput ? <>
        {label ? <GreyLabel text={label} className={classes.label}/> : null}
        <ReactQuill
            onFocus={(range, source, editor) => {
                if (onEditorFocus) {
                    onEditorFocus();
                }
            }
            }
            onBlur={(range, source, editor) => {
                if (onEditorBlur) {
                    onEditorBlur();
                }
            }
            }
            value={value}
            placeholder={placeholder}
            onChange={onHtmlChange}
            theme={'snow'}
        />
    </> : <Box pt={1}>
        <AppButton
            testId={`${testId}ShowInputButton`}
            sx={{py: 0, height: '36px !important', mb: 2, display: hidden ? 'none' : 'block'}}
            onClick={HandleClickToggleInput}>{toggleButtonText}
        </AppButton>
    </Box>;

    return (
        <div className={cx('Form_Editor', className, classes.container)}>
            {contentJSX}
            {toggleButtonText && showInput ? trashIconJSX : null}
        </div>
    );
}
