import ModalBottomSheet from "../../../ModalBottomSheet";
import {Box, Checkbox, Theme} from "@mui/material";
import {tt} from "../../../../../core/Localization";
import AppListItem from "../../../listItems/AppListItem";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import AppButton from "../../../buttons/AppButton";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import {AddOutlined, CheckCircle} from "@mui/icons-material";
import {GetLocationInput, LocationResponse} from "../../../../../generated/graphql/graphql";
import {LocationTypeDisplay} from "../../../../../service/LocationService";
import CreateLocationPlaceModal from "../../../places/CreateLocationPlaceModal";
import ListShimmer from "../../../shimmers/ListShimmer";
import EmptyListText from "../../../textComponents/EmptyListText";
import Icons8Here from "../../../../../icons/Icons8-here";
import {processQueryError} from "../../../../../service/ErrorService";
import {AppDataContext} from "../../../../../AppData";
import {RestApiClientContext} from "../../../../../core/RestApiProvider";
import PermissionValid from "../../../permissions/PermissionValid";
import {kActionUpdate, kPermissionsClients} from "../../../../../core/constants";


export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        maxHeight: '80vh',
        height: '100vh',
        overflow: "auto",
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
    },
}));

export interface IChoosePlaceModalBottomSheetProps {
    multipleSelect: boolean;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    locationId: number;
    clientId: number;
    existingLocationPlaceIds: number[];
    onSave: (ids: number[]) => void;
}

export default function ChoosePlaceModalBottomSheet(props: IChoosePlaceModalBottomSheetProps) {
    const {
        multipleSelect,
        open,
        setOpen,
        locationId,
        clientId,
        existingLocationPlaceIds,
        onSave,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);

    const {classes} = useStyles();

    const [selected, setSelected] = useState<number[]>([]);
    const [createModal, setCreateModal] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<LocationResponse | undefined>(undefined);
    useEffect(() => {
        if (open && !createModal) {
            restApiGet({
                uri: '/location',
                params: {
                    locationId,
                } as GetLocationInput,
                setLoading: setLoading,
                onData: setData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        }
    }, [open, locationId, createModal]);

    useEffect(() => {
        if (open) {
            setSelected([...existingLocationPlaceIds]);
        }
    }, [open]);

    /**
     * Toggle Place is selected list.
     */
    const Toggle = (id: number) => {
        if (multipleSelect) {
            if (selected.includes(id)) {
                setSelected(prev => {
                    const newSelected = [];

                    for (const idOf of prev) {
                        if (idOf !== id) {
                            newSelected.push(idOf);
                        }
                    }

                    return newSelected;
                });
            } else {
                setSelected(prev => {
                    return [
                        ...prev,
                        id,
                    ];
                });
            }
        } else {
            setSelected([id]);
        }
    };

    const placesJSX = data?.places.map(place =>
        <AppListItem
            key={place.id}
            customAvatarInCircle={<Icons8Here/>}
            title={place.name}
            description={LocationTypeDisplay(place.type)}
            variant={"smaller-title"}
            onClick={() => Toggle(place.id)}
            actionWidget={
                multipleSelect ? (
                    selected.includes(place.id) ? <Checkbox checked={true}/> : <Checkbox checked={false}/>
                ) : (
                    selected.includes(place.id) ? <CheckCircle color={"primary"}/> : <></>
                )
            }
        />
    );

    return (
        <ModalBottomSheet
            tallOnMobile={true}
            blurBackdrop={true}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
            children={
                <Box className={classes.container}>
                    <BottomSheetModalAppbar
                        onClose={() => setOpen(false)}
                    >
                        <PermissionValid
                            permission={kPermissionsClients}
                            requiredPermissions={[kActionUpdate]}
                        >
                            <AppButton
                                onClick={() => {
                                    setCreateModal(true);
                                }}
                                variant={"textThin"}

                            >
                                <AddOutlined sx={{mr: 0.25}}/>
                                {tt('common.newPlace')}
                            </AppButton>
                        </PermissionValid>
                    </BottomSheetModalAppbar>

                    {loading ?
                        <ListShimmer items={3} showIcon={false}/>
                        : (!placesJSX || placesJSX.length === 0
                            ? <EmptyListText text={tt('placesList.emptyListMessage')}/> :
                            placesJSX)}

                    <Box sx={{pb: 10}}/>

                    <Box className={classes.buttonContainer}>
                        <AppButton
                            variant={"contained"}
                            disabled={selected.length < 1}
                            fullWidth={true}
                            onClick={() => {
                                onSave(selected);

                                setOpen(false);
                            }}>
                            {tt('common.save')}
                        </AppButton>

                    </Box>

                    <CreateLocationPlaceModal
                        open={createModal}
                        setOpen={setCreateModal}
                        locationId={locationId}
                        clientId={clientId}
                        navigation={false}/>
                </Box>
            }
        />
    );
}
