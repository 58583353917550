import AppListItem from "../../listItems/AppListItem";
import Icons8Document from "../../../../icons/Icons8Document";
import AppIconButton from "../../buttons/AppIconButton";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MoreFilledIcon from "../../../../icons/MoreFilledIcon";
import React, {useEffect, useId} from "react";
import Icons8Eye from "../../../../icons/Icons8Eye";
import {usePopupState} from "material-ui-popup-state/hooks";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {tt} from "../../../../core/Localization";
import {Box, Theme} from "@mui/material";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import DeleteIcon from "../../../../icons/DeleteIcon";
import IFileState, {IFileStateType} from "../../../../model/FileState";
import {storeFileToFirebase} from "../../../../service/StorageService";
import CircularProgressWithLabel from "../../storage/CircularProgressWithLabel";
import {
    CreateFileDocument,
    CreateFileInput,
    CreateFileMutation,
    CreateFileMutationVariables
} from "../../../../generated/graphql/graphql";
import {useMutation} from "@apollo/client";
import {ErrorToast} from "../../../../service/ToastService";
import {makeStyles} from "tss-react/mui";
import Icons8Attachment from "../../../../icons/Icons8Attachment";
import {processMutationError} from "../../../../service/ErrorService";
import PermissionValid from "../../permissions/PermissionValid";
import {kActionDelete, kPermissionsVisitAttachments} from "../../../../core/constants";

export const useStyles = makeStyles()((theme: Theme) => ({
    addBreakline: {
        '.MuiTypography-body1': {
            lineBreak: 'anywhere',
        }
    }
}));

export interface IAttachmentListItemProps {
    isNewJobCreation?: boolean;
    fileState: IFileState;
    onUpdateFileState: (updateFileState: (fileState: IFileState) => IFileState, fileUploadFinished?: boolean) => void;
    onDelete: (uuid: string) => void;
    onPreview: (uuid: string) => void;
    canEdit: boolean;
}

export default function AttachmentListItem(props: IAttachmentListItemProps) {
    const {isNewJobCreation, fileState, onUpdateFileState, onDelete, onPreview, canEdit} = props;
    const data = fileState.data;

    const {classes} = useStyles();

    const theProgress = fileState.progress || 0;

    const [mutateCreateFile, {
        loading: createFileLoading,
    }] = useMutation<CreateFileMutation, CreateFileMutationVariables>(CreateFileDocument);

    useEffect(() => {
        if (fileState.inputFile && fileState.type === IFileStateType.Select) {
            onUpdateFileState((fileState) => ({
                ...fileState,
                type: IFileStateType.Upload,
            }));

            FileChosen();
        }
    }, [fileState.inputFile]);

    /**
     * On file chosen upload, send to BE and on success change state to file.
     */
    const FileChosen = () => {
        storeFileToFirebase({
            fileState,
            onUpdateFileState,
            onDelete,
            onComplete: UpdateFileStateToBE,
        });
    };

    /**
     * On file upload success, update file state to BE.
     */
    const UpdateFileStateToBE = async (input: CreateFileInput) => {
        try {
            const variables: CreateFileMutationVariables = {
                input,
            };

            const result = await mutateCreateFile({variables});

            if (!result.errors) {
                onUpdateFileState((fileState) => ({
                    ...fileState,
                    type: IFileStateType.File,
                    data: result.data?.createFile,
                }), true);
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    const morePopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const progressJSX = fileState.type === IFileStateType.Select || fileState.type === IFileStateType.Upload ? (
        <CircularProgressWithLabel value={theProgress}/>
    ) : null;

    const previewJSX = fileState.type === IFileStateType.File ? (
        <AppIconButton
            variant={"greyBg"}
            placement={"right"}
            onClick={() => {
                onPreview(fileState.uuid);
            }}
        >
            <Icons8Eye/>
        </AppIconButton>
    ) : null;

    const fileSize = fileState.totalBytes ? fileState.totalBytes / 1024 / 1024 : 0;

    return (
        <>
            <AppListItem
                className={classes.addBreakline}
                variant={"smaller-title"}
                title={data?.name || tt('common.unknown')}
                description={fileSize > 0 ? `${fileSize.toFixed(2)} MB` : undefined}
                customAvatar={<Icons8Attachment/>}
                actionWidget={
                    <>
                        {progressJSX}

                        {previewJSX}

                        <Box pr={1}/>

                        {canEdit ? (
                            <PermissionValid
                                permission={kPermissionsVisitAttachments}
                                requiredPermissions={[kActionDelete]}
                                forceValid={isNewJobCreation}
                            >
                                <AppIconButton
                                    variant={"greyBg"}
                                    placement={"right"}
                                    {...bindTrigger(morePopupState)}
                                >
                                    <MoreFilledIcon/>
                                </AppIconButton>
                            </PermissionValid>
                        ) : null}
                    </>
                }
            />

            <Menu {...bindMenu(morePopupState)}>
                <PermissionValid
                    permission={kPermissionsVisitAttachments}
                    requiredPermissions={[kActionDelete]}
                    forceValid={isNewJobCreation}
                >
                    <MenuItem key={'documentDeleteKey'} onClick={() => {
                        morePopupState.close();
                        onDelete(fileState.uuid);
                    }}>
                        <Box sx={{color: kAppColors.red.main}}><DeleteIcon/></Box>
                        {tt('common.delete')}
                    </MenuItem>
                </PermissionValid>
            </Menu>
        </>
    );
}
