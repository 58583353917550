import AppIconButton from "../buttons/AppIconButton";
import {tt} from "../../../core/Localization";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import Icons8Address from "../../../icons/Icons8Address";
import {Menu, Theme} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {NavigateToAddress, NavigateUsing} from "../../../service/MapsService";
import GoogleMapsIcon from "../../../icons/GoogleMapsIcon";
import WazeIcon from "../../../icons/WazeIcon";
import AppleMapsIcon from "../../../icons/AppleMapsIcon";
import React from "react";
import {usePopupState} from "material-ui-popup-state/hooks";
import {makeStyles} from "tss-react/mui";
import {Address, AddressProvider} from "../../../generated/graphql/graphql";
import SMapsIcon from "../../../icons/SMapsIcon";
import {SuccessToast} from "../../../service/ToastService";
import Icons8Copy from "../../../icons/Icons8Copy";
import {addressToSingleLine} from "../../../utils/AddressUtils";

export const useStyles = makeStyles()((theme: Theme) => ({
    marginRight: {
        marginRight: 8,
    }
}));

export interface IShowOnMapMenuProps {
    address?: Address;
    showMarginRight?: boolean;
}

export default function ShowOnMapMenu(props: IShowOnMapMenuProps) {
    const {address, showMarginRight} = props;

    const addressOptionsPopupState = usePopupState({
        variant: 'popover',
        popupId: 'popup-address',
    });

    const {classes, cx} = useStyles();

    const theButtonClass = cx(
        showMarginRight ? classes.marginRight : null,
    );

    const addressSingleLine = address ? addressToSingleLine(address) : undefined;

    return (
        <>
            <AppIconButton
                className={theButtonClass}
                placement={"right"}
                variant={"greyBg"}
                {...bindTrigger(addressOptionsPopupState)}>
                <Icons8Address/>
            </AppIconButton>

            <Menu {...bindMenu(addressOptionsPopupState)}>
                <MenuItem onClick={() => {
                    addressOptionsPopupState.close();

                    NavigateToAddress(address!, NavigateUsing.MapyCz);
                }}>
                    <SMapsIcon/>
                    {tt('common.sMaps')}
                </MenuItem>

                <MenuItem onClick={() => {
                    addressOptionsPopupState.close();

                    NavigateToAddress(address!, NavigateUsing.Google);
                }}>
                    <GoogleMapsIcon/>
                    {tt('common.googleMaps')}
                </MenuItem>

                <MenuItem onClick={() => {
                    addressOptionsPopupState.close();

                    NavigateToAddress(address!, NavigateUsing.Waze);
                }
                }>
                    <WazeIcon/>
                    {tt('common.Waze')}
                </MenuItem>

                <MenuItem onClick={() => {
                    addressOptionsPopupState.close();

                    NavigateToAddress(address!, NavigateUsing.Apple);
                }
                }>
                    <AppleMapsIcon/>
                    {tt('common.appleMaps')}
                </MenuItem>

                {addressSingleLine ? (
                    <MenuItem onClick={() => {
                        navigator.clipboard.writeText(addressSingleLine)
                            .catch(console.error);

                        SuccessToast(tt('showOnMap.copyToClipboard.success'));

                        addressOptionsPopupState.close();
                    }
                    }>
                        <Icons8Copy/>
                        {tt('showOnMap.copyToClipboard')}
                    </MenuItem>
                ) : undefined}
            </Menu>
        </>
    );
}
