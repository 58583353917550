import { iconUseStyles } from "../styles/IconStyles";

export interface ICalendarProps {
    className?: string;
}

export default function Grid3Icon(props: ICalendarProps) {
    const { className } = props;

    const { classes, cx } = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path d="M 11.5 6 C 8.4802259 6 6 8.4802259 6 11.5 L 6 29.253906 A 1.50015 1.50015 0 0 0 6 29.740234 L 6 36.5 C 6 39.519774 8.4802259 42 11.5 42 L 36.5 42 C 39.519774 42 42 39.519774 42 36.5 L 42 29.746094 A 1.50015 1.50015 0 0 0 42 29.259766 L 42 11.5 C 42 8.4802259 39.519774 6 36.5 6 L 11.5 6 z M 11.5 9 L 17 9 L 17 17 L 9 17 L 9 11.5 C 9 10.101774 10.101774 9 11.5 9 z M 20 9 L 36.5 9 C 37.898226 9 39 10.101774 39 11.5 L 39 17 L 20 17 L 20 9 z M 9 20 L 17 20 L 17 28 L 9 28 L 9 20 z M 20 20 L 39 20 L 39 28 L 20 28 L 20 20 z M 9 31 L 17 31 L 17 39 L 11.5 39 C 10.101774 39 9 37.898226 9 36.5 L 9 31 z M 20 31 L 39 31 L 39 36.5 C 39 37.898226 37.898226 39 36.5 39 L 20 39 L 20 31 z" />
            </svg>
        </span>
    );
}
