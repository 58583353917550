import ModalBottomSheet from "../../../ModalBottomSheet";
import {Box, Checkbox, InputAdornment, Theme} from "@mui/material";
import {tt} from "../../../../../core/Localization";
import AppListItem from "../../../listItems/AppListItem";
import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {makeStyles} from "tss-react/mui";
import FormBuilder, {IInputsData, InputType} from "../../../form/FormBuilder";
import SearchIcon from "../../../../../icons/SearchIcon";
import {AppDataContext} from "../../../../../AppData";
import AppButton from "../../../buttons/AppButton";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import {AddOutlined} from "@mui/icons-material";
import {
    CreateJobEmployeeTimesheetItemInput,
    EmployeeJoinedUserResponsePage,
    EmployeeRole,
    GetEmployeesJoinedUsersIncludingInactiveInput,
} from "../../../../../generated/graphql/graphql";
import ListShimmer from "../../../shimmers/ListShimmer";
import {UserFullName, UserPhotoUrl, UserRoleTitle} from "../../../../../service/UserService";
import EmptyListText from "../../../textComponents/EmptyListText";
import CreateWorkerModal from "../../../workers/CreateWorkerModal";
import {SortEmployeesByName} from "../../../../../service/EmployeeService";
import AppChip from "../../../chips/AppChip";
import {
    DisplaySubscriptionUpgradeModal,
    HoursMinutesDisplay,
    TotalPriceDisplay
} from "../../../../../service/CompanyService";
import Icons8AddShoppingCart2 from "../../../../../icons/Icons8AddShoppingCart2";
import {processQueryError} from "../../../../../service/ErrorService";
import {RestApiClientContext} from "../../../../../core/RestApiProvider";
import PermissionValid from "../../../permissions/PermissionValid";
import {
    kActionCreate,
    kActionUpdate,
    kPermissionsCompanySubscription,
    kPermissionsWorkers
} from "../../../../../core/constants";


export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        maxHeight: '80vh',
        height: '100vh',
        overflow: "auto",
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
    },
    conflict: {
        backgroundColor: 'rgba(255, 34, 34, 0.2)',
        borderRadius: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 2,
        paddingBottom: 2,
        fontSize: 12,
        color: 'rgb(255, 34, 34)',
        fontWeight: 600,
    },
    timesheet: {
        textAlign: 'right',
        paddingRight: 16,
    },
    timesheetSecondLine: {
        color: '#6F6F6F',
        fontSize: 14,
    },
}));

export interface IChooseWorkersModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    existingEmployeeIds: number[];
    onSave: (ids: number[]) => void;
    canSaveToClear?: boolean;
    loading?: boolean;
    modalAboveModals?: boolean;
    // visitStartDate: DateTime;
    // visitEndDate: DateTime;
    visitId?: number;
    repeatingDay?: number;
    showInactive?: boolean;
    employeeTimesheetsCreateInput?: CreateJobEmployeeTimesheetItemInput[];
    excludeEmployeeIds?: number[];
    filterByEmployeeRoles?: EmployeeRole[];
}

export default function ChooseWorkersModalBottomSheet(props: IChooseWorkersModalBottomSheetProps) {
    const {
        open,
        setOpen,
        existingEmployeeIds,
        onSave,
        canSaveToClear,
        loading: loadingProp,
        modalAboveModals,
        // visitStartDate,
        // visitEndDate,
        visitId,
        repeatingDay,
        showInactive,
        employeeTimesheetsCreateInput,
        excludeEmployeeIds,
        filterByEmployeeRoles,
    } = props;

    const {classes} = useStyles();

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {storage, setStorage, companyId, currency, language, companySubscriptionStatus} = appDataContext;

    const [selected, setSelected] = useState<number[]>([]);
    const [createModal, setCreateModal] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<EmployeeJoinedUserResponsePage | undefined>(undefined);
    useEffect(() => {
        if (open && !createModal) {
            restApiGet({
                uri: '/company/employee/search-joined-users-including-inactive',
                params: {
                    companyId: companyId!,
                } as GetEmployeesJoinedUsersIncludingInactiveInput,
                setLoading: setLoading,
                onData: setData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        }
    }, [open, companyId, createModal]);

    useEffect(() => {
        if (data) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(data.files || []),
                    ],
                };
            });
        }
    }, [data]);

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            type: InputType.Text,
            label: '',
            placeholder: tt('workers.screen.searchForWorker'),
            hideLabel: true,
            inputVariant: 'standard',
            extraStyle: 'thin',
            value: '',
            required: true,
            isClearable: true,
            innerPrefixJSX: (
                <InputAdornment position={"start"}>
                    <SearchIcon/>
                </InputAdornment>
            ),
        },
        selectAll: {
            type: InputType.CheckBox,
            value: false,
            label: tt('chooseWorkers.modal.checkbox.label.selectAll'),
        },
    });

    useEffect(() => {
        if (open) {
            setInputs(prev => {
                setTimeout(() => {
                    setSelected([...existingEmployeeIds]);
                }, 1);

                return {
                    ...prev,
                    selectAll: {
                        ...prev.selectAll,
                        value: false,
                    },
                };
            });
        }
    }, [open]);

    const filteredEmployees = data && data.content.length > 0 ?
        SortEmployeesByName(
            data!.content
                .filter(worker => {
                    if (!showInactive && !worker.active) {
                        return false;
                    } else if (showInactive && !worker.active) {
                        return selected.includes(worker.id);
                    }

                    if (excludeEmployeeIds && excludeEmployeeIds.includes(worker.id)) {
                        return false;
                    }

                    if (filterByEmployeeRoles && !filterByEmployeeRoles.includes(worker.role)) {
                        return false;
                    }

                    const fullName = UserFullName(worker?.name || worker?.user?.name, worker?.surname || worker?.user?.surname);

                    return fullName.toLowerCase().includes(inputs.search.value.toLowerCase());
                })
        ) : [];

    useEffect(() => {
        if (data) {
            if (inputs.selectAll.value) {
                setSelected(filteredEmployees.map(e => e.id));
            } else {
                setSelected([]);
            }
        }
    }, [inputs.selectAll.value]);

    /**
     * Toggle Employee is selected list.
     */
    const Toggle = (id: number) => {
        if (selected.includes(id)) {
            setSelected(prev => {
                const newSelected: number[] = [];

                for (const idOf of prev) {
                    if (idOf !== id) {
                        newSelected.push(idOf);
                    }
                }

                return newSelected;
            });
        } else {
            setSelected(prev => {
                return [
                    ...prev,
                    id,
                ];
            });
        }
    };

    const content = useMemo(
        () => {
            return loading && !data ?
                <ListShimmer items={3}
                             showIcon={false}/> : data && data.content.length > 0 ?
                    filteredEmployees
                        .map(worker => {
                            // const existingConflict = conflicts.find(c => c.employee.id === worker.id);
                            const employeeTimesheetCreateInput = employeeTimesheetsCreateInput?.filter(e => e.employeeId === worker.id);

                            let timesheetJSX;
                            if (employeeTimesheetCreateInput) {
                                const hoursDisplay = HoursMinutesDisplay({
                                    timesheets: employeeTimesheetCreateInput,
                                    language
                                });
                                const priceDisplay = TotalPriceDisplay({
                                    timesheets: employeeTimesheetCreateInput,
                                    currency,
                                    language
                                });

                                timesheetJSX = (
                                    <span className={classes.timesheet}>
                                            {hoursDisplay ? (
                                                <>{hoursDisplay} <br/></>
                                            ) : undefined}
                                        <span
                                            className={classes.timesheetSecondLine}>{priceDisplay}</span>
                                    </span>
                                );
                            }

                            return (
                                <AppListItem
                                    key={worker.id}
                                    variant={"smaller-title"}
                                    profileImage={
                                        UserPhotoUrl(worker.user, data?.files, storage.publicUrlsForFiles)
                                    }
                                    onClick={() => Toggle(worker.id)}
                                    title={UserFullName(worker?.name || worker?.user?.name, worker?.surname || worker?.user?.surname)}
                                    description={UserRoleTitle(worker.role)}
                                    actionWidget={
                                        <>
                                            {timesheetJSX}

                                            {!worker.active ? (
                                                <AppChip label={tt('common.worker.inActive')} chipstyle="danger"/>
                                            ) : undefined}

                                            {selected.includes(worker.id) ?
                                                <Checkbox checked={true}/> :
                                                <Checkbox checked={false}/>}
                                        </>
                                    }
                                    //TODO offers rejected job
                                    //         <Typography className={classes.conflict}>
                                    //          {tt('common.label.workerDeclinedJob')}
                                    //         </Typography>
                                />
                            );
                        })
                    :
                    <EmptyListText text={tt('workers.screen.emptyListMessage')}/>;
        },
        [data, inputs.search.value, selected, /*conflicts,*/ employeeTimesheetsCreateInput, storage.publicUrlsForFiles]
    );

    const currentUsers = companySubscriptionStatus?.currentUsers || 0;
    const maxUsers = companySubscriptionStatus?.maxUsers || 0;
    const showUpgradeButton = currentUsers >= maxUsers;

    return (
        <ModalBottomSheet
            tallOnMobile={true}
            blurBackdrop={true}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
            modalAboveModals={modalAboveModals}
            children={
                <Box className={classes.container}>
                    <BottomSheetModalAppbar
                        onClose={() => setOpen(false)}
                        children={
                            showUpgradeButton ? (
                                <PermissionValid
                                    permission={kPermissionsCompanySubscription}
                                    requiredPermissions={[kActionUpdate]}
                                >
                                    <AppButton
                                        variant={"textThin"}
                                        onClick={
                                            () => {
                                                DisplaySubscriptionUpgradeModal(
                                                    appDataContext,
                                                    currentUsers,
                                                    maxUsers,
                                                );
                                            }
                                        }
                                    >
                                        <Icons8AddShoppingCart2/>
                                        <Box sx={{mr: 0.25}}/>
                                        {tt('chooseWorker.modal.button.upgrade')}
                                    </AppButton>
                                </PermissionValid>
                            ) : (
                                <PermissionValid
                                    permission={kPermissionsWorkers}
                                    requiredPermissions={[kActionCreate]}
                                >
                                    <AppButton
                                        variant={"textThin"}
                                        onClick={
                                            () => {
                                                setCreateModal(true);
                                            }
                                        }
                                    >
                                        <AddOutlined sx={{mr: 0.25}}/>
                                        {tt('common.newWorker')}
                                    </AppButton>
                                </PermissionValid>
                            )
                        }
                        bottomContent={
                            <>
                                <FormBuilder inputs={inputs} setInputs={setInputs}/>
                            </>
                        }
                    />

                    {content}

                    <Box sx={{pb: 10}}/>

                    <Box className={classes.buttonContainer}>
                        <AppButton
                            variant={"contained"}
                            disabled={(selected.length === 0 && !canSaveToClear) || loadingProp}
                            fullWidth={true}
                            onClick={() => {
                                onSave(selected)

                                setOpen(false);
                            }}>
                            {tt('common.save')}
                        </AppButton>
                    </Box>

                    <CreateWorkerModal
                        open={createModal}
                        setOpen={setCreateModal}
                        navigation={false}
                        modalAboveModals={modalAboveModals}/>
                </Box>
            }
        />
    );
}
