import {
    CreateJobFormTemplateInput,
    JobFormElementFragment,
    JobFormPureResponse,
    JobFormResponse
} from "../generated/graphql/graphql";
import {IInputsData} from "../ui/components/form/FormBuilder";
import {ex} from "@fullcalendar/core/internal-common";

export interface ICombineJobFormResponseParams {
    companyId: number;
    isTemplate: boolean;
    inputs: IInputsData;
    sections: string[];
    elements: Record<string, JobFormElementFragment[]>;
    existingData?: JobFormResponse | NullOrUndefined;
    isDuplication?: boolean;
}

/**
 * Combine JobForm form data into JobFormResponse.
 */
export function combineJobFormResponse(params: ICombineJobFormResponseParams): JobFormResponse {
    const {companyId, isTemplate, inputs, sections, elements, existingData, isDuplication} = params;

    const response: JobFormResponse = {
        companyId,
        isTemplate,
        name: inputs.name?.value || '',
        sections: sections,
        elements: [],
        deleted: false,
        position: 0,
    };

    for (let i = 0; i < sections.length; i++) {
        const sectionKey = `section_${i}`;

        response.sections[i] = inputs[`${sectionKey}_name`]?.value || '';
    }

    Object.keys(elements).forEach((sectionId) => {
        const sectionKey = `section_${sectionId}`;
        let elementsForSection = [...elements[sectionId]];

        let i = 0;
        while (elementsForSection.length > 0) {
            const element = elementsForSection.shift()!;
            const elementKey = `${sectionKey}_element_${i}`;

            element.label = inputs[`${elementKey}_name`]?.value || '';

            const options = inputs[`${elementKey}_options`]?.value;
            if (options && options.length) {
                element.options = options.map((option: any) => option.value);
            } else {
                element.options = [];
            }

            response.elements.push(element);
            i++;

            if (element.groupId) {
                elementsForSection = elementsForSection.filter((item) => item.groupId != element.groupId);

                element.options.forEach((option) => {
                    const optionElement = {...element};
                    const existingElement = existingData?.elements.find((item) => item.groupId == element.groupId && item.label == option);

                    optionElement.id = existingElement?.id;
                    optionElement.uuid = existingElement?.uuid;
                    optionElement.label = option;

                    if (isDuplication) {
                        delete optionElement.id;
                        delete optionElement.uuid;
                        delete optionElement.createdAt;
                    }

                    response.elements.push(optionElement);
                });
            }
        }
    });

    return response;
}

/**
 * Process CreateJobFormTemplateInput into JobFormResponse.
 * This is partial function for NewVisit and NewJobForm.
 */
export function processCreateJobFormTemplateInputToJobFormResponse(input: CreateJobFormTemplateInput): JobFormResponse {
    return {
        companyId: input.companyId,
        isTemplate: true,
        name: input.name,
        sections: input.sections,
        elements: input.elements,
        deleted: false,
        position: 0,
    };
}

/**
 * Filter list of JobForms by Visit.
 * Handles for repeating days to avoid duplication and on wrong day.
 * Input needs to include deleted JobForms, output will not include deleted JobForms.
 */
export function filterJobFormsPureByVisit(
    visitId: number,
    repeatingDay: number | NullOrUndefined,
    jobForms: JobFormPureResponse[],
): JobFormPureResponse[] {
    if (repeatingDay) {
        return jobForms.filter((jobForm) => {
            if (!jobForm.repeatingDay) {
                const repeatDayData = jobForms.find((item) => {
                    return item.visitId === visitId && item.uuid === jobForm.uuid && item.repeatingDay === repeatingDay;
                });

                if (!repeatDayData) {
                    return false;
                }
            }

            return jobForm.visitId === visitId && !jobForm.deleted && (!jobForm.repeatingDay || jobForm.repeatingDay === repeatingDay);
        });
    } else {
        return jobForms.filter((jobForm) => {
            return jobForm.visitId === visitId && !jobForm.deleted;
        });
    }
}

export interface IJobFormStats {
    hasRequired: boolean;
    totalRequired: number;
    doneRequired: number;
}

/**
 * Calculate JobForm stats for "required" feature to work correctly.
 */
export function calculateJobFormStats(jobFormResponse: JobFormResponse): IJobFormStats {
    const mainElementsOnly = jobFormResponse.elements.filter((element) => {
        let isGroupedElement = !!element.groupId && element.options.includes(element.label);

        return !isGroupedElement;
    });

    const hasRequired = mainElementsOnly.filter(element => element.required).length > 0;
    let totalRequired = 0;
    let doneRequired = 0;

    if (hasRequired) {
        for (const mainElementOf of mainElementsOnly) {
            if (mainElementOf.required) {
                totalRequired++;

                if (mainElementOf.groupId) {
                    const groupedElements = jobFormResponse.elements.filter((element) => {
                        return element.groupId === mainElementOf.groupId && mainElementOf.options.includes(element.label);
                    });

                    for (const groupedElement of groupedElements) {
                        if (groupedElement.done) {
                            doneRequired++;
                            break;
                        }
                    }
                } else if (mainElementOf.done) {
                    doneRequired++;
                }
            }
        }
    }

    return {
        hasRequired: hasRequired,
        totalRequired: totalRequired,
        doneRequired: doneRequired,
    };
}

export interface IJobFormElementStats {
    isRequired: boolean;
    isDone: boolean;
}

/**
 * Calculate stats for single JobFormElement.
 * Include consideration for groups, where at least one is required to be done only.
 */
export function calculateJobFormElementStats(jobFormElement: JobFormElementFragment, jobFormResponse: JobFormResponse): IJobFormElementStats {
    let isRequired = false;
    let isDone = false;

    const isMainElement = !jobFormElement.groupId || !jobFormElement.options.includes(jobFormElement.label);
    if (isMainElement) {
        if (jobFormElement.required) {
            isRequired = true;

            if (!jobFormElement.groupId) {
                isDone = jobFormElement.done;
            } else {
                const groupedElements = jobFormResponse.elements.filter((element) => {
                    return element.groupId === jobFormElement.groupId && jobFormElement.options.includes(element.label);
                });

                isDone = groupedElements.some((element) => {
                    return element.done;
                });
            }
        }
    } else {
        /*const mainElement = jobFormResponse.elements.find((element) => {
            return element.groupId === jobFormElement.groupId && element.options.includes(jobFormElement.label);
        });
        const groupedElements = jobFormResponse.elements.filter((element) => {
            return element.groupId === jobFormElement.groupId && jobFormElement.options.includes(element.label);
        });
        const anyIsDone = groupedElements.some((element) => {
            return element.done;
        });

        if (mainElement) {
            if (mainElement.required) {
                isRequired = !anyIsDone;

                if (jobFormElement.done) {
                    isDone = true;
                }
            }
        }*/
    }

    return {
        isRequired,
        isDone,
    };
}
