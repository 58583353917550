import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import FormBuilder, {IInputsData, InputType} from "../form/FormBuilder";
import {tt} from "../../../core/Localization";
import AppButton from "../buttons/AppButton";
import ContactPersonEditScreenShimmer from "../shimmers/screenSpecificShimmers/ContactPersonEditScreenShimmer";

export const useStyles = makeStyles()((theme: Theme) => ({
    submitRightContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    button: {
        "@media (max-width: 767px)": {
            flexGrow: 1,
        }
    }
}));


export interface IContactPersonFormProps {
    isEdit?: boolean;
    name?: string;
    surname?: string;
    phone?: string | NullOrUndefined;
    role?: any;
    onSubmit: (inputs: IInputsData, setInputs: Dispatch<SetStateAction<IInputsData>>) => void;
    loading?: boolean;
    loadingData?: boolean;
}

export default function ContactPersonForm(props: IContactPersonFormProps) {
    const {
        isEdit,
        name,
        surname,
        phone,
        role,
        onSubmit,
        loading,
        loadingData
    } = props;

    const {classes} = useStyles();

    const [inputs, setInputs] = useState<IInputsData>({
        name: {
            type: InputType.Text,
            label: `${tt('common.firstName')}*`,
            value: name || '',
            required: true,
            grid: {
                sm: 6,
                xs: 12
            },
        },

        surname: {
            type: InputType.Text,
            label: `${tt('common.lastName')}*`,
            value: surname || '',
            required: true,
            grid: {
                sm: 6,
                xs: 12
            },
        },

        phone: {
            type: InputType.ButtonTextField,
            label: tt('common.phone'),
            toggleButtonText: tt('common.addPhone'),
            value: phone || '',
            required: false,
            inputMode: 'tel',
            grid: {
                sm: 6,
                xs: 12
            },
        },
        role: {
            type: InputType.ButtonTextField,
            label: tt('common.position'),
            toggleButtonText: tt('common.addPosition'),
            value: role || '',
            required: false,
            grid: {
                sm: 6,
                xs: 12
            },
        },
    });

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                name: {
                    ...prev.name,
                    value: name || '',
                },
                surname: {
                    ...prev.surname,
                    value: surname || '',
                },
                phone: {
                    ...prev.phone,
                    value: phone || '',
                },
                role: {
                    ...prev.role,
                    value: role || '',
                },
            };
        });
    }, [name, surname, phone, role]);

    let submitJSX = (
        <AppButton
            className={classes.button}
            variant={"contained"}
            fullWidth={isEdit}
            onClick={() => onSubmit(inputs, setInputs)}
            disabled={loadingData}
            isLoading={loading}>
            {isEdit ? tt('common.save') : tt('common.create')}
        </AppButton>
    );

    submitJSX = isEdit ? submitJSX : (
        <div className={classes.submitRightContainer}>
            {submitJSX}
        </div>
    );

    return (
        <>
            {loadingData ? <ContactPersonEditScreenShimmer/> : <FormBuilder inputs={inputs} setInputs={setInputs}/>}

            {submitJSX}
        </>
    );
}
