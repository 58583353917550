import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import ContentPadding from "../../components/paper/ContentPadding";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../components/form/FormBuilder";
import AppButton from "../../components/buttons/AppButton";
import ScreenContent from "../../components/screens/ScreenContent";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {useNavigate} from "react-router-dom";
import {kSignOutRoute} from "../authorization/SignOutScreen";
import {ErrorToast} from "../../../service/ToastService";
import {getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider} from "firebase/auth";
import {kProfileRoute} from "../profile/ProfileScreen";
import {FirebaseAuthErrorMessage} from "../../../service/AuthUserService";

export const kChangePasswordRoute = '/change-password';

export default function ChangePasswordScreen() {
    const appContext = useContext(AppContext);
    const {setTitle, setAuthUser} = appContext;

    const navigate = useNavigate();

    const [processLoading, setProcessLoading] = useState<boolean>(false);

    useEffect(() => {
        setTitle(tt('changePassword.screen.title'));
    }, []);

    /**
     * Change User password on Firebase.
     */
    const ChangePassword = async (inputs: IInputsData) => {
        setProcessLoading(true);

        try {
            const auth = getAuth();

            const user = auth.currentUser!;

            await reauthenticateWithCredential(user, EmailAuthProvider.credential(user.email!, inputs.currentPassword.value));

            await updatePassword(user, inputs.newPassword.value);

            const idToken = await user.getIdToken(true);

            setAuthUser(prev => {
                setTimeout(() => {
                    navigate(kProfileRoute);
                }, 1);

                return {
                    ...prev!,
                    idToken,
                };
            });
        } catch (e: any) {
            console.error(e);

            if (e.code === 'auth/requires-recent-login') {
                navigate(kSignOutRoute);
                ErrorToast(tt('changePassword.screen.error.recentLogin'), {duration: 6000});
            } else {
                FirebaseAuthErrorMessage(e);
            }
        }

        setProcessLoading(false);
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                loading={processLoading}
                isMobile={isMobile}
                changePassword={ChangePassword}
            />
        );
    }

    return (<ResponsiveContainer
            smallPhoneScreen={bodyJSX(true)}
            largePhoneScreen={bodyJSX(true)}
            tabletScreen={bodyJSX()}
            smallDesktopScreen={bodyJSX()}
            largeDesktopScreen={bodyJSX()}
            extraLargeDesktopScreen={bodyJSX()}
        />
    );
}

interface IBodyProps {
    isMobile?: boolean;
    loading: boolean;
    changePassword: (inputs: IInputsData) => void;
}

function Body(props: IBodyProps) {
    const {isMobile, loading, changePassword} = props;

    const [inputs, setInputs] = useState<IInputsData>({
        currentPassword: {
            type: InputType.Password,
            label: tt('changePassword.screen.currentPassword.label') + '*',
            value: '',
            required: true,
            grid: {
                sm: 6,
                xs: 12
            }
        },
        newPassword: {
            type: InputType.Password,
            label: tt('changePassword.screen.newPassword.label') + '*',
            value: '',
            required: true,
            requireMinLength: 8,
            validateOnChange: true,
            grid: {
                sm: 6,
                xs: 12
            }
        },
    });

    return (
        <ScreenContent appBar={!isMobile} noContentPadding={isMobile} navigationDrawer={!isMobile} bottomBar={isMobile}
                       centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar isMobile={isMobile} title={tt('changePassword.screen.title')}
                             backRoute={kProfileRoute}
                             cancelIconBackButton={true}/>
                <ContentPadding>
                    <FormBuilder inputs={inputs} setInputs={setInputs}/>
                    <AppButton
                        isLoading={loading}
                        variant={"contained"}
                        onClick={() => {
                            if (ValidateForm(inputs, setInputs)) {
                                changePassword(inputs);
                            }
                        }
                        }
                        fullWidth={true}>
                        {tt('changePassword.screen.submit')}
                    </AppButton>
                </ContentPadding>
            </AppPaper>
        </ScreenContent>
    )
        ;
}
