import {makeStyles} from "tss-react/mui";
import {Accordion, AccordionDetails, AccordionSummary, Box, Divider, Theme, Typography} from "@mui/material";
import {Maybe, VisitRepeatDayResponse, VisitResponse} from "../../../../../generated/graphql/graphql";
import ChevronDownIcon from "../../../../../icons/ChevronDownIcon";
import {tt} from "../../../../../core/Localization";
import React, {useContext} from "react";
import CalendarIcon from "../../../../../icons/CalendarIcon";
import {kAppColors} from "../../../../../styles/AppThemeProcessor";
import GreyLabel from "../../../decorations/GreyLabel";
import {visitDateTimes} from "../../../../../service/VisitService";
import {AppDataContext} from "../../../../../AppData";
import {DateTime} from "luxon";
import AppAccordion from "../../../modals/AppAccordion";

export const useStyles = makeStyles()((theme: Theme) => ({
    section: {
        display: "flex",
        alignItems: "center",
        'p': {
            fontSize: 14,
            fontWeight: 500,
            color: kAppColors.text.primary(theme.palette.mode === "dark")
        }
    },
    datesContainer: {
        WebkitColumnCount: '3',
        MozColumnCount: '3',
        columnsCount: '3',
        width: '100%',
        marginLeft: -8,
        marginRight: -8,
        paddingBottom: 16,
        paddingRight: 16,
        paddingLeft: 16,
        "@media (max-width: 767px)": {
            WebkitColumnCount: '1',
            MozColumnCount: '1',
            columnsCount: '1',
            width: '100%',
        },
    },
    singleDateContainer: {
        paddingLeft: 8,
        paddingRight: 8,
        p: {
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
            fontWeight: 500
        }
    },
    isPast: {
        p: {
            color: kAppColors.text.secondary(theme.palette.mode === "dark"),
            fontWeight: 400,
        }
    }
}));

export interface IVisitDetailMultidayInfoProps {
    visitData?: VisitResponse | NullOrUndefined;
    visitRepeatDay?: Maybe<VisitRepeatDayResponse>,
    visitRepeatingDay: number | undefined,
}

export default function VisitDetailMultidayInfoSection(props: IVisitDetailMultidayInfoProps) {
    const {
        visitData,
        visitRepeatDay,
        visitRepeatingDay,
    } = props;

    const {cx, classes} = useStyles();

    const visitDateTimesValues = visitDateTimes(
        visitData,
        visitRepeatDay,
        visitRepeatingDay
    );

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const today = DateTime.now().startOf('day')

    return (
        !visitDateTimesValues.isSingleDay && visitDateTimesValues.individualDays?.length ?
            <>
                <Divider/>

                <AppAccordion summaryWidget={
                    <Box className={classes.section}>
                        <CalendarIcon/>
                        <Box pl={1}/>
                        <Typography>{tt('visitDetail.screen.label.multidayInfoSection')}</Typography>
                    </Box>
                }>
                    <Box className={classes.datesContainer}>
                        {visitDateTimesValues.individualDays.map(value => {
                            return <Box
                                key={value.toMillis()}
                                className={cx(classes.singleDateContainer, value < today ? classes.isPast : null)}>
                                <Typography>{value.toFormat('EEEE d.M.yyyy', {locale: language})}</Typography>
                            </Box>
                        })}
                    </Box>
                </AppAccordion>
            </> : null
    );
}