import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8MultilineTextProps {
    className?: string;
}

export default function Icons8TextOneLine(props: IIcons8MultilineTextProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 3.5 22.5 A 1.50015 1.50015 0 1 0 3.5 25.5 L 44.5 25.5 A 1.50015 1.50015 0 1 0 44.5 22.5 L 3.5 22.5 z"/>
            </svg>
        </span>
    );
}



