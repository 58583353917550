import React, {Dispatch, SetStateAction, useContext, useEffect, useId, useMemo, useState} from "react";
import {AppDataContext} from "../../../../../AppData";
import FilterButton from "../../../buttons/FilterButton";
import {usePopupState} from "material-ui-popup-state/hooks";
import {tt} from "../../../../../core/Localization";
import Menu from "@mui/material/Menu";
import {bindMenu} from "material-ui-popup-state";
import {Box, Theme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {EmployeeJoinedUserResponsePage, GetEmployeesJoinedUsersInput} from "../../../../../generated/graphql/graphql";
import {SortEmployeesByName} from "../../../../../service/EmployeeService";
import {UserFullName, UserPhotoUrl, UserRoleTitle} from "../../../../../service/UserService";
import ListShimmer from "../../../shimmers/ListShimmer";
import EmptyListText from "../../../textComponents/EmptyListText";
import FormBuilder, {IInputsData, InputType} from "../../../form/FormBuilder";
import AppChip from "../../../chips/AppChip";
import {kAppColors} from "../../../../../styles/AppThemeProcessor";
import AppAvatar from "../../../AppAvatar";
import {processQueryError} from "../../../../../service/ErrorService";
import AppListItem from "../../../listItems/AppListItem";
import {kTopicCompanyEmployees} from "../../../../../core/constants";
import {RestApiClientContext} from "../../../../../core/RestApiProvider";


export const useStyles = makeStyles()((theme: Theme) => ({
    menuOffset: {
        '.MuiPaper-root': {
            width: '90%',
            maxWidth: 400,
        },
        transform: 'translateY(8px)',
        '.MuiListItem-root': {
            borderRadius: 0,
        }
    },
    chip: {
        marginRight: 8,
        marginTop: 6,
        marginBottom: 2,
        borderRadius: 40,
        paddingLeft: 0,
        paddingRight: 4,
        fontSize: 14,
        height: 32,
        fontWeight: 600,
        background: kAppColors.background.autocompleteChip(theme.palette.mode === "dark"),
        color: kAppColors.text.primary(theme.palette.mode === 'dark'),
        ':hover': {
            background: kAppColors.background.autocompleteChip(theme.palette.mode === "dark"),
        },
        '.MuiChip-icon.MuiChip-icon': {
            marginLeft: 3,
        },
        '.MuiChip-deleteIcon': {
            color: theme.palette.mode === 'dark' ? kAppColors.text.white : kAppColors.text.secondaryLightModeGrey,
        }
    },
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
    },
    input: {
        '.MuiInputBase-root': {
            flexDirection: "column",
            alignItems: "start",
        }
    }
}));


export interface IJobsByWorkerFilterProps {
    selected: number[];
    setSelected: Dispatch<SetStateAction<number[]>>;
    validEmployees?: number[];
    isDisabled?: boolean;
}

export default function VisitsByWorkerFilter(props: IJobsByWorkerFilterProps) {
    const {selected, setSelected, validEmployees, isDisabled} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, storage, setStorage} = appDataContext;

    const {classes} = useStyles();

    const workersFilterMenu = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            className: classes.input,
            type: InputType.Text,
            label: '',
            value: '',
            required: true,
            size: "small",
            hideLabel: true,
            placeholder: tt('jobsScreen.filterByWorkers.input.label'),
        },
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<EmployeeJoinedUserResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (companyId && workersFilterMenu.isOpen) {
            const subscription = subscribe(
                kTopicCompanyEmployees,
                {
                    uri: '/company/employee/search-joined-users',
                    params: {
                        companyId: companyId!,
                    } as GetEmployeesJoinedUsersInput,
                    setLoading,
                    onData: setData,
                    onError: (error) => {
                        processQueryError(appDataContext, error);
                    },
                },
                (notifications) => true,
            );

            return () => subscription.cancel();
        } else {
            setData(undefined);
        }
    }, [companyId, workersFilterMenu.isOpen]);

    useEffect(() => {
        if (data) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(data.files || []),
                    ],
                };
            });
        }
    }, [data]);

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                search: {
                    ...prev.search,
                    innerPrefixJSX: (
                        <Box className={classes.chipsContainer}>{selected.map(id => {
                            const worker = data?.content.find(element => element.id === id);
                            const name = UserFullName(worker?.name || worker?.user?.name, worker?.surname || worker?.user?.surname);

                            return <AppChip
                                key={id}
                                icon={
                                    <AppAvatar
                                        variant={"extraSmall"}
                                        img={
                                            UserPhotoUrl(worker?.user, data?.files, storage.publicUrlsForFiles)
                                        }
                                        title={name}
                                    />
                                }
                                onDelete={
                                    () => {
                                        setSelected(prev => {
                                            const newSelected = [];

                                            for (const idOf of prev) {
                                                if (idOf !== id) {
                                                    newSelected.push(idOf);
                                                }
                                            }

                                            return newSelected;
                                        });
                                    }
                                }
                                label={name}
                                className={classes.chip}
                            />;
                        })}</Box>
                    ),
                }
            };
        });
    }, [selected, data, storage.publicUrlsForFiles]);

    const content = useMemo(
        () => {
            const theData = data?.content
                .filter(worker => {
                    if (validEmployees && !validEmployees.includes(worker.id)) {
                        return false;
                    }

                    return worker.active && !worker.deleted;
                });

            return loading && !theData ?
                <ListShimmer items={2}/> : theData && theData.length > 0 ?
                    SortEmployeesByName(
                        theData
                            .filter(worker => {
                                const fullName = UserFullName(worker?.name || worker?.user?.name, worker?.surname || worker?.user?.surname);

                                return fullName.toLowerCase().includes(inputs.search.value.toLowerCase()) && !selected.includes(worker.id);
                            })
                    ).slice(0, 5)
                        .map(worker => {
                            return (
                                <Box
                                    key={worker.id}
                                    mr={-1}
                                    ml={-1}
                                >
                                    <AppListItem
                                        key={worker.id}
                                        onClick={() => {
                                            ///can select max 3 workers
                                            if (selected.length < 3) {
                                                setSelected(prev => {
                                                    return [
                                                        ...prev,
                                                        worker.id,
                                                    ];
                                                });
                                            }
                                        }}
                                        variant={"smaller-title"}
                                        profileImage={
                                            UserPhotoUrl(worker?.user, data?.files, storage.publicUrlsForFiles)
                                        }
                                        title={UserFullName(worker?.name || worker?.user?.name, worker?.surname || worker?.user?.surname)}
                                        description={UserRoleTitle(worker.role)}
                                        actionWidget={<></>}
                                    />
                                </Box>
                            );
                        })
                    : <EmptyListText text={tt('workers.screen.emptyListMessage')}/>;
        },
        [data, inputs.search.value, selected, validEmployees, storage.publicUrlsForFiles]
    );

    const buttonTitle = useMemo(
        () => {
            const firstWorker = data?.content.find(element => element.id === selected[0]);
            const name = UserFullName(firstWorker?.name || firstWorker?.user?.name, firstWorker?.surname || firstWorker?.user?.surname);
            return `${name}${selected.length > 1 ? ` + ${selected.length - 1}` : ''}`;
        }, [data, selected]
    );

    return (
        <>
            <FilterButton
                title={selected.length > 0 ? buttonTitle : tt('jobs.screen.filterByWorkers.button')}
                onClick={
                    (e) => {
                        workersFilterMenu.setOpen(true, e);
                    }
                }
                isSelected={selected.length > 0}
                isDisabled={isDisabled}
            />

            <Menu className={classes.menuOffset}  {...bindMenu(workersFilterMenu)}>
                <FormBuilder inputs={inputs} setInputs={setInputs}/>

                {content}
            </Menu>
        </>
    );
}
