import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8QRCodeProps {
    className?: string;
}

export default function Icons8QRCode(props: IIcons8QRCodeProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 10.5 6 C 8.0324991 6 6 8.0324991 6 10.5 L 6 17.5 C 6 19.967501 8.0324991 22 10.5 22 L 17.5 22 C 19.967501 22 22 19.967501 22 17.5 L 22 10.5 C 22 8.0324991 19.967501 6 17.5 6 L 10.5 6 z M 30.5 6 C 28.032499 6 26 8.0324991 26 10.5 L 26 17.5 C 26 19.967501 28.032499 22 30.5 22 L 37.5 22 C 39.967501 22 42 19.967501 42 17.5 L 42 10.5 C 42 8.0324991 39.967501 6 37.5 6 L 30.5 6 z M 10.5 9 L 17.5 9 C 18.346499 9 19 9.6535009 19 10.5 L 19 17.5 C 19 18.346499 18.346499 19 17.5 19 L 10.5 19 C 9.6535009 19 9 18.346499 9 17.5 L 9 10.5 C 9 9.6535009 9.6535009 9 10.5 9 z M 30.5 9 L 37.5 9 C 38.346499 9 39 9.6535009 39 10.5 L 39 17.5 C 39 18.346499 38.346499 19 37.5 19 L 30.5 19 C 29.653501 19 29 18.346499 29 17.5 L 29 10.5 C 29 9.6535009 29.653501 9 30.5 9 z M 12 12 L 12 16 L 16 16 L 16 12 L 12 12 z M 32 12 L 32 16 L 36 16 L 36 12 L 32 12 z M 10.5 26 C 8.0324991 26 6 28.032499 6 30.5 L 6 37.5 C 6 39.967501 8.0324991 42 10.5 42 L 17.5 42 C 19.967501 42 22 39.967501 22 37.5 L 22 30.5 C 22 28.032499 19.967501 26 17.5 26 L 10.5 26 z M 26 26 L 26 31.5 L 31.5 31.5 L 31.5 26 L 26 26 z M 31.5 31.5 L 31.5 36.5 L 36.5 36.5 L 36.5 31.5 L 31.5 31.5 z M 36.5 31.5 L 42 31.5 L 42 26 L 36.5 26 L 36.5 31.5 z M 36.5 36.5 L 36.5 42 L 42 42 L 42 36.5 L 36.5 36.5 z M 31.5 36.5 L 26 36.5 L 26 42 L 31.5 42 L 31.5 36.5 z M 10.5 29 L 17.5 29 C 18.346499 29 19 29.653501 19 30.5 L 19 37.5 C 19 38.346499 18.346499 39 17.5 39 L 10.5 39 C 9.6535009 39 9 38.346499 9 37.5 L 9 30.5 C 9 29.653501 9.6535009 29 10.5 29 z M 12 32 L 12 36 L 16 36 L 16 32 L 12 32 z"/>
            </svg>
        </span>
    );
}
