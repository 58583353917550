import React from "react";
import {useStyles as formUseStyles} from "./FormBuilder";
import {Box, Checkbox, FormControlLabel, Theme, Typography} from "@mui/material";
import {IconCheckBoxProps} from "./InputProps";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";

const useStyles = makeStyles()((theme: Theme) => ({
    checkboxClass: {
        ':hover': {
            backgroundColor: kAppColors.hoverColor.grey(theme.palette.mode === "dark")
        },
    },
    checkBoxLabel: {
        width: 'calc(100% - 16px)',
        justifyContent: "space-between",
        padding: '4px 0',
    }
}));

/**
 * Render the component into html.
 */
export default function IconCheckBox(props: IconCheckBoxProps) {
    const {
        index,
        className,
        label,
        value,
        updateValue,
        icon,
        hidden,
        error,
        errorMessage,
        disabled,
    } = props;

    const {classes: formClasses, cx} = formUseStyles();
    const {classes} = useStyles();

    /**
     * OnChange update data of this field.
     */
    const OnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateValue(index, e.target.checked);
    };

    const _label = <Box sx={{display: 'flex'}}>
        <Box mr={1}>{icon}</Box>
        <Typography mr={"auto"} sx={{alignSelf: 'center'}}>{label}</Typography>
    </Box>;

    return (
        <Box className={cx(className, (hidden ? formClasses.hidden : undefined), classes.checkboxClass)}>
            <FormControlLabel className={classes.checkBoxLabel}
                              control={<Checkbox
                                  checked={value}
                                  disabled={disabled}
                                  onChange={OnChange}
                              />}
                              label={_label}
                              labelPlacement="start"/>
        </Box>
    );
}