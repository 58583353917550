import React, {Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../../../App";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import PaperAppbar from "../../components/paper/PaperAppbar";
import ContentPadding from "../../components/paper/ContentPadding";
import {useNavigate, useParams} from "react-router-dom";
import {clientDetailRoute} from "./ClientDetailScreen";
import AppButton from "../../components/buttons/AppButton";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../components/form/FormBuilder";
import {
    AddressProvider,
    ClientResponse,
    GetClientInput,
    UpdateClientDocument,
    UpdateClientMutation,
    UpdateClientMutationVariables
} from "../../../generated/graphql/graphql";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import IBreadcrumb from "../../../model/Breadcrumb";
import {kClientsBreadcrumb, kClientsRoute} from "./ClientsScreen";
import {AppDataContext, SetAppBreadcrumbs} from "../../../AppData";
import AppBreadCrumb from "../../components/breadCrumb/AppBreadCrumb";
import ClientEditScreenShimmer from "../../components/shimmers/screenSpecificShimmers/ClientEditScreenShimmer";
import IEventSystemListener from "../../../model/EventSystemListener";
import {
    listenToEventSystem,
    unListenToEventSystem
} from "../../../service/EventSystemService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import {processQueryError} from "../../../service/ErrorService";
import {kActionDelete, kTopicClients} from "../../../core/constants";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import {Box, Divider, InputAdornment, Theme} from "@mui/material";
import AppAccordion from "../../components/modals/AppAccordion";
import Icons8InvoicePaid from "../../../icons/Icons8InvoicePaid";
import {makeStyles} from "tss-react/mui";
import {IAresResponseCompany, kAvailableCountries, loadCompaniesFromAres} from "../../../service/CompanyService";
import Icons8Invoice from "../../../icons/Icons8Invoice";
import {
    addressToSingleLine,
    countryOptionForCode,
    hasBillingAddress,
    kAllCountriesAsOptions
} from "../../../utils/AddressUtils";
import SearchIcon from "../../../icons/SearchIcon";

export const kClientEditRoute = '/clients/:clientId/edit';

export function clientEditRoute(clientId: number | string): string {
    return kClientEditRoute.replace(':clientId', `${clientId}`)
}

export const useStyles = makeStyles()((theme: Theme) => ({
    accordion: {
        '.titleContainer': {
            width: '100%',
            alignItems: "center",
            p: {
                flexGrow: 1,
            }
        }
    },
    desktopOnly: {
        "@media (max-width: 430)": {
            display: "none",
        }
    },
    extendPopOver: {
        width: 735,
        maxWidth: 'calc( 100vw - 32px)',
    },
    helperText: {
        display: 'block',
        color: theme.palette.mode == 'dark' ? '#989898' : '#65676B',
        fontSize: '0.75rem',
        marginTop: -16,
        marginLeft: 12,
        paddingBottom: 20,
    },
}));


export default function ClientEditScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);

    const {clientId} = useParams();
    const navigate = useNavigate();

    const {classes} = useStyles();

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<ClientResponse | NullOrUndefined>();
    useEffect(() => {
        if (clientId) {
            restApiGet({
                uri: '/client',
                params: {
                    clientId: parseInt(clientId!),
                } as GetClientInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading,
                onData: setData,
                onError: (error) => processQueryError(appDataContext, error),
            });
        } else {
            setLoading(false);
            setData(null);
        }
    }, [clientId]);

    const [mutateUpdate, {
        loading: updateLoading,
        error: updateError,
        data: updateData,
    }] = useResettableMutation<UpdateClientMutation, UpdateClientMutationVariables>(UpdateClientDocument);

    /**
     * Set breadcrumbs.
     */
    const SetBreadcrumbs = () => {
        const breadcrumbs: IBreadcrumb[] = [kClientsBreadcrumb];

        if (data) {
            breadcrumbs.push({
                key: 'client-detail',
                text: data!.name,
                route: clientDetailRoute(clientId!),
            });

            breadcrumbs.push({
                key: 'client-edit',
                text: tt('clientEdit.screen.title'),
                route: clientEditRoute(clientId!),
            });
        }

        SetAppBreadcrumbs(appDataContext, breadcrumbs);
    };

    useEffect(() => {
        setTitle(tt('clientEdit.screen.title'));

        SetBreadcrumbs();
    }, []);

    useEffect(() => {
        SetBreadcrumbs();
    }, [data]);

    const [formInitialized, setFormInitialized] = useState<boolean>(false);
    const [validAresCompanies, setValidAresCompanies] = useState<IAresResponseCompany[]>([]);
    const [billingExpanded, setBillingExpanded] = useState<boolean>(false);
    const [inputs, setInputs] = useState<IInputsData>({
        switch: {
            type: InputType.Switch,
            label: tt('createClientModal.switch'),
            switchVariant: "Android12Switch",
            value: false,
        },
        companyName: {
            type: InputType.TextAutocomplete,
            paperClass: classes.extendPopOver,
            clearOnBlur: false,
            autocompleteOptions: [],
            label: `${tt('common.companyNameOrClientName')}*`,
            value: '',
            required: true,
            grid: {
                sm: 6,
                xs: 12
            },
        },
        country: {
            type: InputType.TextAutocomplete,
            label: `${tt('common.country')}*`,
            placeholder: tt('clientForm.label.country.placeholder'),
            autocompleteOptions: kAllCountriesAsOptions(),
            value: '',
            required: true,
            grid: {
                sm: 6,
                xs: 12
            }
        },
        emptySpace: {
            type: InputType.CustomRenderer,
            value: '',
            label: '',
            render: () => {
                return (<></>);
            },
        },
        companyPhone: {
            type: InputType.ButtonTextField,
            label: tt('common.phone'),
            toggleButtonText: tt('common.addPhone'),
            value: '',
            required: false,
            inputMode: 'tel',
            grid: {
                sm: 6,
                xs: 12
            },
        },
        companyEmail: {
            testId: 'createClientFormCompanyEmailInput',
            type: InputType.ButtonTextField,
            label: tt('common.email'),
            toggleButtonText: tt('common.addEmail'),
            value: '',
            required: false,
            requireEmail: true,
            grid: {
                sm: 6,
                xs: 12
            },
        },
    });

    const [billingDataInputs, setBillingDataInputs] = useState<IInputsData>({
        businessId: {
            testId: 'createClientFormBusinessIdInput',
            type: InputType.TextAutocomplete,
            clearOnBlur: false,
            autocompleteOptions: [],
            label: tt('clientForm.label.businessId'),
            placeholder: tt('clientForm.label.businessId.placeholder'),
            value: '',
            grid: {
                sm: 6,
                xs: 12
            },
            filterOptions: (options, params) => {
                return [...options].filter((option) => option.value.includes(params.inputValue));
            },
        },
        taxId: {
            testId: 'createClientFormTaxIdInput',
            type: InputType.Text,
            label: tt('clientForm.label.taxId'),
            value: '',
            grid: {
                sm: 6,
                xs: 12
            },
        },
        street: {
            testId: 'createClientFormStreetInput',
            type: InputType.Text,
            label: tt('clientForm.label.street'),
            value: '',
            grid: {
                sm: 6,
                xs: 12
            },
        },
        city: {
            testId: 'createClientFormCityInput',
            type: InputType.Text,
            label: tt('clientForm.label.city'),
            value: '',
            grid: {
                sm: 6,
                xs: 12
            },
        },
        zipcode: {
            testId: 'createClientFormZipCodeInput',
            type: InputType.Text,
            label: tt('clientForm.label.zipcode'),
            value: '',
            grid: {
                sm: 6,
                xs: 12
            },
        },
        emptySpace: {
            className: classes.desktopOnly,
            type: InputType.CustomRenderer,
            value: '',
            label: '',
            render: () => {
                return (
                    <Box></Box>
                );
            },
            grid: {
                sm: 6,
            },
        },
    });

    useEffect(() => {
        if (data) {
            setInputs(prev => {
                return {
                    ...prev,
                    switch: {
                        ...prev.switch,
                        value: data.company || false,
                    },
                    companyName: {
                        ...prev.companyName,
                        label: data.company ? `${tt('clientForm.label.companyName')}*` : `${tt('clientForm.label.nameAndSurname')}*`,
                        value: data.name || '',
                    },
                    country: {
                        ...prev.country,
                        value: data.billingAddress?.country ? countryOptionForCode(data.billingAddress.country) : '',
                    },
                    companyEmail: {
                        ...prev.companyEmail,
                        value: data.email || '',
                    },
                    companyPhone: {
                        ...prev.companyPhone,
                        value: data.phoneNumber || '',
                    },
                };
            });

            if (data.billingAddress) {
                setBillingExpanded(true);

                setBillingDataInputs(prev => {
                    return {
                        ...prev,
                        street: {
                            ...prev.street,
                            value: data.billingAddress?.street || '',
                        },
                        city: {
                            ...prev.city,
                            value: data.billingAddress?.city || '',
                        },
                        zipcode: {
                            ...prev.zipcode,
                            value: data.billingAddress?.zipcode || '',
                        },
                        businessId: {
                            ...prev.businessId,
                            value: data.billingAddress?.businessId || '',
                        },
                        taxId: {
                            ...prev.taxId,
                            value: data.billingAddress?.taxId || '',
                        },
                    };
                });
            }

            setTimeout(() => {
                setFormInitialized(true);
            }, 1);
        }
    }, [data]);

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                companyName: {
                    ...prev.companyName,
                    label: inputs.switch.value ? `${tt('clientForm.label.companyName')}*` : `${tt('clientForm.label.nameAndSurname')}*`,
                },
            };
        });
    }, [inputs.switch.value]);

    const country = inputs.country.value.value || inputs.country.value;

    useEffect(() => {
        if (formInitialized) {
            setInputs(prev => {
                return {
                    ...prev,
                    companyName: {
                        ...prev.companyName,
                        value: '',
                        autocompleteOptions: [],
                    },
                };
            });
            setValidAresCompanies([]);
        }
    }, [country]);

    useEffect(() => {
        if (formInitialized) {
            let type = InputType.Text;
            let updateOptionsForQueryName: ((query: string) => void) | undefined;
            let updateOptionsForQueryId: ((query: string) => void) | undefined;
            let innerPrefixJSX: ReactNode | undefined;
            let helperText: ReactNode | undefined;

            if (country === 'cz' && inputs.switch.value) {
                type = InputType.TextAutocomplete;

                updateOptionsForQueryName = async (query: string) => {
                    const companies = await loadCompaniesFromAres(appDataContext, {
                        name: query,
                    });

                    setValidAresCompanies(companies);
                    setInputs(prevState => {
                        return {
                            ...prevState,
                            companyName: {
                                ...prevState.companyName,
                                autocompleteOptions: companies.map(company => {
                                    return {
                                        label: company.name,
                                        value: company.businessId,
                                        description: `${company.businessId} · ${addressToSingleLine(company.address)}`.trim(),
                                        icon: <Icons8Invoice/>,
                                    };
                                }),
                            },
                        };
                    });
                };
                updateOptionsForQueryId = async (query: string) => {
                    const companies = await loadCompaniesFromAres(appDataContext, {
                        businessId: query,
                    });

                    setValidAresCompanies(companies);
                    setBillingDataInputs(prevState => {
                        return {
                            ...prevState,
                            businessId: {
                                ...prevState.businessId,
                                autocompleteOptions: companies.map(company => {
                                    return {
                                        label: company.name,
                                        value: company.businessId,
                                        description: tt('createClientModal.companyOptionDescription').replace('$businessId', company.businessId).replace('$address', addressToSingleLine(company.address)).trim(),
                                        icon: <Icons8Invoice/>,
                                    };
                                }),
                            },
                        };
                    });
                };
                innerPrefixJSX = (
                    <InputAdornment position={"start"}>
                        <SearchIcon/>
                    </InputAdornment>
                );
                helperText = <span className={classes.helperText}>{tt('clientForm.label.businessId.helperText')}</span>;
            } else {
                setValidAresCompanies([]);
            }

            setInputs(prevState => {
                return {
                    ...prevState,
                    companyName: {
                        ...prevState.companyName,
                        type: type,
                        updateOptionsForQuery: updateOptionsForQueryName,
                        innerPrefixJSX,
                    },
                };
            });
            setBillingDataInputs(prevState => {
                return {
                    ...prevState,
                    businessId: {
                        ...prevState.businessId,
                        type: type,
                        updateOptionsForQuery: updateOptionsForQueryId,
                        innerPrefixJSX,
                        helperText,
                    },
                };
            });
        }
    }, [inputs.switch.value, inputs.country.value, formInitialized]);

    useEffect(() => {
        const value = inputs.companyName.value;

        if (value && value.value) {
            const validCompany = validAresCompanies.find(company => company.businessId === value.value);

            if (validCompany) {
                setBillingExpanded(true);

                setInputs(prev => {
                    return {
                        ...prev,
                        country: {
                            ...prev.country,
                            value: countryOptionForCode(validCompany.address.country),
                        },
                    };
                });
                setBillingDataInputs(prev => {
                    return {
                        ...prev,
                        street: {
                            ...prev.street,
                            value: validCompany.address.street,
                        },
                        city: {
                            ...prev.city,
                            value: validCompany.address.city,
                        },
                        zipcode: {
                            ...prev.zipcode,
                            value: validCompany.address.zipcode,
                        },
                        businessId: {
                            ...prev.businessId,
                            value: validCompany.businessId,
                        },
                        taxId: {
                            ...prev.taxId,
                            value: validCompany.taxId,
                        },
                    };
                });
            }
        }
    }, [inputs.companyName.value, validAresCompanies]);

    useEffect(() => {
        const value = billingDataInputs.businessId.value;

        if (value && value.value) {
            const validCompany = validAresCompanies.find(company => company.businessId === value.value);

            if (validCompany) {
                setBillingExpanded(true);

                setInputs(prev => {
                    return {
                        ...prev,
                        companyName: {
                            ...prev.companyName,
                            value: validCompany.name,
                        },
                        country: {
                            ...prev.country,
                            value: countryOptionForCode(validCompany.address.country),
                        },
                    };
                });
                setBillingDataInputs(prev => {
                    return {
                        ...prev,
                        street: {
                            ...prev.street,
                            value: validCompany.address.street,
                        },
                        city: {
                            ...prev.city,
                            value: validCompany.address.city,
                        },
                        zipcode: {
                            ...prev.zipcode,
                            value: validCompany.address.zipcode,
                        },
                        businessId: {
                            ...prev.businessId,
                            value: validCompany.businessId,
                        },
                        taxId: {
                            ...prev.taxId,
                            value: validCompany.taxId,
                        },
                    };
                });
            }
        }
    }, [billingDataInputs.businessId.value, validAresCompanies]);

    const UpdateClient = async () => {
        if (ValidateForm(inputs, setInputs) && ValidateForm(billingDataInputs, setBillingDataInputs)) {
            try {
                const country = inputs.country.value.value || inputs.country.value;
                const businessId = billingDataInputs.businessId.value.value || billingDataInputs.businessId.value;

                const billingAddress = {
                    id: undefined,
                    street: billingDataInputs.street.value,
                    city: billingDataInputs.city.value,
                    country,
                    state: '',
                    zipcode: billingDataInputs.zipcode.value,
                    businessId,
                    taxId: billingDataInputs.taxId.value,
                    createdAt: undefined,
                    provider: AddressProvider.None,
                };

                const companyName = inputs.companyName.value.label || inputs.companyName.value;

                const variables: UpdateClientMutationVariables = {
                    input: {
                        clientId: parseInt(clientId!),
                        isCompany: inputs.switch.value,
                        name: companyName,
                        phoneNumber: inputs.companyPhone.value || undefined,
                        email: inputs.companyEmail.value || undefined,
                        billingAddress: hasBillingAddress(billingAddress) && inputs.switch.value ? billingAddress : undefined,
                    },
                };

                const result = await mutateUpdate({variables});

                if (!result.errors) {
                    navigate(clientDetailRoute(clientId!));

                    SuccessToast(tt('clientEdit.screen.success'));
                }
            } catch (e) {
                console.error(e);

                ErrorToast(tt('common.mutation.error'));
            }
        }
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                clientId={parseInt(clientId!)}
                isMobile={isMobile}
                inputs={inputs}
                setInputs={setInputs}
                billingDataInputs={billingDataInputs}
                setBillingDataInputs={setBillingDataInputs}
                loading={loading || updateLoading}
                loadingData={!data && loading}
                updateClient={UpdateClient}
                billingExpanded={billingExpanded}
                setBillingExpanded={setBillingExpanded}
            />
        );
    }

    return (
        <>
            <EventSystemListeners/>

            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}
            />
        </>
    );
}

/**
 * Component for EventSystem listeners.
 */
function EventSystemListeners() {
    const {clientId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicClients,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(clientId!);
                });

                if (doDelete) {
                    navigate(kClientsRoute);
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [clientId]);

    return null;
}

interface IBodyProps {
    isMobile?: boolean;
    clientId: number,
    loading: boolean;
    loadingData?: boolean;
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    billingDataInputs: IInputsData;
    setBillingDataInputs: Dispatch<SetStateAction<IInputsData>>;
    updateClient: VoidFunction;
    billingExpanded: boolean;
    setBillingExpanded: Dispatch<SetStateAction<boolean>>;
}

function Body(props: IBodyProps) {
    const {
        isMobile,
        clientId,
        inputs,
        setInputs,
        updateClient,
        loading,
        loadingData,
        billingDataInputs,
        setBillingDataInputs,
        billingExpanded,
        setBillingExpanded,
    } = props;

    const {classes} = useStyles();

    const billingFormJSX = useMemo(() => {
        if (inputs.switch.value) {
            return (
                <>
                    <Divider/>

                    <AppAccordion
                        className={classes.accordion}
                        summaryText={tt('clientEdit.screen.label.billingData')}
                        summaryWidget={<Box pr={1}><Icons8Invoice/></Box>}
                        expanded={billingExpanded}
                        setExpanded={setBillingExpanded}
                    >
                        <FormBuilder inputs={billingDataInputs} setInputs={setBillingDataInputs}/>
                    </AppAccordion>
                </>
            );
        }

        return null;
    }, [billingDataInputs, inputs.switch.value, billingExpanded]);

    return (
        <ScreenContent
            showBreadCrumb={true}
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}
            >
                <PaperAppbar isMobile={isMobile}
                             title={tt('clientEdit.screen.title')}
                             backRoute={clientDetailRoute(clientId!)}
                             cancelIconBackButton={true}/>
                {isMobile ? <AppBreadCrumb/> : null}
                <ContentPadding>
                    {loadingData ? <ClientEditScreenShimmer/> : <>
                        <FormBuilder inputs={inputs} setInputs={setInputs}/>

                        {billingFormJSX}

                        <Box pb={1}/>
                    </>}

                    <AppButton
                        variant={"contained"}
                        fullWidth={true}
                        onClick={updateClient}
                        disabled={loading}
                    >
                        {tt('common.save')}
                    </AppButton>
                </ContentPadding>
            </AppPaper>
        </ScreenContent>
    );
}
