import {Box} from "@mui/material";
import DetailContactRow from "./DetailContactRow";
import React from "react";
import {tt} from "../../../core/Localization";
import Icons8Envelope from "../../../icons/Icons8Envelope";
import Icons8PhoneFilled from "../../../icons/Icons8PhoneFilled";
import ShowOnMapMenu from "../../components/menus/ShowOnMapMenu";
import {Address} from "../../../generated/graphql/graphql";
import DescriptionWidget from "../../components/jobs/visits/visitDetail/DescriptionWidget";

export interface IContactSectionProps {
    email?: string | NullOrUndefined;
    loginEmail?: string | NullOrUndefined;
    phone?: string | NullOrUndefined;
    address?: string;
    addressSpecification?: string | NullOrUndefined;
    addressLink?: string;
    hideEmail?: boolean;
    hideAddress?: boolean;
    addressFirst?: boolean;
    addressObject?: Address;
}

export default function ContactSection(props: IContactSectionProps) {
    const {
        email,
        loginEmail,
        phone,
        address,
        addressSpecification,
        addressLink,
        hideEmail,
        hideAddress,
        addressFirst,
        addressObject,
    } = props;


    const loginEmailJSX = loginEmail ?
        <DetailContactRow
            description={tt('common.loginEmail')}
            title={loginEmail!}
        /> : null;

    const emailJSX = <DetailContactRow
        title={email || tt('common.noEmail')}
        icon={<Icons8Envelope/>}
        href={`mailto:${email}`}
        disabled={email === '' || email === null || phone === undefined}
    />;

    const phoneJSX = <DetailContactRow
        title={phone || tt('common.noPhone')}
        icon={<Icons8PhoneFilled/>}
        href={`tel:${phone}`}
        disabled={phone === '' || phone === null || phone === undefined}
    />;

    const navigationJSX = addressObject ? (
        <ShowOnMapMenu address={addressObject}/>
    ) : null;

    const addressJSX =
        <>
            <DetailContactRow
                title={address || tt('common.noAddress')}
                customIconComponent={
                    <>
                        {navigationJSX}
                    </>
                }
                href={`${addressLink}`}
                openInNewTab={true}
                disabled={address === undefined || address === ''}/>
            {
                addressSpecification ? <Box pt={1} pr={2} pl={2}><DescriptionWidget
                    variant={'2lines'}
                    content={addressSpecification}
                /></Box> : null
            }
        </>;

    const addressAndPhoneJSX = <>


        {hideAddress ? null : addressJSX}

        {phoneJSX}
    </>;

    const phoneAndAddress = <>
        {phoneJSX}

        {hideAddress ? null : addressJSX}
    </>;

    return (
        <Box>
            {loginEmailJSX}

            {hideEmail ? null : emailJSX}

            {addressFirst ? addressAndPhoneJSX : phoneAndAddress}

            <Box sx={{mb: 1}}/>
        </Box>
    );
}
