import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8DropdownProps {
    className?: string;
}

/**
 * Component representing Icons8Dropdown svg icon.
 */
export default function Icons8DropdownIcon(props: IIcons8DropdownProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px"
                 preserveAspectRatio="xMidYMid meet" focusable="false">
                <path
                    d="M 24 4 C 12.972066 4 4 12.972074 4 24 C 4 35.027926 12.972066 44 24 44 C 35.027934 44 44 35.027926 44 24 C 44 12.972074 35.027934 4 24 4 z M 24 7 C 33.406615 7 41 14.593391 41 24 C 41 33.406609 33.406615 41 24 41 C 14.593385 41 7 33.406609 7 24 C 7 14.593391 14.593385 7 24 7 z M 31.986328 18.978516 A 1.50015 1.50015 0 0 0 30.939453 19.439453 L 24 26.378906 L 17.060547 19.439453 A 1.50015 1.50015 0 0 0 15.984375 18.984375 A 1.50015 1.50015 0 0 0 14.939453 21.560547 L 22.939453 29.560547 A 1.50015 1.50015 0 0 0 25.060547 29.560547 L 33.060547 21.560547 A 1.50015 1.50015 0 0 0 31.986328 18.978516 z"/>
            </svg>
        </span>
    );
}
