import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8MoveGrabberProps {
    className?: string;
}

export default function Icons8MoveGrabber(props: IIcons8MoveGrabberProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 23.978516 4 A 1.50015 1.50015 0 0 0 22.939453 4.4394531 L 17.939453 9.4394531 A 1.50015 1.50015 0 1 0 20.060547 11.560547 L 22.5 9.1210938 L 22.496094 16 L 6.5 16 A 1.50015 1.50015 0 1 0 6.5 19 L 23.748047 19 A 1.50015 1.50015 0 0 0 24.234375 19 L 41.5 19 A 1.50015 1.50015 0 1 0 41.5 16 L 25.496094 16 L 25.5 9.1210938 L 27.939453 11.560547 A 1.50015 1.50015 0 1 0 30.060547 9.4394531 L 25.060547 4.4394531 A 1.50015 1.50015 0 0 0 23.978516 4 z M 6.5 22.5 A 1.50015 1.50015 0 1 0 6.5 25.5 L 41.5 25.5 A 1.50015 1.50015 0 1 0 41.5 22.5 L 6.5 22.5 z M 23.964844 28.978516 A 1.50015 1.50015 0 0 0 23.742188 29 L 6.5 29 A 1.50015 1.50015 0 1 0 6.5 32 L 22.486328 32 L 22.482422 38.861328 L 20.060547 36.439453 A 1.50015 1.50015 0 1 0 17.939453 38.560547 L 22.939453 43.560547 A 1.50015 1.50015 0 0 0 25.060547 43.560547 L 30.060547 38.560547 A 1.50015 1.50015 0 1 0 27.939453 36.439453 L 25.482422 38.896484 L 25.486328 32 L 41.5 32 A 1.50015 1.50015 0 1 0 41.5 29 L 24.232422 29 A 1.50015 1.50015 0 0 0 23.964844 28.978516 z"/>
            </svg>
        </span>
    );
}