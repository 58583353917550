import {Address, AddressFragment, AddressProvider} from "../generated/graphql/graphql";
import GoogleMapReact from "google-map-react";
import {IAppDataContext} from "../AppData";
import {WaitFor} from "../utils/DateUtils";
import {FlavorByEnvironment} from "../flavor-config";
import {addressToSingleLine} from "../utils/AddressUtils";

export const kDefaultMapProps = {
    center: {
        lat: 50.08089414234179,
        lng: 14.429381567668688,
    },
    zoom: 11,
    closeInMapZoom: 17,
};

export interface IAddressesForTermsResult {
    description: string;
    placeId: string;
}

export interface IAddressesForTermsMapyCZResult {
    description: string;
}

/**
 * Get list of addresses for terms.
 */
export async function AddressesForTerms(service: any, terms: string): Promise<IAddressesForTermsResult[]> {
    try {
        const result = await service.getPlacePredictions({
            input: terms,
            types: ['address'],
        });

        if (result.predictions) {
            const results: IAddressesForTermsResult[] = [];

            for (const predictionOf of result.predictions) {
                const existing = results.find((existing) => existing.description == predictionOf.description);

                if (!existing) {
                    results.push({
                        description: predictionOf.description,
                        placeId: predictionOf.place_id,
                    });
                }
            }

            return results;
        }
    } catch (e) {
        console.error(e);
    }

    return [];
}

export interface IGeocodeAddressToCoordinateParams {
    service: any;
    address: string;
    placeId?: string;
}

export interface IGeocodeAddressToCoordinateResult {
    coordinate: GoogleMapReact.Coords;
    addressFragment: AddressFragment;
}

/**
 * Geocode address text into useAble location coordinate as well as address parts.
 */
export async function GeocodeAddressToCoordinate(params: IGeocodeAddressToCoordinateParams): Promise<IGeocodeAddressToCoordinateResult | null> {
    const {service, address, placeId} = params;

    try {
        const result = await service.geocode({
            address: placeId ? undefined : address,
            placeId: placeId,
        });

        if (result && result.results.length > 0) {
            const theResult = result.results[0];
            const geometry = theResult.geometry;
            const components = theResult.address_components;

            const theCoordinate = {
                lat: geometry.location.lat(),
                lng: geometry.location.lng(),
            };

            const theFragment = AddressComponentsIntoParts(theCoordinate, components,
                AddressProvider.Google
            );

            theFragment.googlePlacesId = theResult.place_id;

            return {
                coordinate: theCoordinate,
                addressFragment: theFragment,
            };
        }
    } catch (e) {
        console.error(e);
    }

    return null;
}

/**
 * Parse map components into address parts.
 */
function AddressComponentsIntoParts(coordinate: GoogleMapReact.Coords, components: any, provider: AddressProvider): AddressFragment {
    let street = '';
    let streetNumber = '';
    let city = '';
    let zipcode = '';
    let state = '';
    let country = '';

    for (const component of components) {
        const types = component.types;

        if (types.includes('street_number')) {
            streetNumber = component.long_name;
        } else if (!streetNumber && types.includes('premise')) {
            streetNumber = component.long_name;
        } else if (types.includes('route')) {
            street = component.long_name;
        } else if (types.includes('sublocality_level_1')) {
            city = component.long_name;
        } else if (types.includes('country')) {
            country = component.short_name.toLowerCase();
        } else if (types.includes('postal_code')) {
            zipcode = component.long_name;
        } else if (types.includes('administrative_area_level_1')) {
            state = component.long_name;
        }
    }

    return {
        street: `${street} ${streetNumber}`.trim(),
        city,
        zipcode,
        state,
        country,
        latitude: coordinate.lat,
        longitude: coordinate.lng,
        provider,
    };
}

/**
 * Update GoogleMaps map directly with API.
 */
export function OnGoogleMapLoaded(appDataContext: IAppDataContext, maps: { map: any; maps: any; ref: Element | null }) {
    if (appDataContext.darkMode) {
        const mapDarkMode = require('../assets/mapDarkMode.json');

        maps.map.setOptions({styles: mapDarkMode});
    }
}

export const kLocationDiagnosisDocumentLink = 'https://docs.google.com/document/d/1GE5jBWzA_Yjq2GWb_xPs6qNnTaSBySW2lbbR1We16Tk/edit#heading=h.tns913j8ogdg';

/**
 * Get GoogleMaps link to display location.
 */
export function GoogleMapsLink(
    point: { latitude: number; longitude: number },
    targetLocationForDirections?: { latitude: number; longitude: number },
    travelMode?: 'driving' | 'walking' | 'bicycling' | 'transit',
): string {
    if (targetLocationForDirections) {
        const theTravelMode = travelMode ? travelMode : 'driving';

        return `https://www.google.com/maps/dir/?api=1&origin=${point.latitude},${point.longitude}&destination=${targetLocationForDirections.latitude},${targetLocationForDirections.longitude}&travelmode=${theTravelMode}`;
    }

    return `https://www.google.com/maps/search/?api=1&query=${point.latitude},${point.longitude}`;
}

export enum NavigateUsing {
    Google,
    Waze,
    Apple,
    MapyCz,
}

/**
 * Open navigation in new tab for address.
 */
export function NavigateToAddress(address: Address, using: NavigateUsing) {
    const addressSingleLine = addressToSingleLine(address)!;

    switch (using) {
        case NavigateUsing.Google: {
            if (address.googlePlacesId) {
                window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addressSingleLine)}&query_place_id=${encodeURIComponent(address.googlePlacesId)}`, "_blank");
            } else if (address.provider !== AddressProvider.MapyCz) {
                window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addressSingleLine)}`, "_blank");
            } else {
                window.open(`https://www.google.com/maps/search/?api=1&query=${address.latitude},${address.longitude}`, "_blank");
            }

            break;
        }
        case NavigateUsing.MapyCz: {
            window.open(`https://mapy.cz/zakladni?x=${address.longitude}&y=${address.latitude}&source=coor&id=${address.longitude},${address.latitude}`, "_blank");

            break;
        }
        case NavigateUsing.Waze:
            window.open(`https://www.waze.com/ul?ll=${address.latitude},${address.longitude}&navigate=yes`, "_blank");
            break;
        case NavigateUsing.Apple:
            window.open(`https://maps.apple.com/?ll=${address.latitude},${address.longitude}`, "_blank");
            break;
    }
}

/**
 * Add MapyCZ API if not yet added to head.
 * Updated to use Leafleat instead of old api.
 */
export async function AddMapyCZApi(): Promise<boolean> {
    const existing = document.getElementById('mapycz-api');
    if (existing) {
        for (let i = 0; i < 50; i++) {
            if (window.L) {
                return true;
            }

            await WaitFor(100);
        }
    }

    const head = document.getElementsByTagName('head')[0];

    const style = document.createElement('link');
    style.id = 'mapycz-style';
    style.rel = 'stylesheet';
    style.href = 'https://unpkg.com/leaflet@1.9.4/dist/leaflet.css';
    style.integrity = 'sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY=';
    style.crossOrigin = '';

    head.appendChild(style);

    const script = document.createElement('script');
    script.id = 'mapycz-api';
    script.integrity = 'sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo=';
    script.crossOrigin = '';
    script.src = 'https://unpkg.com/leaflet@1.9.4/dist/leaflet.js';

    head.appendChild(script);

    return new Promise((resolve) => {
        script.onload = () => {
            resolve(true);
        };
    });
}

/**
 * Get list of addresses for terms using MapyCZ API.
 */
export async function AddressesForTermsMapyCZ(terms: string): Promise<IAddressesForTermsMapyCZResult[]> {
    try {
        const config = FlavorByEnvironment();
        const url = `https://api.mapy.cz/v1/suggest?lang=cs&limit=5&type=regional.address&apikey=${config!.mapyCZApiKey}&query=${terms}`;

        const response = await fetch(url);

        const json = await response.json();

        if (json.items) {
            const newOptions: IAddressesForTermsMapyCZResult[] = [];

            for (const item of json.items) {
                const regionalStructure = item.regionalStructure;

                let street = '';
                let streetNumber = '';
                let city = '';
                let zipcode = item.zip as string;
                let state = '';
                let country = '';

                for (const regional of regionalStructure) {
                    if (regional.type === 'regional.address') {
                        streetNumber = regional.name as string;
                    } else if (regional.type === 'regional.street') {
                        street = regional.name as string;
                    } else if (regional.type === 'regional.municipality') {
                        city = regional.name as string;
                    } else if (regional.type === 'regional.region') {
                        //does not seem right for Mapycz
                        // state = regional.name as string;
                    } else if (regional.type === 'regional.country') {
                        country = (regional.isoCode as string).toLowerCase();
                    }
                }

                const streetLine = [street, streetNumber].join(' ').trim();
                const cityLine = [zipcode, city].join(' ').trim();

                const parts: string[] = [];
                if (streetLine) {
                    parts.push(streetLine);
                }
                if (cityLine) {
                    parts.push(cityLine);
                }
                if (state) {
                    parts.push(state);
                }
                if (country) {
                    parts.push(country);
                }

                const address = parts.join(', ');

                if (address) {
                    newOptions.push({
                        description: address,
                    });
                }
            }

            return newOptions;
        }
    } catch (e) {
        console.error(e);
    }

    return [];
}

/**
 * Geocode address text into useAble location coordinate as well as address parts.
 */
export async function GeocodeAddressToCoordinateMapyCZ(address: string): Promise<IGeocodeAddressToCoordinateResult | null> {
    try {
        const config = FlavorByEnvironment();
        const url = `https://api.mapy.cz/v1/suggest?lang=cs&limit=5&type=regional.address&apikey=${config!.mapyCZApiKey}&query=${address}`;

        const response = await fetch(url);

        const json = await response.json();

        if (json.items) {
            for (const item of json.items) {
                const regionalStructure = item.regionalStructure;

                let street = '';
                let streetNumber = '';
                let city = '';
                let zipcode = item.zip as string;
                let state = '';
                let country = '';

                for (const regional of regionalStructure) {
                    if (regional.type === 'regional.address') {
                        streetNumber = regional.name as string;
                    } else if (regional.type === 'regional.street') {
                        street = regional.name as string;
                    } else if (regional.type === 'regional.municipality') {
                        city = regional.name as string;
                    } else if (regional.type === 'regional.region') {
                        //does not seem right for Mapycz
                        // state = regional.name as string;
                    } else if (regional.type === 'regional.country') {
                        country = (regional.isoCode as string).toLowerCase();
                    }
                }

                const streetLine = [street, streetNumber].join(' ').trim();
                const cityLine = [zipcode, city].join(' ').trim();

                const parts: string[] = [];
                if (streetLine) {
                    parts.push(streetLine);
                }
                if (cityLine) {
                    parts.push(cityLine);
                }
                if (state) {
                    parts.push(state);
                }
                if (country) {
                    parts.push(country);
                }

                const address = parts.join(', ');

                if (address) {
                    const position = item.position;
                    const lat = position.lat as number;
                    const lng = position.lon as number;

                    return {
                        coordinate: {
                            lat,
                            lng,
                        },
                        addressFragment: {
                            city,
                            country,
                            state,
                            street: `${street} ${streetNumber}`.trim(),
                            zipcode,
                            provider: AddressProvider.MapyCz,
                            latitude: lat,
                            longitude: lng,
                        },
                    };
                }
            }
        }
    } catch (e) {
        console.error(e);
    }

    return null;
}
