import {iconUseStyles} from "../styles/IconStyles";

export interface ILoginMethodEmailEnvelopeProps {
    className?: string;
}

export default function LoginMethodEmailEnvelopeIcon(props: ILoginMethodEmailEnvelopeProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256"
                 width="28px" height="28px" fillRule="nonzero">
                <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt"
                   strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0"
                   fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none"
                   style={{mixBlendMode: "normal"}}>
                    <g transform="scale(5.33333,5.33333)">
                        <path
                            d="M40,10h-32c-2.209,0 -4,1.791 -4,4v20c0,2.209 1.791,4 4,4h32c2.209,0 4,-1.791 4,-4v-20c0,-2.209 -1.791,-4 -4,-4z"
                            fill="#fcc419"></path>
                        <path
                            d="M44,14.025c0,-0.465 -0.095,-0.904 -0.24,-1.32l-19.76,14.32l-19.759,-14.32c-0.146,0.416 -0.241,0.856 -0.241,1.32v0.975l20,14.495l20,-14.495z"
                            fill="#b58b0d"></path>
                    </g>
                </g>
            </svg>
        </span>
    );
}












