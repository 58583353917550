import {FileResponse} from "../generated/graphql/graphql";

export default interface IFileState {
    type: IFileStateType;
    uuid: string;
    data?: FileResponse;
    companyId?: number | NullOrUndefined;
    category?: string | NullOrUndefined;
    fileAccept?: string;
    progress?: number;
    totalBytes?: number;
    inputFile?: File;
    overrideFileUpdateData?: Blob;// | ArrayBuffer | Uint8Array;
    fileId?: number;
}

export enum IFileStateType {
    None,
    Select,
    Upload,
    File,
}
