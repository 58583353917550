import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8RightArrowProps {
    className?: string;
}

export default function Icons8RightArrow(props: IIcons8RightArrowProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 34.484375 11.984375 A 1.50015 1.50015 0 0 0 33.439453 14.560547 L 40.878906 22 L 3.5 22 A 1.50015 1.50015 0 1 0 3.5 25 L 40.878906 25 L 33.439453 32.439453 A 1.50015 1.50015 0 1 0 35.560547 34.560547 L 45.560547 24.560547 A 1.50015 1.50015 0 0 0 45.560547 22.439453 L 35.560547 12.439453 A 1.50015 1.50015 0 0 0 34.484375 11.984375 z"/>
            </svg>
        </span>
    );
}
