import {Typography} from "@mui/material";
import React from "react";

export interface IEmptyListTextProps {
    text: string;
}

export default function EmptyListText(props: IEmptyListTextProps) {
    const {text} = props;
    return (<Typography
            align={"center"}
            sx={{py: 6}}
        >
            {text}
        </Typography>
    );
}