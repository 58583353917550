import {tt} from "../core/Localization";

/**
 * Calculate the distance between two points.
 * Distance is calculated in meters.
 */
export function distanceBetweenPoints(
  point1: { latitude: number; longitude: number },
  point2: { latitude: number; longitude: number },
): number {
    const R = 6371e3; // metres
    const φ1 = (point1.latitude * Math.PI) / 180; // φ, λ in radians
    const φ2 = (point2.latitude * Math.PI) / 180;
    const Δφ = ((point2.latitude - point1.latitude) * Math.PI) / 180;
    const Δλ = ((point2.longitude - point1.longitude) * Math.PI) / 180;

    const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres

    return d;
}

/**
 * Display distance in meters or kilometers.
 */
export function displayDistance(distance: number, language: string): string {
    const formatter = Intl.NumberFormat(language, {maximumFractionDigits: 2});

    if (distance < 1000) {
        return `${formatter.format(distance)} ${tt('distance.meters.short')}`;
    }

    return `${formatter.format(distance / 1000)} ${tt('distance.kilometers.short')}`;
}
