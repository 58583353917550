import {iconUseStyles} from "../styles/IconStyles";

export interface IIconPlusProps {
    className?: string;
}

export default function IconPlus(props: IIconPlusProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
                <path d="M4 12H20M12 4V20" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"/>
            </svg>
        </span>
    );
}









