import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8GoogleProps {
    className?: string;
}

export default function Icons8Google(props: IIcons8GoogleProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg id="icons8-google" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path id="Path_27" data-name="Path 27" d="M23.806,12.042H23V12H14v4h5.651A6,6,0,1,1,17.981,9.52l2.828-2.829A9.99,9.99,0,1,0,24,14,10.068,10.068,0,0,0,23.806,12.042Z" transform="translate(-4 -4)" fill="#ffc107"/>
                <path id="Path_28" data-name="Path 28" d="M6.306,9.343,9.59,11.752a5.985,5.985,0,0,1,9.538-2.235L21.955,6.69A9.976,9.976,0,0,0,6.306,9.343Z" transform="translate(-5.154 -4)" fill="#ff3d00"/>
                <path id="Path_29" data-name="Path 29" d="M15.094,36.024a9.949,9.949,0,0,0,6.7-2.595L18.7,30.811a5.982,5.982,0,0,1-9.247-2.757L6.2,30.565A9.988,9.988,0,0,0,15.094,36.024Z" transform="translate(-5.098 -16.024)" fill="#4caf50"/>
                <path id="Path_30" data-name="Path 30" d="M33.8,20.041H33V20H24v4h5.649a6.017,6.017,0,0,1-2.043,2.784h0L30.7,29.4A9.669,9.669,0,0,0,34,22,10.064,10.064,0,0,0,33.8,20.041Z" transform="translate(-13.996 -11.995)" fill="#1976d2"/>
            </svg>
        </span>
    );
}
