import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8DoubleTickProps {
    className?: string;
}

export default function Icons8DoubleTick(props: IIcons8DoubleTickProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="24px" height="24px">
                <path
                    d="M 13 42 C 12.472656 42 11.960938 41.789063 11.585938 41.414063 L 0.585938 30.414063 C -0.195313 29.632813 -0.195313 28.367188 0.585938 27.585938 C 1.367188 26.804688 2.632813 26.804688 3.414063 27.585938 L 12.859375 37.03125 L 35.460938 9.726563 C 36.164063 8.871094 37.425781 8.753906 38.273438 9.460938 C 39.125 10.164063 39.246094 11.425781 38.539063 12.273438 L 14.539063 41.273438 C 14.183594 41.710938 13.65625 41.972656 13.09375 41.996094 C 13.0625 42 13.03125 42 13 42 Z M 24.09375 41.996094 C 24.65625 41.972656 25.179688 41.710938 25.539063 41.273438 L 49.539063 12.273438 C 50.246094 11.425781 50.125 10.164063 49.273438 9.460938 C 48.425781 8.753906 47.164063 8.875 46.460938 9.726563 L 23.859375 37.03125 L 23.414063 36.585938 C 22.632813 35.804688 21.367188 35.804688 20.585938 36.585938 C 19.804688 37.367188 19.804688 38.632813 20.585938 39.414063 L 22.585938 41.414063 C 22.960938 41.789063 23.472656 42 24 42 C 24.03125 42 24.0625 42 24.09375 41.996094 Z"/>
            </svg>
        </span>
    );
}
