import React, {Dispatch, SetStateAction, useContext} from "react";
import AppModal from "../modals/AppModal";
import {tt} from "../../../core/Localization";
import WorkersAndProfileForm, {IWorkersFormUpdate} from "./WorkersAndProfileForm";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {
    CreateCompanyEmployeeDocument,
    CreateCompanyEmployeeMutation,
    CreateCompanyEmployeeMutationVariables
} from "../../../generated/graphql/graphql";
import {AppDataContext} from "../../../AppData";
import {SuccessToast} from "../../../service/ToastService";
import {workerDetailRoute} from "../../screens/workers/WorkerDetailScreen";
import {useNavigate} from "react-router-dom";
import {processMutationError} from "../../../service/ErrorService";

export interface ICreateWorkerModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    navigation?: boolean;
    modalAboveModals?: boolean;
    onCreate?: (id: number) => void;
}

export default function CreateWorkerModal(props: ICreateWorkerModalProps) {
    const {open, setOpen, navigation, modalAboveModals, onCreate} = props;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const [mutateCreate, {
        loading,
    }] = useResettableMutation<CreateCompanyEmployeeMutation, CreateCompanyEmployeeMutationVariables>(CreateCompanyEmployeeDocument);

    const navigate = useNavigate();
    const theNavigation = navigation === undefined ? true : navigation;

    /**
     * Mutate Employee to BE and close modal if success.
     */
    const CreateEmployee = async (update: IWorkersFormUpdate) => {
        try {
            const variables: CreateCompanyEmployeeMutationVariables = {
                input: {
                    name: update.inputs.firstName.value,
                    surname: update.inputs.lastName.value,
                    phoneNumber: update.inputs.phone.value,
                    contactEmail: update.inputs.email.value,
                    address: update.addressFragment,
                    role: update.inputs.position.value,
                    companyId: companyId!,
                    contractType: update.inputs.contractType.value || undefined,
                    hourRate: parseFloat(update.inputs.hourlyWage.value),
                    distanceRate: parseFloat(update.inputs.distanceRate.value),
                },
            };

            const result = await mutateCreate({variables});

            if (!result.errors) {
                setOpen(false);

                SuccessToast(tt('createWorker.modal.success'));

                if (theNavigation) {
                    navigate(workerDetailRoute(result.data!.createCompanyEmployee.id));
                }

                if (onCreate) {
                    onCreate(result.data!.createCompanyEmployee.id);
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    return (
        <AppModal setOpen={setOpen}
                  title={tt('common.newWorker')}
                  open={open}
                  fullScreenOnMobile={true}
                  blurBackdrop={true}
                  modalAboveModals={modalAboveModals}
        >

            <WorkersAndProfileForm onSubmit={CreateEmployee} submitRight={true} loading={loading}/>
        </AppModal>
    );
}
