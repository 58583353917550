import { useContext, useState } from "react";
import AppModal from "../AppModal";
import { HideLinkProvidersModal } from "../AppModals";
import { AppDataContext } from "../../../../AppData";
import { Box, Grid, Theme, Typography } from "@mui/material";
import {makeStyles} from "tss-react/mui";
import { kAppColors } from "../../../../styles/AppThemeProcessor";
import { tt } from "../../../../core/Localization";
import { LoginProvider } from "../../../../generated/graphql/graphql";
import FormBuilder, { IInputsData, InputType, ValidateForm } from "../../form/FormBuilder";
import AppButton from "../../buttons/AppButton";
import TextDivider from "../../decorations/TextDivider";
import SocialButton from "../../buttons/SocialButton";
import Icons8Google from "../../../../icons/Icons8Google";
import Icon8Facebook from "../../../../icons/Icons8Facebook";
import Icons8AppleLogo from "../../../../icons/Icons8AppleLogo";
import { trackDataLayerEvent } from "../../../../service/AnalyticsService";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { AuthUserSignInSocial, FirebaseAuthErrorMessage } from "../../../../service/AuthUserService";

export const useStyles = makeStyles()((theme: Theme) => ({
    textStyle: {
        fontSize: 14,
        fontWeight: 500,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        textAlign: 'center',
        whiteSpace: 'break-spaces',
    },
    socialButton: {
        marginRight: 4,
        marginLeft: 4,
        marginBottom: 8,
    },
}));

export interface ILinkProvidersModalProps {
    open: boolean;
    hideProvider?: LoginProvider;
}

/**
 * Modal to allow linking of auth providers when signIn fails.
 */
export default function LinkProvidersModal(props: ILinkProvidersModalProps) {
    const {open, hideProvider} = props;

    const appDataContext = useContext(AppDataContext);
    const {classes, cx} = useStyles();
    const {innerWidth} = window;
    const isMobile = innerWidth <= 768;

    const [signInLoading, setSignInLoading] = useState<boolean>(false);

    const [inputs, setInputs] = useState<IInputsData>({
        username: {
            type: InputType.Text,
            label: `${tt('common.email')}*`,
            value: '',
            required: true,
            requireEmail: true,
            grid: {
                sm: 6,
                xs: 12
            },
        },
        password: {
            type: InputType.Password,
            label: `${tt('common.password')}*`,
            value: '',
            required: true,
            grid: {
                sm: 6,
                xs: 12
            },
        },
    });

    /**
     * SignIn to link providers.
     */
    const LinkProviders = async (provider: LoginProvider) => {
        trackDataLayerEvent('sign_in_link_providers');

        if (provider === LoginProvider.Email) {
            if (ValidateForm(inputs, setInputs)) {
                try {
                    setSignInLoading(true);

                    await signInWithEmailAndPassword(
                        getAuth(),
                        inputs.username.value,
                        inputs.password.value,
                    );
                } catch (e) {
                    setSignInLoading(false);
                    console.error(e);

                    FirebaseAuthErrorMessage(e, false);
                }
            }
        }

        AuthUserSignInSocial(
            appDataContext,
            provider,
            true,
        );
    };

    const formJSX = hideProvider !== LoginProvider.Email ? (
        <>
            <FormBuilder inputs={inputs} setInputs={setInputs} />

            <AppButton
                isLoading={signInLoading}
                onClick={() =>
                    LinkProviders(LoginProvider.Email)}
                fullWidth={true}
                variant="contained"
            >{tt('linkProvidersModal.linkProviders')}</AppButton>
            
            <Box height={8} />

            <TextDivider text={tt('common.dividerText.or')} />

            <Box pb={3.5} />
        </>
    ) : null;

    const googleJSX = hideProvider !== LoginProvider.Google ? (
        <SocialButton
            fullWidth={isMobile}
            onClick={() => LinkProviders(LoginProvider.Google)}
            className={classes.socialButton} text={tt('common.google')}
            icon={<Icons8Google />} />
    ) : null;

    const facebookJSX = hideProvider !== LoginProvider.Facebook ? (
        <SocialButton
            fullWidth={isMobile}
            onClick={() => LinkProviders(LoginProvider.Facebook)}
            className={classes.socialButton} text={tt('common.facebook')}
            icon={<Icon8Facebook />} />
    ) : null;

    const appleJSX = hideProvider !== LoginProvider.Apple ? (
        <SocialButton
            fullWidth={isMobile}
            onClick={() => LinkProviders(LoginProvider.Apple)}
            className={classes.socialButton} text={tt('common.apple')}
            icon={<Icons8AppleLogo />} />
    ) : null;

    return (
        <AppModal
            blurBackdrop={true}
            fullScreenOnMobile={true}
            open={open}
            onClose={() => HideLinkProvidersModal(appDataContext)}
            title={tt('linkProvidersModal.title')}
        >
            <Typography className={classes.textStyle} paragraph={true}>
                {tt('linkProvidersModal.description')}
            </Typography>

            {formJSX}

            <Grid container justifyContent={"center"}>
                {googleJSX}

                {facebookJSX}

                {appleJSX}
            </Grid>
        </AppModal>
    );
}
