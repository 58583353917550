import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8CheckedCheckboxProps {
    className?: string;
}

export default function Icons8CheckedCheckbox(props: IIcons8CheckedCheckboxProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 11.5 6 C 8.4802259 6 6 8.4802259 6 11.5 L 6 36.5 C 6 39.519774 8.4802259 42 11.5 42 L 36.5 42 C 39.519774 42 42 39.519774 42 36.5 L 42 11.5 C 42 8.4802259 39.519774 6 36.5 6 L 11.5 6 z M 11.5 9 L 36.5 9 C 37.898226 9 39 10.101774 39 11.5 L 39 36.5 C 39 37.898226 37.898226 39 36.5 39 L 11.5 39 C 10.101774 39 9 37.898226 9 36.5 L 9 11.5 C 9 10.101774 10.101774 9 11.5 9 z M 34.470703 15.986328 A 1.50015 1.50015 0 0 0 33.439453 16.439453 L 20.5 29.378906 L 15.560547 24.439453 A 1.50015 1.50015 0 1 0 13.439453 26.560547 L 19.439453 32.560547 A 1.50015 1.50015 0 0 0 21.560547 32.560547 L 35.560547 18.560547 A 1.50015 1.50015 0 0 0 34.470703 15.986328 z"/>
            </svg>
        </span>
    );
}




















