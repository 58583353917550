import {makeStyles} from "tss-react/mui";
import {Box, SxProps, Theme} from "@mui/material";
import React from "react";
import {kContentWidthNarrow} from "../../../styles/AppThemeProcessor";

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        width: '100%',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    mobile: {
        flexGrow: 1,
    },
    centerContent: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        paddingBottom: 0,
    },
    bottomCompensationPadding: {
        paddingBottom: 68,
    },
    narrow: {
        maxWidth: (kContentWidthNarrow + 32),
        marginLeft: "auto",
        marginRight: "auto",
    }
}));

export interface IContentPadding {
    children?: Array<React.ReactNode> | React.ReactNode;
    isMobile?: boolean;
    centered?: boolean,
    isNarrow?: boolean,
    sx?: SxProps<Theme>;
}

export default function (props: IContentPadding) {
    const {children, isMobile, centered, sx, isNarrow} = props;

    const {classes, cx} = useStyles();
    const containerClass = cx(classes.container,
        isMobile ? classes.mobile : undefined,
        centered ? classes.centerContent : undefined,
        isMobile && centered ? classes.bottomCompensationPadding : undefined,
        isNarrow ? classes.narrow : undefined,
    );

    return (
        <Box className={containerClass} sx={sx}>{children}</Box>
    );
}