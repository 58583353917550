import {DateTime} from "luxon";
import {useContext, useEffect, useState} from "react";
import {Box, Theme, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import SingleBar from "./SingleBar";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import {hoursToChartLabel, numberShort} from "../../../../utils/Utils";
import {distanceDisplay} from "../../../../service/CompanyService";
import {AppDataContext} from "../../../../AppData";

const useStyles = makeStyles()((theme: Theme) => ({
    containerOuter: {
        maxWidth: '100vw',
        position: "relative",
    },
    scrollView: {
        overflowX: "auto",
        paddingRight: 16,
    },
    container: {
        display: 'flex',
        flexWrap: "nowrap",
        overflowY: "visible",
        paddingLeft: 0,
        paddingBottom: 8,
        paddingTop: 24,
    },
    yAxisLabel: {
        'p': {
            lineHeight: '20px',
            fontWeight: 600,
            whiteSpace: "nowrap",
        }
    },
    dashedLine: {
        height: 1,
        position: "absolute",
        left: 0,
        right: 0,
        background: `linear-gradient(to right, transparent 33%, ${kAppColors.border(theme.palette.mode === "dark")} 0%) bottom/12px 1px repeat-x`,
    },
    yLabelsContainer: {
        paddingLeft: 16,
        paddingRight: 8,
        position: "sticky",
        top: 0,
        left: 0,
        zIndex: 1,
    }
}));

export interface IBarChartProps {
    onChange: Function;
    data: Array<{
        value: number;
        innerValue: number;
        date: DateTime;
        totalHours: number;
        doneHours: number;
        totalDistance: number;
        doneDistance: number;
    }>;
    activeItem: number;
    showHours?: boolean;
    showDistance?: boolean;
}

export default function BarChart(props: IBarChartProps) {
    const {
        activeItem,
        onChange,
        data,
        showHours,
        showDistance,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const {classes, cx} = useStyles();

    const _barMaxHeight = 160;

    const [maxValue, setMaxValue] = useState<number>(0);


    let _barHeightPerOneValuePoint = 0;

    useEffect(() => {
        let _maxValue = 0;

        for (let i = 0; i < data.length; i++) {
            if (showHours) {
                if (_maxValue < data[i].totalHours) {
                    _maxValue = data[i].totalHours;
                }
            } else if (showDistance) {
                if (_maxValue < data[i].totalDistance) {
                    _maxValue = data[i].totalDistance;
                }
            } else {
                if (_maxValue < data[i].value) {
                    _maxValue = data[i].value;
                }
            }
        }
        setMaxValue(_maxValue)
    }, [data, showHours, showDistance]);

    return (
        <Box className={classes.containerOuter}>
            <Box className={cx('styledScrollbar', classes.scrollView)}>
                <Box className={classes.container}>
                    <Box sx={{height: _barMaxHeight + 38}}></Box>
                    <Box className={classes.dashedLine} sx={{top: 0 + 24}}>
                    </Box>
                    <Box className={classes.dashedLine} sx={{top: _barMaxHeight * 0.33 + 24}}>
                    </Box>
                    <Box className={classes.dashedLine} sx={{top: _barMaxHeight * 0.66 + 24}}>
                    </Box>
                    <Box className={classes.yLabelsContainer}>
                        {showHours ? (
                            <>
                                <Box className={classes.yAxisLabel} sx={{marginTop: -22 / 8}}>
                                    <Typography>{hoursToChartLabel(maxValue)}</Typography>
                                </Box>
                                <Box className={classes.yAxisLabel} sx={{marginTop: (_barMaxHeight * 0.33) / 8 - 20 / 8}}>
                                    <Typography>{hoursToChartLabel(maxValue * 0.66)}</Typography>
                                </Box>
                                <Box className={classes.yAxisLabel} sx={{marginTop: (_barMaxHeight * 0.33) / 8 - 20 / 8}}>
                                    <Typography>{hoursToChartLabel(maxValue * 0.33)}</Typography>
                                </Box>
                            </>
                        ) : showDistance ? (
                            <>
                                <Box className={classes.yAxisLabel} sx={{marginTop: -22 / 8}}>
                                    <Typography>{distanceDisplay(maxValue, language, true)}</Typography>
                                </Box>
                                <Box className={classes.yAxisLabel} sx={{marginTop: (_barMaxHeight * 0.33) / 8 - 20 / 8}}>
                                    <Typography>{distanceDisplay(maxValue * 0.66, language, true)}</Typography>
                                </Box>
                                <Box className={classes.yAxisLabel} sx={{marginTop: (_barMaxHeight * 0.33) / 8 - 20 / 8}}>
                                    <Typography>{distanceDisplay(maxValue * 0.33, language, true)}</Typography>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box className={classes.yAxisLabel} sx={{marginTop: -22 / 8}}>
                                    <Typography>{numberShort(maxValue)}</Typography>
                                </Box>
                                <Box className={classes.yAxisLabel} sx={{marginTop: (_barMaxHeight * 0.33) / 8 - 20 / 8}}>
                                    <Typography>{numberShort(maxValue * 0.66)}</Typography>
                                </Box>
                                <Box className={classes.yAxisLabel} sx={{marginTop: (_barMaxHeight * 0.33) / 8 - 20 / 8}}>
                                    <Typography>{numberShort(maxValue * 0.33)}</Typography>
                                </Box>
                            </>
                        )}
                    </Box>
                    {
                        data.length == 0 ?
                            <SingleBar
                                key={0}
                                onClick={() => {
                                }}
                                height={4}
                                maxHeight={4}
                                totalMaxHeight={_barMaxHeight}
                                isSelected={false}
                                labelLine1={DateTime.now().monthShort ?? ''}
                                labelLine2={DateTime.now().year.toString()}
                            />
                            :
                            data.map((item, index) => {
                                let heightValue = item.innerValue;
                                let maxHeightValue = item.value;
                                if (showHours) {
                                    heightValue = item.doneHours;
                                    maxHeightValue = item.totalHours;
                                } else if (showDistance) {
                                    heightValue = item.doneDistance;
                                    maxHeightValue = item.totalDistance;
                                }

                                return (
                                    <SingleBar
                                        key={index}
                                        onClick={() => {
                                            onChange(index);
                                        }}
                                        height={maxValue > 0 ? (_barMaxHeight / maxValue) * heightValue : 0}
                                        maxHeight={maxValue > 0 ? (_barMaxHeight / maxValue) * maxHeightValue : 0}
                                        totalMaxHeight={_barMaxHeight}
                                        isSelected={index === activeItem}
                                        labelLine1={item.date.monthShort ?? ''}
                                        labelLine2={item.date.year.toString()}
                                    />
                                );
                            })}
                </Box>
            </Box>
        </Box>
    );
}
