import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8AddressProps {
    className?: string;
}

export default function Icons8Change(props: IIcons8AddressProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 33.556641 4.0019531 A 1.50015 1.50015 0 0 0 32.384766 4.4960938 L 23.832031 14 L 14.5 14 C 11.480226 14 9 16.480226 9 19.5 L 9 31 L 5.5 31 A 1.50015 1.50015 0 0 0 4.3847656 33.503906 L 13.384766 43.503906 A 1.50015 1.50015 0 0 0 15.615234 43.503906 L 24.167969 34 L 33.5 34 C 36.519774 34 39 31.519774 39 28.5 L 39 17 L 42.5 17 A 1.50015 1.50015 0 0 0 43.615234 14.496094 L 34.615234 4.4960938 A 1.50015 1.50015 0 0 0 33.556641 4.0019531 z M 33.5 7.7421875 L 39.130859 14 L 37.5 14 A 1.50015 1.50015 0 0 0 36 15.5 L 36 28.5 C 36 29.898226 34.898226 31 33.5 31 L 23.503906 31 L 23.5 31 L 20 31 L 20 25.5 L 29.5 25.5 A 1.50015 1.50015 0 0 0 31 24 L 31 15.746094 A 1.50015 1.50015 0 0 0 29.5 14 L 27.869141 14 L 33.5 7.7421875 z M 14.5 17 L 24.496094 17 L 28 17 L 28 22.5 L 18.5 22.5 A 1.50015 1.50015 0 0 0 17 24 L 17 32.5 A 1.50015 1.50015 0 0 0 18.5 34 L 20.130859 34 L 14.5 40.257812 L 8.8691406 34 L 10.5 34 A 1.50015 1.50015 0 0 0 12 32.5 L 12 19.5 C 12 18.101774 13.101774 17 14.5 17 z"/>
            </svg>
        </span>
    );
}
