import {makeStyles} from "tss-react/mui";
import {Box, TextField, Theme, Typography} from "@mui/material";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import React, {useContext, useEffect} from "react";
import AppPaper from "../../components/paper/AppPaper";
import {tt} from "../../../core/Localization";
import {AppContext} from "../../../App";
import PaperAppbar from "../../components/paper/PaperAppbar";
import LanguagesMenu from "../../components/menus/LanguagesMenu";
import DarkModeMenu from "../../components/menus/DarkModeMenu";
import LogoWithText from "../../components/LogoWithText";
import SocialButton from "../../components/buttons/SocialButton";
import Icons8AppleLogo from "../../../icons/Icons8AppleLogo";
import Icons8GooglePlay from "../../../icons/Icons8GooglePlay";
import AppIconButton from "../../components/buttons/AppIconButton";
import {SuccessToast} from "../../../service/ToastService";
import Icons8Copy from "../../../icons/Icons8Copy";
import {FlavorByEnvironment} from "../../../flavor-config";
import Icons8Internet from "../../../icons/Icons8Internet";
import {useParams} from "react-router-dom";
import {EmployeeRole} from "../../../generated/graphql/graphql";

export const kInvitationInstructionsRoute = '/invitation-instructions/:role/:code';

/**
 * Create navigation route for this screen.
 */
export function invitationInstructionsRoute(role: number, code: string): string {
    return kInvitationInstructionsRoute
        .replace(':role', `${role}`)
        .replace(':code', `${code}`);
}

export const useStyles = makeStyles()((theme: Theme) => ({
    outerContainer: {
        width: '100%',
        maxWidth: kContentWidthMedium,
        margin: "auto",
        "@media (max-width: 767px)": {
            marginTop: 0,
        },
    },
    paper: {
        width: '100%',
        maxWidth: kContentWidthMedium,
        marginTop: 16,
        marginBottom: 16,
        paddingTop: 8,
        "@media (max-width: 767px)": {
            paddingTop: 0,
            marginTop: 0,
            marginBottom: 0,
        },
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
        textAlign: "center",
    },
    text: {
        fontWeight: 500,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        textAlign: "center",
    },
    verticalLine: {
        width: 1,
        background: kAppColors.border(theme.palette.mode === "dark"),
        height: 80,
        margin: "auto",
        marginTop: 16,
        marginBottom: 16,
        "@media (max-width: 767px)": {
            height: 40,
        },
    },
    verticalLineSmallerMarginTop: {
        marginTop: 8,
    },
    contentContainer: {
        width: '100%',
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    check: {
        fontSize: 20,
    },
    textField: {
        maxWidth: 460,
        margin: "auto",
        marginBottom: '0 !important',
    },
    socialButtons: {
        display: "flex",
        justifyContent: "center",
        flexFlow: "wrap",
        'button': {
            flexShrink: 0,
            marginLeft: 4,
            marginRight: 4,
            marginBottom: 8
        }
    },
}));

export default function InvitationInstructionsScreen() {
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    useEffect(() => {
        setTitle(tt('invitation.screen.title'));
    }, []);

    return (
        <ResponsiveContainer
            smallPhoneScreen={<Body isMobile={true}/>}
            largePhoneScreen={<Body isMobile={true}/>}
            tabletScreen={<Body/>}
            smallDesktopScreen={<Body/>}
            largeDesktopScreen={<Body/>}
            extraLargeDesktopScreen={<Body/>}
        />);
}


interface IBodyProps {
    isMobile?: boolean;
}

function Body(props: IBodyProps) {
    const {isMobile} = props;

    const {classes, cx} = useStyles();

    const {role, code} = useParams();

    const isEmployee = role != undefined ? EmployeeRole.Worker === Object.values(EmployeeRole)[parseInt(role)] : false;

    const step1JSX = isEmployee ? <>
        <Typography className={classes.title}>{tt('invitation.screen.step1.downloadApp')}</Typography>
        <Box pb={2}/>
        <Box className={classes.socialButtons}>
            <SocialButton
                onClick={() => {
                    window.open(FlavorByEnvironment()!.appstoreLink, '_blank', 'noreferrer');
                }
                }
                text={tt('downloadApp.modal.appstore')}
                icon={<Icons8AppleLogo/>}/>
            <SocialButton
                onClick={() => {
                    window.open(FlavorByEnvironment()!.googlePlayLink, '_blank', 'noreferrer');
                }
                }
                text={tt('downloadApp.modal.googlePlay')}
                icon={<Icons8GooglePlay/>}/>
        </Box>
    </> : <>
        <Typography className={classes.title}>{tt('invitation.screen.step1.visitWebsite')}</Typography>
        <Box pb={2}/>
        <Box className={classes.socialButtons}>
            <SocialButton
                onClick={() => {
                    window.open(FlavorByEnvironment()!.webUrl, '_blank', 'noreferrer');
                }
                }
                text={FlavorByEnvironment()!.webUrl}
                icon={<Icons8Internet/>}/>
        </Box>
    </>;

    return (
        <Box className={classes.outerContainer}>
            <AppPaper
                className={classes.paper}
                centerContent={true}
            >
                <PaperAppbar
                    title={tt('invitation.screen.title')}
                    hideBackButton={true}
                    children={<><LanguagesMenu/><DarkModeMenu/></>}
                />
                <LogoWithText description={tt('invitation.screen.appDescription')}/>
                <Box pb={isMobile ? 1 : 4}/>
                <Box className={classes.contentContainer}>

                    {step1JSX}

                    <Box className={cx(classes.verticalLine, classes.verticalLineSmallerMarginTop)}/>
                    <Typography className={classes.title}>{tt('invitation.screen.step2.signUp')}</Typography>
                    <Typography className={classes.text}>{tt('invitation.screen.step2.description')}</Typography>

                    <Box className={classes.verticalLine}/>
                    <Typography className={classes.title}>{tt('invitation.screen.step3.copyCode')}</Typography>
                    <Box pb={2}/>
                    <TextField
                        className={classes.textField}
                        label={tt('invitation.input.label.inviteCode')}
                        fullWidth={true}
                        variant={"filled"}
                        value={code}
                        InputProps={{
                            endAdornment: <AppIconButton
                                tooltip={tt('common.copy')}
                                onClick={() => {
                                    navigator.clipboard.writeText(code || '');
                                    SuccessToast(tt('common.copiedToClipboard'));
                                }}
                            ><Icons8Copy/></AppIconButton>,
                        }}
                    >
                    </TextField>

                    <Box className={classes.verticalLine}/>
                    <Typography className={classes.title}>{tt('invitation.screen.step4.done')}
                        <span className={classes.check}>&nbsp;✅</span>
                    </Typography>
                    <Box pb={2}/>
                </Box>
            </AppPaper>
        </Box>
    );
}
