import {Box, Menu, MenuItem} from "@mui/material";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import {usePopupState} from "material-ui-popup-state/hooks";
import {tt} from "../../../../../core/Localization";
import {ClientContactResponse} from "../../../../../generated/graphql/graphql";
import MoreFilledIcon from "../../../../../icons/MoreFilledIcon";
import UserIcon from "../../../../../icons/UserIcon";
import {UserFullName} from "../../../../../service/UserService";
import {kAppColors} from "../../../../../styles/AppThemeProcessor";
import {linkLikeButtonStyle} from "../../../../../styles/UtilStyles";
import AppListItem from "../../../listItems/AppListItem";
import AppIconButton from "../../../buttons/AppIconButton";
import PhoneMenu from "../../../menus/PhoneMenu";
import React, {useContext, useId} from "react";
import CloseIcon from "../../../../../icons/CloseIcon";
import PermissionValid, {hasSomePermissions} from "../../../permissions/PermissionValid";
import {
    kActionDelete,
    kActionUpdate,
    kActionView,
    kPermissionsClients,
    kPermissionsVisitClientDetailsContacts
} from "../../../../../core/constants";
import {AppDataContext} from "../../../../../AppData";

export interface IVisitDetailClientContactProps {
    canEdit: boolean;
    isRepeating: boolean;
    contact: ClientContactResponse;
    existingContactIds: number[];
    setRecurringConfirmActionCallback: (recurringConfirmActionCallback: VoidFunction) => void;
    setRecurringEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    updateClientContacts: (clientContactIds: number[]) => Promise<void> | void;
}

/**
 * Component for Client Contact on Visit Detail.
 */
export default function VisitDetailClientContact(props: IVisitDetailClientContactProps) {
    const {
        canEdit,
        isRepeating,
        contact,
        existingContactIds,
        setRecurringConfirmActionCallback,
        setRecurringEditModal,
        updateClientContacts
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {employeePermissionsMap} = appDataContext;

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    /**
     * Remove this contact and call update contacts to BE.
     */
    const removeContact = () => {
        const newContactIds = existingContactIds.filter(id => id !== contact.id);

        updateClientContacts(newContactIds);
    };

    return (
        <AppListItem
            customAvatar={<UserIcon/>}
            title={UserFullName(contact.name, contact.surname)}
            description={contact.role}
            variant={"smaller-title"}
            actionWidget={
                <>
                    {
                        contact.phoneNumber && hasSomePermissions([
                            {permission: kPermissionsClients, requiredPermissions: [kActionView]},
                            {permission: kPermissionsVisitClientDetailsContacts, requiredPermissions: [kActionView]},
                        ], employeePermissionsMap) ? <PhoneMenu
                            phone={contact.phoneNumber}
                            showMarginRight={true}/> : null
                    }

                    {canEdit ? (
                        <PermissionValid
                            permission={kPermissionsClients}
                            requiredPermissions={[kActionUpdate]}
                        >
                            <AppIconButton
                                variant={"greyBg"}
                                placement={"right"}
                                tooltip={tt('common.more')}
                                {...bindTrigger(settingsPopupState)}
                            >
                                <MoreFilledIcon/>
                            </AppIconButton>

                            <Menu {...bindMenu(settingsPopupState)}>
                                <MenuItem key={'contactDetailDeleteKey' + contact.id} onClick={() => {
                                    settingsPopupState.close();

                                    setRecurringConfirmActionCallback(removeContact);

                                    if (isRepeating) {
                                        setRecurringEditModal(true);
                                    } else {
                                        removeContact();
                                    }
                                }}>
                                    <Box sx={{color: kAppColors.red.confirmButton}}><CloseIcon/></Box>
                                    {tt('common.removeFromList')}
                                </MenuItem>
                            </Menu>
                        </PermissionValid>
                    ) : undefined}
                </>}
        />
    );
}
