import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material";

export const useStyles = makeStyles()((theme: Theme) => ({
    marker: {
        display: 'block',
        width: 36,
        height: 36,
        position: "relative",
        'svg': {
            position: "absolute",
            left: 0,
            top: -18,
            transform: `translate(-50%, -50%)`,
        },
    },
}));

export interface ILocationAddressMarkerProps {
    lat: number;
    lng: number;
}

/**
 * Component which is rendered on Google Maps for Location address.
 */
export default function LocationAddressMarker(props: ILocationAddressMarkerProps) {
    const {classes} = useStyles();

    return (
        <div className={classes.marker}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="36px" height="36px">
                <path fill="#ff3d00"
                      d="M24,1C15.2,1,6.015,7.988,6,18C5.982,29.981,24,48,24,48s18.019-17.994,18-30 C41.984,8.003,32.8,1,24,1z M24,26c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S28.418,26,24,26z"/>
            </svg>
        </div>
    );
}
