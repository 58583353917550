import {makeStyles} from "tss-react/mui";
import {Box, Checkbox, ListItem, ListItemText, Theme} from "@mui/material";
import React from "react";
import {kAppColors} from "../../../../../styles/AppThemeProcessor";
import {DateTime} from "luxon";
import LabelWithAvatar from "../../../jobForms/LabelWithAvatar";

const useStyles = makeStyles()((theme: Theme) => ({
    listItem: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 0,
        paddingTop: 0,
        marginBottom: 0,
        minHeight: 48,
        ':hover': {
            background: kAppColors.hoverColor.grey(theme.palette.mode === "dark"),
            cursor: "pointer",
        },
        '.MuiTypography-body1': {
            fontSize: 14 + 'px !important',
        },
        '.MuiTypography-body2': {
            fontSize: 14,
            lineHeight: 1.28,
            fontWeight: 500,
            textOverflow: 'ellipsis',
        },
        '.MuiListItemText-root': {
            flex: "unset",
            flexShrink: 1,
        }
    },
    checkbox: {
        paddingLeft: 0,
        '&.Mui-checked.Mui-disabled': {
            color: kAppColors.primary.main,
        },
    },
    noHover: {
        '&:hover': {
            background: "transparent",
            cursor: "default",
        }
    },
}));

export interface ICheckBoxItemWithAvatarProps {
    avatarName?: string;
    img?: string | NullOrUndefined;
    title: string;
    date?: DateTime;
    isDone?: boolean;
    isRequired?: boolean;
    onTap?: Function | null;
}

export default function CheckBoxItemWithAvatar(props: ICheckBoxItemWithAvatarProps) {
    const {
        title,
        avatarName,
        img,
        isDone,
        onTap,
        date,
        isRequired,
    } = props;

    const {classes, cx} = useStyles();

    return (
        <ListItem
            className={cx(classes.listItem, onTap ? null : classes.noHover)}
            onClick={() => {
                if (onTap != null) {
                    onTap(!isDone);
                }
            }
            }
        >
            <Checkbox
                className={classes.checkbox}
                checked={isDone}
                disabled={true}
            />

            <LabelWithAvatar
                label={title}
                workerName={avatarName}
                img={img}
                date={date}
                isDone={isDone}
                isRequired={isRequired}
            />
        </ListItem>
    );
}
