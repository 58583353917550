import React, {Dispatch, SetStateAction} from "react";
import {useNavigate} from "react-router-dom";
import {IInputsData, ValidateForm} from "../form/FormBuilder";
import AppModal from "../modals/AppModal";
import {tt} from "../../../core/Localization";
import ContactPersonForm from "./ContactPersonForm";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {
    CreateClientContactDocument,
    CreateClientContactMutation,
    CreateClientContactMutationVariables
} from "../../../generated/graphql/graphql";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import {contactPersonDetailRoute} from "../../screens/contactPerson/ContactPersonDetailScreen";


export interface ICreateContactPersonModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    clientId: number;
    navigation?: boolean;
    modalAboveModals?: boolean;
    onCreate?: (id: number) => void;
}

export default function CreateContactPersonModal(props: ICreateContactPersonModalProps) {
    const {
        open,
        setOpen,
        clientId,
        navigation,
        modalAboveModals,
        onCreate,
    } = props;

    const navigate = useNavigate();
    const theNavigation = navigation === undefined ? true : navigation;

    const [mutateCreate, {
        loading,
        error,
        data,
    }] = useResettableMutation<CreateClientContactMutation, CreateClientContactMutationVariables>(CreateClientContactDocument);

    const createContactPerson = async (inputs: IInputsData, setInputs: Dispatch<SetStateAction<IInputsData>>) => {
        if (ValidateForm(inputs, setInputs)) {
            try {
                const variables: CreateClientContactMutationVariables = {
                    input: {
                        clientId,
                        name: inputs.name.value,
                        surname: inputs.surname.value,
                        phoneNumber: inputs.phone.value,
                        role: inputs.role.value,
                    },
                };

                const result = await mutateCreate({variables});

                if (!result.errors) {
                    setOpen(false);

                    if (theNavigation) {
                        navigate(contactPersonDetailRoute(clientId, result.data!.createClientContact.id));
                    }

                    SuccessToast(tt('createClientContact.modal.success'));

                    if (onCreate) {
                        onCreate(result.data!.createClientContact.id);
                    }
                }
            } catch (e) {
                console.error(e);

                ErrorToast(tt('common.mutation.error'));
            }
        }
    };

    return (
        <AppModal
            open={open}
            setOpen={setOpen}
            title={tt('common.newContactPerson')}
            fullScreenOnMobile={true}
            blurBackdrop={true}
            modalAboveModals={modalAboveModals}>
            <ContactPersonForm onSubmit={createContactPerson} loading={loading}/>
        </AppModal>
    );
}
