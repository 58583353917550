import {Box, Theme, ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import React, {ReactNode} from "react";
import {FormToggleButtonsProps} from "./InputProps";
import {useStyles as formUseStyles} from "./FormBuilder";
import {makeStyles} from "tss-react/mui";
import GreyLabel from "../decorations/GreyLabel";
import {tt} from "../../../core/Localization";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import Icons8Done from "../../../icons/Icons8Done";


const useStyles = makeStyles()((theme: Theme) => ({
    topLabelPadding: {
        paddingTop: 16,
        paddingBottom: 16,
    },
    container: {
        marginBottom: 20,
        '.MuiButtonBase-root': {
            height: 44,
            padding: 0,
        },
        '.MuiToggleButtonGroup-root': {
            height: "auto",
        },
    },
    firstWeek: {
        paddingTop: 0,
    },
    container4Weeks: {
        marginTop: -16,
    },
    calendar: {
        '.MuiToggleButtonGroup-root': {
            padding: 2,
            height: "auto",
            flexWrap: "wrap",
        },
        '.MuiButtonBase-root': {
            width: 'calc(14.28% - 4px)',
            height: 44,
            margin: '2px !important',
        },
        '.MuiButtonBase-root.isLast': {
            width: 'auto',
            paddingLeft: 8,
            paddingRight: 8,
        }
    },
    notFullWidth: {
        minWidth: 45,
    },
    chipsVariant: {
        '.MuiToggleButtonGroup-root': {
            padding: 0,
            background: "transparent",
            flexWrap: "wrap",
        },
        '.MuiButtonBase-root': {
            transition: 'all 0.3s linear',
            marginBottom: '8px !important',
            borderRadius: '100px !important',
            paddingLeft: 16,
            paddingRight: 16,
            background: "transparent",
            color: kAppColors.text.secondary(theme.palette.mode === "dark"),
            border: `2px solid ${kAppColors.border(theme.palette.mode === "dark")} !important`,
            '&.Mui-selected': {
                background: "transparent",
                border: `2px solid ${kAppColors.primary.main} !important`,
                color: kAppColors.primary.main,
            },
        },
    },
    wrapIfIconsOnly: {
        '.MuiToggleButtonGroup-root': {
            flexWrap: "wrap",
        },
    },
    oneLineButtons: {
        '.MuiToggleButtonGroup-root': {
            flexWrap: "nowrap",
        },
    },
}));

export default function FormToggleButtons(props: FormToggleButtonsProps) {
    const {
        className,
        hidden,
        index,
        label,
        value,
        error,
        errorMessage,
        updateValue,
        topLabel,
        options,
        toggleButtonsVariant,
        singleValue,
        fullWidth,
        disabled,
    } = props;

    const {classes: formClasses} = formUseStyles();
    const {classes, cx} = useStyles();

    const OnChange = (e: React.MouseEvent<HTMLElement>, val: string) => {
        let updatedValue;

        if (singleValue) {
            // always have value selected
            if (typeof value !== 'undefined') {
                updatedValue = [val];
            }
        } else {
            if (value.indexOf(val) !== -1) {
                value.splice(value.indexOf(val), 1);
                updatedValue = value;
            } else {
                updatedValue = [...value, val];
            }
        }

        updateValue(index, updatedValue);
    };

    if (toggleButtonsVariant === '4weeks') {
        let buttons: ReactNode[] = [];
        const rows: ReactNode[] = [];
        let currentRow = 1;

        for (const optionOf of options) {
            const row = parseInt(optionOf.value.split('-')[0]);

            if (currentRow !== row) {
                rows.push(
                    <GreyLabel
                        key={`${currentRow}-label`}
                        className={classes.topLabelPadding}
                        text={tt('form.toggleButtons.weeks').replace('{week}', currentRow.toString())}
                    />
                );

                rows.push(
                    <ToggleButtonGroup
                        key={currentRow}
                        fullWidth={true}
                        value={value}
                        exclusive
                        onChange={OnChange}
                        aria-label={label}
                    >
                        {buttons}
                    </ToggleButtonGroup>
                );

                buttons = [];
                currentRow = row;
            }

            buttons.push(
                <ToggleButton
                    key={optionOf.value}
                    value={optionOf.value}
                    aria-label={optionOf.label}>
                    {optionOf.label}
                </ToggleButton>
            );
        }

        if (buttons.length > 0) {
            rows.push(
                <GreyLabel
                    key={`${currentRow}-label`}
                    className={classes.topLabelPadding}
                    text={tt('form.toggleButtons.weeks').replace('{week}', currentRow.toString())}
                />
            );

            rows.push(
                <ToggleButtonGroup
                    key={currentRow}
                    fullWidth={true}
                    value={value}
                    exclusive
                    onChange={OnChange}
                    aria-label={label}
                >
                    {buttons}
                </ToggleButtonGroup>
            );
        }

        return (
            <div className={
                cx(className,
                    hidden ? formClasses.hidden : undefined,
                    classes.container,
                )}>
                {topLabel ? <GreyLabel className={classes.topLabelPadding} text={topLabel}/> : null}

                <Box className={cx(classes.container4Weeks)}>
                    {rows}
                </Box>
            </div>
        );
    }

    let iconsOnly;

    const buttons = options.map((item, index) => {
            if (item.showIconWithTooltip) {
                iconsOnly = true;

                return (
                    <Tooltip
                        key={item.value}
                        title={item.label}>
                        <ToggleButton
                            className={cx(index === options.length - 1 ? 'isLast' : null, fullWidth === false ? classes.notFullWidth : null)}
                            selected={value[0] === item.value || (value != null && true && Array.isArray(value) && value.includes(item.value))}
                            key={item.value}
                            value={item.value}
                            aria-label={item.label}>
                            <>{item.icon}</>
                        </ToggleButton>
                    </Tooltip>
                );
            }

            return (
                <ToggleButton
                    className={cx(index === options.length - 1 ? 'isLast' : null)}
                    key={item.value}
                    value={item.value}
                    aria-label={item.label}>
                    {toggleButtonsVariant === 'chips' && (value[0] === item.value || (value != null && true && Array.isArray(value) && value.includes(item.value))) ?
                        <Box pr={1}><Icons8Done/></Box> : null}
                    {item.label}
                </ToggleButton>
            );
        }
    );

    return (
        <div className={
            cx(className,
                hidden ? formClasses.hidden : undefined,
                classes.container,
                toggleButtonsVariant === 'calendar' ? classes.calendar : null,
                toggleButtonsVariant === 'chips' ? classes.chipsVariant : null,
                iconsOnly ? classes.wrapIfIconsOnly : null,
                toggleButtonsVariant === "oneLineButtons" ? classes.oneLineButtons : null,
            )}>
            {topLabel ? <GreyLabel className={classes.topLabelPadding} text={topLabel}/> : null}

            <ToggleButtonGroup
                disabled={disabled}
                fullWidth={fullWidth != undefined ? fullWidth : true}
                value={value}
                exclusive
                onChange={OnChange}
                aria-label={label}
            >
                {buttons}
            </ToggleButtonGroup>
        </div>
    );
}
