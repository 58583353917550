import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState} from "react";
import {tt} from "../../../../core/Localization";
import BottomSheetModalAppbar from "../BottomSheetModalAppbar";
import ModalBottomSheet from "../../ModalBottomSheet";
import FormBuilder, {IInputsData, InputType} from "../../form/FormBuilder";
import {Box, Divider, Theme} from "@mui/material";
import AppButton from "../../buttons/AppButton";
import HeadlineWithButton from "../../../screenSections/detailListPreviewSection/HeadlineWithButton";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import Icons8Gallery from "../../../../icons/Icons8Gallery";
import {
    AddJobProtocolToVisitDocument,
    AddJobProtocolToVisitMutation,
    AddJobProtocolToVisitMutationVariables,
    FileResponsePage,
    GetFilesByIdsInput} from "../../../../generated/graphql/graphql";
import {useMutation} from "@apollo/client";
import {processMutationError, processQueryError} from "../../../../service/ErrorService";
import {SuccessToast} from "../../../../service/ToastService";
import IFileState, {IFileStateType} from "../../../../model/FileState";
import {genericStyles} from "../../../../styles/UtilStyles";
import {v4 as uuidv4} from "uuid";
import {
    checkAndRequestCameraPermission,
    isImageFile,
    kStorageCategoryJobProtocols
} from "../../../../service/StorageService";
import {AppDataContext} from "../../../../AppData";
import UploadProtocolListItem from "../../jobs/protocols/UploadProtocolListItem";
import {uniqueArray} from "../../../../utils/Utils";
import {IOnUpdateVisitIdParams} from "../job/visits/VisitDetailModal";
import AppIconButton from "../../buttons/AppIconButton";
import DeleteIcon from "../../../../icons/DeleteIcon";
import ProtocolOtherFileListItem from "../../jobs/protocols/ProtocolOtherFileListItem";
import Icons8Camera from "../../../../icons/Icons8Camera";
import Icons8Folder from "../../../../icons/Icons8Folder";
import { RestApiClientContext } from "../../../../core/RestApiProvider";
import { kTopicStorage } from "../../../../core/constants";

export const useStyles = makeStyles()((theme: Theme) => ({
    redTextButton: {
        color: kAppColors.red.main + ' !important',
    },
    galleryContainerOuter: {
        paddingTop: 8,
        marginLeft: 16,
        marginRight: 16,
    },
    galleryContainer: {
        display: "flex",
        marginLeft: -8,
        marginRight: -8,
        flexFlow: "wrap",
    },
    singleItem: {
        paddingRight: 8,
        paddingLeft: 8,
        marginBottom: 16,
        "@media (max-width: 767px)": {
            display: "flex",
            width: '25%',
        }
    }
}));

export interface ICreateProtocolBottomSheetProps {
    visitId: number;
    repeatingDay?: number;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    captureOnOpen?: "user" | "environment";
    onUpdateVisitId: (params: IOnUpdateVisitIdParams) => void;
}

export default function CreateProtocolBottomSheet(props: ICreateProtocolBottomSheetProps) {
    const {
        repeatingDay,
        setOpen,
        open,
        visitId,
        captureOnOpen,
        onUpdateVisitId,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const {classes: genericClasses} = genericStyles();
    const {classes, cx} = useStyles();

    const appDataContext = useContext(AppDataContext);
    const {companyId, storage, setStorage} = appDataContext;

    const imageInputRef = useRef<HTMLInputElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [canUseCamera, setCanUseCamera] = useState<boolean>(false);
    const [capture, setCapture] = useState<"user" | "environment">();
    const [fileStates, setFileStates] = useState<IFileState[]>([]);
    const [selectedProtocols, setSelectedProtocols] = useState<string[]>([]);
    const [fileIds, setFileIds] = useState<number[]>([]);

    const [filesLoading, setFilesLoading] = useState<boolean>(false);
    const [filesData, setFilesData] = useState<FileResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (open && fileIds.length > 0) {
            const subscription = subscribe(
                kTopicStorage,
                {
                    uri: '/storage/search',
                    params: {
                        fileIds,
                    } as GetFilesByIdsInput,
                    setLoading: setFilesLoading,
                    onData: setFilesData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                () => true,
            );

            return () => subscription.cancel();
        } else {
            setFilesData(null);
        }
    }, [fileIds, open]);

    const [mutateAddJobProtocolToJob, {
        loading: addJobProtocolToJobLoading,
    }] = useMutation<AddJobProtocolToVisitMutation, AddJobProtocolToVisitMutationVariables>(AddJobProtocolToVisitDocument);

    useEffect(() => {
        const newFileIds: number[] = [];

        for (const protocol of fileStates) {
            if (protocol.data?.id) {
                newFileIds.push(protocol.data.id);
            }
        }

        setFileIds(uniqueArray(newFileIds));
    }, [fileStates]);

    useEffect(() => {
        setStorage((prev) => {
            return {
                filesToProcess: [
                    ...prev.filesToProcess,
                    ...(filesData?.content || []),
                ],
            };
        });
    }, [filesData]);

    const [defectSwitchInput, setDefectsSwitchInput] = useState<IInputsData>({
        defect: {
            testId: 'createDefectIsDefectSwitch',
            value: false,
            label: tt('common.defect'),
            type: InputType.Switch,
            switchVariant: "Android12Switch"
        },
    });

    const [noteInput, setNoteInput] = useState<IInputsData>({});

    useEffect(() => {
        if (open) {
            setFileStates([]);

            setDefectsSwitchInput((prev: IInputsData) => {
                return {
                    ...prev,
                    defect: {
                        ...prev.defect,
                        value: false,
                    },
                };
            });

            setNoteInput((prev) => {
                    return {
                        ...prev,
                        note: {
                            testId: 'createDefectIsDefectNote',
                            value: '',
                            label: tt('common.note'),
                            type: InputType.ButtonTextField,
                            toggleButtonText: tt('common.addNote'),
                            minRows: 4,
                        },
                    }
                }
            );
        }
    }, [open]);

    const checkCameraPermission = () => {
        checkAndRequestCameraPermission((canUseCamera) => {
            if (canUseCamera) {
                setCanUseCamera(true);
                selectFile('camera');
            }
        })
    }

    /**
     * Select file from camera or gallery and start the process.
     */
    const selectFile = (type: 'camera' | 'gallery' | 'any') => {
        setCapture(type === 'camera' ? 'environment' : undefined);

        setTimeout(() => {
            chooseFile(type === 'any');
        }, 100);
    };

    /**
     * Choose file button clicked.
     */
    const chooseFile = (any?: boolean) => {
        if (any) {
            fileInputRef.current?.click();
        } else {
            imageInputRef.current?.click();
        }
    };

    /**
     * On file/s chosen upload, send to BE and on success change state to file.
     */
    const fileChosen = (e: React.ChangeEvent<HTMLInputElement>, image: boolean) => {
        const htmlInput = e.target;

        if (htmlInput.files && htmlInput.files.length > 0) {
            const newFileStates = [...fileStates];

            for (let i = 0; i < htmlInput.files.length; i++) {
                newFileStates.push({
                    type: IFileStateType.Select,
                    uuid: uuidv4(),
                    companyId: companyId,
                    category: kStorageCategoryJobProtocols,
                    fileAccept: image ? "image/*" : undefined,
                    inputFile: htmlInput.files[i],
                });
            }

            setFileStates(newFileStates);
        }
    };

    /**
     * Mutate add job protocol to job to BE.
     */
    const mutateAddJobProtocolToJobToBE = async () => {
        try {
            const variables: AddJobProtocolToVisitMutationVariables = {
                input: {
                    visitId: visitId,
                    repeatingDay,
                    fileIds: fileStates
                        .filter(protocol => protocol.data?.id)
                        .map(protocol => protocol.data!.id!),
                    isDefect: defectSwitchInput.defect.value,
                    description: noteInput.note.value,
                },
            };

            const result = await mutateAddJobProtocolToJob({variables});

            if (!result.errors) {
                setOpen(false);

                SuccessToast(tt('jobDetailContent.addJobProtocolToJob.success'));

                if (result.data!.addJobProtocolToVisit.id) {
                    onUpdateVisitId({
                        visitId: result.data!.addJobProtocolToVisit.id,
                        repeatingDay: repeatingDay!,
                    });
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    /**
     * Delete selectedProtocols from fileStates.
     */
    const deleteSelectedProtocols = () => {
        setFileStates(prev => {
            return prev.filter((item) => {
                return !selectedProtocols.includes(item.uuid);
            });
        });

        setSelectedProtocols([]);
    };

    const imageFilesJSX = useMemo(() => {
        return fileStates
            .filter(file => isImageFile(file.inputFile?.name || ''))
            .map((file, index) => {
                return (
                    <Box
                        className={classes.singleItem}
                        key={index}
                    >
                        <UploadProtocolListItem
                            fileState={file}
                            onUpdateFileState={(updateFileState: (fileState: IFileState) => IFileState, fileUploadFinished?: boolean) => {
                                setFileStates(prev => {
                                    return prev.map((item) => {
                                        if (item.uuid === file.uuid) {
                                            return updateFileState(item);
                                        }

                                        return item;
                                    });
                                });
                            }}
                            onDelete={(uuid: string) => {
                                setFileStates(prev => {
                                    return prev.filter((item) => {
                                        return item.uuid !== uuid;
                                    });
                                });
                            }}
                            isSelected={selectedProtocols.includes(file.uuid)}
                            onSelect={(uuid: string) => {
                                setSelectedProtocols(prev => {
                                    if (prev.includes(uuid)) {
                                        return prev.filter((item) => {
                                            return item !== uuid;
                                        });
                                    } else {
                                        return [...prev, uuid];
                                    }
                                });
                            }}
                        />
                    </Box>
                );
            });
    }, [fileStates, selectedProtocols, storage.publicUrlsForFiles]);

    const otherFilesJSX = useMemo(() => {
        return fileStates
            .filter(file => !isImageFile(file.inputFile?.name || ''))
            .map((file, index) => {
                return (
                    <ProtocolOtherFileListItem
                        key={index}
                        fileState={file}
                        onUpdateFileState={(updateFileState: (fileState: IFileState) => IFileState, fileUploadFinished?: boolean) => {
                            setFileStates(prev => {
                                return prev.map((item) => {
                                    if (item.uuid === file.uuid) {
                                        return updateFileState(item);
                                    }

                                    return item;
                                });
                            });
                        }}
                        onDelete={(uuid: string) => {
                            setFileStates(prev => {
                                return prev.filter((item) => {
                                    return item.uuid !== uuid;
                                });
                            });
                        }}
                    />
                );
            });
    }, [fileStates]);

    const notUploadedProtocols = fileStates
        .filter(protocol => !protocol.data?.id);

    return (
        <ModalBottomSheet
            blurBackdrop={true}
            hideHeader={true}
            open={open}
            setOpen={setOpen}
            modalAboveModals={true}
            children={
                <>
                    <input
                        ref={imageInputRef}
                        className={genericClasses.hidden}
                        type="file"
                        accept={"image/*"}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => fileChosen(e, true)}
                        multiple={true}
                        capture={capture}
                    />

                    <input
                        ref={fileInputRef}
                        className={genericClasses.hidden}
                        type="file"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => fileChosen(e, false)}
                        multiple={true}
                    />

                    <BottomSheetModalAppbar
                        title={tt('createProtocolBottomSheet.title')}
                        noBorderBottom={true}
                        onClose={() => setOpen(false)}
                    />

                    <Box pl={2} pr={2} pb={2}>
                        <Box pb={2} ml={-2} mr={-2}>
                            <HeadlineWithButton
                                title={tt('createProtocolBottomSheet.label.photos')}
                                customAction={
                                    <Box sx={{display: 'flex'}}>
                                        {selectedProtocols.length > 0 ? (
                                            <>
                                                <AppIconButton
                                                    color={"primary"}
                                                    onClick={deleteSelectedProtocols}
                                                    tooltip={tt('createProtocolBottomSheet.button.deleteSelected')}
                                                >
                                                    <Box sx={{color: kAppColors.red.main}}>
                                                        <DeleteIcon/>
                                                    </Box>
                                                </AppIconButton>

                                                <Box pr={1}/>
                                            </>
                                        ) : undefined}

                                        <AppIconButton
                                            color={"primary"}
                                            onClick={(e) => {
                                                if (canUseCamera) {
                                                    selectFile('camera');
                                                } else {
                                                    checkCameraPermission();
                                                }
                                            }}
                                            tooltip={tt('common.camera')}
                                        >
                                            <Icons8Camera/>
                                        </AppIconButton>

                                        <Box pr={1}/>

                                        <AppIconButton
                                            color={"primary"}
                                            onClick={(e) => {
                                                selectFile('gallery');
                                            }}
                                            tooltip={tt('common.gallery')}
                                        >
                                            <Icons8Gallery/>
                                        </AppIconButton>
                                    </Box>
                                }
                            />

                            <Box className={'styledScrollbar'}>
                                <Box className={classes.galleryContainerOuter}>
                                    <Box className={classes.galleryContainer}>
                                        {imageFilesJSX}
                                    </Box>
                                </Box>
                            </Box>

                            <Divider/>

                            <HeadlineWithButton
                                title={tt('createProtocolBottomSheet.title.files')}
                                customAction={
                                    <Box sx={{display: 'flex'}}>
                                        <AppIconButton
                                            color={"primary"}
                                            onClick={(e) => {
                                                selectFile('any');
                                            }}
                                            tooltip={tt('common.add')}
                                        >
                                            <Icons8Folder/>
                                        </AppIconButton>
                                    </Box>
                                }
                            />

                            {otherFilesJSX}

                            <Divider/>
                        </Box>


                        <FormBuilder
                            inputs={noteInput}
                            setInputs={setNoteInput}
                        />

                        <FormBuilder
                            inputs={defectSwitchInput}
                            setInputs={setDefectsSwitchInput}
                        />

                        <Box pb={2}/>

                        <AppButton
                            variant={"contained"}
                            fullWidth={true}
                            onClick={() => mutateAddJobProtocolToJobToBE()}
                            isLoading={addJobProtocolToJobLoading}
                            disabled={notUploadedProtocols.length > 0 || (!noteInput?.note?.value && fileStates.length === 0)}
                        >
                            {tt('common.save')}
                        </AppButton>
                    </Box>
                </>
            }
        />
    );
}
