import {iconUseStyles} from "../styles/IconStyles";

export interface IHomeProps {
    className?: string;
}

/**
 * Component representing SMapsIcon svg icon.
 */
export default function SMapsIcon(props: IHomeProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                 width="24px" height="24px" viewBox="0 0 153.000000 164.000000"
                 preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,164.000000) scale(0.100000,-0.100000)"
                   fill="currentColor" stroke="none">
                    <path d="M414 1619 c-66 -19 -68 -25 -69 -282 -1 -208 -4 -247 -29 -372 -52
-272 -100 -427 -225 -728 -46 -110 -80 -201 -77 -204 9 -9 187 46 237 73 51
28 69 55 135 199 71 157 115 297 158 510 22 113 46 207 51 207 14 2 89 -131
126 -222 55 -137 63 -152 86 -167 16 -10 34 -13 58 -9 41 8 201 124 304 222
39 37 75 64 79 60 3 -4 10 -100 14 -214 5 -114 12 -231 18 -259 11 -62 63
-192 92 -228 23 -29 58 -32 81 -7 54 59 64 165 73 748 l6 370 -31 32 c-18 17
-60 47 -94 66 -87 49 -97 52 -97 30 0 -29 -66 -151 -121 -225 -59 -79 -268
-284 -278 -272 -4 4 -31 67 -60 138 -83 201 -194 426 -232 469 -57 65 -128 88
-205 65z"/>
                </g>
            </svg>
        </span>
    );
}
