import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8OkProps {
    className?: string;
}

export default function Icons8Ok(props: IIcons8OkProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path fill="currentColor"
                      d="M 24 4 C 12.972066 4 4 12.972074 4 24 C 4 35.027926 12.972066 44 24 44 C 35.027934 44 44 35.027926 44 24 C 44 12.972074 35.027934 4 24 4 z M 24 7 C 33.406615 7 41 14.593391 41 24 C 41 33.406609 33.406615 41 24 41 C 14.593385 41 7 33.406609 7 24 C 7 14.593391 14.593385 7 24 7 z M 31.470703 17.986328 A 1.50015 1.50015 0 0 0 30.439453 18.439453 L 21.5 27.378906 L 17.560547 23.439453 A 1.50015 1.50015 0 1 0 15.439453 25.560547 L 20.439453 30.560547 A 1.50015 1.50015 0 0 0 22.560547 30.560547 L 32.560547 20.560547 A 1.50015 1.50015 0 0 0 31.470703 17.986328 z"/>
            </svg>
        </span>
    );
}
