import {ProductType} from "../../../generated/graphql/graphql";
import React, {Dispatch, SetStateAction} from "react";
import {tt} from "../../../core/Localization";
import ProductForm, {IProductFormUpdate} from "./ProductForm";
import ModalBottomSheet from "../ModalBottomSheet";
import BottomSheetModalAppbar from "../modals/BottomSheetModalAppbar";
import {Box} from "@mui/material";

export interface IEditProductModalBottomSheet {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    data: IEditProductModalBottomSheetData,
    modalAboveModals?: boolean;
    mutateLoading?: boolean;
    onSubmit: (update: IProductFormUpdate) => void;
    loading?: boolean;
}

export interface IEditProductModalBottomSheetData {
    id: number;
    uuid: string;
    name: string;
    description?: string | NullOrUndefined;
    price?: number | NullOrUndefined;
    type: ProductType;
    unitOfMeasure?: string | NullOrUndefined;
    quantity?: number | NullOrUndefined;
    vatRate: number | NullOrUndefined;
}

/**
 * Modal bottom sheet for editing a product.
 */
export default function EditProductModalBottomSheet(props: IEditProductModalBottomSheet) {
    const {open, setOpen, data, modalAboveModals, mutateLoading, onSubmit, loading} = props;

    return (
        <ModalBottomSheet
            blurBackdrop={true}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
            tallOnMobile={true}
        >
            <Box>
                <BottomSheetModalAppbar
                    noBorderBottom={true}
                    title={tt('editProduct.modal.title')}
                    onClose={() => setOpen(false)}
                />

                <Box pr={2} pl={2} pb={2}>
                    <ProductForm
                        onSubmit={onSubmit}
                        updateLoading={mutateLoading}
                        isEdit={true}
                        editQuantity={true}
                        name={data.name}
                        description={data.description}
                        price={data.price}
                        type={data.type}
                        unitOfMeasure={data.unitOfMeasure}
                        quantity={data.quantity}
                        vatRate={data.vatRate}
                    />
                </Box>
            </Box>
        </ModalBottomSheet>
    );
}
