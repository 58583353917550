import React from "react";
import {DateTime} from "luxon";
import {makeStyles} from "tss-react/mui";
import {Box, Theme, Typography} from "@mui/material";
import AppAvatar from "../AppAvatar";
import {FormatDateWithIfTodayModification} from "../../../utils/DateUtils";
import {kAppColors} from "../../../styles/AppThemeProcessor";

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        minHeight: 24,
    },
    text: {
        fontSize: 14,
        fontWeight: 500,
        marginRight: 8
    },
    formRequired: {
        color: kAppColors.red.main,
    },
    formDone: {
        color: kAppColors.green.main,
    }
}));

export interface ILabelWithAvatarProps {
    workerName?: string | NullOrUndefined;
    img?: string | NullOrUndefined;
    className?: string,
    date?: DateTime,
    label?: string,
    isRequired?: boolean,
    isDone?: boolean,
}

export default function (props: ILabelWithAvatarProps) {
    const {
        date,
        img,
        className,
        workerName,
        label,
        isRequired,
        isDone,
    } = props;

    const {cx, classes} = useStyles();

    return (
        <Box className={cx(classes.container, className)}>
            {label ? <Typography className={classes.text}>{label}{
                isRequired ? (
                    isDone ? <span
                            className={classes.formDone}> ✓</span> :
                        <span
                            className={classes.formRequired}> *</span>
                ) : null}</Typography> : null}
            {workerName || img ? <AppAvatar
                img={img}
                title={workerName}
                tooltip={`${workerName} · ${FormatDateWithIfTodayModification(date)}`}
                variant={"tiny"}
            /> : null}
        </Box>
    );
}
