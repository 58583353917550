import {iconUseStyles} from "../styles/IconStyles";
import {useId} from "react";

export interface IIcons8BoxPlusProps {
    className?: string;
}

export default function Icons8BoxPlus(props: IIcons8BoxPlusProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    const idOverlayMask = useId();
    const clipThingID = useId();
    const clipPath = `url("#${clipThingID}")`;

    return (
        <span className={theClassName}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256"
                 width="48px" height="48px" fillRule="nonzero">
                <defs>
                    <clipPath id={clipThingID}>
                        <path
                            d="M0,256v-256h256v256h-40.10967c19.20941,-10.77768 32.13997,-31.32826 32.13997,-55c0,-34.90909 -28.12121,-63.0303 -63.0303,-63.0303c-34.90909,0 -63.0303,28.12121 -63.0303,63.0303c0,23.67174 12.93056,44.22232 32.13997,55z"
                            id={idOverlayMask} fill="none"></path>
                    </clipPath>
                </defs>
                <g clipPath={clipPath} fill="none" fillRule="nonzero" stroke="none" strokeWidth="1"
                   strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray=""
                   strokeDashoffset="0"
                >
                    <g transform="scale(5.33333,5.33333)" fill="currentColor">
                        <path
                            d="M8,16v20.5c0,3.584 2.916,6.5 6.5,6.5h19c3.584,0 6.5,-2.916 6.5,-6.5v-20.5zM28.5,25h-9c-0.829,0 -1.5,-0.672 -1.5,-1.5c0,-0.828 0.671,-1.5 1.5,-1.5h9c0.829,0 1.5,0.672 1.5,1.5c0,0.828 -0.671,1.5 -1.5,1.5zM39.5,14h-31c-1.378,0 -2.5,-1.121 -2.5,-2.5v-4c0,-1.379 1.122,-2.5 2.5,-2.5h31c1.378,0 2.5,1.121 2.5,2.5v4c0,1.379 -1.122,2.5 -2.5,2.5z"></path>
                    </g>
                </g>
                <g fill="currentColor" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt"
                   strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0"
                >
                    <g transform="translate(131.66667,147.66667) scale(4.84848,4.84848)" id="overlay">
                        <path
                            d="M11,0c-6.075,0 -11,4.925 -11,11c0,6.075 4.925,11 11,11c6.075,0 11,-4.925 11,-11c0,-6.075 -4.925,-11 -11,-11zM18,12h-6v6c0,0.552 -0.448,1 -1,1c-0.552,0 -1,-0.448 -1,-1v-6h-6c-0.552,0 -1,-0.448 -1,-1c0,-0.552 0.448,-1 1,-1h6v-6c0,-0.552 0.448,-1 1,-1c0.552,0 1,0.448 1,1v6h6c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1z"></path>
                    </g>
                </g>
            </svg>
        </span>
    );
}






