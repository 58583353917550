import {Box, CircularProgress, Typography} from "@mui/material";
import React, {useContext} from "react";
import {AppDataContext} from "../../../AppData";
import {VisitStatus, VisitStatusModifier} from "../../../generated/graphql/graphql";
import {
    getVisitStatusBackgroundColor,
    getVisitStatusModifierColor,
    getVisitStatusTextColor
} from "../../../service/VisitService";

export interface IColoredValueChipProps {
    value: number;
    status?: VisitStatus;
    statusModifier?: VisitStatusModifier;
    isLoading: boolean;
}

export default function ColoredValueChip(props: IColoredValueChipProps) {
    const {value, status, isLoading, statusModifier} = props;

    const appDataContext = useContext(AppDataContext);
    const {darkMode} = appDataContext;

    if (statusModifier) {
        return (<Box
            style={{
                height: 37,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 1,
                borderRadius: 14,
                minWidth: 66,
                border: `1px solid ${getVisitStatusModifierColor(statusModifier)}`,
                background: darkMode ? '#202020' : '#FFFFFF',
            }}
        >
            {isLoading ?
                <CircularProgress
                    style={{color: getVisitStatusModifierColor(statusModifier), height: 22, width: 22}}/> :
                <Typography style={{
                    fontSize: 14,
                    fontWeight: 600,
                    textTransform: "uppercase",
                    color: getVisitStatusModifierColor(statusModifier),
                }}>{value > 999 ? '999+' : value}</Typography>}
        </Box>);
    }

    if (status) {
        return (
            <Box
                style={{
                    height: 37,
                    display: "inline-block",
                    padding: 8,
                    textAlign: 'center',
                    borderRadius: 14,
                    minWidth: 66,
                    background: getVisitStatusBackgroundColor(status, darkMode),
                    border: status == VisitStatus.Closed || status == VisitStatus.Canceled ? `1px solid ${getVisitStatusTextColor(status, darkMode)}` : undefined,
                }}
            >
                {isLoading ? <CircularProgress
                        style={{color: getVisitStatusTextColor(status, darkMode), height: 22, width: 22}}/> :
                    <Typography
                        style={{
                            fontSize: 14,
                            fontWeight: 600,
                            textTransform: "uppercase",
                            color: getVisitStatusTextColor(status, darkMode),
                        }}
                    >{value > 999 ? '999+' : value}</Typography>}
            </Box>
        );
    }

    return <></>;
}
