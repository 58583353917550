import React from "react";
import MovingIcon from "../icons/MovingIcon";
import LawnCareIcon from "../icons/LawnCareIcon";
import PlumbingIcon from "../icons/PlumbingIcon";
import HouseKeepingIcon from "../icons/HouseKeepingIcon";
import RadiatorIcon from "../icons/RadiatorIcon";
import ElectricityIcon from "../icons/ElectricityIcon";
import AirConditionerIcon from "../icons/AirConditionerIcon";
import ToolIcon from "../icons/ToolIcon";
import MoreFilledIcon from "../icons/MoreFilledIcon";
import JalousieIcon from "../icons/JalousieIcon";
import Icons8GarageDoor from "../icons/Icons8GarageDoor";
import Icons8Key2 from "../icons/Icons8Key2";
import Icons8HouseFence from "../icons/Icons8HouseFence";
import Icons8SolarPanel from "../icons/Icons8SolarPanel";
import {tt} from "../core/Localization";
import Icons8Water from "../icons/Icons8Water";
import Icons8Carpet from "../icons/Icons8Carpet";
import Icons8Window from "../icons/Icons8Window";
import Icons8Door from "../icons/Icons8Door";
import Icons8Pavilion from "../icons/Icons8Pavilion";
import Icons8Warehouse from "../icons/Icons8Warehouse";
import Icons8PaintRoller from "../icons/Icons8PaintRoller";
import Icons8Digger from "../icons/Icons8Digger";
import Icons8Cable from "../icons/Icons8Cable";
import Icons8CameraIndustry from "../icons/Icons8CameraIndustry";
import Icons8MediumIcons from "../icons/Icons8MediumIcons";
import Icons8Chimney from "../icons/Icons8Chimney";
import Icons8WashingMachine from "../icons/Icons8WashingMachine";
import Icons8Bug from "../icons/Icons8Bug";
import Icons8Elevator from "../icons/Icons8Elevator";
import DeleteIcon from "../icons/DeleteIcon";
import Icons8Pool from "../icons/Icons8Pool";

/**
 * Get svg icon for an Industry by its name.
 */
export function getIndustryIcon(itemTitle: String) {
    let icon = <ToolIcon/>;

    switch (itemTitle) {
        case 'housekeeping':
            icon = <HouseKeepingIcon/>;
            break;
        case 'moving':
            icon = <MovingIcon/>;
            break;
        case 'lawn-care':
            icon = <LawnCareIcon/>;
            break;
        case 'plumbing':
            icon = <PlumbingIcon/>;
            break;
        case 'ventilation':
            icon = <AirConditionerIcon/>;
            break;
        case 'heating':
            icon = <RadiatorIcon/>;
            break;
        case 'electricity':
            icon = <ElectricityIcon/>;
            break;
        case 'handyman':
            icon = <ToolIcon/>;
            break;
        case 'other':
            icon = <MoreFilledIcon/>;
            break;
        case 'shadingTechnology':
            icon = <JalousieIcon/>;
            break;
        case 'garageDoor':
            icon = <Icons8GarageDoor/>;
            break;
        case 'locksmith':
            icon = <Icons8Key2/>;
            break;
        case 'fence':
            icon = <Icons8HouseFence/>;
            break;
        case 'solarPanel':
            icon = <Icons8SolarPanel/>;
            break;
        case 'pressureCleaning':
            icon = <Icons8Water/>;
            break;
        case 'carpetCleaning':
            icon = <Icons8Carpet/>;
            break;
        case 'windowCleaning':
            icon = <Icons8Window/>;
            break;
        case 'doorInstallation':
            icon = <Icons8Door/>;
            break;
        case 'pergolaInstallation':
            icon = <Icons8Pavilion/>;
            break;
        case 'warehouseRackInstallation':
            icon = <Icons8Warehouse/>;
            break;
        case 'electroRevision':
            icon = <ElectricityIcon/>;
            break;
        case 'painting':
            icon = <Icons8PaintRoller/>;
            break;
        case 'digging':
            icon = <Icons8Digger/>;
            break;
        case 'itServices':
            icon = <Icons8Cable/>;
            break;
        case 'cameraSystemsInstallation':
            icon = <Icons8CameraIndustry/>;
            break;
        case 'tiling':
            icon = <Icons8MediumIcons/>;
            break;
        case 'chimney':
            icon = <Icons8Chimney/>;
            break;
        case 'homeApplianceServices':
            icon = <Icons8WashingMachine/>;
            break;
        case 'deratization':
            icon = <Icons8Bug/>;
            break;
        case 'elvatorServices':
            icon = <Icons8Elevator/>;
            break;
        case 'wasteDisposal':
            icon = <DeleteIcon/>;
            break;
        case 'poolsServices':
            icon = <Icons8Pool/>;
            break;
        default:
            return <ToolIcon/>;
    }

    return icon;
}
