import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import AppModal from "../../AppModal";
import {tt} from "../../../../../core/Localization";
import AppChip from "../../../chips/AppChip";
import {makeStyles} from "tss-react/mui";
import {Box, Theme, Typography} from "@mui/material";
import {kContentWidthNarrow} from "../../../../../styles/AppThemeProcessor";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../../form/FormBuilder";
import {UpdateVisitDateTimeOnlyInput} from "../../../../../generated/graphql/graphql";
import {FetchPolicy, RestApiClientContext} from "../../../../../core/RestApiProvider";
import {SuccessToast} from "../../../../../service/ToastService";
import {processMutationError} from "../../../../../service/ErrorService";
import {DateTime} from "luxon";
import {EventImpl} from "@fullcalendar/core/internal";

export const useStyles = makeStyles()((theme: Theme) => ({
    modalPaper: {
        maxWidth: kContentWidthNarrow,
    },
    chip: {
        flexGrow: 1,
    },
    oldDate: {
        textDecoration: "line-through",
    },
    newDate: {
        fontWeight: 700,
    },
}));

export interface IEditVisitDateByCalendarModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    modalAboveModals?: boolean;
    oldData?: EventImpl | undefined;
    input?: UpdateVisitDateTimeOnlyInput;
    onCancel: () => void;
}

/**
 * Modal component for editing visit date by calendar.
 */
export default function EditVisitDateByCalendarModal(props: IEditVisitDateByCalendarModalProps) {
    const {
        open,
        setOpen,
        modalAboveModals,
        input,
        onCancel,
        oldData,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiPost} = restApiClientContext;

    const {classes, cx} = useStyles();

    const [inputs, setInputs] = useState<IInputsData>({
        notifications: {
            type: InputType.CheckBox,
            label: tt('common.visit.checkbox.sendNotificationsToWorkers'),
            value: true,
        },
    });

    useEffect(() => {
        if (open) {
            setInputs(prev => {
                return {
                    ...prev,
                    notifications: {
                        ...prev.notifications,
                        value: true,
                    },
                };
            });
        }
    }, [open]);

    const [loading, setLoading] = useState<boolean>(false);
    /**
     * Edit start/end of Visit with optional notifications.
     */
    const editVisitDate = () => {
        if (ValidateForm(inputs, setInputs)) {
            try {
                const theInput = input as UpdateVisitDateTimeOnlyInput;

                theInput.sendNotifications = inputs.notifications.value;

                restApiPost({
                    uri: '/job/visit/update-date-time-only',
                    params: theInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading,
                    onData: (data) => {
                        if (data) {
                            SuccessToast(tt('common.visit.update.success'));

                            setOpen(false);
                        }
                    },
                    onError: (error) => {
                        processMutationError(error);

                        onCancel();
                    },
                });
            } catch (e) {
                processMutationError(e);

                onCancel();
            }
        }
    };

    return (
        <AppModal
            paperClassName={classes.modalPaper}
            title={tt('editVisitDateByCalendar.modal.title')}
            centeredHeader={true}
            open={open}
            modalAboveModals={modalAboveModals}
            actions={(
                <>
                    <AppChip
                        color={"success"}
                        className={classes.chip}
                        key={'confirmButtonKey'}
                        onClick={editVisitDate}
                        label={tt('editVisitDateByCalendar.modal.save')}
                        disabled={loading}
                    />

                    <Box key={'confirmPopupSpaceKey'} pr={2}/>

                    <AppChip
                        className={classes.chip}
                        key={'cancelButtonKey'}
                        onClick={(event) => {
                            setOpen(false);

                            onCancel();
                        }}
                        disabled={loading}
                        chipstyle={"secondary"}
                        label={tt('common.cancel')}
                    />
                </>
            )}
        >

            <Box pb={2}/>

            {input?.startDate && oldData?.start ?
                <Typography sx={{textAlign: 'center'}}>
                    <span
                        className={classes.oldDate}>{DateTime.fromMillis(oldData?.start.getTime()).toFormat('H:mm · d.M.yyyy')}</span>
                    &nbsp;&nbsp;→&nbsp;&nbsp;
                    <span
                        className={classes.newDate}>{DateTime.fromMillis(input?.startDate).toFormat('H:mm · d.M.yyyy')}</span>
                </Typography>
                : null}

            <Box pb={2}/>
            <FormBuilder inputs={inputs} setInputs={setInputs}/>
            <Box pb={2}/>
        </AppModal>
    );
}
