import {Box, Theme} from "@mui/material";
import AppButton from "../../../buttons/AppButton";
import {tt} from "../../../../../core/Localization";
import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../../../styles/AppThemeProcessor";

const kContentHeight = 22;
const kContentHeight2Lines = 44;

export const useStyles = makeStyles()((theme: Theme) => ({
    viewAllButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 8,
        height: "auto !important",
        paddingTop: '2px !important',
        paddingBottom: '2px !important',
        color: kAppColors.primary.main,
        lineHeight: 1,
        borderRadius: '4px !important',
        ':hover': {
            backgroundColor: kAppColors.hoverColor.mainLighter,
        },
    },
    outerOuterContainer: {
        position: "relative",
    },
    htmlOuterContainer: {
        maxHeight: kContentHeight,
        overflow: "hidden",
        marginBottom: 16,
        position: "relative",
        'p': {
            maxHeight: kContentHeight,
            margin: 0,
            textOverflow: "ellipsis",
        }
    },
    htmlOuterContainer2LinesVariant: {
        maxHeight: kContentHeight2Lines,
        'p': {
            maxHeight: kContentHeight2Lines,
        }
    },
    fullHtmlOuterContainer: {
        'p': {
            maxHeight: 'unset',
            margin: 0,
        },
        marginBottom: 16,
    },
    viewAllButtonContainer: {
        'button': {
            marginBottom: 0,
        },
        position: "absolute",
        zIndex: 1,
        bottom: 4,
        right: 0,
        paddingLeft: 10,
        background: theme.palette.mode === "dark" ? 'rgba(32,32,32,1)' : 'rgba(255,255,255,1)',
    },
    viewLessButtonContainer: {
        'button': {
            marginLeft: "auto"
        }
    }
}));


export interface IDescriptionWidgetProps {
    content: string | NullOrUndefined;
    variant?: 'default' | '2lines',
}

export default function DescriptionWidget(props: IDescriptionWidgetProps) {
    const {
        content,
        variant,
    } = props;

    const {classes, cx} = useStyles();

    const [showViewAll, setShowViewAll] = useState<boolean>(true);
    const [viewAll, setViewAll] = useState<boolean>(false);

    const theOuterContainerClass = cx(
        viewAll || !showViewAll ? classes.fullHtmlOuterContainer : classes.htmlOuterContainer,
        variant == "2lines" && !(viewAll || !showViewAll) ? classes.htmlOuterContainer2LinesVariant : null,
    );

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref && ref.current) {
            if (ref.current.clientHeight < kContentHeight) {
                setShowViewAll(false);
                setViewAll(false);
            } else {
                setShowViewAll(true);
            }
        }
    }, [content]);

    if (!content) {
        return <Box sx={{pb: 1}}></Box>;
    }

    return (
        <Box className={classes.outerOuterContainer}>

            {showViewAll && !viewAll ? <Box className={classes.viewAllButtonContainer}>
                <AppButton
                    className={classes.viewAllButton}
                    onClick={() => setViewAll(!viewAll)}
                    variant={"textThin"}>
                    {tt('descriptionWidget.viewMore')}
                </AppButton>
            </Box> : null}

            <Box className={theOuterContainerClass}>
                <div
                    ref={ref}
                    dangerouslySetInnerHTML={{__html: content}}></div>
            </Box>


            {showViewAll && viewAll ? <Box className={classes.viewLessButtonContainer}>
                <AppButton
                    className={classes.viewAllButton}
                    onClick={() => setViewAll(!viewAll)}
                    variant={"textThin"}>
                    {tt('descriptionWidget.viewLess')}
                </AppButton>
            </Box> : null}

        </Box>
    );
}
