import {makeStyles} from "tss-react/mui";
import {Box, Skeleton, Theme} from "@mui/material";
import {ChevronRight} from "@mui/icons-material";
import React from "react";
import {kAppColors} from "../../../styles/AppThemeProcessor";

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'flex',
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
        paddingBottom: 8,
        flexGrow: 1,
        flexDirection: "column",
    },
    outerContainer: {
        width: '100%',
        display: "flex",
    },
    innerContainer: {
        display: "flex",
        flexGrow: 1,
    },
    textsContainer: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: 12,
    },
    iconContainer: {
        display: "flex",
        alignItems: "center",
        marginLeft: 'auto',
    },
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: 10,
    },
    singleChipContainer: {
        marginBottom: 4,
        marginRight: 4,
        '.MuiSkeleton-root': {
            borderRadius: 8,
        }
    },
    listItemBeforeContent: {
        flexShrink: 0,
        paddingRight: 6,
        paddingLeft: 14,
        marginTop: 8,
        marginBottom: 22,
        width: 80,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRight: `1px solid ${kAppColors.border(theme.palette.mode === "dark")}`,
        textOverflow: "ellipsis",
        whiteSpace: "pre-wrap",
        overflow: "hidden",
    },
}));

export default function VisitListItemShimmer() {
    const {classes} = useStyles();

    return (
        <Box className={classes.outerContainer}>
            <Box className={classes.listItemBeforeContent}>
                <Skeleton variant={"rectangular"} width={40} height={14}></Skeleton>
                <Box pb={0.5}/>
                <Skeleton variant={"rectangular"} width={58} height={14}></Skeleton>
                <Box pb={0.5}/>
                <Skeleton variant={"rectangular"} width={36} height={14}></Skeleton>
            </Box>
            <Box className={classes.container}>
                <Box className={classes.innerContainer}>
                    <Box className={classes.textsContainer}>
                        <Skeleton variant={"rectangular"} width={200} height={16}></Skeleton>
                        <Box pb={0.5}/>
                        <Skeleton variant={"rectangular"} width={160} height={14}></Skeleton>
                    </Box>
                </Box>
                <Box className={classes.chipsContainer}>
                    <Box className={classes.singleChipContainer}>
                        <Skeleton variant={"rectangular"} width={80} height={30}></Skeleton>
                    </Box>
                    <Box className={classes.singleChipContainer}>
                        <Skeleton variant={"rectangular"} width={60} height={30}></Skeleton>
                    </Box>
                    <Box className={classes.singleChipContainer}>
                        <Skeleton variant={"rectangular"} width={30} height={30}></Skeleton>
                    </Box>
                </Box>
            </Box>

        </Box>

    );
}