import {iconUseStyles} from "../../styles/IconStyles";

export interface ISKIconProps {
    className?: string;
}

export default function SKIcon(props: ISKIconProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg id="icons8-slovakia" xmlns="http://www.w3.org/2000/svg" width="19.999" height="19.999"
                 viewBox="0 0 19.999 19.999">
                <path id="Path_86" data-name="Path 86"
                      d="M14.642,4a9.995,9.995,0,0,0-9.358,6.5H24A9.994,9.994,0,0,0,14.642,4Z"
                      transform="translate(-4.642 -4)" fill="#eceff1"/>
                <path id="Path_87" data-name="Path 87"
                      d="M24,20.5a9.962,9.962,0,0,0-.642-3.5H4.642a9.861,9.861,0,0,0,0,7H23.357A9.951,9.951,0,0,0,24,20.5Z"
                      transform="translate(-4 -10.5)" fill="#1976d2"/>
                <path id="Path_88" data-name="Path 88" d="M24,31H5.284A9.986,9.986,0,0,0,24,31Z"
                      transform="translate(-4.642 -17.5)" fill="#ff3d00"/>
                <path id="Path_89" data-name="Path 89"
                      d="M12,24.072c-1.212-.565-3.014-1.182-3.042-3.463s-.042-3.5-.042-3.5l6-.072s.015,1.242.042,3.5c.028,2.281-1.759,2.941-2.957,3.536"
                      transform="translate(-6.459 -10.519)" fill="#ff3d00"/>
                <path id="Path_90" data-name="Path 90"
                      d="M7.905,16.109s.018,1.513.048,4,2.124,3.342,3.548,3.957c1.408-.65,3.481-1.554,3.451-4.042s-.048-4-.048-4Zm6.547,3.921c.027,2.281-1.759,2.941-2.957,3.536C10.283,23,8.48,22.384,8.453,20.1s-.042-3.5-.042-3.5l6-.072S14.425,17.773,14.452,20.03Z"
                      transform="translate(-5.953 -10.013)" fill="#eceff1"/>
                <path id="Path_91" data-name="Path 91"
                      d="M13.282,20.844a5.276,5.276,0,0,0,1.708-.212s-.013.226-.009.49.021.49.021.49a5.575,5.575,0,0,0-1.713-.172l.017,1.41-.576.007-.017-1.408a5.465,5.465,0,0,0-1.71.214s.013-.226.009-.492-.021-.49-.021-.49a5.177,5.177,0,0,0,1.715.17l-.01-.884a3.077,3.077,0,0,0-1.211.147s0-.226-.006-.49-.006-.492-.006-.492a3.7,3.7,0,0,0,1.214.243,7.858,7.858,0,0,0-.2-1.265s.338.021.471.019.47-.031.47-.031a7.85,7.85,0,0,0-.166,1.267,3.686,3.686,0,0,0,1.208-.262s-.013.226-.009.49.021.492.021.492a3.1,3.1,0,0,0-1.212-.126Z"
                      transform="translate(-7.496 -11.049)" fill="#fff"/>
                <path id="Path_92" data-name="Path 92"
                      d="M13.617,26.922s-.328-.82-.924-.813-.9.835-.9.835a.727.727,0,0,0-.667-.382,1.1,1.1,0,0,0-.761.564,6.367,6.367,0,0,0,2.362,1.483,6.154,6.154,0,0,0,2.3-1.5c-.157-.244-.446-.584-.745-.581A.73.73,0,0,0,13.617,26.922Z"
                      transform="translate(-7.181 -15.055)" fill="#1976d2"/>
            </svg>
        </span>
    );
}
