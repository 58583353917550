import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import {useNavigate, useParams} from "react-router-dom";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import PaperAppbar from "../../components/paper/PaperAppbar";
import ContentPadding from "../../components/paper/ContentPadding";
import {IProductFormUpdate} from "../../components/products/ProductForm";
import {tt} from "../../../core/Localization";
import MaterialForm from "../../components/materials/MaterialForm";
import {materialDetailRoute} from "./MaterialDetailScreen";
import {GetMaterialInput, MaterialResponse, UpdateMaterialTemplateInput} from "../../../generated/graphql/graphql";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import {processMutationError} from "../../../service/ErrorService";
import {SuccessToast} from "../../../service/ToastService";

export const kMaterialEditRoute = '/materials/:materialId/edit';

export function materialEditRoute(materialId: string | number): string {
    return kMaterialEditRoute.replace(':materialId', `${materialId}`)
}

export default function MaterialEditScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet, restApiPost} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const {materialId} = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        setTitle(tt('materialEdit.screen.title'));
    }, []);

    const [resetFormTrigger, setResetFormTrigger] = useState(0);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<MaterialResponse | NullOrUndefined>();
    useEffect(() => {
        restApiGet({
            uri: '/material',
            params: {
                materialId: parseInt(materialId!),
            } as GetMaterialInput,
            setLoading,
            onData: (data: MaterialResponse) => {
                setData(data);

                setResetFormTrigger((prev) => prev + 1);
            },
            onError: (error) => processMutationError(error),
        });
    }, [materialId]);

    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const UpdateMaterial = async (update: IProductFormUpdate) => {
        setLoadingUpdate(true);

        restApiPost({
            uri: '/material/template/update',
            params: {
                id: parseInt(materialId!),
                name: update.inputs.name.value,
                unitName: update.inputs.unitOfMeasure.value,
            } as UpdateMaterialTemplateInput,
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading: setLoadingUpdate,
            onData: (data: MaterialResponse) => {
                if (data) {
                    SuccessToast(tt('materialEdit.screen.success'));

                    navigate(materialDetailRoute(data.id));
                }
            },
            onError: (error) => processMutationError(error),
        });
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                loading={loading && !data}
                data={data}
                resetFormTrigger={resetFormTrigger}
                isMobile={isMobile}
                onSubmit={UpdateMaterial}
                updateLoading={loadingUpdate}
            />
        );
    }

    return (
        <ResponsiveContainer
            smallPhoneScreen={bodyJSX(true)}
            largePhoneScreen={bodyJSX(true)}
            tabletScreen={bodyJSX()}
            smallDesktopScreen={bodyJSX()}
            largeDesktopScreen={bodyJSX()}
            extraLargeDesktopScreen={bodyJSX()}
        />
    );
}

interface IBodyProps {
    loading: boolean;
    updateLoading?: boolean;
    data?: any;
    resetFormTrigger: number;
    isMobile?: boolean;
    onSubmit: (update: IProductFormUpdate) => void;
}

function Body(props: IBodyProps) {
    const {
        loading,
        data,
        resetFormTrigger,
        isMobile,
        onSubmit,
        updateLoading,
    } = props;

    const {materialId} = useParams();

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}
            >
                <PaperAppbar
                    isMobile={isMobile}
                    title={tt('materialEdit.screen.title')}
                    backRoute={materialDetailRoute(materialId!)}
                    cancelIconBackButton={true}/>

                <ContentPadding>
                    <MaterialForm
                        isEdit={true}
                        name={data?.name ?? ''}
                        unitOfMeasure={data?.unitName ?? ''}
                        onSubmit={onSubmit}
                        loading={loading}
                        updateLoading={updateLoading}
                        loadingData={!data && loading}
                        resetFormTrigger={resetFormTrigger}
                    />
                </ContentPadding>
            </AppPaper>
        </ScreenContent>
    );
}
