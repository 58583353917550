import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import {kAppColors} from "../../../../../styles/AppThemeProcessor";
import {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {AppDataContext} from "../../../../../AppData";
import {usePopupState} from "material-ui-popup-state/hooks";
import FormBuilder, {IInputsData, InputType} from "../../../form/FormBuilder";
import {tt} from "../../../../../core/Localization";
import ListShimmer from "../../../shimmers/ListShimmer";
import EmptyListText from "../../../textComponents/EmptyListText";
import {
    GetLocationsForCompanyInput,
    LocationPureResponsePage
} from "../../../../../generated/graphql/graphql";
import Icons8Location from "../../../../../icons/Icons8Location";
import {LocationNameDisplay, LocationTypeDisplay} from "../../../../../service/LocationService";
import AppChip from "../../../chips/AppChip";
import AppAvatar from "../../../AppAvatar";
import FilterButton from "../../../buttons/FilterButton";
import Menu from "@mui/material/Menu";
import {bindMenu} from "material-ui-popup-state";
import {processQueryError} from "../../../../../service/ErrorService";
import AppListItem from "../../../listItems/AppListItem";
import { RestApiClientContext } from "../../../../../core/RestApiProvider";
import { kTopicLocations } from "../../../../../core/constants";
import {addressToSingleLine} from "../../../../../utils/AddressUtils";

export const useStyles = makeStyles()((theme: Theme) => ({
    menuOffset: {
        '.MuiPaper-root': {
            width: '90%',
            maxWidth: 400,
        },
        transform: 'translateY(8px)',
        '.MuiListItem-root': {
            borderRadius: 0,
        }
    },
    chip: {
        marginRight: 8,
        marginTop: 6,
        marginBottom: 2,
        borderRadius: 40,
        paddingLeft: 0,
        paddingRight: 4,
        fontSize: 14,
        height: 32,
        fontWeight: 600,
        background: kAppColors.background.autocompleteChip(theme.palette.mode === "dark"),
        color: kAppColors.text.primary(theme.palette.mode === 'dark'),
        ':hover': {
            background: kAppColors.background.autocompleteChip(theme.palette.mode === "dark"),
        },
        '.MuiChip-icon.MuiChip-icon': {
            marginLeft: 3,
        },
        '.MuiChip-deleteIcon': {
            color: theme.palette.mode === 'dark' ? kAppColors.text.white : kAppColors.text.secondaryLightModeGrey,
        }
    },
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
    },
    input: {
        '.MuiInputBase-root': {
            flexDirection: "column",
            alignItems: "start",
        }
    },
    iconColor: {
        color: kAppColors.text.primary(theme.palette.mode === 'dark'),
    }
}));


export interface IJobsByLocationFilterProps {
    selected: number | undefined;
    setSelected: Dispatch<SetStateAction<number | undefined>>;
    validLocations?: number[];
    isDisabled?: boolean;
}

export default function VisitsByLocationFilter(props: IJobsByLocationFilterProps) {
    const {selected, setSelected, validLocations, isDisabled} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const {classes} = useStyles();

    const locationsFilterMenu = usePopupState({
        variant: 'popover',
        popupId: 'popup-jobs-locations-filter',
    });

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            className: classes.input,
            type: InputType.Text,
            label: '',
            value: '',
            required: true,
            size: "small",
            hideLabel: true,
            placeholder: tt('jobsScreen.filterByLocation.input.label'),
        },
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<LocationPureResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            const subscription = subscribe(
                kTopicLocations,
                {
                    uri: '/location/search-pure',
                    params: {
                        companyId,
                    } as GetLocationsForCompanyInput,
                    setLoading,
                    onData: setData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                () => true,
            );

            return () => subscription.cancel();
        } else {
            setData(null);
        }
    }, [companyId]);

    useEffect(() => {
        const location = data?.content.find(element => element.id === selected);
        const title = LocationNameDisplay(location?.name, location?.type || '', location?.address);

        setInputs(prev => {
            return {
                ...prev,
                search: {
                    ...prev.search,
                    innerPrefixJSX: (
                        <Box className={classes.chipsContainer}>
                            {location ? <AppChip
                                icon={
                                    <AppAvatar
                                        variant={"extraSmall"}
                                        icon={<Icons8Location className={classes.iconColor}/>}
                                    />
                                }
                                onDelete={
                                    () => {
                                        setSelected(undefined);
                                    }
                                }
                                label={title}
                                className={classes.chip}
                            /> : null}
                        </Box>
                    ),
                }
            };
        });
    }, [selected, data]);

    const content = useMemo(
        () => {
            const theSearch = inputs.search.value.toLowerCase();

            const theData = data?.content
                .filter(location => {
                    if (validLocations && !validLocations.includes(location.id)) {
                        return false;
                    }

                    const theName = LocationNameDisplay(location.name, location.type || '');

                    const theAddress = addressToSingleLine(location.address) || '';

                    return theName.toLowerCase().includes(theSearch) || theAddress.toLowerCase().includes(theSearch);
                });

            return loading && !theData ?
                <ListShimmer items={2}/> : theData && theData.length > 0 ?
                    theData.slice(0, 5)
                        .map(location => {
                            return (
                                <Box
                                    key={location.id}
                                    mr={-1}
                                    ml={-1}
                                >
                                    <AppListItem
                                        onClick={() => {
                                            ///can select max 1 location
                                            setSelected(location.id);
                                            locationsFilterMenu.close();
                                        }}
                                        variant={"smaller-title"}
                                        customAvatarInCircle={<Icons8Location/>}
                                        title={LocationNameDisplay(location.name, location.type || '')}
                                        description={`${location.name ? LocationTypeDisplay(location.type || '') : ''}\n${addressToSingleLine(location.address)}`.trim()}
                                        actionWidget={<></>}
                                    />
                                </Box>
                            );
                        })
                    : <EmptyListText text={tt('locations.screen.emptyListMessage')}/>;
        },
        [data, inputs.search.value, selected, validLocations]
    );

    const buttonTitle = useMemo(
        () => {
            const location = data?.content?.find(element => element.id === selected);

            return LocationNameDisplay(location?.name, location?.type || '', location?.address);
        }, [data, selected]
    );

    return (
        <>
            <FilterButton
                title={selected ? buttonTitle : tt('visits.screen.filterByLocations.button')}
                onClick={
                    (e) => {
                        locationsFilterMenu.setOpen(true, e);
                    }
                }
                isSelected={selected != undefined}
                isDisabled={isDisabled}
            />

            <Menu className={classes.menuOffset}  {...bindMenu(locationsFilterMenu)}>
                <FormBuilder inputs={inputs} setInputs={setInputs}/>

                {content}
            </Menu>
        </>
    );
}
