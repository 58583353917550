import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {
    CancelVisitByManagerInput,
    JobUpdateRepeats,
    ReactivateVisitInput,
    VisitResponse
} from "../../../../../generated/graphql/graphql";
import {FetchPolicy, RestApiClientContext} from "../../../../../core/RestApiProvider";
import AppModal from "../../AppModal";
import {tt} from "../../../../../core/Localization";
import AppChip from "../../../chips/AppChip";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../../form/FormBuilder";
import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import {kAppColors, kContentWidthNarrow} from "../../../../../styles/AppThemeProcessor";
import {SuccessToast} from "../../../../../service/ToastService";
import {processMutationError} from "../../../../../service/ErrorService";
import {IOnUpdateVisitIdParams} from "./VisitDetailModal";

export const useStyles = makeStyles()((theme: Theme) => ({
    modalPaper: {
        maxWidth: kContentWidthNarrow,
    },
    chip: {
        flexGrow: 1,
    },
    confirmChip: {
        flexGrow: 1,
    },
    closeButtonContainer: {
        flexGrow: 1,
        display: "flex",
        '.MuiChip-root': {
            margin: "auto",
            minWidth: 190,
        }
    }
}));

export interface ICancelActivateVisitModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    modalAboveModals?: boolean;
    reActivate?: boolean;
    visitId: number;
    repeats: JobUpdateRepeats;
    repeatingDay?: number;
    onUpdateVisitId: (params: IOnUpdateVisitIdParams) => void;
}

/**
 * Component for displaying Cancel or ReActivate confirmation and exectute Visit modal.
 */
export default function CancelActivateVisitModal(props: ICancelActivateVisitModalProps) {
    const {open, setOpen, modalAboveModals, reActivate, visitId, repeats, repeatingDay, onUpdateVisitId} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiPost} = restApiClientContext;

    const {classes, cx} = useStyles();

    const [inputs, setInputs] = useState<IInputsData>({
        notifications: {
            type: InputType.CheckBox,
            label: tt('common.visit.checkbox.sendNotificationsToWorkers'),
            value: true,
        },
    });

    useEffect(() => {
        if (open) {
            setInputs(prev => {
                return {
                    ...prev,
                    notifications: {
                        ...prev.notifications,
                        value: true,
                    },
                };
            });
        }
    }, [open]);

    const [loading, setLoading] = useState<boolean>(false);
    /**
     * OnConfirm cancel or reActivate Visit to BE.
     */
    const onConfirm = () => {
        if (ValidateForm(inputs, setInputs)) {
            if (reActivate) {
                restApiPost({
                    uri: '/job/visit/reactivate',
                    params: {
                        visitId,
                        repeatingDay,
                        repeats,
                        sendNotifications: inputs.notifications.value,
                    } as ReactivateVisitInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading,
                    onData: (data: VisitResponse) => {
                        if (data) {
                            SuccessToast(tt('visitDetailMenu.reactivateVisit.success'));

                            setOpen(false);

                            if (data.id) {
                                onUpdateVisitId({
                                    visitId: data.id,
                                    repeatingDay: repeatingDay!,
                                });
                            }
                        }
                    },
                    onError: (error) => processMutationError(error),
                });
            } else {
                restApiPost({
                    uri: '/job/visit/cancel-by-manager',
                    params: {
                        visitId,
                        repeatingDay,
                        repeats,
                        sendNotifications: inputs.notifications.value,
                    } as CancelVisitByManagerInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading,
                    onData: (data: VisitResponse) => {
                        if (data) {
                            SuccessToast(tt('visitDetailMenu.cancelVisit.success'));

                            setOpen(false);

                            if (data.id) {
                                onUpdateVisitId({
                                    visitId: data.id,
                                    repeatingDay: repeatingDay!,
                                });
                            }
                        }
                    },
                    onError: (error) => processMutationError(error),
                });
            }
        }
    };

    const title = reActivate ? tt('visitDetail.activate.confirmModal.title')
        : tt('visitDetail.cancel.confirmModal.title');
    const subtitle = reActivate ? tt('visitDetail.activate.confirmModal.subtitle')
        : tt('visitDetail.cancel.confirmModal.subtitle');

    const confirmButtonColor = reActivate ? kAppColors.green.secondary : undefined;
    const confirmText = reActivate ? tt('visitDetail.activate.confirmModal.button.reactivate')
        : tt('common.cancel');

    return (
        <AppModal
            paperClassName={classes.modalPaper}
            title={title}
            subtitle={subtitle}
            open={open}
            centeredHeader={true}
            modalAboveModals={modalAboveModals}
            actions={(
                <>
                    <AppChip
                        sx={{
                            backgroundColor: `${confirmButtonColor || kAppColors.red.confirmButton} !important`,
                            ":hover": `${confirmButtonColor || kAppColors.red.confirmButton} !important`,
                        }}
                        className={classes.confirmChip}
                        key={'confirmButtonKey'}
                        onClick={onConfirm}
                        chipstyle={"primary"}
                        label={confirmText}
                        disabled={loading}
                    />

                    <Box key={'confirmPopupSpaceKey'} pr={2}/>

                    <AppChip
                        className={classes.chip}
                        key={'cancelButtonKey'}
                        onClick={(event) => {
                            setOpen(false);
                        }}
                        disabled={loading}
                        chipstyle={"secondary"}
                        label={tt('common.close')}
                    />
                </>
            )}
        >
            <FormBuilder inputs={inputs} setInputs={setInputs}/>
            <Box pb={2}/>
        </AppModal>
    );
}
