import {DateTime} from "luxon";
import {VisitRepeating} from "../generated/graphql/graphql";
import {Option} from "../ui/components/form/InputProps";
import {tt} from "../core/Localization";


export function FormatDateWithIfTodayModification(dateTime?: DateTime, millis?: number) {
    if (!dateTime && !millis) {
        return '';
    }

    const tempDateTime = dateTime || DateTime.fromMillis(millis!);
    const isToday = tempDateTime.startOf('day').toMillis() === DateTime.now().startOf('day').toMillis();

    return isToday ? `${tt('common.today')}, ${tempDateTime.toFormat('H:mm')}` : tempDateTime.toFormat('H:mm · d.M.yyyy');
}

/**
 * Convert date millis to formatted date.
 */
export function MillisToDate(millis: number | NullOrUndefined, weekday?: boolean, language?: string): string {
    if (millis) {
        if (weekday) {
            return DateTime.fromMillis(millis).toFormat('EEEE d.L.y', {locale: language});
        }

        return DateTime.fromMillis(millis).toFormat('d.L.y');
    }

    return '';
}

/**
 * Wait for set amount of millis.
 */
export function WaitFor(millis: number): Promise<void> {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, millis);
    });
}

/**
 * Add interval to DateTime by VisitRepeating and every.
 */
export function DateTimePlusVisitRepeating(
    date: DateTime,
    repeating: VisitRepeating,
    every: number,
): DateTime {
    const theHour = date.hour;
    const theMinute = date.minute;
    const theSecond = date.second;

    let theDateTime: DateTime;

    /*DateTime addVisitRepeating(VisitRepeating repeating, int every) {
        final theHour = hour;
        final theMinute = minute;
        final theSecond = second;

        DateTime theDateTime;

        switch (repeating) {
            case VisitRepeating.daily:
                theDateTime = DateTime(year, month, day + every, 12, minute, second, 0, 0);
                break;
            case VisitRepeating.weekly:
                theDateTime = DateTime(year, month, day + (every * 7), 12, minute, second, 0, 0);
                break;
            case VisitRepeating.monthly:
                theDateTime = DateTime(year, month + every, day, 12, minute, second, 0, 0);
                break;
            case VisitRepeating.yearly:
                theDateTime = DateTime(year + every, month, day, 12, minute, second, 0, 0);
                break;
            case VisitRepeating.artemisUnknown:
            case VisitRepeating.none:
            default:
                throw Exception('Unknown interval');
        }

        return theDateTime.copyWith(
            hour: theHour,
            minute: theMinute,
            second: theSecond,
        );
    }*/

    switch (repeating) {
        case VisitRepeating.Daily:
            theDateTime = date.set({hour: 12, millisecond: 0})
                .plus({day: every || 1});
            break;
        case VisitRepeating.Weekly:
            theDateTime = date.set({hour: 12, millisecond: 0})
                .plus({day: 7 * (every || 1)});
            break;
        case VisitRepeating.Monthly:
            theDateTime = date.set({hour: 12, millisecond: 0})
                .plus({month: every || 1});
            break;
        case VisitRepeating.Yearly:
            theDateTime = date.set({hour: 12, millisecond: 0})
                .plus({year: every || 1});
            break;
        default:
            return date;
    }

    return theDateTime.set({
        hour: theHour,
        minute: theMinute,
        second: theSecond,
    });
}

export interface IDayOfWeekPositionInMonth {
    dayOfWeek: number;
    positionInMonth: number;
}

/**
 * Get day of week and position in month from DateTime.
 * Position is e.g. first or second wednesday of month.
 */
export function GetDayOfWeekPositionInMonth(date: DateTime): IDayOfWeekPositionInMonth {
    const dayOfWeek = date.weekday;
    const positionInMonth = Math.floor((date.day - 1) / 7) + 1;

    return {dayOfWeek, positionInMonth};
}

/**
 * Check if DateTime can be set to IDayOfWeekPositionInMonth.
 * E.g. 31.1.2021 is not valid for 5th sunday of month.
 */
export function IsDayOfWeekPositionInMonthValid(date: DateTime, dayOfWeekPositionInMonth: IDayOfWeekPositionInMonth): boolean {
    const daysInMonth = date.daysInMonth!;

    const dateCopy = DateTime.fromMillis(date.toMillis());

    for (let i = 1; i <= daysInMonth; i++) {
        const day = dateCopy.set({day: i});

        if (day.weekday === dayOfWeekPositionInMonth.dayOfWeek && GetDayOfWeekPositionInMonth(day).positionInMonth === dayOfWeekPositionInMonth.positionInMonth) {
            return true;
        }
    }

    return false;
}

/**
 * Set DateTime to IDayOfWeekPositionInMonth.
 * Do not validate here, use first IsDayOfWeekPositionInMonthValid for validation when using this function.
 */
export function SetDayOfWeekPositionInMonth(date: DateTime, dayOfWeekPositionInMonth: IDayOfWeekPositionInMonth): DateTime {
    const daysInMonth = date.daysInMonth!;

    const dateCopy = DateTime.fromMillis(date.toMillis());

    for (let i = 1; i <= daysInMonth; i++) {
        const day = dateCopy.set({day: i});

        if (day.weekday === dayOfWeekPositionInMonth.dayOfWeek && GetDayOfWeekPositionInMonth(day).positionInMonth === dayOfWeekPositionInMonth.positionInMonth) {
            return day;
        }
    }

    return date;
}

/**
 * Set DateTime to last weekday of month.
 */
export function SetLastDayOfWeekInMonth(date: DateTime, dayOfWeek: number): DateTime {
    const daysInMonth = date.daysInMonth!;

    const dateCopy = DateTime.fromMillis(date.toMillis());

    for (let i = daysInMonth; i >= 1; i--) {
        const day = dateCopy.set({day: i});

        if (day.weekday === dayOfWeek) {
            return day;
        }
    }

    return date;
}

/**
 * Check if two pairs of DateTime are overlapping.
 */
export function isOverlapping(start1: DateTime, end1: DateTime, start2: DateTime, end2: DateTime): boolean {
    return start1 <= end2 && start2 <= end1;
}

/**
 * Using luxon create weekday options array.
 * Dates are formatted with 2 short letters.
 */
export function CreateWeekdayOptions(language?: string): Option[] {
    const options: Option[] = [];

    for (let i = 1; i <= 7; i++) {
        options.push({
            label: DateTime.fromObject({weekday: i as any}).toFormat('EEE', {locale: language}).toUpperCase(),
            value: i.toString()
        });
    }

    return options;
}

/**
 * Using luxon create list of Options for 4 weeks.
 */
export function Create4WeeksOptions(language?: string): Option[] {
    const options: Option[] = [];

    for (let i = 1; i <= 4; i++) {
        for (let j = 1; j <= 7; j++) {
            options.push({
                label: DateTime.fromObject({weekday: j as any}).toFormat('EEE', {locale: language}).toUpperCase(),
                value: `${i}-${j}`,
            });
        }
    }

    return options;
}

export function CreateMonthDayPickerOptions(): Option[] {
    const options: Option[] = [];

    for (let i = 1; i <= 31; i++) {
        options.push({
            label: i.toString(),
            value: i.toString()
        });
    }

    options.push({
        label: tt('monthDayPicker.label.lastDay'),
        value: "-1"
    });

    return options;
}
