import {ClientJoinedLocationsResponse, ClientPureResponse, ClientResponse} from "../generated/graphql/graphql";
import {tt} from "../core/Localization";
import {addressToSingleLine} from "../utils/AddressUtils";

export function displayClientTaxInfoAsText(params: {
    client: ClientResponse | ClientPureResponse | ClientJoinedLocationsResponse | undefined;
    ignoreTaxId?: boolean;
    displayBillingAddress?: boolean;
}): string {
    const {client, ignoreTaxId, displayBillingAddress} = params;

    const parts = [];

    if (client?.billingAddress?.businessId) {
        parts.push(tt('common.companyIdLabel').replace('$companyId', client?.billingAddress?.businessId));
    }

    if (client?.billingAddress?.taxId && !ignoreTaxId) {
        parts.push(tt('common.companyTaxIdLabel').replace('$taxId', client?.billingAddress?.taxId));
    }

    if (client?.billingAddress && displayBillingAddress) {
        parts.push(addressToSingleLine(client.billingAddress));
    }

    return parts.join(' · ').trim();
}
