import React, {useContext, useEffect, useId, useState} from "react";
import {AppContext} from "../../../App";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import AppIconButton from "../../components/buttons/AppIconButton";
import PencilIcon from "../../../icons/PencilIcon";
import {Box, Divider, Theme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {useNavigate, useParams} from "react-router-dom";
import {AppDataContext} from "../../../AppData";
import DetailScreenShimmer from "../../components/shimmers/DetailScreenShimmer";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import MoreFilledIcon from "../../../icons/MoreFilledIcon";
import {usePopupState} from "material-ui-popup-state/hooks";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "../../../icons/DeleteIcon";
import Menu from "@mui/material/Menu";
import DetailColumnItem from "../../screenSections/userStatussRow/DetailColumnItem";
import {HideConfirmModal, SetConfirmModal, SetConfirmModalLoading} from "../../components/modals/AppModals";
import AppListItem from "../../components/listItems/AppListItem";
import {getBackRoute} from "../../../utils/Utils";
import Icons8Layers from "../../../icons/Icons8Layers";
import {kMaterialsRoute} from "./MaterialsListScreen";
import {materialEditRoute} from "./MaterialEditScreen";
import {DeleteMaterialTemplateInput, GetMaterialInput, MaterialResponse} from "../../../generated/graphql/graphql";
import {processMutationError, processQueryError} from "../../../service/ErrorService";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import {SuccessToast} from "../../../service/ToastService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import {kActionDelete, kActionUpdate, kPermissionsMaterials, kTopicMaterialTemplates} from "../../../core/constants";
import PermissionValid from "../../components/permissions/PermissionValid";

export const kMaterialDetailRoute = '/materials/:materialId';

export function materialDetailRoute(materialId: string | number): string {
    return kMaterialDetailRoute.replace(':materialId', `${materialId}`)
}

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: "flex",
        overflowX: "auto",
        alignItems: "start",
    },
}));

export default function MaterialDetailScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe, restApiDelete} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);

    const navigate = useNavigate();
    const {materialId} = useParams();

    useEffect(() => {
        setTitle(tt('materialDetail.screen.title'));
    }, []);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<MaterialResponse | NullOrUndefined>();
    useEffect(() => {
        const subscription = subscribe(
            kTopicMaterialTemplates,
            {
                uri: '/material',
                params: {
                    materialId: parseInt(materialId!),
                } as GetMaterialInput,
                setLoading,
                onData: setData,
                onError: (error: any) => processQueryError(appDataContext, error),
            },
            (notification: IEventSystemNotification[]) => {
                const isDeleted = notification.some(n => n.action === kActionDelete && n.data.id === parseInt(materialId!));

                if (isDeleted) {
                    navigate(getBackRoute(kMaterialsRoute));
                }

                return !isDeleted;
            },
        );

        return () => {
            subscription.cancel();
        };
    }, [materialId]);

    /**
     * Show confirm modal and if confirmed delete Material on BE.
     */
    const DeleteMaterial = async () => {
        SetConfirmModal(appDataContext, {
            open: true,
            title: tt('deleteMaterial.popup.title'),
            subtitle: tt('deleteMaterial.popup.description'),
            cancelButtonText: tt('common.close'),
            confirmationButtonText: tt('deleteMaterial.popup.confirm'),
            onConfirm: () => {
                SetConfirmModalLoading(appDataContext, true);

                restApiDelete({
                    uri: '/material/template',
                    params: {
                        materialId: parseInt(materialId!),
                    } as DeleteMaterialTemplateInput,
                    fetchPolicy: FetchPolicy.NetworkOnly,
                    setLoading: (loading: boolean) => SetConfirmModalLoading(appDataContext, loading),
                    onData: (data: MaterialResponse) => {
                        if (data) {
                            SuccessToast(tt('deleteMaterial.toast.success'));

                            HideConfirmModal(appDataContext);
                        }
                    },
                    onError: (error) => processMutationError(error),
                });
            },
        });
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                loading={loading}
                data={data}
                isMobile={isMobile}
                deleteMaterial={DeleteMaterial}
            />
        );
    }

    return (
        <>
            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}
            />
        </>
    );
}

interface IBodyProps {
    loading: boolean;
    data: MaterialResponse | NullOrUndefined;
    isMobile?: boolean;
    deleteMaterial: VoidFunction;
}

function Body(props: IBodyProps) {
    const {
        loading,
        data,
        isMobile,
        deleteMaterial,
    } = props;

    const navigate = useNavigate();

    const {classes, cx} = useStyles();

    const contentJSX = loading && !data ? <DetailScreenShimmer/> : (
        <>
            <AppListItem
                title={data?.name || ''}
                customAvatarInCircle={<Icons8Layers/>}
                actionWidget={<></>}
            />

            <Box pb={1}/>

            {data?.unitName ?
                <>
                    <Divider/>

                    <Box className={cx('styledScrollbar', classes.container)}>
                        <DetailColumnItem
                            title={tt('materialDetail.screen.label.unit')}
                            description={data?.unitName || ''}
                        />
                    </Box>
                </> : null}
        </>
    );

    const materialPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}
            >
                <PaperAppbar
                    backRoute={getBackRoute(kMaterialsRoute)}
                    isMobile={isMobile}
                    title={tt('materialDetail.screen.title')}
                >
                    <PermissionValid
                        permission={kPermissionsMaterials}
                        requiredPermissions={[kActionUpdate]}
                    >
                        <AppIconButton
                            key={'materialDetailEditBtnKey'}
                            onClick={() => {
                                navigate(materialEditRoute(data?.id.toString() ?? 0));
                            }
                            }>
                            <PencilIcon/>
                        </AppIconButton>
                    </PermissionValid>

                    <PermissionValid
                        permission={kPermissionsMaterials}
                        requiredPermissions={[kActionDelete]}
                    >
                        <AppIconButton
                            key={'moreOptionsBtn'}
                            {...bindTrigger(materialPopupState)}
                        >
                            <MoreFilledIcon/>
                        </AppIconButton>
                    </PermissionValid>
                </PaperAppbar>

                <Menu {...bindMenu(materialPopupState)}>
                    <PermissionValid
                        permission={kPermissionsMaterials}
                        requiredPermissions={[kActionDelete]}
                    >
                        <MenuItem key={'deleteProductItem'} onClick={() => {
                            materialPopupState.close();

                            deleteMaterial();
                        }}>
                            <Box color={kAppColors.red.confirmButton}><DeleteIcon/></Box>
                            {tt('common.delete')}
                        </MenuItem>
                    </PermissionValid>
                </Menu>

                {contentJSX}
            </AppPaper>
        </ScreenContent>
    );
}
