import {
    collection, getCountFromServer,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    query,
    QuerySnapshot,
    Unsubscribe, where
} from "firebase/firestore";
import IInfo, {MessageSeverity} from "../model/firestore/Info";
import packageJson from "../../package.json";
import {AlertColor} from "@mui/material/Alert/Alert";
import {IAppContext} from "../App";

export const kInfosCollection = 'infos';

export const kInfosPageSize = 10;

/**
 * Create subscription to infos collection.
 */
export function subscribeInfos(onNext: (snapshot: QuerySnapshot) => void, loadPages: number): Unsubscribe {
    const firestore = getFirestore();
    const theCollection = collection(firestore, kInfosCollection);

    const theQuery = query(
        theCollection,
        orderBy('createdAt', 'desc'),
        where('enabled', '==', true),
        limit(kInfosPageSize * loadPages),
    );

    return onSnapshot(theQuery, onNext);
}

/**
 * Count number of infos in Firestore.
 */
export async function countInfos(): Promise<number> {
    const firestore = getFirestore();
    const theCollection = collection(firestore, kInfosCollection);

    const snapshot = await getCountFromServer(
        theCollection,
    );

    return snapshot.data().count;
}

/**
 * Process Infos to display based on conditions.
 */
export function processInfos(appContext: IAppContext, infos: IInfo[]): IInfo[] {
    const version = packageJson.version;
    const isDebug = process.env.NODE_ENV === 'development';
    const now = Date.now();

    return infos.filter((info) => {
        if (info.appVersions && info.appVersions.length > 0 && !info.appVersions.includes(version)) {
            return false;
        }

        if (info.debugOnly && !isDebug) {
            return false;
        }

        if (info.authUsersOnly && !appContext.authUser) {
            return false;
        }

        if (info.from && info.from > now) {
            return false;
        }

        if (info.to && info.to < now) {
            return false;
        }

        return info.enabled;
    });
}

/**
 * Convert Info MessageSeverity to AlertColor.
 */
export function infoSeverityToAlertColor(severity?: MessageSeverity | NullOrUndefined): AlertColor {
    if (!severity) {
        return 'info';
    }

    switch (severity) {
        case MessageSeverity.Warning:
            return 'warning';
        case MessageSeverity.Error:
            return 'error';
        case MessageSeverity.Success:
            return 'success';
        case MessageSeverity.Info:
        default:
            return 'info';
    }
}
