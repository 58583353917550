import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import AppModal from "../modals/AppModal";
import {tt} from "../../../core/Localization";
import MaterialForm, {IMaterialFormUpdate} from "./MaterialForm";
import {CreateMaterialTemplateInput, MaterialResponse} from "../../../generated/graphql/graphql";
import {AppDataContext} from "../../../AppData";
import {useNavigate} from "react-router-dom";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import {SuccessToast} from "../../../service/ToastService";
import {processMutationError} from "../../../service/ErrorService";
import {materialDetailRoute} from "../../screens/materials/MaterialDetailScreen";

export interface ICreateMaterialModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    navigation?: boolean;
    modalAboveModals?: boolean;
    name?: string;
    onCreated?: (input: CreateMaterialTemplateInput) => void;
}

export default function CreateMaterialTemplateModal(props: ICreateMaterialModalProps) {
    const {
        open,
        setOpen,
        navigation,
        modalAboveModals,
        name,
        onCreated,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiPost} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const navigate = useNavigate();
    const theNavigation = navigation === undefined ? true : navigation;

    const [resetFormTrigger, setResetFormTrigger] = useState(0);

    const [loadingCreate, setLoadingCreate] = useState(false);
    /**
     * Create new Material template on BE.
     */
    const createMaterial = (update: IMaterialFormUpdate, createAnother?: boolean) => {
        setLoadingCreate(true);

        const input = {
            companyId: companyId,
            name: update.inputs.name.value,
            unitName: update.inputs.unitOfMeasure.value,
        } as CreateMaterialTemplateInput;

        restApiPost({
            uri: '/material/template',
            params: input,
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading: setLoadingCreate,
            onData: (data: MaterialResponse) => {
                if (data) {
                    if (!createAnother) {
                        setOpen(false);
                    }

                    SuccessToast(tt('createMaterial.toast.success'));

                    if (onCreated) {
                        onCreated(input);
                    }

                    if (theNavigation && !createAnother) {
                        navigate(materialDetailRoute(data.id));
                    } else {
                        setResetFormTrigger(resetFormTrigger + 1);
                    }
                }
            },
            onError: (error) => processMutationError(error),
        });
    };

    return (
        <AppModal
            setOpen={setOpen}
            title={tt('createMaterial.modal.title')}
            open={open}
            fullScreenOnMobile={true}
            blurBackdrop={true}
            modalAboveModals={modalAboveModals}
        >
            <MaterialForm
                onSubmit={createMaterial}
                submitRight={true}
                updateLoading={loadingCreate}
                name={name}
                createAnotherAble={true}
                resetFormTrigger={resetFormTrigger}
                isModal={true}
            />
        </AppModal>
    );
}
