import React, {useContext, useId} from "react";
import AppIconButton from "../buttons/AppIconButton";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MoreFilledIcon from "../../../icons/MoreFilledIcon";
import {usePopupState} from "material-ui-popup-state/hooks";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {tt} from "../../../core/Localization";
import {Box, Theme} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import CloseIcon from "../../../icons/CloseIcon";
import {AppDataContext} from "../../../AppData";
import PencilIcon from "../../../icons/PencilIcon";
import AppListItem from "../listItems/AppListItem";
import Icons8Layers from "../../../icons/Icons8Layers";
import {makeStyles} from "tss-react/mui";
import AppChip from "../chips/AppChip";
import {EmployeeJoinedUserResponse, FileResponse} from "../../../generated/graphql/graphql";
import {unitNameAndCountText} from "../../../service/VisitService";
import AppAvatar from "../AppAvatar";
import {UserFullName, UserPhotoUrl} from "../../../service/UserService";
import PermissionValid from "../permissions/PermissionValid";
import {kActionDelete, kActionUpdate, kPermissionsMaterials} from "../../../core/constants";


const useStyles = makeStyles()((theme: Theme) => ({
    chipsContainer: {
        marginTop: -12,
        paddingLeft: 44,
        display: 'flex',
        flexWrap: "wrap",
        '.MuiChip-root': {
            marginRight: 4,
            marginBottom: 4,
        }
    }
}));

interface IMaterialListItemsProps {
    isNewJobCreation?: boolean;
    data: {
        index: number;
        name: string;
        unitName?: string | NullOrUndefined;
        unitCount?: number | NullOrUndefined;
        employees?: EmployeeJoinedUserResponse[];
    };
    files?: FileResponse[] | NullOrUndefined;
    onUpdate?: () => void;
    onDelete: (index: number) => void;
    isVisitDetail?: boolean;
    canEdit?: boolean;
}

export default function MaterialListItem(props: IMaterialListItemsProps) {
    const {isNewJobCreation, data, files, onUpdate, onDelete, isVisitDetail, canEdit} = props;

    const {classes} = useStyles();

    const appDataContext = useContext(AppDataContext);
    const {language, storage} = appDataContext;

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    return (
        <>
            <AppListItem
                customAvatar={<Icons8Layers/>}
                title={data.name}
                variant={"smaller-title"}
                actionWidget={canEdit ? (
                    <PermissionValid
                        permission={kPermissionsMaterials}
                        requiredPermissions={[kActionUpdate, kActionDelete]}
                        anyRequiredPermission={true}
                        forceValid={isNewJobCreation}
                    >
                        <AppIconButton
                            variant={"greyBg"}
                            placement={"right"}
                            {...bindTrigger(settingsPopupState)}
                        >
                            <MoreFilledIcon/>
                        </AppIconButton>
                    </PermissionValid>
                ) : <></>}
                belowListItemWidget={
                    <Box className={classes.chipsContainer}>
                        {data.unitCount ? (
                            <Box pr={0.5}>
                                <AppChip
                                    chipstyle={"outlined"}
                                    label={unitNameAndCountText(data.unitName, data.unitCount, language)}
                                />
                            </Box>
                        ) : null}

                        {data.employees ? (
                            <AppChip
                                chipstyle={"outlined"}
                                icon={<>
                                    {data.employees.map((employee, index) => <Box
                                        key={employee.id}
                                        pr={index < data.employees!.length - 1 ? 0.5 : 0}
                                    >
                                        <AppAvatar
                                            title={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                                            variant={"tiny"}
                                            tooltip={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                                            img={
                                                UserPhotoUrl(employee?.user, files, storage.publicUrlsForFiles)
                                            }
                                        />
                                    </Box>)}
                                </>}
                            />
                        ) : null}
                    </Box>
                }
            />

            <Menu {...bindMenu(settingsPopupState)}>
                <PermissionValid
                    permission={kPermissionsMaterials}
                    requiredPermissions={[kActionUpdate]}
                    forceValid={isNewJobCreation}
                >
                    <MenuItem key={'materialEditKey' + data.index} onClick={() => {
                        settingsPopupState.close();
                        if (onUpdate) {
                            onUpdate();
                        }
                    }}>
                        <PencilIcon/>
                        {tt('common.edit')}
                    </MenuItem>
                </PermissionValid>

                {!isVisitDetail ? (
                    <PermissionValid
                        permission={kPermissionsMaterials}
                        requiredPermissions={[kActionDelete]}
                        forceValid={isNewJobCreation}
                    >
                        <MenuItem key={'materialDeleteKey' + data.index} onClick={() => {
                            settingsPopupState.close();
                            onDelete(data.index);
                        }}>
                            <Box sx={{color: kAppColors.red.confirmButton}}><CloseIcon/></Box>
                            {tt('common.removeFromList')}
                        </MenuItem>
                    </PermissionValid>
                ) : null}
            </Menu>
        </>
    );
}
