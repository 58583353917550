import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8InTransitProps {
    className?: string;
}

export default function Icons8InTransit(props: IIcons8InTransitProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 3.5 10 A 1.50015 1.50015 0 1 0 3.5 13 L 18.5 13 A 1.50015 1.50015 0 1 0 18.5 10 L 3.5 10 z M 24.5 10 A 1.50015 1.50015 0 1 0 24.5 13 L 27.5 13 C 28.346499 13 29 13.653501 29 14.5 L 29 33 L 17.785156 33 C 17.125763 30.701587 14.999736 29 12.5 29 C 10.000264 29 7.8742368 30.701587 7.2148438 33 L 6.5 33 C 5.6535009 33 5 32.346499 5 31.5 L 5 29.5 A 1.50015 1.50015 0 0 0 3.4765625 27.978516 A 1.50015 1.50015 0 0 0 2 29.5 L 2 31.5 C 2 33.967501 4.0324991 36 6.5 36 L 7.2148438 36 C 7.8742368 38.298413 10.000264 40 12.5 40 C 14.999736 40 17.125763 38.298413 17.785156 36 L 30.214844 36 C 30.874237 38.298413 33.000264 40 35.5 40 C 37.999736 40 40.125763 38.298413 40.785156 36 L 41.5 36 C 43.967501 36 46 33.967501 46 31.5 L 46 25.136719 C 46 24.505308 45.866775 23.885098 45.613281 23.3125 A 1.50015 1.50015 0 0 0 45.611328 23.310547 L 42.662109 16.673828 L 42.662109 16.671875 C 41.941923 15.05033 40.324799 14 38.550781 14 L 31.949219 14 C 31.694299 11.766623 29.796595 10 27.5 10 L 24.5 10 z M 3.5 16 A 1.50015 1.50015 0 1 0 3.5 19 L 14.5 19 A 1.50015 1.50015 0 1 0 14.5 16 L 3.5 16 z M 32 17 L 38.550781 17 C 39.148248 17 39.677843 17.345067 39.919922 17.890625 L 41.746094 22 L 32 22 L 32 17 z M 3.5 22 A 1.50015 1.50015 0 1 0 3.5 25 L 10.5 25 A 1.50015 1.50015 0 1 0 10.5 22 L 3.5 22 z M 32 25 L 42.986328 25 C 42.990597 25.045531 43 25.091276 43 25.136719 L 43 31.5 C 43 32.346499 42.346499 33 41.5 33 L 40.785156 33 C 40.125763 30.701587 37.999736 29 35.5 29 C 34.174012 29 32.953662 29.479254 32 30.271484 L 32 25 z M 12.5 32 C 13.848747 32 14.91448 33.032799 14.986328 34.361328 A 1.50015 1.50015 0 0 0 14.986328 34.638672 C 14.91448 35.967201 13.848747 37 12.5 37 C 11.151253 37 10.08552 35.967201 10.013672 34.638672 A 1.50015 1.50015 0 0 0 10.013672 34.361328 C 10.08552 33.032799 11.151253 32 12.5 32 z M 35.5 32 C 36.896406 32 38 33.103594 38 34.5 C 38 35.896406 36.896406 37 35.5 37 C 34.103594 37 33 35.896406 33 34.5 C 33 33.103594 34.103594 32 35.5 32 z"/>
            </svg>
        </span>
    );
}
