import {iconUseStyles} from "../styles/IconStyles";

export interface IElectricityIconIconProps {
    className?: string;
}

export default function ElectricityIconIcon(props: IElectricityIconIconProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px"
                 preserveAspectRatio="xMidYMid meet" focusable="false">
                <path
                    d="M29.83,6l-4.3,7.51L23,18h9.82L25.56,30.5,23,35H28.3L18.1,41.36,14.92,35h4l.87-1.5,4.63-8L27,21H17.17L25.74,6h4.09m.47-3H25.58a2.73,2.73,0,0,0-2.37,1.37L14.33,19.92A2.72,2.72,0,0,0,16.69,24h5.1l-4.64,8H12.5a1.5,1.5,0,0,0-1.35,2.17l5,10a1.49,1.49,0,0,0,.93.77,1.45,1.45,0,0,0,.41.06,1.48,1.48,0,0,0,.79-.23l16.06-10A1.5,1.5,0,0,0,33.55,32h-5.4l7.48-12.91A2.73,2.73,0,0,0,33.27,15H28.14l4.53-7.92A2.73,2.73,0,0,0,30.3,3Z"></path>
            </svg>
        </span>
    );
}









