import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Fade, Modal, Theme} from "@mui/material";
import {DatePicker, DatesRangeValue} from "@mantine/dates";
import AppPaper from "../paper/AppPaper";
import AppButton from "../buttons/AppButton";
import {tt} from "../../../core/Localization";
import {AppDataContext} from "../../../AppData";
import {kAppColors} from "../../../styles/AppThemeProcessor";

export const useStyles = makeStyles()((theme: Theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '.MuiBackdrop-root': {
            background: 'transparent',
        },
    },
    modalAboveModals: {
        zIndex: '1301 !important',
    },
    paper: {
        padding: 4,
        border: `1px solid ${kAppColors.border(theme.palette.mode === "dark")}`
    },
    button: {
        display: "flex",
        alignItems: "center",
        marginLeft: "auto",
    },
    calendar: {
        '.mantine-DatePicker-day[data-in-range]': {
            background: '#ff572269',
        },
        '.mantine-DatePicker-day[data-selected]': {
            background: kAppColors.primary.main,
        }
    }
}));

export interface Props {
    value?: DatesRangeValue;
    setRangeVal: Dispatch<SetStateAction<DatesRangeValue | undefined>>;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    modalAboveModals?: boolean;
    anchorRef: React.MutableRefObject<HTMLDivElement | null>;
}

export default function DateRangeModal(props: Props) {
    const {
        open,
        modalAboveModals,
        setOpen,
        value,
        setRangeVal,
        anchorRef
    } = props;

    const {classes, cx} = useStyles();

    const modalClass = cx(
        classes.modal,
        modalAboveModals ? classes.modalAboveModals : undefined,
    );

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const [pickerOffset, setPickerOffset] = useState<Array<number>>([0, 0])
    const [datePickerInnerRangeValue, setDatePickerInnerRangeValue] = useState<DatesRangeValue | undefined>(value);


    function setPosition() {
        const posY = (anchorRef?.current?.clientHeight || 0) + (anchorRef?.current?.offsetTop || 0) + 8;
        const posX = anchorRef?.current?.offsetLeft || 0;
        setPickerOffset([posX, posY]);
    }

    useEffect(() => {
            setPosition();
        },
        [open]);

    return (
        <Modal onClose={() => {
            setOpen(false)
        }} className={modalClass} open={open}>
            <Fade in={open}>
                <Box
                    style={{
                        position: 'fixed',
                        zIndex: 1301,
                        top: pickerOffset[1],
                        left: pickerOffset[0]
                    }}
                >
                    <AppPaper className={classes.paper}>
                        <DatePicker
                            className={classes.calendar}
                            locale={language}
                            type={"range"}
                            value={datePickerInnerRangeValue}
                            onChange={(val) => {
                                setDatePickerInnerRangeValue(val);
                            }}
                        />
                        <Box pb={1}/>
                        <AppButton
                            className={classes.button}
                            variant={"textThin"}
                            onClick={event => {
                                setRangeVal(datePickerInnerRangeValue);
                                setOpen(false);
                            }}
                        >{tt('common.ok')}</AppButton>
                    </AppPaper>
                </Box>
            </Fade>
        </Modal>
    );
}
