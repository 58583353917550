import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8CloneFigureProps {
    className?: string;
}

export default function Icons8CloneFigure(props: IIcons8CloneFigureProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 15.5 6 C 10.253 6 6 10.253 6 15.5 L 6 32.859375 C 6 34.196375 6.66825 35.4455 7.78125 36.1875 L 9 37 L 9 15.5 C 9 11.91 11.91 9 15.5 9 L 37 9 L 36.1875 7.78125 C 35.4465 6.66825 34.197375 6 32.859375 6 L 15.5 6 z M 16.5 11 C 13.480226 11 11 13.480226 11 16.5 L 11 36.5 C 11 39.519774 13.480226 42 16.5 42 L 36.5 42 C 39.519774 42 42 39.519774 42 36.5 L 42 16.5 C 42 13.480226 39.519774 11 36.5 11 L 16.5 11 z M 16.5 14 L 36.5 14 C 37.898226 14 39 15.101774 39 16.5 L 39 36.5 C 39 37.898226 37.898226 39 36.5 39 L 16.5 39 C 15.101774 39 14 37.898226 14 36.5 L 14 16.5 C 14 15.101774 15.101774 14 16.5 14 z"/>
            </svg>
        </span>
    );
}



