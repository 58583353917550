import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8ClipBoardListProps {
    className?: string;
}

export default function Icons8ClipBoardList(props: IIcons8ClipBoardListProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg id="icons8-clipboard-list" xmlns="http://www.w3.org/2000/svg" width="18" height="22.5"
                 viewBox="0 0 18 22.5">
                <path fill="currentColor" id="icons8-clipboard-list-2" data-name="icons8-clipboard-list"
                      d="M15.031,4a2.537,2.537,0,0,0-2.5,2.25h-2A2.544,2.544,0,0,0,8,8.781V23.969A2.544,2.544,0,0,0,10.531,26.5H23.469A2.544,2.544,0,0,0,26,23.969V8.781A2.544,2.544,0,0,0,23.469,6.25h-2A2.537,2.537,0,0,0,18.969,4Zm0,1.688h3.938a.844.844,0,0,1,0,1.688H15.031a.844.844,0,0,1,0-1.687Zm-4.5,2.25h2.4a2.537,2.537,0,0,0,2.1,1.125h3.938a2.537,2.537,0,0,0,2.1-1.125h2.4a.831.831,0,0,1,.844.844V23.969a.831.831,0,0,1-.844.844H10.531a.831.831,0,0,1-.844-.844V8.781A.831.831,0,0,1,10.531,7.938ZM12.781,13a.844.844,0,1,0,.844.844A.844.844,0,0,0,12.781,13Zm3.375,0a.844.844,0,1,0,0,1.688h5.063a.844.844,0,1,0,0-1.688Zm-3.375,3.375a.844.844,0,1,0,.844.844A.844.844,0,0,0,12.781,16.375Zm3.375,0a.844.844,0,1,0,0,1.688h5.063a.844.844,0,1,0,0-1.688ZM12.781,19.75a.844.844,0,1,0,.844.844A.844.844,0,0,0,12.781,19.75Zm3.375,0a.844.844,0,1,0,0,1.688h5.063a.844.844,0,1,0,0-1.688Z"
                      transform="translate(-8 -4)"/>
            </svg>
        </span>
    );
}

