import { kActionUpdate, kPermissionsTimesheetApprove, kPermissionsTimesheetApproveAllRoles } from "../core/constants";
import {EmployeeRole} from "../generated/graphql/graphql";
import { hasPermission } from "../ui/components/permissions/PermissionValid";

/**
 * Check if current User/Employee can Write Employee by Role cascade.
 */
export function userEmployeeCanWriteEmployeeByRoleCascade(
    role: EmployeeRole,
    targetRole: EmployeeRole,
): boolean {
    switch (role) {
        case EmployeeRole.Admin:
            return true;
        case EmployeeRole.Manager:
            return targetRole === EmployeeRole.Manager || targetRole === EmployeeRole.HeadOfWorkers || targetRole === EmployeeRole.Worker;
        case EmployeeRole.HeadOfWorkers:
            return targetRole === EmployeeRole.HeadOfWorkers || targetRole === EmployeeRole.Worker;
        default:
            return false;
    }
}

/**
 * Check if User can approve by Permissions or by Role cascade.
 */
export function userCanApproveByRoleCascade(
    role: EmployeeRole | NullOrUndefined,
    targetRole: EmployeeRole | NullOrUndefined,
    employeePermissionsMap: Record<string, string[]> | NullOrUndefined,
): boolean {
    let canApprove = false;
    if (hasPermission(kPermissionsTimesheetApprove, [kActionUpdate], employeePermissionsMap)) {
        if (hasPermission(kPermissionsTimesheetApproveAllRoles, [kActionUpdate], employeePermissionsMap)) {
            canApprove = true;
        } else if (role && targetRole) {
            switch (role) {
                case EmployeeRole.Admin:
                    canApprove = true;
                    break;
                case EmployeeRole.Manager:
                    canApprove = targetRole === EmployeeRole.Manager || targetRole === EmployeeRole.HeadOfWorkers || targetRole === EmployeeRole.Worker;
                    break;
                case EmployeeRole.HeadOfWorkers:
                    canApprove = targetRole === EmployeeRole.HeadOfWorkers || targetRole === EmployeeRole.Worker;
                    break;
                case EmployeeRole.Worker:
                    canApprove = targetRole === EmployeeRole.Worker;
                    break;
                default:
                    canApprove = false;
            }
        }
    }

    return canApprove;
}
