import React from "react";
import AppListItem, {IAppListItemProps} from "../../components/listItems/AppListItem";
import HeadlineWithButton, {IHeadlineWithButtonProps} from "./HeadlineWithButton";
import AppButton from "../../components/buttons/AppButton";
import {tt} from "../../../core/Localization";
import {Theme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";

export const useStyles = makeStyles()((theme: Theme) => ({
    viewAllButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
        marginBottom: 8,
        color: kAppColors.primary.main,
        lineHeight: 1,
        borderRadius: 8,
        ':hover': {
            backgroundColor: kAppColors.hoverColor.mainLighter,
        },
    },
}));

export interface IDetailListPreviewProps {
    headlineWidgetProps: IHeadlineWithButtonProps;
    itemProps?: IAppListItemProps;
    itemPropsMultiple?: IAppListItemProps[];
    totalItems?: number | NullOrUndefined;
    onViewAllClick?: VoidFunction;
}

export default function DetailListPreviewSection(props: IDetailListPreviewProps) {
    const {
        headlineWidgetProps,
        onViewAllClick,
        itemProps,
        itemPropsMultiple,
        totalItems,
    } = props;

    const {classes} = useStyles();

    const listItemJSX = itemProps ?
        <AppListItem
            variant={"smaller-title"}
            customAvatar={itemProps.customAvatar}
            title={itemProps.title}
            description={itemProps.description}
            onClick={itemProps.onClick}
        />
        : undefined;

    const listItemsJSX = itemPropsMultiple ? itemPropsMultiple.map(item => (
        <AppListItem
            key={item.listKey}
            variant={"smaller-title"}
            customAvatar={item.customAvatar}
            title={item.title}
            description={item.description}
            onClick={item.onClick}
        />
    )) : undefined;

    const viewMoreButtonJSX = onViewAllClick && (totalItems || 0) > 1 ?
        <AppButton
            className={classes.viewAllButton}
            onClick={onViewAllClick}
            variant={"textThin"}>
            {tt('common.viewAll')} ({totalItems})
        </AppButton> : undefined;

    return (
        <>
            <HeadlineWithButton
                {...headlineWidgetProps}
            />

            {listItemJSX}

            {listItemsJSX}

            {viewMoreButtonJSX}
        </>
    );
}
