import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import CustomStatusLikeChip from "../../chips/CustomStatusLikeChip";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import {tt} from "../../../../core/Localization";
import GreyLabel from "../../decorations/GreyLabel";
import AppChip from "../../chips/AppChip";
import React, {useContext, useMemo} from "react";
import IVisitEvent from "../../../../model/VisitEvent";
import {DateTime} from "luxon";
import {JobOfferSeatResponse} from "../../../../generated/graphql/graphql";
import {IVisitEventConflict, visitEventConflicts} from "../../../../service/VisitService";
import {AppDataContext} from "../../../../AppData";

const useStyles = makeStyles()((theme: Theme) => ({
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        paddingLeft: 70,
    },
    notAvailableSection: {
        paddingLeft: 70,
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
    },
    notAvailableChipsContainer: {
        display: "flex",
        flexWrap: "wrap",
    },
    chipMarginRight: {
        marginRight: 4,
        marginBottom: 4,
    },
    customChip: {
        marginRight: 4,
        marginBottom: 4,
        minWidth: 30,
        display: "flex",
        justifyContent: "center",
    },
    monthSectionContainer: {
        display: "flex",
        flexWrap: "wrap",
    }
}));

export interface INewJobWorkerConflictsSectionProps {
    employeeId: number;
    visitsForConflicts: IVisitEvent[] | NullOrUndefined;
    visitOfferSeatsForConflicts: JobOfferSeatResponse[] | NullOrUndefined;
    visitsNewEvents: IVisitEvent[] | NullOrUndefined;
}

export default function NewJobWorkerConflictsSection(props: INewJobWorkerConflictsSectionProps) {
    const {employeeId, visitsForConflicts, visitOfferSeatsForConflicts, visitsNewEvents} = props;

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const {classes} = useStyles();

    const conflictsJSX = useMemo(() => {
        if (visitsForConflicts && visitOfferSeatsForConflicts && visitsNewEvents) {
            const conflicts = visitEventConflicts({
                employeeId,
                visitsForConflicts,
                visitOfferSeatsForConflicts,
                visitsNewEvents,
            });

            if (conflicts.length > 0) {
                const first50Conflicts = conflicts.slice(0, 50);
                const groupedByYears: Record<number, IVisitEventConflict[]> = {};

                first50Conflicts.forEach(conflict => {
                    const year = conflict.date.year;
                    if (!groupedByYears[year]) {
                        groupedByYears[year] = [];
                    }
                    groupedByYears[year].push(conflict);
                });

                return (
                    <Box className={classes.notAvailableSection}>
                        <CustomStatusLikeChip
                            variant={"outlined"}
                            textColor={kAppColors.red.main}
                            text={tt('newVisit.screen.workerItem.chip.notAvailableInThisTime')}
                        />

                        <Box pb={2}/>

                        {Object.keys(groupedByYears).map((year) => {
                            const conflictsForYear = groupedByYears[parseInt(year)];
                            const groupedByMonths: Record<number, IVisitEventConflict[]> = {};

                            conflictsForYear.forEach(conflict => {
                                const month = conflict.date.month;
                                if (!groupedByMonths[month]) {
                                    groupedByMonths[month] = [];
                                }
                                groupedByMonths[month].push(conflict);
                            });

                            return (
                                <Box key={year}>
                                    <GreyLabel text={year}/>

                                    <Box pb={1}/>

                                    <Box className={classes.notAvailableChipsContainer}>
                                        {Object.keys(groupedByMonths).map((month) => {
                                            const conflictsForMonth = groupedByMonths[parseInt(month)]
                                                .sort((a, b) => a.date.day - b.date.day);
                                            const displayed: number[] = [];

                                            return (
                                                <Box className={classes.monthSectionContainer} key={`${year}-${month}`}>
                                                    <AppChip
                                                        className={classes.chipMarginRight}
                                                        label={DateTime.fromObject({month: parseInt(month)}).setLocale(language).monthLong}
                                                        chipstyle={"outlined"}
                                                    />

                                                    {conflictsForMonth.map(conflict => {
                                                        const dayOfMonth = conflict.date.day;
                                                        if (displayed.includes(dayOfMonth)) {
                                                            return null;
                                                        }
                                                        displayed.push(dayOfMonth);

                                                        return (
                                                            <CustomStatusLikeChip
                                                                key={`${year}-${month}-${dayOfMonth}-${conflict.visitId}-${conflict.repeatingDay}`}
                                                                className={classes.customChip}
                                                                variant={"outlined"}
                                                                textColor={kAppColors.red.main}
                                                                text={dayOfMonth}
                                                            />
                                                        );
                                                    })}
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                );
            }
        }

        return null;
    }, [employeeId, visitsForConflicts, visitOfferSeatsForConflicts, visitsNewEvents]);

    return (
        <>
            {conflictsJSX}
        </>
    );
}
