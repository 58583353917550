import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import AppModal from "../modals/AppModal";
import {tt} from "../../../core/Localization";
import {AppDataContext} from "../../../AppData";
import {useNavigate} from "react-router-dom";
import {processMutationError} from "../../../service/ErrorService";
import ProductForm, {IProductFormUpdate} from "./ProductForm";
import {
    CreateProductTemplateDocument,
    CreateProductTemplateInput,
    CreateProductTemplateMutation,
    CreateProductTemplateMutationVariables,
    ProductType
} from "../../../generated/graphql/graphql";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {SuccessToast} from "../../../service/ToastService";
import {productDetailRoute} from "../../screens/products/ProductDetailScreen";

export interface ICreateServiceModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    navigation?: boolean;
    modalAboveModals?: boolean;
    type?: ProductType;
    name?: string;
    onCreated?: (input: CreateProductTemplateInput) => void;
}

export default function CreateProductTemplateModal(props: ICreateServiceModalProps) {
    const {
        open,
        setOpen,
        navigation,
        modalAboveModals,
        type,
        name,
        onCreated,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const navigate = useNavigate();
    const theNavigation = navigation === undefined ? true : navigation;

    const [resetFormTrigger, setResetFormTrigger] = useState(0);

    const [mutateCreate, {
        loading,
    }] = useResettableMutation<CreateProductTemplateMutation, CreateProductTemplateMutationVariables>(CreateProductTemplateDocument);

    /**
     * Mutate Product to BE and close modal if success.
     */
    const CreateProduct = async (update: IProductFormUpdate, createAnother?: boolean) => {
        try {
            const variables: CreateProductTemplateMutationVariables = {
                input: {
                    companyId: companyId!,
                    type: update.inputs.type.value,
                    name: update.inputs.name.value,
                    description: update.inputs.description.value,
                    price: parseFloat(update.inputs.price.value || '0'),
                    vatRate: parseFloat(update.inputs.vatRate.value || '0'),
                    unitName: update.inputs.unitOfMeasure.value,
                    sortOrder: 0,
                },
            };

            const result = await mutateCreate({variables});

            if (!result.errors) {
                if (!createAnother) {
                    setOpen(false);
                }

                SuccessToast(tt('createProduct.modal.success'));

                if (onCreated) {
                    onCreated(variables.input);
                }

                if (theNavigation && !createAnother) {
                    navigate(productDetailRoute(result.data!.createProductTemplate!.id));
                } else {
                    setResetFormTrigger(resetFormTrigger + 1);
                }
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    return (
        <AppModal
            setOpen={setOpen}
            title={tt('createProduct.modal.title')}
            open={open}
            fullScreenOnMobile={true}
            blurBackdrop={true}
            modalAboveModals={modalAboveModals}
        >
            <ProductForm
                onSubmit={CreateProduct}
                submitRight={true}
                updateLoading={loading}
                type={type}
                name={name}
                createAnotherAble={true}
                resetFormTrigger={resetFormTrigger}
                isModal={true}
            />
        </AppModal>
    );
}
