import React, {useContext, useEffect, useState} from "react";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import {tt} from "../../../core/Localization";
import AppModal from "./AppModal";
import AppButton from "../buttons/AppButton";
import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material";
import {kContentWidthNarrow} from "../../../styles/AppThemeProcessor";
import {AppDataContext} from "../../../AppData";
import {SetJoinCompanyModal} from "./AppModals";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {
    JoinCompanyWithPasscodeDocument,
    JoinCompanyWithPasscodeMutation,
    JoinCompanyWithPasscodeMutationVariables
} from "../../../generated/graphql/graphql";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import {UserCompany, UserStartSwitchCompany} from "../../../service/UserService";
import {kDashboardRoute} from "../../screens/dashboard/DashboardScreen";
import {useNavigate} from "react-router-dom";
import {AuthUserUpdateCompanyIds} from "../../../service/AuthUserService";
import {AppContext} from "../../../App";

export const useStyles = makeStyles()((theme: Theme) => ({
    modalPaper: {
        maxWidth: kContentWidthNarrow,
    }
}));

export interface IJoinCompanyModalProps {
    open?: boolean;
    invalidateForm?: boolean;
}

export default function JoinCompanyModal(props: IJoinCompanyModalProps) {
    const {open, invalidateForm} = props;

    const appContext = useContext(AppContext);

    const appDataContext = useContext(AppDataContext);
    const {appModals, setCompanyId} = appDataContext;
    const joinCompany = appModals?.joinCompany;

    const navigate = useNavigate();

    const {classes} = useStyles();

    const [mutateJoin, {
        loading: joinLoading,
        error: joinError,
        data: joinData,
    }] = useResettableMutation<JoinCompanyWithPasscodeMutation, JoinCompanyWithPasscodeMutationVariables>(JoinCompanyWithPasscodeDocument);

    useEffect(() => {
        if (joinError) {
            console.error(joinError);
            ErrorToast(tt('common.mutation.error'));
        }
    }, [joinError]);

    const [inputs, setInputs] = useState<IInputsData>({
        passcode: {
            type: InputType.Text,
            label: `${tt('common.inviteKey')}*`,
            value: '',
            required: true,
        },
    });

    useEffect(() => {
        setInputs((prev) => {
            return {
                ...prev,
                passcode: {
                    ...prev.passcode,
                    value: '',
                },
            };
        });
    }, [invalidateForm]);

    if (!joinCompany) {
        return null;
    }

    /**
     * Close the modal.
     */
    const CloseModal = () => {
        const props: IJoinCompanyModalProps = {...joinCompany};
        props.open = false;
        props.invalidateForm = !props.invalidateForm;

        SetJoinCompanyModal(appDataContext, props);
    };

    /**
     * Mutate to BE passcode for joining Company.
     */
    const JoinCompany = async () => {
        if (ValidateForm(inputs, setInputs)) {
            try {
                const variables: JoinCompanyWithPasscodeMutationVariables = {
                    input: {
                        passcode: inputs.passcode.value,
                    },
                };

                const result = await mutateJoin({variables});

                if (!result.errors) {
                    const theData = result.data!.joinCompanyWithPasscode;

                    UserStartSwitchCompany(
                        appDataContext,
                        theData.companyId,
                        tt('switching.to.company.join'),
                    );

                    AuthUserUpdateCompanyIds(appContext, appDataContext, {add: theData.companyId});

                    CloseModal();

                    SuccessToast(tt('joinCompanyModal.success'));

                    setTimeout(() => {
                        navigate(kDashboardRoute);
                    }, 100);
                }
            } catch (e: any) {
                console.error(e);

                if (e.message.indexOf('UAEC:')) {
                    ErrorToast(tt('joinCompanyModal.error.alreadyEmployee'));
                } else {
                    ErrorToast(tt('joinCompanyModal.error'));
                }
            }
        }
    };

    return (
        <AppModal blurBackdrop={true} paperClassName={classes.modalPaper} onClose={CloseModal}
                  title={tt('onboarding.modal.joinExistingCompany')}
                  actions={
                      <AppButton
                          fullWidth={true}
                          variant={"contained"}
                          onClick={JoinCompany}
                          isLoading={joinLoading}
                      >
                          {tt('onboarding.modal.confirmKey')}
                      </AppButton>}
                  open={open || false}>
            <FormBuilder inputs={inputs} setInputs={setInputs}/>
        </AppModal>
    );
}
