import {makeStyles} from "tss-react/mui";
import {Grid, IconButton, TextField, Theme, Typography} from "@mui/material";
import {InputProps} from "./InputProps";
import React, {useEffect, useRef, useState} from "react";
import {useStyles as formUseStyles} from "./FormBuilder";
import {Close} from "@mui/icons-material";
import GreyLabel from "../decorations/GreyLabel";
import {kAppColors} from "../../../styles/AppThemeProcessor";

const useStyles = makeStyles()((theme: Theme) => ({
    suffix: {
        marginLeft: theme.spacing(2),
    },
    prefix: {
        marginRight: theme.spacing(2),
    },
    topLabelPadding: {
        paddingTop: 16,
        paddingBottom: 16,
    },
    container: {
        position: "relative",
    },
    counter: {
        position: "absolute",
        bottom: 0,
        right: 0,
        fontSize: 12,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
    },
    standardVariant: {
        '.MuiInputBase-root': {
            minHeight: 52,
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 8,
            paddingRight: 8,
        },
        '.MuiInputAdornment-root': {
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
            'svg': {
                width: 28,
                height: 28,
            }
        }
    },
    thin: {
        '.MuiInputBase-root': {
            minHeight: 40,
            height: 40,
            paddingTop: 0,
            paddingBottom: 0,
        },
    }
}));

/**
 * Form component for Text input.
 */
export default function TextInput(props: InputProps) {
    const {
        index,
        className,
        label,
        value,
        numbersOnly,
        decimalPoints,
        readOnly,
        updateValue,
        hidden,
        error,
        errorMessage,
        suffixJSX,
        innerSuffixJSX,
        innerPrefixJSX,
        prefixJSX,
        isClearable,
        inputMode,
        placeholder,
        testId,
        helperText,
        onFocus,
        onBlur,
        size,
        hideLabel,
        minRows,
        topLabel,
        disabled,
        maxChars,
        showCharCounter,
        inputVariant,
        extraStyle,
        /* removeZeroOnFocus, */
    } = props;
    const removeZeroOnFocus = true; //TODO Jan: does weird stuff if not enabled

    const {classes: formClasses} = formUseStyles();
    const {classes, cx} = useStyles();

    const [charsCount, setCharsCount] = useState<number>(value.length);
    const [didRemoveZero, setDidRemoveZero] = useState<boolean>(false);
    const [tempValue, setTempValue] = useState<string>(value);
    const [tempValueJustChanged, setTempValueJustChanged] = useState<boolean>(false);

    let showClearButton = false;
    const ref = useRef<HTMLInputElement>(null);

    const textfieldClass = cx(
        inputVariant === "standard" ? classes.standardVariant : null,
        extraStyle === "thin" ? classes.thin : null,
    );

    let theValue = value;
    if (numbersOnly && decimalPoints) {
        const number: number = parseFloat(theValue || '0');

        theValue = number.toFixed(decimalPoints);
    }

    if (tempValueJustChanged) {
        theValue = tempValue;
    }

    /**
     * OnChange update data of this field.
     */
    const OnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let theValue = e.target.value;
        if (theValue && numbersOnly && decimalPoints) {
            const number: number = parseFloat(theValue);

            theValue = number.toFixed(decimalPoints);
        }

        if (isClearable) {
            updateClearButton();
        }

        setCharsCount(e.target.value.length);
        setTempValueJustChanged(false);
        updateValue(index, theValue);
    };

    const suffixJSXContent = suffixJSX ? (
        <Grid className={classes.suffix} item={true}>
            {suffixJSX()}
        </Grid>
    ) : null;

    const prefixJSXContent = prefixJSX ? (
        <Grid className={classes.prefix} item={true}>
            {prefixJSX}
        </Grid>
    ) : null;

    const currentInnerSuffixJSX = innerSuffixJSX == null ? (isClearable && value != '' ? <IconButton onClick={clear}>
        <Close/>
    </IconButton> : null) : innerSuffixJSX;

    function clear() {
        updateValue(index, '');
        ref.current!.focus();
    }

    function updateClearButton() {
        const shouldHideSuffix = theValue === '' && innerSuffixJSX != null;
        const shouldShow = theValue !== '' && innerSuffixJSX == null;

        if (shouldHideSuffix) {
            showClearButton = false;
        }
        if (shouldShow) {
            showClearButton = true;
        }
    }

    function inputRemoveZeroOnFocus(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) {
        if (document.activeElement === ref.current) {
            if (tempValue === '0') {
                setTempValue('');
                setDidRemoveZero(true);
                setTempValueJustChanged(true);
            }
        } else {
            if (didRemoveZero && theValue === '') {
                setTempValue('0');
                setDidRemoveZero(false);
                setTempValueJustChanged(true);
            }
        }
    }

    const charCountJSX = showCharCounter ?
        <Typography className={classes.counter}>{charsCount}{maxChars ? `/${maxChars}` : ''}</Typography> : null;

    return (
        <div className={cx(className, hidden ? formClasses.hidden : undefined, classes.container)}>
            {topLabel ? <GreyLabel className={classes.topLabelPadding} text={topLabel}/> : null}
            <Grid container={true} spacing={0} alignItems="center">
                {prefixJSXContent}

                <Grid item={true} xs={true}>
                    <TextField
                        className={textfieldClass}
                        size={size}
                        onFocus={(e) => {
                            if (removeZeroOnFocus && numbersOnly) {
                                inputRemoveZeroOnFocus(e);
                            }
                            if (onFocus) {
                                onFocus(e)
                            }
                        }}
                        onBlur={(e) => {
                            if (removeZeroOnFocus && numbersOnly) {
                                inputRemoveZeroOnFocus(e);
                            }
                            if (onBlur) {
                                onBlur(e);
                            }
                        }}
                        data-testid={testId}
                        inputRef={ref}
                        variant={inputVariant || "filled"}
                        type={numbersOnly ? 'number' : undefined}
                        label={hideLabel ? undefined : label}
                        placeholder={placeholder}
                        value={theValue}
                        error={error}
                        helperText={helperText}
                        fullWidth={true}
                        margin="none"
                        disabled={readOnly || disabled}
                        onChange={OnChange}
                        minRows={minRows}
                        multiline={parseInt(`${minRows}`) > 1}
                        inputProps={{
                            maxLength: maxChars,
                            inputMode: inputMode,
                        }}
                        InputProps={{
                            inputMode: inputMode,
                            endAdornment: currentInnerSuffixJSX,
                            startAdornment: innerPrefixJSX,
                        }}
                    />

                    {charCountJSX}
                </Grid>
                {suffixJSXContent}
            </Grid>
        </div>
    );
}
