import {iconUseStyles} from "../styles/IconStyles";

export interface IInternetProps {
    className?: string;
}

export default function Icons8Internet(props: IInternetProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
                <radialGradient id="NnLSk57BiaEytNj6VLTnEa" cx="10.062" cy="9.438" r="40.916"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#6fcefc"/>
                    <stop offset="1" stopColor="#167cf7"/>
                </radialGradient>
                <path fill="url(#NnLSk57BiaEytNj6VLTnEa)"
                      d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"/>
                <radialGradient id="NnLSk57BiaEytNj6VLTnEb" cx="10.696" cy="8.05" r="40.918"
                                gradientUnits="userSpaceOnUse">
                    <stop offset=".001" stopColor="#54c0f5"/>
                    <stop offset=".998" stopColor="#0b69db"/>
                </radialGradient>
                <path fill="url(#NnLSk57BiaEytNj6VLTnEb)"
                      d="M41,24c0-2.465-0.399-4.818-1.096-7h2.811c-0.256-0.685-0.546-1.353-0.873-2h-2.682	C36.355,8.485,30.621,4,24,4S11.645,8.485,8.839,15H6.157c-0.327,0.647-0.616,1.315-0.873,2h2.811C7.399,19.182,7,21.535,7,24	s0.399,4.818,1.096,7H5.285c0.256,0.685,0.546,1.353,0.873,2h2.682c2.806,6.515,8.54,11,15.161,11s12.355-4.485,15.161-11h2.682	c0.327-0.647,0.616-1.315,0.873-2h-2.811C40.601,28.818,41,26.465,41,24z M39,24c0,2.481-0.421,4.847-1.181,7H31.51	c0.323-2.266,0.49-4.65,0.49-7s-0.166-4.734-0.49-7h6.309C38.579,19.153,39,21.519,39,24z M24,42c-1.936,0-4.021-3.445-5.152-9	h10.304C28.021,38.555,25.936,42,24,42z M18.5,31c-0.315-2.113-0.5-4.461-0.5-7s0.185-4.887,0.5-7H29.5c0.315,2.113,0.5,4.461,0.5,7	s-0.185,4.887-0.5,7H18.5z M24,6c1.936,0,4.021,3.445,5.152,9H18.848C19.979,9.445,22.064,6,24,6z M36.97,15h-5.792	c-0.639-3.33-1.643-6.272-2.994-8.273C31.922,8.033,35.054,11.038,36.97,15z M19.816,6.727c-1.35,2.002-2.355,4.943-2.994,8.273	H11.03C12.946,11.038,16.078,8.033,19.816,6.727z M9,24c0-2.481,0.421-4.847,1.181-7h6.309C16.166,19.266,16,21.65,16,24	s0.166,4.734,0.49,7h-6.309C9.421,28.847,9,26.481,9,24z M11.03,33h5.792c0.639,3.33,1.643,6.272,2.994,8.273	C16.078,39.967,12.946,36.962,11.03,33z M28.184,41.273c1.35-2.002,2.355-4.943,2.994-8.273h5.792	C35.054,36.962,31.922,39.967,28.184,41.273z"/>
                <path
                    d="M35.264,30.302l-7.895-7.895c-0.407-0.406-1.01-0.523-1.53-0.293C25.329,22.333,25,22.833,25,23.388	v11.298c0,1.304,1.393,1.839,2.289,1.071l1.583-1.319l1.187,2.73c0.228,0.52,1.007,1.021,1.826,0.717l1.345-0.585	c0.707-0.3,1.088-1.035,0.721-1.826l-1.152-2.652l1.602-0.144C35.677,32.678,36.15,31.18,35.264,30.302z"
                    opacity=".05"/>
                <path
                    d="M34.91,30.655l-7.895-7.895c-0.266-0.265-0.65-0.33-0.979-0.187c-0.32,0.139-0.536,0.452-0.536,0.814	v11.298c0,0.82,0.892,1.179,1.469,0.687l2.095-1.747l1.452,3.342c0.156,0.358,0.66,0.653,1.168,0.459l1.346-0.585	c0.452-0.193,0.687-0.674,0.461-1.168l-1.429-3.287l2.293-0.205C35.162,32.165,35.472,31.213,34.91,30.655z"
                    opacity=".07"/>
                <path fill="#fff"
                      d="M34.556,31.009l-7.895-7.895c-0.124-0.124-0.292-0.14-0.428-0.081C26.102,23.09,26,23.218,26,23.388	v11.297c0,0.335,0.391,0.518,0.649,0.304l2.608-2.175l1.718,3.953c0.085,0.196,0.314,0.286,0.51,0.201l1.347-0.586	c0.196-0.085,0.286-0.314,0.201-0.51l-1.705-3.923l2.984-0.267C34.646,31.652,34.794,31.246,34.556,31.009z"/>
            </svg>
        </span>
    );
}


