import ModalBottomSheet from "../../../ModalBottomSheet";
import {Box, InputAdornment, Theme} from "@mui/material";
import {tt} from "../../../../../core/Localization";
import AppListItem from "../../../listItems/AppListItem";
import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {makeStyles} from "tss-react/mui";
import AppButton from "../../../buttons/AppButton";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import {AddOutlined, CheckCircle} from "@mui/icons-material";
import {
    GetLocationsForClientInput,
    LocationResponse,
    LocationResponsePage
} from "../../../../../generated/graphql/graphql";
import CreateLocationModal from "../../../locations/CreateLocationModal";
import {LocationNameDisplay, LocationTypeDisplay} from "../../../../../service/LocationService";
import ListShimmer from "../../../shimmers/ListShimmer";
import EmptyListText from "../../../textComponents/EmptyListText";
import Icons8Location from "../../../../../icons/Icons8Location";
import {processQueryError} from "../../../../../service/ErrorService";
import {AppDataContext} from "../../../../../AppData";
import {RestApiClientContext} from "../../../../../core/RestApiProvider";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../../form/FormBuilder";
import SearchIcon from "../../../../../icons/SearchIcon";
import PermissionValid from "../../../permissions/PermissionValid";
import {kActionUpdate, kPermissionsClients} from "../../../../../core/constants";
import {addressToSingleLine} from "../../../../../utils/AddressUtils";


export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        maxHeight: '80vh',
        height: '100vh',
        overflow: "auto",
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
        backdropFilter: `saturate(180%) blur(10px)`,
    },
}));

export interface IChooseLocationsModalBottomSheetProps {
    clientId: number;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    existingLocationId?: number;
    onSave: (id: number, sendNotifications: boolean) => void;
    modalAboveModals?: boolean;
    askToSendNotifications?: boolean;
}

export default function ChooseLocationModalBottomSheet(props: IChooseLocationsModalBottomSheetProps) {
    const {
        open,
        setOpen,
        onSave,
        clientId,
        existingLocationId,
        modalAboveModals,
        askToSendNotifications,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);

    const {classes} = useStyles();

    const [chosenLocationId, setChosenLocationId] = useState<number>();
    const [createModal, setCreateModal] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<LocationResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (open && !createModal) {
            restApiGet({
                uri: '/location/search',
                params: {
                    clientId: clientId,
                } as GetLocationsForClientInput,
                setLoading: setLoading,
                onData: setData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        }
    }, [open, clientId, createModal]);

    const [searchInputs, setSearchInputs] = useState<IInputsData>({
        search: {
            type: InputType.Text,
            label: '',
            placeholder: tt('chooseLocationModal.searchForLocation'),
            hideLabel: true,
            inputVariant: 'standard',
            extraStyle: 'thin',
            value: '',
            required: true,
            isClearable: true,
            innerPrefixJSX: (
                <InputAdornment position={"start"}>
                    <SearchIcon/>
                </InputAdornment>
            ),
        },
    });

    const [inputs, setInputs] = useState<IInputsData>({
        notifications: {
            type: InputType.CheckBox,
            label: tt('common.visit.checkbox.sendNotificationsToWorkers'),
            value: true,
        },
    });

    useEffect(() => {
        if (open) {
            setChosenLocationId(existingLocationId);

            setSearchInputs(prev => {
                    return {
                        ...prev,
                        search: {
                            ...prev.search,
                            value: '',
                        }
                    }
                }
            );

            setInputs(prev => {
                return {
                    ...prev,
                    notifications: {
                        ...prev.notifications,
                        value: true,
                    },
                };
            });
        }
    }, [open]);

    const filteredLocations: LocationResponse[] = useMemo(() => {
        return data && data.content.length > 0 ? data?.content.filter(location => {
                return `${location.name} ${LocationTypeDisplay(location.type || '')} ${addressToSingleLine(location.address)}`.toLowerCase().includes(searchInputs.search.value.toLowerCase());
            })
            :
            []
    }, [searchInputs, data]);

    const locationsJSX = filteredLocations.map(location =>
        <AppListItem
            key={location.id}
            customAvatar={<Icons8Location/>}
            title={LocationNameDisplay(location.name, location.type || '')}
            description={`${location.name ? LocationTypeDisplay(location.type || '') : ''}\n${addressToSingleLine(location.address)}`.trim()}
            variant={"smaller-title"}
            onClick={() => {
                setChosenLocationId(location.id);
            }
            }
            actionWidget={
                chosenLocationId === location.id ? <CheckCircle color={"primary"}/> : <></>
            }
        />
    );

    /**
     * On save send to callback and optionally also notifications.
     */
    const handleOnSave = (id?: number) => {
        if (askToSendNotifications) {
            if (ValidateForm(inputs, setInputs)) {
                onSave(id || chosenLocationId!, inputs.notifications.value);

                setOpen(false);
            }
        } else {
            onSave(id || chosenLocationId!, false);

            setOpen(false);
        }
    };

    return (
        <ModalBottomSheet
            tallOnMobile={true}
            blurBackdrop={true}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
            modalAboveModals={modalAboveModals}
        >
            <Box className={classes.container}>
                <BottomSheetModalAppbar
                    onClose={() => setOpen(false)}
                    bottomContent={
                        <>
                            <FormBuilder inputs={searchInputs} setInputs={setSearchInputs}/>
                        </>
                    }
                >
                    <PermissionValid
                        permission={kPermissionsClients}
                        requiredPermissions={[kActionUpdate]}
                    >
                        <AppButton
                            onClick={() => {
                                setCreateModal(true);
                            }}
                            variant={"textThin"}
                        >
                            <AddOutlined sx={{mr: 0.25}}/>
                            {tt('common.newLocation')}
                        </AppButton>
                    </PermissionValid>
                </BottomSheetModalAppbar>

                {loading ?
                    <ListShimmer items={3} showIcon={false}/>
                    : (!locationsJSX || locationsJSX.length === 0 ?
                        <EmptyListText text={tt('locationsList.emptyListMessage')}/> : locationsJSX)}

                <Box sx={{pb: 12}}/>

                <Box className={classes.buttonContainer}>

                    {askToSendNotifications ? (
                        <Box sx={{pb: 1}}>
                            <FormBuilder inputs={inputs} setInputs={setInputs}/>
                        </Box>
                    ) : null}

                    <AppButton
                        variant={"contained"}
                        disabled={chosenLocationId === null}
                        fullWidth={true}
                        onClick={() => handleOnSave()}>
                        {tt('common.save')}
                    </AppButton>
                </Box>

                <CreateLocationModal
                    clientId={clientId}
                    open={createModal}
                    setOpen={setCreateModal}
                    navigation={false}
                    onCreate={(id: number) => {
                        setTimeout(() => {
                            handleOnSave(id);
                        }, 400);
                    }}
                    modalAboveModals={true}
                />
            </Box>
        </ModalBottomSheet>
    );
}
