import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import {
    ClientPureResponse,
    ClientResponse,
    EmployeeJoinedUserResponse,
    FileResponse,
    JobResponse,
    JobStatsResponse
} from "../../../generated/graphql/graphql";
import React, {ReactNode, useContext} from "react";
import {UserFullName, UserPhotoUrl} from "../../../service/UserService";
import AppAvatar from "../AppAvatar";
import {tt} from "../../../core/Localization";
import AppChip from "../chips/AppChip";
import {GetJobVisitsChips, JobNameOrSequenceId} from "../../../service/JobService";
import HammerIcon from "../../../icons/HammerIcon";
import AppListItemV2 from "../listItems/ApplistItemV2";
import {AppDataContext} from "../../../AppData";

export const useStyles = makeStyles()((theme: Theme) => ({
    marginRight: {
        marginRight: 4,
        marginBottom: 4,
    },
    statusChipContainer: {
        paddingLeft: 72,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "start",
        alignItems: "center",

    },
    displayFlex: {
        display: "flex",
    }
}));

export interface ISingleJobListItemProps {
    data: JobResponse;
    stats?: JobStatsResponse | NullOrUndefined;
    client?: ClientPureResponse | ClientResponse;
    responsiblePerson?: EmployeeJoinedUserResponse;
    onClick?: (id: number) => void;
    noMarginBottom?: boolean;
    customActionWidget?: ReactNode;
    files: FileResponse[] | NullOrUndefined;
}

export default function SingleJobListItem(props: ISingleJobListItemProps) {
    const {
        data,
        stats,
        client,
        responsiblePerson,
        onClick,
        noMarginBottom,
        customActionWidget,
        files,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {storage} = appDataContext;

    const {classes, cx} = useStyles();

    const chips = GetJobVisitsChips(stats);

    const responsiblePersonJSX = responsiblePerson ? (
        <AppChip
            className={classes.marginRight}
            chipstyle={"outlined"}
            icon={
                <AppAvatar
                    img={
                        UserPhotoUrl(responsiblePerson.user, files, storage.publicUrlsForFiles)
                    }
                    tooltip={UserFullName(responsiblePerson.name || responsiblePerson.user?.name, responsiblePerson.surname || responsiblePerson.user?.surname)}
                    tooltipDescription={tt('common.responsiblePerson')}
                    title={UserFullName(responsiblePerson.name || responsiblePerson.user?.name, responsiblePerson.surname || responsiblePerson.user?.surname)}
                    variant={"tiny"}
                />
            }
        />
    ) : undefined;

    return (
        <>
            <AppListItemV2
                customAvatarInCircle={<HammerIcon/>}
                onClick={onClick ? () => onClick(data.id) : undefined}
                title={JobNameOrSequenceId(data)}
                description={client?.name || tt('singleJobItem.noClient')}
                descriptionEmptyStyle={!client?.name}
                actionWidget={customActionWidget}
                belowListItemWidget={
                    <Box className={classes.statusChipContainer}>
                        {responsiblePersonJSX}
                        {chips}
                    </Box>
                }
            />
        </>
    );
}
