import {Box, Menu, MenuItem} from "@mui/material";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import {usePopupState} from "material-ui-popup-state/hooks";
import {ClientContactResponse} from "../../../../generated/graphql/graphql";
import AppListItem from "../../listItems/AppListItem";
import UserIcon from "../../../../icons/UserIcon";
import {UserFullName} from "../../../../service/UserService";
import PhoneMenu from "../../menus/PhoneMenu";
import AppIconButton from "../../buttons/AppIconButton";
import {tt} from "../../../../core/Localization";
import MoreFilledIcon from "../../../../icons/MoreFilledIcon";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import CloseIcon from "../../../../icons/CloseIcon";
import {useId} from "react";
import PermissionValid from "../../permissions/PermissionValid";
import {kActionUpdate, kPermissionsClients, kPermissionsJobs} from "../../../../core/constants";

export interface IJobDetailClientContactProps {
    contact: ClientContactResponse;
    existingContactIds: number[];
    updateClientContacts: (clientContactIds: number[]) => Promise<void> | void;
}

/**
 * Component for Client Contact on Job Detail.
 */
export default function JobDetailClientContact(props: IJobDetailClientContactProps) {
    const {
        contact,
        existingContactIds,
        updateClientContacts
    } = props;

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    /**
     * Remove this contact and call update contacts to BE.
     */
    const removeContact = () => {
        const newContactIds = existingContactIds.filter(id => id !== contact.id);

        updateClientContacts(newContactIds);
    };

    return (
        <AppListItem
            customAvatar={<UserIcon/>}
            title={UserFullName(contact.name, contact.surname)}
            description={contact.role}
            variant={"smaller-title"}
            actionWidget={<>
                <PermissionValid
                    permission={kPermissionsClients}
                >
                    {contact.phoneNumber ? <PhoneMenu
                        phone={contact.phoneNumber}
                        showMarginRight={true}/> : null}
                </PermissionValid>

                <PermissionValid
                    permission={kPermissionsJobs}
                    requiredPermissions={[kActionUpdate]}
                >
                    <AppIconButton
                        variant={"greyBg"}
                        placement={"right"}
                        tooltip={tt('common.more')}
                        {...bindTrigger(settingsPopupState)}
                    >
                        <MoreFilledIcon/>
                    </AppIconButton>
                </PermissionValid>

                <Menu {...bindMenu(settingsPopupState)}>
                    <PermissionValid
                        permission={kPermissionsJobs}
                        requiredPermissions={[kActionUpdate]}
                    >
                        <MenuItem key={'contactDetailDeleteKey' + contact.id} onClick={() => {
                            settingsPopupState.close();

                            removeContact();
                        }}>
                            <Box sx={{color: kAppColors.red.confirmButton}}><CloseIcon/></Box>
                            {tt('common.removeFromList')}
                        </MenuItem>
                    </PermissionValid>
                </Menu>
            </>}
        />
    );
}
