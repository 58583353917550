import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8CableProps {
    className?: string;
}

export default function Icons8Cable(props: IIcons8CableProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
              <path
                  d="M 30.513672 2.9902344 A 1.50015 1.50015 0 0 0 29.771484 5.8105469 L 33.712891 8 L 27.5 8 A 1.50015 1.50015 0 0 0 26 9.5 L 26 12 L 12 12 C 7.599415 12 4 15.599415 4 20 C 4 24.400585 7.599415 28 12 28 L 35 28 C 37.779415 28 40 30.220585 40 33 C 40 35.779415 37.779415 38 35 38 L 6.5 38 A 1.50015 1.50015 0 1 0 6.5 41 L 35 41 C 39.400585 41 43 37.400585 43 33 C 43 28.599415 39.400585 25 35 25 L 12 25 C 9.220585 25 7 22.779415 7 20 C 7 17.220585 9.220585 15 12 15 L 26 15 L 26 17.5 A 1.50015 1.50015 0 0 0 27.5 19 L 41.5 19 A 1.50015 1.50015 0 0 0 43 17.5 L 43 9.5 A 1.50015 1.50015 0 0 0 41.5 8 L 39.886719 8 L 31.228516 3.1894531 A 1.50015 1.50015 0 0 0 30.513672 2.9902344 z M 29 11 L 39.291016 11 A 1.50015 1.50015 0 0 0 39.798828 11 L 40 11 L 40 16 L 29 16 L 29 13.746094 A 1.50015 1.50015 0 0 0 29 13.259766 L 29 11 z"/>
              </svg>
        </span>
    );
}
























