import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import React, {useContext, useEffect, useState} from "react";
import {AppDataContext} from "../../../AppData";
import {tt} from "../../../core/Localization";
import AppButton from "../buttons/AppButton";
import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material";
import WorkerEditScreenShimmer from "../shimmers/screenSpecificShimmers/WorkerEditScreenShimmer";
import {GetProductTypeTitle} from "../../../service/ProductService";
import {ProductType} from "../../../generated/graphql/graphql";
import {AddVatToPrice, GetVatAmount, PriceDisplay} from "../../../service/CompanyService";
import SplitButton from "../buttons/SplitButton";

export const useStyles = makeStyles()((theme: Theme) => ({
    submitRightContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    button: {
        "@media (max-width: 767px)": {
            flexGrow: 1,
            width: '100%',
        }
    },
    submitRightSplitButton: {
        flexShrink: 1,
        width: "unset",
        marginLeft: "auto",
        "@media (max-width: 767px)": {
            flexShrink: 0,
            marginLeft: 0,
            flexGrow: 1,
            width: '100%',
        }
    }
}));

export interface IServiceFormProps {
    isEdit?: boolean;
    updateLoading?: boolean;
    editQuantity?: boolean;
    type?: ProductType;
    name?: string;
    vatRate?: number | NullOrUndefined;
    unitOfMeasure?: string | NullOrUndefined;
    description?: string | NullOrUndefined;
    price?: number | NullOrUndefined;
    quantity?: number | NullOrUndefined;
    onSubmit: (update: IProductFormUpdate, createAnother?: boolean) => void;
    loading?: boolean;
    loadingData?: boolean;
    submitRight?: boolean;
    createAnotherAble?: boolean;
    resetFormTrigger?: number;
    isModal?: boolean;
}

export interface IProductFormUpdate {
    inputs: IInputsData;
}

export default function ProductForm(props: IServiceFormProps) {
    const appDataContext = useContext(AppDataContext);
    const {currency, language, company} = appDataContext;

    const {classes, cx} = useStyles();

    const {
        isEdit,
        editQuantity,
        type,
        name,
        price,
        quantity,
        vatRate,
        unitOfMeasure,
        description,
        onSubmit,
        loading,
        loadingData,
        submitRight,
        createAnotherAble,
        resetFormTrigger,
        isModal,
        updateLoading,
    } = props;

    const companyHasVat = company?.hasVat || false;

    const [typeInput, setTypeInput] = useState<IInputsData>({
        type: {
            testId: 'productTypeInput',
            type: InputType.ChipSwitch,
            label: '',
            value: type || ProductType.Product,
            options: [
                {label: GetProductTypeTitle(ProductType.Product), value: ProductType.Product},
                {label: GetProductTypeTitle(ProductType.Service), value: ProductType.Service}
            ]
        },
    });

    const [inputs, setInputs] = useState<IInputsData>(isEdit ? {
        name: {
            testId: 'productNameInput',
            type: InputType.Text,
            label: tt('product.form.label.name') + '*',
            value: name || '',
            required: true,
        },
        quantity: {
            testId: 'addProductQuantityInput',
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('product.form.label.quantity')}`,
            value: '',
            required: false,
            hidden: !editQuantity,
            grid: {
                sm: companyHasVat ? 3 : 4,
                xs: 6,
            },
        },
        unitOfMeasure: {
            testId: 'productUnitOfMeasureInput',
            type: InputType.Text,
            label: tt('product.form.label.unitOfMeasure'),
            placeholder: tt('product.form.placeholder.unitOfMeasure'),
            value: unitOfMeasure || '',
            required: false,
            grid: {
                sm: editQuantity ? (companyHasVat ? 3 : 4) : (companyHasVat ? 4 : 6),
                xs: 6,
            },
        },
        price: {
            testId: 'productPriceInput',
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('product.form.label.price')} (${currency})`,
            value: price || '',
            required: false,
            grid: {
                sm: editQuantity ? (companyHasVat ? 3 : 4) : (companyHasVat ? 4 : 6),
                xs: 6,
            },
        },
        vatRate: {
            testId: 'productVatRateInput',
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('product.form.label.vatRate')}`,
            value: vatRate || '',
            hidden: !companyHasVat,
            grid: {
                sm: editQuantity ? (companyHasVat ? 3 : 4) : (companyHasVat ? 4 : 6),
                xs: 6,
            },
        },
        description: {
            testId: 'productDescriptionInput',
            type: InputType.ButtonTextField,
            label: tt('common.description'),
            toggleButtonText: tt('common.description'),
            value: description || '',
            minRows: 4,
        },
    } : {
        name: {
            testId: 'productNameInput',
            type: InputType.Text,
            label: tt('product.form.label.name'),
            value: name || '',
            required: true,
        },
        price: {
            testId: 'productPriceInput',
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('product.form.label.price')} (${currency})`,
            value: price || '',
            required: false,
            grid: {
                sm: editQuantity ? (companyHasVat ? 3 : 4) : (companyHasVat ? 4 : 6),
                xs: 6,
            },
        },
        quantity: {
            testId: 'addProductQuantityInput',
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('product.form.label.quantity')}`,
            value: '',
            required: false,
            hidden: !editQuantity,
            grid: {
                sm: companyHasVat ? 3 : 4,
                xs: 6,
            },
        },
        vatRate: {
            testId: 'productVatRateInput',
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: `${tt('product.form.label.vatRate')}`,
            value: vatRate || '',
            hidden: !companyHasVat,
            grid: {
                sm: editQuantity ? (companyHasVat ? 3 : 4) : (companyHasVat ? 4 : 6),
                xs: 6,
            },
        },
        unitOfMeasure: {
            testId: 'productUnitOfMeasureInput',
            type: InputType.Text,
            label: tt('product.form.label.unitOfMeasure'),
            placeholder: tt('product.form.placeholder.unitOfMeasure'),
            value: unitOfMeasure || '',
            required: false,
            grid: {
                sm: editQuantity ? (companyHasVat ? 3 : 4) : (companyHasVat ? 4 : 6),
                xs: 6,
            },
        },
        description: {
            testId: 'productDescriptionInput',
            type: InputType.ButtonTextField,
            label: tt('common.description'),
            toggleButtonText: tt('common.description'),
            value: description || '',
            minRows: 4,
        },
    });

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                name: {
                    ...prev.name,
                    value: name || '',
                },
                price: {
                    ...prev.price,
                    value: price || '',
                },
                quantity: {
                    ...prev.quantity,
                    value: quantity || '',
                },
                vatRate: {
                    ...prev.vatRate,
                    value: vatRate || '',
                },
                unitOfMeasure: {
                    ...prev.unitOfMeasure,
                    value: unitOfMeasure || '',
                },
                description: {
                    ...prev.description,
                    value: description || '',
                },
            };
        });
    }, [resetFormTrigger]);

    useEffect(() => {
        const newHidden = !companyHasVat;
        const newEditQuantityHidden = !editQuantity;

        if (inputs.vatRate.hidden !== newHidden || inputs.quantity.hidden !== newEditQuantityHidden) {
            setInputs(prev => {
                return {
                    ...prev,
                    price: {
                        ...prev.price,
                        grid: {
                            sm: editQuantity ? (companyHasVat ? 3 : 4) : (companyHasVat ? 4 : 6),
                            xs: 6,
                        },
                    },
                    quantity: {
                        ...prev.quantity,
                        required: false,
                        hidden: !editQuantity,
                        grid: {
                            sm: companyHasVat ? 3 : 4,
                            xs: 6,
                        },
                    },
                    vatRate: {
                        ...prev.vatRate,
                        hidden: !companyHasVat,
                        required: false,
                        grid: {
                            sm: editQuantity ? (companyHasVat ? 3 : 4) : (companyHasVat ? 4 : 6),
                            xs: 6,
                        },
                    },
                    unitOfMeasure: {
                        ...prev.unitOfMeasure,
                        grid: {
                            sm: editQuantity ? (companyHasVat ? 3 : 4) : (companyHasVat ? 4 : 6),
                            xs: 6,
                        },
                    },
                };
            });
        }
    }, [companyHasVat, editQuantity]);

    useEffect(() => {
        if (companyHasVat) {
            const thePrice = parseFloat(inputs.price.value || '0');
            const theVatRate = parseFloat(inputs.vatRate.value || '0');
            const withVAT = AddVatToPrice(thePrice, theVatRate);

            setInputs(prev => {
                return {
                    ...prev,
                    price: {
                        ...prev.price,
                        helperText: `${PriceDisplay(withVAT, currency, language, true)} ${tt('productsForm.includingVat')}`,
                    },
                    vatRate: {
                        ...prev.vatRate,
                        helperText: `${PriceDisplay(GetVatAmount(thePrice, theVatRate), currency, language, true)}`,
                    },
                };
            });
        }
    }, [inputs.vatRate.value, inputs.price.value, companyHasVat]);

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                type: {
                    ...prev.type,
                    value: type || ProductType.Product,
                },
                name: {
                    ...prev.name,
                    value: name || '',
                },
                price: {
                    ...prev.price,
                    value: price || '',
                },
                quantity: {
                    ...prev.quantity,
                    value: quantity || '',
                },
                vatRate: {
                    ...prev.vatRate,
                    value: vatRate || '',
                },
                unitOfMeasure: {
                    ...prev.unitOfMeasure,
                    value: unitOfMeasure || '',
                },
                description: {
                    ...prev.description,
                    value: description || '',
                },
            }
        });
    }, [type, name, price, quantity, vatRate, unitOfMeasure, description]);

    const SubmitForm = (createAnother: boolean) => {
        if (ValidateForm(inputs, setInputs)) {
            onSubmit({
                inputs: {
                    ...inputs,
                    type: typeInput.type,
                },
            }, createAnother);
        }
    };

    let submitJSX = createAnotherAble ? (
        <SplitButton
            className={cx(classes.button, submitRight ? classes.submitRightSplitButton : null)}
            isInModal={isModal}
            onChange={
                (index) => {
                    SubmitForm(true);
                }
            }
            disabled={loading}
            isLoading={updateLoading}
            onClick={(index, value) => {
                SubmitForm(false);
            }}
            text={isEdit ? tt('common.save') : tt('common.create')}
            options={[
                tt('addProductTemplateModal.saveAndCreateAnother'),
            ]}
        />
    ) : (
        <AppButton
            className={classes.button}
            testId={'productsFormSubmitButton'}
            variant={"contained"}
            fullWidth={!submitRight}
            onClick={() => SubmitForm(false)}
            disabled={loading}
            isLoading={updateLoading}
        >
            {isEdit ? tt('common.save') : tt('common.create')}
        </AppButton>
    );

    submitJSX = !submitRight ? submitJSX : (
        <div className={classes.submitRightContainer}>
            {submitJSX}
        </div>
    );

    return (
        <>
            {loadingData ? <WorkerEditScreenShimmer/> : (
                <>
                    {isEdit ? null : <FormBuilder inputs={typeInput} setInputs={setTypeInput}/>}

                    <FormBuilder inputs={inputs} setInputs={setInputs}/>
                </>
            )}

            {submitJSX}
        </>
    );
}
