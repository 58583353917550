import {makeStyles} from "tss-react/mui";
import {Box, Divider, Skeleton, Theme} from "@mui/material";
import React from "react";
import AppListItemShimmer from "./AppListItemShimmer";

const useStyles = makeStyles()((theme: Theme) => ({
    galleryContainer: {
        display: "flex",
        paddingTop: 8,
        marginLeft: 16,
        marginRight: 16,
        overflowX: "auto",
        flexFlow: "wrap",
    },
    galleryImage: {
        display: 'block',
        borderRadius: 8,
        height: 80,
        width: 80,
        marginRight: 16,
        flexShrink: 0,
        marginBottom: 16,
    },
    defectDescription: {
        marginLeft: 16,
        marginRight: 16,
    },
}));

export default function ProtocolsTabShimmer() {
    const {classes, cx} = useStyles();

    return (
        <>
            <AppListItemShimmer showIcon={false}/>
            <Box className={cx('styledScrollbar', classes.galleryContainer)}>
                <Skeleton className={classes.galleryImage} variant={"rounded"}></Skeleton>
                <Skeleton className={classes.galleryImage} variant={"rounded"}></Skeleton>
                <Skeleton className={classes.galleryImage} variant={"rounded"}></Skeleton>
            </Box>

            <Box className={classes.defectDescription}>
                <Skeleton variant={"rectangular"} width={200} height={16}></Skeleton>
                <Box pb={0.5}/>
                <Skeleton variant={"rectangular"} width={160} height={14}></Skeleton>
            </Box>
            <Box pb={2}/>
            <Box pb={2}><Divider/></Box>
            <AppListItemShimmer showIcon={false}/>
            <Box className={classes.galleryContainer}>
                <Skeleton className={classes.galleryImage} variant={"rounded"}></Skeleton>
                <Skeleton className={classes.galleryImage} variant={"rounded"}></Skeleton>
                <Skeleton className={classes.galleryImage} variant={"rounded"}></Skeleton>
            </Box>

            <Box className={classes.defectDescription}>
                <Skeleton variant={"rectangular"} width={200} height={16}></Skeleton>
                <Box pb={0.5}/>
                <Skeleton variant={"rectangular"} width={160} height={14}></Skeleton>
            </Box>
            <Box pb={2}/>
        </>
    );
}
