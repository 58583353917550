import AppListItem from "../../listItems/AppListItem";
import Icons8ClipBoardList from "../../../../icons/Icons8ClipBoardList";
import React, {useContext, useId, useMemo, useState} from "react";
import {usePopupState} from "material-ui-popup-state/hooks";
import AppIconButton from "../../buttons/AppIconButton";
import {tt} from "../../../../core/Localization";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MoreFilledIcon from "../../../../icons/MoreFilledIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {Box} from "@mui/material";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import CloseIcon from "../../../../icons/CloseIcon";
import Icons8Eye from "../../../../icons/Icons8Eye";
import JobFormPreviewModal from "../../modals/jobForms/JobFormPreviewModal";
import {
    CreateJobFormTemplateInput,
    EmployeeJoinedUserResponse,
    FileResponse,
    JobFormResponse
} from "../../../../generated/graphql/graphql";
import {UpdateJobFormElementDoneCallback} from "../visits/visitDetail/VisitDetailContent";
import {v4 as uuidv4} from 'uuid';
import {
    calculateJobFormStats,
    processCreateJobFormTemplateInputToJobFormResponse
} from "../../../../service/JobFormService";
import PencilIcon from "../../../../icons/PencilIcon";
import EditVisitJobFormNameModalBottomSheet from "../../modals/job/visits/EditVisitJobFormNameModalBottomSheet";
import PermissionValid, {hasPermission} from "../../permissions/PermissionValid";
import {kActionDelete, kActionUpdate, kPermissionsForms} from "../../../../core/constants";
import {AppDataContext} from "../../../../AppData";
import ProgressBarWidget from "../../ProgressBarWidget";

export interface IJobFormItemProps {
    isNewJobCreation?: boolean;
    data?: JobFormResponse;
    input?: CreateJobFormTemplateInput;
    onDelete?: (id: number) => void;
    preview?: boolean;
    employeesData?: Array<EmployeeJoinedUserResponse> | NullOrUndefined;
    files?: FileResponse[] | NullOrUndefined;
    canEdit?: boolean | NullOrUndefined;
    updateJobFormElementDone?: UpdateJobFormElementDoneCallback;
    visitId?: number;
    repeatingDay?: number;
    onRename?: (name: string) => void;
    overrideName?: string | NullOrUndefined;
}

export default function JobFormItem(props: IJobFormItemProps) {
    const {
        isNewJobCreation,
        data,
        input,
        onDelete,
        preview,
        updateJobFormElementDone,
        canEdit,
        employeesData,
        files,
        visitId,
        repeatingDay,
        onRename,
        overrideName,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {employeePermissionsMap} = appDataContext;

    const [uuid] = useState<string>(uuidv4());

    const thePreview = preview === undefined ? true : preview;

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const [previewModal, setPreviewModal] = useState<boolean>(false);
    const [renameModal, setRenameModal] = useState<boolean>(false);

    const hasAnyMoreActions = useMemo(() => {
        let result = false;

        if (onDelete || visitId) {
            if ((visitId && data?.uuid) || onRename) {
                if (hasPermission(kPermissionsForms, [kActionUpdate], employeePermissionsMap)) {
                    result = true;
                }
            }

            if (onDelete) {
                if (hasPermission(kPermissionsForms, [kActionDelete], employeePermissionsMap)) {
                    result = true;
                }
            }
        }

        return result;
    }, [onDelete, onRename, visitId, data?.uuid, employeePermissionsMap]);

    const formStats = useMemo(() => {
        if (data) {
            return calculateJobFormStats(data);
        }

        return null;
    }, [data]);

    return (
        <>
            <AppListItem
                variant={"smaller-title"}
                title={overrideName || data?.name || input?.name || ''}
                customAvatar={<Icons8ClipBoardList/>}
                customBottomWidget={formStats?.hasRequired ? (
                    <Box pb={0.8}>
                        <Box width={160}>
                            <ProgressBarWidget done={formStats.doneRequired} total={formStats.totalRequired}/>
                        </Box>
                    </Box>) : null
                }
                actionWidget={
                    <>
                        {thePreview ? (
                            <AppIconButton
                                variant={"greyBg"}
                                placement={"right"}
                                onClick={() => {
                                    setPreviewModal(true);
                                }}
                            >
                                <Icons8Eye/>
                            </AppIconButton>
                        ) : undefined}

                        {hasAnyMoreActions ? (
                            <>
                                <Box mr={1}/>

                                <AppIconButton
                                    variant={"greyBg"}
                                    placement={"right"}
                                    {...bindTrigger(settingsPopupState)}
                                >
                                    <MoreFilledIcon/>
                                </AppIconButton>
                            </>
                        ) : undefined}
                    </>
                }
            />

            {
                onDelete || visitId ? (
                    <Menu {...bindMenu(settingsPopupState)}>
                        {(visitId && data?.uuid) || onRename ? (
                            <PermissionValid
                                permission={kPermissionsForms}
                                requiredPermissions={[kActionUpdate]}
                                forceValid={isNewJobCreation}
                            >
                                <MenuItem key={'formDetailRenameKey'} onClick={() => {
                                    settingsPopupState.close();

                                    setRenameModal(true);
                                }}>
                                    <Box><PencilIcon/></Box>
                                    {tt('common.rename')}
                                </MenuItem>
                            </PermissionValid>
                        ) : undefined}

                        {onDelete ? (
                            <PermissionValid
                                permission={kPermissionsForms}
                                requiredPermissions={[kActionDelete]}
                                forceValid={isNewJobCreation}
                            >
                                <MenuItem key={'formDetailDeleteKey' + uuid} onClick={() => {
                                    settingsPopupState.close();

                                    onDelete(data?.id || 0);
                                }}>
                                    <Box sx={{color: kAppColors.red.confirmButton}}><CloseIcon/></Box>
                                    {tt('common.removeFromList')}
                                </MenuItem>
                            </PermissionValid>
                        ) : undefined}
                    </Menu>
                ) : undefined
            }

            <JobFormPreviewModal
                open={previewModal}
                setOpen={setPreviewModal}
                jobFormResponse={data || processCreateJobFormTemplateInputToJobFormResponse(input!)}
                updateJobFormElementDone={updateJobFormElementDone}
                canEdit={canEdit}
                employeesData={employeesData}
                files={files}
                overrideName={overrideName}
            />

            {
                (visitId && data?.uuid) || onRename ? (
                    <EditVisitJobFormNameModalBottomSheet
                        visitId={visitId}
                        repeatingDay={repeatingDay}
                        open={renameModal}
                        setOpen={setRenameModal}
                        jobFormUUID={data?.uuid}
                        existingName={data?.name}
                        modalAboveModals={true}
                        onRename={onRename}
                    />
                ) : undefined
            }
        </>
    )
        ;
}
