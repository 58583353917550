import {makeStyles} from "tss-react/mui";
import {Box, Skeleton, Theme} from "@mui/material";
import React from "react";
import AppPaper from "../paper/AppPaper";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import VisitListItemShimmer from "./VisitListItemShimmer";

const useStyles = makeStyles()((theme: Theme) => ({
    singleItemContainer: {
        paddingTop: 8,
        marginBottom: 16,
        overflow: "clip",
        cursor: "pointer",
        transition: 'background-color 0.3s linear',
        background: theme.palette.mode === "dark" ? '#202020' : "white",
        borderRadius: 8,
        "@media (max-width: 767px)": {
            border: "none",
            borderRadius: 0,
        }
    },
}));

export default function VisitItemWithBackgroundShimmer() {
    const {classes} = useStyles();

    return (
        <Box className={classes.singleItemContainer}>
            <VisitListItemShimmer/>
        </Box>
    );
}