import {iconUseStyles} from "../styles/IconStyles";

export interface IToolIconIconProps {
    className?: string;
}

export default function ToolIconIcon(props: IToolIconIconProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px"
                 preserveAspectRatio="xMidYMid meet" focusable="false">
                <path
                    d="M 31.5 5 C 24.6143 5 19 10.6143 19 17.5 C 19 18.906803 19.295817 20.234092 19.726562 21.496094 L 7.6113281 33.611328 A 1.50015 1.50015 0 0 0 7.609375 33.611328 C 5.4740391 35.746664 5.4754444 39.254152 7.6113281 41.388672 C 8.6790204 42.454999 10.094164 43 11.5 43 C 12.905836 43 14.32098 42.454999 15.388672 41.388672 A 1.50015 1.50015 0 0 0 15.390625 41.388672 L 27.503906 29.273438 C 28.765866 29.704319 30.094192 30 31.501953 30 C 38.387653 30 44.001953 24.3857 44.001953 17.5 C 44.001953 15.499091 43.516522 13.609327 42.685547 11.939453 A 1.50015 1.50015 0 0 0 40.283203 11.546875 L 33.414062 18.414062 C 33.01784 18.810287 32.514304 19 32 19 C 31.485696 19 30.98216 18.810287 30.585938 18.414062 C 29.792959 17.621665 29.792959 16.380288 30.585938 15.587891 A 1.50015 1.50015 0 0 0 30.585938 15.585938 L 37.453125 8.71875 A 1.50015 1.50015 0 0 0 37.060547 6.3164062 C 35.390285 5.4841061 33.500909 5 31.5 5 z M 31.5 8 C 32.249419 8 32.974604 8.091512 33.671875 8.2578125 L 28.464844 13.464844 C 26.523821 15.404446 26.523821 18.595554 28.464844 20.535156 C 29.434621 21.504933 30.722304 22 32 22 C 33.277696 22 34.565379 21.504933 35.535156 20.535156 L 40.744141 15.328125 C 40.910369 16.025527 41.001953 16.750581 41.001953 17.5 C 41.001953 22.7643 36.766254 27 31.501953 27 C 30.143057 27 28.85978 26.711447 27.689453 26.197266 A 1.50015 1.50015 0 0 0 26.025391 26.509766 L 13.267578 39.267578 C 12.771485 39.762298 12.141559 40 11.5 40 C 10.857836 40 10.226776 39.763251 9.7304688 39.267578 C 8.7423554 38.280098 8.7437578 36.721086 9.7324219 35.732422 L 22.490234 22.976562 A 1.50015 1.50015 0 0 0 22.802734 21.3125 C 22.288662 20.142423 22 18.85776 22 17.5 C 22 12.2357 26.2357 8 31.5 8 z"></path>
            </svg>
        </span>
    );
}









