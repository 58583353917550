import {makeStyles} from "tss-react/mui";
import {Paper, SxProps, Theme} from "@mui/material";
import React from "react";
import {kAppColors} from "../../../styles/AppThemeProcessor";

const useStyles = makeStyles()((theme: Theme) => ({
    paper: {
        border: "none",
        borderRadius: 10,
        width: '100%',
        background: kAppColors.background.paper(theme.palette.mode === "dark"),
    },
    showPadding: {
        padding: theme.spacing(2),
    },
    showPaddingTop: {
        paddingTop: 8,
    },
    centerContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
}));

export interface IAppPaperProps {
    children: React.ReactNode;
    className?: string;
    sx?: SxProps<Theme>;
    showPadding?: boolean;
    centerContent?: boolean;
    showPaddingTop?: boolean;
}

/**
 * Paper component with App theme.
 */
export default function AppPaper(props: IAppPaperProps) {
    const {
        children,
        className,
        sx,
        showPadding,
        centerContent,
        showPaddingTop,
    } = props;

    const {classes, cx} = useStyles();

    const theClassName = cx(
        classes.paper,
        showPadding ? classes.showPadding : undefined,
        centerContent ? classes.centerContent : undefined,
        showPaddingTop ? classes.showPaddingTop : undefined,
        className,
    );

    return (
        <Paper className={theClassName} elevation={0} sx={sx}>
            {children}
        </Paper>
    );
}
