import React, {Dispatch, SetStateAction, useContext, useEffect, useId, useMemo, useState} from "react";
import {AppContext} from "../../../App";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {
    ClientProfileResponse,
    DeleteClientDocument,
    DeleteClientMutation,
    DeleteClientMutationVariables,
    GetClientProfileInput
} from "../../../generated/graphql/graphql";
import {SetURLSearchParams, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useMutation} from "@apollo/client";
import {SuccessToast} from "../../../service/ToastService";
import CreateLocationModal from "../../components/locations/CreateLocationModal";
import Menu from "@mui/material/Menu";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MenuItem from "@mui/material/MenuItem";
import {Box, Divider} from "@mui/material";
import DeleteIcon from "../../../icons/DeleteIcon";
import AppIconButton from "../../components/buttons/AppIconButton";
import PencilIcon from "../../../icons/PencilIcon";
import PaperAppbar from "../../components/paper/PaperAppbar";
import MoreFilledIcon from "../../../icons/MoreFilledIcon";
import {usePopupState} from "material-ui-popup-state/hooks";
import {kClientsBreadcrumb, kClientsRoute} from "./ClientsScreen";
import {HideConfirmModal, SetConfirmModal} from "../../components/modals/AppModals";
import {AppDataContext, SetAppBreadcrumbs} from "../../../AppData";
import DetailScreenShimmer from "../../components/shimmers/DetailScreenShimmer";
import AppListItem from "../../components/listItems/AppListItem";
import Icons8Company from "../../../icons/Icons8Company";
import UserIcon from "../../../icons/UserIcon";
import AppTabsComponent from "../../components/AppTabsComponent";
import ContactSection from "../../screenSections/contactSection/ContactSection";
import DetailListPreviewSection from "../../screenSections/detailListPreviewSection/DetailListPreviewSection";
import {contactPersonDetailRoute} from "../contactPerson/ContactPersonDetailScreen";
import {contactPersonListRoute} from "../contactPerson/ContactPersonListScreen";
import CreateContactPersonModal from "../../components/contactPerson/CreateContactPersonModal";
import {locationDetailRoute} from "../locations/LocationDetailScreen";
import {UserFullName} from "../../../service/UserService";
import {clientEditRoute} from "./ClientEditScreen";
import {LocationNameDisplay, LocationTypeDisplay} from "../../../service/LocationService";
import {LocationsRoute} from "../locations/LocationsScreen";
import IBreadcrumb from "../../../model/Breadcrumb";
import AppBreadCrumb from "../../components/breadCrumb/AppBreadCrumb";
import Icons8Location from "../../../icons/Icons8Location";
import IEventSystemListener from "../../../model/EventSystemListener";
import {listenToEventSystem, unListenToEventSystem} from "../../../service/EventSystemService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import {processMutationError, processQueryError} from "../../../service/ErrorService";
import ClientJobsTab from "../../components/clients/ClientJobsTab";
import Icons8Plus from "../../../icons/Icons8Plus";
import {
    kActionDelete,
    kActionUpdate,
    kActionView,
    kPermissionsClients,
    kPermissionsJobs,
    kTopicClientContacts,
    kTopicClients,
    kTopicLocations
} from "../../../core/constants";
import PermissionValid, {hasPermission} from "../../components/permissions/PermissionValid";
import NoteItemsSection from "../../components/noteItems/NoteItemsSection";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import {addressToSingleLine} from "../../../utils/AddressUtils";
import {displayClientTaxInfoAsText} from "../../../service/ClientService";
import {GetCompanyTaxInfoAsText} from "../../../service/CompanyService";
import BasicRow from "../../components/screens/companyDetail/BasicRow";
import Icons8Invoice from "../../../icons/Icons8Invoice";

export const kClientDetailRoute = '/clients/:clientId';

export function clientDetailRoute(clientId: string | number): string {
    return kClientDetailRoute.replace(':clientId', `${clientId}`)
}

/**
 * Screen component for detail of Client.
 */
export default function ClientDetailScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);

    const {clientId} = useParams();

    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedTab, setSelectedTab] = useState<string>(searchParams.get('clientDetail-selectedTab') ? searchParams.get('clientDetail-selectedTab')! : '0');
    const [createLocationModal, setCreateLocationModal] = useState<boolean>(false);
    const [createContactPersonModal, setCreateContactPersonModal] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<ClientProfileResponse | NullOrUndefined>();
    const [refetch, setRefetch] = useState<number>(0);
    useEffect(() => {
        if (clientId) {
            const subscription = subscribe(
                kTopicClients,
                {
                    uri: '/client/profile',
                    params: {
                        clientId: parseInt(clientId),
                        locationsPageableSize: 1,
                        locationsPageableOffset: 0,
                    } as GetClientProfileInput,
                    setLoading,
                    onData: setData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                (notifications: IEventSystemNotification[]) => {
                    return notifications.some(notification => {
                        if (notification.action !== kActionUpdate) {
                            return false;
                        }

                        return notification.data.id === parseInt(clientId!);
                    });
                },
            );

            return () => subscription.cancel();
        } else {
            setLoading(false);
            setData(null);
        }
    }, [clientId, refetch]);

    const [mutateDelete, {
        loading: deleteLoading,
    }] = useMutation<DeleteClientMutation, DeleteClientMutationVariables>(DeleteClientDocument);

    /**
     * Set breadcrumbs.
     */
    const SetBreadcrumbs = () => {
        const breadcrumbs: IBreadcrumb[] = [kClientsBreadcrumb];

        if (data) {
            breadcrumbs.push({
                key: 'client-detail',
                text: data!.client.name,
                route: clientDetailRoute(clientId!),
            });
        }

        SetAppBreadcrumbs(appDataContext, breadcrumbs);
    };

    useEffect(() => {
        setTitle(tt('clientDetail.screen.title'));

        SetBreadcrumbs();
    }, []);

    useEffect(() => {
        SetBreadcrumbs();
    }, [data]);

    useEffect(() => {
        SetConfirmModal(appDataContext, {
            loading: deleteLoading,
        });
    }, [deleteLoading]);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('clientDetail-selectedTab', selectedTab);

            return prev;
        });
    }, [selectedTab]);

    /**
     * Mutation to delete Client to BE.
     */
    const DeleteClient = async () => {
        try {
            const variables: DeleteClientMutationVariables = {
                input: {
                    clientId: parseInt(clientId!),
                },
            };

            const result = await mutateDelete({variables});

            if (!result.errors) {
                HideConfirmModal(appDataContext);

                SuccessToast(tt('clientDetail.screen.delete.success'));
            }
        } catch (e) {
            processMutationError(e);
        }
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                clientId={parseInt(clientId!)}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                isMobile={isMobile}
                loading={loading}
                data={data}
                setCreateLocationModal={setCreateLocationModal}
                setCreateContactPersonModal={setCreateContactPersonModal}
                deleteClient={DeleteClient}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
            />
        );
    }

    return (
        <>
            <EventSystemListeners
                refetch={() => setRefetch(prev => prev + 1)}
            />

            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}/>

            <CreateLocationModal
                open={createLocationModal}
                setOpen={setCreateLocationModal}
                clientId={parseInt(clientId!)}/>

            <CreateContactPersonModal
                open={createContactPersonModal}
                setOpen={setCreateContactPersonModal}
                clientId={parseInt(clientId!)}/>

        </>
    );
}

interface IEventSystemListenersProps {
    refetch: VoidFunction;
}

/**
 * Component for EventSystem listeners.
 */
function EventSystemListeners(props: IEventSystemListenersProps) {
    const {refetch} = props;

    const {clientId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicClients,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(clientId!);
                });

                if (doDelete) {
                    navigate(kClientsRoute);
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [clientId]);

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicClientContacts,
            callback: (notifications: IEventSystemNotification[]) => {
                const update = notifications.some(notification => {
                    return notification.data.clientId === parseInt(clientId!);
                });

                if (update) {
                    refetch();
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [refetch, clientId]);

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicLocations,
            callback: (notifications: IEventSystemNotification[]) => {
                const update = notifications.some(notification => {
                    return notification.data.clientId === parseInt(clientId!);
                });

                if (update) {
                    refetch();
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [refetch, clientId]);

    return null;
}

interface IBodyProps {
    clientId: number;
    searchParams: URLSearchParams;
    setSearchParams: SetURLSearchParams;
    isMobile?: boolean;
    loading: boolean;
    data?: ClientProfileResponse | NullOrUndefined;
    setCreateLocationModal: Dispatch<SetStateAction<boolean>>;
    setCreateContactPersonModal: Dispatch<SetStateAction<boolean>>;
    deleteClient: VoidFunction;
    selectedTab: string;
    setSelectedTab: Dispatch<SetStateAction<string>>;
}

/**
 * Main body component.
 */
function Body(props: IBodyProps) {
    const {
        clientId,
        searchParams,
        setSearchParams,
        isMobile,
        loading,
        data,
        setCreateLocationModal,
        setCreateContactPersonModal,
        deleteClient,
        selectedTab,
        setSelectedTab,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {employeePermissionsMap} = appDataContext;

    const navigate = useNavigate();

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const clientContacts = useMemo(() => {
        return (data?.client.contacts || [])
            .filter(contact => contact.assignedToClient);
    }, [data]);

    const businessTaxInfo = useMemo(() => {
        if (data && data.client.billingAddress) {
            return {
                info: displayClientTaxInfoAsText({client: data.client}),
                address: addressToSingleLine(data.client.billingAddress),
            };
        }

        return {};
    }, [data]);

    const tabsData = [
        {
            label: tt('common.client'),
            content: (
                <>
                    {businessTaxInfo.address ?
                        <BasicRow icon={<Icons8Invoice/>} text={businessTaxInfo.address}/> : null}

                    <ContactSection
                        hideAddress={true}
                        email={data?.client.email}
                        phone={data?.client.phoneNumber}
                    />
                    <Divider/>

                    {/*contact people*/}
                    <PermissionValid
                        permission={kPermissionsClients}
                    >
                        <>
                            <DetailListPreviewSection
                                totalItems={clientContacts.length}
                                headlineWidgetProps={
                                    {
                                        title: tt('clientDetail.screen.contactPersons'),
                                        iconJSX: <Icons8Plus/>,
                                        onClick: (e) => {
                                            setCreateContactPersonModal(true);
                                        },
                                        buttonText: tt('clientDetail.screen.newPerson'),
                                        permission: kPermissionsClients,
                                        requiredPermissions: [kActionUpdate],
                                    }
                                }
                                itemProps={clientContacts.length ?
                                    {
                                        onClick: () => navigate(contactPersonDetailRoute(clientId, clientContacts[0].id)),
                                        title: UserFullName(clientContacts[0].name, clientContacts[0].surname),
                                        description: clientContacts[0].role || '',
                                        customAvatar: <UserIcon/>
                                    } : undefined
                                }
                                onViewAllClick={() => navigate(contactPersonListRoute(clientId))}
                            />

                            <Divider/>
                        </>
                    </PermissionValid>

                    <DetailListPreviewSection
                        totalItems={data?.allClientLocationsCount}
                        headlineWidgetProps={
                            {
                                title: tt('common.locations'),
                                iconJSX: <Icons8Plus/>,
                                onClick: (e) => {
                                    setCreateLocationModal(true);
                                },
                                buttonText: tt('common.newLocation'),
                                permission: kPermissionsClients,
                                requiredPermissions: [kActionUpdate],
                            }
                        }
                        itemProps={
                            data && data!.locations.length > 0 ? {
                                onClick: () => {
                                    navigate(locationDetailRoute(clientId, data!.locations[0].id));
                                },
                                title: LocationNameDisplay(data!.locations[0].name, data!.locations[0].type || ''),
                                description: `${data!.locations[0].name ? LocationTypeDisplay(data!.locations[0].type || '') : ''}\n${addressToSingleLine(data!.locations[0].address)}`.trim(),
                                customAvatar: <Icons8Location/>,
                            } : undefined
                        }
                        onViewAllClick={() => navigate(LocationsRoute(clientId))}
                    />
                </>
            ),
        },
    ];

    if (hasPermission(kPermissionsJobs, [kActionView], employeePermissionsMap)) {
        tabsData.push({
            label: tt('common.jobs'), content: (
                <ClientJobsTab
                    clientId={clientId}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                />
            )
        });
    }

    if (hasPermission(kPermissionsClients, [kActionView], employeePermissionsMap)) {
        tabsData.push({
            label: tt('clientDetail.screen.tab.notes'),
            content: <NoteItemsSection
                permission={kPermissionsClients}
                clientId={clientId}
            />,
        });
    }

    const contentJSX = loading && !data ? <DetailScreenShimmer/> :
        (
            <>
                <AppListItem
                    customAvatarInCircle={data?.client.company ? <Icons8Company/> : <UserIcon/>}
                    title={data?.client.name || tt('common.notFilledIn')}
                    description={businessTaxInfo?.info ? businessTaxInfo.info : undefined}
                    actionWidget={
                        <></>
                    }/>

                <AppTabsComponent
                    controlledValue={selectedTab}
                    onTabChange={setSelectedTab}
                    data={tabsData}
                />
            </>
        );

    return (
        <ScreenContent
            showBreadCrumb={true}
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    isMobile={isMobile}
                    title={tt('client.detail.content.title')}
                    backRoute={kClientsRoute}
                >
                    <PermissionValid
                        permission={kPermissionsClients}
                        requiredPermissions={[kActionUpdate]}
                    >
                        <AppIconButton key={'clientDetailBtn1'} onClick={() => {
                            navigate(clientEditRoute(clientId));
                        }
                        }><PencilIcon/></AppIconButton>
                    </PermissionValid>

                    <PermissionValid
                        permission={kPermissionsClients}
                        requiredPermissions={[kActionDelete]}
                    >
                        <AppIconButton key={'clientDetailBtn2'}
                                       {...bindTrigger(settingsPopupState)}
                        >
                            <MoreFilledIcon/>
                        </AppIconButton>
                    </PermissionValid>
                </PaperAppbar>

                {isMobile ? <AppBreadCrumb/> : null}

                {contentJSX}

                <Menu {...bindMenu(settingsPopupState)}>
                    <PermissionValid
                        permission={kPermissionsClients}
                        requiredPermissions={[kActionDelete]}
                    >
                        <MenuItem key={'clientDetailDeleteKey'} onClick={() => {
                            settingsPopupState.close();

                            SetConfirmModal(appDataContext, {
                                open: true,
                                title: tt('client.detail.delete.confirm.title'),
                                subtitle: tt('client.detail.delete.confirm.subtitle'),
                                confirmationButtonText: tt('common.delete'),
                                cancelButtonText: tt('common.close'),
                                children: <></>,
                                onConfirm: deleteClient,
                            });
                        }}>
                            <Box sx={{color: kAppColors.red.confirmButton}}><DeleteIcon/></Box>
                            {tt('common.delete')}
                        </MenuItem>
                    </PermissionValid>
                </Menu>
            </AppPaper>
        </ScreenContent>
    );
}
