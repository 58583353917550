import React, {useContext, useEffect} from "react";
import {AppContext} from "../../../App";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import {tt} from "../../../core/Localization";
import AppPaper from "../../components/paper/AppPaper";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {AppDataContext} from "../../../AppData";
import NotificationsContent from "../../components/notifications/NotificationsContent";


export const kNotificationsRoute = '/notifications';

export default function NotificationsScreen() {
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    useEffect(() => {
        setTitle(tt('notifications.screen.title'));
    }, []);


    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                // loading={loading}
                // data={data}
                isMobile={isMobile}/>
        );
    }

    return (
        <ResponsiveContainer
            smallPhoneScreen={bodyJSX(true)}
            largePhoneScreen={bodyJSX(true)}
            tabletScreen={bodyJSX()}
            smallDesktopScreen={bodyJSX()}
            largeDesktopScreen={bodyJSX()}
            extraLargeDesktopScreen={bodyJSX()}
        />
    );
}

interface IBodyProps {
    // loading: boolean;
    // data: GetEmployeesJoinedUsersQuery | NullOrUndefined;
    isMobile?: boolean;
}

function Body(props: IBodyProps) {
    const {isMobile} = props;

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <NotificationsContent isScreen={true} isMobile={isMobile}/>
            </AppPaper>
        </ScreenContent>
    );
}
