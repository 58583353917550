import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8WindowProps {
    className?: string;
}

export default function Icons8Window(props: IIcons8WindowProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
          <path
              d="M 14.5 6 C 11.480226 6 9 8.4802259 9 11.5 L 9 36.5 C 9 39.519774 11.480226 42 14.5 42 L 33.5 42 C 36.519774 42 39 39.519774 39 36.5 L 39 11.5 C 39 8.4802259 36.519774 6 33.5 6 L 14.5 6 z M 14.5 9 L 22.5 9 L 22.5 19 L 12 19 L 12 11.5 C 12 10.101774 13.101774 9 14.5 9 z M 25.5 9 L 33.5 9 C 34.898226 9 36 10.101774 36 11.5 L 36 19 L 25.5 19 L 25.5 9 z M 12 22 L 22.5 22 L 22.5 39 L 14.5 39 C 13.101774 39 12 37.898226 12 36.5 L 12 22 z M 25.5 22 L 36 22 L 36 36.5 C 36 37.898226 34.898226 39 33.5 39 L 25.5 39 L 25.5 22 z"/>
          </svg>
        </span>
    );
}