import React, {useContext} from "react";
import {Box, Theme, Typography} from "@mui/material";
import AppModal from "./AppModal";
import {kAppColors, kContentWidthNarrow} from "../../../styles/AppThemeProcessor";
import {tt} from "../../../core/Localization";
import AppChip from "../chips/AppChip";
import {makeStyles} from "tss-react/mui";
import {HideAppVersionModal, HideConfirmModal} from "./AppModals";
import {AppDataContext} from "../../../AppData";

export const useStyles = makeStyles()((theme: Theme) => ({
    modalPaper: {
        maxWidth: kContentWidthNarrow,
    },
    chip: {
        flexGrow: 1,
    },
    confirmChip: {
        flexGrow: 1,
    },
    textStyle: {
        fontSize: 14,
        fontWeight: 500,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        textAlign: 'center',
        whiteSpace: 'break-spaces',
    },
    linkContainer: {
        textAlign: "center",
    },
    closeButtonContainer: {
        flexGrow: 1,
        display: "flex",
        '.MuiChip-root': {
            margin: "auto",
            minWidth: 190,
        }
    }
}));

export interface IAppVersionModalProps {
    open: boolean;
    version: string;
}

/**
 * Modal component for app version updated notification and reload app.
 */
export default function AppVersionModal(props: IAppVersionModalProps) {
    const {open, version} = props;

    const appDataContext = useContext(AppDataContext);
    const {classes, cx} = useStyles();

    const text = tt('appVersionModal.subtitle').replace('$version', version);

    return (
        <AppModal paperClassName={classes.modalPaper}
                  title={tt('appVersionModal.title')}
                  open={open}
                  centeredHeader={true}
                  onClose={() => HideAppVersionModal(appDataContext)}
                  actions={(
                      <>
                          <AppChip
                              className={classes.chip}
                              onClick={(event) => {
                                  HideAppVersionModal(appDataContext);
                              }}
                              chipstyle={"secondary"}
                              label={tt('appVersionModal.later')}
                          />

                          <Box pr={2}/>

                          <AppChip
                              color={"success"}
                              className={classes.confirmChip}
                              key={'confirmButtonKey'}
                              onClick={() => {
                                  window.location.reload();
                              }}
                              label={tt('appVersionModal.update')}
                          />
                      </>
                  )}>
            <Typography className={classes.textStyle}>{text}</Typography>

            <Box pb={4}/>
        </AppModal>
    );
}
