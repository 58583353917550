import {Box, Theme, Typography, useTheme} from "@mui/material";
import React, {useContext} from "react";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import {tt} from "../../../../core/Localization";
import Icons8InTransit from "../../../../icons/Icons8InTransit";
import Icons8Cancel2 from "../../../../icons/Icons8Cancel2";
import Icons8MediumRisk from "../../../../icons/Icons8MediunRisk";
import {
    getVisitStatusBackgroundColor,
    getVisitStatusIcon,
    getVisitStatusTextColor
} from "../../../../service/VisitService";
import HammerIcon from "../../../../icons/HammerIcon";
import {AppDataContext} from "../../../../AppData";
import {VisitStatus} from "../../../../generated/graphql/graphql";


export const useStyles = makeStyles()((theme: Theme) => ({
    outerContainer: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        '.labelContainer': {
            borderBottom: `1px solid transparent`,
        },
        ':hover': {
            '.labelContainer': {
                borderBottom: `1px solid ${kAppColors.text.primary(theme.palette.mode === "dark")}`,
            }
        },
        "@media (max-width: 576px)": {
            display: "none",
        },
    },
    labelWithValueContainer: {
        display: "flex",
    },
    value: {
        fontSize: 14,
        fontWeight: 700,
    },
    label: {
        fontSize: 14,
    },
    iconContainer: {
        marginRight: 8,
    }
}));

export interface IRadialBarChartIconLabelProps {
    value: number;
    status: VisitStatus;
    onClick: VoidFunction;
}

export default function RadialBarChartIconLabel(props: IRadialBarChartIconLabelProps) {
    const {value, status, onClick} = props;

    const {classes, cx} = useStyles();
    const appDataContext = useContext(AppDataContext);
    const {darkMode} = appDataContext;
    const theme = useTheme();

    let label;
    let icon;
    let iconColor = getVisitStatusTextColor(status, darkMode);

    switch (status) {
        case VisitStatus.Travelling:
            label = tt('radialChart.label.onWay');
            icon = <Icons8InTransit/>;
            break;
        case VisitStatus.Unfinished:
            label = tt('radialChart.label.toSolve');
            icon = <Icons8MediumRisk/>;
            break;
        case VisitStatus.Canceled:
            label = tt('radialChart.label.canceled');
            icon = <Icons8Cancel2/>;
            break;
        case VisitStatus.JobOffer:
            label = tt('radialChart.label.offer');
            icon = getVisitStatusIcon(VisitStatus.JobOffer);
            iconColor = getVisitStatusTextColor(VisitStatus.JobOffer, darkMode);
            break;
        case VisitStatus.Closed:
            label = tt('radialChart.label.closed');
            icon = getVisitStatusIcon(VisitStatus.Closed);
            break;
        case VisitStatus.InProgress:
            label = tt('radialChart.label.inProgress');
            icon = <HammerIcon/>;
            break;
    }

    return (
        <>
            {value == 0 ? null : (
                <Box
                    onClick={onClick}
                    className={classes.outerContainer}>
                    <Box className={classes.iconContainer} color={iconColor}>
                        {icon}
                    </Box>

                    <Box className={cx(classes.labelWithValueContainer, 'labelContainer')}>
                        <Typography className={classes.value}>{value}&nbsp;</Typography>
                        <Typography className={classes.label}>{label}</Typography>
                    </Box>
                </Box>
            )}
        </>
    );
}
