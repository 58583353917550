import {iconUseStyles} from "../styles/IconStyles";

export interface ISecuredEmailIconProps {
    className?: string;
}

/**
 * Component representing Home svg icon.
 */
export default function SecuredEmailIcon(props: ISecuredEmailIconProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill='currentColor' id="icons8-secured-mail" xmlns="http://www.w3.org/2000/svg" width="20" height="19.524" viewBox="0 0 20 19.524">
                <path id="icons8-secured-mail-2" data-name="icons8-secured-mail" d="M7.1,5A3.1,3.1,0,0,0,4,8.1v9.048a3.1,3.1,0,0,0,3.1,3.1h5.548a6.271,6.271,0,0,1-.072-.952,3.937,3.937,0,0,1,.024-.476H7.1a1.668,1.668,0,0,1-1.667-1.667v-6.9l7.756,4.193a.714.714,0,0,0,.679,0l7.756-4.193V13.8a6.09,6.09,0,0,1,1.429,1.029V8.1a3.1,3.1,0,0,0-3.1-3.1Zm0,1.429H19.952A1.668,1.668,0,0,1,21.619,8.1v.526L13.524,13l-8.1-4.376V8.1A1.668,1.668,0,0,1,7.1,6.429Zm11.667,7.619A5.238,5.238,0,1,0,24,19.286,5.238,5.238,0,0,0,18.762,14.048Zm0,1.429a1.668,1.668,0,0,1,1.667,1.667v.748a.95.95,0,0,1,.714.919V21.19a.952.952,0,0,1-.952.952H17.333a.952.952,0,0,1-.952-.952V18.81a.95.95,0,0,1,.714-.919v-.748A1.668,1.668,0,0,1,18.762,15.476Zm0,.952a.715.715,0,0,0-.714.714v.714h1.429v-.714A.715.715,0,0,0,18.762,16.429Z" transform="translate(-4 -5)"/>
            </svg>
        </span>
    );
}
