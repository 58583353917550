import ModalBottomSheet from "../ModalBottomSheet";
import {Box, Theme} from "@mui/material";
import BottomSheetModalAppbar from "../modals/BottomSheetModalAppbar";
import {tt} from "../../../core/Localization";
import AppButton from "../buttons/AppButton";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import {AppDataContext} from "../../../AppData";
import {makeStyles} from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
    customZIndex: {
        zIndex: '1302 !important',
    },
}));

export interface IEditFormSectionTitleModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onSubmit: (title: string) => void;
    title?: string;
}

export default function EditFormSectionTitleModal(props: IEditFormSectionTitleModalProps) {
    const {
        onSubmit,
        title,
        open,
        setOpen,
    } = props;

    const {classes} = useStyles();
    const appDataContext = useContext(AppDataContext);

    const [inputs, setInputs] = useState<IInputsData>({
        title: {
            testId: 'editJobFormSectionTitleInput',
            type: InputType.Text,
            label: tt('common.title'),
            value: title || '',
        },
    });

    useEffect(() => {
        if (open) {
            setInputs(prev => {
                    return {
                        ...prev,
                        title: {
                            ...prev.title,
                            value: title || '',
                        },
                    };
                }
            );
        }
    }, [open]);

    return (
        <ModalBottomSheet
            className={classes.customZIndex}
            blurBackdrop={true}
            customZIndex={1302}
            open={open}
            setOpen={setOpen}
            hideHeader={true}>
            <BottomSheetModalAppbar
                noBorderBottom={true}
                onClose={() => setOpen(false)}
                title={tt('jobForm.editSectionTitleModal.title')}>
            </BottomSheetModalAppbar>
            <Box p={2}>
                <FormBuilder
                    inputs={inputs}
                    setInputs={setInputs}
                />

                <Box sx={{pb: 2}}/>

                <AppButton
                    variant={"contained"}
                    fullWidth={true}
                    onClick={() => {
                        if (ValidateForm(inputs, setInputs)) {
                            onSubmit(inputs.title.value)
                        }
                    }}
                >
                    {tt('common.save')}
                </AppButton>
            </Box>
        </ModalBottomSheet>
    );
}
