import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8AlarmProps {
    className?: string;
}

export default function Icons8Alarm(props: IIcons8AlarmProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path
                    d="M 3.4667969 3.9863281 A 1.50015 1.50015 0 0 0 2.5625 6.671875 L 7.5625 10.671875 A 1.5007322 1.5007322 0 1 0 9.4375 8.328125 L 4.4375 4.328125 A 1.50015 1.50015 0 0 0 3.4667969 3.9863281 z M 44.488281 3.9863281 A 1.50015 1.50015 0 0 0 43.5625 4.328125 L 38.5625 8.328125 A 1.5007322 1.5007322 0 1 0 40.4375 10.671875 L 45.4375 6.671875 A 1.50015 1.50015 0 0 0 44.488281 3.9863281 z M 24 4 C 15.733378 4 9 10.733378 9 19 L 9 27.185547 L 6.2382812 33.498047 C 5.5384109 35.098789 6.7819446 37 8.5292969 37 L 18 37 C 18 40.295865 20.704135 43 24 43 C 27.295865 43 30 40.295865 30 37 L 39.470703 37 C 41.218055 37 42.461589 35.098789 41.761719 33.498047 L 39 27.185547 L 39 19 C 39 10.733378 32.266622 4 24 4 z M 24 7 C 30.645378 7 36 12.354622 36 19 L 36 27.5 A 1.50015 1.50015 0 0 0 36.125 28.101562 L 38.707031 34 L 24 34 L 9.2929688 34 L 11.875 28.101562 A 1.50015 1.50015 0 0 0 12 27.5 L 12 19 C 12 12.354622 17.354622 7 24 7 z M 1.5 16 A 1.50015 1.50015 0 1 0 1.5 19 L 5.5 19 A 1.50015 1.50015 0 1 0 5.5 16 L 1.5 16 z M 42.5 16 A 1.50015 1.50015 0 1 0 42.5 19 L 46.5 19 A 1.50015 1.50015 0 1 0 46.5 16 L 42.5 16 z M 21 37 L 24 37 L 27 37 C 27 38.674135 25.674135 40 24 40 C 22.325865 40 21 38.674135 21 37 z"/>
            </svg>
        </span>
    );
}
























