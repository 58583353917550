import React, {useEffect, useState} from "react";

export interface IResponsiveContainerProps {
    // Content for phone screens (< 576px)
    smallPhoneScreen: React.ReactNode;
    // Content for phone screens (> 576px)
    largePhoneScreen: React.ReactNode;
    // Content for tablet screens (> 768px)
    tabletScreen: React.ReactNode;
    // Content for desktop screens (> 992px)
    smallDesktopScreen: React.ReactNode;
    // Content for desktop screens (> 1200px)
    largeDesktopScreen: React.ReactNode;
    // Content for desktop screens (> 1500px)
    extraLargeDesktopScreen: React.ReactNode;
}

/**
 * Responsive container component used to respond to various screen sized based on Bootstrap breakpoints.
 */
export default function ResponsiveContainer(props: IResponsiveContainerProps) {
    const {
        smallPhoneScreen,
        largePhoneScreen,
        tabletScreen,
        smallDesktopScreen,
        largeDesktopScreen,
        extraLargeDesktopScreen
    } = props;

    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        const callback = () => {
            setWindowSize(getWindowSize());
        };

        window.addEventListener('resize', callback);

        return () => {
            window.removeEventListener('resize', callback);
        };
    }, []);

    if (windowSize.innerWidth >= 1500) {
        return (
            <>
                {extraLargeDesktopScreen}
            </>
        );
    } else if (windowSize.innerWidth > 1200) {
        return (
            <>
                {largeDesktopScreen}
            </>
        );
    } else if (windowSize.innerWidth > 992) {
        return (
            <>
                {smallDesktopScreen}
            </>
        );
    } else if (windowSize.innerWidth > 768) {
        return (
            <>
                {tabletScreen}
            </>
        );
    } else if (windowSize.innerWidth > 576) {
        return (
            <>
                {largePhoneScreen}
            </>
        );
    } else {
        return (
            <>
                {smallPhoneScreen}
            </>
        );
    }
}

/**
 * Get current window size.
 */
function getWindowSize() {
    const {innerWidth, innerHeight} = window;

    return {innerWidth, innerHeight};
}
