import {makeStyles} from "tss-react/mui";
import {Box, Theme, Typography} from "@mui/material";
import React from "react";
import {kAppColors} from "../../../styles/AppThemeProcessor";

export interface IDetailColumnProps {
    title: string;
    description?: string;
    children?: React.ReactNode;
}

export const useStyles = makeStyles()((theme: Theme) => ({
    columnItem: {
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingRight: 16,
        paddingLeft: 16,
        paddingTop: 8,
        paddingBottom: 8,
        marginBottom: 4,
    },
    columnItemInner: {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    columnItemTitle: {
        fontSize: 12,
        fontWeight: 700,
        textTransform: "uppercase",
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        marginBottom: 8,
        textAlign: "center",
    },
    description: {
        fontSize: 20,
        fontWeight: 500,
        lineHeight: 1,
    },
}));


export default function DetailColumnItem(props: IDetailColumnProps) {
    const {
        title,
        children,
        description
    } = props;


    const {classes, cx} = useStyles();

    return (
        <Box className={classes.columnItem}>
            <Typography className={classes.columnItemTitle}>{title}</Typography>
            <Box className={classes.columnItemInner}>
                {description ? <Typography className={classes.description}>{description}</Typography> : null}
                {children}
            </Box>
        </Box>
    );
}