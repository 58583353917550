import {Dispatch, SetStateAction} from "react";

export const kStorageTheme = 'theme';


export const kDefaultTheme = 'light';
export const kSupportedThemes = ['dark', 'light'];


/**
 * Initialize theme
 */
export function InitTheme(setTheme: Dispatch<SetStateAction<string>>) {
    const storedTheme = localStorage.getItem(kStorageTheme);

    if (storedTheme && kSupportedThemes.includes(storedTheme)) {
        setTheme(storedTheme);
    } else {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

        if (prefersDark) {
            setTheme('dark');
        } else {
            setTheme('light');
        }

        ListenTheme(setTheme);
    }
}

let themeChangeListener: ((this: MediaQueryList, ev: MediaQueryListEvent) => any) | null = null;

/**
 * Listen to theme changes and update theme.
 * Does not save to local storage.
 */
export function ListenTheme(setTheme: Dispatch<SetStateAction<string>>) {
    if (themeChangeListener) {
        window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', themeChangeListener);
    }

    themeChangeListener = event => {
        const newColorScheme = event.matches ? "dark" : "light";

        setTheme(newColorScheme);
    };

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', themeChangeListener);
}

/**
 * Change theme
 */
export function ChangeTheme(theme: string, setTheme: Dispatch<SetStateAction<string>>) {
    if (kSupportedThemes.includes(theme)) {
        if (themeChangeListener) {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', themeChangeListener);
            themeChangeListener = null;
        }

        localStorage.setItem(kStorageTheme, theme);

        setTheme(theme);
    }
}
