import {Button, SxProps, Theme} from "@mui/material";
import React from "react";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";

export const useStyles = makeStyles()((theme: Theme) => ({
        button: {
            height: 48,
            borderRadius: 48,
            borderColor: kAppColors.border(theme.palette.mode === "dark"),
            'span': {
                marginRight: 8,
            },
            fontSize:
                14,
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
            ":hover": {
                borderColor: kAppColors.border(theme.palette.mode === "dark"),
                backgroundColor: kAppColors.hoverColor.grey(theme.palette.mode === "dark"),
            }
        },
        fullWidth: {
            width: '100%',
        },
        dashed46: {
            height: 46,
            borderStyle: "dashed",
        },
        default46h: {
            height: 46,
        }
    })
);

export interface ISocialButtonProps {
    icon: React.ReactNode;
    text: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    sx?: SxProps<Theme>;
    className?: string;
    fullWidth?: boolean;
    variant?: 'default' | 'default46h' | 'dashed46h'
}

export default function SocialButton(props: ISocialButtonProps) {
    const {
        sx,
        icon,
        text,
        onClick,
        className,
        fullWidth,
        variant,
    } = props;

    const {classes, cx} = useStyles();
    const theClassName = cx(
        className,
        classes.button,
        variant === "dashed46h" ? classes.dashed46 : null,
        variant === "default46h" ? classes.default46h : null,
        fullWidth ? classes.fullWidth : undefined
    );

    return (
        <Button
            disableElevation={true}
            sx={sx}
            className={theClassName}
            variant={"outlined"}
            onClick={onClick}>
            {icon}
            {text}
        </Button>
    );
}