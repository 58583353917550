import {iconUseStyles} from "../styles/IconStyles";


export interface IIcons8ResetProps {
    className?: string;
}

export default function Icons8Reset(props: IIcons8ResetProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <path fill="currentColor"
                      d="M 37.476562 5.9785156 A 1.50015 1.50015 0 0 0 36 7.5 L 36 10.605469 C 32.815876 7.7471496 28.609324 6 24 6 C 14.0764 6 6 14.0764 6 24 C 6 33.9236 14.0764 42 24 42 C 33.9236 42 42 33.9236 42 24 C 42 23.048235 41.90482 22.141037 41.773438 21.275391 A 1.50015 1.50015 0 1 0 38.808594 21.724609 C 38.925211 22.492963 39 23.247765 39 24 C 39 32.3024 32.3024 39 24 39 C 15.6976 39 9 32.3024 9 24 C 9 15.6976 15.6976 9 24 9 C 27.934257 9 31.480462 10.527501 34.150391 13 L 30.5 13 A 1.50015 1.50015 0 1 0 30.5 16 L 37.5 16 A 1.50015 1.50015 0 0 0 39 14.5 L 39 7.5 A 1.50015 1.50015 0 0 0 37.476562 5.9785156 z"/>
            </svg>
        </span>
    );
}










