import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8MenuProps {
    className?: string;
}

export default function Icons8Menu(props: IIcons8MenuProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
                <g id="icons8-menu" transform="translate(0 0)">
                    <path fill="currentColor" id="icons8-menu-2" data-name="icons8-menu"
                          d="M4.75,9a.75.75,0,1,0,0,1.5h18.5a.75.75,0,1,0,0-1.5Zm0,6.75a.75.75,0,1,0,0,1.5h18.5a.75.75,0,1,0,0-1.5Zm0,6.75a.75.75,0,1,0,0,1.5h18.5a.75.75,0,1,0,0-1.5Z"
                          transform="translate(-4 -9)"/>
                </g>
            </svg>
        </span>
    );
}
