import {iconUseStyles} from "../styles/IconStyles";

export interface IMoreFilledIconIconProps {
    className?: string;
}

export default function MoreFilledIcon(props: IMoreFilledIconIconProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px"
                 preserveAspectRatio="xMidYMid meet" focusable="false">
                <path
                    d="M 7.9980469 18.498047 C 4.9771871 18.498047 2.4960938 20.97914 2.4960938 24 C 2.4960938 27.02086 4.9771871 29.501953 7.9980469 29.501953 C 11.018274 29.501953 13.5 27.02086 13.5 24 C 13.5 20.97914 11.018274 18.498047 7.9980469 18.498047 z M 39.998047 18.498047 C 36.977187 18.498047 34.496094 20.97914 34.496094 24 C 34.496094 27.02086 36.977187 29.501953 39.998047 29.501953 C 43.018274 29.501953 45.5 27.02086 45.5 24 C 45.5 20.97914 43.018274 18.498047 39.998047 18.498047 z M 23.998047 18.5 C 20.97764 18.5 18.498047 20.980679 18.498047 24 C 18.498047 27.019774 20.978273 29.5 23.998047 29.5 C 27.017821 29.5 29.498047 27.019774 29.498047 24 C 29.498047 20.980226 27.017821 18.5 23.998047 18.5 z"></path>
            </svg>
        </span>
    );
}









