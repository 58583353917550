import {makeStyles} from "tss-react/mui";
import {Box, Theme, Tooltip} from "@mui/material";
import {IChipSwitchProps, Option} from "./InputProps";
import {useStyles as formUseStyles} from "./FormBuilder";
import React from "react";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import GreyLabel from "../decorations/GreyLabel";


const useStyles = makeStyles()((theme: Theme) => ({
    containerOuter: {
        marginBottom: 20,
    },
    container: {
        maxWidth: '100%',
        display: "inline-flex",
        padding: 4,
        borderRadius: 20,
        background: theme.palette.mode == "dark" ? '#363637' : '#EEF0F3',
    },
    button: {
        position: "relative",
        cursor: "pointer",
        whiteSpace: "nowrap",
        textOverflow: 'ellipsis',
        overflow: "hidden",
        fontSize: 14,
        borderRadius: 50,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        background: theme.palette.mode == "dark" ? '#363637' : '#EEF0F3',
        color: kAppColors.text.primary(theme.palette.mode == "dark"),
        border: "none",
        fontWeight: 500,
        transition: 'background-color 0.3s ease-in-out',
        ':hover': {
            backgroundColor: theme.palette.mode === "dark" ? '#505050' : '#DFE1E5',
        },
        "@media (max-width: 576px)": {
            paddingLeft: 12,
            paddingRight: 12,
        }
    },
    selectedButton: {
        cursor: "default",
        color: kAppColors.text.tertiary(theme.palette.mode == "dark"),
        backgroundColor: theme.palette.mode === "dark" ? 'white' : kAppColors.text.secondaryLightModeGrey,
        ':hover': {
            backgroundColor: theme.palette.mode === "dark" ? 'white' : kAppColors.text.secondaryLightModeGrey,
        }
    },
    topLabelPadding: {
        paddingTop: 16,
        paddingBottom: 16,
    },
    isDisabled: {
        opacity: 0.5,
        pointerEvents: "none",
        cursor: "default",
    },
}));

export default function ChipSwitch(props: IChipSwitchProps) {
    const {
        className,
        hidden,
        index,
        value,
        updateValue,
        options,
        topLabel,
        disabled,
    } = props;

    const {classes: formClasses} = formUseStyles();
    const {classes, cx} = useStyles();


    const handleClick = (value: Option) => {

        updateValue(index, value.value);
    };

    const buttons = options.map(item =>
        item.tooltip ?
            <Tooltip
                key={item.value}
                title={item.tooltip}>
                <button
                    className={cx(classes.button, item.value == value ? classes.selectedButton : null)}
                    value={item.value}
                    onClick={() => handleClick(item)}
                    disabled={disabled}
                >
                    {item.label}

                    {item.icon}
                </button>
            </Tooltip>
            :
            <button
                key={item.value}
                className={cx(classes.button, item.value == value ? classes.selectedButton : null)}
                value={item.value}
                onClick={() => handleClick(item)}
                disabled={disabled}
            >
                {item.label}

                {item.icon}
            </button>
    );

    return (
        <div
            className={cx(className, hidden ? formClasses.hidden : undefined, classes.containerOuter, disabled ? classes.isDisabled : undefined)}>
            {topLabel ? <GreyLabel className={classes.topLabelPadding} text={topLabel}/> : null}
            <Box
                className={cx(classes.container)}>
                {buttons}
            </Box>
        </div>
    );
}
