import {iconUseStyles} from "../styles/IconStyles";

export interface IIcons8CameraIndustryProps {
    className?: string;
}

export default function Icons8CameraIndustry(props: IIcons8CameraIndustryProps) {
    const {className} = props;

    const {classes, cx} = iconUseStyles();

    const theClassName = cx(
        classes.icon,
        className,
    );

    return (
        <span className={theClassName}>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
              <path
                  d="M 8.5 7 C 5.4802259 7 3 9.4802259 3 12.5 L 3 24.5 C 3 27.519774 5.4802259 30 8.5 30 L 15.050781 30 C 15.24514 31.73308 16.408955 33.158991 18 33.724609 L 18 38.5 C 18 39.898226 16.898226 41 15.5 41 L 4.5 41 A 1.50015 1.50015 0 1 0 4.5 44 L 15.5 44 C 18.519774 44 21 41.519774 21 38.5 L 21 33.724609 C 22.591045 33.158991 23.75486 31.73308 23.949219 30 L 30.5 30 C 33.519774 30 36 27.519774 36 24.5 L 36 24.302734 L 42.667969 28.748047 A 1.50015 1.50015 0 0 0 45 27.5 L 45 9.5 A 1.50015 1.50015 0 0 0 43.455078 8 A 1.50015 1.50015 0 0 0 42.667969 8.2519531 L 36 12.697266 L 36 12.5 C 36 9.4802259 33.519774 7 30.5 7 L 8.5 7 z M 8.5 10 L 30.5 10 C 31.898226 10 33 11.101774 33 12.5 L 33 24.5 C 33 25.898226 31.898226 27 30.5 27 L 8.5 27 C 7.1017741 27 6 25.898226 6 24.5 L 6 12.5 C 6 11.101774 7.1017741 10 8.5 10 z M 42 12.302734 L 42 24.697266 L 36 20.697266 L 36 16.302734 L 42 12.302734 z M 21.5 14 A 1.50015 1.50015 0 1 0 21.5 17 L 27.5 17 A 1.50015 1.50015 0 1 0 27.5 14 L 21.5 14 z"/>
              </svg>
        </span>
    );
}
























