import {ContractType, EmployeeJoinedUserResponse, JobOfferSeatResponse} from "../generated/graphql/graphql";
import {tt} from "../core/Localization";
import {ResourceInput} from "@fullcalendar/resource";
import {UserFullName} from "./UserService";
import {EventInput} from "@fullcalendar/core";
import IVisitEvent from "../model/VisitEvent";

/**
 * Employee contract type to translated string.
 */
export function ContractTypeString(type: ContractType | undefined): string {
    switch (type) {
        case ContractType.Contract:
            return tt('common.dpp');
        case ContractType.FullTime:
            return tt('common.fullTimeContract');
        case ContractType.PartTime:
            return tt('common.partTime');
        case ContractType.SelfEmployed:
            return tt('common.ico');
        case ContractType.Temporary:
            return tt('common.dpc');
        case ContractType.None:
            return tt('common.notDisclosedContractType');
        default:
            return '';
    }
}

/**
 * Convert Employees into Fullcalendar Resources.
 */
export function EmployeesToResources(
    employees: EmployeeJoinedUserResponse[],
    events: EventInput[],
    jobOfferSeats: JobOfferSeatResponse[],
): ResourceInput[] {
    return employees
        .filter(employee => {
            for (const event of events) {
                const visit = event!.extendedProps!.visit as IVisitEvent;

                const employeeIds = visit.visitRepeatDay?.employeeIds || visit.employeeIds;

                if (employeeIds.includes(employee.id)) {
                    return true;
                }
            }

            for (const seat of jobOfferSeats) {
                if (seat.employeeIds.includes(employee.id) || seat.acceptedIds.includes(employee.id)) {
                    return true;
                }
            }

            return false;
        })
        .map(employee => {
            return {
                id: `${employee.id}`,
                title: UserFullName(employee?.name || employee?.user?.name, employee?.surname || employee?.user?.surname),
            };
        });
}

/**
 * Sort list of EmployeeJoinedUserResponse by name.
 */
export function SortEmployeesByName(employees: EmployeeJoinedUserResponse[]): EmployeeJoinedUserResponse[] {
    return employees.sort((a, b) => {
        const fullNameA = UserFullName(a?.name || a?.user?.name, a?.surname || a?.user?.surname);
        const fullNameB = UserFullName(b?.name || b?.user?.name, b?.surname || b?.user?.surname);

        return fullNameA.localeCompare(fullNameB);
    });
}
